import React, { useEffect, useRef, useState } from "react";
import IMAGES from "../../Images";
import Footer from "../../../Component/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BoatDetailCard } from "../Card/BoatDetailCard";
import { boat_list_filter } from "../../../Service/api";
import {
  boatServiceList,
  boatTypeList,
  boat_register_location_data,
  boat_register_step1,
  boat_register_step2,
  boat_register_step3,
  openChat,
  search_boat_id,
  single_boat_details_store,
} from "../../../redux/slices";
import { toast } from "react-toastify";
import "./BoatOwnerDashBoard.css";
import { PageHeader } from "../page-header/PageHeader";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import useWindowDimensions from "../../../UI kit/useWindowDimensions";
import CardLoader from "../../Common/CardLoader";
import { API } from "../../../Service/constants";

export const BoatOwnerDashBoard = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state?.auth);
  const [isLoading, setIsLoading] = useState("");
  const [boatListDataDetails, setBoatListDataDetails] = useState("");
  const [boatListData, setBoatListData] = useState([]);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const containerRef = useRef(null);
  const cardRef = useRef(null);
  const [emptyItems, setEmptyItems] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
    // console.log(boatListData.length);
    const validLength = Math.max(boatListData.length, 0);
    // console.log(validLength);
  }, []);
  console.log(boatListData,'boatlist data')

  //API call boat list
  useEffect(() => {
    setBoatListDataDetails("");
    setBoatListData([]);
    setIsLoading(true);
    let payload = {
      pageNo: 1,
      maxNoOfData: 6,
    };
    boat_list_filter(auth?.AuthToken, payload)
      .then((res) => {
        console.log("boat list res", res?.data);
        if (res?.data?.message === "success") {
          setBoatListDataDetails(res?.data);
          setBoatListData(res?.data?.parameters);
        } else if (res?.data?.status === 401) {
          toast.info(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        setIsLoading(false);
      })

      .catch((err) => {
        console.log("boat list error", err);
        setIsLoading(false);
      });
  }, [auth?.AuthToken]);

  //  block back btn
  useEffect(() => {
    if (auth?.AuthToken) {
      const blockBackButton = (e) => {
        e.preventDefault();
        navigate(location.pathname);
      };
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", blockBackButton);
      return () => {
        window.removeEventListener("popstate", blockBackButton);
      };
    }
  }, [auth?.AuthToken, location.pathname, navigate]);

  const handle_navigation = (name) => {
    if (name === "For Boat Rentals" || name === "For Boat Owners") {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if(name === 'My Listings'){
      navigate('/myListings')
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#f6f6f6",
          justifyContent: "space-between",
        }}
      >
        <div>
          <PageHeader
            showLoginSignUp={auth?.AuthToken ? false : true}
            handle_navigation={handle_navigation}
            presentPage={"Home"}
            link1={"For Boat Owners"}
            link2={"For Boat Rentals"}
            link3={"My Listings"}
          />

          <div className={class_name.page_top_container}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "30px",
              }}
            >
              <Typography className={class_name?.ListYourBoatandEarnMoney}>
                List Your Boat and Earn Money
              </Typography>
              <Typography
                className={class_name?.ListYourBoatandEarnMoney}
                style={{ fontWeight: "bold" }}
              >
                in 2 Steps
              </Typography>
            </div>

            <div className="upload-boat-doc">
              <div className={class_name.doc_img_div}>
                <Typography className={class_name.top_no_txt}>1</Typography>
                <img
                  alt="add_file"
                  src={IMAGES.ADD_FILES}
                  className={class_name.add_boat_deatils1}
                />
              </div>
              <div className={class_name.boat_doc}>
                <Typography className={class_name?.doc_title_txt}>
                  Upload your boat documentations
                </Typography>
                <Typography className={class_name.doc_sub_title_txt}>
                  To ensure authenticity and build trust, we kindly request boat
                  owners to upload their boat documents during the listing
                  process.
                </Typography>
              </div>
            </div>
            <div className="upload-boat-detail">
              <div className={class_name.doc_img_div}>
                <Typography className={class_name.top_no_txt}>2</Typography>
                <img
                  alt="add_file"
                  src={IMAGES.ADD_BOATE_DETAILS}
                  className={class_name.add_boat_deatils2}
                />
              </div>
              <div className={class_name.boat_doc}>
                <Typography className={class_name.doc_title_txt}>
                  Add your boat's details
                </Typography>
                <Typography className={class_name.doc_sub_title_txt}>
                  To attract potential guests, we suggest you to showcase your
                  boat, its features, amenities, and unique qualities.
                </Typography>
              </div>
            </div>
            {/* BUTTON  */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                height: "100%",
              }}
            >
              <div
                className={class_name?.get_action_btn}
                onClick={() => {
                  dispatch(boat_register_step1(null));
                  dispatch(boat_register_step2(null));
                  dispatch(boat_register_step3(null));
                  dispatch(boatTypeList(null));
                  dispatch(boatServiceList(null));
                  dispatch(single_boat_details_store(null));
                  dispatch(boat_register_location_data(null));
                  navigate("/BoatOfferStep1");
                }}
              >
                <Typography
                  className={`${class_name.get_action_txt} hoverEffect`}
                >
                  List Your Boat Now
                </Typography>

                <img
                  alt="left-arr.png"
                  src={require("../../../assets/Images/left-arr.png")}
                  className={class_name.left_arr}
                />
              </div>
            </div>

            {!isLoading && boatListData?.length > 0 ? (
              <Typography className={class_name.BestBoatOffers}>
                Best Boat Offers This Week
              </Typography>
            ) : null}
          </div>
          <div className={class_name.boat_offers}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
                flexWrap: "wrap",
                justifyContent: width > 930 ? "space-between" : "space-evenly",
              }}
              ref={containerRef}
            >
              {isLoading ? (
                <>
                  <CardLoader />
                  <CardLoader />
                </>
              ) : (
                <>
                  {boatListData?.map((item, index) => {
                    return (
                      <div
                        className={class_name.boat_card_space}
                        key={index}
                        onClick={() => {
                          navigate(`/boatData/${item?.dataValues?.boat_uuid}`);
                          dispatch(search_boat_id(item?.dataValues?.boat_id));
                        }}
                        ref={cardRef}
                      >
                        <BoatDetailCard
                          profile_image={`
                          ${
                            item?.dataValues?.front_image 
                          }`} //${API.baseUrls[API.currentEnv]}
                          boatName={item?.dataValues?.boat_name}
                          marine_city={item?.dataValues?.marine_city}
                          starRating={item?.dataValues?.averageRating}
                          pricePerHour={item?.dataValues?.price_per_hour}
                          priceCurrency={item?.dataValues?.price_currency}
                          boatMaxCapacity={item?.dataValues?.boat_max_capacity}
                          cardRef={cardRef}
                          containerRef={containerRef}
                          boatListData={boatListData}
                          setEmptyItems={setEmptyItems}
                        />
                      </div>
                    );
                  })}

                  {[...Array(emptyItems)].map((_, index) => {
                    return (
                      <div
                        key={index}
                        style={
                          width <= 790
                            ? {
                                width: width / 2.4,
                              }
                            : width <= 1024
                            ? { width: 280 }
                            : width <= 1380
                            ? {
                                width: 300,
                              }
                            : {
                                width: 350,
                              }
                        }
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="footer-style-hide">
          <Footer />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  page_top_container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f6f6f6",
    padding: "1% 5%",
    [theme.breakpoints.up("sm")]: {
      padding: "1% 5%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "1% 5%",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "1% 10%",
    },
  },
  ListYourBoatandEarnMoney: {
    fontSize: "clamp(16px, 4vw, 38px)",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.87)",
  },
  //
  get_action_btn: {
    marginTop: "5%",
    cursor: "pointer",
    borderRadius: "30px",
    border: "solid 1.5px #026b93",
    padding: "2% 5%",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    transition: "background-color 0.3s", // Add a smooth transition for the background color
    "&:hover": {
      backgroundColor: "#026b93", // Change the background color on hover
      "& $get_action_txt": {
        color: "white", // Change text color on hover
      },
      "& $left_arr": {
        filter: "brightness(0) invert(1)", // Change image color on hover
      },
    },
  },
  get_action_txt: {
    fontSize: "clamp(12px, 2vw, 24px)", // Adjust the range as needed
    fontWeight: "500",
    textAlign: "center",
    color: "#026b93",
  },

  boat_doc: {
    width: "100%",
    minWidth: "50%",
    paddingLeft: "16px",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "30px",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "50px",
      width: "80%",
      minWidth: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "60px",
      width: "70%",
    },
  },

  doc_title_txt: {
    fontSize: "clamp(14px, 2.7vw, 24px)", // Adjust the range as needed
    fontWeight: "600",
    lineHeight: 1.53,
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.87)",
  },

  doc_sub_title_txt: {
    fontSize: "clamp(10px, 2vw, 16px)", // Adjust the range as needed
    fontWeight: "600",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.6)",
  },

  doc_img_div: {
    position: "relative",
    padding: "2.5% 1.5%",
    [theme.breakpoints.up("sm")]: {
      padding: "2.5% 1.5%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2.5% 1.5%",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "2.5% 1.5%",
    },
    borderRadius: "500px",
    alignSelf: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "white",
  },

  add_boat_deatils1: {
    width: "clamp(63px, 15vw, 100px)", // Adjust the range as needed
    height: "clamp(56.5px, 11vh, 80px)", // Adjust the range as needed
  },

  add_boat_deatils2: {
    width: "clamp(55px, 15vw, 115px)",
    height: "clamp(35px, 11vh, 106px)",
  },

  left_arr: {
    width: "22px",
    height: "15px",
    alignSelf: "center",
    marginLeft: "15px",
  },

  BestBoatOffers: {
    fontSize: "clamp(14px, 5vw, 48px)",
    fontWeight: "600",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.87)",
    marginTop: "10%",
  },

  boat_offers: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "#f6f6f6",
    padding: "1% 5%",
    [theme.breakpoints.up("sm")]: {
      padding: "1% 5%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "1% 5%",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "1% 10%",
    },
  },

  boat_card_space: {
    margin: "2% 0",
  },

  showEmptyCard: {
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    margin: "0px",
    boxSizing: `border-box`,
    overflow: `hidden`,
    height: "auto",
    border: "solid 0.5px rgba(66, 70, 81, 0.4)",
    backgroundColor: "#ffffff",
    margin: "2% 0",
  },

  top_no_txt: {
    position: "absolute",
    top: "-10%",
    left: "5%",
    fontSize: "clamp(10px, 4vw, 44px)",
    fontWeight: "bold",
    color: "rgba(66, 70, 81, 0.87)",

    [theme.breakpoints.down(767)]: {
      top: "-15%",
      left: "20%",
      fontSize: "clamp(10px, 3.5vw, 44px)",
    },
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    add_boat_deatils1: {
      width: "clamp(21px, 15vw, 42px)", // Adjust the range as needed
      height: "clamp(19px, 8vh, 38px)", // Adjust the range as needed

      [theme.breakpoints.up("sm")]: {
        width: "clamp(63px, 13vw, 76px)",
        height: "clamp(56.5px, 8vh, 113px)",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {
        width: "clamp(63px, 15vw, 116px)",
        height: "clamp(56.5px, 11vh, 113px)",
        backgroundColor: "yellow",
      },
    },

    add_boat_deatils2: {
      width: "clamp(27.5px, 15vw, 42px)",
      height: "clamp(17.5px, 8vh, 35px)",

      [theme.breakpoints.up("sm")]: {
        width: "clamp(63px, 13vw, 76px)",
        height: "clamp(56.5px, 8vh, 113px)",
      },
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("lg")]: {
        width: "clamp(55px, 15vw, 115px)",
        height: "clamp(35px, 11vh, 106px)",
        backgroundColor: "yellow",
      },
    },
    left_arr: {
      width: "9px",
      height: "6px",
      alignSelf: "center",
      marginLeft: "10px",
    },
  },
}));
