import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import IMAGES from "../../Images";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { set_new__password } from "../../../Service/api";
import Loader from "../../Loader";
import "./ChangePassword.css";
import { Container } from "react-bootstrap";
import { InputLabel, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useWindowDimensions from "../../../UI kit/useWindowDimensions";

const start_space_Validation = new RegExp(/^(?!\s).*/);

const styles = (theme) => ({
  root: {

    "& input::placeholder": {
      fontSize: "clamp(10px, 5vw, 16px)",
      color: "rgba(66, 70, 81, 0.4)",
    },
    [theme.breakpoints.down("sm")]: {
      "& input::placeholder": {
        fontSize: "13px", // Font size for small screens
      },
    },
    [theme.breakpoints.between("sm", "md")]: {
      "& input::placeholder": {
        fontSize: "14px", // Font size for medium-sized screens
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& input::placeholder": {
        fontSize: "18px", // Font size for large screens
        top: "10px",
      },
      padding: "0px",
    },
  },
});
const CustomTextField = withStyles(styles)(TextField);

const regexPatterns = [
  /[a-z]/, // At least 1 lowercase letter
  /[A-Z]/, // At least 1 uppercase letter
  /[0-9]/, // At least 1 number
  /[!@#$%^&*]/, // At least 1 special character
];

export const ChangePassword = () => {
  const { width } = useWindowDimensions()
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const user = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorPwdNotSame, setErrorPwdNotSame] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [strengthIndicator, setStrengthIndicator] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    let conditionsMet = 0;
    regexPatterns.forEach((pattern) => {
      if (pattern.test(password)) {
        conditionsMet++;
      }
    });
    // Determine the password strength indicator based on the number of conditions met
    if (password.length >= 8 && conditionsMet === 4) {
      setStrengthIndicator("green"); // All conditions met
    } else if (password.length >= 8 && conditionsMet >= 3) {
      setStrengthIndicator("yellow"); // At least 4 conditions met
    } else {
      setStrengthIndicator("red"); // Less than 3 conditions met
    }
  }, [password]);

  useEffect(() => {
    if (password === confirmPassword) {
      setErrorPwdNotSame(false);
    } else {
      setErrorPwdNotSame(true);
    }
  }, [confirmPassword, password]);

  const handleSubmit = (values) => {
    toast.dismiss();
    setErrorMsg("");
    if (password !== "") {
      if (password?.length >= 8) {
        if (confirmPassword === password) {
          let payload = {
            email: user?.emailId,
            password: password,
          };
          console.log("payload", payload);
          setIsLoader(true);
          set_new__password(payload)
            .then((res) => {
              console.log("res", res);
              if (res?.data?.message === "Password updated successfully") {
                toast.success(res?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                navigate("/logIn");
                setIsLoader(false);
              } else {
                console.log("enter else");
                setErrorMsg(res?.data?.message);
                toast.error(res?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                setIsLoader(false);
              }
            })
            .catch((err) => {
              console.log("err", err);
              setIsLoader(false);
            });
        } else {
          toast.error(
            "Please enter your confirm password, Password must be same",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
        }
      } else {
        toast.error("The minimum password length is 8 characters ", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } else {
      toast.error("Please enter your password", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  return (
    <div className={class_name.pwd_full_box}>
      {isLoader ? <Loader loading={isLoader} /> : null}

      <div className={class_name.icon_and_back}>
        <div
          className={class_name.top_back_button}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            src={IMAGES.LEFT_BACK_BUTTON}
            className={class_name.back_iocn}
            alt="Back"
          />
        </div>
        <div className={class_name.appIcon_div}>
          <img src={IMAGES.APP_ICON} alt="ICON" className={class_name.appIcon} />
        </div>
      </div>

      <div className={class_name.page_body_container}>
        <div className={class_name.form_container_box}>
          <div
            onClick={() => {
              navigate(-1);
            }}
            className={class_name.with_txt_back_icon}
          >
            <img
              src={IMAGES.LEFT_BACK_BUTTON}
              className={class_name.back_iocn}
              alt="Back"
            />
            <Typography className={class_name.back_txt}>Back</Typography>
          </div>
          <div className={class_name.inside_body}>
            <Typography className={class_name.pageTitle_pwd}>
              Change Password
            </Typography>

            <Typography className={class_name.pwd_info_txt}>
              Enter new password
            </Typography>

            {/* Password */}
            <div className={class_name.pwd_txt_filed}>
              <InputLabel className={class_name.label}>Password</InputLabel>
              <CustomTextField
                type={showPassword ? "text" : "password"}
                margin="normal"
                fullWidth
                name="password"
                placeholder="Password"
                value={password}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  if (start_space_Validation.test(inputValue)) {
                    setPassword(inputValue);
                  }
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    backgroundColor: "white",
                    borderRadius: "5px",
                  },
                  endAdornment: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "8px",
                      }}
                    >
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  className: "input-txt",
                }}
                className={class_name.text_fileds_box}
                style={{
                  width: "100%",
                  border: width <= 766? 'none':"1px solid rgba(66, 70, 81, 0.6)",
                  borderRadius: '5px',
                  padding: "10px",
                  marginTop: '6px',
                  backgroundColor: 'white'
                }}
              />
              {password ? (
                <>
                  {strengthIndicator !== "green" ? (
                    <div className={class_name.small_pwd_strength_div}>
                      <Typography
                        className={class_name.small_pwd_strength_info}
                        style={{ color: "red" }}
                      >
                        The minimum password length is 8 characters and must
                        contain at least 1 lowercase letter, 1 uppercase letter, 1
                        number and 1 special character.
                      </Typography>
                    </div>
                  ) : (
                    <div className={class_name.small_pwd_strength_div}>
                      <Typography
                        className={class_name.small_pwd_strength_info}
                        style={{ color: "green" }}
                      >
                        Your password is strong and secure. Good job!
                      </Typography>
                    </div>
                  )}
                </>
              ) : null}
            </div>

            <div className={class_name.pwd_txt_filed}>
              {/* Confirm Password */}
              <InputLabel className={class_name.label}>
                Confirm Password
              </InputLabel>
              <CustomTextField
                type="password"
                margin="normal"
                fullWidth
                name="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  if (start_space_Validation.test(inputValue)) {
                    setConfirmPassword(inputValue);
                  }
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    // backgroundColor: "white",
                    // borderRadius: "5px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                className={class_name.text_fileds_box}
                style={{
                  width: "100%",
                  border: width <= 766? 'none':"1px solid rgba(66, 70, 81, 0.6)",
                  borderRadius: '5px',
                  padding: "10px",
                  marginTop: '6px',
                  backgroundColor: 'white'
                }}
                classes={class_name.password_input}
                // inputProps={{
                //   className: class_name.text_field_inputs,
                //   // style: { backgroundColor: "red" },
                // }}
              />
              {errorPwdNotSame && confirmPassword !== "" ? (
                <Typography className={class_name.err_style_txt}>
                  {"Password must be same"}
                </Typography>
              ) : null}
            </div>
            {/* <div className={class_name.big_pwd_strength_div}>
              {password ? (
                <div className="pwd-strength-div">
                  <div>
                    <Typography style={styles.pwdStrengthTxt}>
                      Password Strength{" "}
                      <Typography
                        style={{
                          fontSize: 10,
                          fontFamily: "Poppins",
                          color:
                            strengthIndicator === "red"
                              ? "red"
                              : strengthIndicator === "yellow"
                              ? "#FFAA33"
                              : strengthIndicator === "green"
                              ? "green"
                              : "white",
                        }}
                      >
                        {strengthIndicator === "red"
                          ? "Poor"
                          : strengthIndicator === "yellow"
                          ? "Weak"
                          : strengthIndicator === "green"
                          ? "Good"
                          : null}
                      </Typography>
                    </Typography>
                    <div style={styles.pwdInstructionDev}>
                      <div
                        style={{
                          height: "5px",
                          width:
                            strengthIndicator === "red"
                              ? "30%"
                              : strengthIndicator === "yellow"
                              ? "65%"
                              : strengthIndicator === "green"
                              ? "100%"
                              : "0%",
                          backgroundColor:
                            strengthIndicator === "yellow"
                              ? "#FFAA33"
                              : strengthIndicator,
                        }}
                      />
                    </div>
                    <Typography style={styles.pwdInstructionTxt}>
                      The minimum password length is 8 characters and must
                      contain at least 1 lowercase letter, 1 capital letter, 1
                      number, and 1 special character.
                    </Typography>
                  </div>
                </div>
              ) : null}
            </div> */}
            <div
              style={{
                // backgroundColor: "lightblue",
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <div className={class_name?.get_action_btn}>
                <Typography
                  className={`${class_name.get_action_txt} hoverEffect`}
                  onClick={handleSubmit}
                >
                  Continue
                </Typography>
              </div>
            </div>

            {errorMsg ? (
              <Typography
                style={{ marginTop: "48px", color: "#DC143C", fontSize: 12 }}
              >
                {errorMsg}
              </Typography>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const stylesUSe = {
  //
  //
  //
  //
  //
  // pwdPowerDev: {
  //   borderColor: "white",
  //   borderRadius: "5px",
  //   borderWidth: 1,
  //   borderStyle: "solid",
  //   alignSelf: "center",
  //   flex: 0.5,
  //   backgroundColor: "white",
  //   position: "absolute",
  //   right: "4%",
  //   display: "flex",
  //   justifyContent: "center",
  //   padding: "10px",
  //   boxSizing: "border-box",
  //   boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.09)",
  //   width: "100%",
  //   maxWidth: "13%",
  //   height: "auto",
  //   marginTop: "10px",
  //   border: "1px solid rgba(66, 70, 81, 0.4)",
  // },
  pwdStrengthTxt: {
    color: "#424651",
    fontSize: 14,
    // fontFamily: "Poppins",
    fontWeight: "revert",
  },
  pwdInstructionDev: {
    height: "5px",
    width: "100%",
    border: "solid 0.5px rgba(66, 70, 81, 0.3)",
    marginTop: "10px",
    marginBottom: "10px",
  },
  pwdInstructionTxt: {
    color: "#424651",
    fontSize: 12,
    // fontFamily: "Poppins",
    fontWeight: "normal",
    letterSpacing: 0,
    // lineHeight: 0.2,
  },
  customTextField: {
    fontSize: "16px",
    // fontFamily: "Poppins",
    color: "#424651",
    padding: "10px",
    borderBottom: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    paddingLeft: "15px",
    height: "30px",
  },
  phoneNoStyles: {
    fontSize: "16px",
    // fontFamily: "Poppins",
    color: "#424651",
    borderBottom: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    // backgroundColor: "red",
  },
  pwdStyles: {
    fontSize: "16px",
    // fontFamily: "Poppins",
    color: "#424651",
    // borderBottom: "none",
    backgroundColor: "#fff",
    // marginTop: "-6px",
    // padding: "0px",
    borderStyle: "solid",
  },
  ErrorMsgTxt: {
    color: "#DC143C",
    fontSize: 11,
    // fontFamily: "Poppins",
    marginTop: "5px",
  },
  endContent: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    width: "55%",
    // marginTop: "15px",
    paddingBottom: "15px",
  },
  btnStyle: {
    width: "100%",
    backgroundColor: "#026b93",
    color: "white",
    fontSize: "14px",
    // fontFamily: "Poppins",
    fontWeight: "bold",
    textTransform: "none",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.09)",
    padding: "10px",
  },
  loginDev: {
    display: "flex",
    marginTop: "15px",
    flexDirection: "row",
    alignSelf: "center",
    textAlign: "center",
  },
  loginTxt: {
    marginLeft: "40px",
    color: "#424651",
    fontSize: "14px",
    // fontFamily: "Poppins",
    fontWeight: "normal",
    textDecoration: "underline",
    textAlign: "center",
  },
  alreadyAcc: {
    color: "#424651",
    fontSize: "14px",
    // fontFamily: "Poppins",
    fontWeight: "normal",
    textAlign: "center",
  },
  BoxDev: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    width: "55%",
  },
  checkBoxUncheckedstyle: {
    border: "1px solid rgba(66, 70, 81, 0.4)",
    borderRadius: "1px",
    width: "22px",
    height: "22px",
  },
  checkBoxcheckedstyle: {
    color: "#424651",
    border: "1px solid rgba(66, 70, 81, 0.4)",
    borderRadius: "1px",
    width: "22px",
    height: "22px",
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
  },
};

const useStyles = makeStyles((theme) => {
  return {
    pwd_full_box: {
      width: "100%",
      height: "100vh",
      backgroundColor: "#f6f6f6",
      overflow: 'hidden'
      // paddingBottom: "100px",
    },
    page_body_container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      // height: "100%",
      backgroundColor: "#f6f6f6",
      paddingBottom: "100px",
      // backgroundColor: "red",
    },

    form_container_box: {
      display: "flex",
      // marginTop: "100px",
      justifyContent: "center",
      alignSelf: "center",
      alignContent: "center",
      height: "100%",
      // height: "auto",
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      border: "solid 0.5px rgba(66, 70, 81, 0.1)",
      padding: "1% 1%",
      borderRadius: "2px",
      width: "90%",
      marginTop: "15px",
      [theme.breakpoints.up("sm")]: {
        marginTop: "30px",
        width: "90%",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "30px",
        width: "80%",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "40px",
        width: "70%",
      },
      [theme.breakpoints.down('767')]:{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: 'none'
      }
    },
    // body_content: { display: "flex", height: "90%", width: "100%" },
    container_body: {
      backgroundColor: "white",
      display: "flex",
      height: "100%",
      width: "100%",
    },
    icon_and_back: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    appIcon_div: {
      width: '100%',
      display: 'flex',
      paddingLeft: '0%',
      [theme.breakpoints.down('767')]:{
        justifyContent: 'center',
        paddingLeft: '0%',
        paddingRight: '13%'
      }
    },
    appIcon: {
      marginLeft: "140px",
      width: "clamp(136px, 23vw, 198px)",
      height: "clamp(52px, 13vw, 98px)",
    },
    top_back_button: {
      display: "none",
    },
    inside_body: {
      display: "flex",
      flexDirection: "column",
      // justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      width: "100%",
      height: "100%",
    },
    back_iocn: { width: "14px", height: "18px" },
    with_txt_back_icon: {
      display: "flex",
      flexDirection: "row",
      cursor: "pointer",
      alignItems: "center",
      alignSelf: "flex-start",
      margin: "20px 10px",
      // marginTop: "20px",
    },
    back_txt: {
      marginLeft: "20px",
      fontSize: "clamp(10px, 5vw, 20px)",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#3973a5",
    },
    pageTitle_pwd: {
      fontSize: "clamp(16px, 7vw, 48px)",
      fontWeight: "bolder",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      // height: "auto",
      color: "rgba(66, 70, 81, 1)",
    },
    pwd_info_txt: {
      fontSize: "clamp(10px, 5vw, 20px)",
      textAlign: "center",
      color: "rgba(66, 70, 81, 0.6)",
      width: "70%",
      marginTop: "5%",
      [theme.breakpoints.down('480')]: {
        marginTop: '10%'
      }
    },

    pwd_txt_filed: {
      width: "60%",
      marginTop: "5%",
      [theme.breakpoints.down('480')]: {
        marginTop: '10%'
      }
    },

    label: {
      fontSize: "clamp(12px, 3vw, 20px)",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "rgba(66, 70, 81, 0.8)",
      // width: "70%",
      textAlign: "left",
      marginLeft: "10px",
    },

    text_fileds_box: {
      // margin: "0px",
      // width: "45%",
      // backgroundColor: "white",
      // paddingRight: ({ min, max, unit }) =>
      //   `clamp(${min}${unit}, calc(${min}${unit} + (${max} - ${min}) * ((100vw - 300px) / (1600 - 300))), ${max}${unit})`,
      borderRadius: "10px",
      border: "1px solid rgba(66, 70, 81, 0.4)",
    },

    text_field_inputs: {
      fontSize: "clamp(12px, 3vw, 24px)",
      // fontFamily: "Poppins",
      color: "#424651",
      // margin: "30px 0px 30px 60px",
      padding: ({ min, max, unit }) =>
        `clamp(${min}${unit}, calc(${min}${unit} + (${max} - ${min}) * ((100vw - 300px) / (1600 - 300))), ${max}${unit})`,
      border: "none",
      borderRadius: "5px",
      height: "25px",
      // backgroundColor: "red",
    },
    get_action_btn: {
      marginTop: "5%",
      borderRadius: "5px",
      border: "solid 2.5px #026b93",
      padding: "1% 7%",
      display: "flex",
      // justifyContent: "space-between",
      // alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      transition: "background-color 0.3s",
      backgroundColor: "#026b93",
      "&:hover": {
        backgroundColor: "#026b93",
        boxShadow: "1px 1px 2px #026b93",
        "& $get_action_txt": {
          color: "white", // Change text color on hover
        },
        "& $left_arr": {
          filter: "brightness(0) invert(1)", // Change image color on hover
        },
      },
      [theme.breakpoints.down('767')]:{
        width: '100%',
        // marginTop: '10%',
        padding: '2% 7%'
      },
      [theme.breakpoints.down('480')]: {
        marginTop: '10%',
        padding: '4% 7%'
      },
    },
    get_action_txt: {
      width: '100%',
      fontSize: "clamp(16px, 2vw, 24px)",
      fontWeight: "inherit",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.96,
      letterSpacing: "normal",
      textAlign: "center",
      // color: "#026b93",
      color: "white",
    },
    password_input: {
      marginTop: '5px'
    },
    err_style_txt: {
      color: "#DC143C",
      fontSize: "clamp(10px, 1vw, 12px)",
      // marginTop: "5px",
    },

    small_pwd_strength_div: {
      // display: "none",
      display: "flex",
    },
    big_pwd_strength_div: {
      display: "flex",
    },
    small_pwd_strength_info: {
      fontSize: "clamp(10px, 1.5vw, 14px)",
      fontWeight: "inherit",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.96,
      letterSpacing: "normal",
      textAlign: "start",
      // color: "#026b93",
    },
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //  ==============================    max-width: 767
    //
    //
    //
    //
    //
    //
    //
    //
    //
    "@media (max-width: 767px)": {
      form_container_box: {
        // height: "auto",
        borderRadius: "15px",
      },
      icon_and_back: {
        padding: "16px 16px 0px 16px",
      },
      appIcon: {
        marginLeft: "24px",
        width: "clamp(96px, 25vw, 165px)",
        height: "clamp(48px, 15vw, 98px)",
      },

      top_back_button: {
        display: "flex",
        alignSelf: "center",
      },
      with_txt_back_icon: {
        display: "none",
      },
      inside_body: {
        // justifyContent: "space-around",
        // justifyContent: "space-between",
        // justifyContent: "center",
      },
      // get_action_btn: {
      //   border: "solid 2.5px #026b93",
      //   padding: "1% 7%",
      //   display: "flex",
      //   justifyContent: "space-between",
      //   alignContent: "center",
      //   alignItems: "center",
      //   alignSelf: "center",
      //   transition: "background-color 0.3s",
      //   "&:hover": {
      //     backgroundColor: "#026b93",
      //     "& $get_action_txt": {
      //       color: "white", // Change text color on hover
      //     },
      //     "& $left_arr": {
      //       filter: "brightness(0) invert(1)", // Change image color on hover
      //     },
      //   },
      // },
      // get_action_txt: {
      //   fontSize: "clamp(8px, 5vw, 24px)", // Adjust the range as needed
      // },
      pwd_txt_filed: {
        width: "90%",
      },

      small_pwd_strength_div: {
        display: "flex",
        // /* background-color: lightcyan; */
        marginTop: "2px",
        // justifyContent: "center",
        // alignItems: "flex-start",
        // alignSelf: "flex-start",
        // alignContent: "flex-start",
        width: "90%",
        // backgroundColor: "lightblue",
      },
      big_pwd_strength_div: {
        display: "none",
      },
    },
  };
});
