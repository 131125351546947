import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  },
}));

const Loader = ({ loading,content }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit" />
      {content?
        <span>This process will take some time</span>:''
      }
    </Backdrop>
  );
};

export default Loader;
