import React from "react";
import { FaMoneyBill } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { Skeleton } from "@mui/material";

const localImageBox = [
  {
    id: 1,
    boat_name: "Peace Boat",
    marine_city: "Russel Madam",
    starCount: 4,
    moneyIcon: FaMoneyBill,
    price_per_hour: "55000",
    price_currency: "SAR",
    userGroup: FaUsers,
    boat_max_capacity: "15",
  },
  {
    id: 2,
    boat_name: "Al Madina Boat",
    marine_city: "Yanbu",
    starCount: 4,
    moneyIcon: FaMoneyBill,
    price_per_hour: "6000",
    price_currency: "SAR",
    userGroup: FaUsers,
    boat_max_capacity: "15",
  },
  {
    id: 3,
    boat_name: "Russel Madam",
    marine_city: "Riyadh",
    starCount: 5,
    moneyIcon: FaMoneyBill,
    price_per_hour: "5000",
    price_currency: "SAR",
    userGroup: FaUsers,
    boat_max_capacity: "12",
  },
];

const CardLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexWrap: "wrap",
        justifyContent: "space-between", // Distribute items evenly in a row
        // backgroundColor: "gray",
      }}
    >
      {localImageBox?.map((item) => (
        <div key={item.id} style={{ margin: "1%", width: "30%" }}>
          {/* Adjust the width here */}
          <Skeleton variant="rect" width="100%" height={300} animation="wave" />
          <Skeleton
            variant="text"
            width="80%"
            height={20}
            animation="wave"
            style={{ marginTop: "10px" }}
          />
          <Skeleton
            variant="text"
            width="80%"
            height={20}
            animation="wave"
            style={{ marginTop: "5px" }}
          />
        </div>
      ))}
    </div>
  );
};

export default CardLoader;
