import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserType, signUp_payload } from "../../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../Images";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";

export const UserChoice = () => {
  const user = useSelector((state) => state?.auth);
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const [userType, setUserType] = useState(user?.userType ?? "");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUser = (values) => {
    setUserType(values);
  };

  // useEffect(() => {
  //   dispatch(signUp_payload(null));
  // }, []);

  return (
    <>
      <div className={class_name.page_container}>
        <div className={class_name.show_app_icon_box}>
          <img
            src={IMAGES.APP_ICON}
            alt="ICON"
            className={class_name.app_icon_style}
          />
        </div>

        <div className={class_name.body_content}>
          <div className={class_name.inside_body_content}>
            <Typography className={class_name.choice_title_txt}>
              Join Us In Saudia Ocean Hub
            </Typography>
            <div
              className={class_name.contain_two_option_boxes}
              // style={{ backgroundColor: "red" }}
            >
              {/* ==================================================== owner =============================================== */}
              <div
                onClick={() => {
                  handleUser("BOAT_OWNER");
                }}
                className={class_name.options_box}
              >
                <div className={class_name.dot_box}>
                  <div
                    style={{
                      backgroundColor:
                        userType === "BOAT_OWNER"
                          ? "rgba(2, 107, 147, 1)"
                          : "white",
                    }}
                    className={class_name.dot_circle}
                  />
                </div>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <div className={class_name.text_box}>
                    <Typography className={class_name.option_txt_style}>
                      I am a Boat Owner, offering my boat
                    </Typography>
                  </div>
                </div>
              </div>

              {/* ==================================================== customer =============================================== */}
              <div
                onClick={() => {
                  handleUser("CUSTOMER");
                }}
                className={class_name.options_box}
              >
                <div className={class_name.dot_box}>
                  <div
                    style={{
                      backgroundColor:
                        userType === "CUSTOMER"
                          ? "rgba(2, 107, 147, 1)"
                          : "white",
                    }}
                    className={class_name.dot_circle}
                  />
                </div>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <div className={class_name.text_box}>
                    <Typography className={class_name.option_txt_style}>
                      I am a Customer, looking for a new experience
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            {/* ==================================================== Log In =============================================== */}
            <div className={class_name.end_content_apply_btn_and_login}>
              <div
                style={{
                  backgroundColor:
                    userType === "" ? "rgba(151, 151, 151, 1)" : "#026b93",
                }}
                className={class_name?.list_boat_btn}
                onClick={() => {
                  if (userType !== "") {
                    dispatch(UserType(userType));
                    navigate("/SignUp");
                  } else {
                    toast.dismiss();
                    toast.info("Please select user type", {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 2000,
                    });
                  }
                }}
              >
                <Typography
                  className={`${class_name.list_boat_txt} hoverEffect`}
                >
                  Apply
                </Typography>
              </div>

              <div className={class_name.end_content_login}>
                <Typography className={class_name.login_txt}>
                  Already have an account?
                </Typography>
                <Typography
                  onClick={() => {
                    navigate("/LogIn");
                  }}
                  className={class_name.login_txt}
                  style={{
                    textDecoration: "underline",
                    marginLeft: "5%",
                    cursor: "pointer",
                  }}
                >
                  Log in
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  page_container: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "#f6f6f6",
  },

  show_app_icon_box: {
    backgroundColor: "transparent",
    marginLeft: "10%",
  },

  app_icon_style: {
    width: "clamp(136px, 23vw, 198px)",
    height: "clamp(52px, 13vw, 98px)",
  },

  body_content: {
    display: "flex",
    // height: "100%",
    flexDirection: "column",
    justifyContent: "center",
  },

  inside_body_content: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    margin: "0% 10% 5%",
    padding: "3% 10%",
    justifyContent: "center",
  },

  choice_title_txt: {
    fontSize: "clamp(14px, 3.5vw, 48px)", // Adjust the range as needed
    fontWeight: "bolder",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.87)",
  },

  contain_two_option_boxes: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "5%",
    // backgroundColor: "red",
  },

  options_box: {
    display: "flex",
    flexDirection: "column",
    padding: "1%",
    cursor: "pointer",
    border: "solid 1px lightgray",
    margin: "2%",
    maxHeight: "100%",
    maxWidth: "30%",
  },

  dot_box: {
    display: "flex",
    alignSelf: "flex-end",
    backgroundColor: "white",
    borderRadius: "30px",
    border: "solid 1px #707070",
  },

  dot_circle: {
    width: "100px",
    height: "100px",
    borderRadius: "30px",
    alignSelf: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "15px",
      height: "15px",
    },
    [theme.breakpoints.up("md")]: {
      width: "20px",
      height: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20px",
      height: "20px",
    },
  },

  text_box: {
    width: '100%',
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "10%",
  },

  option_txt_style: {
    // fontSize: "clamp(10px, 1.1vw, 24px)", // Adjust the range as needed
    fontSize: '12px',
    fontWeight: "500",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.87)",
    // [theme.breakpoints.down('766')]:{
    //   fontSize: '100px'
    // }
  },

  end_content_apply_btn_and_login: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "10%",
  },

  list_boat_btn: {
    cursor: "pointer",
    padding: "2%",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
    alignItems: "center",
    borderRadius: "5px",

    [theme.breakpoints.down("sm")]: {
      borderRadius: "10px",
    },
    [theme.breakpoints.up("md")]: {
      borderRadius: "15px",
    },
    [theme.breakpoints.up("lg")]: {
      borderRadius: "15px",
    },

    border: "solid 1px white",
    backgroundColor: "#026b93",
    transition: "background-color 0.3s", // Add a smooth transition for the background color
    "&:hover": {
      backgroundColor: "white", // Change the background color on hover
      border: "solid 1px #026b93",
      "& $list_boat_txt": {
        fontWeight: "bold", // Change text color on hover
      },
    },
  },

  list_boat_txt: {
    fontSize: "clamp(6px, 1.5vw, 24px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.96,
    letterSpacing: "normal",
    textAlign: "center",
    color: "white",
  },

  end_content_login: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: "3%",
    // backgroundColor: "lightblue",
  },

  login_txt: {
    fontSize: "clamp(14px, 1.5vw, 24px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.87)",
  },

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    show_app_icon_box: {
      backgroundColor: "white",
      margin: "0%",
    },
    app_icon_style: {
      width: "clamp(96px, 25vw, 165px)", // Adjust the range as needed
      height: "clamp(48px, 15vw, 98px)", // Adjust the range as needed
      margin: "16px",
    },
    body_content: {
      display: "flex",
      // height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      flex: 1,
    },

    inside_body_content: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#f6f6f6",
      //   backgroundColor: "red",
      margin: "0%",
      padding: "3% 10%",
      // padding: "0",
      // backgroundColor: "pink",
      justifyContent: "center",
      // alignContent: "center",
      //   alignSelf: "center",
      //   alignItems: "center",
      height: "100%",
    },

    contain_two_option_boxes: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginTop: "5%",
      // backgroundColor: "red",
    },

    options_box: {
      display: "flex",
      justifyContent: "center",
      // alignContent: "center",
      // alignSelf: "center",
      // alignItems: "center",
      flexDirection: "row-reverse",
      padding: "5%",
      cursor: "pointer",
      border: "solid 1px lightgray",
      margin: "2%",
      maxHeight: "100%",
      maxWidth: "100%",
      // paddingBottom: "10%",
      [theme.breakpoints.down("400")]: {
        padding: '5% 5%',
        maxHeight: '100%'
      },
      [theme.breakpoints.down("300")]: {
        padding: "5% 5%",
        maxHeight: '100%'
      },
    },

    text_box: {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      alignContent: "flex-start",
      alignItems: "flex-start",
      // height: "100%",
      height: "auto",
      padding: "0%",
      width: "90%",
      [theme.breakpoints.down("400")]: {
        width: "90%",
        // height: '100%'
      },
      [theme.breakpoints.down("300")]: {
        width: "90%",
        // height: '100%'
      },
      // [theme.breakpoints.up("md")]: {
      //   width: "0%",
      // },
      // [theme.breakpoints.up("lg")]: {
      //   width: "50%",
      // },
    },

    option_txt_style: {
      // fontSize: "clamp(10px, 2.1vw, 24px)", // Adjust the range as needed
      fontSize: '14px',
      fontWeight: "500",
      // fontStretch: "normal",
      // fontStyle: "normal",
      lineHeight: 1.38,
      // letterSpacing: "normal",
      textAlign: "left",
      color: "rgba(66, 70, 81, 0.87)",
    },

    dot_box: {
      display: "flex",
      alignSelf: "flex-start",
      padding: "1%",
      backgroundColor: "white",
      borderRadius: "30px",
      border: "solid 1px #707070",
    },
    dot_circle: {
      width: "10px",
      height: "10px",
      borderRadius: "30px",
      alignSelf: "flex-start",
    },

    list_boat_btn: {
      padding: "4%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignSelf: "center",
      alignContent: "center",
      alignItems: "center",
      borderRadius: "5px",
      border: "solid 1px white",
      backgroundColor: "#026b93",
      transition: "background-color 0.3s", // Add a smooth transition for the background color
      "&:hover": {
        backgroundColor: "white", // Change the background color on hover
        border: "solid 1px #026b93",
        "& $list_boat_txt": {
          fontWeight: "bold", // Change text color on hover
        },
      },
    },

    list_boat_txt: {
      fontSize: "clamp(6px, 3.5vw, 24px)", // Adjust the range as needed
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.96,
      letterSpacing: "normal",
      textAlign: "center",
      color: "white",
      // color: "red",
    },
  },
}));
