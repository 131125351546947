import { Button, IconButton, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import IMAGES from "../../Images";
import Footer from "../../../Component/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { LocationOn } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  boat_list_filter,
  boat_type,
  city_list_API,
  marine_city_list_API,
} from "../../../Service/api";
import { BoatDetailCard } from "../Card/BoatDetailCard";
import { toast } from "react-toastify";
import CardLoader from "../../Common/CardLoader";
import "./SearchBoat.css";
import { PageHeader } from "../page-header/PageHeader";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core";
import { API } from "../../../Service/constants";
import { boatTypeList, search_boat_id } from "../../../redux/slices";
import useWindowDimensions from "../../../UI kit/useWindowDimensions";
import Loader from "../../Loader";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Field } from "formik";
import moment from "moment";

const styles = (theme) => ({
  root: {
    //outer
    marginTop: "0px",
    borderRadius: "8px",
    // paddingTop: "3px",

    // Styles for the input text
    "& .MuiInputBase-input": {
      fontSize: "clamp(12px, 0.9vw, 20px)",
      // textAlign: "left",
      color: "#424651",
      borderBottom: "none",
      // padding: '7%',
      // paddingLeft: '9%',
      // [theme.breakpoints.down("992")]: {
      //   padding: '2.7%',
      //   paddingLeft: '4.7%',
      // },
      // [theme.breakpoints.down("480")]: {
      //   padding: '4.2%',
      //   paddingLeft: '6.2%',
      // },
    },
    // "& .MuiInputBase-readOnly": {
    //   border: 'none'
    // },

    // Styles for the placeholder text
    "& .MuiFormLabel-root": {
      borderBottom: "none",
      border: "none",
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      borderBottom: "none",
      fontSize: "clamp(12px, 0.9vw, 20px)",
    },

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
      fontSize: "clamp(12px, 0.9vw, 20px)",
      border: "none",
    },

    "& .MuiFormLabel-filled": {
      transform: "translateY(-1rem)",
    },

    // "& .MuiInputLabel-root": {
    //   height: '45%',
    //   display: 'flex',
    //   alignItems: 'center',
    //   [theme.breakpoints.down('992')]: {
    //     height: '50%',
    //   },
    //   [theme.breakpoints.down('480')]: {
    //     height: '40%',
    //   },
    // },
    "& .MuiSelect-select": {
      padding: "15px",
      [theme.breakpoints.down("992")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("480")]: {
        padding: "15px",
      },
    },
    // "& .MuiInputLabel-shrink": {
    //   margin: "0 auto",
    //   position: "absolute",
    //   right: "0",
    //   left: "0",
    //   top: "-3px",
    //   width: "150px", // Need to give it a width so the positioning will work
    //   background: "white" // Add a white background as below we remove the legend that had the background so text is not meshing with the border
    //   // display: "none" //if you want to hide it completly
    // },

    // Apply styles for small screens
    [theme.breakpoints.down("sm")]: {
      "& .MuiInputBase-input": {},
      // "& .MuiFormLabel-root": {
      //   borderBottom: "none",
      //   fontSize: "12px",
      // },
      // borderRadius: "8px",
    },

    // Adjust for medium screens (md)
    [theme.breakpoints.up("md")]: {
      "& .MuiInputBase-input": {},
      // borderRadius: "15px",
    },

    // Adjust for large screens (lg)
    [theme.breakpoints.up("lg")]: {
      "& .MuiInputBase-input": {},
      // borderRadius: "15px",
    },
  },
});

const CustomTextField = withStyles(styles)(TextField);

const CustomSearchTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
  },
})(TextField);

const boat_date = [
  {
    name: "July",
  },
  {
    name: "August",
  },
  {
    name: "September",
  },
  {
    name: "October",
  },
  {
    name: "November",
  },
];

const boat_price = [
  {
    name: "100-500",
    range: "100-500",
  },
  {
    name: "500-1000",
    range: "500-1000",
  },
  {
    name: "1000-1500",
    range: "1000-1500",
  },
  {
    name: "2000-2500",
    range: "2000-2500",
  },
  {
    name: "3000-3500",
    range: "3000-3500",
  },
  {
    name: "4000-4500",
    range: "4000-4500",
  },
  {
    name: ">=5000",
    range: "5000+",
  },
];

const boat_capacity = [
  {
    name: "1-10",
    range: "1-10",
  },
  {
    name: "10-20",
    range: "10-20",
  },
  {
    name: "20-30",
    range: "20-30",
  },
  {
    name: "30-40",
    range: "30-40",
  },
  {
    name: "40-50",
    range: "40-50",
  },
  {
    name: ">=50",
    range: "50+",
  },
];

export const SearchBoat = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const auth = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedBoatType, setSelectedBoatType] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedCapacity, setSelectedCapaticy] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [boatListDataDetails, setBoatListDataDetails] = useState([]);
  const [boatListData, setBoatListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { width } = useWindowDimensions();
  const containerRef = useRef(null);
  const cardRef = useRef(null);
  const [emptyItems, setEmptyItems] = useState(0);
  const [flag, setFlag] = useState(0);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [cityList, setCityList] = useState([]);
  const modalRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [arrayLen, setArrayLen] = useState(12);
  const [count, setCount] = useState(0);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("480"));

  const location = useLocation();
  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, [flag]);

  //City API call
  // useEffect(() => {
  //   setCityList([]);
  //   city_list_API()
  //     .then((res) => {
  //       // console.log("res", res?.data);
  //       if (res?.data?.success) {
  //         setCityList(res?.data?.parameters);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  //   // if(location?.state !== ""){
  //   // setSearchValue(location.state)
  //   // }
  // }, []);

  // marine city api call
  useEffect(() => {
    setCityList([]);
    marine_city_list_API()
      .then((res) => {
        console.log("marine city res", res?.data);
        if (res?.data?.success) {
          setCityList(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("marine city api err", err);
      });
  }, []);

  // console.log(boatListDataDetails,'boat list data details',boatListData.length)
  //filter city by name
  function filterDataByStartingLetter(data, startingLetter) {
    return data.filter((item) =>
      item?.marine_city?.toLowerCase().startsWith(startingLetter.toLowerCase())
    );
  }
  // console.log(auth?.AuthToken)

  useEffect(() => {
    //==================== Boat types list ====================
    boat_type(auth?.AuthToken)
      .then((res) => {
        // console.log("boat type res", res?.data);
        if (res?.data?.success) {
          dispatch(boatTypeList(res?.data?.parameters));
          setFlag(Math.random() * 10);
        } else {
        }
      })
      .catch((err) => {
        console.log("Boat Types Error : ", err);
      }, []);

    //==================== Boat lists ====================
    setBoatListDataDetails("");
    setBoatListData([]);
    setArrayLen(12);
    setIsLoading(true);
    let payload = {
      pageNo: 1,
      boat_type: selectedBoatType ? selectedBoatType : null,
      price: selectedPrice ? selectedPrice : null,
      capacity: selectedCapacity ? selectedCapacity : null,
      city: searchValue ? searchValue : null,
      date: selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : null,
      // maxNoOfData: 4,
    };
    // if (selectedBoatType) {
    //   payload = {
    //     ...payload,
    //     boat_type: selectedBoatType,
    //   };
    // }
    // if (selectedPrice) {
    //   payload = {
    //     ...payload,
    //     price: selectedPrice,
    //   };
    // }
    // if (selectedCapacity) {
    //   payload = {
    //     ...payload,
    //     capacity: selectedCapacity,
    //   };
    // }
    // if (searchValue) {
    //   payload = {
    //     ...payload,
    //     city: searchValue,
    //   };
    //   // alert(searchValue)
    // }
    // if(selectedDate){
    //   payload={
    //     ...payload,
    //     date: moment(selectedDate).format("YYYY-MM-DD")
    //   }
    // }
    console.log(payload, "payload 1");
    boat_list_filter(auth?.AuthToken, payload)
      .then((res) => {
        console.log("boat list filter res", res?.data);
        if (res?.data?.message === "success") {
          setBoatListDataDetails(res?.data);
          setBoatListData(res?.data?.parameters);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error : ", err);
        setIsLoading(false);
      });
    // };
  }, [
    auth?.AuthToken,
    dispatch,
    selectedBoatType,
    selectedCapacity,
    selectedPrice,
    selectedDate,
    searchValue
  ]);

  useEffect(() => {
    searchBoatList();
  }, [count]);

  const searchBoatList = () => {
    // console.log(searchValue,'city value',arrayLen)
    setBoatListDataDetails("");
    setBoatListData([]);
    setArrayLen(12);
    setIsLoading(true);
    let payload = {
      pageNo: 1,
      boat_type: selectedBoatType ? selectedBoatType : null,
      price: selectedPrice ? selectedPrice : null,
      capacity: selectedCapacity ? selectedCapacity : null,
      city: searchValue ? searchValue : null,
      date: selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : null,
      // maxNoOfData: 4,
    };
    // if (selectedBoatType) {
    //   payload = {
    //     ...payload,
    //     boat_type: selectedBoatType,
    //   };
    // }
    // if (selectedPrice) {
    //   payload = {
    //     ...payload,
    //     price: selectedPrice,
    //   };
    // }
    // if (selectedCapacity) {
    //   payload = {
    //     ...payload,
    //     capacity: selectedCapacity,
    //   };
    // }
    // if (searchValue) {
    //   payload = {
    //     ...payload,
    //     city: searchValue,
    //   };
    //   // alert(searchValue)
    // }
    // if (selectedDate) {
    //   payload = {
    //     ...payload,
    //     date: moment(selectedDate).format("YYYY-MM-DD"),
    //   };
    // }
    console.log(payload, "payload");
    boat_list_filter(auth?.AuthToken, payload)
      .then((res) => {
        console.log("boat list filter res", res?.data);
        if (res?.data?.message === "success") {
          setBoatListDataDetails(res?.data);
          setBoatListData(res?.data?.parameters);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error : ", err);
        setIsLoading(false);
      });
    // };
  };

  // // API Call
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    const filteredData = filterDataByStartingLetter(
      cityList,
      event.target.value
    );
    setFilteredData(filteredData);
    event.target.value.length > 0 ? setOpenModal(true) : setOpenModal(false);
    // setOpenModal(true);
  };

  const handleHeaderCallBack = (name) => {
    if (name === "Home") {
      if (auth?.tokenDecodeData?.user_type === "BOAT_OWNER") {
        navigate("/boatOwnerDashBoard");
      } else {
        navigate("/rental");
      }
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else if (name === "For Boat Rentals" || name === "For Boat Owners") {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (name === "/searchBoat") {
      navigate("/searchBoat");
    }
  };

  const handleLoadMore = () => {
    setLoadMoreLoading(true);
    // let payload = {
    //   pageNo: boatListDataDetails?.currentpage + 1,
    // };
    let payload = {
      pageNo: boatListDataDetails?.currentpage + 1,
      boat_type: selectedBoatType ? selectedBoatType : null,
      price: selectedPrice ? selectedPrice : null,
      capacity: selectedCapacity ? selectedCapacity : null,
      city: searchValue ? searchValue : null,
      date: selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : null,
      // maxNoOfData: 4,
    };
    // if (selectedBoatType) {
    //   payload = {
    //     ...payload,
    //     boat_type: selectedBoatType,
    //   };
    // }
    // if (selectedPrice) {
    //   payload = {
    //     ...payload,
    //     price: selectedPrice,
    //   };
    // }
    // if (selectedCapacity) {
    //   payload = {
    //     ...payload,
    //     capacity: selectedCapacity,
    //   };
    // }
    // if (searchValue) {
    //   payload = {
    //     ...payload,
    //     city: searchValue,
    //   };
    //   // alert(searchValue)
    // }
    // if (selectedDate) {
    //   payload = {
    //     ...payload,
    //     date: moment(selectedDate).format("YYYY-MM-DD"),
    //   };
    // }
    boat_list_filter(auth?.AuthToken, payload)
      .then((res) => {
        console.log("handleLoadMore res", res?.data);
        if (res?.data?.message === "success") {
          setBoatListDataDetails(res?.data);
          setBoatListData(boatListData?.concat(res?.data?.parameters));
          setArrayLen(arrayLen + 12);
        }
        setLoadMoreLoading(false);
      })
      .catch((err) => {
        console.log("Error : ", err);
        setLoadMoreLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "#f6f6f6",
          justifyContent: "space-between",
        }}
      >
        {/* {isLoading ? <Loader loading={isLoading} /> : null} */}
        <div style={{ height: "auto", backgroundColor: "#f6f6f6" }}>
          <div className="show-header-outSide-banner">
            <PageHeader
              showLoginSignUp={auth?.AuthToken ? false : true}
              handle_navigation={handleHeaderCallBack}
              // presentPage={"Home"}
              // link1={"Home"}
              link2={"For Boat Owners"}
              link3={"For Boat Rentals"}
              link4={
                auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
                  ? "My Listings"
                  : null
              }
            />
          </div>

          <div className="show-header-inside-banner">
            <PageHeader
              showLoginSignUp={auth?.AuthToken ? false : true}
              handle_navigation={handleHeaderCallBack}
              // presentPage={"Home"}
              // link1={"Home"}
              link2={"For Boat Owners"}
              link3={"For Boat Rentals"}
              link4={
                auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
                  ? "My Listings"
                  : null
              }
            />
          </div>

          <div className={class_name.body_of_page}>
            <div
              className={class_name.search_bar_tab}
              style={{ position: "relative" }}
            >
              <div
                className={class_name.search_text_box}
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <LocationOn />
                <CustomSearchTextField
                  value={searchValue}
                  variant="outlined"
                  autoFocus={true}
                  onChange={handleSearch}
                  style={{
                    width: "100%",
                  }}
                  placeholder="Search by City"
                  inputProps={{
                    className: class_name.searchTxt,
                  }}
                />
              </div>
              <div className={class_name.search_find_btn}>
                {searchValue ? (
                  <IconButton
                    onClick={() => {
                      setOpenModal(false);
                      setSearchValue("");
                      setCount(count + 1);
                      setArrayLen(12);
                      // searchBoatList()
                    }}
                    style={{ alignSelf: "center" }}
                  >
                    <img
                      alt="cancel"
                      src={IMAGES.CANCEL}
                      className="cancel-icon"
                    />
                  </IconButton>
                ) : null}

                <div className={class_name.straight_line} />
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#3973a5",
                    color: "white",
                    width: "50%",
                    alignSelf: "center",
                    marginLeft: "5%",
                    padding: "4%",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    searchBoatList();
                  }}
                >
                  Find
                </Button>
              </div>
              {filteredData?.length > 0 && openModal ? (
                <Grid className={class_name.city_grid} ref={modalRef}>
                  <Grid
                    item
                    // xs={4}
                    style={{
                      // borderRight: "1px solid black",
                      // width: "33%",
                      // height: "200px",
                      overflowY: "auto",
                    }}
                  >
                    {filteredData?.map((item, index) => {
                      // console.log("item", item);
                      return (
                        <Typography
                          className={class_name.cityOptions}
                          onClick={() => {
                            setSearchValue(item?.marine_city);
                            setOpenModal(false);
                          }}
                        >
                          {item?.marine_city}
                        </Typography>
                      );
                    })}
                  </Grid>
                </Grid>
              ) : null}
            </div>
            {/* ================================================================================   filter options */}
            <div className={class_name.suggestion_box_content}>
              <div
                style={{ marginLeft: "0" }}
                className={class_name.filter_box_div}
              >
                <CustomTextField
                  label={"Trip / Watersport Types"}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="boat_type"
                  name="boat_type"
                  // placeholder="Trip / Watersport Types"
                  value={selectedBoatType}
                  onChange={(e) => {
                    setSelectedBoatType(e.target.value);
                  }}
                  onTouchStart={() => setOpenModal(false)}
                  select
                  InputProps={{
                    style: {
                      ...textFieldStyles,
                      borderRadius: width >= 480 ? "15px" : "8px",
                    },
                  }}
                  InputLabelProps={{
                    // className: class_name.inputlabel_style,
                    style: {
                      transform: selectedBoatType ? "translateY(-1.2rem)" : null,
                      fontSize: "clamp(12px, 0.9vw, 20px)",
                      color: "black",
                      // paddingLeft: "3%",
                    },
                  }}
                >
                  {auth.boatType?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item?.label}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "5px 10px",
                      }}
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </div>

              <div style={{}} className={class_name.filter_box_div}>
                {/* <CustomTextField 
                  label={isSmallScreen && !selectedDate?"Date":null}
                  variant="outlined"
                  // label="date"
                  type="date"
                  margin="normal"
                  fullWidth
                  id="date"
                  name="date"
                  value={selectedDate}
                  onTouchStart={()=>setOpenModal(false)}
                  onChange={(e) => {
                    setSelectedDate(e.target.value);
                  }}
                  InputProps={{ 
                    // readOnly: true,
                    // inputComponent: 'none',
                    // inputMode: 'none',
                    style: {...textFieldStyles,paddingRight: '3%'},
                    className: class_name.date_input,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          display: isSmallScreen? 'flex': 'none',
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: "2%",
                        }}
                      >
                        <IconButton
                        >
                          <img
                            alt="down"
                            src={down_arrow_2}
                            style={{ width: "10px", height: "10px" }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                   }}
                  InputLabelProps={{
                    style: {
                      transform: selectedDate ? "translateY(-1rem)" : null,
                      fontSize: "clamp(12px, 0.9vw, 20px)",
                      color: "black",
                      textAlign: 'left',
                      paddingLeft: '3%'
                      // paddingLeft: '4%'
                      // paddingLeft: "4%",
                    },
                  }}
                >
                </CustomTextField> */}
                <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                  <MobileDatePicker
                    label={selectedDate ? "Date" : null}
                    sx={{
                      "& fieldset": { border: "none" },
                      "& .Mui-readOnly": {
                        padding: "8px",
                        paddingRight: "15px",
                      },
                    }}
                    // className={{root:class_name.date_picker}}
                    disablePast={true}
                    format="DD-MM-YYYY"
                    // placeholder="date"
                    value={selectedDate}
                    onChange={(datevalue) => {
                      setSelectedDate(datevalue.$d);
                    }}
                    slotProps={{
                      field: {
                        // format: false,
                        // label: "date"
                        InputProps: {
                          endAdornment: (
                            <img
                              src={IMAGES.DOWN_ARROW_2}
                              style={{ width: "10px", height: "10px" }}
                            />
                          ),
                        },
                      },
                      textField: {
                        InputProps: {
                          style: {
                            fontSize: "13px",
                            borderRadius: width >= 480 ? "15px" : "8px",
                            ...textFieldStyles,
                            marginBottom: "8px",
                            // padding: '10px'
                            // paddingRight: '13px'
                          },
                        },
                        InputLabelProps: {
                          style: {
                            transform: selectedDate
                              ? "translateY(-1.2rem)"
                              : null,
                            fontSize: "clamp(12px, 0.9vw, 20px)",
                            color: "black",
                            // paddingLeft: "3%",
                          },
                        },
                        error: false,
                        style: {
                          // className: class_name.date_picker,
                          width: "100%",
                          // padding: '10px'
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div style={{}} className={class_name.filter_box_div}>
                <CustomTextField
                  label={"Price"}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="price"
                  name="price"
                  // placeholder="price"
                  value={selectedPrice}
                  onTouchStart={() => setOpenModal(false)}
                  onChange={(e) => {
                    setSelectedPrice(e.target.value);
                  }}
                  select
                  InputProps={{
                    style: {
                      ...textFieldStyles,
                      borderRadius: width >= 480 ? "15px" : "8px",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      transform: selectedPrice ? "translateY(-1.2rem)" : null,
                      fontSize: "clamp(12px, 0.9vw, 20px)",
                      color: "black",
                      // paddingLeft: "3%",
                    },
                  }}
                >
                  {boat_price?.length > 0 ? (
                    boat_price?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.name}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          padding: "5px 10px",
                        }}
                      >
                        {item?.range}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={"index"} value={"item?.label"}>
                      Dummy
                    </MenuItem>
                  )}
                </CustomTextField>
              </div>
              <div
                style={{ marginRight: "0" }}
                className={class_name.filter_box_div}
              >
                <CustomTextField
                  label={"Capacity"}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="capacity"
                  name="capacity"
                  // placeholder="capacity"
                  value={selectedCapacity}
                  onTouchStart={() => setOpenModal(false)}
                  onChange={(e) => {
                    setSelectedCapaticy(e.target.value);
                  }}
                  select
                  InputProps={{
                    style: {
                      ...textFieldStyles,
                      borderRadius: width >= 480 ? "15px" : "8px",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      transform: selectedCapacity ? "translateY(-1.2rem)" : null,
                      fontSize: "clamp(12px, 0.9vw, 20px)",
                      color: "black",
                      // paddingLeft: "3%",
                    },
                  }}
                >
                  {boat_capacity?.length > 0 ? (
                    boat_capacity?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.name}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          padding: "5px 10px",
                        }}
                      >
                        {item?.range}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem key={"index"} value={"item?.label"}>
                      Dummy
                    </MenuItem>
                  )}
                </CustomTextField>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                className={class_name.clearFilterTxt}
                onClick={() => {
                  setArrayLen(12);
                  setSelectedBoatType("");
                  setSelectedDate("");
                  setSelectedPrice("");
                  setSelectedCapaticy("");
                  setSearchValue("");
                  setCount(0);
                  // searchBoatList()
                }}
              >
                Clear Filter
              </Typography>
            </div>
            {/* ================================================================================   Boat Lists */}
            <div className={class_name.boat_offers}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "100%",
                  flexWrap: "wrap",
                  justifyContent:
                    width > 930 ? "space-between" : "space-evenly",
                }}
                ref={containerRef}
              >
                {isLoading ? (
                  <div className={class_name.show_loader_card}>
                    <CardLoader />
                    <CardLoader />
                  </div>
                ) : (
                  <>
                    {boatListData?.length ? (
                      <>
                        {boatListData?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={class_name.boat_card_space}
                              onClick={() => {
                                navigate(
                                  `/boatData/${
                                    item?.dataValues?.boat_uuid ??
                                    item?.boat_uuid
                                  }`
                                );
                                dispatch(
                                  search_boat_id(
                                    item?.dataValues?.boat_id ?? item?.boat_id
                                  )
                                );
                              }}
                              ref={cardRef}
                            >
                              <BoatDetailCard
                                boatName={
                                  item?.dataValues?.boat_name ?? item?.boat_name
                                }
                                marine_city={
                                  item?.dataValues?.marine_city ??
                                  item?.marine_city
                                }
                                starRating={item?.dataValues?.averageRating}
                                priceCurrency={
                                  item?.dataValues?.price_currency ??
                                  item?.price_currency
                                }
                                pricePerHour={
                                  item?.dataValues?.price_per_hour ??
                                  item?.price_per_hour
                                }
                                boatMaxCapacity={
                                  item?.dataValues?.boat_max_capacity ??
                                  item?.boat_max_capacity
                                }
                                profile_image={`
                                ${
                                  item?.dataValues?.front_image ??
                                  item?.front_image
                                }`} //${API.baseUrls[API.currentEnv]}
                                cardRef={cardRef}
                                containerRef={containerRef}
                                boatListData={boatListData}
                                setEmptyItems={setEmptyItems}
                              />
                            </div>
                          );
                        })}
                        {[...Array(emptyItems)].map((_, index) => {
                          return (
                            <div
                              key={index}
                              style={
                                width <= 790
                                  ? {
                                      width: width / 2.4,
                                    }
                                  : width <= 1024
                                  ? { width: 280 }
                                  : width <= 1380
                                  ? {
                                      width: 300,
                                    }
                                  : {
                                      width: 350,
                                    }
                              }
                            ></div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div className={class_name.noDataContent}>
                          <img
                            alt="no result"
                            src={IMAGES.NO_RESULT}
                            className={class_name.noResultImg}
                          />
                          <Typography className={class_name.sryTxt}>
                            Sorry, no results found.
                          </Typography>

                          <Typography className={class_name.noResultTxt}>
                            It looks like we couldn't find any boats that match
                            your search Please try again.
                          </Typography>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className={class_name.loadMoreStyle}>
              {boatListData.length === arrayLen ? (
                // boatListData?.length < boatListDataDetails?.totalCount
                // && boatListData.length > 11
                <>
                  <div
                    className={class_name?.get_action_btn}
                    onClick={() => {
                      handleLoadMore();
                    }}
                  >
                    {loadMoreLoading ? (
                      <CircularProgress
                        size={width > 767 ? 22 : 16}
                        style={{
                          color: "white",
                        }}
                      />
                    ) : (
                      <Typography
                        className={`${class_name.get_action_txt} hoverEffect`}
                      >
                        Load more
                      </Typography>
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <div className="footer-style-hide">
          <Footer />
        </div>
      </div>
    </>
  );
};

const textFieldStyles = {
  // borderRadius: "15px",
  backgroundColor: "white",
  border: "solid 1px rgba(66, 70, 81, 0.2)",
  // padding: '10px'
  // padding: '0%'
};
const useStyles = makeStyles((theme) => ({
  boat_offers: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    backgroundColor: "#f6f6f6",
  },

  searchTxt: {
    fontSize: "16px",
    fontWeight: 600,
    textAlign: "left",
    color: "#424651",
  },
  searchBtnStyle: {
    display: "flex",
    width: "30%",
    justifyContent: "space-between",
  },
  cancelIcon: {
    width: "31px",
    height: "31px",
  },
  btnStyle: {
    backgroundColor: "#3973a5",
    color: "white",
    width: "50%",
    height: "50px",
    alignSelf: "center",
  },
  suggestionBoxContent: {
    display: "flex",
    margin: "24px 140px 0px",
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: 'center'
  },
  headerContainer: {
    width: "100%",
  },
  contentContainer: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    margin: "0px 24px",
  },
  appIcon: {
    width: "158px",
    height: "98px",
  },
  titleOptionTxt: {
    fontSize: 16,
    color: "#424651",
    fontWeight: "500",
    cursor: "pointer",
  },
  headerLeftSide: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 0.5,
  },
  actionButton: {
    fontSize: 24,
    color: "#026b93",
    fontWeight: "600",
    borderColor: "#026b93",
    borderWidth: 2,
    borderRadius: "30px",
    borderStyle: "solid",
    padding: "10px 50px",
    cursor: "pointer",
    marginLeft: "65%",
    alignItems: "center",
    width: "25%",
  },

  city_grid: {
    display: "flex",
    flexDirection: "row",
    width: "80%",
    borderRadius: "3px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    padding: "0.8% 3.7%",
    backgroundColor: "white",
    zIndex: "9999",
    position: "absolute",
    bottom: "-40%",
    left: "5%",
    // whiteSpace: 'nowrap'
    [theme.breakpoints.down("480")]: {
      width: "90%",
      bottom: "-65%",
    },
  },

  cityOptions: {
    fontSize: "16px",
    width: "100%",
    fontWeight: 500,
    textAlign: "left",
    color: "#424651",
    cursor: "pointer",
  },

  pageTopContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
  },
  subtitleTxt: {
    fontSize: "28px",
    color: "#424651",
    textAlign: "center",
  },
  docUploadContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    marginLeft: "10%",
  },
  uploadBoatDocTitleTxt: {
    fontSize: 30,
    color: "rgba(66, 70, 81, 0.87)",
    fontWeight: "600",
    lineHeight: 1.53,
  },
  uploadBoatDocTxt: {
    marginTop: "20px",
    fontSize: 20,
    color: "rgba(66, 70, 81, 0.87)",
    fontWeight: "300",
    lineHeight: 1.53,
  },
  boatOfferTitle: {
    marginTop: "193px",
    fontSize: 48,
    color: "rgba(66, 70, 81, 0.87)",
    fontWeight: "600",
    lineHeight: 1.5,
    textAlign: "center",
  },
  boatName: {
    fontSize: 20,
    color: "rgba(66, 70, 81, 0.87)",
    fontWeight: "600",
    lineHeight: 1.5,
    textAlign: "left",
  },
  boatDrierName: {
    fontSize: 20,
    color: "rgba(66, 70, 81, 0.6)",
    lineHeight: 1.5,
    textAlign: "left",
    marginTop: "8px",
  },
  boatprice: {
    fontSize: 18,
    color: "rgba(66, 70, 81, 0.87)",
    fontWeight: "600",
    lineHeight: 1.5,
    textAlign: "left",
    marginLeft: "10px",
  },
  moneyIcon: {
    width: "28px",
    height: "30px",
  },
  groupIcon: {
    width: "33px",
    height: "25px",
    marginRight: "10px",
  },
  straightLine: {
    backgroundColor: "rgba(66, 70, 81, 0.7)",
    width: "1px",
    height: "70px",
  },
  noDataContent: {
    display: "flex",
    flexDirection: "column",
    marginTop: "100px",
    alignItems: "center",
  },
  noResultImg: {
    width: "clamp(80px, 35vw, 139px)", // Adjust the range as needed
    height: "clamp(80px, 35vh, 139px)", // Adjust the range as needed
  },
  sryTxt: {
    fontSize: "clamp(14px, 3vw, 48px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.87)",
  },
  noResultTxt: {
    marginTop: "24px",
    fontSize: "clamp(12px, 3vw, 36px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.45)",
    width: "70%",
    textAlign: "center",
    marginBottom: "100px",
  },
  loadMoreStyle: {
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },

  suggestion_box_content: {
    marginTop: "2%",
    display: "flex",
    height: "auto",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    // alignItems: 'center'
  },

  filter_box_div: {
    width: "25%",
    margin: "0 2% 0 0%",
  },

  show_loader_card: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
  },

  show_boar_cards: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    margin: "0px",
    padding: "0px",
  },

  show_boar_cards_cahnge_align: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    margin: "0px",
    padding: "0px",
  },

  boat_card_space: {
    margin: "5px 0 5px 0",
    [theme.breakpoints.up("sm")]: {
      margin: "10.5px 0 10.5px 0",
    },
    [theme.breakpoints.up("md")]: {
      margin: "20.5px 0 20.5px 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "27.5px 0 27.5px 0",
    },
  },

  body_of_page: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "1% 5%",
    [theme.breakpoints.up("sm")]: {
      padding: "1% 5%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "1% 5%",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "1% 10%",
    },
  },

  search_find_btn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "20%",
    // marginBottom: '5%'
  },

  search_bar_tab: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "1% 5%",
    height: "100%",
    marginBottom: '2%'
  },

  straight_line: {
    backgroundColor: "rgba(66, 70, 81, 0.7)",
    width: "1px",
    height: "70px",
  },

  get_action_btn: {
    // marginTop: "16px",
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "55px",
    },
    borderRadius: "10px",
    border: "solid 1.5px #026b93",
    padding: "3% 7%",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    transition: "background-color 0.3s", // Add a smooth transition for the background color
    backgroundColor: "#3973a5",
    "&:hover": {
      backgroundColor: "#026b93", // Change the background color on hover
      "& $get_action_txt": {
        fontWeight: "bold",
      },
      "& $left_arr": {
        filter: "brightness(0) invert(1)", // Change image color on hover
      },
    },
  },
  get_action_txt: {
    fontSize: "clamp(14px, 2vw, 24px)", // Adjust the range as needed
    fontWeight: "500",
    lineHeight: 0.96,
    textAlign: "center",
    color: "white",
  },

  clearFilterTxt: {
    fontSize: "clamp(16px, 1.5vw, 16px)", // Adjust the range as needed
    fontWeight: "500",
    padding: "0px 5px 5px 10px",
    lineHeight: 0.96,
    textAlign: "center",
    color: "#3973a5",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("480")]: {
      // paddingTop: '2%',
      paddingBottom: "1%",
    },
    // input[type="date"]::-webkit-input-placeholder{
    //     visibility: hidden !important;
    // }
    // inputlabel_style: {
    //   height: '50%',
    //   display: 'flex',
    //   alignItems: 'center',
    //   [theme.breakpoints.down('992')]:{
    //     height: '2000%'
    //   }
    // },
  },

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 992px)": {
    noResultImg: {
      width: "clamp(30px, 35vw, 80px)", // Adjust the range as needed
      height: "clamp(30px, 35vh, 80px)", // Adjust the range as needed
    },
    suggestion_box_content: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      height: "auto",
      width: "100%",
      justifyContent: "center",
    },

    filter_box_div: {
      width: "100%",
      margin: "0",
    },

    date_picker: {
      // backgroundColor: 'yellow',
      // padding: '10px'
    },

    search_find_btn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "30%",
      [theme.breakpoints.down("xs")]: {
        width: "50%",
      },
    },

    search_bar_tab: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      backgroundColor: "white",
      borderRadius: "15px",
      padding: "1% 5%",
      height: "100%",
      margin: "5% 0",
    },

    straight_line: {
      backgroundColor: "rgba(66, 70, 81, 0.7)",
      width: "1px",
      height: "40px",
      alignSelf: "center",
    },
  },
}));
