/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Footer from "../../../Component/Footer/Footer";
import "./BoatViewDetails.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import IMAGES from "../../Images";
import Map from "../../Common/map/Map2";
import Policy from "./CancelationPolicy/Policy";
import { BoatDetailCard } from "../Card/BoatDetailCard";
import { boat_list_filter, get_all_review_API, single_boat_data_API } from "../../../Service/api";
import CalendarComponent from "../../Common/Calendar/CalendarComponent";
import ImageSlider from "../../Common/ViewImages/ImageSlider/ImageSlider";
import { PageHeader } from "../page-header/PageHeader";
import useWindowDimensions from "../../../UI kit/useWindowDimensions";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { StarRating } from "../../../UI kit/5Star/StarRating";
import { easings, useSpring } from "react-spring";
import {
  boat_owner_id,
  search_boat_id,
  single_boat_details_store,
} from "../../../redux/slices";
import { API } from "../../../Service/constants";
import { Dialog, DialogContent, DialogTitle, Skeleton } from "@mui/material";
import Client_review from "./Testimonial/Client_review";
import { Close } from "@material-ui/icons";
import Loader from "../../Loader";
import zIndex from "@mui/material/styles/zIndex";

const Testimonial = [
  {
    id: 1,
    name: "Abdallah Hazmi",
    place: "Riyadh",
    review: "Best Experiences Ever. Good Idea!",
    image: IMAGES.weekendCircle_1,
  },
  {
    id: 2,
    name: "Baker Arkan",
    place: "Jeddah",
    review: "This is very useful! we had a wonderful time.",
    image: IMAGES.weekendCircle_2,
  },
  {
    id: 3,
    name: "Mohammed Wadi",
    place: "Abha",
    review: "good effort!",
    image: IMAGES.weekendCircle_3,
  },
  {
    id: 4,
    name: "Salem Salem",
    place: "Mecca",
    review: "The most trusted scuba classes! Thank you.",
    image: IMAGES.weekendCircle_4,
  },
  {
    id: 5,
    name: "Salem Salem",
    place: "Mecca",
    review: "The most trusted scuba classes! Thank you.",
    image: IMAGES.weekendCircle_5,
  },
  {
    id: 6,
    name: "Salem Salem",
    place: "Mecca",
    review: "The most trusted scuba classes! Thank you.",
    image: IMAGES.weekendCircle_6,
  },
];

const CustomCheckbox = withStyles({
  root: {
    color: "#ffffff",
    "&$checked": {
      color: "#ffffff",
    },
  },
  checked: {},
})(Checkbox);

const skeletonData = Array(4).fill(null);

const BoatViewDetails = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const dashboard = useSelector((state) => state?.dashboard);
  const { height, width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [boatDetails, setBoatDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [calendar_1_date, setCalendar_1_date] = useState(moment().format(""));
  const [calendar_2_date, setCalendar_2_date] = useState(
    moment().add(1, "month").format("")
  );
  const boatListContainerRef = useRef(null);
  const clientReviewContainerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(false);
  const [selectedImageUrl_index, setSelectedImageUrl_index] = useState(false);
  const [selectedImageUrlFullData, setSelectedImageUrlFullData] =
    useState(false);
  const [Image1Spring, Image1Api] = useSpring(() => ({
    config: {
      duration: 750,
      easing: easings["easeInOutQuint"],
    },
    delay: 1000,
    from: { transform: "scale(0.9)", opacity: 0 },
  }));
  const [boatListDataDetails, setBoatListDataDetails] = useState([]);
  const [openReviewModal, setOpenReviewModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [allReviews, setAllReviews] = useState([])

  const { id } = useParams();
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScrollHeight(window.scrollY);
  };
  // console.log(id,'id')

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  // console.log(window?.onscroll,'scroll')

  useEffect(() => {
    setBoatDetails(null);
    setUserDetails(null);
    setIsLoading(true);
    let payload = {
      boat_uuid: id, //dashboard?.boat_id
      user_id: auth?.userId,
    };
    single_boat_data_API(auth?.AuthToken, payload)
      .then((res) => {
        console.log("res?.data", res?.data);
        if (res?.data?.message === "success") {
          setBoatDetails(res?.data?.parameters, "res data");
          setUserDetails(res?.data?.user);
          dispatch(single_boat_details_store(res?.data?.parameters));
        } else {
          toast.error(res?.data?.message ?? "error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("single boat data -=-=-=-=-=->>>>>  Error : ", err);
        toast.error(err?.message??"Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setIsLoading(false);
      });
  }, [auth?.AuthToken, auth?.userId, dashboard?.boat_id, dispatch]);
  // console.log(boatDetails,'boat details')

  useEffect(() => {
    let payload = {
      pageNo: null,
      boat_type: null,
      price: null,
      capacity: null,
      city: boatDetails?.marine_city,
      date: null,
      maxNoOfData: Infinity,
    };
    boat_list_filter(auth?.AuthToken, payload)
      .then((res) => {
        console.log("boat list filter res", res?.data);
        if (res?.data?.message) {
          setBoatListDataDetails(res?.data?.parameters);
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
    // };
  }, [boatDetails]);

  useEffect(() => {
    Image1Api.start({
      ...{ transform: "scale(1)", opacity: 1 },
      delay: 1000,
      config: { duration: 750 },
    });
  }, [Image1Api]);

  const handleGetAllReview = (boat_id) =>{
    let payload = {
      boat_id: boat_id
    }
    setLoading(true)
    console.log(payload,'all review payload')
    get_all_review_API(auth?.AuthToken,payload)
    .then((res)=>{
      console.log(res?.data,'all review end')
      if(res?.data?.success){
        setAllReviews(res?.data?.reviews)
        setLoading(false)
      } else {
        console.log('start trip')
        setLoading(false)
      }
    })
    .catch((err)=>{
      console.log('get all review api=>', err)
      setLoading(false)
    })
  }

  const handleClick = () => {
    const scrollAmount = boatListContainerRef.current.offsetWidth;
    boatListContainerRef.current.scrollLeft += scrollAmount;
  };
  const handleClick_right = () => {
    const scrollAmount = boatListContainerRef.current.offsetWidth;
    boatListContainerRef.current.scrollLeft -= scrollAmount;
  };
  const handleClick_1 = () => {
    const scrollAmount = clientReviewContainerRef.current.offsetWidth;
    clientReviewContainerRef.current.scrollLeft += scrollAmount;
  };

  const handleHeaderCallBack = (name) => {
    if (name === "Home") {
      if (auth?.tokenDecodeData?.user_type === "BOAT_OWNER") {
        navigate("/boatOwnerDashBoard");
      } else {
        navigate("/rental");
      }
    } else if (name === "Log In") {
      navigate("/logIn");
    } else if (name === "Sign Up") {
      navigate("/signUP");
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else if (name === "For Boat Rentals" || name === "For Boat Owners") {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (name === "/searchBoat") {
      navigate("/searchBoat");
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  // console.log(boatDetails?.longitude,'latitude')

  const handleImageClick = (item, index) => {
    setShowModal(true);
    setSelectedImageUrl(`${item[index]?.path}`); //${API.baseUrls[API.currentEnv]}
    setSelectedImageUrlFullData(item);
    setSelectedImageUrl_index(index);
  };

  const goToPrevious = () => {
    handleImageClick(
      boatDetails?.boats_image,
      selectedImageUrl_index > 0
        ? selectedImageUrl_index - 1
        : boatDetails?.boats_image?.length - 1
    );
  };

  const goToNext = () => {
    handleImageClick(
      boatDetails?.boats_image,
      selectedImageUrl_index + 1 < boatDetails?.boats_image?.length
        ? selectedImageUrl_index + 1
        : 0
    );
  };

  const handleCloseReviewModal = () =>{
    setOpenReviewModal(!openReviewModal)
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          backgroundColor: "#f6f6f6",
          height: "100%",
          // position: 'relative',
          // top: '0',
          // left: '0',
          // overflow: 'auto',
        }}
      >
        <div className="show-header-outSide-banner">
          <PageHeader
            showLoginSignUp={auth?.AuthToken ? false : true}
            handle_navigation={handleHeaderCallBack}
            // presentPage={"Home"}
            link1={"Boat Offers"}
            link2={"Scuba Courses/Programs"}
            link3={"Scuba Diving Trips"}
          />
        </div>
        <div className="show-header-inside-banner h-100">
          <PageHeader
            showLoginSignUp={auth?.AuthToken ? false : true}
            handle_navigation={handleHeaderCallBack}
            // presentPage={"Home"}
            link1={"Boat Offers"}
            link2={"Scuba Courses/Programs"}
            link3={"Scuba Diving Trips"}
            link4={
              auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
                ? "My Listings"
                : null
            }
          />
        </div>
        {isLoading || (!isLoading && boatDetails) ? (
          <div
            className="boatViewDetails"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className={class_name.booking_box} 
            style={{
            display: scrollHeight >= 500 && width > 767? 'block':'none',
            width: '24.3%'
          }}
            >
              <div 
              className={class_name.inside_booking_box_float}
              // style={{
              //   padding: '3.3% 8%'
              // }}
              >
                <div className={class_name.start_review_count_row}>
                  {!isLoading ? (
                    <StarRating rating={boatDetails?.averageRating} />
                  ) : (
                    <Skeleton width={100} height={20} />
                  )}
                  <Typography className={class_name.review_count_txt}>
                    {!isLoading ? (
                      `(${boatDetails?.overallRaters} reviews)`
                    ) : (
                      <Skeleton width={100} height={20} />
                    )}
                  </Typography>
                </div>
                {!isLoading ? (
                  <Typography className={class_name.label_txt}>
                    {"Estimated Price :"}
                  </Typography>
                ) : (
                  <Skeleton
                    width={100}
                    height={20}
                    style={{ margin: "5% 0" }}
                  />
                )}
                <div
                  className={class_name.start_review_count_row}
                  style={{
                    justifyContent: "flex-start",
                  }}
                >
                  {!isLoading ? (
                    <Typography className={class_name.price_values_txt}>
                      <>
                        {boatDetails?.price_per_hour}{" "}
                        {boatDetails?.price_currency}
                      </>
                    </Typography>
                  ) : (
                    <Skeleton width={"20%"} height={20} />
                  )}

                  {!isLoading ? (
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginLeft: "2.5%",
                      }}
                      className={class_name.price_values_txt}
                    >
                      <>{"per hour"}</>
                    </Typography>
                  ) : (
                    <Skeleton
                      width={"20%"}
                      height={20}
                      style={{ marginLeft: "5%" }}
                    />
                  )}
                </div>
                {!isLoading ? (
                  <div
                    className={class_name?.send_a_booking_req}
                    onClick={() => {
                      navigate("/boatBookingRequest");
                      dispatch(
                        boat_owner_id({
                          boat_owner_id: userDetails?.user_id,
                        })
                      );
                    }}
                  >
                    <Typography
                      className={`${class_name.send_a_booking_req_txt} hoverEffect`}
                    >
                      Send a Book Request
                    </Typography>
                  </div>
                ) : (
                  <Skeleton width={"100%"} height={100} />
                )}
              </div>
            </div>
            {!isLoading ? (
              <div
                className="view_boat_details_background_img"
                style={{
                  height: width > 1000 ? height / 1.5 : "10%",

                  backgroundImage: `url(${boatDetails?.background_image})`, //${API.baseUrls[API.currentEnv]}
                }}
              >
                <div className={class_name.insideBannerImg}>
                  <Typography className={class_name.Welcome_txt}>
                    {boatDetails?.boat_name}
                  </Typography>
                  <Typography className={class_name.sub_titile}>
                    {boatDetails?.boat_type}
                  </Typography>
                </div>
              </div>
            ) : (
              <Skeleton
                variant="rect"
                width="100%"
                height={width > 1000 ? height / 1.5 : "10%"}
                animation="wave"
              />
            )}

            <div className={class_name.form_container_box} >
              {/* ================== ================== ================== ================== show image'S and booking btn */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  height: 'auto'
                }}
              >
                {showModal ? (
                  <>
                    <ImageSlider
                      slides={selectedImageUrl}
                      onClose={handleModalClose}
                      item={selectedImageUrlFullData}
                      index={selectedImageUrl_index}
                      goToPrevious={goToPrevious}
                      goToNext={goToNext}
                    />
                  </>
                ) : null}
                {!isLoading ? (
                  <div className={class_name.show_img}>
                    {boatDetails?.boats_image?.map((item, index) => (
                      <>
                        <div className={class_name.show_boat_imgs_box} key={index}>
                          <img
                            src={item?.path ? `${item?.path}` : IMAGES.APP_ICON} //${API.baseUrls[API.currentEnv]}
                            alt="carousal_img"
                            className={class_name.boat_imgs}
                            style={{
                              ...Image1Spring,
                            }}
                            onClick={
                              () =>
                                handleImageClick(
                                  boatDetails?.boats_image,
                                  index
                                )
                              // boatData?.boats_image
                              // image?.path
                              //   ? `${`http://localhost:3000/`}${image.path}`
                              //   : IMAGES.APP_ICON
                            }
                          />
                        </div>
                      </>
                    ))}
                  </div>
                ) : (
                  <>
                    {skeletonData.map((_, index) => (
                      <Skeleton
                        key={index}
                        width={200}
                        height={200}
                        style={{ marginRight: "1%" }}
                      />
                    ))}
                  </>
                )}
              <div 
              className={class_name.booking_box}
              style={{
                display: scrollHeight < 500 && width > 767? 'block':'none',
              }}
              >
              <div className={class_name.inside_booking_box}
              >
                <div className={class_name.start_review_count_row}>
                  {!isLoading ? (
                    <StarRating rating={boatDetails?.averageRating} />
                  ) : (
                    <Skeleton width={100} height={20} />
                  )}
                  <Typography className={class_name.review_count_txt}>
                    {!isLoading ? (
                      `(${boatDetails?.overallRaters} reviews)`
                    ) : (
                      <Skeleton width={100} height={20} />
                    )}
                  </Typography>
                </div>
                {!isLoading ? (
                  <Typography className={class_name.label_txt}>
                    {"Estimated Price :"}
                  </Typography>
                ) : (
                  <Skeleton
                    width={100}
                    height={20}
                    style={{ margin: "5% 0" }}
                  />
                )}
                <div
                  className={class_name.start_review_count_row}
                  style={{
                    justifyContent: "flex-start",
                  }}
                >
                  {!isLoading ? (
                    <Typography className={class_name.price_values_txt}>
                      <>
                        {boatDetails?.price_per_hour}{" "}
                        {boatDetails?.price_currency}
                      </>
                    </Typography>
                  ) : (
                    <Skeleton width={"20%"} height={20} />
                  )}

                  {!isLoading ? (
                    <Typography
                      style={{
                        fontWeight: "bold",
                        marginLeft: "2.5%",
                      }}
                      className={class_name.price_values_txt}
                    >
                      <>{"per hour"}</>
                    </Typography>
                  ) : (
                    <Skeleton
                      width={"20%"}
                      height={20}
                      style={{ marginLeft: "5%" }}
                    />
                  )}
                </div>
                {!isLoading ? (
                  <div
                    className={class_name?.send_a_booking_req}
                    onClick={() => {
                      navigate("/boatBookingRequest");
                      dispatch(
                        boat_owner_id({
                          boat_owner_id: userDetails?.user_id,
                        })
                      );
                    }}
                  >
                    <Typography
                      className={`${class_name.send_a_booking_req_txt} hoverEffect`}
                    >
                      Send a Book Request
                    </Typography>
                  </div>
                ) : (
                  <Skeleton width={"100%"} height={100} />
                )}
              </div>
            </div>

                {/*  ================== ================== ================== ================== show image in modal page */}
              </div>

              <Divider
                variant="fullWidth"
                style={{
                  marginTop: "5%",
                  backgroundColor: "rgba(66, 70, 81, 0.8)",
                  height: "1px",
                  border: "solid 0.5px rgba(66, 70, 81, 0.8)",
                }}
              />

              {/* ==========================      profile   details   ========================== */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // backgroundColor: "mistyrose",
                  margin: "5% 0",
                  height: 'auto'
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {!isLoading ? (
                    <img
                      src={
                        // `${
                        userDetails?.profile_image ??
                        // }`
                        IMAGES.PROFILE_ICON
                      } //${API.baseUrls[API.currentEnv]}
                      alt="profile"
                      className={class_name.profile_img}
                    />
                  ) : (
                    <>
                      <Skeleton
                        className={class_name.loading_profile_icon}
                        // width={"10%"}
                        // height={"100%"}
                        // style={{ borderRadius: "50%" }}
                      />
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "1.5%",
                      width: "100%",
                    }}
                  >
                    <Typography className={class_name.owner_name}>
                      {!isLoading ? (
                        `${userDetails?.first_name ?? ""} ${
                          userDetails?.last_name ?? ""
                        }`
                      ) : (
                        <Skeleton width={100} height={20} />
                      )}
                    </Typography>

                    <Typography
                      className={class_name.owner_name_txt}
                      style={{
                        fontWeight: "normal",
                      }}
                    >
                      {!isLoading ? (
                        <>{"Boat Owner"}</>
                      ) : (
                        <Skeleton width={100} height={20} />
                      )}
                    </Typography>
                  </div>
                </div>
                <div className={class_name.show_review_count_and_star}>
                  {!isLoading ? (
                    <>
                      <StarRating rating={boatDetails?.averageRating} />
                      <Typography className={class_name.greeting_message_txt}>
                        {`${boatDetails?.overallRaters} reviews`}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Skeleton width={"20%"} height={20} />
                      <Skeleton width={"20%"} height={20} />
                    </>
                  )}
                </div>
                <Typography
                  className={class_name.greeting_message_txt}
                  // style={{ marginLeft: "10%" }}
                >
                  {!isLoading ? (
                    boatDetails?.greeting_message
                  ) : (
                    <>
                      <Skeleton width={"100%"} height={20} />
                      <Skeleton width={"100%"} height={20} />
                      <Skeleton width={"100%"} height={20} />
                      <Skeleton width={"100%"} height={20} />
                    </>
                  )}
                </Typography>
              </div>

              <Divider
                variant="fullWidth"
                style={{
                  marginTop: "5%",
                  backgroundColor: "rgba(66, 70, 81, 0.8)",
                  height: "1px",
                  border: "solid 0.5px rgba(66, 70, 81, 0.8)",
                }}
              />
              {/* ==========================       Boat Details       ========================== */}
              <div className={class_name.Boat_details_and_services}>
                <div className={class_name.show_width_half_or_full}>
                  <Typography className={class_name.Boat_details_txt}>
                    Boat Details
                  </Typography>
                  {!isLoading ? (
                    <>
                      <div
                        className={class_name.boat_details_content_row}
                        style={{ marginTop: "7%" }}
                      >
                        <Typography
                          className={class_name.Boat_details_point_txt}
                        >
                          Type
                        </Typography>
                        <Typography
                          className={class_name.Boat_details_point_ans_txt}
                        >
                          {boatDetails?.boat_type ?? (
                            <Skeleton width={100} height={20} />
                          )}
                        </Typography>
                      </div>
                      <div className={class_name.boat_details_content_row}>
                        <Typography
                          className={class_name.Boat_details_point_txt}
                        >
                          Length
                        </Typography>
                        <Typography
                          className={class_name.Boat_details_point_ans_txt}
                        >
                          {boatDetails?.boat_length ?? (
                            <Skeleton width={100} height={20} />
                          )}{" "}
                          {boatDetails?.length_type ?? "ft"}
                        </Typography>
                      </div>
                      <div className={class_name.boat_details_content_row}>
                        <Typography
                          className={class_name.Boat_details_point_txt}
                        >
                          Year
                        </Typography>
                        <Typography
                          className={class_name.Boat_details_point_ans_txt}
                        >
                          {boatDetails?.boat_year ?? (
                            <Skeleton width={100} height={20} />
                          )}
                        </Typography>
                      </div>
                      <div className={class_name.boat_details_content_row}>
                        <Typography
                          className={class_name.Boat_details_point_txt}
                        >
                          Max Capacity
                        </Typography>
                        <Typography
                          className={class_name.Boat_details_point_ans_txt}
                        >
                          {boatDetails?.boat_max_capacity ?? (
                            <Skeleton width={100} height={20} />
                          )}
                        </Typography>
                      </div>
                    </>
                  ) : (
                    <div className={class_name.boat_servie_row} style={{}}>
                      <div
                        style={{
                          width: "50%",
                          // display:'flex',
                          // flexDirection:'row',
                          // flexWrap:'wrap'
                          // backgroundColor: "lavender",
                        }}
                      >
                        {skeletonData.map((_, index) => (
                          <Skeleton
                            width={100}
                            height={20}
                            key={index}
                            style={{ marginTop: "5%" }}
                          />
                        ))}
                      </div>
                      <div
                        style={{
                          width: "50%",
                        }}
                      >
                        {skeletonData.map((_, index) => (
                          <Skeleton
                            width={100}
                            height={20}
                            key={index}
                            style={{ marginTop: "5%" }}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                {/* ==========================       Services       ========================== */}
                <div className={class_name.show_width_half_or_full}>
                  <Typography className={class_name.Boat_details_txt}>
                    Services
                  </Typography>
                  <div className={class_name.boat_servie_row} style={{}}>
                    {!isLoading ? (
                      <>
                        {boatDetails?.selected_services?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                width: "50%",
                                //  backgroundColor: "lavender"
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <>
                                    <CustomCheckbox
                                      name="email"
                                      checked={true}
                                      // onChange={handleCheckboxChange}
                                      icon={
                                        <span
                                          className={class_name.unChecked_box}
                                        />
                                      }
                                      checkedIcon={
                                        <span
                                          className={class_name.checked_box}
                                        >
                                          ✓
                                        </span>
                                      }
                                    />
                                  </>
                                }
                                label={
                                  <Typography
                                    className={class_name.check_box_txt}
                                  >
                                    {item.service_label}
                                  </Typography>
                                }
                              />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            width: "50%",
                            // backgroundColor: "lavender",
                          }}
                        >
                          {skeletonData.map((_, index) => (
                            <Skeleton
                              key={index}
                              width={100}
                              height={20}
                              style={{ marginTop: "5%" }}
                            />
                          ))}
                        </div>
                        <div
                          style={{
                            width: "50%",
                          }}
                        >
                          {skeletonData.map((_, index) => (
                            <Skeleton
                              key={index}
                              width={100}
                              height={20}
                              style={{ marginTop: "5%" }}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* ==========================       Calendar       ========================== */}
              <div>
                <Typography
                  className={class_name.Boat_details_txt}
                  style={{ margin: "7% 0 3%" }}
                >
                  Check Avaliability
                </Typography>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div className={class_name.first_calendar}>
                    {!isLoading ? (
                      <>
                        <CalendarComponent
                          hideSelectedDayColor={true}
                          calender_no={"1"}
                          showFixedDates={boatDetails?.boats_timeslot}
                          setDay={calendar_1_date}
                          setPrimary_date={setCalendar_1_date}
                          setSecondary_date={setCalendar_2_date}
                          handleShowMonth={calendar_1_date}
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "300px",

                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <Skeleton width={"100%"} height={"100%"} />
                      </div>
                    )}
                  </div>

                  <div className={class_name.second_calendar}>
                    {!isLoading ? (
                      <>
                        <CalendarComponent
                          hideSelectedDayColor={true}
                          showFixedDates={boatDetails?.boats_timeslot}
                          calender_no={"2"}
                          setDay={calendar_2_date}
                          setPrimary_date={setCalendar_2_date}
                          setSecondary_date={setCalendar_1_date}
                          handleShowMonth={calendar_2_date}
                        />
                      </>
                    ) : (
                      <Skeleton width={"100%"} height={"100%"} />
                    )}
                  </div>
                </div>
              </div>

              {/* ==========================       map Location       ========================== */}

              <Typography
                className={class_name.Boat_details_txt}
                style={{ margin: "7% 0 2%" }}
              >
                Boat Location
              </Typography>

              {!isLoading ? (
                <Map
                  selectedMarker={{
                    lat: parseFloat(boatDetails?.latitude),
                    lng: parseFloat(boatDetails?.longitude),
                  }}
                  // markers={{
                  //   lat: parseFloat(boatDetails?.latitude),
                  //   lng: parseFloat(boatDetails?.longtitude),
                  // }}
                  // onSelectMarker={boatDetails?.longtitude ?? ""}
                  notSelect={true}
                />
              ) : (
                <>
                  {/* <Skeleton
                  width={"100%"}
                  height={"100%"}
                  style={{
                    margin: 0,
                    padding: 0,
                    boxSizing: "border-box", // Include padding and borders in width and height
                  }}
                /> */}
                  <Skeleton
                    width={"100%"}
                    height={500}
                    style={{ marginTop: "1%" }}
                  />
                </>
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "3%",
                }}
              >
                {!isLoading ? (
                  <>
                    <img
                      src={IMAGES.LOCATION}
                      className={class_name.location_icon_img}
                      alt="location_img"
                    />
                    <div style={{ marginLeft: "5%" }}>
                      <Typography className={class_name.city_nameTxt}>
                        {boatDetails?.marine_city}
                      </Typography>
                      <Typography className={class_name.address_Txt}>
                        {boatDetails?.marine_address}
                      </Typography>
                    </div>
                  </>
                ) : (
                  <>
                    <Skeleton
                      width={"5%"}
                      height={"120px"}
                      style={{
                        margin: 0,
                        padding: 0,
                        boxSizing: "border-box",
                      }}
                    />
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "3%",
                        padding: 0,
                      }}
                    >
                      <Skeleton
                        width={"20%"}
                        height={"30px"}
                        style={{
                          marginLeft: "0%",
                          padding: 0,
                          boxSizing: "border-box",
                        }}
                      />
                      <Skeleton
                        width={"10%"}
                        height={"30px"}
                        style={{
                          marginLeft: "0%",
                          padding: 0,
                          boxSizing: "border-box",
                        }}
                      />
                    </div>
                  </>
                )}
              </div>

              {/* ==========================  ==========================      customers       ========================== */}
              {!isLoading ? (
                <>
                  {boatDetails?.boats_user_ratings?.length > 0 ? (
                    <div>
                      <div style={{ padding: "7% 0 2%" }} className="d-flex justify-content-between align-items-center">
                      <Typography
                        className={class_name.Boat_details_txt}
                      >
                        What customers say about this boat
                      </Typography>
                      <span 
                      className={class_name.show_more_txt}
                      onClick={()=>{
                        setOpenReviewModal(!openReviewModal)
                        handleGetAllReview(boatDetails?.boat_id)
                      }}
                      >
                        Show More
                      </span>
                      </div>
                      <div className="d-flex">
                        <div
                          ref={clientReviewContainerRef}
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            // backgroundColor: "red",
                            overflowX: "auto",
                          }}
                        > 
                        {/* boatDetails?. */}
                          {boatDetails?.boats_user_ratings?.map((item, index) => {
                            return (
                              <div
                                style={{ marginLeft: index === 0 ? "2%" : ".1%" }}
                              >
                                <Client_review
                                  id={item?.id}
                                  name={`${item?.user_accounts?.first_name} ${item?.user_accounts?.last_name}`}
                                  place={item?.user_accounts?.city}
                                  review={item?.review}
                                  rating={item?.rating}
                                  seeMoreOption={true}
                                  image={
                                    item?.user_accounts?.profile_image ?item?.user_accounts?.profile_image:
                                    IMAGES?.PROFILE_ICON
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                        {boatDetails?.boats_user_ratings?.length > 4 ? (
                          <IconButton className={class_name.align_arrow_icon}>
                            <img
                              src={IMAGES.RIGHT_ARROW}
                              onClick={handleClick_1}
                              className={class_name.right_arrow_style}
                              alt="next >>"
                            />
                          </IconButton>
                        ) : null}
                    </div>
                      {/* {
                  boats_user_ratings?.map((item) => (
                    <Client_review
                    id={item?.id}
                    name={`${item?.user_accounts?.first_name} ${item?.user_accounts?.last_name}`}
                    place={item?.user_accounts?.city}
                    review={item?.review}
                    image={item?.user_accounts?.profile_image??IMAGES.PROFILE_ICON}
                    />
                  ))
                } */}
                    </div>
                  ) : null}
                </>
              ) : (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Skeleton
                    width={200}
                    height={200}
                    style={{ marginRight: "1%" }}
                  />
                  <Skeleton
                    width={200}
                    height={200}
                    style={{ marginRight: "1%" }}
                  />
                  <Skeleton
                    width={200}
                    height={200}
                    style={{ marginRight: "1%" }}
                  />
                </div>
              )}

              {/* ========================== ==========================  Cancellation     Policy       ========================== */}
              <div>
                <Typography
                  className={class_name.Boat_details_txt}
                  style={{ margin: "7% 0 2%" }}
                >
                  Cancellation Policy
                </Typography>

                {!isLoading ? (
                  <>
                    {boatDetails?.boats_cancellation_policy?.map(
                      (item, index) => {
                        return (
                          <span className={class_name.policy_txt}>
                            <Policy
                              id={index + 1}
                              policy_statement={item?.policy_statement}
                            />
                          </span>
                        );
                      }
                    )}
                  </>
                ) : (
                  <>
                    {skeletonData.map((_, index) => (
                      <Skeleton
                        key={index}
                        width={"100%"}
                        height={80}
                        style={{ marginTop: "1%" }}
                      />
                    ))}
                  </>
                )}
              </div>
              {isLoading ? (
                <Typography
                  className={class_name.owner_name}
                  style={{
                    marginTop: "7%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Other Boats in{" "}
                  <Skeleton
                    width={"25%"}
                    height={25}
                    style={{ marginLeft: "3%" }}
                  />
                </Typography>
              ) : null}
              {/* ========================== ==========================       boatListData       ========================== */}
              {!isLoading ? (
                <>
                  {boatListDataDetails?.length > 1 ? (
                    <div>
                      <Typography
                        className={class_name.owner_name}
                        style={{ marginTop: "7%" }}
                      >
                        Other Boats in {boatDetails?.marine_city}
                      </Typography>
                      <div
                        style={{}}
                        className={class_name.showing_boat_list_card}
                      >
                        {boatDetails?.boat_list?.length > 4 ? (
                          <IconButton className={class_name.align_arrow_icon}>
                            <img
                              src={IMAGES.RIGHT_ARROW}
                              style={{
                                transform: 'rotate(180deg)'
                              }}
                              onClick={handleClick_right}
                              className={class_name.right_arrow_style}
                              alt="next >>"
                            />
                          </IconButton>
                        ) : null}
                        <div
                          ref={boatListContainerRef}
                          className={class_name.showing_boat_list_inner_div}
                          style={
                            {
                              // width: "95%",
                              // overflowX: "auto",
                              // margin: `0px 0px 0px 0px`,
                              // display: "flex",
                              // flexDirection: "row",
                            }
                          }
                        >
                          <Row>
                            <Col xs={11}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {boatListDataDetails?.map((item, index) => {
                                  if(item?.dataValues?.boat_uuid !== boatDetails?.boat_uuid){
                                  // item?.marine_city === boatDetails?.marine_city?? //boatDetails?.boat_list?.filter((item, index) => item?.marine_city === boatDetails?.marine_city)
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        margin: "0.1%",
                                        marginBottom: "0.3%",
                                      }}
                                      onClick={() => {
                                        navigate(`/boatData/${item?.dataValues?.boat_uuid}`)
                                        dispatch(search_boat_id(item?.boat_uuid));
                                      }}
                                    >
                                      <BoatDetailCard
                                        boatName={item?.dataValues?.boat_name}
                                        marine_city={
                                          item?.dataValues?.marine_city
                                        }
                                        starRating={item?.averageRating}
                                        priceCurrency={
                                          item?.dataValues?.price_currency
                                        }
                                        pricePerHour={
                                          item?.dataValues?.price_per_hour
                                        }
                                        boatMaxCapacity={
                                          item?.dataValues?.boat_max_capacity
                                        }
                                        profile_image={`
                                        ${item?.dataValues?.front_image}`} //${API.baseUrls[API.currentEnv]}
                                        borderRadius={"1px"}
                                      />
                                    </div>
                                  );
                                }
                                })}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        {boatDetails?.boat_list?.length > 4 ? (
                          <IconButton className={class_name.align_arrow_icon}>
                            <img
                              src={IMAGES.RIGHT_ARROW}
                              onClick={handleClick}
                              className={class_name.right_arrow_style}
                              alt="next >>"
                            />
                          </IconButton>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    // backgroundColor: "rebeccapurple",
                  }}
                >
                  {skeletonData.map((_, index) => (
                    <div
                      key={index}
                      style={{
                        width: "40%",
                        display: "flex",
                        flexDirection: "column",
                        // backgroundColor: "rebeccapurple",
                        margin: "1%",
                      }}
                    >
                      <Skeleton
                        variant="rect"
                        width="100%"
                        height={300}
                        animation="wave"
                      />
                      <Skeleton
                        variant="text"
                        width="80%"
                        height={20}
                        animation="wave"
                        style={{ marginTop: "10px" }}
                      />
                      <Skeleton
                        variant="text"
                        width="80%"
                        height={20}
                        animation="wave"
                        style={{ marginTop: "5px" }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div style={{}} className={class_name.show_bottom_box_content}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  {!isLoading ? (
                    <Typography
                      style={{ color: "rgba(21, 28, 48, 0.6)", margin: "0" }}
                      className={class_name.label_txt}
                    >
                      Estimated Price:
                    </Typography>
                  ) : (
                    <Skeleton width={100} height={20} />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  {!isLoading ? (
                    <>
                      <Typography className={class_name.price_values_txt}>
                        {boatDetails?.price_per_hour}{" "}
                        {boatDetails?.price_currency}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          marginLeft: "2.5%",
                        }}
                        className={class_name.price_values_txt}
                      >
                        {"per hour"}
                      </Typography>
                    </>
                  ) : (
                    <Skeleton width={100} height={20} />
                  )}
                </div>
              </div>
              {!isLoading ? (
                <div
                  className={class_name?.send_a_booking_req_outer_box}
                  onClick={() => {
                    dispatch(
                      boat_owner_id({
                        boat_owner_id: userDetails?.user_id,
                      })
                    );
                    navigate("/boatBookingRequest");
                  }}
                >
                  <Typography
                    className={`${class_name.send_a_booking_req_txt_outer_box} hoverEffect`}
                  >
                    Send a Book Request
                  </Typography>
                </div>
              ) : (
                <Skeleton width={"100%"} height={80} />
              )}
            </div>
          </div>
        ) : (
          <div className={class_name.invalidBoatDataDiv}>
            <span className={class_name.invalidBoatDataTxt}>
              Invalid Boat Data
            </span>
          </div>
        )}
        <div className="footer-style-hide">
          <Footer />
        </div>
        <Dialog 
        open={openReviewModal}
        onClose={handleCloseReviewModal}
        scroll="paper"
        >
          <div>
          <Loader loading={loading}/>
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "sticky",
              top: 0,
              left: 0,
              right: 0,
              backgroundColor: "white",
              zIndex: '1'
            }}
          >
            <span>All Customer Reviews</span>
            <span onClick={handleCloseReviewModal}>
              <Close />
            </span>
          </DialogTitle>
            <DialogContent>
              {
                allReviews?.map((item)=>(
                  <Client_review
                  id={item?.id}
                  name={`${item?.user_accounts?.first_name} ${item?.user_accounts?.last_name}`}
                  place={item?.user_accounts?.city}
                  review={item?.review}
                  rating={3}
                  image={
                    item?.user_accounts?.profile_image ??
                    IMAGES.PROFILE_ICON
                  }
                  customWidth={'100%'}
                />
                ))
              }
            </DialogContent>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default BoatViewDetails;

const useStyles = makeStyles((theme) => ({
  form_container_box: {
    backgroundColor: "#fff",
    alignSelf: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    border: "solid 0.5px rgba(66, 70, 81, 0.1)",
    padding: "5%",
    paddingTop: "1.5%",
    borderRadius: "2px",
    marginBottom: "5%",
    width: "90%",
    height:'100%',
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("950")]: {},
  },

  insideBannerImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },

  Welcome_txt: {
    fontSize: "clamp(22px, 3vw, 65px)", // Adjust the range as needed
    fontWeight: "bolder",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.87)",
    // fontSize: '3rem'
  },

  sub_titile: {
    fontSize: "clamp(16px, 2.5vw, 60px)", // Adjust the range as needed
    fontWeight: "500",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.87)",
  },

  profile_img: {
    borderRadius: "100px",
    width: "50px",
    height: "50px",

    [theme.breakpoints.up("sm")]: {
      width: "60px",
      height: "60px",
    },
    [theme.breakpoints.up("md")]: {
      width: "75px",
      height: "75px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90px",
      height: "90px",
    },
  },

  loading_profile_icon: {
    width: 100,
    height: 150,
    borderRadius: "50%",
    [theme.breakpoints.down("768")]: {
      height: 50,
      widht: 30,
    },
    [theme.breakpoints.down("480")]: {
      height: 100,
      width: 80,
    },
  },

  owner_name: {
    fontSize: "clamp(14px, 3vw, 24px)",
    textAlign: "left",
    color: "#424651",
  },
  owner_name_txt: {
    fontSize: "clamp(14px, 2vw, 20px)",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.8)",
  },
  greeting_message_txt: {
    fontSize: "clamp(14px, 1.5vw, 28px)",
    // fontWeight: "lighter",
    textAlign: "left",
    color: "#424651",
    marginTop: "1%",
  },

  boat_servie_row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "7%",
  },

  Course_Program_Schedule_txt: {
    fontSize: "clamp(12px, 3.5vw, 36px)",
    fontWeight: "bold",
    textAlign: "left",
    color: "#424651",
    marginTop: "10%",
  },

  date_box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "48%",
    padding: "2% 3%",
    border: "solid 0.5px rgba(66, 70, 81, 0.8)",
    borderRadius: "15px",
    marginTop: "2%",
  },

  days_weeks_txt: {
    fontSize: "clamp(12px, 3.5vw, 30px)",
    fontWeight: "400",
    color: "#424651",
    marginTop: "1.4%",
  },
  day_name: {
    fontSize: "clamp(10px, 2vw, 28px)",
    fontWeight: "400",
    textAlign: "left",
    color: "#424651",
  },
  from_to_txt: {
    fontSize: "clamp(10px, 2vw, 22px)",
    fontWeight: "lighter",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.5)",
  },

  Course_Program_Outline_point_txt: {
    fontSize: "clamp(10px, 2vw, 28px)",
    textAlign: "left",
    color: "#424651",
  },

  circular: {
    marginTop: "10px",
    borderRadius: "50%",
    width: "clamp(20px, 4vw, 50px)",
    height: "clamp(10px, 2vh, 20px)",
    backgroundColor: "#424651",
    marginRight: "3%",
  },

  Boat_details_txt: {
    fontSize: "clamp(16px, 3.5vw, 36px)",
    fontWeight: "bold",
    textAlign: "left",
    color: "#424651",
    marginTop: "1%",
  },

  boat_details_content_row: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2%",
  },

  Boat_details_point_txt: {
    fontSize: "clamp(14px, 1.5vw, 26px)",
    fontWeight: "500",
    textAlign: "left",
    color: "#424651",
    width: "50%",
  },

  Boat_details_point_ans_txt: {
    fontSize: "clamp(14px, 1.5vw, 24px)",
    textAlign: "left",
    color: "#424651",
  },

  first_calendar: {
    display: "flex",
    justifyContent: "center",
    width: "50%",
    padding: "3%",
  },

  second_calendar: {
    display: "flex",
    justifyContent: "center",
    width: "50%",
    padding: "3%",
  },

  location_icon_img: {
    width: "20px",
    height: "30px",

    [theme.breakpoints.up("sm")]: {
      width: "23px",
      height: "35px",
    },
    [theme.breakpoints.up("md")]: {
      width: "23px",
      height: "45px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "33px",
      height: "55px",
    },
  },

  city_nameTxt: {
    fontSize: "clamp(14px, 1.5vw, 28px)",
    fontWeight: "500",
    textAlign: "left",
    color: "#424651",
  },

  address_Txt: {
    fontSize: "clamp(14px, 1.5vw, 22px)",
    textAlign: "left",
    color: "#424651",
  },

  show_review_count_and_star: {
    display: "none",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "5% 0",
  },

  Boat_details_and_services: {
    display: "flex",
    flexDirection: "row",
    height: "auto",
    width: "100%",
  },

  show_width_half_or_full: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    margin: "5% 0",
  },

  unChecked_box: {
    border: "1px solid rgba(66, 70, 81, 0.4)",
    borderRadius: "1px",
    width: "22px",
    height: "22px",
  },

  checked_box: {
    color: "#424651",
    borderRadius: "1px",
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    width: "clamp(12px, 15vw, 22px)",
    height: "clamp(12px, 10vh, 22px)",
    fontSize: "clamp(10px, 1.5vw, 28px)",
  },

  show_img: {
    display: "flex",
    flexDirection: "row",
    overflowX: "auto",
    width: "80%",
  },

  show_boat_imgs_box: {
    display: `flex`,
    // position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `10px`,
    boxSizing: `border-box`,
    alignSelf: `stretch`,
    margin: `0px`,
  },

  boat_imgs: {
    cursor: "pointer",
    borderRadius: "5px",
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "123px",
    },
    [theme.breakpoints.up("md")]: {
      width: "200px",
      height: "173px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "225px",
      height: "197px",
    },
  },

  booking_box: {
    // height: "auto",
    height: '100%',
    marginTop: "-5%",
    position: '-webkit-sticky',
    position: 'sticky',
    top: '10px',
    // top: '0',
    left: '65.6%',
    // bottom: '-1rem',
    width: "35%",
    zIndex: '999',
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
  },

  inside_booking_box_float: {
    borderRadius: 0,
    padding: "0% 8%",
    backgroundColor: 'red',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.16)`,
    backgroundColor: `rgba(250, 250, 250, 1)`,
    height: "100%",
    [theme.breakpoints.down('md')]:{
      padding: '10% 8%'
    }
  },

  inside_booking_box: {
    borderRadius: 0,
    padding: "8%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.16)`,
    backgroundColor: `rgba(250, 250, 250, 1)`,
    height: "100%",
  },

  start_review_count_row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },

  review_count_txt: {
    fontSize: "clamp(8px, 1vw, 18px)",
    color: "rgba(66, 70, 81, 0.87)",
  },

  label_txt: {
    fontSize: "clamp(14px, 1vw, 18px)",
    color: "rgba(66, 70, 81, 0.8)",
    margin: "5% 0",
  },

  price_values_txt: {
    fontSize: "clamp(14px, .5vw, 24px)",
    color: "rgba(66, 70, 81, 0.87)",
  },

  send_a_booking_req: {
    marginTop: "5%",
    borderRadius: "10px",
    border: "solid 1px #026b93",
    padding: "5% 10%",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    transition: "background-color 0.3s",
    backgroundColor: "#026b93", // Add a smooth transition for the background color
    "&:hover": {
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
      // Change the background color on hover
      "& $send_a_booking_req_txt": {
        fontWeight: "600",
      },
    },
  },
  send_a_booking_req_txt: {
    fontSize: "clamp(8px, 1vw, 20px)", // Adjust the range as needed
    fontWeight: "400",
    textAlign: "center",
    color: "white",
  },

  align_arrow_icon: {
    width: "5%",
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
  },

  right_arrow_style: {
    width: "clamp(10px, 5vw, 50px)", // Adjust the range as needed
    height: "clamp(10px, 5vh, 51px)", // Adjust the range as needed
  },

  send_a_booking_req_outer_box: { display: "none" },
  show_bottom_box_content: {
    display: "none",
  },

  showing_boat_list_card: {
    width: "100%",
    overflowX: "auto",
    margin: `0px 0px 0px 0px`,
    display: "flex",
    flexDirection: "row",
  },
  showing_boat_list_inner_div: {
    width: "95%",
    overflowX: "auto",
    margin: `0px 0px 0px 0px`,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("767")]: {
      width: "100%",
    },
  },

  invalidBoatDataDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
    backgroundColor: "#f6f6f6",
  },

  invalidBoatDataTxt: {
    fontSize: "clamp(20px,4vw,100px)",
    fontWeight: "bold",
    color: "rgba(66, 70, 81, 0.87)",
  },

  show_more_txt: {
    fontSize: "clamp(12px, 3vw, 24px)",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.74,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.6)",
    cursor: "pointer",
    whiteSpace: 'nowrap',
    "&:hover": {
      // textDecoration: "underline",
      color: "rgba(66, 70, 81, 0.9)",
    },
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 768px)": {
    form_container_box: {
      backgroundColor: "#f6f6f6",
      width: "100%",
      height: '100%',
      margin: "0",
      marginBottom: "25%",
    },

    second_calendar: {
      display: "none",
    },
    first_calendar: {
      width: "100%",
      padding: "0",
    },
    show_review_count_and_star: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "5% 0",
    },

    Boat_details_and_services: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
      width: "100%",
    },

    show_width_half_or_full: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },

    show_bottom_box_content: {
      display: "flex",
      position: "fixed",
      bottom: 0,
      backgroundColor: "white",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      padding: "5%",
      boxShadow: "4px 6px 12px rgba(96, 96, 96, 0.15)",
    },

    send_a_booking_req_outer_box: {
      cursor: "pointer",
      borderRadius: "0px",
      border: "solid 1px rgba(151, 151, 151, 1)",
      padding: "2.5% 10%",
      marginTop: "5%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      backgroundColor: "#026b93",
    },
    send_a_booking_req_txt_outer_box: {
      fontSize: "clamp(16px, 5vw, 20px)",
      fontWeight: "400",
      textAlign: "center",
      color: "white",
    },
    show_img: {
      width: "100%",
      justifyContent: "center",
    },
    boat_imgs: {
      backgroundColor: "whitesmoke",
      width: "clamp(108px, 25vw, 112.5px)",
      height: "clamp(72px, 20vh, 98px)",
      borderRadius: "5px",
    },
    booking_box: {
      display: "none",
    },
    showing_boat_list_card: {
      width: "100%",
      overflowX: "auto",
      margin: `0px 0px 100px 0px`,
      display: "flex",
      flexDirection: "row",
    },
    align_arrow_icon: {
      width: "5%",
      display: "none",
      alignSelf: "center",
      justifyContent: "center",
    },
  },
}));


const boats_user_ratings = [
  {
    user_accounts: {
      first_name: "sudha",
      last_name: "kar",
    },
    review: "i enjoyed the boat ride very much",
    id: 1,
  },
  {
    user_accounts: {
      first_name: "sudha",
      last_name: "kar",
    },
    review: "i enjoyed the boat ride very much",
    id: 2,
  },
  {
    user_accounts: {
      first_name: "sudha",
      last_name: "kar",
    },
    review: "i enjoyed the boat ride very much",
    id: 3,
  },
  {
    user_accounts: {
      first_name: "sudha",
      last_name: "kar",
    },
    review: "i enjoyed the boat ride very much",
    id: 4,
  },
  {
    user_accounts: {
      first_name: "sudha",
      last_name: "kar",
    },
    review: "i enjoyed the boat ride very much",
    id: 5,
  },
  {
    user_accounts: {
      first_name: "sudha",
      last_name: "kar",
      profile_image: IMAGES.boat2,
    },
    review:
      "i enjoyed the boat ride very much i enjoyed the boat ride very much",
    id: 6,
  },
];