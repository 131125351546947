import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../../Component/Footer/Footer";
import { EditProfileTab } from "./setting-tab/EditProfileTab";
import { NotificationTab } from "./setting-tab/NotificationTab";
import { SecurityAndPwdTab } from "./setting-tab/SecurityAndPwdTab";
import { PageHeader } from "../page-header/PageHeader";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IMAGES from "../../Images";
import { useMediaQuery, useTheme } from "@mui/material";
import { setting_tab } from "../../../redux/slices";

const first_tab = "Edit Profile";
const second_tab = "Billing & Payment";
const third_tab = "Security & Password";
const fourth_tab = "Notifications Settings";

export const Setting = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const auth = useSelector((state) => state?.auth);
  const dashboard = useSelector((state) => state?.dashboard);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(first_tab);
  const [drawerIsOpenOrNot, setDrawerIsOpenOrNot] = useState(null);
  const [dropdown,setDropdown] = useState(false)

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(()=>{
    if(dashboard?.setting_tab){
      setSelectedTab(dashboard?.setting_tab)
    }
  },[dashboard])

  // console.log("auth", auth?.userDetails);

  const handle_navigation = (name) => {
    if (name === "Home") {
      if (auth?.AuthToken) {
        if (auth?.tokenDecodeData?.user_type === "BOAT_OWNER") {
          navigate("/boatOwnerDashBoard");
        } else {
          navigate("/rental");
        }
      } else {
        navigate("/rental");
      }
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else if (name === "For Boat Rentals" || name === "For Boat Owners") {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleNavigation = (tabName) => {
    dispatch(setting_tab(tabName))
    setSelectedTab(tabName);
    setDropdown(false)
  };

  return (
    <>
      <div className={class_name.setting_box}>
        <PageHeader
          showLoginSignUp={auth?.AuthToken ? false : true}
          handle_navigation={handle_navigation}
          presentPage={""}
          link1={"Boat Offers"}
          link2={"Scuba Courses/Programs"}
          link3={"Scuba Diving Trips"}
          link4={auth?.tokenDecodeData?.user_type === "BOAT_OWNER"? "My Listings":null}
          drawerIsOpen={setDrawerIsOpenOrNot}
        />

        <div style={{}} className={class_name.setting_body_content}>
            <Typography className={class_name.title}>Settings</Typography>


          {/* ===================================================== Title  ===================================================== */}
          <div
            style={{
              // display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
            }}
            className={class_name.tab_options}
          >
            <Typography
              onClick={() => handleNavigation(first_tab)}
              className={
                selectedTab === first_tab
                  ? class_name.tab_selected_title
                  : class_name.tab_title
              }
            >
              {first_tab}
            </Typography>

            <Typography
              onClick={() => {
                toast.info("Under Development", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
              }}
              className={
                selectedTab === second_tab
                  ? class_name.tab_selected_title
                  : class_name.tab_title
              }
            >
              {second_tab}
            </Typography>

            <Typography
              onClick={() => handleNavigation(third_tab)}
              className={
                selectedTab === third_tab
                  ? class_name.tab_selected_title
                  : class_name.tab_title
              }
            >
              {third_tab}
            </Typography>

            <Typography
              onClick={() => handleNavigation(fourth_tab)}
              className={
                selectedTab === fourth_tab
                  ? class_name.tab_selected_title
                  : class_name.tab_title
              }
              style={{ paddingRight: "0" }}
            >
              {fourth_tab}
            </Typography>
          </div>

          <div
            style={{
              padding: "5% 0px",
            }}
          >
            {
            isSmallScreen &&
            <>
            <div className="d-flex align-items-center">
            <span className="fs-5 ps-1 fw-semibold" onClick={()=>setDropdown(!dropdown)}>{selectedTab}</span>
            <img
              src={IMAGES.DOWN}
              alt="up"
              style={{}}
              className={class_name.down_arror}
              onClick={()=>setDropdown(!dropdown)}
            />
            </div>
            <div className={class_name.down_arror_dropdown}
              style={{display: dropdown && isSmallScreen? 'flex':'none'}}
              >
                <Typography
                  onClick={() => handleNavigation(first_tab)}
                  className={
                    selectedTab === first_tab
                      ? class_name.tab_selected_title
                      : class_name.tab_title
                  }
                >
                  {first_tab}
                </Typography>

                <Typography
                  onClick={() => {
                    toast.info("Under Development", {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 2000,
                    });
                  }}
                  className={
                    selectedTab === second_tab
                      ? class_name.tab_selected_title
                      : class_name.tab_title
                  }
                >
                  {second_tab}
                </Typography>

                <Typography
                  onClick={() => handleNavigation(third_tab)}
                  className={
                    selectedTab === third_tab
                      ? class_name.tab_selected_title
                      : class_name.tab_title
                  }
                >
                  {third_tab}
                </Typography>

                <Typography
                  onClick={() => handleNavigation(fourth_tab)}
                  className={
                    selectedTab === fourth_tab
                      ? class_name.tab_selected_title
                      : class_name.tab_title
                  }
                  style={{ paddingRight: "0" }}
                >
                  {fourth_tab}
                </Typography>
            </div>
            </>
            }
            {selectedTab === first_tab ? (
              <>
                <EditProfileTab drawerIsOpenOrNot={drawerIsOpenOrNot} />
              </>
            ) : selectedTab === third_tab ? (
              <SecurityAndPwdTab />
            ) : selectedTab === fourth_tab ? (
              <NotificationTab />
            ) : null}
          </div>
        </div>

        <div className="footer-style-hide">
          <Footer />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  setting_box: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // height: "100vh",
    backgroundColor: "#f6f6f6",
    [theme.breakpoints.down("600")]: {
      height: "100vh",
      // backgroundColor: "green",
    },
  },

  setting_body_content: {
    padding: "0% 10%",
    height: "100%",

    [theme.breakpoints.down("764")]: {
      padding: "0% 5%",
    },
  },
  title: {
    // fontFamily: "Poppins",
    fontSize: "clamp(14px, 5vw, 40px)",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    marginTop: "3%",
  },

  tab_options: {
    display: 'flex',
    [theme.breakpoints.down('xs')]:{
      display: 'none'
    }
  },

  tab_selected_title: {
    marginTop: "2%",
    fontSize: "20px",//clamp(10px, 2.5vw, 20px)
    fontWeight: "normal",
    lineHeight: "1.5",
    textAlign: "left",
    color: "#3973a5",
    textDecoration: "underline",
    textDecorationSkipInk: "none",
    paddingRight: "6%",
    [theme.breakpoints.down('xs')]:{
      textDecoration: "none",
    },
    [theme.breakpoints.down('599')]:{
      fontSize: "14px",//clamp(10px, 2.5vw, 20px)
    }
  },

  tab_title: {
    marginTop: "2%",
    fontSize: "20px",//clamp(10px, 2.5vw, 20px)
    fontWeight: "normal",
    lineHeight: "1.5",
    textAlign: "left",
    color: "black",
    paddingRight: "6%",
    cursor: "pointer",
    [theme.breakpoints.down('599')]:{
      fontSize: "14px",//clamp(10px, 2.5vw, 20px)
    }
  },
  down_arror: {
    width: "10px",
    height: "10px",
    marginLeft: '10px',
    marginTop: '2px',
    alignSelf: "center",
    position: 'relative',
    // [theme.breakpoints.down("xs")]: {
    //   display: 'block'
    // },
    [theme.breakpoints.up("sm")]: {
      display: 'none'
    },
    // [theme.breakpoints.up("md")]: {
    //   width: "8px",
    //   height: "8px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   width: "10px",
    //   height: "10px",
    // },
  },
  down_arror_dropdown: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '90%',
    padding: '20px',
    borderRadius: '5px',
    zIndex: '999',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
  }
}));
