/* eslint-disable no-lone-blocks */
import React, { useEffect, useRef, useState } from "react";
import AWS from 'aws-sdk'
import './Chat.css'
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import socket from "../../../Service/SocketIo";
import {
  Divider,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import IMAGES from "../../Images";
import { API } from "../../../Service/constants";
import {
  booking_details_for_payment_API,
  cancel_payment,
  chat_history_API,
  chat_status_API,
  create_pay,
  online_status_API,
  payment_cash_API,
  payment_initiate_API,
  transaction_details_API,
  update_transaction_API,
} from "../../../Service/api";
import moment from "moment";
import {
  HighlightOff,
  RemoveCircle,
  ArrowBackIos,
  Close,
  FiberManualRecord,
} from "@material-ui/icons";
import { Navigate } from "react-router-dom";
import { PageHeader } from "../page-header/PageHeader";
import {
  openChat,
  tap_pay_page,
  payload_detail,
  popup_page,
  transaction_detail,
} from "../../../redux/slices";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Loader from "../../Loader";
import Payment from "../../Common/Payment/Payment";
import { GoSell, config } from "@tap-payments/gosell";
import PopupPage from "../../Common/PopupPage/PopupPage";
import useWindowDimensions from "../../../UI kit/useWindowDimensions";
import { key } from "localforage";

const spaceRegex = new RegExp(/^(?!\s).*/);

export const Chat = ({ setLoadChatPersonAPI }) => {
  const navigate = useNavigate();
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const auth = useSelector((state) => state?.auth);
  const dash = useSelector((state) => state?.dashboard);
  const dispatch = useDispatch();
  const [typedTextMsg, setTypedTextMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [roomtype, setRoomType] = useState("");
  const [users, setUsers] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [mediaFile, setMediaFile] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  let newMsgRef = useRef();
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("767"));
  const [roomName, setRoomName] = useState(null);
  const chatRef = useRef(null);
  const [boatBookingList, setBoatBookingList] = useState([]);
  const [myBookingList, setMybookingList] = useState([])
  const [initiatePayment, setInitiatePayment] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState(null);
  // const [openPayment, setOpenPayment] = useState()
  const [amount, setAmount] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openTapPay, setOpenTapPay] = useState(false);
  const [msgItem, setMsgItem ] = useState(null)
  const [count, setCount] = useState(0)
  const {width} = useWindowDimensions()
  const [uploadCopy, setUploadCopy] = useState(null)

  const scrollRef = useRef(null)

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [chatHistory,allMessages]);

  // useEffect(() => {
  //   if (chatRef) {
  //     chatRef.current.addEventListener("DOMNodeInserted", (event) => {
  //       const { currentTarget: target } = event;
  //       target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  //     });
  //   }
  // }, []);

  useEffect(()=>{
    if(width > 767){
      navigate('/allMessage')
    }
  },[width])

  useEffect(()=>{
    console.log(dash?.Opened_chat_data,'update online')
    if(dash?.Opened_chat_data?.user_boat){
      console.log('update online',dash?.Opened_chat_data?.user_boat)
      setOnlineStatus(dash?.Opened_chat_data?.user_boat?.isOnline)
    } else {
      setOnlineStatus(dash?.Opened_chat_data?.boat_user?.isOnline)
    }
  },[chatHistory])

  useEffect(()=>{
    console.log(msgItem,'msgitem',allMessages)
    if(msgItem && msgItem?.room_name === dash?.Opened_chat_data?.room_name){
      if(msgItem?.type === 2 || msgItem?.type === 5){
        allMessages.pop()
      }
      setAllMessages((prevMsg) => [...prevMsg, msgItem])
      // setCount(msgItem?.count)
    }
  },[msgItem])

  // console.log(dash?.Opened_chat_data,'update online',dash?.Opened_chat_data?.user_boat?.isOnline)

  useEffect(()=>{
    if(auth?.userId === dash?.Opened_chat_data?.boat_owner_id){
      dispatch(tap_pay_page(false))
    }
  },[])

  useEffect(() => {
    if ( dash?.tap_pay_page) {
      handlePaymentStatus();
    }
  }, []);

  // socket
  useEffect(() => {
    const user = dash?.Opened_chat_data?.user_id;
    const room = dash?.Opened_chat_data?.boat_owner_id;
    const roomtype = auth?.userDetails?.user_type === "CUSTOMER" ? 1 : 2;

    console.log("userId, BoatId, roomtype", user, room, roomtype);
    if (user && room && roomtype) {
      setRoomType(roomtype);

      if(width > 767){
        socket.connect(auth?.AuthToken);
      }

      //   socket.client.on("connect", () => {
      //     console.log("connected");
      //   });

      // userId, BoatId, roomtype //{ user, room, roomtype }
      socket.client.emit("join", { user, room, roomtype }, (err) => {
        if (err) {
          console.error("socket.emit join err", err);
        }
      });

      // return () => {
      //   socket.disconnect();
      // };
    }
  }, [dash?.Opened_chat_data?.room_name]);

  console.log(socket,'socket_rendering')

  // chat history API
  useEffect(() => {
    setIsLoading(true);
    setAllMessages([]);
    setChatHistory([]);
    let payload = {
      customer_id: dash?.Opened_chat_data?.user_id,
      boat_owner_id: dash?.Opened_chat_data?.boat_owner_id,
      type: auth?.userDetails?.user_type,
    };
    setChatHistory([]);
    chat_history_API(auth?.AuthToken, payload)
      .then((res) => {
        if (res?.data?.success) {
          // toast.success("chat history", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
          setIsLoading(false);
          // console.log("chat history res", res?.data);
          setChatHistory(res?.data?.data);
          handleRead();
        } else {
          setIsLoading(false);
          toast.error("Something went wrong. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err?.response?.data?.message??"chat history err", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth?.AuthToken,
    auth?.userDetails?.user_type,
    dash?.Opened_chat_data?.boat_owner_id,
    dash?.Opened_chat_data?.user_id,
  ]);

  window.onbeforeunload = function (e){
    e.preventDefault()
    socket?.client?.emit("online_status", auth?.userId, false, (err) => {
      console.log("online status socket result", err);
    });
  }

  //online status
  useEffect(() => {
    // This function will be called when the component mounts
    console.log("Component mounted");
    socket?.client?.emit("online_status", auth?.userId, true, (err) => {
      console.log("online status socket result", err);
    });

    // This function will be called when the component unmounts
    return () => {
      console.log("Component will unmount", socket);
      socket?.client?.emit("online_status", auth?.userId, false, (err) => {
        console.log("online status socket err", err);
      });
      // socket.disconnect();
    };
  }, []);

  // socket.on
  useEffect(() => {
    console.log("online emit");
    setOnlineStatus(false);
    // const handleChatMsg = async(msg) => {
    //   console.log(msg, "msg readed");
    //   console.log(
    //     chatDetail?.room_name,
    //     "room name",
    //     msg?.room_name,chatDetail?.room_name === msg?.room_name
    //   );
    //   if (chatDetail?.room_name === msg?.room_name) {
    //     console.log('im got this msg',dash?.Opened_chat_data?.room_name,msg?.room_name)
    //     setAllMessages((prevMsg) => [...prevMsg, msg]);
    //   }
    // };
    socket.client.on("message", (msg)=>{
      setMsgItem(msg)
    });

    socket.client.on("roomMembers", (users) => {
      setUsers(users);
    });

    socket.client.on("chatRoomInfo", (res) => {
      let room_name = res?.room;
      setRoomName(room_name);
    });

    let user_id =
      auth?.userId === dash?.Opened_chat_data?.boat_owner_id
        ? dash?.Opened_chat_data?.user_id
        : dash?.Opened_chat_data?.boat_owner_id;

    socket.client.emit("online_status_receiver", user_id, (err) => {
      console.log("online status socket result", err);
    });

    socket.client.on("update online", (status) => {
      console.log(status,'update online')
      if (status?.userId === user_id) {
        setOnlineStatus(status?.isOnline);
      }
      // else {
      //   setOnlineStatus(false);
      // }
    });

    socket.client.on("online_status_sent", (status) => {
      // console.log(status, "online status sent");
      console.log(status,'update online')
      if (status?.userId === user_id) {
        // console.log("status condition");
        setOnlineStatus(status?.isOnline);
      }
    });

    socket.client.on("chat_status_sent", (status) => {
      console.log(status, "chat status online");
      if (status?.boatOwnerId === dash?.Opened_chat_data?.boat_owner_id && 
        dash?.Opened_chat_data?.user_id === status?.userId ) {
        let chat_status = { ...dash?.Opened_chat_data, status: status?.status };
        dispatch(openChat(chat_status));
      }
    });

    return () => {
      // socket.off("message");
    };
  }, []);

  //read message socket emit
  useEffect(() => {
    handleRead();
  }, [allMessages]);

  // handle read
  const handleRead = () => {
    let opposite_userId =
      // auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
      auth?.userId === dash?.Opened_chat_data?.boat_owner_id
        ? dash?.Opened_chat_data?.user_id
        : dash?.Opened_chat_data?.boat_owner_id;
    let user_id =
      // auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
      auth?.userId === dash?.Opened_chat_data?.boat_owner_id
        ? dash?.Opened_chat_data?.boat_owner_id
        : dash?.Opened_chat_data?.user_id;

    socket.client.emit(
      "getLastMessage",
      dash?.Opened_chat_data?.chat_id,
      (err) => {
        if (err) {
          console.log(err, "err emit");
        } else {
          console.log("emit done");
        }
      }
    );

    // console.log(
    //   'opposite user id',
    //   opposite_userId,
    //   auth?.userId === dash?.Opened_chat_data?.boat_owner_id,
    //   'boat owner',
    //   dash?.Opened_chat_data?.boat_owner_id,
    //   'customer',
    //   dash?.Opened_chat_data?.user_id
    // )

    socket.client.emit("readMessage", opposite_userId,dash?.Opened_chat_data?.chat_id, (err) => {
      if (err) {
        console.log("readmessage err", err);
      } else {
        console.log("readmessage done");
      }
    });

    socket.client.emit(
      "getUnreadMessages",
      opposite_userId,
      dash?.Opened_chat_data?.chat_id,
      (err) => {
        if (err) {
          console.log(err, "err emit");
        } else {
          console.log("emit done");
        }
      }
    );

    if(auth?.userDetails?.user_type === 'BOAT_OWNER' && 
      dash?.Opened_chat_data?.user_boat?.user_type === 'CUSTOMER'){
      setTimeout(() => {
        socket.client.emit("getUnreadChatRoomsCountforcus", dash?.Opened_chat_data?.user_id, (err) => {
          console.log("getunread chat room count err", err);
        });
      }, 1000);
    }

    if(auth?.userDetails?.user_type === 'BOAT_OWNER' && 
      dash?.Opened_chat_data?.user_boat?.user_type === 'BOAT_OWNER'){
      setTimeout(() => {
        socket.client.emit("getUnreadChatRoomsCount", dash?.Opened_chat_data?.user_id, (err) => {
          console.log("getunread chat room count err", err);
        });
      }, 1000);
    }

    if(auth?.userDetails?.user_type === 'BOAT_OWNER' && 
      dash?.Opened_chat_data?.boat_user?.user_type === 'BOAT_OWNER'){
      setTimeout(() => {
        socket.client.emit("getUnreadChatRoomsCount", dash?.Opened_chat_data?.boat_owner_id, (err) => {
          console.log("getunread chat room count err", err);
        });
      }, 1000);
    }

    if(auth?.userDetails?.user_type === 'CUSTOMER'){
      setTimeout(() => {
        socket.client.emit("getUnreadChatRoomsCount", dash?.Opened_chat_data?.boat_owner_id, (err) => {
          console.log("getunread chat room count err", err);
        });
      }, 1000);
    }

    if(auth?.userDetails?.user_type === 'BOAT_OWNER' && auth?.userId === dash?.Opened_chat_data?.boat_owner_id){
      setTimeout(() => {
        socket.client.emit("getUnreadChatRoomsCount", dash?.Opened_chat_data?.boat_owner_id, (err) => {
          console.log("getunread chat room count err", err);
        });
      }, 1000);
    }

    if(auth?.userDetails?.user_type === 'BOAT_OWNER' && auth?.userId === dash?.Opened_chat_data?.user_id){
      setTimeout(() => {
        socket.client.emit("getUnreadChatRoomsCount", dash?.Opened_chat_data?.user_id, (err) => {
          console.log("getunread chat room count err", err);
        });
      }, 1000);
    }

    if(auth?.userDetails?.user_type === 'CUSTOMER'){
      setTimeout(() => {
        socket.client.emit("getUnreadChatRoomsCountforcus", dash?.Opened_chat_data?.user_id, (err) => {
          console.log("getunread chat room count err", err);
        });
      }, 1000);
    }
  };

  const handle_navigation = (name) => {
    toast.dismiss();
    if (name === "Home") {
      navigate("/boatOwnerDashBoard");
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleCloseModal = () => {
    if (auth?.userId === dash?.Opened_chat_data?.boat_owner_id) {
      setShowModal(!showModal);
      // setBookingList(null);
    } else {
      setShowModal1(!showModal1);
      // setBookingList(null);
    }
  };

  const handleSendImage = async(mediaFile) =>{

    console.log(mediaFile?.name,'file name')
    const imgSize = mediaFile?.size / 1024 / 1024
    console.log(imgSize,'image size')
    if(imgSize > 3){
      toast.error('Image size should be below 2MB',{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      })
      setMediaFile('')
      return
    }

    let userId =
    dash?.Opened_chat_data?.boat_owner_id === auth?.userId
      ? dash?.Opened_chat_data?.boat_owner_id
      : dash?.Opened_chat_data?.user_id;
  let opposite_userId =
    dash?.Opened_chat_data?.boat_owner_id === auth?.userId
      ? dash?.Opened_chat_data?.user_id
      : dash?.Opened_chat_data?.boat_owner_id;

    const S3_BUCKET = 'sauditest'
    const REGION = 'ap-south-1'

    AWS.config.update({
      accessKeyId: 'AKIA47CRVOG4SZDRUXWC',
      secretAccessKey: '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'
    })

    const s3 = new AWS.S3({
      params: {Bucket: S3_BUCKET},
      region: REGION
    })

    const params = {
      Bucket: S3_BUCKET,
      Key: `chat/${mediaFile?.name}`,
      Body: mediaFile
    }

    let upload = s3
    .putObject(params)
    .on('httpUploadProgress',(event)=>{
      console.log(
        "Uploading " + parseInt((event.loaded * 100) / event.total) + "%"
      )
    })
    setAllMessages(
      (prev)=>[...prev,
        {
          message: URL.createObjectURL(mediaFile),
          type: 0,
          first_name:auth?.userDetails?.first_name,
          room_name: dash?.Opened_chat_data?.room_name,
        }
      ]
    )
    setMediaFile("")
    setUploadCopy(upload)

    try {
      const data = await upload.promise();
      console.log('File Uploaded Successfully');
      console.log(data, 'data');
      socket.client.emit(
        "sendMessage",
        // roomName,
        dash?.Opened_chat_data?.room_name,
        2,
        `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/chat/${mediaFile?.name}`,
        userId,
        auth?.userDetails?.first_name,
        (err) => {
          if (err) {
            console.log("mediafile command error", err);
          } else {
            // setMediaFile("");
          }
        }
      );
      setMediaFile("");
      // alert('File Uploaded Successfully');
    } catch (err) {
      // setMediaFile('')
      console.error('Error uploading file:', err);
      alert('Error uploading file');
    }
  }

  const handleSendVideo = async(mediaFile) =>{
    const videoSize = mediaFile?.size / 1024 / 1024
    console.log(videoSize,'image size')
    if(videoSize > 11){
      toast.error('Video size should be below 10MB',{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000
      })
      setMediaFile('')
      return
    }
    let userId =
    dash?.Opened_chat_data?.boat_owner_id === auth?.userId
      ? dash?.Opened_chat_data?.boat_owner_id
      : dash?.Opened_chat_data?.user_id;
  let opposite_userId =
  dash?.Opened_chat_data?.boat_owner_id === auth?.userId
      ? dash?.Opened_chat_data?.user_id
      : dash?.Opened_chat_data?.boat_owner_id;

    const S3_BUCKET = 'sauditest'
    const REGION = 'ap-south-1'

    AWS.config.update({
      accessKeyId: 'AKIA47CRVOG4SZDRUXWC',
      secretAccessKey: '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'
    })

    const s3 = new AWS.S3({
      params: {Bucket: S3_BUCKET},
      region: REGION
    })

    const params = {
      Bucket: S3_BUCKET,
      Key: `chat/${mediaFile?.name}`,
      Body: mediaFile
    }

    let upload = s3
    .putObject(params)
    .on('httpUploadProgress',(event)=>{
      console.log(
        "Uploading " + parseInt((event.loaded * 100) / event.total) + "%"
      )
    })
    setAllMessages(
      (prev)=>[...prev,
        {
          message: IMAGES?.VIDEO_IMG,
          type: 0,
          first_name:auth?.userDetails?.first_name,
          room_name: dash?.Opened_chat_data?.room_name,
        }
      ]
    )
    setMediaFile('')
    setUploadCopy(upload)

    try {
      const data = await upload.promise();
      console.log('File Uploaded Successfully');
      console.log(data, 'data');
      socket.client.emit(
        "sendMessage",
        // roomName,
        dash?.Opened_chat_data?.room_name,
        5,
        `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/chat/${mediaFile?.name}`,
        userId,
        auth?.userDetails?.first_name,
        (err) => {
          if (err) {
            console.log("mediafile command error", err);
          } else {
            // setMediaFile("");
          }
        }
      );
      // setMediaFile("");
      // alert('File Uploaded Successfully');
    } catch (err) {
      console.error('Error uploading file:', err);
      alert('Error uploading file');
    }
    // setMediaFile("")
  }

  const handleCancelUploadVideo = () =>{
    uploadCopy.abort()
    allMessages.pop()
  }

  // send Message
  const sendMessage = (e) => {
    // console.log("e", e.target.value);
    e.preventDefault();
    let userId =
    dash?.Opened_chat_data?.boat_owner_id === auth?.userId
        ? dash?.Opened_chat_data?.boat_owner_id
        : dash?.Opened_chat_data?.user_id;
    let opposite_userId =
    dash?.Opened_chat_data?.boat_owner_id === auth?.userId
        ? dash?.Opened_chat_data?.user_id
        : dash?.Opened_chat_data?.boat_owner_id;

    // 1==> normal msg
    // 2==> img msg
    console.log("typed message", typedTextMsg);
    console.log("selected mediaFile", mediaFile);
    if (mediaFile) {
      const extenstion = mediaFile?.name?.split('.').at(-1)
      if(extenstion === 'mp4'){
        handleSendVideo(mediaFile)
      } else {
        handleSendImage(mediaFile)
      }
    } else {
      console.log("message", roomName, userId);
      let user_id = socket.client.emit(
        "sendMessage",
        // roomName,
        dash?.Opened_chat_data?.room_name,
        // chatDetail?.room_name,
        1,
        typedTextMsg,
        userId,
        auth?.userDetails?.first_name,
        (err) => {
          // setTypedTextMsg("");
          console.log("send message err", err);
        }
      );
      setTypedTextMsg("");
    }
    handleRead();
  };

  //handle status request
  const handle_status_req = (status, reason) => {
    setIsLoading(true);
    let payload = {
      customer_id: dash?.Opened_chat_data?.user_boat?.user_id,
      status: status,
    };
    if (status === "REJECT") {
      payload["reason"] = reason;
    }
    console.log("chat status payload", payload);
    chat_status_API(auth?.AuthToken, payload)
      .then((res) => {
        console.log("chat status res", res?.data);
        if (res?.data?.success) {
          if (auth?.userId === dash?.Opened_chat_data?.boat_owner_id) {
            socket.client.emit(
              "chat_status",
              dash?.Opened_chat_data?.user_boat?.user_id,
              dash?.Opened_chat_data?.boat_owner_id,
              roomName,
              status,
              (err) => {
                console.log(err);
              }
            );
          }

          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });

          let chat_status = { ...dash?.Opened_chat_data, status: status };
          dispatch(openChat(chat_status));
        } else {
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        setIsLoading(false);
        setLoadChatPersonAPI(Math.random() * 100);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    // const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // if (allowedExtensions.includes(fileExtension)) {
      setMediaFile(file);
      // } else {
      //   alert(
      //     "Invalid file extension. Please select a file with extensions: jpg, jpeg, png, pdf"
      //   );
      // }
    }
  };
  console.log(myBookingList,'my booking list')

  const handleCancelPayment = (bookingId,boatOwnerId) => {
    setIsLoading(true)
    cancel_payment(auth?.AuthToken, { boat_booking_id: bookingId })
      .then((response) => {
        console.log('Cancellation response:', response);
        const updatedMybookingList = myBookingList?.filter((prev)=> prev?.booking_id !== bookingId)
        setMybookingList(updatedMybookingList)
        toast.success(response.data.message)
        socket.client.emit("getUnreadCountNotification", boatOwnerId, (err) => {
          console.log("getunread count notification err", err);
        });
        socket.client.emit("getUnreadCountNotification", auth?.userId, (err) => {
          console.log("getunread count notification err", err);
        });
      })
      .catch((error) => {
        console.error('Error cancelling payment:', error);
        toast.success(error.message)
      });
      setIsLoading(false)
      setShowModal(false);
  };

  //boat owner boat bookings
  const handleGetBoatBookings = () =>{
    // setShowModal(true);
    let payload = {
      user_id: dash?.Opened_chat_data?.user_id,
      boat_owner_id: dash?.Opened_chat_data?.boat_owner_id,
    };
    booking_details_for_payment_API(auth?.AuthToken, payload)
      .then((res) => {
        console.log(res?.data);
        if (res?.data?.success) {
          setBoatBookingList(res?.data?.data);
        } else {
          console.log("something went wrong in bookinglist api");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("booking list api=>", err);
        setIsLoading(false);
      })
  }

  const handleGetMyBookings = () =>{
    // setShowModal1(true);
    console.log("customer modal");
    let payload = {
      boat_owner_id: dash?.Opened_chat_data?.boat_owner_id,
    };
    transaction_details_API(auth?.AuthToken, payload)
      .then((res) => {
        console.log(res?.data);
        if (res?.data?.data?.length) {
          setMybookingList(res?.data?.data);
        } else {
          console.log("something went wrong in transaction details api");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err?.response?.data?.message??"transaction details api=>", err);
        setIsLoading(false);
      })
  }

  //pay button API Trip details
  const handlePayBtn = () => {
    setIsLoading(true);
    if (auth?.userId === dash?.Opened_chat_data?.boat_owner_id) {
      console.log("boat owner modal");
      setShowModal(true)
      handleGetBoatBookings()
      handleGetMyBookings()
    } else {
      setShowModal1(true)
      handleGetMyBookings()
    }
  };

  // const handleOpenTapPay = (amount, transaction_id) => {
  //   console.log(transaction_id);
  //   setAmount(amount);
  //   dispatch(
  //     payload_detail({
  //       amount: amount,
  //       transaction_id: transaction_id,
  //     })
  //   );
  //   // setOpenPayment(true)
  //   GoSell.openLightBox();
  //   GoSell.openPaymentPage();
  //   dispatch(tap_pay_page(true));
  //   // config()
  //   setIsLoading(true);
  // };
  const handleOpenTapPay = (transaction_id) => {
    console.log(transaction_id);
    dispatch(
      payload_detail({
        transaction_id: transaction_id,
      })
    );
  
    const payload = {
      transactionId:transaction_id
    };
  
    create_pay(auth?.AuthToken, payload)
      .then((res) => {
        console.log(res, "create charge res?.data");
        if (res?.status === 200) {
          const url = res?.data; 
          if (url) {
            window.location.href = url; 
          }
        }
      })
      .catch((error) => {
        console.error("Error creating charge:", error);
      });
  
    dispatch(tap_pay_page(true));
    setIsLoading(true);
  };
  

  const handleClosePaymentModal = () => {
    console.log("payment closed");
  };

  const handleCallbackFunc = (value) => {
    console.log(value, "value callback");
    if (value) {
      dispatch(transaction_detail(value));
    }
  };

  const handlePaymentStatus = () => {
    let opposite_userId =
    dash?.Opened_chat_data?.boat_owner_id === auth?.userId
        ? dash?.Opened_chat_data?.user_id
        : dash?.Opened_chat_data?.boat_owner_id;

    let user_id =
    dash?.Opened_chat_data?.boat_owner_id === auth?.userId
        ? dash?.Opened_chat_data?.boat_owner_id
        : dash?.Opened_chat_data?.user_id;
      
    let payload = {
      transaction_id: dash?.payload_detail?.transaction_id, //dash?.transaction_detail?.callback?.reference?.transaction,
      // charge_id: dash?.transaction_detail?.callback?.id,
      // currency: dash?.transaction_detail?.callback?.currency,
      // ref_id: dash?.transaction_detail?.callback?.reference?.track,
      // amount: dash?.transaction_detail?.callback?.amount,
      payment_mode: dash?.transaction_detail?.callback?.source?.payment_method,
      // status:
      //   dash?.transaction_detail?.callback?.status === "CAPTURED"
      //     ? "PAID"
      //     : "FAILED",
      // transaction_id: '35',
      // currency: "USD",
      // ref_id:"212122",
      // amount: 100,
      // payment_mode: "card",
      // status: "PAID",
      // charge_id: '14526267'
    };
    console.log(payload, "transaction payload");

    update_transaction_API(auth?.AuthToken, payload)
      .then((res) => {
        let tripDetails = `
        I have paid for my booking
        Payment Details
        Order ID: ${res?.data?.transaction?.order_id},
        Amount: ${res?.data?.transaction?.amount} ${res?.data?.transaction?.currency},
      `
        console.log(res, "payment res?.data");
        if (res?.status === 200) {

          socket.client.emit(
            "sendMessage",
            // roomName,
            dash?.Opened_chat_data?.room_name,
            4,
            tripDetails,
            user_id,
            auth?.userDetails?.first_name,
            (err) => {
              if (err) {
                console.log("mediafile command error", err);
              } else {
                // setMediaFile("");
              }
            }
          );

          socket.client.emit("getUnreadCountNotification", user_id, (err) => {
            console.log("getunread count notification err", err);
          });

          socket.client.emit("getUnreadCountNotification", opposite_userId, (err) => {
            console.log("getunread count notification err", err);
          });

          dispatch(transaction_detail(null));
          dispatch(payload_detail(null));
          dispatch(tap_pay_page(false));
          // navigate("/bookedReqDetails",{state:{boat_booking_id:res?.data?.transaction?.booking_id}});
        }
      })
      .catch((err) => {
        console.log(console.log("payment api err ", err));
        // setTimeout(() => {
        dispatch(transaction_detail(null));
        dispatch(payload_detail(null));
        dispatch(tap_pay_page(false));
      });
  };

  // console.log(bookingList,'payment initiate res')

  // payment initiate API
  const handlePaymentInitiate = (
    boat_id,
    boat_booking_id,
    user_id,
    duration,
    duration_type,
    price,
    booking_date,
    start_time,
    end_time,
    adults,
    children,
    infants,
    seniors,
    special_request
  ) => {
    let userId =
    dash?.Opened_chat_data?.boat_owner_id === auth?.userId
      ? dash?.Opened_chat_data?.boat_owner_id
      : dash?.Opened_chat_data?.user_id

    let opposite_userId =
    dash?.Opened_chat_data?.boat_owner_id === auth?.userId
        ? dash?.Opened_chat_data?.user_id
        : dash?.Opened_chat_data?.boat_owner_id

    let payload = {
      user_id: user_id,
      boat_id: boat_id,
      booking_id: boat_booking_id,
      // price: price,
      hours: duration,
      chat_id: dash?.Opened_chat_data?.chat_id,
    };
    setShowModal(false);
    setIsLoading(false)
    console.log("payload", payload);
    payment_initiate_API(auth?.AuthToken, payload)
      .then((res) => {
        console.log(res?.data,'payment initiate res');
        let tripDetails = `
          Your boat booking is confirmed by boat owner,
          Trip Details,
          booking_date: ${moment(booking_date)?.format('DD-MM-YYYY')},
          depart: ${`${moment(start_time,'HH:mm:ss').format('h:mm A')}`},
          return: ${`${moment(end_time,'HH:mm:ss').format('h:mm A')}`},
          duration: ${`${duration_type}`},
          Guests: ${adults+children+infants+seniors},
          special_request: ${special_request?? ''}
        `
        if (res?.data?.success) {
          let updateBookingList = boatBookingList?.map((item) => {
            if (item?.boat_booking_id === boat_booking_id) {
              return { ...item, trip_status: "TRIP_INITIATED" };
            }
            return item;
          });
          setBoatBookingList(updateBookingList);
          console.log(tripDetails,'payment initiate res')

          socket.client.emit(
            "sendMessage",
            // roomName,
            dash?.Opened_chat_data?.room_name,
            3,
            tripDetails,
            userId,
            auth?.userDetails?.first_name,
            (err) => {
              if (err) {
                console.log("mediafile command error", err);
              } else {
                // setMediaFile("");
              }
            }
          );

          socket.client.emit("getUnreadCountNotification", opposite_userId, (err) => {
            console.log("getunread count notification err", err);
          });
          
        }
      })
      .catch((err) => {
        console.error("payment initiate api err", err);
      });
  };

  const handleOpenPopup = (status) => {
    dispatch(
      popup_page({
        ride_mode: status,
      })
    );
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    dispatch(popup_page(null));
  };

  const handleStatusPopup = (status) => {
    console.log(status,'status')
    if (status?.button === "accept") {
      handle_status_req(status?.status, status?.reason);
      setOpenPopup(false);
    } else {
      setOpenPopup(false);
    }
    dispatch(popup_page(null));
  };

  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th'; // Exceptions for 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  const handleDate = (date) =>{
    // Get the day without leading zeros
    const day = moment.utc(date).format("D");

    // Add the ordinal suffix
    const dayWithSuffix = day + getOrdinalSuffix(day);

    // Format the rest of the date
    const formattedDateWithoutDay = moment.utc(date).format("MMMM YYYY [AT] hh:mm A");

    // Combine the formatted day with the rest of the date
    const finalFormattedDate = `${dayWithSuffix} ${formattedDateWithoutDay}`;

    return finalFormattedDate
  }

  return (
    <>
      <Loader loading={isLoading} />
      <div className={class_name.chat_container} id="chat_container_id">
        <div className="d-flex flex-column">
          <div className={class_name.page_header}>
            <PageHeader
              showLoginSignUp={auth?.AuthToken ? false : true}
              handle_navigation={handle_navigation}
              // presentPage={"Home"}
              link1={"Boat Offers"}
              link2={"Scuba Courses/Programs"}
              link3={"Scuba Diving Trips"}
              link4={
                auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
                  ? "My Listings"
                  : null
              }
            />
          </div>
          <div className={class_name.header_name_div}>
            <div
              onClick={() => navigate(-1)}
              className={class_name.back_arrow_icon}
            >
              {/* <IconButton> */}
              <ArrowBackIos />
              {/* </IconButton> */}
            </div>
            <div className="d-flex gap-2 align-items-center">
              {auth?.userId === dash?.Opened_chat_data?.boat_owner_id ? (
                <span className={class_name.header_name_txt}>
                  {dash?.Opened_chat_data?.user_boat?.first_name}{" "}
                  {dash?.Opened_chat_data?.user_boat?.last_name ?? ""}
                </span>
              ) : (
                <span className={class_name.header_name_txt}>
                  {dash?.Opened_chat_data?.boat_user?.first_name}{" "}
                  {dash?.Opened_chat_data?.boat_user?.last_name ?? ""}
                </span>
              )}
              <span>
                {onlineStatus ? (
                  <span>
                    <FiberManualRecord className="text-success" />
                  </span>
                ) : (
                  <span>
                    <FiberManualRecord className="text-danger" />
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div
          id="chat_body"
          className={class_name.message_show_container}
          ref={chatRef}
        >
          {dash?.Opened_chat_data?.status === "ACCEPT" ||
          dash?.Opened_chat_data?.status === "PENDING" ||
          dash?.Opened_chat_data?.status === "REJECT" ? (
            <>
              {chatHistory?.map((item, i) => {
                // if (index === chatHistory?.length - 1) {
                //   newMsgRef.current.focus();
                // }
                if (item?.user_id === auth?.userDetails?.user_id) {
                  return (
                    <div
                      style={{
                        padding: "2% 0",
                      }}
                      key={i}
                    >
                      <Typography className={class_name.time_txt_style}>
                        {handleDate(item?.createdAt)}
                        {/* {moment
                          .utc(item?.createdAt)
                          .format("DD MMMM YYYY [AT] hh:mm A")} */}
                          {/*DD MMMM YYYY [AT] hh:mm A*/}
                      </Typography>

                      <div
                        className={class_name.row_style}
                        style={{
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography
                          className={class_name.name_txt_style}
                          style={{ paddingRight: "2.7%" }}
                        >
                          {auth?.userDetails?.first_name}{" "}
                          {auth?.userDetails?.last_name ?? ""}
                        </Typography>
                        <img
                          alt="profile"
                          src={
                            auth?.userDetails?.profile_image
                              ? `
                                  ${
                                    auth?.userDetails?.profile_image //${API.baseUrls[API.currentEnv]}
                                  }`
                              : IMAGES.PROFILE_ICON
                          }
                          className={class_name.profile_icon_style}
                        />
                      </div>
                      <div
                        className={class_name.row_style}
                        style={{
                          justifyContent: "flex-end",
                          // wordBreak: "break-word",
                        }}
                      >
                        <Typography
                          className={class_name.msg_txt_style_right}
                          style={{ paddingRight: "2.7%" }}
                        >
                          {item?.type === 3 ? (
                            item?.message.split(",").map((msg, index) => (
                              <React.Fragment key={index}>
                                {msg}
                                <br />
                              </React.Fragment>
                            ))
                          ) : item?.type === 2 ? (
                            <img
                              src={item?.message}
                              alt="image"
                              className={class_name.chat_image}
                            />
                          ) :item?.type === 5?(
                            <video
                            src={item.message}
                            controls
                            style={{
                              width: '50%',
                              height: 'auto',
                              objectFit: 'cover'
                            }}
                            />
                          )
                          :(
                            item?.message
                          )
                          }
                        </Typography>
                        <div style={{ width: "clamp(35px, 4.9vw, 60px)" }} />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div style={{ padding: "2% 0" }}>
                      <Typography className={class_name.time_txt_style}>
                      {handleDate(item?.createdAt)}
                        {/* {moment
                          .utc(item?.createdAt)
                          .format("DD MMMM YYYY [AT] hh:mm A")} */}
                      </Typography>
                      <div className={class_name.row_style}>
                        <img
                          alt="profile"
                          src={
                            dash?.Opened_chat_data?.boat_user?.profile_image
                              ? `${
                                  dash?.Opened_chat_data?.boat_user //${API.baseUrls[API.currentEnv]}
                                    ?.profile_image
                                }`
                              : dash?.Opened_chat_data?.user_boat?.profile_image
                              ? `
                                  ${
                                    dash?.Opened_chat_data?.user_boat //${API.baseUrls[API.currentEnv]}
                                      ?.profile_image
                                  }`
                              : IMAGES.PROFILE_ICON
                          }
                          className={class_name.profile_icon_style}
                        />
                        <Typography className={class_name.name_txt_style}>
                          {dash?.Opened_chat_data?.boat_user?.first_name ??
                            dash?.Opened_chat_data?.user_boat?.first_name ??
                            "User Name"}{" "}
                          {dash?.Opened_chat_data?.boat_user?.last_name ??
                            dash?.Opened_chat_data?.user_boat?.last_name ??
                            ""}
                        </Typography>
                      </div>
                      <div
                        className={class_name.row_style}
                        style={{
                          justifyContent: "flex-start",
                          // wordBreak: "break-word",
                        }}
                      >
                        <div style={{ width: "clamp(35px, 4.9vw, 60px)" }} />
                        <Typography className={class_name.msg_txt_style}>
                          {item?.type === 3 ? (
                            item?.message.split(",").map((msg, index) => (
                              <React.Fragment key={index}>
                                {msg}
                                <br />
                              </React.Fragment>
                            ))
                          ) : item?.type === 2 ? (
                            <img
                              src={item?.message}
                              alt="image"
                              className={class_name.chat_image}
                            />
                          ) : item?.type === 5?(
                            <video
                            src={item.message}
                            controls
                            style={{
                              width: '50%',
                              height: 'auto',
                              objectFit: 'cover'
                            }}
                            />
                          ):(
                            item?.message
                          )}
                        </Typography>
                      </div>
                    </div>
                  );
                }
              })}
              {allMessages?.length ? (
                <Typography className={class_name.time_txt_style}>
                  Just now
                </Typography>
              ) : null}
              {allMessages?.map((e, i) => (
                <>
                  {e?.first_name === auth?.userDetails?.first_name ? (
                    <>
                      <div
                        style={{
                          padding: "2% 0",
                        }}
                      >
                        <div
                          className={class_name.row_style}
                          style={{
                            justifyContent: "flex-end",
                          }}
                        >
                          <Typography
                            className={class_name.name_txt_style}
                            style={{ paddingRight: "2.7%" }}
                          >
                            {auth?.userDetails?.first_name}{" "}
                            {auth?.userDetails?.last_name ?? ""}
                          </Typography>
                          <img
                            alt="profile"
                            src={
                              auth?.userDetails?.profile_image
                                ? `
                                    ${
                                      auth?.userDetails?.profile_image //${API.baseUrls[API.currentEnv]}
                                    }`
                                : IMAGES.PROFILE_ICON
                            }
                            className={class_name.profile_icon_style}
                          />
                        </div>
                        <div
                          className={class_name.row_style}
                          style={{
                            justifyContent: "flex-end",
                            // wordBreak: "break-word",
                          }}
                        >
                          <Typography
                            className={class_name.msg_txt_style_right}
                            style={{ paddingRight: "2.7%" }}
                          >
                            {
                            e?.type === 0?(
                            <div 
                            style={{
                              position: 'relative'
                            }}
                            >
                              {/* <div
                              style={{
                              position: 'absolute',
                              // inset: '0',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(200%, -50%)'
                              // margin: 'auto',
                            }}
                              >
                                <Loader loading={true}/>
                              </div>
                            <span 
                            style={{
                              position: 'absolute',
                              // inset: '0',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(200%, -50%)'
                              // margin: 'auto',
                            }}
                            >
                              close
                            </span> */}
                              <img
                              src={e?.message}
                              alt="image"
                              className="animate-flicker"
                              />
                            </div>
                            ):
                            e?.type === 3 ? (
                              e?.message.split(",").map((msg, index) => (
                                <React.Fragment key={index}>
                                  {msg}
                                  <br />
                                </React.Fragment>
                              ))
                            ) : e?.type === 2 ? (
                              <img
                                src={e?.message}
                                alt="image"
                                className={class_name.chat_image}
                              />
                            ) : e?.type === 5?(
                              <video
                              src={e.message}
                              controls
                              style={{
                                width: '50%',
                                height: 'auto',
                                objectFit: 'cover'
                              }}
                              />
                            ):
                            (
                              e?.message
                            )}
                          </Typography>
                          <div style={{ width: "clamp(35px, 4.9vw, 60px)" }} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ padding: "2% 0" }}>
                        <div className={class_name.row_style}>
                          <img
                            alt="profile"
                            src={
                              dash?.Opened_chat_data?.boat_user?.profile_image
                                ? `
                                    ${
                                      dash?.Opened_chat_data?.boat_user //${API.baseUrls[API.currentEnv]}
                                        ?.profile_image
                                    }`
                                : dash?.Opened_chat_data?.user_boat
                                    ?.profile_image
                                ? `${
                                    dash?.Opened_chat_data?.user_boat //${API.baseUrls[API.currentEnv]}
                                      ?.profile_image
                                  }`
                                : IMAGES.PROFILE_ICON
                            }
                            className={class_name.profile_icon_style}
                          />
                          <Typography className={class_name.name_txt_style}>
                            {dash?.Opened_chat_data?.boat_user?.first_name ??
                              dash?.Opened_chat_data?.user_boat?.first_name ??
                              "User Name"}{" "}
                            {dash?.Opened_chat_data?.boat_user?.last_name ??
                              dash?.Opened_chat_data?.user_boat?.last_name ??
                              ""}
                          </Typography>
                        </div>
                        <div 
                        className={class_name.row_style}
                        style={{
                          justifyContent: "flex-start",
                          // wordBreak: "break-word",
                        }}
                        >
                          <div style={{ width: "clamp(35px, 4.9vw, 60px)" }} />
                          <Typography className={class_name.msg_txt_style}>
                            {e?.type === 3 ? (
                              e?.message.split(",").map((msg, index) => (
                                <React.Fragment key={index}>
                                  {msg}
                                  <br />
                                </React.Fragment>
                              ))
                            ) : e?.type === 2 ? (
                              <img
                                src={e?.message}
                                alt="image"
                                className={class_name.chat_image}
                              />
                            ) : e.type === 5?(
                              <video
                              src={e.message}
                              controls
                              style={{
                                width: '50%',
                                height: 'auto',
                                objectFit: 'cover'
                              }}
                              />
                            ):
                            (
                              e?.message
                            )}
                          </Typography>
                        </div>
                      </div>
                    </>
                  )}
                </>
              ))}
            </>
          ) : null}
          <div ref={scrollRef}></div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            // height: "auto",
            // backgroundColor: "lightgreen",
          }}
        >
          {dash?.Opened_chat_data?.status === "PENDING" ||
          dash?.Opened_chat_data?.status === "ACCEPT" ||
          dash?.Opened_chat_data?.status === "REJECT" ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {mediaFile !== "" ? (
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "rgba(66, 70, 81, 0.1)",
                    padding: "1% 5%",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      // backgroundColor: "lightseagreen",
                    }}
                  >
                    {mediaFile?.name?.endsWith('.mp4') ? (
                      <img
                        alt="pdf"
                        src={IMAGES.VIDEO_IMG}
                        className={class_name.selectedPdf}
                      />
                    ) : (
                      <img
                        alt="img"
                        src={URL.createObjectURL(mediaFile)}
                        // src={mediaFile}
                        className={class_name.selectedImg}
                      />
                    )}
                    <Typography className={class_name.name_txt_style}>
                      {mediaFile?.name}
                    </Typography>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setMediaFile("");
                    }}
                  >
                    <HighlightOff
                      style={{
                        fontSize: "clamp(10px, 3vw, 25px)",
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {dash?.Opened_chat_data?.status === "PENDING" &&
              dash?.Opened_chat_data?.boat_owner_id === auth?.userId ? (
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      border: "solid 0.5px #3973a5",
                      width: "100%",
                      padding: "3%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleOpenPopup("REJECT");
                      // handle_status_req("REJECT");
                    }}
                  >
                    <Typography className={class_name.decline_txt}>
                      Decline
                    </Typography>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#3973a5",
                      width: "100%",
                      padding: "3%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleOpenPopup("ACCEPT");
                      // handle_status_req("ACCEPT");
                    }}
                  >
                    <Typography className={class_name.accpet_txt}>
                      Accept
                    </Typography>
                  </div>
                </div>
              ) : dash?.Opened_chat_data?.status === "PENDING" &&
                dash?.Opened_chat_data?.user_id === auth?.userId ? (
                <div className="p-5 text-center">
                  <span className={class_name.sryTxt}>
                    Your Request is Pending!
                  </span>
                </div>
              ) : dash?.Opened_chat_data?.status === "ACCEPT" ? (
                <div className={class_name.textBox_container}>
                  <TextField
                    variant="standard"
                    placeholder="Write Your Message"
                    value={typedTextMsg}
                    autoFocus
                    multiline
                    disabled={
                      dash?.Opened_chat_data?.status === "REJECT" ? true : false
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        sendMessage(event);
                      }
                    }}
                    onChange={(event) => {
                      if (spaceRegex.test(event.target.value)) {
                        setTypedTextMsg(event.target.value);
                      }
                    }}
                    style={{ width: "100%", marginRight: "3%", border: "none" }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png,.mp4"
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    ref={fileInputRef}
                  />
                  <div>
                    <IconButton
                      onClick={() => {
                        handlePayBtn();
                      }}
                      style={
                        {
                          // display:
                          // dash?.Opened_chat_data?.user_id === auth?.userId
                          //     ? "flex"
                          //     : "none",
                        }
                      }
                    >
                      <span
                        className={class_name.pay_btn}
                        style={{
                          backgroundColor: "#026b93",
                        }}
                      >
                        pay
                      </span>
                    </IconButton>
                    <Dialog
                      open={showModal}
                      onClose={handleCloseModal}
                      scroll="paper"
                      //  sx={{width: }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Loader loading={isLoading} />
                        <DialogTitle
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "sticky",
                            top: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: "white",
                          }}
                        >
                          <span>Trip Details</span>
                          <span onClick={handleCloseModal}>
                            <Close />
                          </span>
                        </DialogTitle>
                        {myBookingList?.length?
                        <>
                        <div
                          style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            paddingLeft: '30px'
                          }}
                        >
                            My Booking List
                        </div>
                        <DialogContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                            padding: "30px",
                          }}
                          className={class_name.dialog_content}
                        >
                          {myBookingList?.length ? (
                            myBookingList?.map((booking) => (
                              <div
                                key={booking?._id} // Add a key prop for each booking
                                className="d-flex flex-column gap-2 border border-5 p-md-3 p-4 p-sm-5 rounded-2"
                              >
                                <div className="d-flex gap-md-3 gap-2 flex-column flex-md-row">
                                  <div>
                                    <span className="fw-bold">Date: </span>
                                    <span>
                                      {moment(booking?.booking_date).format("DD.MM.YYYY")}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">Duration: </span>
                                    <span>{booking?.hours} hour</span>
                                  </div>
                                </div>
                                <div
                                  className="text-end"
                                  style={{
                                    display: "flex",
                                    // justifyContent: "flex-center", // Align buttons to the right
                                    gap: "2%", // Add some gap between buttons
                                  }}
                                >
                                  <span
                                    className={class_name.pay_btn}
                                    style={{
                                      backgroundColor:
                                        booking?.status === "PAID" ? "green" : "#026b93",
                                      color: "white",
                                      padding: "10px",
                                      borderRadius: "5px",
                                      minWidth: "50%", // Adjust width as needed
                                      textAlign: "center",
                                      cursor: booking?.status !== "PAID" ? "pointer" : "default",
                                    }}
                                    onClick={() =>
                                      booking?.status !== "PAID"
                                        ? handleOpenTapPay(booking?.transaction_id)
                                        : null
                                    }
                                  >
                                    {booking?.status === "PAID" ||
                                    initiatePayment?.includes(booking?.boat_booking_id)
                                      ? "Paid"
                                      : "Pay"}
                                  </span>
                                  <span
                                    style={{
                                      backgroundColor: "red",
                                      color: "white",
                                      padding: "10px",
                                      borderRadius: "5px",
                                      minWidth: "50%", // Same width as the first button
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={
                                      () => handleCancelPayment(
                                      booking?.booking_id,
                                      booking?.boat_owner_id
                                    )}
                                  >
                                    Cancel
                                  </span>
                                </div>
                              </div>
                            ))
                          ) : null
                          }
                        </DialogContent>
                        </>:null
                        }
                        <DialogContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                            padding: "30px",
                          }}
                          className={class_name.dialog_content}
                        >
                          <div
                          style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            // display: boatBookingList?.length? 'block':'none'
                          }}
                          >
                            Boat Booking List
                          </div>
                          {boatBookingList?.length ? (
                            boatBookingList?.map((booking) => (
                              <div className="d-flex flex-column gap-2 border border-5 p-md-3 p-4 p-sm-5 rounded-2">
                                <div className="d-flex gap-md-3 gap-2 flex-column flex-md-row">
                                  <div>
                                    <span className="fw-bold">Date: </span>
                                    <span>
                                      {moment(booking?.booking_date).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">Duration: </span>
                                    <span>{booking?.duration} hour</span>
                                  </div>
                                </div>
                                <div className="d-flex gap-md-3 gap-2 flex-column flex-md-row">
                                  <div>
                                    <span className="fw-bold">
                                      Start Time:{" "}
                                    </span>
                                    <span>
                                      {moment(
                                        booking?.start_time,
                                        "HH:mm:ss"
                                      ).format("h:mm A")}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">End Time: </span>
                                    <span>
                                      {moment(
                                        booking?.end_time,
                                        "HH:mm:ss"
                                      ).format("h:mm A")}
                                    </span>
                                  </div>
                                </div>
                                <div className="text-end">
                                  <span
                                    className={class_name.pay_btn}
                                    style={{
                                      backgroundColor:
                                        booking?.trip_status === "PENDING"
                                          ? "#026b93"
                                          : "green",
                                    }}
                                    onClick={() =>
                                      handlePaymentInitiate(
                                        booking?.boat_id,
                                        booking?.boat_booking_id,
                                        booking?.user_id,
                                        booking?.duration,
                                        booking?.duration_type,
                                        booking?.price,
                                        booking?.booking_date,
                                        booking?.start_time,
                                        booking?.end_time,
                                        booking?.adults,
                                        booking?.children,
                                        booking?.infants,
                                        booking?.seniors,
                                        booking?.special_request
                                      )
                                    }
                                  >
                                    {booking?.trip_status === "PENDING" ||
                                    initiatePayment?.includes(
                                      booking?.boat_booking_id
                                    )
                                      ? "Confirm"
                                      : "Booking on progress"}
                                  </span>
                                </div>
                              </div>
                            ))
                          ) : (
                            <span
                              className={class_name.sryTxt}
                              style={{
                                display: isLoading ? "none" : "block",
                              }}
                            >
                              No Trips are Available
                            </span>
                          )}
                        </DialogContent>
                      </div>
                    </Dialog>

                    <Dialog open={showModal1} onClose={handleCloseModal} scroll="paper">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Loader loading={isLoading} />
                        <DialogTitle
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "sticky",
                            top: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: "white",
                          }}
                        >
                          <span>Trip Details</span>
                          <span onClick={handleCloseModal}>
                            <Close />
                          </span>
                        </DialogTitle>
                        <DialogContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                            padding: "30px",
                          }}
                          className={class_name.dialog_content}
                        >
                          {myBookingList?.length ? (
                            myBookingList?.map((booking) => (
                              <div
                                key={booking?._id} // Add a key prop for each booking
                                className="d-flex flex-column gap-2 border border-5 p-md-3 p-4 p-sm-5 rounded-2"
                              >
                                <div className="d-flex gap-md-3 gap-2 flex-column flex-md-row">
                                  <div>
                                    <span className="fw-bold">Date: </span>
                                    <span>
                                      {moment(booking?.booking_date).format("DD.MM.YYYY")}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">Duration: </span>
                                    <span>{booking?.hours} hour</span>
                                  </div>
                                </div>
                                <div
                                  className="text-end"
                                  style={{
                                    display: "flex",
                                    // justifyContent: "flex-center", // Align buttons to the right
                                    gap: "2%", // Add some gap between buttons
                                  }}
                                >
                                  <span
                                    className={class_name.pay_btn}
                                    style={{
                                      backgroundColor:
                                        booking?.status === "PAID" ? "green" : "#026b93",
                                      color: "white",
                                      padding: "10px",
                                      borderRadius: "5px",
                                      minWidth: "50%", // Adjust width as needed
                                      textAlign: "center",
                                      cursor: booking?.status !== "PAID" ? "pointer" : "default",
                                    }}
                                    onClick={() =>
                                      booking?.status !== "PAID"
                                        ? handleOpenTapPay(booking?.transaction_id)
                                        : null
                                    }
                                  >
                                    {booking?.status === "PAID" ||
                                    initiatePayment?.includes(booking?.boat_booking_id)
                                      ? "Paid"
                                      : "Pay"}
                                  </span>
                                  <span
                                    style={{
                                      backgroundColor: "red",
                                      color: "white",
                                      padding: "10px",
                                      borderRadius: "5px",
                                      minWidth: "50%", // Same width as the first button
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => 
                                      handleCancelPayment(
                                        booking?.booking_id,
                                        booking?.boat_owner_id
                                      )}
                                  >
                                    Cancel
                                  </span>
                                </div>
                              </div>
                            ))
                          ) : (
                            <span
                              className={class_name.sryTxt}
                              style={{
                                display: isLoading ? "none" : "block",
                              }}
                            >
                              No Trips are Available
                            </span>
                          )}
                        </DialogContent>
                      </div>
                    </Dialog>

                  </div>
                  <IconButton onClick={() => fileInputRef.current.click()}>
                    <img
                      alt="attach-pin"
                      src={IMAGES.ATTACH_PIN_ICON}
                      className={class_name.attachPin_icon}
                    />
                  </IconButton>
                  <div className={class_name.straight_line} />
                  <IconButton>
                    <img
                      alt="attach-pin"
                      src={IMAGES.SENT_ICON}
                      className={class_name.send_icon}
                      onClick={(event) => {
                        if (typedTextMsg || mediaFile) {
                          sendMessage(event);
                        }
                      }}
                    />
                  </IconButton>
                </div>
              ) : (
                <div className="p-5 text-center">
                  <span className={class_name.sryTxt}>
                    Sorry, Your Request Denined by Owner
                  </span>
                </div>
              )}
            </div>
          ) : null}
          {dash?.tap_pay_page ? (
            <Payment
              open={dash?.tap_pay_page}
              redirectUrl={redirectUrl}
              handleClosePayment={handleClosePaymentModal}
              amount={amount}
              callbackFunc={handleCallbackFunc}
            />
          ) : null}
          {openPopup ? (
            <PopupPage
              open={openPopup}
              handleClosePopup={handleClosePopup}
              handleStatus={handleStatusPopup}
              title={`Are you sure you want to ${dash?.popup_page?.ride_mode}`}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  chat_container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    // backgroundColor: "lightgreen",
    justifyContent: "space-between",
    // position: "relative",
    // height: "100%",
    // height: "auto",
  },

  textBox_container: {
    // height: '100vh',
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    borderTop: "solid 0.5px rgba(66, 70, 81, 0.6)",
    // borderBottom: "solid 0.5px rgba(66, 70, 81, 0.6)",
    // marginBottom: '25px',
    padding: "2% 7%",
    width: "100%",
    // borderBottom: 'solid 1px black',
    // backgroundColor: "lightcoral",
    alignItems: "center",
    // position: "ṣticky",
    // left: "0",
    // right: '0',
    // bottom: "0",
    // height: "100%",
  },

  header_name_div: {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 20px 20px 0px",
    padding: "2% 5%",
    display: "flex",
    gap: "5%",
    alignItems: "center",
  },

  header_name_txt: {
    fontWeight: "bold",
    fontSize: "clamp(14px,1.5vw,30px)",
    // padding: '5%',
    // textAlign: 'center',
    // marginTop: '5%'
  },

  straight_line: {
    height: "90%",
    backgroundColor: "rgba(66, 70, 81, 0.4)",
    width: "1px",
    marginRight: "3%",
  },
  attachPin_icon: {
    width: "clamp(12px, 1vw, 15px)",
    height: "clamp(24px, 1vw, 30px)",
    marginRight: "3%",
  },
  pay_btn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "clamp(14px,1.5vw,16px)",
    // backgroundColor: '#026b93',
    padding: "2px 10px",
    textAlign: "center",
    color: "white",
    borderRadius: "2px",
    fontWeight: "600",
  },
  send_icon: {
    width: "clamp(23px, 1vw, 30px)",
    height: "clamp(20px, 1vw, 25px)",
    // marginRight: "5%",
  },

  //
  msg_list_detail_box: {
    // backgroundColor: "red",
    // padding: "5% 1.5%",

    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    alignItems: "center",
    // borderTop: "solid 0.3px rgba(112, 112, 112, 0.19)",
    width: "100%",
  },

  profile_icon_style: {
    width: "clamp(35px, 4.9vw, 60px)",
    height: "clamp(35px, 4.9vw, 60px)",
    borderRadius: "50%",
    border: "solid 1px rgba(66, 70, 81, 0.9)",
  },

  column_div: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // justifyContent: "space-between",
    marginBottom: "7%",

    padding: "5%",
    // backgroundColor: "lightgoldenrodyellow",
  },

  row_div: {
    display: "flex",
    width: "100%",
    // justifyContent: "space-between",
    alignItems: "flex-start",
  },

  chat_name_txt: {
    fontSize: "clamp(12px, 1.8vw, 24px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 1.87)",
    marginLeft: "3%",
  },

  decline_txt: {
    fontSize: "clamp(14px, 2vw, 24px)",
    fontWeight: "600",
    textAlign: "center",
    color: "#3973a5",
  },

  accpet_txt: {
    fontSize: "clamp(14px, 2vw, 24px)",
    fontWeight: "600",
    textAlign: "center",
    color: "white",
  },

  //

  //
  //
  row_style: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  name_txt_style: {
    fontSize: "clamp(14px, 2.5vw, 16px)",
    fontWeight: "bold",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.87)",
    paddingLeft: "2.7%",
    whiteSpace: 'nowrap'
  },
  msg_txt_style: {
    fontSize: "clamp(14px, 2.5vw, 16px)",
    fontWeight: "normal",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.87)",
    paddingLeft: "2.7%",
    width: '80%',
    overflowWrap: 'break-word'
  },
  msg_txt_style_right: {
    fontSize: "clamp(14px, 2.5vw, 16px)",
    fontWeight: "normal",
    textAlign: "right",
    color: "rgba(66, 70, 81, 0.87)",
    paddingLeft: "2.7%",
    width: '80%',
    overflowWrap: 'break-word'
  },
  chat_image: {
    maxWidth: "50%",
    height: "auto",
    margin: "5px",
    // objectFit: 'scale-down',
    borderRadius: "2px",
    // [theme.breakpoints.down('481')]: {
    //   width: '200px',
    //   height: 'auto',
    // },
  },
  time_txt_style: {
    fontSize: "clamp(11px, 1vw, 14px)",
    fontWeight: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.6)",
  },
  selectedImg: {
    width: "clamp(85px, 20vw, 200px)",
    height: "clamp(65px, 10vw, 160px)",
    border: "solid 1px rgba(66, 70, 81, 0.5)",
  },
  selectedPdf: {
    width: "clamp(24px, 5vw, 100px)",
    height: "clamp(24px, 5vw, 100px)",
  },
  page_header: {
    display: "none",
  },
  message_show_container: {
    display: "flex",
    // height: "75vh",
    // height: "100%",

    flexDirection: "column",
    padding: "0 10%",
    overflowX: "hidden",

    // [theme.breakpoints.up("1000")]: {
    //   height: "69vh",
    //   backgroundColor: "lightgreen",
    // },
    // [theme.breakpoints.down("800")]: {
    //   height: "69vh",
    //   backgroundColor: "red",
    // },
    // [theme.breakpoints.down("700")]: {
    //   height: "71vh",
    //   backgroundColor: "rebeccapurple",
    // },
  },

  noDataContent: {
    display: "flex",
    flexDirection: "column",
    marginTop: "100px",
    alignItems: "center",
    textAlign: "center",
  },
  noResultImg: {
    width: "clamp(80px, 35vw, 139px)", // Adjust the range as needed
    height: "clamp(80px, 35vh, 139px)", // Adjust the range as needed
  },
  sryTxt: {
    fontSize: "clamp(14px, 3vw, 48px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.87)",
  },
  noResultTxt: {
    marginTop: "24px",
    fontSize: "clamp(12px, 3vw, 36px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.45)",
    width: "70%",
    textAlign: "center",
    marginBottom: "100px",
  },
  back_arrow_icon: {
    display: "none",
  },

  "@media (max-width: 767px)": {
    page_header: {
      display: "flex",
      // flexDirection: 'column'
    },
    back_arrow_icon: {
      display: "flex",
    },
  },
}));
