/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GetUserDetails, verifyOTP } from "../../redux/slices";
import { PageHeader } from "../Dash/page-header/PageHeader";
import Footer from "../../Component/Footer/Footer";
import "./Home.css";
//Imges
import Backgroundimg from "../../assets/Images/bg_img.png";
import BoatOwner from "../../assets/Images/owner_boat.png";
import Clock from "../../assets/Images/clock.svg";
import Grow from "../../assets/Images/grow.svg";
import Handshake from "../../assets/Images/handshake.svg";
import ProfessionalConnectionIcon from "../../assets/Images/ProfessionalConnection.png";
import Start from "../../assets/Images/start.svg";
import { Container } from "react-bootstrap";

import IMAGES from "../Images";
import useWindowDimensions from "../../UI kit/useWindowDimensions";
import { UserDetailsAPI } from "../../utils/UserDetailsAPI";

const Home = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const auth = useSelector((state) => state?.auth);
  const scrollableRowRef = useRef(null);
  const [fullyVisibleCardIds, setFullyVisibleCardIds] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const rentalRef = useRef(null);
  const boatRef = useRef(null);

  const handleIntersection = (entries) => {
    const visibleCardIds = entries
      .filter((entry) => entry.isIntersecting)
      .map((entry) => parseInt(entry.target.dataset.id));
    setFullyVisibleCardIds(visibleCardIds);
  };

  const Testimonial = [
    {
      id: 1,
      name: "Abdallah Hazmi",
      place: "Riyadh",
      review: "Best Experiences Ever. Good Idea!",
      image: IMAGES.BACKGROUNG_IMG_1_HOVER,
    },
    {
      id: 2,
      name: "Baker Arkan",
      place: "Jeddah",
      review: "This is very useful! we had a wonderful time.",
      image: IMAGES.SEA_VIEW_IMG,
    },
    {
      id: 3,
      name: "Mohammed Wadi",
      place: "Abha",
      review: "good effort!",
      image: IMAGES.weekendCircle_3,
    },
    {
      id: 4,
      name: "Salem Salem",
      place: "Mecca",
      review: "The most trusted scuba classes! Thank you.",
      image: IMAGES.weekendCircle_4,
    },
    {
      id: 5,
      name: "Salem Salem",
      place: "Mecca",
      review: "The most trusted scuba classes! Thank you.",
      image: IMAGES.weekendCircle_5,
    },
    {
      id: 6,
      name: "Salem Salem",
      place: "Mecca",
      review: "The most trusted scuba classes! Thank you.",
      image: IMAGES.weekendCircle_6,
    },
    {
      id: 5,
      name: "Salem Salem",
      place: "Mecca",
      review: "The most trusted scuba classes! Thank you.",
      image: IMAGES.weekendCircle_5,
    },
    {
      id: 6,
      name: "Salem Salem",
      place: "Mecca",
      review: "The most trusted scuba classes! Thank you.",
      image: IMAGES.weekendCircle_6,
    },
  ];

  const link1 = "For Boat Owners";
  const link2 = "For Boat Rentals";
  const backgroundImage = Backgroundimg;
  const location = useLocation();
  const content =
    "Embark on your maritime journey and let us make your boat booking experience seamless and extraordinary.";
  // "Find all boat trips and certified Scuba professionals within Saudi Arabia in one place";
  const title = "Welcome to the only ocean hub in Saudi Arabia";

  // ==========================  UserDetailsAPI
  useEffect(() => {
    UserDetailsAPI(auth?.userDetails?.user_id, auth?.AuthToken, (err, ress) => {
      dispatch(GetUserDetails(ress));
    });
  }, [auth?.AuthToken, auth?.userDetails?.user_id, dispatch]);

  useEffect(() => {
    const handleBackButton = (event) => {
      // Prevent the default behavior of the back button
      event.preventDefault();
      // Force the user back to the current route
      navigate(location.pathname);
    };
    window.addEventListener("popstate", handleBackButton);
    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [location.pathname, navigate]);

  const handle_navigation = (selected_page) => {
    toast.dismiss();

    dispatch(verifyOTP(null));
    if (selected_page === "Home") {
      // if (auth?.tokenDecodeData?.user_type === "BOAT_OWNER") {
      //   navigate("/boatOwnerDashBoard");
      // } else {
      //   navigate("/rental");
      // }
    } else if (selected_page === "rental") {
      navigate("/rental");
    } else if (selected_page === "SignUp") {
      navigate("/userChoice");
    } else if (selected_page === "Login") {
      navigate("/login");
    } else if (selected_page === link1) {
      handleLink1Click();
    } else if (selected_page === link2) {
      goToRental();
    } else {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust the screen width threshold as needed
    };
    // Attach the event listener
    window.addEventListener("resize", handleResize);
    // Initial check
    handleResize();
    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    function adjustBannerHeight() {
      const backgroundImageElement = new Image();
      backgroundImageElement.src = backgroundImage;
      backgroundImageElement.onload = function () {
        const height = backgroundImageElement.height;
        setImageHeight(height);
      };
    }
    // Call the function initially
    adjustBannerHeight();
    // Attach the resize listener
    window.addEventListener("resize", adjustBannerHeight);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", adjustBannerHeight);
    };
  }, [backgroundImage]);

  const handleLink1Click = () => {
    // window.scrollTo({ top: 1855, behavior: "smooth" });
    if (boatRef.current) {
      // const yOffset = boatRef.current.getBoundingClientRect().top;
      // window.scrollTo({ top: yOffset, behavior: "smooth" });
      //
      //
      //
      //
      const contentHeight = boatRef.current.offsetHeight;
      const windowHeight = window.innerHeight;
      const yOffset =
        boatRef.current.getBoundingClientRect().top -
        (windowHeight - contentHeight) / 2;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };
  const goToRental = () => {
    // window.scrollTo({ top: 850, behavior: "smooth" });
    if (rentalRef.current) {
      const yOffset = rentalRef.current.getBoundingClientRect().top;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const container = scrollableRowRef.current;

    // Function to handle the scroll event
    const handleScroll = () => {
      // Get the position of the container relative to the viewport
      const rect = container.getBoundingClientRect();
      const containerLeftEdge = rect.left;
      const containerRightEdge = rect.right;

      // Get all the card elements inside the container
      const cards = container.querySelectorAll(`.${class_name.review_card}`);

      // Loop through the cards and adjust their top margin based on their position
      cards.forEach((card) => {
        const cardRect = card.getBoundingClientRect();

        // Check if the card is at the left edge
        if (cardRect.left <= containerLeftEdge) {
          card.style.bottom = "60px";
        } else if (cardRect.right >= containerRightEdge) {
          // Check if the card is at the right edge
          card.style.bottom = "60px";
        } else {
          // Reset the margin for cards that are not at the edge
          card.style.bottom = "0";
        }
      });
    };

    // Add the scroll event listener
    container.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <Typography
        onClick={() => {
          navigate("/boatBookingRequest");
        }}
      >
        Booking
      </Typography> */}
      <div className="home-full-box">
        {/* outSide header */}
        <div className={class_name?.show_outside_banner}>
          <PageHeader
            showLoginSignUp={auth?.AuthToken ? false : true}
            handle_navigation={handle_navigation}
            presentPage={"Home"}
            link1={link1}
            link2={link2}
          />
        </div>

        {/* ================================================== banner ================================================== */}

        {/* header & content */}
        <div
          className="banner"
          style={{
            height: width > 1000 ? height : "auto",
          }}
        >
          {/* inside header */}
          <div className={class_name?.show_inside_banner}>
            <PageHeader
              showLoginSignUp={auth?.AuthToken ? false : true}
              handle_navigation={handle_navigation}
              presentPage={"Home"}
              link1={link1}
              link2={link2}
            />
          </div>

          {/* banner txt content */}
          <div className="inner-banner-box">
            <Container className="banner-txt-content">
              <div className="banner-txt-box" style={{ width: "50%" }}>
                <Typography className={class_name?.Welcome_txt}>
                  {title}
                </Typography>

                <Typography className={class_name?.sub_titile}>
                  {content}
                </Typography>

                <div
                  className={class_name?.get_start_btn}
                  onClick={() => goToRental()}
                >
                  <Typography
                    className={`${class_name.get_start_txt} hoverEffect`}
                  >
                    Get Started
                  </Typography>
                </div>
              </div>
            </Container>
          </div>
        </div>

        {/* ================================================== Choose Your Journey To Go With Saudia Ocean Hub ================================================== */}
        <div className={class_name.Choose_Your_Journey} ref={rentalRef}>
          <Container className={class_name.Choose_Your_Journey_container}>
            <Typography className={class_name.choose_journey_txt}>
              Choose Your Journey To Go With Saudia Ocean Hub
            </Typography>

            <div className={class_name.show_img_box}>
              <div
                className={class_name.imageContainer}
                style={{ width: "48%" }}
              >
                <img
                  src={IMAGES?.BACKGROUNG_IMG_1_HOVER}
                  style={{ width: "100%", height: "100%" }}
                  alt="boat_carousal_img"
                />
                <div
                  className={class_name.overlay}
                  onClick={() => {
                    handle_navigation("rental");
                  }}
                >
                  <Typography className={class_name.hoverTxt}>
                    Boat Rentals
                  </Typography>
                </div>
              </div>
              <div
                className={class_name.imageContainer}
                style={{ width: "48%" }}
              >
                <img
                  src={IMAGES?.BACKGROUNG_IMG_HOVER}
                  style={{ width: "100%", height: "100%" }}
                  alt="boat_carousal_img"
                />
                <div
                  className={class_name.overlay}
                  onClick={() => {
                    toast.dismiss();
                    toast.info("Coming Soon!", {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 2000,
                    });
                  }}
                >
                  <Typography className={class_name.hoverTxt}>
                    Stay Tuned!
                  </Typography>
                  <Typography
                    className={class_name.hoverTxt}
                    style={{ marginTop: "5%" }}
                  >
                    More adventures are coming soon!
                  </Typography>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* ================================================== For Boat Owners and show IMG ================================================== */}
        <>
          <Container
            fluid
            style={{
              margin: "0px",
              padding: "0px",
              backgroundColor: "rebeccapurple",
            }}
            ref={boatRef}
          >
            <div className="boat-owner-box">
              <Container className="boat-owners-point-list">
                <Typography className={class_name.for_boat_owner_txt}>
                  For Boat Owners
                </Typography>
                <Typography className={class_name.boat_owner_sub_title_txt}>
                  It's time for your boat to start paying you back!
                </Typography>

                <div className="owner-desc">
                  <div className="point-list">
                    <img
                      src={Clock}
                      alt="clock"
                      className={class_name.point_list_img1}
                      // className={class_name.picture_style}
                    />
                    <Typography
                      // className="point-txt"
                      className={class_name.point_txt}
                    >
                      Save time, earn more by listing your boat on Saudia Ocean
                      hub
                    </Typography>
                  </div>
                  <div className="point-list">
                    <img
                      src={Grow}
                      alt="clock"
                      className={class_name.point_list_img2}
                      // className={class_name.picture_style}
                    />
                    <Typography
                      className={class_name.point_txt}
                      //   className={class_name.point_list_img2}
                    >
                      Boost up your business and increase your visitors and
                      profits
                    </Typography>
                  </div>
                  <div className="point-list">
                    <img
                      src={IMAGES?.WEB_WORLD}
                      alt="clock"
                      className={class_name.point_list_img3}
                    />
                    <Typography
                      // className="point-txt"
                      className={class_name.point_txt}
                    >
                      Let a wide range of customers know about your boat, and
                      have the freedom to update its information at any time
                      with ease
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    // width: "100%",
                  }}
                >
                  <div
                    className={class_name?.list_boat_btn}
                    onClick={() => {
                      navigate("/boatOwnerDashBoard");
                    }}
                  >
                    <Typography
                      className={`${class_name.list_boat_txt} hoverEffect`}
                    >
                      List Your Boat Now{" "}
                    </Typography>
                  </div>
                </div>
              </Container>

              <div
                className="boat-img-show"
                // style={{ backgroundColor: "teal" }}
              >
                <img
                  src={BoatOwner}
                  alt="boat_owner"
                  className="img-fluid"
                  // style={{ backgroundSize: "cover" }}
                />
              </div>
            </div>
          </Container>
        </>

        {/* ================================================== Ocian hub ================================================== */}
        <div>
          <Container
            fluid
            // className="ocean-hub"
            className={class_name.ocean_hub}
          >
            <Typography
              // className="ocean-hub-title"
              className={class_name.ocean_hub_title}
            >
              Why Saudia Ocean Hub?
            </Typography>

            <div className="ocean-hub-content-box">
              <div className="ocen-hub-each-point">
                <div
                  // className="white-circle-background"
                  className={class_name.white_circle_background1}
                >
                  <img
                    src={Handshake}
                    alt="handshake"
                    className={class_name.ocen_hub_list_icon1}
                  />
                </div>
                <div className="ocen-hub-txt-box">
                  <Typography
                    // className="ocean-hub-point-title"
                    className={class_name.ocean_hub_point_title}
                  >
                    Flexibility
                  </Typography>
                  <Typography
                    // className="ocean-hub-point-info"
                    className={class_name.ocean_hub_point_info}
                  >
                    At Saudia Ocean Hub, we believe in giving our customers the
                    flexibility to plan their bookings according to their own
                    schedule.
                  </Typography>
                </div>
              </div>
              <div className="ocen-hub-each-point">
                <div
                  //  className="white-circle-background"
                  className={class_name.white_circle_background1}
                >
                  <img
                    src={Start}
                    alt="handshake"
                    className={class_name.ocen_hub_list_icon2}
                  />
                </div>
                <div className="ocen-hub-txt-box">
                  <Typography
                    // className="ocean-hub-point-title"
                    className={class_name.ocean_hub_point_title}
                  >
                    100% Trust Worthy
                  </Typography>
                  <Typography
                    // className="ocean-hub-point-info"
                    className={class_name.ocean_hub_point_info}
                  >
                    At Saudia Ocean Hub, we take pride in offering a safe and
                    secure platform for all of our users. We ensure that all of
                    our boats are fully licensed and certified.
                  </Typography>
                </div>
              </div>
              <div className="ocen-hub-each-point">
                <div className={class_name.white_circle_background1}>
                  <img
                    src={ProfessionalConnectionIcon}
                    alt="ProfessionalConnectionIcon"
                    className={class_name.ocen_hub_list_icon3}
                  />
                </div>
                <div className="ocen-hub-txt-box">
                  <Typography className={class_name.ocean_hub_point_title}>
                    Professional Connection
                  </Typography>
                  <Typography className={class_name.ocean_hub_point_info}>
                    At Saudia Ocean Hub, we care about providing a unique
                    opportunity for all of our customers to connect together and
                    expand their network.
                  </Typography>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* ================================================== Client review ================================================== */}
        <>
          <Container fluid className="client-review-container">
            <div className="client-review-title">
              <Typography className={class_name.client_review_title_txt}>
                What Our{" "}
                <span style={{ fontWeight: "bold" }}>Customers Are Saying</span>
              </Typography>
            </div>

            <div
              className={class_name?.scrollableRowStyle}
              ref={scrollableRowRef}
            >
              {Testimonial.map((item, index) => (
                <div className={class_name.card_special_eff} key={index}>
                  <div
                    key={item.id}
                    className={class_name.review_card}
                    data-id={item.id}
                  >
                    <div style={{}} className={class_name.profile_move_top}>
                      <div className={class_name.client_img}>
                        <img
                          alt="client_pic"
                          style={{ backgroundColor: "white" }}
                          src={item.image}
                          className={class_name.client_img_icon}
                        />
                      </div>
                    </div>
                    <Typography className={class_name.client_card_name_txt}>
                      {item.name}
                    </Typography>
                    <Typography className={class_name.client_card_city_txt}>
                      {item.place}
                    </Typography>
                    <Typography
                      style={{ textAlign: "left" }}
                      className={class_name?.client_card_review_txt}
                    >
                      {item.review}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </Container>
        </>
        {/* ================================================== certified ================================================== */}

        {/* <div className={class_name.certified_content}>
          <div style={{ width: "75%" }}>
            <Typography
              className={class_name.certified_title}
              style={{ fontWeight: "-moz-initial" }}
            >
              {"Trusted by "}
              <span style={{ fontWeight: "bolder" }}> {"PADI®"}</span>
            </Typography>
            <Typography className={class_name.certified_info}>
              Saudia Ocean Hub is officially certified as an official hub for
              scuba by PADI Professional Association of Diving Instructors
            </Typography>
          </div>
          <div
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Cretificate}
              alt="Cretificate"
              className={class_name.certified_img}
            />
          </div>
        </div> */}

        <div className="footer-style-hide">
          <Footer />
        </div>
      </div>
    </>
  );
};
export default Home;

const useStyles = makeStyles((theme) => ({
  Welcome_txt: {
    fontSize: "clamp(14px, 3.5vw, 48px)", // Adjust the range as needed
    fontWeight: "bolder",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.87)",
  },

  sub_titile: {
    margin: "8px 0 24px",
    [theme.breakpoints.up("sm")]: {
      margin: "8px 0 24px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "16px 0 50px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "24px 0 80px",
    },
    fontSize: "clamp(8px, 2.8vw, 24px)", // Adjust the range as needed
    fontWeight: "500",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.87)",
    [theme.breakpoints.down(767)]: {},
  },

  get_start_btn: {
    cursor: "pointer",
    borderRadius: "30px",
    border: "solid 1.5px #026b93",
    padding: "3% 15%",
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    transition: "background-color 0.3s", // Add a smooth transition for the background color
    "&:hover": {
      backgroundColor: "#026b93", // Change the background color on hover
      "& $get_start_txt": {
        color: "white", // Change text color on hover
      },
    },
  },
  get_start_txt: {
    cursor: "pointer",
    fontSize: "clamp(8px, 2vw, 24px)", // Adjust the range as needed
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.96,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#026b93",
  },

  for_boat_owner_txt: {
    fontSize: "clamp(8px, 2vw, 30px)", // Adjust the range as needed
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "12px",
    letterSpacing: "normal",
    textAlign: "start",
    color: "#f6f6f6",
  },

  boat_owner_sub_title_txt: {
    fontSize: "clamp(10px, 3vw, 45px)", // Adjust the range as needed
    fontWeight: "bold",
    textAlign: "left",
    color: "#f6f6f6",
    marginTop: "10px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "50px",
    },
  },

  point_txt: {
    margin: "0 0 0 10px",
    fontSize: "clamp(6px, 1.5vw, 20px)", // Adjust the range as needed
    fontWeight: "bold",
    textAlign: "left",
    color: "rgba(246, 246, 246, 0.87)",
    [theme.breakpoints.up("sm")]: {
      margin: "0 0 0 20px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 0 0 30px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "0 0 0 55px",
    },
  },

  list_boat_btn: {
    marginTop: "10px",
    display: "flex",
    borderRadius: "30px",
    width: "65%",
    justifyContent: "center",
    cursor: "pointer",
    border: "solid 1px white",
    transition: "background-color 0.3s", // Add a smooth transition for the background color
    "&:hover": {
      backgroundColor: "white", // Change the background color on hover
      "& $list_boat_txt": {
        color: "#03487a", // Change text color on hover
      },
    },
    padding: "5% 7%",
    [theme.breakpoints.up("sm")]: {
      padding: "3% 5%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "3% 10%",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "3% 15%",
    },
  },

  list_boat_txt: {
    fontSize: "clamp(6px, 1.5vw, 24px)", // Adjust the range as needed
    lineHeight: 0.96,
    textAlign: "center",
    color: "white",
  },

  Choose_Your_Journey: {
    backgroundColor: "#f6f6f6",
    transition: "background-color 0.3s",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
    height: "auto",
    padding: "20px", // Default padding value
    [theme.breakpoints.up("sm")]: {
      padding: "30px", // Padding for small screens and up
    },
    [theme.breakpoints.up("md")]: {
      padding: "100px", // Padding for medium screens and up
    },
    [theme.breakpoints.up("lg")]: {
      padding: "150px 0px", // Padding for large screens and up
    },
  },

  Choose_Your_Journey_container: {
    padding: "0px",
    margin: "0px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // height: "100%",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
  },
  choose_journey_txt: {
    fontSize: "clamp(15px, 3vw, 48px)", // Adjust the range as needed
    fontWeight: "bolder",

    lineHeight: 1.38,

    textAlign: "center",
    color: "rgba(66, 70, 81, 0.87)",
    maxWidth: "60%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "52%", // Padding for small screens and up
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "55%",
    },
  },

  show_img_box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10% 0%",
    // backgroundColor: "yellowgreen",
  },
  ocean_hub: {
    backgroundColor: "#f6f6f6",
    padding: "32px 0px 16px 0px", // Default padding value
    [theme.breakpoints.up("sm")]: {
      padding: "32px 0px 16px 0px", // Padding for small screens and up
    },
    [theme.breakpoints.up("md")]: {
      padding: "100px 0px 50px 0px", // Padding for medium screens and up
    },
    [theme.breakpoints.up("lg")]: {
      padding: "150px 0px 75px 0px", // Padding for large screens and up
    },

    // backgroundColor: "red",
  },

  ocean_hub_title: {
    fontSize: "clamp(14px, 5vw, 48px)",
    fontWeight: "bold",
    textAlign: "center",
    color: "#424651",
    opacity: 0.8,
  },

  ocean_hub_point_title: {
    fontSize: "clamp(12px, 2vw, 30px)",
    fontWeight: "500",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.87)",
    paddingBottom: "20px",
  },
  ocean_hub_point_info: {
    fontSize: "clamp(8px, 2vw, 18px)",
    fontWeight: "500",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.6)",
    paddingBottom: "20px",
  },
  certified_title: {
    fontSize: "clamp(14px, 5vw, 48px)",
    fontWeight: "400",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.87)",
    paddingBottom: "40px",
  },
  certified_info: {
    fontSize: "clamp(10px, 3vw, 22px)",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.6)",
  },

  certified_img: {
    width: "clamp(50px, 15vw, 357.1px)", // Adjust the range as needed
    height: "clamp(20px, 10vh, 139px)", // Adjust the range as needed
  },

  point_list_img1: {
    width: "clamp(15px, 5vw, 30px)", // Adjust the range as needed
    height: "clamp(21px, 7vh, 56px)", // Adjust the range as needed
    marginLeft: "5.5px",
  },
  point_list_img2: {
    width: "clamp(20px, 5vw, 40px)", // Adjust the range as needed
    height: "clamp(24px, 7vh, 48px)", // Adjust the range as needed
  },
  point_list_img3: {
    width: "clamp(20px, 5vw, 40px)", // Adjust the range as needed
    height: "clamp(20px, 5vh, 40px)", // Adjust the range as needed
  },

  ocen_hub_list_icon1: {
    width: "clamp(45.5px, 5vw, 91px)", // Adjust the range as needed
    height: "clamp(33.5px, 3vh, 57px)", // Adjust the range as needed
    //   margin: "17px",
  },
  ocen_hub_list_icon2: {
    width: "clamp(45.5px, 5vw, 91px)", // Adjust the range as needed
    height: "clamp(37.5px, 4vh, 75px)", // Adjust the range as needed
  },
  ocen_hub_list_icon3: {
    width: "clamp(35.5px, 5vw, 71px)", // Adjust the range as needed
    height: "clamp(32.5px, 5vh, 75px)", // Adjust the range as needed
  },

  white_circle_background1: {
    width: "clamp(90px, 5vw, 180px)", // Adjust the range as needed
    height: "clamp(90px, 5vh, 180px)",
    borderRadius: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "44px",
    paddingRight: "44px",
    alignSelf: "center",
  },
  certified_content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#f6f6f6",
    padding: "24px 16px 24px 16px", // Default padding value
    [theme.breakpoints.up("sm")]: {
      padding: "24px 16px 24px 16px", // Default padding value
    },
    [theme.breakpoints.up("md")]: {
      padding: "54px 60px 44px 60px", // Padding for large screens and up
    },
    [theme.breakpoints.up("lg")]: {
      padding: "81px 140px 64px 140px", // Padding for large screens and up
    },
  },

  overlay: {
    flexDirection: "column",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    opacity: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "opacity 0.3s ease-in-out",
    cursor: "pointer",
  },

  imageContainer: {
    position: "relative", // To make sure .overlay is positioned relative to this container
    "&:hover $overlay": {
      opacity: 0.8,
    },
  },

  hoverTxt: {
    fontSize: "clamp(8px, 2vw, 30px)", // Adjust the range as needed
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "12px",
    letterSpacing: "normal",
    textAlign: "start",
    // color: "rgba(66, 70, 81, 0.87)",
    color: "black",
  },

  client_review_title_txt: {
    fontSize: "clamp(15px, 3vw, 48px)",

    // fontWeight: "600",
    textAlign: "center",
    color: "rgba(246, 246, 246, 0.87)",
  },

  //
  //
  //

  scrollableRowStyle: {
    width: "100%",
    overflowX: "auto",
    display: "flex",
    flexWrap: "nowrap",
    padding: "5% 0 0 3%",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
    scrollPadding: "0px 20px", // Added padding on left and right
    scrollSnapAlign: "start", // Ensures each card snaps to the start position
    "-ms-overflow-style": "none", // Hide scrollbar in Internet Explorer
    scrollbarWidth: "none", // Hide scrollbar in Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar in Webkit browsers
    },
  },

  card_special_eff: {
    // backgroundColor: "lightgreen",
    display: "flex",
    width: "100%",
    margin: "0 1% 0% 0",
    height: "100%",
    transition: "margin-top 0.3s ease",

    [theme.breakpoints.down(330)]: {
      marginTop: "30%",
    },
    [theme.breakpoints.up(330)]: {
      marginTop: "20%",
    },
    [theme.breakpoints.up(510)]: {
      marginTop: "15%",
    },
    [theme.breakpoints.up(850)]: {
      marginTop: "8%",
    },
  },

  review_card: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    flexShrink: "0",
    marginTop: "0",
    transition: "bottom 0.3s ease",
    backgroundColor: "rgba(246, 246, 246, 0.7)",
    position: "relative",
    borderRadius: "15px",
    margin: "0 1% 0 0",

    padding: "0 5% 10%",
    width: "200px",
    [theme.breakpoints.up("sm")]: {
      width: "275px",
    },
    [theme.breakpoints.up("md")]: {
      width: "325px",
      height: "290px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "425px",
      height: "290px",
    },
  },

  client_img_icon: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  client_img: {
    width: "clamp(70px, 25vw, 99px)", // Adjust the range as needed clamp(70px, 25vw, 99px)
    height: "clamp(70px, 25vh, 99px)", // Adjust the range as needed clamp(70px, 25vh, 99px)
    borderRadius: "60px",
    overflow: 'hidden',

    // [theme.breakpoints.down('sm')]:{
    //   '@media (orientation: landscape)': {
    //     // flexDirection: `orientation`,
    //     width: "clamp(70px, 25vh, 99px)", // Adjust the range as needed clamp(80px, 25vw, 60px)
    //     height: "clamp(70px, 25vh, 99px)", // Adjust the range as needed
    //     borderRadius: "60px",
    //   },
    // },

    // [theme.breakpoints.down('lg')]: {
      // '@media (orientation: landscape)': {
      //   flexdirection: `orientation`,
      //   width: "clamp(70px, 25vw, 99px)", // Adjust the range as needed clamp(80px, 25vw, 60px)
      //   height: "clamp(70px, 25vh, 99px)", // Adjust the range as needed
      //   borderRadius: "100px",
      // },
    // }
  },

  client_card_name_txt: {
    fontSize: "clamp(10px, 2vw, 21px)",
    fontWeight: "600",
    textAlign: "left",
    color: "#424651",
    // paddingTop: "75px",
    [theme.breakpoints.down(760)]: {
      paddingTop: "50px",
      // backgroundColor: "lightblue",
    },
    [theme.breakpoints.up(760)]: {
      paddingTop: "75px",
      // backgroundColor: "lightgreen",
    },
    [theme.breakpoints.up(800)]: {
      paddingTop: "75px",
    },
    [theme.breakpoints.up(900)]: {
      paddingTop: "75px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "75px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: "75px",
    },
  },

  client_card_city_txt: {
    // margin: "8px 198px 32px 0",
    marginBottom: "5%",
    fontSize: "clamp(8px, 2vw, 15px)",
    fontWeight: "300",
    textAlign: "left",
    color: "#424651",
  },

  client_card_review_txt: {
    width: "100%",
    fontSize: "clamp(8px, 2vw, 18px)",
    textAlign: "left",
    color: "#424651",
  },
  profile_move_top: {
    position: "absolute",
    // top: "-20%",
    width: "100%",
    [theme.breakpoints.down(600)]: {
      top: "-25%",
    },
    [theme.breakpoints.up(600)]: {
      top: "-20%",
    },
    [theme.breakpoints.up(800)]: {
      top: "-15%",
    },
  },

  show_inside_banner: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down(910)]: {
      display: "none",
    },
  },

  show_outside_banner: {
    display: "none",
    [theme.breakpoints.down(910)]: {
      display: "flex",
    },
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    overlay: {
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      opacity: 0.8,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "opacity 0.3s ease-in-out",
    },

    ocean_hub_point_title: {
      fontSize: "clamp(12px, 2vw, 30px)",
      fontWeight: "600",
      textAlign: "left",
      color: "rgba(66, 70, 81, 0.87)",
      paddingBottom: "5px",
    },
    ocean_hub_point_info: {
      fontSize: "clamp(8px, 2vw, 20px)",
      fontWeight: "400",
      letterSpacing: 0.5,
      textAlign: "left",
      color: "rgba(66, 70, 81, 0.6)",
      paddingBottom: "20px",
      // backgroundColor: "red",
    },
    certified_title: {
      paddingBottom: "20px",
    },
    certified_info: {
      fontSize: "clamp(8px, 3vw, 16px)",
      textAlign: "left",
      color: "rgba(66, 70, 81, 0.6)",
    },
    certified_img: {
      width: "clamp(80px, 20vw, 150px)", // Adjust the range as needed
      height: "clamp(20px, 15vh,75 px)", // Adjust the range as needed
    },
    point_list_img1: {
      width: "clamp(7px, 5vw, 15px)", // Adjust the range as needed
      height: "clamp(10px, 7vh, 21px)", // Adjust the range as needed
      marginLeft: "5.5px",
    },
    point_list_img2: {
      width: "clamp(11px, 5vw, 20px)", // Adjust the range as needed
      height: "clamp(13px, 7vh, 24px)", // Adjust the range as needed
      left: "-20px",
    },
    point_list_img3: {
      width: "clamp(10px, 5vw, 20px)", // Adjust the range as needed
      height: "clamp(10px, 5vh, 20px)", // Adjust the range as needed
    },
    ocen_hub_list_icon1: {
      width: "clamp(28px, 5vw, 46px)", // Adjust the range as needed
      height: "clamp(20px, 3vh, 33.5px)", // Adjust the range as needed
      //   margin: "17px",
    },
    ocen_hub_list_icon2: {
      width: "clamp(22px, 5vw, 45.5px)", // Adjust the range as needed
      height: "clamp(23px, 4vh, 37.5px)", // Adjust the range as needed
    },
    ocen_hub_list_icon3: {
      width: "clamp(23px, 5vw, 35.5px)", // Adjust the range as needed
      height: "clamp(20px, 3.2vh, 32.5px)", // Adjust the range as needed
    },
    white_circle_background1: {
      width: "clamp(56px, 5vw, 90px)", // Adjust the range as needed
      height: "clamp(56px, 5vh, 90px)",
      borderRadius: "100%",
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 20,
      paddingRight: 20,
    },
    ocean_hub: {
      backgroundColor: "#f6f6f6",
      padding: "32px 16px 16px 16px", // Default padding value
      [theme.breakpoints.up("sm")]: {
        padding: "32px 16px 16px 16px", // Padding for small screens and up
      },
      [theme.breakpoints.up("md")]: {
        padding: "100px 25px 50px 25    px", // Padding for medium screens and up
      },
      [theme.breakpoints.up("lg")]: {
        padding: "150px 0px 75px 0px", // Padding for large screens and up
      },
    },
    client_img: {
      borderRadius: "60px",
      [theme.breakpoints.down(450)]: {
        width: "clamp(24px, 20vw, 54px)", // Adjust the range as needed clamp(24px, 20vw, 54px)
        height: "clamp(24px, 20vh, 54px)", // Adjust the range as needed clamp(24px, 20vh, 54px)
        marginTop: "5%",
      },
      // [theme.breakpoints.up(200)]: {
      //   // marginTop: "10%",
      //   width: "clamp(24px, 15vw, 54px)", // Adjust the range as needed
      //   height: "clamp(24px, 15vh, 54px)", // Adjust the range as needed
      // },
      // [theme.breakpoints.up(400)]: {
      //   width: "clamp(44px, 19vw, 70px)", // Adjust the range as needed
      //   height: "clamp(44px, 15vh, 70px)", // Adjust the range as needed
      // },
      width: "clamp(44px, 15vw, 74px)", // Adjust the range as needed clamp(44px, 15vw, 74px)
      height: "clamp(44px, 15vh, 74px)", // Adjust the range as needed clamp(44px, 8vh, 74px)
    },
  },
  '@media (max-height: 575.98px) and (orientation: landscape)': {
    client_img: {
      width: "clamp(55px, 6vw, 74px)",
      height: "clamp(55px, 6vh, 74px)",
    }
  }
}));
