import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  userType: null,

  //
  First_Name: null,
  Last_Name: null,
  Email_Id: null,
  Cell_Phn_No: null,
  Password_SignUp: null,
  Confirm_Password_SignUp: null,
  Email_Check_Box: null,
  //
  SignUp_Payload_Data: null,
  //
  //
  //
  //
  //
  emailId: null,
  verifyOTPpage: null,
  password: null,
  //
  AuthToken: null,
  tokenDecodeData: null,
  userId: null,
  // profile_img: null,
  //
  //
  userDetails: null,
  //
  //
  //
  //
  //
  //

  //
  confirmTickMsg_title: null,
  confirmTickMsg_buttonName: null,
  calculateTime: false,
  token: null,
};

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    UserType(state, action) {
      state.userType = action.payload;
    },

    signUp_payload(state, action) {
      state.SignUp_Payload_Data = action.payload;
    },

    EmailId(state, action) {
      state.emailId = action.payload;
    },

    verifyOTP(state, action) {
      state.verifyOTPpage = action.payload;
    },

    Password(state, action) {
      state.password = action.payload;
    },

    AuthToken(state, action) {
      state.AuthToken = action.payload;
    },

    // ProfileImg(state, action) {
    //   state.profile_img = action.payload;
    // },

    TokenDecodeData(state, action) {
      state.tokenDecodeData = action.payload;
    },

    UserId(state, action) {
      state.userId = action.payload;
    },

    GetUserDetails(state, action) {
      state.userDetails = action.payload;
    },
    GetUserCity(state, action) {
      // console.log("working or not")
      state.userDetails.city = action.payload;
    },

    //
    //
    //
    //
    //

    boatTypeList(state, action) {
      state.boatType = action.payload;
    },

    boatServiceList(state, action) {
      state.boatService = action.payload;
    },

    confirmTickMsg(state, action) {
      state.confirmTickMsg_title = action.payload?.title;
      state.confirmTickMsg_buttonName = action.payload?.buttonName;
    },
    dispatchcalculateTime(state, action) {
      console.log("action data", action.payload);
      state.calculateTime = action.payload.calculateTime;
      state.token = action.payload.token;
    },
  },
});
export const {
  UserType,
  signUp_payload,
  EmailId,
  verifyOTP,
  Password,
  AuthToken,
  // ProfileImg,
  UserId,
  GetUserCity,

  GetUserDetails,

  boatTypeList,
  boatServiceList,

  TokenDecodeData,
  confirmTickMsg,
  dispatchcalculateTime,
} = authSlice.actions;

export default authSlice.reducer;
