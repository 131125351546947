import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IMAGES from "../../Images";
import "./Confirmation.css";
import { confirmTickMsg } from "../../../redux/slices";
import { Container } from "react-bootstrap";
import { Typography, makeStyles } from "@material-ui/core";

export const Confirmation = () => {
  const auth = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });

  return (
    <div style={{}} className={class_name.full_page}>
      <img src={IMAGES.APP_ICON} alt="ICON" className={class_name.appIcon} />
      <div className={class_name.container_glass}>
        <Container className={class_name.container_style}>
          <div className={class_name.inside_body}>
            <img
              src={IMAGES.CONFIRM_TICK}
              alt="ICON"
              className={class_name.tick_icon}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography className={class_name.reqSentTxt}>
                {auth?.confirmTickMsg_title ?? "Request Sent Successfully"}
              </Typography>
              <Typography className={class_name.msgTxt}>
                {auth?.confirmTickMsg_title
                  ? null
                  : "Our team is now processing your request. If you have any further questions or need assistance, please don`t hesitate to contact our support team"}
              </Typography>
            </div>
            <Typography
              className={class_name.backToHomeTxt}
              onClick={() => {
                dispatch(
                  confirmTickMsg({
                    title: null,
                    buttonName: null,
                  })
                );
                if (auth?.AuthToken) {
                  if (auth?.tokenDecodeData?.user_type === "BOAT_OWNER") {
                    navigate("/boatOwnerDashBoard");
                  } else {
                    navigate("/rental");
                  }
                } else {
                  navigate("/logIn");
                  dispatch(
                    confirmTickMsg({
                      title: null,
                      buttonName: null,
                    })
                  );
                }
              }}
            >
              {auth?.confirmTickMsg_buttonName ?? "back to home"}
            </Typography>
          </div>
        </Container>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  full_page: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f6f6f6",
  },

  appIcon: {
    margin: "0px 0px 50px 140px",
    width: "200px",
    height: "100px",
  },

  container_glass: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    paddingBottom: "100px",
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: "#f6f6f6",
  },

  reqSentTxt: {
    marginTop: "3%",
    fontSize: "clamp(14px, 3vw, 30px)",
    fontWeight: "bold",
    textAlign: "center",
    color: "#424651",
  },

  msgTxt: {
    marginTop: "2%",
    fontSize: "clamp(12px, 2.5vw, 26px)",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.7)",
    width: "80%",
  },

  backToHomeTxt: {
    margin: "7% 0 1%",
    fontSize: "clamp(12px, 2.5vw, 26px)",
    textAlign: "center",
    color: "#424651",
    textDecoration: "underline",
    cursor: "pointer",
  },
  tick_icon: {
    width: "clamp(100px, 10vw, 150px)",
    height: "clamp(100px, 10vw, 150px)",
  },

  inside_body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    backgroundColor: "white",
    height: "auto",
    width: "100%",
    border: "solid 0.1px rgba(66, 70, 81, 0.2)",
    borderRadius: "5px",
    padding: "5% ",
  },

  container_style: {},

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    full_page: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
    },
    appIcon: {
      margin: "0px",
      width: "200px",
      height: "100px",
    },
    container_glass: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      paddingBottom: "100px",
      justifyContent: "center",
      alignContent: "center",
      alignSelf: "center",
      alignItems: "center",
      backgroundColor: "#f6f6f6",
    },
    container_style: {
      display: "flex",
      height: "100%",
      padding: "5% ",
    },
    inside_body: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      alignContent: "center",
      alignSelf: "center",
      backgroundColor: "#f6f6f6",
      height: "100%",
      width: "100%",
      border: "solid 0.1px rgba(66, 70, 81, 0.2)",
      borderRadius: "5px",
      padding: "5% ",
    },
  },
  '@media (max-height: 575.98px) and (orientation: landscape)': {
    full_page: {
      height: '200%',
      // backgroundColor: 'red'
    }
  }
}));
