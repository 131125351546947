import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, useFormik } from "formik";
import { Checkbox, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Clear, MoreVert } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  add_boat_service_API,
  add_boat_type_API,
  address_gov_API,
  boat_service,
  boat_type,
  city_list_API,
  marine_city_list_API,
} from "../../../../../Service/api";
import {
  boatServiceList,
  boatTypeList,
  boat_register_location_data,
  boat_register_step2,
} from "../../../../../redux/slices";
import IMAGES from "../../../../Images";
import Map from "../../../../Common/map/Map2";
import { toast } from "react-toastify";
import "./BoatOfferStep2.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  Divider,
  Typography,
  InputLabel,
  MenuItem,
  Tooltip,
  IconButton,
  InputAdornment,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Select,
} from "@material-ui/core";
import { PageHeader } from "../../../page-header/PageHeader";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Footer from "../../../../../Component/Footer/Footer";
import moment from "moment";
import useWindowDimensions from "../../../../../UI kit/useWindowDimensions";
import { API } from "../../../../../Service/constants";
import Loader from "../../../../Loader";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import ImageSlider from "../../../../Common/ViewImages/ImageSlider/ImageSlider";
import axios from "axios";

const start_space_Validation = new RegExp(/^(?!\s).*/);
const nameValidationPattern = /^(?! )[A-Za-z-' ]+( [A-Za-z-' ]*)?$/;
const latterAndNo_ValidationPattern =
  /^(?! )[A-Za-z0-9-' ]+( [A-Za-z0-9-' ]+)* ?$/;
const numberValidationPattern = /^[0-9]+$/;
const NotNumber = new RegExp(/^[^\d]*$/);

const styles = (theme) => ({
  root: {
    //outer
    marginTop: "0px",

    paddingTop: "3px",

    "& .MuiInputLabel-root": {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },

    // "& #boatType":{
    //   color: '#bcbcbc'
    // },

    // "& #city":{
    //   color: '#bcbcbc'
    // },

    // Styles for the input text
    "& .MuiInputBase-input": {
      // padding: "3% 3%",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      textAlign: "left",
      // color: "#424651",
      borderBottom: "none",
    },

    "& .Mui-disabled": {
      color: "white",
    },

    "& .MuiInputLabel-root": {
      right: 0,
      textAlign: "left",
    },
    // "& .MuiInputBase-input MuiOutlinedInput-input": {},

    // Styles for the placeholder text
    "& .MuiFormLabel-root": {
      borderBottom: "none",
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-root": {
      fontSize: "clamp(12px, 0.9vw, 20px)",
      border: "solid 1.5px rgba(66, 70, 81, 0.2)",
      borderRadius: "5px",
    },

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
      fontSize: "clamp(12px, 0.9vw, 20px)",
      border: "none",
    },

    // Apply styles for small screens
    [theme.breakpoints.down("sm")]: {
      "& .MuiInputBase-input": {
        fontSize: "12px",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "5px",
      },
      // Add styles for the error message span element
      "& .Mui-error": {
        padding: 0, // Remove padding
        margin: 0, // Remove margin
      },
    },

    // Adjust for medium screens (md)
    [theme.breakpoints.up("md")]: {
      "& .MuiInputBase-input": {
        fontSize: "14px",
        // color: "black",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "10px",
      },
    },

    // Adjust for large screens (lg)
    [theme.breakpoints.up("lg")]: {
      "& .MuiInputBase-input": {
        fontSize: "16px",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
      },
    },
  },
});

const CustomTextField = withStyles(styles)(TextField);

const CustomCheckbox = withStyles({
  root: {
    color: "#ffffff",
    "&$checked": {
      color: "#ffffff",
    },
    // '& .MuiSvgIcon-root': { fontSize: 100 }
  },
  checked: {},
})(Checkbox);

export const BoatOfferStep2 = () => {
  // const inputRef = useRef()
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const navigate = useNavigate();
  const locationNavi = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const [loading, setLoading] = useState(false);
  const [boatServices, setBoatServices] = useState([]);
  const [showCut, setShowCut] = useState();
  const [selectedBoatServices, setSelectedBoatServices] = useState([]);
  const [customServiceValues, setCustomServiceValues] = useState([]);
  const [showCustomService, setShowCustomService] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addNewBoatTypeValue, setAddNewBoatTypeValue] = useState("");
  const [addBoatTypeLoader, setAddBoatTypeLoader] = useState(false);
  const [boatTypeFlag, setBoatTypeFlag] = useState(0);
  const [addNewBoatServiceValue, setAddNewBoatServiceValue] = useState("");
  const [addBoatServiceLoader, setAddBoatServiceLoader] = useState(false);
  const [boatServiceFlag, setBoatServiceFlag] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredBackgroundImg, setIsHoveredBackgroundImg] = useState(false);
  const [searchAddress, setSearchAddress] = useState("");

  //images
  const [selectedImageFiles, setSelectedImageFiles] = useState([]);
  const [backgroungImageIndex, setBackgroungImageIndex] = useState(null);
  const [profileImgIndex, setProfileImgIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [imgUploadError, setImgUploadError] = useState(false);
  const [imgUploadMsg, setImgUploadMsg] = useState("");
  const [boatServiceError, setBoatServiceError] = useState(false);
  const [mapLocError, setMapLocError] = useState(false);
  const [modalOpenIndex, setModalOpenIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [markers, setMarkers] = useState([
    { id: 1, lat: 20.146220361679458, lng: 40.2568970541965 },
    // { id: 2, lat: 20.146220361679458, lng: 42.2568970541965 },
    // { id: 3, lat: 20.146220361679458, lng: 41.2568970541965 },
  ]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const modalRef = useRef(null);
  const dashboard = useSelector((state) => state?.dashboard);
  const [enterNewBoatType, setEnterNewBoatType] = useState("");
  const [showMapAddressLocation, setShowMapAddressLocation] = useState("");
  const [formikError, setFormikError] = useState(false);
  const [backgroundImgError, setBackgroundImgError] = useState(false);
  const [profileImgError, setProfileImgError] = useState(false);
  const { width } = useWindowDimensions();
  const imageRef = useRef();
  const BoatServiceRef = useRef();
  const [selectedCity, setSelectedCity] = useState("");
  const [showModalForCityNames, setShowModalForCityNames] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [selectedCityError, setSelectedCityError] = useState(false);
  const [hideBtn, setHideBtn] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showModalBoatType, setShowModalBoatType] = useState(false);
  const [boatImgPath, setBoatImgPath] = useState([]);
  const [lengthFormat, setLengthFormat] = useState("meter");
  // const fileInputRef = useRef(null);
  // const [menuOpen,setMenuOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(false);
  const [selectedImageUrl_index, setSelectedImageUrl_index] = useState(false);
  const [selectedImageUrlFullData, setSelectedImageUrlFullData] =
    useState(false);
  const [errTrue, setErrTrue] = useState(null);
  const [count, setCount] = useState(0);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  let address_name = dashboard?.boat_reg_location_data
    ? dashboard?.boat_reg_location_data?.Addresses
      ? dashboard?.boat_reg_location_data?.Addresses[0]?.Address1?.split(",")
      : null
    : null;

  let city_name = dashboard?.boat_reg_location_data
    ? dashboard?.boat_reg_location_data?.Addresses
      ? dashboard?.boat_reg_location_data?.Addresses[0]?.City?.split(",")
      : null
    : null;

  // Scroll to the top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // boat_service API call
  useEffect(() => {
    boat_service(auth?.AuthToken)
      .then((res) => {
        console.log("boat_service res", res?.data);
        if (res?.data?.success) {
          dispatch(boatServiceList(res?.data?.services));
          setBoatServices(res?.data?.services);
        } else {
        }
      })
      .catch((err) => {
        console.log("boat_service Error : ", err);
      });
  }, [auth?.AuthToken, dispatch, boatServiceFlag]);

  const formik = useFormik({
    initialValues: {
      boatName:
        dashboard?.boat_register_step2_data?.boat_name ??
        dashboard?.single_boat_details?.boat_name ??
        "",

      boatType:
        dashboard?.boat_register_step2_data?.boat_type ??
        dashboard?.single_boat_details?.boat_type ??
        "",

      boatYear:
        dashboard?.boat_register_step2_data?.boat_year ??
        dashboard?.single_boat_details?.boat_year ??
        "",

      boatLength:
        dashboard?.boat_register_step2_data?.boat_length ??
        dashboard?.single_boat_details?.boat_length ??
        "",

      lengthType:
        dashboard?.boat_register_step2_data?.length_type ??
        dashboard?.single_boat_details?.length_type ??
        lengthFormat,

      maxCapacity:
        dashboard?.boat_register_step2_data?.boat_max_capacity ??
        dashboard?.single_boat_details?.boat_max_capacity ??
        "",

      pricePerHour:
        dashboard?.boat_register_step2_data?.boat_price_per_hour ??
        dashboard?.single_boat_details?.price_per_hour ??
        "",

      // Marine_Name:
      //   dashboard?.boat_register_step2_data?.marine_name ??
      //   dashboard?.single_boat_details?.marine_name ??
      //   "",

      Marine_Address:
        dashboard?.boat_register_step2_data?.marine_address ??
        dashboard?.single_boat_details?.marine_address ??
        "",

      Marine_city:
        dashboard?.boat_register_step2_data?.marine_city ??
        dashboard?.single_boat_details?.marine_city ??
        "",

      lat:
        dashboard?.boat_register_step2_data?.latitude ??
        dashboard?.single_boat_details?.latitude ??
        "",

      lng:
        dashboard?.boat_register_step2_data?.longitude ??
        dashboard?.single_boat_details?.longitude ??
        "",

      boats_image:
        dashboard?.boat_register_step2_data?.boats_image?.path ??
        dashboard?.single_boat_details?.boats_image ??
        "",
    },

    onSubmit: (values) => {
      // console.log("values", values);
    },

    validate: (values) => {
      const errors = {};

      if (!values.boatName) {
        errors.boatName = "Please enter boat name";
      }
      if (!values.boatType) {
        errors.boatType = "Please select boat type";
      }
      if (values.boatYear === "") {
        errors.boatYear = "Please enter boat year";
      } else if (values.boatYear?.length < 4) {
        errors.boatYear = "Please enter current year or past years";
      }
      if (!values.boatLength) {
        errors.boatLength = "Please enter boat length";
      }
      if (values.maxCapacity === "") {
        errors.maxCapacity = "Please enter Max Capacity";
      }

      if (!values.pricePerHour) {
        errors.pricePerHour = "Please enter Price Per Hour";
      }
      if (!values?.Marine_Address) {
        errors.Marine_Address = "Please select a location in map";
      }
      // if (!values.Marine_Name) {
      //   errors.Marine_Name = "Please enter your Marine Name";
      // }
      if (!values.Marine_city) {
        errors.Marine_city = "Please select a Marine City";
      }
      return errors;
    },
  });

  // Check if there are form validation errors
  useEffect(() => {
    const isFormValid = Object.keys(formik.errors).length === 0;
    setFormikError(isFormValid);
  }, [formik.errors, formik.values]);

  // boat_type API call
  useEffect(() => {
    boat_type(auth?.AuthToken)
      .then((res) => {
        // console.log("boat type list res", res?.data);
        if (res?.data?.success) {
          dispatch(boatTypeList(res?.data?.parameters));
        } else {
        }
      })
      .catch((err) => {
        console.log("boat type list Error : ", err);
      });
  }, [auth?.AuthToken, boatTypeFlag, dispatch]);

  //boat services
  useEffect(() => {
    if (dashboard?.single_boat_details) {
      console.log("dashboard single boat details");
      let selectedBoatServices =
        dashboard?.single_boat_details?.selected_services?.map((s) => {
          const selected = boatServices?.filter(
            (item) => item?.label === s?.service_label
          );
          return selected;
        });
      // Flatten the array of arrays into a single array with depth 1
      const flattenedSelectedBoatServices = selectedBoatServices?.flat(1);
      console.log(flattenedSelectedBoatServices, "selectedboatservices");
      setSelectedBoatServices(flattenedSelectedBoatServices);
      setSelectedImageFiles(dashboard?.single_boat_details?.boats_image);
      setBackgroungImageIndex("API");
      setProfileImgIndex("API");
      setSelectedMarker({
        lat: parseFloat(formik.values.lat),
        lng: parseFloat(formik.values.lng),
      });
      setShowMapAddressLocation(
        dashboard?.single_boat_details?.boats_image?.marine_address
      );
    }
  }, [boatServices]);
  // console.log(selectedImageFiles,'selected image files')

  //selected image files error
  useEffect(() => {
    if (imgUploadMsg?.includes("2") && selectedImageFiles?.length >= 2) {
      setImgUploadError(false);
    } else if (imgUploadMsg?.includes("3") && selectedImageFiles?.length >= 3) {
      setImgUploadError(false);
    }
  }, [selectedImageFiles]);

  useEffect(() => {
    console.log(errTrue, "useeffect");
    if (!errTrue) {
      console.log(errTrue, "errtrue useeffect");
      handleSubmit();
    }
  }, [errTrue, count]);

  //set redux data
  useEffect(() => {
    if (dashboard?.boat_register_step2_data) {
      console.log("dashboard boat step2 details");
      // marine_address
      if (dashboard?.boat_register_step2_data?.marine_address) {
        let marker = {
          lat: parseFloat(dashboard?.boat_register_step2_data?.latitude),
          lng: parseFloat(dashboard?.boat_register_step2_data?.longitude),
        };
        setShowMapAddressLocation(
          dashboard?.boat_register_step2_data?.marine_address
        );
        setSelectedAddress(marker);
        setSelectedMarker(marker);
      }

      // backgroung_image_index
      if (
        dashboard?.boat_register_step2_data?.backgroung_image_index !== null
      ) {
        setBackgroungImageIndex(
          // dashboard?.single_boat_details
          //   ? null
          //   :
          dashboard?.boat_register_step2_data?.backgroung_image_index
        );
      }

      // profile_image_index
      if (dashboard?.boat_register_step2_data?.profile_image_index !== null) {
        setProfileImgIndex(
          // dashboard?.single_boat_details
          //   ? null
          //   :
          dashboard?.boat_register_step2_data?.profile_image_index
        );
      }

      // boat_services_selected
      if (dashboard?.boat_register_step2_data?.boat_services_selected?.length) {
        setSelectedBoatServices(
          dashboard?.boat_register_step2_data?.boat_services_selected
        );
      }

      // customize_boat_services
      if (
        dashboard?.boat_register_step2_data?.customize_boat_services?.length
      ) {
        setCustomServiceValues(
          dashboard?.boat_register_step2_data?.customize_boat_services
        );
      }

      // show_custom_service
      if (dashboard?.boat_register_step2_data?.show_custom_service) {
        setShowCustomService(
          dashboard?.boat_register_step2_data?.show_custom_service
        );
      }

      if (dashboard?.boat_register_step2_data?.marine_city) {
        setSelectedCity(dashboard?.boat_register_step2_data?.marine_city);
      }

      // if(dashboard?.boat_register_step2_data?.selectedImgFiles.length){
      //   setSelectedImageFiles(dashboard?.boat_register_step2_data?.selectedImgFiles.length)
      // }

      // upload_images_of_your_boat
      if (
        dashboard?.boat_register_step2_data?.upload_images_of_your_boat?.length
      ) {
        const files = [];
        for (
          let i = 0;
          i <
          dashboard?.boat_register_step2_data?.upload_images_of_your_boat
            .length;
          i++
        ) {
          const base64String =
            dashboard?.boat_register_step2_data?.upload_images_of_your_boat[i];
          const fileName =
            dashboard?.boat_register_step2_data
              ?.upload_images_of_your_boat_name[i];
          const fileType =
            dashboard?.boat_register_step2_data
              ?.upload_images_of_your_boat_type[i];

          // Check if the data is a valid base64 string
          if (isBase64(base64String)) {
            const file = convertBase64ToFile(base64String, fileName, fileType);
            files.push(file);
          } else {
            // If it's not a base64 string, add the data directly to the array
            files.push(base64String);
          }
        }

        setSelectedImageFiles(files);
      }

      // Function to check if a string is a valid base64 string
      function isBase64(str) {
        try {
          return btoa(atob(str)) == str;
        } catch (err) {
          return false;
        }
      }
    }
  }, [boatServices]); //boatServices, dashboard
  // console.log(selectedBoatServices,'selected boat services')

  //outside click close modal => set cover,thbm imges
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    // Check if the action is "POP" (back button was pressed)
    if (locationNavi.action === "POP") {
      console.log("Back button pressed or slide to go back");
      // You can handle this event as needed, e.g., navigate to a different page
      // navigate('/other-page');
    }
  }, [locationNavi]);

  // marine city api
  useEffect(() => {
    setCityList([]);
    marine_city_list_API()
      .then((res) => {
        console.log("marine city res", res?.data);
        if (res?.data?.success) {
          setCityList(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("marine city api err", err);
      });
  }, []);

  useEffect(() => {
    if (selectedBoatServices?.length) {
      handleHistory();
    }
    if (selectedImageFiles?.length) {
      handleHistory();
    }
    if (selectedAddress) {
      handleHistory();
    }
    if (selectedMarker) {
      handleHistory();
    }
    if (showMapAddressLocation) {
      handleHistory();
    }
  }, [
    selectedBoatServices,
    selectedImageFiles,
    backgroungImageIndex,
    profileImgIndex,
    selectedAddress,
    selectedMarker,
    showMapAddressLocation,
  ]);
  // console.log(formik.values.lat,formik.values.lng)

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleImageClick = (item, index) => {
    setShowModal(true);
    setSelectedImageUrl(
      item instanceof File ? URL.createObjectURL(item) : `${item?.path}`
    ); //${API.baseUrls[API.currentEnv]}
    setSelectedImageUrlFullData(item);
    setSelectedImageUrl_index(index);
  };

  const handleGetLatandLng = (address) => {
    console.log(address, "lat lng address");
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyDJC9c-nt6H0NMCq2eBYMxKT_SfIcZTWwo`
      )
      .then(async (res) => {
        console.log(res, "result lat lng");
        const result = res?.data?.results[0]?.geometry?.location;

        console.log(result?.lng, "lng result");
        setShowMapAddressLocation(address);

        await handleHistory(address, "Marine_Address");
        // dispatch(boat_register_step2({
        //   ...dashboard?.boat_register_step2_data,
        //   latitude: result?.lat,
        //   longitude: result?.lng,
        //   marine_address: address
        // }))

        let marker = {
          lat: result?.lat,
          lng: result?.lng,
        };
        setSelectedMarker(marker);
        formik.setFieldValue("lat", result?.lat);
        formik.setFieldValue("lng", result?.lng);
        formik.setFieldValue("Marine_Address", address);
      })
      .catch((err) => {
        console.log(err, "result lat lng");
      });
  };

  const handleBoatServiceToggle = (service) => {
    console.log(selectedBoatServices, "service");
    const isSelected = selectedBoatServices?.some(
      (s) => s?.label === service?.label
    );

    if (isSelected) {
      console.log("if service");
      setSelectedBoatServices(
        selectedBoatServices?.filter((s) => s?.label !== service?.label)
      );
    } else {
      console.log("else service");
      setSelectedBoatServices([...selectedBoatServices, service]);
    }
  };

  const handleCustomServiceToggle = () => {
    setShowCustomService(!showCustomService);
  };

  const removeImage = (index) => {
    if (index === profileImgIndex) {
      setProfileImgIndex(null);
    } else if (index < profileImgIndex) {
      setProfileImgIndex(profileImgIndex - 1);
    }

    if (index === backgroungImageIndex) {
      setBackgroungImageIndex(null);
    } else if (index < backgroungImageIndex) {
      setBackgroungImageIndex(backgroungImageIndex - 1);
    }

    // const updatedFiles = selectedImageFiles;
    // updatedFiles.filter(index, 1);
    const updatedFiles = selectedImageFiles.filter((item, i) => i !== index);
    setSelectedImageFiles(updatedFiles);
    console.log(profileImgError, backgroundImgError);
  };

  function handleDrop(event) {
    // event.preventDefault();
    const files = event.target.value[0] || event.dataTransfer.files;

    handleFileSelect(files);

    const allowedExtensions = ["jpg", "jpeg", "png"];
    const selectedFile = files[0];
    const fileName = files[0]?.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const fileData = e.target.result;
      };
    }
  }

  function handleDragOver(event) {
    event.preventDefault();
  }

  const handleFileSelect = (files) => {
    toast.dismiss();
    const maxFileSize = 2 * 1024 * 1024;
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const currentSelectedImagesCount = selectedImageFiles.length;

    const selectedImages = Array.from(files).filter((file, index) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const fileSize = file.size;
      return (
        allowedExtensions.includes(fileExtension) && fileSize <= maxFileSize
      );
    });

    if (selectedImages.length !== files.length) {
      toast.error(
        "Invalid file extension or file size. Please select a file with extensions: jpg, jpeg, png and a size below 2MB",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 20000,
        }
      );
    }
    const totalSelectedImagesCount =
      currentSelectedImagesCount + selectedImages.length;
    if (totalSelectedImagesCount > 8) {
      toast.error("You can only select a maximum of 8 images.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 20000,
      });
    } else {
      setSelectedImageFiles([...selectedImageFiles, ...selectedImages]);
    }
    handleHistory();
  };

  // Handle marker selection
  const handleSelectMarker = async (marker) => {
    console.log(marker?.address, "marker address");
    await handleHistory(marker?.address, "Marine_Address");

    setSelectedMarker(marker);
    setSelectedAddress(marker);
    setShowMapAddressLocation(marker?.address);

    formik.setFieldValue("lat", marker?.lat);
    formik.setFieldValue("lng", marker?.lng);
    formik.setFieldValue("Marine_Address", marker?.address);
  };

  //header
  const handleHeaderCallBack = (name) => {
    toast.dismiss();
    if (name === "Home") {
      navigate("/boatOwnerDashBoard");
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else if (
      // name === "My Listings" ||
      name === "Boat Offers" ||
      name === "List a Boat Offer"
    ) {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const add_new_boat_type = () => {
    const labelExists = auth.boatType?.some(
      (item) =>
        item?.label.toLowerCase().split(" ").join("") ===
        addNewBoatTypeValue.toLocaleLowerCase().split(" ").join("")
    );
    if (!labelExists) {
      setAddBoatTypeLoader(true);
      let payload = {
        label: addNewBoatTypeValue,
      };

      add_boat_type_API(auth?.AuthToken, payload)
        .then((res) => {
          // console.log("add_boat_type res", res?.data);
          if (res?.data?.message === "boat type added successfully") {
            formik.setFieldValue("boatType", addNewBoatTypeValue);
            setBoatTypeFlag(Math.random() * 100);
            setAddNewBoatTypeValue("");
            setEnterNewBoatType(addNewBoatTypeValue);
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          setAddBoatTypeLoader(false);
        })
        .catch((err) => {
          console.log("add_boat_type Error : ", err);
          setAddBoatTypeLoader(false);
          toast.error(
            err?.response?.data?.message ??
              "Something went wrong. Please try again later.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
        });
    } else {
      toast.error("Type already exists", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 20000,
      });
    }
  };

  const add_new_boat_service = () => {
    const labelExists = boatServices?.some(
      (item) =>
        item?.label.toLowerCase().split(" ").join("") ===
        addNewBoatServiceValue.toLocaleLowerCase().split(" ").join("")
    );
    if (!labelExists) {
      setAddBoatServiceLoader(true);
      let payload = {
        label: addNewBoatServiceValue,
      };

      add_boat_service_API(auth?.AuthToken, payload)
        .then((res) => {
          // console.log("add_boat service res", res?.data);
          if (res?.data?.message === "boat service added successfully") {
            setBoatServiceFlag(Math.random() * 100);
            setAddNewBoatServiceValue("");
            // setEnterNewBoatType("");

            setSelectedBoatServices((prevState) => [...prevState, payload]);

            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          } else {
            toast.error("Something went wrong. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          setAddBoatServiceLoader(false);
        })
        .catch((err) => {
          console.log("add_boat_type Error : ", err);
          setAddBoatServiceLoader(false);
          toast.error(
            err?.response?.data?.message ??
              "Something went wrong. Please try again later.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
        });
    } else {
      toast.error("Service already exists", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 20000,
      });
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  // Function to convert an array of files to an array of base64 strings
  const convertFilesToBase64 = (files) => {
    const promises = files.map((file) => convertFileToBase64(file));
    return Promise.all(promises);
  };

  const convertBase64ToFile = (base64String, fileName, fileType) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const file = new File([blob], fileName);

    return file;
  };

  const handleCityChange = (values) => {
    const city_Code = values;
    const selectCity = cityList.find((city) => city.city_id === city_Code);
    setSelectedCity(selectCity.marine_city);
    setShowModalForCityNames(false);
    formik.values.Marine_city = selectCity.marine_city;

    formik.setFieldValue("Marine_city", selectCity.marine_city);
    handleHistory(selectCity.marine_city, "Marine_city");
  };

  const handleChangeBoatType = (value) => {
    auth?.boatType?.map((item) => {
      if (value === "others") {
        setEnterNewBoatType("others");
      } else if (item?.label === value) {
        setEnterNewBoatType(item.label);
        formik.values.boatType = item.label;
      }
      handleHistory();
    });
    // dispatch(
    //   boat_register_step2({
    //     boat_type: formik.values.boatType
    // }))
  };
  // console.log(selectedImageFiles,'selectedimgfiles')

  //
  // const SetErrorMsg = () => {
  //   if (selectedCity === "") {
  //     setSelectedCityError(true);
  //   } else {
  //     setSelectedCityError(false);
  //   }

  //   if (selectedImageFiles.length) {
  //     setImgUploadError(false);
  //     setImgUploadMsg("");
  //   } else {
  //     setImgUploadError(true);
  //     setImgUploadMsg("Please upload an image");
  //   }

  //   if (backgroungImageIndex !== null) {
  //     setBackgroundImgError(false);
  //   } else {
  //     setBackgroundImgError(true);

  //     const middleY =
  //       imageRef.current.offsetTop + imageRef.current.offsetHeight / 2;
  //     window.scrollTo({
  //       top: middleY - window.innerHeight / 2,
  //       behavior: "smooth",
  //     });
  //   }

  //   if (profileImgIndex !== null) {
  //     setProfileImgError(false);
  //   } else {
  //     setProfileImgError(true);
  //     const middleY =
  //       imageRef.current.offsetTop + imageRef.current.offsetHeight / 2;
  //     window.scrollTo({
  //       top: middleY - window.innerHeight / 2,
  //       behavior: "smooth",
  //     });
  //   }
  //   console.log(
  //     // (profileImgIndex !== "API" || backgroungImageIndex !== "API") &&
  //     //   profileImgIndex === backgroungImageIndex &&
  //     //   selectedImageFiles.length < 2,
  //     (profileImgIndex !== "API" || backgroungImageIndex !== "API") &&
  //       profileImgIndex !== backgroungImageIndex &&
  //       selectedImageFiles.length < 3,
  //     "new condition"
  //   );
  //   if (
  //     (profileImgIndex !== "API" || backgroungImageIndex !== "API") &&
  //     profileImgIndex === backgroungImageIndex &&
  //     selectedImageFiles.length < 2
  //   ) {
  //     // console.log("new err working");
  //     setImgUploadError(true);
  //     setImgUploadMsg("Please select minimun 2 images");
  //   } else if (
  //     (profileImgIndex !== "API" || backgroungImageIndex !== "API") &&
  //     profileImgIndex !== backgroungImageIndex &&
  //     selectedImageFiles.length < 3
  //   ) {
  //     console.log("new err working");
  //     setImgUploadError(true);
  //     setImgUploadMsg("Please select minimun 3 images");
  //   } else {
  //     setImgUploadMsg("");
  //     setImgUploadError(false);
  //   }

  //   if (selectedBoatServices?.length) {
  //     setBoatServiceError(false);
  //   } else {
  //     setBoatServiceError(true);
  //     if (profileImgIndex !== null && backgroungImageIndex !== null) {
  //       // BoatServiceRef.current.scrollIntoView({ behavior: "smooth" });
  //       const middleY =
  //         BoatServiceRef.current.offsetTop +
  //         BoatServiceRef.current.offsetHeight / 2;
  //       window.scrollTo({
  //         top: middleY - window.innerHeight / 2,
  //         behavior: "smooth",
  //       });
  //     }
  //   }
  //   if (showMapAddressLocation) {
  //     setMapLocError(false);
  //   } else {
  //     setMapLocError(true);
  //   }
  // };
  // console.log(selectedImageFiles,'selected image files')

  const SetErrorMsg = () => {
    console.log("working or not");
    Object.keys(formik.values).forEach((fieldName) => {
      formik.setFieldTouched(fieldName, true);
    });
    if (
      selectedCity === "" ||
      !selectedImageFiles.length ||
      backgroungImageIndex === null ||
      profileImgIndex === null ||
      ((profileImgIndex !== "API" || backgroungImageIndex !== "API") &&
        profileImgIndex === backgroungImageIndex &&
        selectedImageFiles.length < 2) ||
      ((profileImgIndex !== "API" || backgroungImageIndex !== "API") &&
        profileImgIndex !== backgroungImageIndex &&
        selectedImageFiles.length < 3) ||
      !selectedBoatServices?.length
    ) {
      if (selectedCity === "") {
        setSelectedCityError(true);
        setErrTrue(true);
      }
      if (!selectedImageFiles.length) {
        setImgUploadError(true);
        setImgUploadMsg("Please upload an image");
        setErrTrue(true);
      }

      if (backgroungImageIndex === null) {
        setBackgroundImgError(true);
        setErrTrue(true);
        const middleY =
          imageRef.current.offsetTop + imageRef.current.offsetHeight / 2;
        window.scrollTo({
          top: middleY - window.innerHeight / 2,
          behavior: "smooth",
        });
      }

      if (profileImgIndex === null) {
        setProfileImgError(true);
        setErrTrue(true);
        const middleY =
          imageRef.current.offsetTop + imageRef.current.offsetHeight / 2;
        window.scrollTo({
          top: middleY - window.innerHeight / 2,
          behavior: "smooth",
        });
      }

      if (
        (profileImgIndex !== "API" || backgroungImageIndex !== "API") &&
        profileImgIndex === backgroungImageIndex &&
        selectedImageFiles.length < 2
      ) {
        // console.log("new err working");
        setImgUploadError(true);
        setErrTrue(true);
        setImgUploadMsg("Please select minimun 2 images");
      }
      if (
        (profileImgIndex !== "API" || backgroungImageIndex !== "API") &&
        profileImgIndex !== backgroungImageIndex &&
        selectedImageFiles.length < 3
      ) {
        setErrTrue(true);
        setImgUploadError(true);
        setImgUploadMsg("Please select minimun 3 images");
      }

      if (!selectedBoatServices?.length) {
        setBoatServiceError(true);
        setErrTrue(true);
        const middleY =
          BoatServiceRef.current.offsetTop +
          BoatServiceRef.current.offsetHeight / 2;
        window.scrollTo({
          top: middleY - window.innerHeight / 2,
          behavior: "smooth",
        });
      }
      if (!showMapAddressLocation) {
        // setMapLocError(true);
        setErrTrue(true);
      }
      setCount(count + 1);
    } else {
      console.log("false errtrue");
      setErrTrue(false);
      setCount(count + 1);
    }
  };
  // };
  const handleSubmit = async () => {
    console.log(
      "Going to Step 3",
      //   Boolean(selectedImageFiles.length > 0),
      //   Boolean(selectedBoatServices?.length > 0),
      //   Boolean(showMapAddressLocation !== null),
      //   formikError
      // selectedImageFiles.length > 0 &&
      //   selectedBoatServices?.length > 0 &&
      //   showMapAddressLocation !== null &&
      //   formikError &&
      //   backgroungImageIndex !== null &&
      //   profileImgIndex !== null &&
      //   selectedCity.city !== "" &&
      //   !imgUploadError &&
      !errTrue
    );
    // Object.keys(formik.values).forEach((fieldName) => {
    //   formik.setFieldTouched(fieldName, true);
    // });
    if (dashboard?.single_boat_details) {
      if (
        selectedImageFiles.length > 0 &&
        selectedBoatServices?.length > 0 &&
        // showMapAddressLocation !== null &&
        formikError &&
        backgroungImageIndex !== null &&
        profileImgIndex !== null &&
        selectedCity.city !== "" &&
        !imgUploadError &&
        !errTrue
      ) {
        console.log("selectedImageFiles", selectedImageFiles);
        let afterConversion = selectedImageFiles?.map(async (item, index) => {
          if (item instanceof File) {
            try {
              const base64Data = await convertFilesToBase64([item]);
              return base64Data[0]; // Since convertFilesToBase64 returns an array, take the first element
            } catch (error) {
              console.error("Error converting file to base64:", error);
              return null; // Handle the error as needed
            }
          } else {
            console.log("not file");
            return item;
          }
        });

        console.log("afterConversion", afterConversion);
        Promise.all(afterConversion)
          .then((result) => {
            console.log("After conversion:", result);
            console.log("selectedImageFiles", selectedImageFiles);
            const fileNames = selectedImageFiles.map((file) =>
              typeof file === "string" ? null : file.name
            );
            const fileTypes = selectedImageFiles.map((file) =>
              typeof file === "string" ? null : file.type
            );

            dispatch(
              boat_register_step2({
                boat_name: formik.values.boatName,
                boat_type: formik.values.boatType,
                boat_year: formik.values.boatYear,
                boat_length: formik.values.boatLength,
                length_type: formik.values.lengthType,
                boat_max_capacity: formik.values.maxCapacity,
                boat_price_per_hour: formik.values.pricePerHour,
                //
                upload_images_of_your_boat: result,
                upload_images_of_your_boat_name: fileNames,
                upload_images_of_your_boat_type: fileTypes,
                //
                boat_services_selected: selectedBoatServices,
                // marine_name: formik.values.Marine_Name,
                marine_address: formik.values.Marine_Address,
                backgroung_image_index: backgroungImageIndex,
                profile_image_index: profileImgIndex,
                customize_boat_services: customServiceValues,
                show_custom_service: showCustomService,
                latitude: selectedMarker.lat,
                longitude: selectedMarker.lng,
                marine_city: formik.values.Marine_city,
              })
            );
            console.log(dashboard?.boat_register_step2);
            navigate("/BoatOfferStep3");
          })
          .catch((error) => {
            console.error("Error converting files to base64:", error);
          });
      }
      // else {
      //   SetErrorMsg();
      // }
    } else {
      if (
        selectedImageFiles.length > 0 &&
        selectedBoatServices?.length > 0 &&
        // showMapAddressLocation !== null &&
        formikError &&
        backgroungImageIndex !== null &&
        profileImgIndex !== null &&
        selectedCity.city !== "" &&
        !imgUploadError &&
        !errTrue
      ) {
        // console.log(selectedImageFiles, "img");
        selectedImageFiles.forEach((item, index) => {
          if (typeof item?.path === "string") {
            selectedImageFiles.splice(index, 1);
          }
        });
        // console.log(selectedImageFiles, "img");
        convertFilesToBase64(selectedImageFiles)
          .then((base64Array) => {
            // console.log(base64Array, "base64array");
            const fileNames = selectedImageFiles.map((file) =>
              typeof file === "string" ? null : file.name
            );
            const fileTypes = selectedImageFiles.map((file) =>
              typeof file === "string" ? null : file.type
            );

            dispatch(
              boat_register_step2({
                boat_name: formik.values.boatName,
                boat_type: formik.values.boatType,
                boat_year: formik.values.boatYear,
                boat_length: formik.values.boatLength,
                length_type: formik.values.lengthType,
                boat_max_capacity: formik.values.maxCapacity,
                boat_price_per_hour: formik.values.pricePerHour,
                //
                upload_images_of_your_boat: base64Array,
                upload_images_of_your_boat_name: fileNames,
                upload_images_of_your_boat_type: fileTypes,
                //
                boat_services_selected: selectedBoatServices,
                // marine_name: formik.values.Marine_Name,
                marine_address: formik.values.Marine_Address,
                backgroung_image_index: backgroungImageIndex,
                profile_image_index: profileImgIndex,
                customize_boat_services: customServiceValues,
                show_custom_service: showCustomService,
                latitude: selectedMarker?.lat,
                longitude: selectedMarker?.lng,
                marine_city: formik.values.Marine_city,
              })
            );

            navigate("/BoatOfferStep3");
          })
          .catch((error) => {
            console.error("Error converting files to base64:", error);
          });
      } else {
        console.log(
          "its not working bcz of something",
          selectedImageFiles.length > 0,
          selectedBoatServices?.length > 0,
          formikError,
          backgroungImageIndex !== null,
          profileImgIndex !== null,
          selectedCity.city !== "",
          !imgUploadError,
          !errTrue
        );
      }
    }
  };

  console.log(
    dashboard?.boat_register_step2_data,
    "step 2 data",
    formik.values.lng
  );

  const handleHistory = (value, type) => {
    console.log("history values", value, type);
    // console.log('formik',formik.values.boatName,"-",value)
    let afterConversion = selectedImageFiles?.map(async (item, index) => {
      if (item instanceof File) {
        try {
          const base64Data = await convertFilesToBase64([item]);
          return base64Data[0]; // Since convertFilesToBase64 returns an array, take the first element
        } catch (error) {
          console.error("Error converting file to base64:", error);
          return null; // Handle the error as needed
        }
      } else {
        console.log("not file");
        return item;
      }
    });
    Promise.all(afterConversion)
      .then((result) => {
        // console.log("After conversion:", result);
        // console.log("selectedImageFiles", selectedImageFiles);
        const fileNames = selectedImageFiles.map((file) =>
          typeof file === "string" ? null : file.name
        );
        const fileTypes = selectedImageFiles.map((file) =>
          typeof file === "string" ? null : file.type
        );
        // convertFilesToBase64(selectedImageFiles)
        // .then((base64Array) => {
        //   const fileNames = selectedImageFiles.map((file) =>
        //     typeof file === "string" ? null : file.name
        //   );
        //   const fileTypes = selectedImageFiles.map((file) =>
        //     typeof file === "string" ? null : file.type
        //   );
        dispatch(
          boat_register_step2({
            boat_name:
              type === "boatName" ? value : null || formik.values.boatName,
            boat_type: formik.values.boatType,
            boat_year:
              type === "boatYear" ? value : null || formik.values.boatYear,
            boat_length:
              type === "boatLength" ? value : null || formik.values.boatLength,
            length_type:
              type === "lengthType"
                ? value
                : formik.values.lengthType || lengthFormat,
            boat_max_capacity:
              type === "maxCapacity"
                ? value
                : null || formik.values.maxCapacity,
            boat_price_per_hour:
              type === "pricePerHour"
                ? value
                : null || formik.values.pricePerHour,

            upload_images_of_your_boat: result,
            upload_images_of_your_boat_name: fileNames,
            upload_images_of_your_boat_type: fileTypes,

            boat_services_selected: selectedBoatServices,
            // marine_name:
            //   type === "Marine_Name"
            //     ? value
            //     : null || formik.values.Marine_Name,
            marine_address:
              type === "Marine_Address" ? value : showMapAddressLocation,
            backgroung_image_index: backgroungImageIndex,
            profile_image_index: profileImgIndex,
            customize_boat_services: customServiceValues,
            show_custom_service: showCustomService,
            latitude: selectedMarker?.lat || formik.values.lat,
            longitude: selectedMarker?.lng || formik.values.lng,
            marine_city:
              type === "Marine_city"
                ? value
                : null || formik.values.Marine_city,
          })
        );
      })
      .catch((error) => {
        console.error("Error converting files to base64:", error);
      });
  };
  // console.log(formik.values.boatName,'-',dashboard?.boat_register_step2_data?.boat_name)

  return (
    <>
      <div className="show-header-outSide-banner">
        <PageHeader
          showLoginSignUp={auth?.AuthToken ? false : true}
          handle_navigation={handleHeaderCallBack}
          presentPage={""}
          link1={"Boat Offers"}
          // link2={"My Listings"}
          link2={"List a Boat Offer"}
          link3={
            auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
              ? "My Listings"
              : null
          }
        />
      </div>
      <div
        className="show-header-inside-banner"
        style={{ backgroundColor: "#f6f6f6" }}
      >
        <PageHeader
          showLoginSignUp={auth?.AuthToken ? false : true}
          handle_navigation={handleHeaderCallBack}
          presentPage={""}
          link1={"Boat Offers"}
          // link2={"My Listings"}
          link2={"List a Boat Offer"}
          link3={
            auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
              ? "My Listings"
              : null
          }
        />
      </div>

      <div className={class_name.page_body_container}>
        <Typography className={class_name.Show_off_your_boat_in_few_clicks}>
          Show off your boat in few clicks!
        </Typography>

        <div className={class_name.form_container_box}>
          <Typography className={class_name.step_2_txt}>Step 2</Typography>

          <Divider
            variant="fullWidth"
            style={{
              backgroundColor: "rgba(66, 70, 81, 0.9)",
              height: "1px",
              border: "solid .5px rgba(66, 70, 81, 0.2)",
            }}
          />
          <form
            // onSubmit={formik.handleSubmit}
            className={class_name.submit_form_container}
          >
            {/* ======================---- Boat Name ----&&---- Boat Type ----==================== */}
            <div className={class_name.row_div_contain_two_box}>
              <div className={class_name.txt_filed_container_box}>
                <Typography className={class_name.labelStyle}>
                  Boat Name
                </Typography>
                <CustomTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="boatName"
                  name="boatName"
                  placeholder="Boat Name"
                  value={formik.values.boatName}
                  onChange={(event) => {
                    console.log("render");
                    const inputValue = event.target.value;
                    if (NotNumber.test(inputValue)) {
                      formik.setFieldValue("boatName", inputValue);
                      handleHistory(inputValue, "boatName");
                    }
                  }}
                  error={
                    formik.touched.boatName && Boolean(formik.errors.boatName)
                  }
                  helperText={formik.touched.boatName && formik.errors.boatName}
                  InputProps={{
                    style: {
                      ...textFieldStyles,
                      borderRadius: width >= 480 ? "15px" : "8px",
                      border:
                        formik.touched.boatName &&
                        Boolean(formik.errors.boatName)
                          ? "solid 1px red"
                          : "solid 1px rgba(66, 70, 81, 0.3)",
                      cursor: "pointer",
                    },
                  }}
                  inputProps={{
                    className: class_name.input_txt_style,
                  }}
                />
              </div>

              <div className={class_name.txt_filed_container_box}>
                <Typography className={class_name.labelStyle}>
                  Boat Type
                </Typography>
                <CustomTextField
                  // label={
                  //   formik.values.boatType === "" ? <span className="fs-1">Select Boat Type</span> : null
                  // }
                  select
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="boatType"
                  name="boatType"
                  value={formik.values.boatType}
                  // style={{"& .MuiInputBase-input":{
                  //   color: 'red'
                  // }}}
                  SelectProps={{
                    displayEmpty: true,
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                      },
                      // anchorPosition:"bottom",
                      getContentAnchorEl: null,
                    },
                  }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setEnterNewBoatType(e.target.value);
                  }}
                  error={
                    formik.touched.boatType && Boolean(formik.errors.boatType)
                  }
                  helperText={formik.touched.boatType && formik.errors.boatType}
                  InputProps={{
                    style: {
                      ...textFieldStyles,
                      color: formik.values.boatType ? "#212121" : "#bcbcbc",
                      borderRadius: width >= 480 ? "15px" : "8px",
                      border:
                        formik.touched.boatType &&
                        Boolean(formik.errors.boatType)
                          ? "solid 1px red"
                          : "solid 1px rgba(66, 70, 81, 0.3)",
                    },
                  }}
                  inputProps={{
                    className: class_name.input_txt_style,
                  }}
                  InputLabelProps={{
                    shrink: false,
                    style: {
                      // top: width < 767 ? "-25%" : "-5%",
                      fontSize: "clamp(8px, 2vw, 16px)",
                      color: "#757575",
                      // height: width > 768 ? "38%" : width > 480 ? "40%" : "0%",
                      // translate:
                      //   width > 992
                      //     ? "0px 25%"
                      //     : width > 768
                      //     ? "0px 20%"
                      //     : width > 480
                      //     ? "0px -10%"
                      //     : "0px 80%",
                      display: "flex",
                      alignItems: "center",
                      // className: class_name.input_label_boatType
                    },
                  }}
                >
                  <MenuItem
                    disabled
                    value=""
                    // style={{color: 'red'}}
                  >
                    Boat type
                  </MenuItem>
                  {auth.boatType?.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.label}
                      selected={formik.values.boatType === item.label}
                      onClick={() => {
                        handleChangeBoatType(item.label);
                        handleHistory();
                      }}
                      style={{
                        width: "auto",
                        height: "auto",
                        textAlign: "left",
                        alignItems: "flex-start",
                        padding: "5px 10px",
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "clamp(16px, 3vw, 15px)",
                        color: "#424651",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
                  <MenuItem
                    value={"others"}
                    style={{
                      padding: "5px 10px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Others
                  </MenuItem>
                </CustomTextField>
                {/* <CustomTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="boatType"
                  name="boatType"
                  placeholder="Boat Type"
                  value={formik.values.boatType}
                  onClick={()=>setShowModalBoatType(!showModalBoatType)}
                  onChange={(e) => {
                    // formik.handleChange(e);
                    // setEnterNewBoatType(e.target.value);
                    formik.setFieldValue("boatType",enterNewBoatType)
                  }}
                  error={
                    formik.touched.boatType && Boolean(formik.errors.boatType)
                  }
                  helperText={formik.touched.boatType && formik.errors.boatType}
                  InputProps={{
                    readOnly: true,
                    style: {
                      ...textFieldStyles,
                      border:
                        formik.touched.boatName &&
                        Boolean(formik.errors.boatName)
                          ? "solid 1px red"
                          : "solid 1px rgba(66, 70, 81, 0.3)",
                      cursor: "pointer",
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 0,
                        }}
                        // onClick={() => {
                        //   setHideBtn(true);
                        //   setShowModalForCityNames(true);
                        // }}
                      >
                        <IconButton
                        onClick={() => {
                          setHideBtn(true);
                          setShowModalBoatType(true);
                        }}
                        >
                          <img
                            alt="down"
                            src={IMAGES.DOWN}
                            style={{ width: "13px", height: "10px" }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    className: class_name.input_txt_style,
                  }}
                /> */}

                {/* Render the boat type options */}
                {/* <Dialog
                  open={showModalBoatType}
                  onClose={() => {
                    setHideBtn(false);
                    setShowModalBoatType(false);
                  }}
                  scroll="paper"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <DialogTitle>Select Boat Type</DialogTitle>
                    <IconButton
                      onClick={() => {
                        setHideBtn(false);
                        setShowModalBoatType(false);
                      }}
                      style={{
                        alignContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  </div>
                  <DialogContent
                    dividers
                    style={{ minHeight: "auto", minWidth: "100%" }}
                    onClick={()=>setShowModalBoatType(false)}
                  >
                    {auth.boatType?.map((item, index) => (
                    <MenuItem
                    key={index}
                    value={item.label}
                    selected={formik.values.boatType === item.label}
                    onClick={()=>handleChangeBoatType(item.label)}
                    style={{
                      width: "auto",
                      height: "auto",
                      textAlign: "left",
                      alignItems: "flex-start",
                      padding: "5px 10px",
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "clamp(16px, 3vw, 15px)",
                      color: "#424651",
                      overflowX: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    {item?.label}
                  </MenuItem>
                    ))}
                  <MenuItem
                    value={"Others"}
                    onClick={()=>handleChangeBoatType("others")}
                    style={{
                      padding: "5px 10px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Others
                  </MenuItem>
                  </DialogContent>
                </Dialog> */}

                {enterNewBoatType === "others" ? (
                  <CustomTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="enterNewBoatType"
                    className={class_name.add_new_type_input}
                    name="enterNewBoatType"
                    placeholder="Enter new boat type"
                    value={addNewBoatTypeValue}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      if (
                        latterAndNo_ValidationPattern.test(inputValue) &&
                        NotNumber.test(inputValue)
                      ) {
                        setAddNewBoatTypeValue(inputValue);
                        // setEnterNewBoatType(inputValue)
                      } else if (inputValue === "") {
                        setAddNewBoatTypeValue("");
                      }
                    }}
                    error={
                      formik.touched.boatType && Boolean(formik.errors.boatType)
                    }
                    helperText={
                      formik.touched.boatType && formik.errors.boatType
                    }
                    InputProps={{
                      style: {
                        ...textFieldStyles,
                        borderRadius: width >= 480 ? "15px" : "8px",
                        border:
                          formik.touched.boatType &&
                          Boolean(formik.errors.boatType)
                            ? "solid 1px red"
                            : "solid 1px rgba(66, 70, 81, 0.3)",
                      },
                      endAdornment: (
                        <InputAdornment
                          style={{
                            width: "10%",
                            display: "flex",
                            justifyContent: "center",
                            marginLeft: "1%",
                            paddingRight: "0",
                          }}
                        >
                          <IconButton
                            className={class_name.icon_btn}
                            onClick={() => {
                              toast.dismiss();
                              addNewBoatTypeValue === ""
                                ? toast.error("Please enter boat type", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 20000,
                                  })
                                : add_new_boat_type();
                            }}
                          >
                            {addBoatTypeLoader ? (
                              <CircularProgress size={24} thickness={4} />
                            ) : (
                              <Typography
                                style={{
                                  fontSize: "clamp(6px, 3vw, 12px)",
                                  color: "#3973a5",
                                }}
                              >
                                ADD
                              </Typography>
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      className: class_name.input_txt_style,
                    }}
                  />
                ) : null}
              </div>
            </div>

            {/* ======================---- Boat Year ----&&---- Boat Length ----==================== */}
            <div className={class_name.row_div_contain_two_box}>
              <div className={class_name.txt_filed_container_box}>
                <Typography className={class_name.labelStyle}>
                  Boat Year
                </Typography>

                <CustomTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="boatYear"
                  name="boatYear"
                  placeholder="Boat Year"
                  value={formik.values.boatYear}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (
                      numberValidationPattern.test(inputValue) &&
                      inputValue?.length <= 4
                    ) {
                      if (inputValue?.length === 4) {
                        if (inputValue <= moment().format("YYYY")) {
                          formik.setFieldValue("boatYear", inputValue);
                          handleHistory(inputValue, "boatYear");
                        } else {
                          toast.dismiss();
                          toast.error(
                            "Please enter current year or past years",
                            {
                              position: toast.POSITION.TOP_RIGHT,
                              autoClose: 2000,
                            }
                          );
                        }
                      } else {
                        formik.setFieldValue("boatYear", inputValue);
                      }
                    } else if (inputValue === "") {
                      formik.setFieldValue("boatYear", "");
                    }
                  }}
                  error={
                    formik.touched.boatYear && Boolean(formik.errors.boatYear)
                  }
                  helperText={formik.touched.boatYear && formik.errors.boatYear}
                  InputProps={{
                    style: {
                      ...textFieldStyles,
                      borderRadius: width >= 480 ? "15px" : "8px",
                      border:
                        formik.touched.boatYear &&
                        Boolean(formik.errors.boatYear)
                          ? "solid 1px red"
                          : "solid 1px rgba(66, 70, 81, 0.3)",
                    },
                  }}
                  inputProps={{
                    className: class_name.input_txt_style,
                  }}
                />
              </div>
              <div className={class_name.txt_filed_container_box}>
                <Typography className={class_name.labelStyle}>
                  Boat Length
                </Typography>
                <CustomTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="boatLength"
                  name="boatLength"
                  placeholder="Boat Length"
                  value={formik.values.boatLength}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (
                      numberValidationPattern.test(inputValue) &&
                      inputValue.length <= 6
                    ) {
                      formik.setFieldValue("boatLength", inputValue);
                      handleHistory(inputValue, "boatLength");
                    } else if (inputValue === "") {
                      formik.setFieldValue("boatLength", "");
                    }
                  }}
                  error={
                    formik.touched.boatLength &&
                    Boolean(formik.errors.boatLength)
                  }
                  helperText={
                    formik.touched.boatLength && formik.errors.boatLength
                  }
                  InputProps={{
                    style: {
                      ...textFieldStyles,
                      borderRadius: width >= 480 ? "15px" : "8px",
                      border:
                        formik.touched.boatLength &&
                        Boolean(formik.errors.boatLength)
                          ? "solid 1px red"
                          : "solid 1px rgba(66, 70, 81, 0.3)",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        {/* <IconButton>
                          <Typography className={class_name.input_txt_style}>
                            feet(ft)
                          </Typography>
                        </IconButton> */}
                        <Select
                          label="MM"
                          placeholder="MM"
                          disableUnderline
                          value={formik.values.lengthType.toLowerCase()}
                          onChange={(e) => {
                            setLengthFormat(e.target.value);
                            formik.setFieldValue("lengthType", e.target.value);
                            handleHistory(e.target.value, "lengthType");
                          }}
                          SelectProps={{
                            MenuProps: {
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                              anchorPosition: "bottom",
                              getContentAnchorEl: null,
                            },
                          }}
                        >
                          <MenuItem value="meter">m</MenuItem>
                          <MenuItem value="feet">ft</MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    className: class_name.input_txt_style,
                  }}
                />
              </div>
            </div>

            {/* ======================---- Max Capacity ----&&---- Price Per Hour ----==================== */}
            <div className={class_name.row_div_contain_two_box}>
              <div className={class_name.txt_filed_container_box}>
                <Typography className={class_name.labelStyle}>
                  Max Capacity
                </Typography>
                <CustomTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="maxCapacity"
                  name="maxCapacity"
                  placeholder="Max Capacity"
                  value={formik.values.maxCapacity}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (
                      numberValidationPattern.test(inputValue) &&
                      inputValue <= 999
                    ) {
                      formik.setFieldValue("maxCapacity", inputValue);
                      handleHistory(inputValue, "maxCapacity");
                    } else if (inputValue === "") {
                      formik.setFieldValue("maxCapacity", "");
                    }
                  }}
                  error={
                    formik.touched.maxCapacity &&
                    Boolean(formik.errors.maxCapacity)
                  }
                  helperText={
                    formik.touched.maxCapacity && formik.errors.maxCapacity
                  }
                  InputProps={{
                    style: {
                      ...textFieldStyles,
                      borderRadius: width >= 480 ? "15px" : "8px",
                      border:
                        formik.touched.maxCapacity &&
                        Boolean(formik.errors.maxCapacity)
                          ? "solid 1px red"
                          : "solid 1px rgba(66, 70, 81, 0.3)",
                    },
                  }}
                  inputProps={{
                    className: class_name.input_txt_style,
                  }}
                />
              </div>
              <div className={class_name.txt_filed_container_box}>
                <Typography className={class_name.labelStyle}>
                  Price Per Hour
                </Typography>
                <CustomTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="pricePerHour"
                  name="pricePerHour"
                  placeholder="Price Per Hour"
                  value={formik.values.pricePerHour}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (
                      numberValidationPattern.test(inputValue) &&
                      inputValue <= 9999
                    ) {
                      formik.setFieldValue("pricePerHour", inputValue);
                      handleHistory(inputValue, "pricePerHour");
                    } else if (inputValue === "") {
                      formik.setFieldValue("pricePerHour", "");
                    }
                  }}
                  error={
                    formik.touched.pricePerHour &&
                    Boolean(formik.errors.pricePerHour)
                  }
                  helperText={
                    formik.touched.pricePerHour && formik.errors.pricePerHour
                  }
                  InputProps={{
                    style: {
                      ...textFieldStyles,
                      borderRadius: width >= 480 ? "15px" : "8px",
                      border:
                        formik.touched.pricePerHour &&
                        Boolean(formik.errors.pricePerHour)
                          ? "solid 1px red"
                          : "solid 1px rgba(66, 70, 81, 0.3)",
                    },
                  }}
                  inputProps={{
                    className: class_name.input_txt_style,
                  }}
                />
              </div>
            </div>

            {/* Upload images of your boat */}

            <div>
              {showModal ? (
                <>
                  <ImageSlider
                    slides={selectedImageUrl}
                    onClose={handleModalClose}
                    item={selectedImageUrlFullData}
                    index={selectedImageUrl_index}
                    goToPrevious={null}
                    goToNext={null}
                  />
                </>
              ) : null}
            </div>

            <Typography className={class_name.upload_imges_txt}>
              Upload images of your boat
            </Typography>

            <label
              htmlFor="fileInput"
              style={{
                width: "100%",
              }}
            >
              <div
                id="dropArea"
                onDrop={(e) => handleDrop(e)}
                onDragOver={handleDragOver}
              >
                <div
                  style={{
                    border:
                      imgUploadError && errTrue
                        ? "1px dashed red"
                        : "1px dashed gray",
                  }}
                  className={class_name.select_img_box}
                >
                  <input
                    type="file"
                    id="fileInput"
                    multiple
                    onChange={(event) => {
                      handleFileSelect(event.target.files);
                    }}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    style={{ display: "none" }}
                    accept=".jpg,.jpeg,.png"
                  />
                  <img
                    alt="cloud"
                    src={IMAGES.CLOUD_UPLOAD_SIGNAL}
                    className={class_name.cloud_img}
                  />

                  <Typography className={class_name.Drop_img_txt}>
                    Drop your images here, or
                  </Typography>
                  <Typography className={class_name.Choose_txt}>
                    Choose File
                  </Typography>
                  <Typography
                    style={{}}
                    className={class_name.select_type_info}
                  >
                    supports JPG, PNG,JPEG
                  </Typography>
                </div>
              </div>
            </label>
            <div ref={imageRef}>
              {imgUploadError && errTrue ? (
                <Typography className={class_name.err_msg_style}>
                  {imgUploadMsg}
                </Typography>
              ) : null}

              {selectedImageFiles.length > 0 &&
              profileImgIndex === null &&
              profileImgError &&
              errTrue ? (
                <Typography className={class_name.err_msg_style}>
                  Please set Thumbnail Image
                </Typography>
              ) : null}

              {selectedImageFiles.length > 0 &&
              backgroungImageIndex === null &&
              backgroundImgError &&
              errTrue ? (
                <Typography className={class_name.err_msg_style}>
                  Please set cover Image
                </Typography>
              ) : null}
            </div>

            {/* ========================================================== selected boat images  ==========================================================  */}
            <div
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                {selectedImageFiles.length ? (
                  <>
                    {selectedImageFiles.map((item, index) => {
                      let trackIndex = index;
                      const blob = new Blob([item.fileData], {
                        type: item.type,
                      });
                      if (item instanceof File) {
                        // console.log("index item", index, item);
                      }
                      return (
                        <Tooltip
                          // style={{width: '100%'}}
                          arrow={true}
                          key={index}
                          placement={"top"}
                          title={
                            backgroungImageIndex === index
                              ? "Boat Profile Cover Image"
                              : profileImgIndex === index
                              ? "Boat Thumbnail in Search Result"
                              : ""
                          }
                        >
                          <div className={class_name.show_selected_imgs}>
                            <div
                              className={
                                class_name.show_selected_imgs_div_have_cut
                              }
                              onMouseOver={(e) => {
                                setShowCut(index);
                              }}
                            >
                              <img
                                alt="user selected img"
                                src={
                                  item instanceof File
                                    ? URL.createObjectURL(item)
                                    : `${item?.path}` //${API.baseUrls[API.currentEnv]}
                                }
                                className={class_name.selected_imgs_style}
                                onClick={() => handleImageClick(item, null)}
                              />

                              <div
                                className={
                                  class_name.selected_imgs_name_txt_style
                                }
                              >
                                <div
                                  className={
                                    class_name.selected_imgs_name_inner_style
                                  }
                                >
                                  <Typography
                                    className={
                                      class_name.selected_imgs_naming_style_txt
                                    }
                                  >
                                    {!item?.name
                                      ? "Boat Image"
                                      : item?.name?.length > 26
                                      ? item?.name?.substring(0, 26) + " ..."
                                      : item?.name}
                                  </Typography>
                                  {backgroungImageIndex === index ||
                                  item ===
                                    dashboard?.single_boat_details
                                      ?.background_image ? (
                                    <Typography
                                      style={{
                                        color: "#424651",
                                      }}
                                      className={
                                        class_name.selected_imgs_naming_style_txt
                                      }
                                    >
                                      {"Set as Cover"}
                                    </Typography>
                                  ) : null}
                                  {profileImgIndex === index ||
                                  item ===
                                    dashboard?.single_boat_details
                                      ?.front_image ? (
                                    <Typography
                                      style={{
                                        color: "#424651",
                                      }}
                                      className={
                                        class_name.selected_imgs_naming_style_txt
                                      }
                                    >
                                      {"Set as Thumbnail"}
                                    </Typography>
                                  ) : null}
                                </div>
                                <div
                                  className={
                                    class_name.selected_imgs_cutter_styles
                                  }
                                >
                                  {showCut === index || width < 768 ? (
                                    <div>
                                      <Clear
                                        className={class_name.clear_icon}
                                        onClick={() => {
                                          removeImage(index);
                                        }}
                                      />
                                    </div>
                                  ) : null}

                                  <MoreVert
                                    className={class_name.morevert_icon}
                                    // style={{
                                    //   cursor: "pointer",
                                    //   fontSize: "clamp(8px, 5vw, 30px)",
                                    // }}
                                    onClick={() => {
                                      setOpenModal(true);
                                      setModalOpen(true);
                                      setModalOpenIndex(trackIndex);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* ======================================  set profile & backgroung img Modal ====================================== */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                zIndex: "9999",
                              }}
                            >
                              {modalOpen && modalOpenIndex === trackIndex ? (
                                <>
                                  <Grid
                                    ref={modalRef}
                                    style={{}}
                                    className={class_name.modal_style}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        className={class_name.modal_options}
                                        onClick={() => {
                                          setOpenModal(false);
                                          setModalOpen(false);
                                          setModalOpenIndex(null);
                                          setBackgroungImageIndex(index);
                                        }}
                                        onMouseEnter={(e) => {
                                          e.target.style.color = "blue";
                                        }}
                                        onMouseLeave={(e) => {
                                          e.target.style.color = "black";
                                        }}
                                      >
                                        Set as Cover
                                      </Typography>
                                      <Typography
                                        className={class_name.modal_options}
                                        onClick={() => {
                                          setOpenModal(false);
                                          setModalOpen(false);
                                          setModalOpenIndex(null);
                                          setProfileImgIndex(index);
                                        }}
                                        onMouseEnter={(e) => {
                                          e.target.style.color = "blue";
                                        }}
                                        onMouseLeave={(e) => {
                                          e.target.style.color = "black";
                                        }}
                                      >
                                        Set as Thumbnail
                                      </Typography>
                                    </div>
                                  </Grid>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </Tooltip>
                      );
                    })}
                  </>
                ) : null}
                {backgroungImageIndex === "API" &&
                dashboard?.single_boat_details?.background_image ? (
                  <div className={class_name.show_selected_imgs}>
                    <div
                      className={class_name.show_selected_imgs_div_have_cut}
                      onMouseEnter={() => setIsHoveredBackgroundImg(true)}
                      onMouseLeave={() => setIsHoveredBackgroundImg(false)}
                    >
                      {/* {console.log(
                        " dashboard?.single_boat_details",
                        dashboard?.single_boat_details
                      )} */}
                      <img
                        alt="background_image"
                        src={`${dashboard?.single_boat_details?.background_image}`} //${API.baseUrls[API.currentEnv]}
                        className={class_name.selected_imgs_style}
                        onClick={() =>
                          handleImageClick(
                            {
                              path: dashboard?.single_boat_details
                                ?.background_image,
                            },
                            null
                          )
                        }
                      />
                      <div className={class_name.selected_imgs_name_txt_style}>
                        <div
                          className={class_name.selected_imgs_name_inner_style}
                        >
                          <Typography
                            className={
                              class_name.selected_imgs_naming_style_txt
                            }
                          >
                            {"Background Image"}
                          </Typography>

                          <Typography
                            style={{
                              color: "#424651",
                            }}
                            className={
                              class_name.selected_imgs_naming_style_txt
                            }
                          >
                            {"Set as Cover"}
                          </Typography>
                        </div>
                        <div className={class_name.selected_imgs_cutter_styles}>
                          {isHoveredBackgroundImg ? (
                            <div>
                              <Clear
                                className={class_name.clear_icon}
                                onClick={() => {
                                  // removeImage(index);
                                  setBackgroungImageIndex(null);
                                }}
                              />
                            </div>
                          ) : null}

                          <MoreVert
                            className={class_name.morevert_icon}
                            onClick={() => {
                              setOpenModal(true);
                              setModalOpen(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {profileImgIndex === "API" &&
                dashboard?.single_boat_details?.front_image ? (
                  <div className={class_name.show_selected_imgs}>
                    <div
                      className={class_name.show_selected_imgs_div_have_cut}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <img
                        alt="front_image"
                        src={`${dashboard?.single_boat_details?.front_image}`} //${API.baseUrls[API.currentEnv]}
                        className={class_name.selected_imgs_style}
                        onClick={() =>
                          handleImageClick(
                            {
                              path: dashboard?.single_boat_details?.front_image,
                            },
                            null
                          )
                        }
                      />
                      <div className={class_name.selected_imgs_name_txt_style}>
                        <div
                          className={class_name.selected_imgs_name_inner_style}
                        >
                          <Typography
                            className={
                              class_name.selected_imgs_naming_style_txt
                            }
                          >
                            {"Profile Image"}
                          </Typography>

                          <Typography
                            style={{
                              color: "#424651",
                            }}
                            className={
                              class_name.selected_imgs_naming_style_txt
                            }
                          >
                            {"Set as Thumbnail"}
                          </Typography>
                        </div>
                        <div className={class_name.selected_imgs_cutter_styles}>
                          {isHovered ? (
                            <div>
                              <Clear
                                className={class_name.clear_icon}
                                onClick={() => {
                                  // removeImage(index);
                                  setProfileImgIndex(null);
                                }}
                              />
                            </div>
                          ) : null}

                          <MoreVert
                            className={class_name.morevert_icon}
                            onClick={() => {
                              setOpenModal(true);
                              setModalOpen(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {/* ========================================================== Boat Services List ==========================================================  */}
            <div className={class_name.boat_service_container}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                ref={BoatServiceRef}
              >
                <Typography className={class_name.boat_service_title_txt}>
                  Boat Services
                </Typography>

                {boatServiceError && errTrue ? (
                  <>
                    {selectedBoatServices?.length > 0 ||
                    (showCustomService &&
                      customServiceValues?.length > 0 &&
                      customServiceValues[0]?.label !== "") ? null : ( // customServiceValues[0]?.label // ||
                      <Typography className={class_name.err_msg_style}>
                        Please select Services
                      </Typography>
                    )}
                  </>
                ) : null}
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {boatServices?.map((boatService, index) => (
                  <div
                    className={class_name.checkbox_div}
                    key={index}
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   marginRight: "2%",
                    //   marginBottom: "1%",
                    //   width: "40%",
                    //   whiteSpace: 'nowrap'
                    // }}
                  >
                    {/* <div className="d-flex"> */}
                    <CustomCheckbox
                      style={{ marginRight: "3%", padding: "0" }}
                      checked={selectedBoatServices?.some(
                        (selectedService) =>
                          selectedService?.label === boatService?.label
                      )}
                      onChange={() => {
                        handleBoatServiceToggle(boatService);
                        // handleHistory()
                      }}
                      icon={<span className={class_name.checked_box} />}
                      checkedIcon={
                        <span className={class_name.checked_box}>✓</span>
                      }
                    />
                    <Typography className={class_name.boat_services_txt}>
                      {boatService.label}
                    </Typography>
                    {/* </div> */}
                  </div>
                ))}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // flexWrap: "wrap",
                  // width: "100%",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    // width: "31%",
                    // width: '100%',
                    alignSelf: "center",
                    alignItems: "center",
                    marginRight: "2%",
                    marginBottom: "1%",
                  }}
                >
                  <CustomCheckbox
                    style={{ marginRight: "3%", padding: 0 }}
                    checked={showCustomService}
                    onChange={handleCustomServiceToggle}
                    icon={<span className={class_name.checked_box} />}
                    checkedIcon={
                      <span className={class_name.checked_box}>✓</span>
                    }
                  />
                  <Typography className={class_name.boat_services_txt}>
                    Other
                  </Typography>
                </div>
                {showCustomService ? (
                  <CustomTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="enterNewBoatService"
                    name="enterNewBoatService"
                    placeholder="Enter new boat service"
                    value={addNewBoatServiceValue}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      if (
                        nameValidationPattern.test(inputValue) &&
                        inputValue?.length <= 11
                      ) {
                        setAddNewBoatServiceValue(inputValue);
                      } else if (inputValue === "") {
                        setAddNewBoatServiceValue("");
                      }
                    }}
                    InputProps={{
                      style: {
                        ...textFieldStyles,
                        width: width <= 480 ? "160%" : "100%",
                        border:
                          formik.touched.boatType &&
                          Boolean(formik.errors.boatType)
                            ? "solid 1px red"
                            : "solid 1px rgba(66, 70, 81, 0.3)",
                      },
                      endAdornment: (
                        <InputAdornment
                          style={{
                            width: "10%",
                            display: "flex",
                            justifyContent: "center",
                            marginLeft: "5%",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              toast.dismiss();
                              addNewBoatServiceValue === ""
                                ? toast.error("Please enter boat service", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 20000,
                                  })
                                : add_new_boat_service();
                            }}
                          >
                            {addBoatServiceLoader ? (
                              <CircularProgress size={24} thickness={4} />
                            ) : (
                              <Typography
                                style={{
                                  fontSize: "clamp(6px, 3vw, 12px)",
                                  color: "#3973a5",
                                }}
                              >
                                ADD
                              </Typography>
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      className: class_name.input_txt_style,
                    }}
                    className={class_name.addServiceTextBoxWidth}
                  />
                ) : null}
              </div>
            </div>

            {/* ========================================================== map ==========================================================  */}
            <div className={class_name.boat_service_container}>
              <Grid item xs={12}>
                <Typography className={class_name.boat_service_title_txt}>
                  Boat Location
                </Typography>
                <div style={{}} className={class_name.boatLocBox_div}>
                  {/* Marine Name */}
                  <div className="d-flex align-items-center flex-column flex-md-row gap-md-3 w-100 ">
                    {/* <div
                      item
                      xs={12}
                      sm={6}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        // marginRight: "5%",
                      }}
                    >
                      <InputLabel
                        htmlFor="Marine_Name"
                        className={class_name.labelStyle}
                      >
                        Marine Name
                      </InputLabel>
                      <CustomTextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="Marine_Name"
                        name="Marine_Name"
                        placeholder="Marine Name"
                        value={formik.values.Marine_Name}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          if (nameValidationPattern.test(inputValue)) {
                            formik.setFieldValue("Marine_Name", inputValue);
                            handleHistory(inputValue, "Marine_Name");
                          } else if (inputValue === "") {
                            formik.setFieldValue("Marine_Name", "");
                          }
                        }}
                        error={
                          formik.touched.Marine_Name &&
                          Boolean(formik.errors.Marine_Name)
                        }
                        helperText={
                          formik.touched.Marine_Name &&
                          formik.errors.Marine_Name
                        }
                        InputProps={{
                          style: {
                            ...textFieldStyles,
                            borderRadius: width >= 480 ? "15px" : "8px",
                            border:
                              formik.touched.Marine_Name &&
                              Boolean(formik.errors.Marine_Name)
                                ? "solid 1px red"
                                : "solid 1px rgba(66, 70, 81, 0.3)",
                          },
                        }}
                        // inputProps={{
                        //   className: class_name.input_txt_style,
                        // }}
                      />
                    </div> */}
                    <div
                      item
                      xs={12}
                      sm={6}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <InputLabel
                        htmlFor="boatType"
                        className={class_name.labelStyle}
                      >
                        Marine City
                      </InputLabel>
                      <CustomTextField
                        // label={
                        //   formik.values.Marine_city === "" ? (
                        //     <span style={{ color: "rgba(223,224,225,1)" }}>
                        //       City
                        //     </span>
                        //   ) : null
                        // }
                        select
                        // className={class_name.select}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="city"
                        name="city"
                        value={formik.values.Marine_city}
                        style={{ border: "none", marginTop: "0px" }}
                        SelectProps={{
                          displayEmpty: true,
                          MenuProps: {
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            // anchorPosition:"bottom",
                            getContentAnchorEl: null,
                          },
                        }}
                        // onChange={() => {
                        //   // formik.handleChange(e.target.value);
                        //   formik.setFieldValue("Marine_city",selectedCity)
                        // }}
                        error={
                          formik.touched.Marine_city &&
                          Boolean(formik.errors.Marine_city)
                        }
                        helperText={
                          formik.touched.Marine_city &&
                          formik.errors.Marine_city
                        }
                        InputProps={{
                          className: class_name.input_txt_style,
                          style: {
                            ...textFieldStyles,
                            color: formik.values.Marine_city
                              ? "#212121"
                              : "#bcbcbc",
                            backgroundColor: "white",
                            borderRadius: width >= 480 ? "15px" : "8px",
                            border:
                              formik.touched.Marine_city &&
                              Boolean(formik.errors.Marine_city)
                                ? "solid 1px red"
                                : "solid 1px rgba(66, 70, 81, 0.3)",
                          },
                        }}
                        InputLabelProps={{
                          shrink: false,
                          style: {
                            // top: width < 767 ? "-25%" : "-5%",
                            fontSize: "clamp(8px, 2vw, 16px)",
                            color: "#757575",
                            borderRadius: width >= 480 ? "15px" : "8px",
                            // height: width > 992? '10px': width> 768? "6px": width > 480? "0px": "-20px",
                            // translate:
                            //   width > 992
                            //     ? "0px 25%"
                            //     : width > 768
                            //     ? "0px 20%"
                            //     : width > 480
                            //     ? "0px -10%"
                            //     : "0px 80%",
                            display: "flex",
                            alignItems: "center",
                            // className: class_name.input_label_boatType
                          },
                        }}
                      >
                        <MenuItem disabled value="">
                          City
                        </MenuItem>
                        {cityList?.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item?.marine_city}
                            selected={
                              formik.values.Marine_city === item?.marine_city
                            }
                            onClick={() => handleCityChange(item?.city_id)}
                            style={{
                              width: "100%",
                              height: "auto",
                              textAlign: "left",
                              alignItems: "flex-start",
                              padding: "5px 10px",
                              display: "flex",
                              flexDirection: "column",
                              fontSize: "clamp(16px, 3vw, 15px)",
                              color: "#424651",
                              overflowX: "hidden",
                              overflowY: "auto",
                            }}
                          >
                            {item?.marine_city}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                      {/* <CustomTextField
                        // label={"City"}
                        margin="normal"
                        fullWidth
                        id="City"
                        name="City"
                        placeholder="Select city"
                        value={selectedCity || formik.values.Marine_city}
                        onClick={() => setShowModalForCityNames(true)}
                        onChange={(event) => {
                          formik.setFieldValue("Marine_city",selectedCity)
                        }}
                        error={
                          formik.touched.Marine_city &&
                          Boolean(formik.errors.Marine_city)
                        }
                        helperText={
                          selectedCity === ""
                            ? formik.touched.Marine_city &&
                              formik.errors.Marine_city
                            : null
                        }
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          className: class_name.labelStyle,
                        }}
                        inputProps={{
                          ...textFieldStyles,
                          style: {
                            border:
                              formik.touched.Marine_city &&
                              Boolean(formik.errors.Marine_city) &&
                              selectedCity === ""
                                ? "solid 1px red"
                                : "solid 1px rgba(66, 70, 81, 0.3)",
                            // borderRadius: "10px",
                            padding: '18.5px 14px',
                          },
                          className: class_name.input_city_style,
                        }}
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                          style: {
                            // marginTop: "30px",
                            // padding: '18.5px 14px',
                          },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                right: 0,
                              }}
                              onClick={() => {
                                setShowModalForCityNames(true);
                              }}
                            >
                              <IconButton>
                                <img
                                  alt="down"
                                  src={IMAGES.DOWN}
                                  style={{ width: "15px", height: "15px" }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      /> */}
                      {/* <Dialog
                        open={showModalForCityNames}
                        onClose={() => {
                          setShowModalForCityNames(false);
                        }}
                        scroll="paper"
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <DialogTitle>Select City</DialogTitle>
                          <IconButton
                            onClick={() => {
                              setShowModalForCityNames(false);
                            }}
                            style={{
                              alignContent: "center",
                              alignItems: "center",
                              alignSelf: "center",
                            }}
                          >
                            <Cancel />
                          </IconButton>
                        </div>
                        <DialogContent
                          dividers
                          style={{ minHeight: "auto", minWidth: "100%" }}
                        >
                          {cityList.map((city) => (
                            <MenuItem
                              key={city?.city_id}
                              value={city?.city}
                              style={{ width: "100%" }}
                              onClick={() => {
                                handleCityChange(city.city_id);
                              }}
                            >
                              <Typography>{city.city} </Typography>
                            </MenuItem>
                          ))}
                        </DialogContent>
                      </Dialog> */}
                    </div>
                  </div>

                  {/* Marine Address */}
                  <div
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      // marginRight: "5%",
                    }}
                  >
                    <InputLabel
                      htmlFor="boatType"
                      className={class_name.labelStyle}
                    >
                      Marine Address
                    </InputLabel>
                    <ReactGoogleMapLoader
                      params={{
                        key: "AIzaSyCWUx1QA5ZML7Ymb3ChcybKP34YcYkcr7c",
                        libraries: "places,geometry",
                      }}
                      render={(googleMaps) =>
                        googleMaps && (
                          <div>
                            <ReactGooglePlacesSuggest
                              displayPoweredByGoogle={false}
                              autocompletionRequest={{
                                input: searchValue,
                                // types:['restaurant']
                              }}
                              googleMaps={googleMaps}
                              customRender={(prediction) => {
                                return (
                                  <>
                                    {prediction ? (
                                      <div
                                        key={prediction.id}
                                        style={{ padding: "10px" }}
                                        onClick={() => {
                                          formik.setFieldValue(
                                            "Marine_Address",
                                            prediction.description
                                          );
                                          handleGetLatandLng(
                                            prediction.description
                                          );
                                        }}
                                      >
                                        {prediction.description}
                                      </div>
                                    ) : null}
                                  </>
                                );
                              }}
                            >
                              <CustomTextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="Marine_Address"
                                name="Marine_Address"
                                placeholder="Enter an address"
                                multiline={true}
                                value={formik.values.Marine_Address}
                                onChange={(e) => {
                                  console.log("e.target.value", e.target.value);
                                  let inputValue = e.target.value;
                                  formik.setFieldValue(
                                    "Marine_Address",
                                    inputValue
                                  );
                                  setSearchValue(inputValue);
                                  // setSearchAddress(inputValue)
                                }}
                                error={
                                  formik.touched.Marine_Address &&
                                  Boolean(formik.errors.Marine_Address) //formik.errors.Marine_Address
                                }
                                helperText={
                                  !formik.values.Marine_Address
                                    ? formik.touched.Marine_Address &&
                                      formik.errors.Marine_Address
                                    : // formik.errors.Marine_Address
                                      null
                                }
                                InputProps={{
                                  className: class_name.input_txt_style,
                                  style: {
                                    ...textFieldStyles,
                                    borderRadius: width >= 480 ? "15px" : "8px",
                                    border:
                                      formik.touched.Marine_Address &&
                                      Boolean(formik.errors.Marine_Address) &&
                                      !formik.values.Marine_Address
                                        ? "solid 1px red"
                                        : "solid 1px rgba(66, 70, 81, 0.3)",
                                    padding: "18.5px 14px",
                                  },
                                }}
                              />
                            </ReactGooglePlacesSuggest>
                          </div>
                        )
                      }
                    />
                  </div>
                </div>
                <div>
                  {loading ? <Loader loading={loading} /> : null}
                  <Map
                    // markers={markers}
                    selectedMarker={selectedMarker}
                    onSelectMarker={handleSelectMarker}
                  />
                </div>

                {!selectedMarker && mapLocError && errTrue ? (
                  <Typography className={class_name.err_msg_style}>
                    Please select Location
                  </Typography>
                ) : null}
              </Grid>
            </div>

            {/* ========================================================== Button ==========================================================  */}
            <div className={class_name.btnDiv}>
              <div
                onClick={() => {
                  // handleSubmit();
                  SetErrorMsg();
                }}
                className={class_name?.save_and_continue_btn}
              >
                <Typography
                  className={`${class_name.save_and_continue_txt} hoverEffect`}
                >
                  Next
                </Typography>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="footer-style-hide">
        <Footer />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  selected_imgs_name_txt_style: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  selected_imgs_name_inner_style: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  selected_imgs_cutter_styles: {
    flex: 0.3,
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "center",
    alignItems: "center",
  },

  page_body_container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f6f6f6",
    // paddingBottom: "100px",
    paddingBottom: "3%",
    // overflowY: "scroll",
    // WebkitOverflowScrolling: "touch",
  },

  add_new_type_input: {
    [theme.breakpoints.down("480")]: {
      position: "relative",
      left: "-108.5%",
      width: "208.5%",
    },
  },

  input_city_style: {
    borderRadius: "10px",
    [theme.breakpoints.down("768")]: {
      borderRadius: "5px",
    },
  },

  form_container_box: {
    backgroundColor: "#fff",
    alignSelf: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    border: "solid 0.5px rgba(66, 70, 81, 0.1)",
    paddingLeft: "5%",
    paddingRight: "5%",
    borderRadius: "2px",
    width: "90%",
    marginTop: "15px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "30px",
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "40px",
      width: "70%",
    },
  },

  submit_form_container: {
    marginTop: "5.8%",
  },
  Show_off_your_boat_in_few_clicks: {
    marginTop: "1.1%",
    fontSize: "clamp(22.5px, 5vw, 45px)",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.51,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#424651",
  },

  step_2_txt: {
    fontSize: "clamp(14px, 5vw, 35px)",
    fontWeight: "bolder",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.51,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    padding: "20px 0px",
  },

  row_div_contain_two_box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "2.3%",
  },

  input_txt_style: {
    borderRadius: "15px",
    // color: 'red',
    // cursor: "pointer",
    // textAlign: 'center',
    [theme.breakpoints.down("480")]: {
      // padding: '13% 5%'
    },
  },

  icon_btn: {
    padding: "0",
  },

  labelStyle: {
    paddingLeft: "0px",
    fontSize: "clamp(12px, 1.1vw, 22px)",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "16px",
    },
  },

  txt_filed_container_box: {
    display: "flex",
    flexDirection: "column",
    width: "48%",
  },

  upload_imges_txt: {
    fontSize: "clamp(12px, 3vw, 24px)",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.51,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    marginTop: "10%",
    marginBottom: "2%",
  },

  select_img_box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    // width: "100%",
    // height: "100%",
    padding: "3% 5%",
    borderRadius: "20px",
    // alignContent: "center",
    // alignSelf: "center",
    marginTop: ".7%",
    // backgroundColor: "RED",
  },

  cloud_img: {
    width: "clamp(52px, 10vw, 104px)", // Adjust the range as needed
    height: "clamp(35px, 10vh, 70px)", // Adjust the range as needed
  },

  Drop_img_txt: {
    marginTop: "1%",
    fontSize: "clamp(10px, 2.5vw, 18px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#424651",
  },
  Choose_txt: {
    fontSize: "clamp(10px, 2.5vw, 18px)",
    fontWeight: "lighter",
    color: "#f6f6f6",
    backgroundColor: "#3973a5",
    padding: "1% 4%",
    borderRadius: "10px",
    marginTop: "10px",
  },
  select_type_info: {
    marginTop: "1%",
    fontSize: "clamp(8px, 2vw, 12px)", // Adjust the range as needed
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.3)",
  },
  boat_service_container: {
    marginTop: "10%",
    // backgroundColor: "red",
  },
  boat_service_title_txt: {
    fontSize: "clamp(16px, 3vw, 24px)",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.51,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    marginBottom: "2%",
  },
  boat_services_txt: {
    fontSize: "clamp(14px, 2.5vw, 18px)",
    textAlign: "center",
    color: "#424651",
    whiteSpace: "nowrap",
  },

  btnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "14% 0px",
  },

  save_and_continue_btn: {
    cursor: "pointer",
    borderRadius: "15px",
    border: "solid 1px #026b93",
    padding: "2% 10%",
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    transition: "background-color 0.3s",
    backgroundColor: "#026b93", // Add a smooth transition for the background color
    "&:hover": {
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
      // Change the background color on hover
      "& $save_and_continue_txt": {},
    },
  },

  save_and_continue_txt: {
    fontSize: "clamp(8px, 2vw, 24px)", // Adjust the range as needed
    fontWeight: "600",
    textAlign: "center",
    color: "white",
  },

  modal_options: {
    fontSize: "clamp(14px, 2vw, 12px)", // Adjust the range as needed
    fontWeight: "400",
    cursor: "pointer",
    textAlign: "left",
    color: "rgba(21, 28, 48, 0.87)",
    whiteSpace: "nowrap",
  },

  err_msg_style: {
    fontSize: "clamp(10px, 1vw, 20px)",
    color: "red",
    textAlign: "center",
  },

  checked_box: {
    width: "20px",
    height: "20px",
    borderRadius: "1px",
    border: "1px solid rgba(66, 70, 81, 0.4)",
    fontSize: "clamp(14px, 1.5vw, 22px)",
    color: "#424651",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",

    [theme.breakpoints.up("sm")]: {
      width: "20px",
      height: "20px",
    },
    [theme.breakpoints.up("md")]: {
      width: "18px",
      height: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "22px",
      height: "22px",
    },
  },

  show_selected_imgs: {
    width: "45%",
    // width: "100%",
    justifyContent: "space-evenly",
    margin: "2%",
  },
  show_selected_imgs_div_have_cut: {
    display: "flex",
    // padding: "15px",
    padding: "5%",
    borderStyle: "solid",
    borderRadius: "10px",
    justifyContent: "center",
    borderColor: "lightgray",
    borderWidth: "1px",
    alignItems: "center",
  },
  selected_imgs_style: {
    // width: "70px",
    // height: "60px",
    width: "clamp(15px, 20vw, 70px)", // Adjust the range as needed
    height: "clamp(10px, 20vw, 60px)", // Adjust the range as needed
    // backgroundColor: "rgba(66, 70, 81, 0.3)",
    backgroundSize: "contain",
    borderStyle: "solid",
    borderRadius: "10px",
    borderColor: "gray",
    borderWidth: ".5px",
  },

  selected_imgs_naming_style_txt: {
    fontSize: "clamp(12px, 1vw, 24px)", // Adjust the range as needed
    fontWeight: "bold",
    width: "90%",
    textAlign: "left",
    color: "rgba(21, 28, 48, 0.87)",
    marginLeft: "10px",
    wordBreak: "break-word",
    // textOverflow: 'elipsis',
    // overflow: 'hidden',
    // whiteSpace: 'nowrap'
    // maxInlineSize: 1,
    // maxLines: 1,
  },

  modal_style: {
    width: "15%",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    border: "1px solid whitesmoke",
    borderRadius: "10px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    padding: "10px 10px",
    backgroundColor: "white",
    justifyContent: "center",
    zIndex: "999",
    [theme.breakpoints.down("480")]: {
      // padding: '10px 5px'
    },
  },

  nxt_txt: {
    fontSize: "clamp(10px, 5vw, 26px)",
    color: "#f6f6f6",
  },

  addServiceTextBoxWidth: {
    width: "100%",
    [theme.breakpoints.down("480")]: {
      width: "160%",
    },
  },

  boatLocBox_div: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
    width: "100%",
  },

  clear_icon: {
    cursor: "pointer",
    fontSize: "clamp(8px, 5vw, 30px)",
  },
  morevert_icon: {
    cursor: "pointer",
    fontSize: "clamp(8px, 5vw, 30px)",
  },
  checkbox_div: {
    display: "flex",
    alignItems: "center",
    marginRight: "2%",
    marginBottom: "1%",
    width: "31%",
    // whiteSpace: "nowrap",
    [theme.breakpoints.down("520")]: {
      width: "40%",
    },
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    page_body_container: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#f6f6f6",
      // paddingBottom: "100px",
      paddingBottom: "10%",
      //   overflowY: "scroll",
      //   WebkitOverflowScrolling: "touch",
    },
    show_selected_imgs: {
      width: "100%",
      justifyContent: "space-evenly",
      alignItems: "center",
      alignContent: "center",
      // justifyContent: 'center',
      margin: "2% 0",
    },

    modal_style: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      border: "1px solid whitesmoke",
      borderRadius: "10px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
      padding: "20px 10px",
      backgroundColor: "white",
      justifyContent: "center",
    },

    cloud_img: {
      width: "clamp(40px, 10vw, 52px)", // Adjust the range as needed
      height: "clamp(18px, 9vh, 32px)", // Adjust the range as needed
    },

    form_container_box: {
      backgroundColor: "#fff",
      alignSelf: "center",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      paddingLeft: "5%",
      paddingRight: "5%",
      borderRadius: "15px",
      width: "90%",
      marginTop: "15px",
      border: "solid 1px rgba(66, 70, 81, 0.1)",
      [theme.breakpoints.up("sm")]: {
        marginTop: "30px",
        width: "90%",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "30px",
        width: "80%",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "40px",
        width: "70%",
      },
    },

    save_and_continue_btn: {
      borderRadius: "0px",
      position: "fixed",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      // backgroundColor: "rgba(151, 151, 151, 1)",
      border: "solid 1px rgba(151, 151, 151, 1)",
      padding: "0px",
      width: "100%",
      height: "56px",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },

    save_and_continue_txt: {
      fontSize: "clamp(16px, 5vw, 20px)", // Adjust the range as needed
      fontWeight: "400",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.96,
      letterSpacing: "normal",
      textAlign: "center",
      color: "white",
    },

    addServiceTextBoxWidth: {
      width: "50%",
    },

    boatLocBox_div: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  },
  "@media (max-width: 480px)": {
    morevert_icon: {
      fontSize: "clamp(10px, 8vw, 80px)",
    },
    clear_icon: {
      fontSize: "clamp(10px, 8vw, 80px)",
    },
  },
}));

const textFieldStyles = {
  // borderRadius: "10px",
  borderWidth: ".1px",
  borderColor: "rgba(66, 70, 81, 0.2)",
};
