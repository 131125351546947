import { Dialog, TextField } from '@mui/material'
import moment from 'moment';
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { popup_page } from '../../../redux/slices';
import { toast } from 'react-toastify';

const PopupPage = ({open,handleClosePopup,handleStatus,title}) => {
    const spaceRegex = new RegExp(/^(?!\s).*/);
    const dashboard = useSelector((state) => state?.dashboard);
    const [reason, setReason] = useState(null)

   const handleAccept = (status) =>{
        console.log(status,'popup')
        if(status === "REJECT"){
            if(!reason){
                toast.error('Reason is required',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000
                  })
            } else {
                handleStatus({button:'accept', status:status, reason:reason, user_id:dashboard?.popup_page?.user_id})
            }
        } else {
            handleStatus({button: 'accept', status: status, reason:reason, user_id:dashboard?.popup_page?.user_id})
        }
        
    }
    const handleDecline = () =>{
        handleStatus({button:'decline'})
    }

  return (
    <div>
        <Dialog
        open={open}
        onClose={handleClosePopup}
        scroll="paper"
        >
            <div 
            className='p-5 d-flex flex-column text-center gap-3'
            style={{
                fontSize: 'clamp(14px,1.8vw,24px)'
            }} 
            >
                <div className='fw-bold'>
                {/* {`Are you sure you want to ${dashboard?.popup_page?.ride_mode} ${dashboard?.popup_page?.ride_start_time? 'your ride':''}`} */}
                    <span>{title}</span>
                </div>
                {
                    dashboard?.popup_page?.ride_mode === "REJECT"?
                    <TextField
                    sx={{
                        "&.MuiInputBase-input":{
                            paddding: '10px 10px'
                        }
                    }}
                    value={reason}
                    placeholder='Reason for Reject'
                    onChange={(e)=>{
                        if(spaceRegex?.test(e.target.value)){
                            setReason(e.target.value);
                        }
                    }
                    }
                    />
                    :null
                }
                {dashboard?.popup_page?.ride_start_time?
                <div className='d-flex flex-column'>
                    <span>{`Start Time ${moment(dashboard?.popup_page?.ride_start_time,'HH:mm:ss').format('hh:mm A')}`}</span>
                    <span>{`End Time ${moment(dashboard?.popup_page?.ride_end_time,'HH:mm:ss').format('hh:mm A')}`}</span>
                    <span>{`Riding Date ${dashboard?.popup_page?.ride_date}`}</span>
                </div>:null
                }
                <div className='d-flex gap-3 text-white'>
                    <button 
                    className='btn bg-danger fw-semibold'
                    onClick={()=>handleDecline()}
                    >
                        Cancel
                    </button>
                    <button 
                    className='btn bg-success fw-semibold'
                    onClick={()=>handleAccept(dashboard?.popup_page?.ride_mode)}
                    >
                        Accept
                    </button>
                </div>
            </div>
        </Dialog>
    </div>
  )
}

export default PopupPage