import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, MenuItem, TextField, Typography } from "@mui/material";
import IMAGES from "../../../Images";
// import { HeaderContent } from "../../../Common/map/HeaderContent";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../../../Component/Footer/Footer";
import { PageHeader } from "../../../Dash/page-header/PageHeader";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { useState } from "react";
import { booking_status_detail_API, cancel_trip, end_trip, start_trip, user_bookedBoat_list_API } from "../../../../Service/api";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Loader from "../../../Loader";
import PopupPage from "../../../Common/PopupPage/PopupPage";
import { popup_page } from "../../../../redux/slices";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useWindowDimensions from "../../../../UI kit/useWindowDimensions";
import socket from "../../../../Service/SocketIo";

const styles = (theme) =>({
  root: {
    //outer
    marginTop: "0px",
    borderRadius: "8px",
    // paddingTop: "3px",

    // Styles for the input text
    "& .MuiInputBase-input": {
      fontSize: "clamp(12px, 0.9vw, 20px)",
      // textAlign: "left",
      color: "#424651",
      borderBottom: "none",
      // padding: '7%',
      // paddingLeft: '9%',
      // [theme.breakpoints.down("992")]: {
      //   padding: '2.7%',
      //   paddingLeft: '4.7%',
      // },
      // [theme.breakpoints.down("480")]: {
      //   padding: '4.2%',
      //   paddingLeft: '6.2%',
      // },
    },
    // "& .MuiInputBase-readOnly": {
    //   border: 'none'
    // },

    // Styles for the placeholder text
    "& .MuiFormLabel-root": {
      borderBottom: "none",
      border: "none",
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      borderBottom: "none",
      fontSize: "clamp(12px, 0.9vw, 20px)",
    },

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
      fontSize: "clamp(12px, 0.9vw, 20px)",
      border: "none",
    },

    "& .MuiFormLabel-filled": {
      transform: 'translateY(-1rem)',
    },

    // "& .MuiInputLabel-root": {
    //   height: '45%',
    //   display: 'flex',
    //   alignItems: 'center',
    //   [theme.breakpoints.down('992')]: {
    //     height: '50%',
    //   },
    //   [theme.breakpoints.down('480')]: {
    //     height: '40%',
    //   },
    // },
    "& .MuiSelect-select": {
      padding: '15px',
      [theme.breakpoints.down("992")]: {
        padding: '15px'
      },
      [theme.breakpoints.down("480")]: {
        padding: '15px',
      },
    },
    // "& .MuiInputLabel-shrink": {
    //   margin: "0 auto",
    //   position: "absolute",
    //   right: "0",
    //   left: "0",
    //   top: "-3px",
    //   width: "150px", // Need to give it a width so the positioning will work
    //   background: "white" // Add a white background as below we remove the legend that had the background so text is not meshing with the border
    //   // display: "none" //if you want to hide it completly
    // },

    // Apply styles for small screens
    [theme.breakpoints.down("sm")]: {
      "& .MuiInputBase-input": {},
      // "& .MuiFormLabel-root": {
      //   borderBottom: "none",
      //   fontSize: "12px",
      // },
      // borderRadius: "8px",
    },

    // Adjust for medium screens (md)
    [theme.breakpoints.up("md")]: {
      "& .MuiInputBase-input": {},
      // borderRadius: "15px",
    },

    // Adjust for large screens (lg)
    [theme.breakpoints.up("lg")]: {
      "& .MuiInputBase-input": {},
      // borderRadius: "15px",  
    },
  },
})

const CustomTextField = withStyles(styles)(TextField);

export const RequestList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const dashboard = useSelector((state) => state?.dashboard);
  const { width } = useWindowDimensions();

  const class_name = useStyles({ min: 10, max: 60, unit: "px" });
  const [isLoading, setIsLoading] = useState(false);
  const [userBookedBoatList, setUserBookedBoatList] = useState([]);
  const [startTripArr, setStartTripArr] = useState([])
  const [openPopup, setOpenPopup] = useState(false)
  const [userAcc, setUserAcc] = useState([])
  const [selectStartDate, setSelectStartDate] = useState('')
  const [selectEndDate, setSelectEndDate] = useState('')
  const [selectPaymentStatus, setSelectPaymentStatus] = useState('')
  const [selectBookingStatus, setSelectBookingStatus] = useState('')
  const [boatId, setBoatId] = useState(null)
  const [bookingStatus, setBookingStatus] = useState([])

  useEffect(() => {
    if(
      !selectStartDate && !selectEndDate &&
      !selectPaymentStatus && !selectBookingStatus
      ){
          setIsLoading(true);
          setBoatId(location?.state?.boat_id)
          let payload = {
            boat_id: location?.state?.boat_id,
            // boat_booking_id: location?.state?.boat_booking_id,
          };
          console.log(payload,'payload')
        user_bookedBoat_list_API(auth?.AuthToken, payload)
          .then((res) => {
            console.log(res?.data);
            if (res?.data?.success) {
              setUserBookedBoatList(res?.data?.bookings);
              // setUserAcc(res?.data?.parameters?.userAccounts)
            } else {
              toast.error(res?.data?.message ?? res?.data?.error, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("User booked boat list error: ", err);
            toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setIsLoading(false);
          });
    }
  }, [
    auth?.AuthToken,
    selectStartDate,
    selectEndDate,
    selectPaymentStatus,
    selectBookingStatus
  ]);

  useEffect(()=>{
    booking_status_detail_API()
    .then((res)=>{
      console.log(res?.data,'booking status detail')
      setBookingStatus(res?.data)
    })
    .catch((err)=>{
      console.log(err,'booking status detail')
    })
  },[])

  // useEffect(()=>{
  //   if(
  //     !selectStartDate && 
  //     !selectEndDate &&
  //     !selectBookingStatus &&
  //     !selectBookingStatus
  //     ){
  //       setIsLoading(true)
  //       let payload = {
  //           boat_id: boatId,
  //       }
  //       user_bookedBoat_list_API(auth?.AuthToken, payload)
  //         .then((res) => {
  //           console.log(res?.data);
  //           if (res?.data?.success) {
  //             setUserBookedBoatList(res?.data?.bookings);
  //           } else {
  //             toast.error(res?.data?.error ?? res?.data?.error, {
  //               position: toast.POSITION.TOP_RIGHT,
  //               autoClose: 2000,
  //             });
  //           }
  //           setIsLoading(false);
  //         })
  //         .catch((err) => {
  //           console.log("User booked boat list error: ", err);
  //           toast.error("Something went wrong. Please try again later.", {
  //             position: toast.POSITION.TOP_RIGHT,
  //             autoClose: 2000,
  //           });
  //           setIsLoading(false);
  //         });
  //     }
  // },[
  //   selectStartDate,
  //   selectEndDate,
  //   selectBookingStatus,
  //   selectPaymentStatus
  // ])

  const handle_navigation = (name) => {
    // if (name === "chat") {
    //   navigate("/allMessage");
    // }
    if (name === "Home") {
      if (auth?.tokenDecodeData?.user_type === "BOAT_OWNER") {
        navigate("/boatOwnerDashBoard");
      } else {
        navigate("/rental");
      }
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleStartTrip = (boat_booking_id,user_id) =>{
    let payload = {
      boat_booking_id: boat_booking_id
    }
      start_trip(auth?.AuthToken,payload)
      .then((res)=>{
        console.log(res?.data,'start trip')
        if(res?.data?.success){
          toast.success(res?.data?.message,{
            position: toast?.POSITION?.TOP_RIGHT,
            autoClose: 5000
          })
          let updateBookedBoatList = userBookedBoatList?.map((item)=>{
            if(item?.booking?.boat_booking_id === boat_booking_id){
              return {...item,booking: {...item?.booking, trip_status:'TRIP_STARTED'}}
            }
            return item
          })
          setUserBookedBoatList(updateBookedBoatList)
          socket.client.emit("getUnreadCountNotification", user_id, (err) => {
            console.log("getunread count notification err", err);
          });
        }
        setOpenPopup(false)
        dispatch(popup_page(null))
      })
      .catch((err)=>{
        console.log('start trip error',err?.response)
        if(err?.response?.status === 400){
          toast.error(err?.response?.data?.message,{
            position: toast?.POSITION?.TOP_RIGHT,
            autoClose: 5000
          })
        }
        setOpenPopup(false)
        dispatch(popup_page(null))
      })

  }

  const handleEndTrip = (boat_booking_id, user_id) =>{
    let payload = {
      boat_booking_id: boat_booking_id
    }
    end_trip(auth?.AuthToken,payload)
    .then((res)=>{
      console.log(res?.data,'end trip')
      if(res?.data?.success){
        toast.success(res?.data?.message,{
          position: toast?.POSITION?.TOP_RIGHT,
          autoClose: 5000
        })
        let updateBookedBoatList = userBookedBoatList?.map((item)=>{
          if(item?.booking?.boat_booking_id === boat_booking_id){
            return {...item,booking: {...item?.booking, trip_status:'TRIP_END'}}
          }
          return item
        })
        setUserBookedBoatList(updateBookedBoatList)
        socket.client.emit("getUnreadCountNotification", user_id, (err) => {
          console.log("getunread count notification err", err);
        });
      }
      setOpenPopup(false)
      dispatch(popup_page(null))
    })
    .catch((err)=>{
      console.log('start end error',err)
      toast.error(err?.response?.data?.message,{
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 5000
      })
      setOpenPopup(false)
      dispatch(popup_page(null))
    })
  }

  const handleCancelTrip = (boat_booking_id, user_id) =>{
    let payload = {
      boat_booking_id: boat_booking_id,
      reason:'reason'
    }
    cancel_trip(auth?.AuthToken,payload)
    .then((res)=>{
      console.log(res?.data,'cancel trip')
      toast.success(res?.data.message)
      if(res?.data?.success){
        let updateBookedBoatList = userBookedBoatList?.map((item,i)=>{
          if(item?.booking?.boat_booking_id === boat_booking_id){
            return {...item,booking: {...item?.booking, trip_status:'TRIP_CANCEL'}}
          }
          return item
        })
        setUserBookedBoatList(updateBookedBoatList)
        socket.client.emit("getUnreadCountNotification", user_id, (err) => {
          console.log("getunread count notification err", err);
        });
      }
      setOpenPopup(false)
      dispatch(popup_page(null))
    })
    .catch((err)=>{
      console.log('start trip error',err)
      toast.error(err?.response?.data?.error,{
        position: toast?.POSITION?.TOP_RIGHT,
        autoClose: 5000
      })
      setOpenPopup(false)
      dispatch(popup_page(null))
    })
  }

  const handleClosePopup = () =>{
    setOpenPopup(false)
    dispatch(popup_page(null))
  }

  const handleOpenPopup = (trip_status,start_time,end_time,booking_date,booking_id,user_id) =>{
    console.log(trip_status,'trip status')
    if(
      trip_status === "TRIP_CANCEL" ||
      trip_status === "TRIP_END" ||
      trip_status === "PENDING"
      ){
        setOpenPopup(false)
      } else {
        setOpenPopup(true)
    dispatch(
      popup_page({
        ride_mode: trip_status === 'TRIP_INITIATED'?
        'start':
        trip_status === 'TRIP_STARTED'?
        'end': 'cancel',
        ride_start_time: start_time,
        ride_end_time: end_time,
        ride_date: booking_date,
        booking_id: booking_id,
        user_id: user_id
      })
    )
      }
  }

  const handleCancelOpenPopup = (trip_status,start_time,end_time,booking_date,booking_id,user_id) =>{
    if(
      trip_status === "TRIP_CANCEL" ||
      trip_status === "TRIP_END"
      // trip_status === "PENDING"
      ){
        setOpenPopup(false)
      } else {
        setOpenPopup(true)
    dispatch(
      popup_page({
        ride_mode: 'cancel',
        ride_start_time: start_time,
        ride_end_time: end_time,
        ride_date: booking_date,
        booking_id: booking_id,
        user_id: user_id
      })
    )
      }
  }

  const handleStatusPopup = (status) =>{
    if(status?.button === 'accept'){
      if(status?.status === 'cancel'){
        handleCancelTrip(dashboard?.popup_page?.booking_id,status?.user_id)
      } else if(status?.status === 'start'){
        handleStartTrip(dashboard?.popup_page?.booking_id,status?.user_id)
      } else if(status?.status === 'end'){
        handleEndTrip(dashboard?.popup_page?.booking_id, status?.user_id)
      }
      setOpenPopup(false)
    } else {
      setOpenPopup(false)
    }
    dispatch(popup_page(null))
  }

  const handleSubmitFilter = () =>{
    if(moment(selectStartDate)?.isAfter(moment(selectEndDate))){
      toast.error('Please select start date below than end date',{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      })
    } 
    else if(moment(selectStartDate)?.isAfter(moment(selectEndDate))){
      toast.error('Please select start date below than end date',{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      })
    }
    else if(
      selectStartDate && selectEndDate ||
      !selectStartDate && !selectEndDate &&
      (selectPaymentStatus || selectBookingStatus)
      ){
        setIsLoading(true)
        let payload = {
            boat_id: boatId,
            start_date: selectStartDate? moment(selectStartDate).format("YYYY-MM-DD"):'',
            end_date: selectEndDate? moment(selectEndDate).format("YYYY-MM-DD"):'',
            booking_status: selectBookingStatus,
            payment_status: selectPaymentStatus
        }
        console.log(payload)
        user_bookedBoat_list_API(auth?.AuthToken, payload)
          .then((res) => {
            console.log(res?.data);
            if (res?.data?.success) {
              setUserBookedBoatList(res?.data?.bookings);
            } else {
              toast.error(res?.data?.message ?? res?.data?.error, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("User booked boat list error: ", err);
            toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setIsLoading(false);
          });
      } else {
        toast.error(`Please Select ${selectStartDate? "End Date": selectEndDate? "Start Date":"some filter Options"}`,{
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        })
      }
  }

  const handleClearFilter = () =>{
    setSelectStartDate('')
    setSelectEndDate('')
    setSelectBookingStatus('')
    setSelectPaymentStatus('')
  }

  return (
    <>
      {isLoading ? <Loader loading={isLoading} /> : null}
      <PageHeader
        showLoginSignUp={auth?.AuthToken ? false : true}
        handle_navigation={handle_navigation}
        // presentPage={"Home"}
        link1={"For Boat Owners"}
        link2={"For Boat Rentals"}
        link3={auth?.tokenDecodeData?.user_type === "BOAT_OWNER"? "My Listings":null}
      />
      <div style={{height: '100%'}}>
        <div className={class_name.myListingsContainer}>
          <div className={class_name.body}>
            <div className={class_name.headerContent}>
              <div>
                <Typography className={class_name.titleName}>
                  Received Requests
                </Typography>
                <Typography className={class_name.subTitle}>
                  You have received {userBookedBoatList?.length} Requests
                </Typography>
              </div>
              <div>
                <img
                  alt="list"
                  src={IMAGES.FILTER_LIST}
                  className={class_name.filter_icon}
                />
              </div>
            </div>
            <div className={class_name.filter_div}>
              <div className="row gy-4 gx-2">
                <div className='col-md-6'>
                  <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                    <MobileDatePicker 
                    label={selectStartDate? 'Start Date':"Start Date"}
                    sx={{ 
                      "& fieldset": { 
                        border: 'none' 
                      },
                      "& .Mui-readOnly": {
                        padding: '8px',
                        paddingRight: '15px',
                        color: 'black'
                      }, 
                    }}
                    // className={{root:class_name.date_picker}}
                    // disablePast={true}
                    format="DD-MM-YYYY"
                    // placeholder="date"
                    value={selectStartDate}
                    onChange={(datevalue) => {
                      setSelectStartDate(datevalue?.$d);
                    }}
                    slotProps={{
                      field:{
                        // format: false,
                        // label: "date"
                        InputProps: {
                          endAdornment: (
                            <img src={IMAGES.DOWN_ARROW_2} style={{ width: "10px", height: "10px" }}/>
                          )
                        },
                      },
                      textField: {
                        InputProps:{
                          style:{
                            fontSize: '13px',
                            ...textFieldStyles,
                            borderRadius: width >= 480?"15px":'8px',
                            marginBottom: '8px',
                            // padding: '10px'
                            // paddingRight: '13px'
                          }
                        },
                        InputLabelProps: {
                          style: {
                            transform: selectStartDate
                              ? "translateY(-1.2rem)"
                              : "translateY(-1.2rem)",
                            fontSize: "clamp(12px, 0.9vw, 20px)",
                            color: "black",
                            // paddingLeft: "3%",
                          },
                        },
                        error: false,
                        style:{
                          // className: class_name.date_picker,
                          width: '100%',
                          // padding: '10px'
                        }
                      }
                    }}
                    />
                  </LocalizationProvider>
                </div>
                <div className='col-md-6'>
                  <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                    <MobileDatePicker 
                    label={selectEndDate? 'End Date':"End Date"}
                    sx={{ "& fieldset": { border: 'none' },"& .Mui-readOnly": {padding: '8px',paddingRight: '15px'}, }}
                    // className={{root:class_name.date_picker}}
                    // disablePast={true}
                    format="DD-MM-YYYY"
                    // placeholder="date"
                    value={selectEndDate}
                    onChange={(datevalue) => {
                      setSelectEndDate(datevalue?.$d);
                    }}
                    slotProps={{
                      field:{
                        // format: false,
                        // label: "date"
                        InputProps: {
                          endAdornment: (
                            <img src={IMAGES.DOWN_ARROW_2} style={{ width: "10px", height: "10px" }}/>
                          )
                        },
                      },
                      textField: {
                        InputProps:{
                          style:{
                            fontSize: '13px',
                            ...textFieldStyles,
                            marginBottom: '8px',
                            borderRadius: width >= 480?"15px":'8px'
                            // padding: '10px'
                            // paddingRight: '13px'
                          }
                        },
                        InputLabelProps: {
                          style: {
                            transform: selectEndDate
                              ? "translateY(-1.2rem)"
                              : "translateY(-1.2rem)",
                            fontSize: "clamp(12px, 0.9vw, 20px)",
                            color: "black",
                            // paddingLeft: "3%",
                          },
                        },
                        error: false,
                        style:{
                          // className: class_name.date_picker,
                          width: '100%',
                          // padding: '10px'
                        }
                      }
                    }}
                    />
                  </LocalizationProvider>
                </div>
                <div className='col-md-6'>
                  <CustomTextField
                    label={"Booking Status"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="boat_type"
                    name="boat_type"
                    // placeholder="Trip / Watersport Types"
                    value={selectBookingStatus}
                    onChange={(e) => {
                      setSelectBookingStatus(e.target.value);
                    }}
                    // onTouchStart={()=>setOpenModal(false)}
                    select
                    InputProps={{
                      style: {
                        ...textFieldStyles,
                        borderRadius: width >= 480?"15px":'8px'
                      },
                    }}
                    InputLabelProps={{
                      // className: class_name.inputlabel_style,
                      style: {
                        transform: selectBookingStatus
                          ? "translateY(-1.2rem)"
                          : null,
                        fontSize: "clamp(12px, 0.9vw, 20px)",
                        color: "black",
                        // paddingLeft: "3%",
                      },
                    }}
                  >
                    {bookingStatus.map((item, index) => (
                      <MenuItem 
                      key={index} 
                      value={item?.status}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: "5px 10px",
                      }}
                      >
                        {
                        item?.status?.charAt(0)+
                        item?.status?.slice(1).toLowerCase()
                        }
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </div>
                <div className='col-md-6'>
                  <CustomTextField
                    label={"Payment Status"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="boat_type"
                    name="boat_type"
                    value={selectPaymentStatus}
                    onChange={(e) => {
                      setSelectPaymentStatus(e.target.value);
                    }}
                    select
                    InputProps={{
                      style: {
                        ...textFieldStyles,
                        borderRadius: width >= 480?"15px":'8px'
                      },
                    }}
                    InputLabelProps={{
                      // className: class_name.inputlabel_style,
                      style: {
                        transform: selectPaymentStatus
                          ? "translateY(-1.2rem)"
                          : null,
                        fontSize: "clamp(12px, 0.9vw, 20px)",
                        color: "black",
                        // paddingLeft: "3%",
                      },
                    }}
                  >
                    {/* {auth.boatType?.map((item, index) => ( */}
                      <MenuItem 
                      // key={index} 
                      value={"PAID"}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: "5px 10px",
                      }}
                      >
                        Paid
                      </MenuItem>
                      <MenuItem 
                      // key={index} 
                      value={"PENDING"}
                      onChange={()=>{

                      }}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: "5px 10px",
                      }}
                      >
                        Pending
                      </MenuItem>
                      <MenuItem 
                      // key={index} 
                      value={"FAILED"}
                      onChange={()=>{

                      }}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: "5px 10px",
                      }}
                      >
                        Failed
                      </MenuItem>
                    {/* ))} */}
                  </CustomTextField>
                </div>
              </div>
              <div className="d-flex w-100 justify-content-center align-items-center gap-5 mt-3">
                <div>
                  <span 
                  className={class_name.filter_btn}
                  onClick={()=>handleSubmitFilter()}
                  >
                    Submit
                  </span>
                </div>
                <div>
                  <span 
                  className={class_name.filter_btn}
                  onClick={()=>handleClearFilter()}
                  >
                    Clear Filter
                  </span>
                </div>
              </div>
            </div>
            {userBookedBoatList?.length?
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "30%",
              }}
            >
              {userBookedBoatList?.map((item) => {
                return (
                  <div
                    key={item?.booking?.id}
                    className={class_name.profile_div}
                  >
                    <div 
                    className={class_name.profile_icon}
                    style={{
                      backgroundImage: `url(${
                        item?.userAccount?.profile_image??
                          IMAGES.PROFILE_ICON
                      })`
                    }}
                    >
                      
                    </div>
                    {/* <img
                      alt="list"
                      src={
                        item?.userAccount?.profile_image??
                          IMAGES.PROFILE_ICON
                      }
                      className={class_name.profile_icon}
                    /> */}
                    <div 
                    className={class_name.details_div}
                    >
                      <div 
                      className={class_name.content_div}
                      onClick={() => {
                        navigate("/bookedReqDetails", {
                          state: { boat_booking_id: item?.booking?.boat_booking_id },
                        });
                      }}
                      >
                        <Typography
                          className={class_name.contentTitleName}
                          noWrap={true}
                        >
                          {item?.booking?.user_name}
                        </Typography>
                        <div className="d-flex gap-sm-4 gap-2">
                          <div className="">
                          <Typography className={class_name.lable}>
                              Reserved
                            </Typography>
                            <Typography className={class_name.booking_date}>
                              Booking Date
                            </Typography>
                            <Typography className={class_name.booking_date}>
                              Time
                            </Typography>
                          </div>
                          <div>
                          <Typography className={class_name.lable}>
                          {item?.booking?.booking_boat_detail?.boat_name}
                            </Typography>
                            <Typography className={class_name.booking_date}>
                            {moment(item?.booking?.booking_date).format("DD-MMM-YYYY")}
                            </Typography>
                            <Typography className={class_name.booking_date}>
                            {`${moment(item?.booking?.start_time,'HH:mm:ss').format("hh:mm A")} - ${moment(item?.booking?.end_time,'HH:mm:ss').format("hh:mm A")}`}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-md-column gap-3 flex-row align-content-between">
                        <div
                        style={{
                          display: 
                          item?.booking?.trip_status === 'PAYMENT_INITIATED' || 
                          item?.booking?.trip_status === 'PENDING' ||
                          item?.booking?.trip_status === 'TRIP_CANCEL'
                          ? 'none':'block'
                        }}
                        >
                          <span 
                          className={class_name.cancel_btn}
                          onClick={()=>{
                            handleOpenPopup(
                              item?.booking?.trip_status,
                              item?.booking?.start_time,
                              item?.booking?.end_time,
                              item?.booking?.booking_date,
                              item?.booking?.boat_booking_id,
                              item?.booking?.user_id,
                              )
                          }}
                          style={{
                            backgroundColor: 
                            item?.booking?.trip_status === 'TRIP_INITIATED'?
                            'darkgreen':
                            item?.booking?.trip_status === 'TRIP_END'?
                            "transparent":'darkred',
                            color:
                            item?.booking?.trip_status === 'TRIP_END'?
                            "green":'white',
                          }}
                          >
                            {
                            item?.booking?.trip_status === 'TRIP_INITIATED'?
                            'Start ':
                            item?.booking?.trip_status === 'TRIP_STARTED'?
                            'End ':'Ended '

                          }Trip
                            
                          </span>
                        </div>
                        <div
                        style={{
                          display: item?.booking?.trip_status === 'TRIP_STARTED' || item?.booking?.trip_status === 'TRIP_END'?
                          'none':'block'
                        }} 
                        >
                          <span 
                          className={class_name.cancel_btn}
                          style={{
                            backgroundColor: 
                            item?.booking?.trip_status === 'TRIP_CANCEL'?
                            "transparent":'darkred',
                            color:
                            item?.booking?.trip_status === 'TRIP_CANCEL'?
                            "red":'white',
                          }}
                          onClick={()=>{
                            handleCancelOpenPopup(
                              item?.booking?.trip_status,
                              item?.booking?.start_time,
                              item?.booking?.end_time,
                              item?.booking?.booking_date,
                              item?.booking?.boat_booking_id,
                              item?.booking?.user_id
                              )
                          }}
                          >{
                            item?.booking?.trip_status === 'TRIP_CANCEL'?
                            'Cancelled':'Cancel Trip'
                          }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>:
            <div className={class_name.noDataContent}>
              <img
                alt="no result"
                src={IMAGES.NO_RESULT}
                className={class_name.noResultImg}
              />
              <Typography className={class_name.sryTxt}>
                Sorry, No Records found.
              </Typography>

              {/* <Typography className={class_name.noResultTxt}>
                No Records Found
              </Typography> */}
            </div>
            }
          </div>
        </div>
        {openPopup?
        <PopupPage
        open={openPopup}
        handleClosePopup={handleClosePopup}
        handleStatus={handleStatusPopup}
        title={`Are you sure you want to ${dashboard?.popup_page?.ride_mode} your ride`}
        />:null
      }
        <div className="d-none d-md-block">
          <Footer />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  myListingsContainer: {
    display: "flex",
    backgroundColor: "#f6f6f6",
    width: "100%",
    minHeight: '100%',
    // height: '100vh'
    // overflowX: 'none',
    // [theme.breakpoints.down("700")]: {
    //   height: "100%",
    // },
  },
  body: {
    display: "flex",
    width: "100%",
    paddingRight: "220px",
    paddingLeft: "220px",
    flexDirection: "column",
    // overflowX: 'none',
    [theme.breakpoints.down("md")]: {
      padding: "0px 200px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 100px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 12px",
    },
  },
  headerContent: {
    marginTop: "60px",
    flexDirection: "row",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      margin: "50px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "35px 0px",
    },
  },

  cancel_btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 'clamp(14px,1.5vw,16px)',
    // backgroundColor: '#026b93',
    padding: '2px 10px',
    textAlign: 'center',
    // color: 'white',
    borderRadius: '2px',
    fontWeight: "600",
    whiteSpace: 'nowrap'
  },

  titleName: {
    fontSize: "39px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#424651",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  subTitle: {
    marginTop: "10px",
    fontSize: "25px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.52,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      // marginTop: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      marginTop: "7px",
    },
  },
  filter_icon: {
    width: "32px",
    height: "22px",
    [theme.breakpoints.down("sm")]: {
      width: "28px",
      height: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "24px",
      height: "14px",
    },
  },

  filter_div: {
    paddingBottom: '5%',
  },

  filter_btn: {
    backgroundColor: '#026b93',
    padding: '7px 20px',
    color: 'white',
    whiteSpace: 'nowrap',
    borderRadius: '2px',
    fontWeight: '600',
    cursor: 'pointer'
  },

  filter_box_div: {
    width: "100%",
    margin: "0",
  },

  profile_div: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "white",
    boxShadow: "0px 0px 1px rgba(66, 70, 81, 0.3)",
    padding: "20px 82px", //20px 82px
    borderBottom: "solid 0.5px rgba(66, 70, 81, 0.3)",
    [theme.breakpoints.down("md")]: {
      padding: "15px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px 52px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 12px",
      border: "none",
      borderBottom: "solid 0.5px rgba(66, 70, 81, 0.3)",
    },
  },
  profile_icon: {
    width: "140px",
    height: "115px",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "105px",
      height: "90px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "65px",
      height: "57px",
    },
  },
  reqName: {
    fontSize: "25px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.52,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
  },
  reqDetailTxt: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
    textAlign: "left",
    // color: "#424651",
    color: "aqua",
    width: "10%",
    backgroundColor: "tomato",
  },

  boatListBody: {
    display: "flex",
    flexDirection: "row",
  },

  details_div: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
    [theme.breakpoints.down('992')]:{
      flexDirection: 'column',
      justifyContent: 'center',
    }
  },

  content_div: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginLeft: "35px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "15px",
    },
  },

  contentTitleName: {
    fontSize: "25px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.52,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  lable_div: {
    display: "flex",
    inlineSize: "min-content",
    whiteSpace: "nowrap",
    gap: "57px",
    marginTop: "17px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "12px",
      whiteSpace: "noWrap",
      gap: "53px",
      inlineSize: "min-content",
    },
  },
  lable: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  booking_date_div: {
    display: "flex",
    flexDirection: "row",
    inlineSize: "min-content",
    whiteSpace: "nowrap",
    gap: "30px",
    [theme.breakpoints.down("xs")]: {
      whiteSpace: "noWrap",
      gap: "30px",
      inlineSize: "min-content",
    },
  },
  booking_date: {
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.56,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    trip_btn: {
      border: '1px solid black',
      fontSize: '10rem'
    }
  },
  noDataContent: {
    display: "flex",
    flexDirection: "column",
    marginTop: "100px",
    alignItems: "center",
  },
  noResultImg: {
    width: "clamp(80px, 35vw, 139px)", // Adjust the range as needed
    height: "clamp(80px, 35vh, 139px)", // Adjust the range as needed
  },
  sryTxt: {
    fontSize: "clamp(14px, 3vw, 48px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.87)",
  },
  noResultTxt: {
    marginTop: "24px",
    fontSize: "clamp(12px, 3vw, 36px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.45)",
    width: "70%",
    textAlign: "center",
    marginBottom: "100px",
  },
}));

const textFieldStyles = {
  // borderRadius: "15px",
  backgroundColor: "white",
  border: "solid 1px rgba(66, 70, 81, 0.2)",
  // padding: '10px'
  // padding: '0%'
};

// const request_list = [
//   {
//     id: 1,
//     boatName: "Night Light, Jeddah",
//     star: 4,
//     SAR: "300 SAR",
//     date: "18.07.2022",
//     image: IMAGES.boat1,
//   },
//   {
//     id: 1,
//     boatName: "Black Purl",
//     star: 2,
//     SAR: "30 SAR",
//     date: "31.07.2022",
//     image: IMAGES.boat2,
//   },
//   {
//     id: 1,
//     boatName: "Tanker",
//     star: 3.5,
//     SAR: "500 SAR",
//     date: "11.07.2022",
//     image: IMAGES.boat3,
//   },
//   {
//     id: 1,
//     boatName: "Titen",
//     star: 4.5,
//     SAR: "700 SAR",
//     date: "15.07.2022",
//     image: IMAGES.boat4,
//   },
// ];