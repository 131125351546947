import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { PageHeader } from "../../../Dash/page-header/PageHeader";
import {
  Instagram,
  Star,
  StarOutline,
  Twitter,
  YouTube,
} from "@material-ui/icons";
import IMAGES from "../../../Images";
import { StarRating } from "../../../../UI kit/5Star/StarRating";
import { HeaderContent } from "../../../Common/map/HeaderContent";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../../../Component/Footer/Footer";
import { useEffect } from "react";
import { useState } from "react";
import { user_bookedBoat_detail_API } from "../../../../Service/api";

export const CustomerProfile = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const classes = useStyles({ min: 10, max: 60, unit: "px" });
  const auth = useSelector((state) => state?.auth);

  const handle_navigation = (name) => {
    if (name === "chat") {
      navigate("/allMessage");
    } else{
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleHeaderCallBack = (name) => {
    if (name === "Home") {
      if (auth?.tokenDecodeData?.user_type === "BOAT_OWNER") {
        navigate("/boatOwnerDashBoard");
      } else {
        navigate("/rental");
      }
    } else if (name === "Log In") {
      navigate("/logIn");
    } else if (name === "Sign Up") {
      navigate("/signUP");
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else if (name === "For Boat Rentals" || name === "For Boat Owners") {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (name === "/searchBoat") {
      navigate("/searchBoat");
    }
  };
  return (
    <>
      {/* <HeaderContent
        contentname1={"Home"}
        contentname2={"For Boat Owners"}
        contentname3={"For Boat Rentals"}
        contentname4={"My Listings"}
        handleBack={handleHeaderCallBack}
        search={"/searchBoat"}
        showLoginSignUp={auth?.AuthToken ? false : true}
        // presentPage={"My Listings"}
      /> */}
      <PageHeader
        showLoginSignUp={auth?.AuthToken ? false : true}
        handle_navigation={handle_navigation}
        // presentPage={"Home"}
        link1={"Boat Offers"}
        link2={"Scuba Courses/Programs"}
        link3={"Scuba Diving Trips"}
        link4={auth?.tokenDecodeData?.user_type === "BOAT_OWNER"? "My Listings":null}
      />
      <div className={classes.myListingsContainer}>
        <div className={classes.body}>
          <div className={classes.headerContent}>
            <div className={classes.insideHeader}>
              <div className={classes.contentContainer}>
                <div className={classes.dateTitleDiv}>
                  <div>
                    <Typography className={classes.titleName}>28</Typography>
                    <Typography className={classes.titleName}>May </Typography>
                  </div>
                  <div className={classes.titleContainer}>
                    <Typography className={classes.titleName}>
                      Advanced Open Water Diving Course
                    </Typography>
                    <Typography className={classes.subTitle}>
                      Blue Ocean Dive Resort
                    </Typography>
                  </div>
                </div>
                <div className={classes.cancelDiv}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    className={classes.buttonStyles}
                  >
                    Cancel Booking
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.userDetailsContainer_parentDiv}>
            <div className={classes.userDetailsContainer}>
              <div className={classes.userBoxDiv}>
                <div className={classes.rowDiv}>
                  <Typography className={classes.title}>
                    Course/Program
                  </Typography>
                  <Typography className={classes.seeDetailsTxt}>
                    See Full Details
                  </Typography>
                </div>
                <div
                  className={classes.rowDivShowUserDetails}
                  style={{ marginTop: "40px" }}
                >
                  <Typography className={classes.lableTxt}>
                    Instructor
                  </Typography>
                  <Typography className={classes.userInfoTxt}>
                    Ali Muhammed
                  </Typography>
                </div>
                <div className={classes.rowDivShowUserDetails}>
                  <Typography className={classes.lableTxt}>Duration</Typography>
                  <Typography className={classes.userInfoTxt}>
                    5 days
                  </Typography>
                </div>
                <div className={classes.rowDivShowUserDetails}>
                  <Typography className={classes.lableTxt}>Price</Typography>
                  <Typography className={classes.userInfoTxt}>
                    2000 SAR Per Person
                  </Typography>
                </div>
              </div>

              <div className={classes.userBoxDiv} style={{ marginTop: "24px" }}>
                <div className={classes.rowDiv}>
                  <Typography className={classes.title}>
                    Recent Activity
                  </Typography>
                </div>
                <div
                  className={classes.rowDivShowUserDetails}
                  style={{ marginTop: "40px" }}
                >
                  <Typography className={classes.lableTxt}>
                    19.05.2022
                  </Typography>
                  <Typography className={classes.userInfoTxt}>
                    Ali accepted your book request
                  </Typography>
                </div>
                <div className={classes.rowDivShowUserDetails}>
                  <Typography className={classes.lableTxt}>
                    22.05.2022
                  </Typography>
                  <Typography className={classes.userInfoTxt}>
                    You paid 2000 SAR
                  </Typography>
                </div>
                <div className={classes.rowDivShowUserDetails}>
                  <Typography className={classes.lableTxt}>
                    11.06.2022
                  </Typography>
                  <Typography className={classes.userInfoTxt}>
                    You gave your feedback to Ali
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.userDetailsSingleContainer}>
              <Typography
                className={classes.title}
                style={{ marginBottom: "9%" }}
              >
                User's Review
              </Typography>
              <div className={classes.starRating_div}>
                <StarRating rating={3} />
              </div>
              <Typography
                className={classes.userInfoTxt}
                style={{ marginTop: "50px", textAlign: "center" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Typography>
            </div>
          </div>
        </div>
        {/* <div
          style={{
            backgroundColor: "white",
            padding: "26px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              onClick={() => {
                console.log("Twitter");
              }}
              style={{ backgroundColor: "rgba(66, 70, 81, 0.2)" }}
            >
              <Twitter style={{ fill: "black" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                console.log("YouTube");
              }}
              style={{
                marginLeft: "25px",
                backgroundColor: "rgba(66, 70, 81, 0.2)",
              }}
            >
              <YouTube style={{ fill: "black" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                console.log("Instagram");
              }}
              style={{
                marginLeft: "25px",
                backgroundColor: "rgba(66, 70, 81, 0.2)",
              }}
            >
              <Instagram style={{ fill: "black" }} />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "28px",
              paddingBottom: "40px",
              // width: "100%"
            }}
          >
            <Typography
              className={classes.userInfoTxt}
              style={{ width: "10%", fontWeight: "200" }}
            >
              About Us
            </Typography>
            <Typography
              className={classes.userInfoTxt}
              style={{ width: "15%", fontWeight: "200" }}
            >
              Help & Support
            </Typography>
            <Typography
              className={classes.userInfoTxt}
              style={{ width: "10%", fontWeight: "200" }}
            >
              Contact Us
            </Typography>
            <Typography
              className={classes.userInfoTxt}
              style={{ width: "15%", fontWeight: "200" }}
            >
              Terms Of Service
            </Typography>
            <Typography
              className={classes.userInfoTxt}
              style={{ width: "10%", fontWeight: "200" }}
            >
              Privacy Policy
            </Typography>
          </div>
        </div> */}
      </div>
      <div className="d-none d-md-block">
      <Footer />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({

  myListingsContainer: {
    display: "flex",
    width: "100vw",
    backgroundColor: "#f6f6f6",
    // height: "100vw",
    flexDirection: "column",
  },
  body: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingBottom: "114px",
  },
  headerContent: {
    display: "flex",
    width: "100%",
    backgroundImage: `url(${IMAGES.subaDiving})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    height: "367px",
  },
  insideHeader: {
    display: "flex",
    width: "100%",
    // flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    backgroundColor: "rgba(169, 209, 239, 0.5)",
    padding: "0px 15%",
    alignContent: "center",
    // alignSelf: "center",
    paddingBottom: "3%",
    [theme.breakpoints.down('992')]: {
      padding: '0% 6%',
      paddingBottom: "3%",
    },
    [theme.breakpoints.down('768')]: {
      padding: '0% 4%',
      paddingBottom: "3%",
    },
  },
  contentContainer: {
    display: "flex",
    // flexDirection: "column",
    // opacity: 1,
    width: "100%",
  },
  titleName: {
    fontSize: "24px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.46,
    letterSpacing: "normal",
    // color: "#424651",
    color: "black",
    textAlign: "left",
    [theme.breakpoints.down('768')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.down('480')]: {
      fontSize: '17px'
    },
  },

  title: {
    fontSize: "24px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.46,
    letterSpacing: "normal",
    // color: "#424651",
    color: "black",
    textAlign: "left",
    [theme.breakpoints.down('768')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.down('480')]: {
      fontSize: '15px'
    },
  },
  subTitle: {
    marginTop: "8px",
    fontSize: "22px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    // color: "#424651",
    color: "#36454F",
    [theme.breakpoints.down('768')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.down('480')]: {
      fontSize: '15px'
    },
  },
  titleContainer: {
    marginLeft: "11%",
    [theme.breakpoints.down('992')]: {
      marginLeft: '8%'
    },
    [theme.breakpoints.down('480')]: {
      marginLeft: '5%'
    },
  },
  cancelDiv: {
    display: "flex",
    alignItems: "flex-end",
    // backgroundColor: "red",
    // flex: 1,
    width: "200px",
    alignSelf: "center",
    justifyContent: "center",
  },
  buttonStyles: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    whiteSpace: 'nowrap',
    lineHeight: "1.46",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#3973a5",
    borderRadius: "10px",
    [theme.breakpoints.down('768')]: {
      fontSize: '12px'
    }
  },
  dateTitleDiv: {
    display: "flex",
    width: "100%",
  },
  userDetailsContainer_parentDiv: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    paddingBottom: "114px",
    padding: "0px 16%",
    paddingTop: "8%",
    [theme.breakpoints.down('992')]: {
      padding: "0px 10%",
      flexDirection: 'column',
      paddingTop: "8%",
    },
    [theme.breakpoints.down('768')]: {
      paddingTop: "8%",
    },
    [theme.breakpoints.down('480')]: {
      paddingTop: "8%",
      padding: "0px 6%",
    },
  },
  userDetailsContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.down('768')]: {
      width: '100%'
    }
  },
  userDetailsSingleContainer: {
    marginLeft: "20px",
    display: "flex",
    width: "50%",
    flexDirection: "column",
    backgroundColor: "white",
    justifyContent: "center",
    // alignSelf: "center",
    // alignContent: "center",
    padding: "65px",
    alignItems: "center",
    [theme.breakpoints.down('992')]: {
      width: '100%',
      marginLeft: "0px",
      marginTop: "24px",
    },
    [theme.breakpoints.down('480')]: {
      padding: '40px'
    },
  },
  userBoxDiv: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: "53px 87px",
    width: '100%',
    [theme.breakpoints.down('768')]: {
      padding: "5% 10%"
    },
    [theme.breakpoints.down('480')]: {
      padding: "5% 8%"
    },
  },
  rowDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  rowDivShowUserDetails: {
    display: "flex",
    flexDirection: "row",
    // gap: "10px",
    width: "100%",
    marginTop: "24px",
  },
  seeDetailsTxt: {
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.6)",
    whiteSpace: 'nowrap',
    marginLeft: '5px',
    [theme.breakpoints.down('768')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.down('480')]: {
      fontSize: '15px'
    },
  },
  lableTxt: {
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    width: "130px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down('768')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.down('480')]: {
      fontSize: '15px'
    },
  },
  userInfoTxt: {
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    width: '70%',
    whiteSpace: 'normal',
    [theme.breakpoints.down('768')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.down('480')]: {
      fontSize: '15px'
    },
  },
  // "@media (max-width: 768px)":{
  //   userDetailsContainer_parentDiv: {
  //     display: 'flex',
  //     flexDirection: 'column'
  //   },
  // },
}));
