export const API = {
  currentEnv: "qa",

  baseUrls: {
    dev: "http://localhost:3000/",
    qa: "https://oceanhub.herokuapp.com/",
  },

  authUrls: {
    user_register: "user/register",
    login: "user/login",
    verify_otp: "user/otp",
    resend_otp: "user/resend-otp",
    boat_type: "boat_type",
    boat_service: "boat_service",
    boat_register: "boat_register",
    my_listing: "my_listing",
    boat_list_filter: "boat_list",
    forgot_password_request: "forgot-password-request",
    // verify_forgotpass_otp: "verify-forgotpass-otp",
    verify_forgotpass_otp: "user/otp",
    set_password: "set-password",
    single_boat_data: "single_boat_data",
    update_boat: "update_boat",
    user_details: "user/user-details",
    user_updateUser: "user/updateUser",
    user_phone_verify_initiate: "user/phone-verify-initiate",
    user_verified_number: "user/verified-number",
    get_notification: "user/get-notification",
    updateNotificationsSettings: "user/update-notification",
    add_boat_type: "add_boat_type",
    add_boat_service: "add_boat_services",
    boat_booking: "boat_booking",
    boat_booking_list: "boat_booking_list",
    boat_booking_detail: "boat_booking_detail",
    boat_booking_slots: "boat_booking_slots",
    chat_person_list: "chat_person_list",
    chat_status: "chat_status",
    city_list: "city_list?city=",
    chat_history: "chat_history",
    user_bookedBoat_list: "user_bookedBoat_list",
    user_bookedBoat_detail: "user_bookedBoat_detail",
    notifications: "notifications",
    deleteNotificationbyId: "api/notification",
    deleteNotificationbyUserId: "api/notifications",
    notificationCount: "unread-count",
    notification_read: 'read',
    booking_details_for_payment: "booking_list",
    initiate_payment: 'initiate_payment',
    start_trip: 'trip_start',
    end_trip: 'trip_end',
    cancel_trip: 'trip_cancel',
    payment: 'payment',
    complete_payment: 'complete_payment',
    TransactionDetail: 'TransactionDetail',
    boat_rating: 'boat_rating',
    recent_activity: 'recent_activity',
    update_transaction: 'update_transaction',
    create_charge:'create/charge',
    refund:'refund',
    refund_details:"refund_details",
    get_boat_marinecity: 'admin/get_boat_marinecity',
    booking_status_detail: 'booking_status_detail',
    previous_booking_status_detail: 'previous_booking_status_detail',
    isOnline: 'isOnline',
    update_document: 'update_document',
    all_review: 'all_review',
    cancel_payment:"cancel_payment"
  },
};
