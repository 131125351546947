import React, { useState } from "react";
import { Star, StarOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

export const SelectableStarRating = ({ onSelect }) => {
  const [selectedRating, setSelectedRating] = useState(0);

  const class_name = useStyles({ min: 10, max: 60, unit: "px" });

  const handleStarClick = (starNumber) => {
    setSelectedRating(starNumber);
    return onSelect(starNumber);
  };

  return (
    <div>
      {[...Array(5)].map((_, index) => {
        const starNumber = index + 1;
        return (
          <span
            key={starNumber}
            onClick={() => handleStarClick(starNumber)}
            style={{ cursor: "pointer" }}
          >
            {starNumber <= selectedRating ? (
              <>
                <Star className={class_name.gold_star} />
              </>
            ) : (
              <>
                <StarOutline
                className={class_name.star}
                />
              </>
            )}
          </span>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  star: {
    fill: "gray",
    width: "60px",
    height: "60px",
    fontWeight: "lighter",
    [theme.breakpoints.down('768')]: {
      width: '40px',
      height: '40px'
    }
  },
  gold_star: {
    fill: "gold", 
    width: "60px", 
    height: "60px"
  }
}))
