import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { AuthToken, dispatchcalculateTime } from "../redux/slices";

const useStorage = () => {
  const [token, setTokenData] = useState(null);
  const [calculateTime, setCalculateTime] = useState(false);
  const [userId, setUserId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const getCurrentSession = async () => {
      const token = localStorage.getItem("session");
      const user_id = localStorage.getItem("user_id");

      setUserId(user_id);

      if (token) {
        let tokenDecode = await jwt_decode(token);
        setTokenData(tokenDecode);
        // dispatch(AuthToken(tokenDecode));
        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (currentTimestamp < tokenDecode.exp) {
          setCalculateTime(true);
          //   dispatch(dispatchcalculateTime(true));
          //   setLoading(false);
          //   UserDetailsAPI(user_id, token, (err, ress) => {
          //     dispatch(GetUserDetails(ress));
          //   });
        } else {
          //   setLoading(false);
          setCalculateTime(false);
          //   dispatch(dispatchcalculateTime(false));
        }
      } else {
        // setLoading(false);
        setCalculateTime(false);
        // dispatch(dispatchcalculateTime(false));
      }
    };

    getCurrentSession();
  }, []);

  return { token, calculateTime, userId };
};

export default useStorage;
