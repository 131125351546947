/* eslint-disable react/prop-types */
import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "@mui/material";
import axios from "axios";
import IMAGES from "../../Images";
import "./GoogleSignInButton.css";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const googleClientId =
  "496577884812-quv2n4j54nvk6gtrmo4vuv98cmrlf5q4.apps.googleusercontent.com";

const GoogleSignInButton = ({
  handle,
  title = "Sign up with Google",
  isTermsOfServiceChecked,
  style,
}) => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      console.log("userInfo", userInfo);
      handle(userInfo, "GOOGLE", "GOOGLE");
    },
  });

  return (
    <div
      className={class_name.googleContainer}
      onClick={() => {
        if (isTermsOfServiceChecked) {
          login();
        } else {
          handle("", "", "GOOGLE");
        }
      }}
    >
      <img alt="google" src={IMAGES.GOOGLE} className={class_name.google_img} />
      <Typography
        className={style ? "no-acc-txt" : class_name.name_txt}
        style={{ color: "#3973a5", textTransform: "none", marginLeft: "15px" }}
      >
        {title}
      </Typography>
      {/* </Button> */}
    </div>
  );
};

export default GoogleSignInButton;

const useStyles = makeStyles((theme) => ({
  googleContainer: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "1.5% 0%",
    alignItems: "center",
    width: "100%",
    backgroundColor: "white",
    border: "1px solid #026b93",
    "&:hover": {
      boxShadow: "0px 0px 4px #026b93",
      "& $name_txt": {
        fontWeight: "600",
      },
    },
  },

  google_login_btn: {
    width: "100%",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.09)",
    padding: "10px",
  },
  google_img: {
    width: "clamp(5px, 25vw, 20px)", // Adjust the range as needed
    height: "clamp(5px, 25vh, 20px)", // Adjust the range as needed
  },
  name_txt: {
    fontSize: "clamp(14px, 2vw, 18px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.96,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#3973a5",
    textTransform: "none",
    marginLeft: "15px",
  },
}));
//   const { access_token, refresh_token } = tokenResponse.access_token; // now I have valid access_token
//   let userInfo = await axios.get(
//     "https://www.googleapis.com/oauth2/v3/userinfo",
//     {
//       headers: {
//         Authorization: `Bearer ${access_token}`,
//       },
//     }
//   );
//   console.log("userInfo", userInfo);

// const response = await fetch(
//   // "https://www.googleapis.com/oauth2/v3/userinfo",
//   // "https://www.googleapis.com/oauth2/v2/userinfo",
//   "https://www.googleapis.com/oauth2/v3/userinfo?access_token=",
//   {
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       Authorization: `Bearer ${tokenResponse.access_token}`,
//       "Content-Type": "application/json",
//     },
//   }
// );
// console.log("response", response);
