import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { toast } from 'react-toastify';

const ConfirmationModal = ({ open, handleClose, title, createRefund, transactionId, refund_details, bookingDetails }) => {
    const [reason, setReason] = useState('');
    const [loading, setLoading] = useState(false); // State to manage loading state of API call

    const handleReject = () => {
        handleClose(); // Close the modal on reject 
    };
    const bookingDate = new Date(refund_details.bookingDate);
    const formattedDate = bookingDate.toLocaleDateString('en-GB').split('/').reverse().join('-');
  
    const handleAccept = () => {
        if (!reason.trim()) {
            toast.error('Reason is required', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000
            });
        } else {
            const payload = {
                transaction_id: transactionId,
                reason: reason
            };

            createRefund(payload,bookingDetails?.boat_owner_id)
                // .then((res) => {
                //     handleClose(); // Close the modal
                // })
                // .catch((error) => {
                //     // Handle error if needed
                // });
        }
    };

    const handleChangeReason = (e) => {
        setReason(e.target.value);
    };
    console.log("refund",refund_details)

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            
            <DialogContent>
                <p>If you want refund your booking will be cancelled</p>
            <div>
                    <p><strong>Booking Date:</strong> {formattedDate}</p>
                    <p><strong>Refund Amount:</strong> {refund_details.amount}</p>
                    <p><strong>Refund Percentage:</strong> {refund_details.refundPercentage}%</p>
                    {/* <p><strong>Original Amount:</strong> {refund_details.amount}</p> */}
                </div>
                <p>Are you sure you want to proceed?</p>
                <div style={{ marginBottom: '1rem' }}>
                    <input
                        type="text"
                        value={reason}
                        placeholder="Reason for Reject"
                        onChange={handleChangeReason}
                        style={{
                            width: '100%',
                            padding: '10px',
                            fontSize: '1rem',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            boxSizing: 'border-box'
                        }}
                    />
                </div>
               
            </DialogContent>
            <DialogActions>
                <button
                    onClick={handleReject}
                    className='btn bg-danger fw-semibold'
                    style={{
                        padding: '10px 20px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        border: 'none',
                        color: 'white',
                        backgroundColor: '#dc3545',
                        fontSize: '1rem'
                    }}
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Cancel'}
                </button>
                <button
                    onClick={handleAccept}
                    className='btn bg-success fw-semibold'
                    style={{
                        padding: '10px 20px',
                        marginRight: '10px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        border: 'none',
                        color: 'white',
                        backgroundColor: '#28a745',
                        fontSize: '1rem'
                    }}
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Accept'}
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationModal;
