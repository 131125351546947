import axios from "axios";
import { API } from "../constants";

export function register(data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.user_register,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function verifyOtp(data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.verify_otp,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function login(data) {
  return axios.post(API.baseUrls[API.currentEnv] + API.authUrls.login, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function resend_otp(data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.resend_otp,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function forgot_password_request(data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.forgot_password_request,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
export function verify_forgotpass_otp(data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.verify_forgotpass_otp,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function set_new__password(data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.set_password,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export function boat_type(token) {
  return axios.get(API.baseUrls[API.currentEnv] + API.authUrls.boat_type, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function boat_booking_details(token) {
  console.log(token);
  return axios.get(API.baseUrls[API.currentEnv] + API.authUrls.boat_booking, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function boat_service(token) {
  return axios.get(API.baseUrls[API.currentEnv] + API.authUrls.boat_service, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function boat_register(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.boat_register,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function my_listing(token) {
  return axios.get(API.baseUrls[API.currentEnv] + API.authUrls.my_listing, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function my_notifications(token) {
  return axios.get(API.baseUrls[API.currentEnv] + API.authUrls.notifications, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function notification_mark_as_read(token, id) {
  return axios.patch( API.baseUrls[API.currentEnv] + API.authUrls.notification_read,
    id,
    {
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

// var myHeaders = new Headers();
// myHeaders.append("Authorization", `Bearer ${token}`);

// var requestOptions = {
//   method: 'PATCH',
//   headers: myHeaders,
//   redirect: 'follow'
// };

// return fetch(`https://oceanhub.herokuapp.com/${id}/read`, requestOptions)
//   .then(response => response.json())
//   .then(result => {
//     return result
//   })
//   .catch(error => {
//     return error
//   });
}


export function delete_notifications(token,id) {
  return axios.delete(`${API.baseUrls[API.currentEnv] + API.authUrls.deleteNotificationbyId}/${id}`,
    {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function delete_all_notifications(token,id) {
  return axios.delete(`${API.baseUrls[API.currentEnv] + API.authUrls.deleteNotificationbyUserId}/${id}`,
    {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function notifications_count(token) {
  return axios.get(API.baseUrls[API.currentEnv] + API.authUrls.notificationCount,
    {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function boat_list_filter(token, payload) {
  console.log(payload,'payload')
  console.log(
    `${API.baseUrls[API.currentEnv] + API.authUrls.boat_list_filter}?page=${
      payload?.pageNo
    }&limit=${payload?.maxNoOfData ?? 12}${
      payload?.price ? "&price=" + payload?.price : ""
    }${payload?.capacity ? "&capacity=" + payload?.capacity : ""}${
      payload?.city ? "&city=" + payload?.city : ""
    }${payload?.boat_type ? "&type=" + payload?.boat_type.toLowerCase() : ""}${
      payload?.date ? "&date=" + payload?.date : ""}
    `
  )
  return axios.get(
    `${API.baseUrls[API.currentEnv] + API.authUrls.boat_list_filter}?page=${
      payload?.pageNo
    }&limit=${payload?.maxNoOfData ?? 12}${
      payload?.price ? "&price=" + payload?.price : ""
    }${payload?.capacity ? "&capacity=" + payload?.capacity : ""}${
      payload?.city ? "&city=" + payload?.city : ""
    }${payload?.boat_type ? "&type=" + payload?.boat_type.toLowerCase() : ""}${
      payload?.date ? "&date=" + payload?.date : ""}
    `,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function single_boat_data_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.single_boat_data,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function update_boat(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.update_boat,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data", //multipart/form-data
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function user_details(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.user_details,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function user_updateUser(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.user_updateUser,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function user_phone_verify_initiate_API(token) {
  return axios.get(
    API.baseUrls[API.currentEnv] + API.authUrls.user_phone_verify_initiate,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function user_verified_number_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.user_verified_number,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function boat_booking_list_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.boat_booking_list,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function boat_booking_detail_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.boat_booking_detail,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function payment_initiate_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.initiate_payment,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function start_trip(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.start_trip,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function end_trip(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.end_trip,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function cancel_trip(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.cancel_trip,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function get_notification_API(token) {
  return axios.get(
    API.baseUrls[API.currentEnv] + API.authUrls.get_notification,

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function updateNotificationsSettings_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.updateNotificationsSettings,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function add_boat_type_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.add_boat_type,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function add_boat_service_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.add_boat_service,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function booking_details_for_payment_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.booking_details_for_payment,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function transaction_details_API(token,data) {
  return axios.post(API.baseUrls[API.currentEnv] + API.authUrls.TransactionDetail, 
    data,
    {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function payment_cash_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.payment,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function complete_payment_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.complete_payment,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function boat_rating_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.boat_rating,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function boat_booking_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.boat_booking,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function boat_booking_slots_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.boat_booking_slots,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function chat_person_list_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.chat_person_list,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function chat_status_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.chat_status,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function address_gov_API(data) {
  // console.log("address_gov_API data", data);
  const apiUrl = `https://apina.address.gov.sa/NationalAddress/v3.1/address/address-geocode?language=E&format=JSON&lat=${data?.latitude}&long=${data?.longitude}&api_key=5c79e409db0b49a3b6d5f8595e853ec5`;

  // console.log("apiUrl", apiUrl);

  return axios.get(apiUrl);
}

export function city_list_API(token, data) {
  return axios.get(
    API.baseUrls[API.currentEnv] + API.authUrls.city_list,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function booking_status_detail_API() {
  return axios.get(
    API.baseUrls[API.currentEnv] + API.authUrls.booking_status_detail,
    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function previous_booking_status_detail_API() {
  return axios.get(
    API.baseUrls[API.currentEnv] + API.authUrls.previous_booking_status_detail,
    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function marine_city_list_API() {
  return axios.get(
    API.baseUrls[API.currentEnv] + API.authUrls.get_boat_marinecity,
    // data,
    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function get_all_review_API(token,data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.all_review,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function recent_activity_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.recent_activity,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function chat_history_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.chat_history,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

// console.log(API.baseUrls[API.currentEnv] + API.authUrls.user_bookedBoat_list)

export function user_bookedBoat_list_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.user_bookedBoat_list,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function user_bookedBoat_detail_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.user_bookedBoat_detail,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function update_transaction_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.update_transaction,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
export function cancel_payment(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.cancel_payment,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
export function create_pay(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.create_charge,
    data,
    {
      headers: {        
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function create_refund(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.refund,
    data,
    {
      headers: {        
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
export function refund_details(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.refund_details,
    data,
    {
      headers: {        
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
export function online_status_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.isOnline,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function update_boat_documents_API(token, data) {
  return axios.post(
    API.baseUrls[API.currentEnv] + API.authUrls.update_document,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

// export function update_transaction_API(token, data) {
//   // var myHeaders = new Headers();
//   // myHeaders.append("Authorization", `Bearer ${token}`);
//   var requestOptions = {
//     method: 'PUT',
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//     body: JSON.stringify(data),
//     // redirect: 'follow',
//   };
//   return(
//   fetch("https://oceanhub.herokuapp.com/update_transaction", requestOptions)
//     .then(response => response.text())
//     .then(result => console.log(result))
//     .catch(error => console.log('error', error))
//     )
// }
