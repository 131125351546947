import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import { RootNavigator } from "./Navigation/root-navigator";
import { PersistGate } from "redux-persist/integration/react";
import useStorage from "./hooks/useStorage";
import FetchData from "./Component/FetchData";
// import { Helmet } from "react-helmet";
import { ThemeProvider, createTheme } from "@material-ui/core";
// import { ThemeProvider, createTheme } from "@material-ui/core";

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    // fontweight: '400'
  },
});

const App = () => {
  const [loading, setLoading] = useState(true);
  console.log("loading", loading);
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastContainer />
            {/* {useStorage()} */}
            {/* {loading ? (
              <FetchData loading={loading} setLoading={setLoading} />
            ) : ( */}
            <RootNavigator
            // token={tokenData}
            // calculateTime={calculateTime}
            // user_id={userId}
            />
            {/* )} */}
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
