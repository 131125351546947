// import react from 'react';
import {Twemoji} from 'react-emoji-render';
import './countryCodeJson.css'

export const countryCodeJson = [
  {
    name: {
      en: "Afghanistan",
      ru: "Афганистан",
      pl: "Afganistan",
      ua: "Афганістан",
      cz: "Afghánistán",
      by: "",
      pt: "Afeganistão",
      es: "Afganistán",
    },
    countryName: "Afghanistan",
    dial_code: "+93",
    code: "AF",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇫</Twemoji>,
  },
  {
    name: {
      en: "Albania",
      ru: "Албания",
      pl: "Albania",
      ua: "Албанія",
      cz: "Albánie",
      by: "",
      pt: "Albânia",
      es: "Albania",
    },
    countryName: "Albania",
    dial_code: "+355",
    code: "AL",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇱</Twemoji>,
  },
  {
    name: {
      en: "Algeria",
      ru: "Алжир",
      pl: "Algieria",
      ua: "Алжир",
      cz: "Alžírsko",
      by: "",
      pt: "Argélia",
      es: "Argelia",
    },
    countryName: "Algeria",
    dial_code: "+213",
    code: "DZ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇩🇿</Twemoji>,
  },
  {
    name: {
      en: "American Samoa",
      ru: "Американское Самоа",
      pl: "Samoa Amerykańskie",
      ua: "Американське Самоа",
      cz: "Americká Samoa",
      by: "",
      pt: "Samoa",
      es: "Samoa Americana",
    },
    dial_code: "+1684",
    code: "AS",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇸</Twemoji>,
  },
  {
    name: {
      en: "Andorra",
      ru: "Андорра",
      pl: "Andora",
      ua: "Андорра",
      cz: "Andorra",
      by: "",
      pt: "Andorra",
      es: "Andorra",
    },
    dial_code: "+376",
    code: "AD",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇩</Twemoji>,
  },
  {
    name: {
      en: "Angola",
      ru: "Ангола",
      pl: "Angola",
      ua: "Ангола",
      cz: "Angola",
      by: "",
      pt: "Angola",
      es: "Angola",
    },
    dial_code: "+244",
    code: "AO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇴</Twemoji>,
  },
  {
    name: {
      en: "Anguilla",
      ru: "Ангилья",
      pl: "Anguilla",
      ua: "Ангілья",
      cz: "Anguilla",
      by: "",
      pt: "Anguila",
      es: "Anguila",
    },
    dial_code: "+1264",
    code: "AI",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇮</Twemoji>,
  },
  {
    name: {
      en: "Antarctica",
      ru: "Антарктида",
      pl: "Antarktyda",
      ua: "Антарктида",
      cz: "Antarktida",
      by: "",
      pt: "Antarctica",
      es: "Antártida",
    },
    dial_code: "+672",
    code: "AQ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇶</Twemoji>,
  },
  {
    name: {
      en: "Antigua and Barbuda",
      ru: "Антигуа и Барбуда",
      pl: "Antigua i Barbuda",
      ua: "Антигуа та Барбуда",
      cz: "Antigua a Barbuda",
      by: "",
      pt: "Antiga e Barbuda",
      es: "Antigua y Barbuda",
    },
    dial_code: "+1268",
    code: "AG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇬</Twemoji>,
  },
  {
    name: {
      en: "Argentina",
      ru: "Аргентина",
      pl: "Argentyna",
      ua: "Аргентина",
      cz: "Argentina",
      by: "",
      pt: "Argentina",
      es: "Argentina",
    },
    dial_code: "+54",
    code: "AR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇷</Twemoji>,
  },
  {
    name: {
      en: "Armenia",
      ru: "Армения",
      pl: "Armenia",
      ua: "Вірменія",
      cz: "Arménie",
      by: "",
      pt: "Arménia",
      es: "Armenia",
    },
    dial_code: "+374",
    code: "AM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇲</Twemoji>,
  },
  {
    name: {
      en: "Aruba",
      ru: "Аруба",
      pl: "Aruba",
      ua: "Аруба",
      cz: "Aruba",
      by: "",
      pt: "Aruba",
      es: "Aruba",
    },
    dial_code: "+297",
    code: "AW",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇼</Twemoji>,
  },
  {
    name: {
      en: "Australia",
      ru: "Австралия",
      pl: "Australia",
      ua: "Австралія",
      cz: "Austrálie",
      by: "",
      pt: "Austrália",
      es: "Australia",
    },
    dial_code: "+61",
    code: "AU",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇺</Twemoji>,
  },
  {
    name: {
      en: "Austria",
      ru: "Австрия",
      pl: "Austria",
      ua: "Австрія",
      cz: "Rakousko",
      by: "",
      pt: "Áustria",
      es: "Austria",
    },
    dial_code: "+43",
    code: "AT",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇹</Twemoji>,
  },
  {
    name: {
      en: "Azerbaijan",
      ru: "Азербайджан",
      pl: "Azerbejdżan",
      ua: "Азербайджан",
      cz: "Ázerbajdžán",
      by: "",
      pt: "Azerbaijão",
      es: "Azerbaiyán",
    },
    dial_code: "+994",
    code: "AZ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇿</Twemoji>,
  },
  {
    name: {
      en: "Bahamas",
      ru: "Багамские Острова",
      pl: "Bahamy",
      ua: "Багамські острови",
      cz: "Bahamy",
      by: "",
      pt: "Baamas",
      es: "Bahamas",
    },
    dial_code: "+1242",
    code: "BS",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇸</Twemoji>,
  },
  {
    name: {
      en: "Bahrain",
      ru: "Бахрейн",
      pl: "Bahrajn",
      ua: "Бахрейн",
      cz: "Bahrajn",
      by: "",
      pt: "Barém",
      es: "Bahrein",
    },
    dial_code: "+973",
    code: "BH",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇭</Twemoji>,
  },
  {
    name: {
      en: "Bangladesh",
      ru: "Бангладеш",
      pl: "Bangladesz",
      ua: "Бангладеш",
      cz: "Bangladéš",
      by: "",
      pt: "Bangladeche",
      es: "Bangladesh",
    },
    dial_code: "+880",
    code: "BD",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇩</Twemoji>,
  },
  {
    name: {
      en: "Barbados",
      ru: "Барбадос",
      pl: "Barbados",
      ua: "Барбадос",
      cz: "Barbados",
      by: "",
      pt: "Barbados",
      es: "Barbados",
    },
    dial_code: "+1246",
    code: "BB",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇧</Twemoji>,
  },
  {
    name: {
      en: "Belarus",
      ru: "Белоруссия",
      pl: "Białoruś",
      ua: "Білорусь",
      cz: "Bělorusko",
      by: "",
      pt: "Bielorrússia",
      es: "Bielorrusia",
    },
    dial_code: "+375",
    code: "BY",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇾</Twemoji>,
  },
  {
    name: {
      en: "Belgium",
      ru: "Бельгия",
      pl: "Belgia",
      ua: "Бельгія",
      cz: "Belgie",
      by: "",
      pt: "Bélgica",
      es: "Bélgica",
    },
    dial_code: "+32",
    code: "BE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇪</Twemoji>,
  },
  {
    name: {
      en: "Belize",
      ru: "Белиз",
      pl: "Belize",
      ua: "Беліз",
      cz: "Belize",
      by: "",
      pt: "Belize",
      es: "Belice",
    },
    dial_code: "+501",
    code: "BZ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇿</Twemoji>,
  },
  {
    name: {
      en: "Benin",
      ru: "Бенин",
      pl: "Benin",
      ua: "Бенін",
      cz: "Benin",
      by: "",
      pt: "Benim",
      es: "Benín",
    },
    dial_code: "+229",
    code: "BJ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇯</Twemoji>,
  },
  {
    name: {
      en: "Bermuda",
      ru: "Бермуды",
      pl: "Bermudy",
      ua: "Бермуди",
      cz: "Bermudy",
      by: "",
      pt: "Bermudas",
      es: "Bermudas",
    },
    dial_code: "+1441",
    code: "BM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇲</Twemoji>,
  },
  {
    name: {
      en: "Bhutan",
      ru: "Бутан",
      pl: "Bhutan",
      ua: "Бутан",
      cz: "Bhútán",
      by: "",
      pt: "Butão",
      es: "Bután",
    },
    dial_code: "+975",
    code: "BT",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇹</Twemoji>,
  },
  {
    name: {
      en: "Bolivia",
      ru: "Боливия",
      pl: "Boliwia",
      ua: "Болівія",
      cz: "Bolívie",
      by: "",
      pt: "Bolívia",
      es: "Bolivia",
    },
    dial_code: "+591",
    code: "BO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇴</Twemoji>,
  },
  {
    name: {
      en: "Bosnia and Herzegovina",
      ru: "Босния и Герцеговина",
      pl: "Bośnia i Hercegowina",
      ua: "Боснія і Герцеговина",
      cz: "Bosna a Hercegovina",
      by: "",
      pt: "Bósnia e Herzegovina",
      es: "Bosnia y Herzegovina",
    },
    dial_code: "+387",
    code: "BA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇦</Twemoji>,
  },
  {
    name: {
      en: "Botswana",
      ru: "Ботсвана",
      pl: "Botswana",
      ua: "Ботсвана",
      cz: "Botswana",
      by: "",
      pt: "Botsuana",
      es: "Botsuana",
    },
    dial_code: "+267",
    code: "BW",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇼</Twemoji>,
  },
  {
    name: {
      en: "Brazil",
      ru: "Бразилия",
      pl: "Brazylia",
      ua: "Бразилія",
      cz: "Brazílie",
      by: "",
      pt: "Brasil",
      es: "Brasil",
    },
    dial_code: "+55",
    code: "BR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇷</Twemoji>,
  },
  {
    name: {
      en: "British Indian Ocean Territory",
      ru: "Британская территория Индийского океана",
      pl: "Brytyjskie Terytorium Oceanu Indyjskiego",
      ua: "Британська територія в Індійському океані",
      cz: "Britské území v Indickém oceánu",
      by: "",
      pt: "Território Britânico do Oceano Índico",
      es: "Territorio Británico del Océano Índico",
    },
    dial_code: "+246",
    code: "IO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇮🇴</Twemoji>,
  },
  {
    name: {
      en: "Brunei Darussalam",
      ru: "Бруней-Даруссалам",
      pl: "Brunei Darussalam",
      ua: "Бруней-Даруссалам",
      cz: "Brunej Darussalam",
      by: "",
      pt: "Brunei",
      es: "Brunei Darussalam",
    },
    dial_code: "+673",
    code: "BN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇳</Twemoji>,
  },
  {
    name: {
      en: "Bulgaria",
      ru: "Болгария",
      pl: "Bułgaria",
      ua: "Болгарія",
      cz: "Bulharsko",
      by: "",
      pt: "Bulgária",
      es: "Bulgaria",
    },
    dial_code: "+359",
    code: "BG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇬</Twemoji>,
  },
  {
    name: {
      en: "Burkina Faso",
      ru: "Буркина-Фасо",
      pl: "Burkina Faso",
      ua: "Буркіна-Фасо",
      cz: "Burkina Faso",
      by: "",
      pt: "Burquina Faso",
      es: "Burkina Faso",
    },
    dial_code: "+226",
    code: "BF",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇫</Twemoji>,
  },
  {
    name: {
      en: "Burundi",
      ru: "Бурунди",
      pl: "Burundi",
      ua: "Бурунді",
      cz: "Burundi",
      by: "",
      pt: "Burúndi",
      es: "Burundi",
    },
    dial_code: "+257",
    code: "BI",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇮</Twemoji>,
  },
  {
    name: {
      en: "Cambodia",
      ru: "Камбоджа",
      pl: "Kambodża",
      ua: "Камбоджа",
      cz: "Kambodža",
      by: "",
      pt: "Camboja",
      es: "Camboya",
    },
    dial_code: "+855",
    code: "KH",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇰🇭</Twemoji>,
  },
  {
    name: {
      en: "Cameroon",
      ru: "Камерун",
      pl: "Kamerun",
      ua: "Камерун",
      cz: "Kamerun",
      by: "",
      pt: "Camarões",
      es: "Camerún",
    },
    dial_code: "+237",
    code: "CM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇲</Twemoji>,
  },
  {
    name: {
      en: "Canada",
      ru: "Канада",
      pl: "Kanada",
      ua: "Канада",
      cz: "Kanada",
      by: "",
      pt: "Canadá",
      es: "Canadá",
    },
    dial_code: "+1",
    code: "CA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇦</Twemoji>,
  },
  {
    name: {
      en: "Cape Verde",
      ru: "Кабо-Верде",
      pl: "Wyspy Zielonego Przylądka",
      ua: "Кабо-Верде",
      cz: "Kapverdy",
      by: "",
      pt: "Cabo Verde",
      es: "Cabo Verde",
    },
    dial_code: "+238",
    code: "CV",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇻</Twemoji>,
  },
  {
    name: {
      en: "Cayman Islands",
      ru: "Каймановы острова",
      pl: "Kajmany",
      ua: "Кайманові острови",
      cz: "Kajmanské ostrovy",
      by: "",
      pt: "Ilhas Caimão",
      es: "Islas Caimán",
    },
    dial_code: "+345",
    code: "KY",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇰🇾</Twemoji>,
  },
  {
    name: {
      en: "Central African Republic",
      ru: "Центрально-Африканская Республика",
      pl: "Republika Środkowoafrykańska",
      ua: "Центральноафриканська Республіка",
      cz: "Středoafrická republika",
      by: "",
      pt: "República Centro-Africana",
      es: "República Centroafricana",
    },
    dial_code: "+236",
    code: "CF",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇫</Twemoji>,
  },
  {
    name: {
      en: "Chad",
      ru: "Чад",
      pl: "Czad",
      ua: "Чад",
      cz: "Čad",
      by: "",
      pt: "Chade",
      es: "Chad",
    },
    dial_code: "+235",
    code: "TD",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇩</Twemoji>,
  },
  {
    name: {
      en: "Chile",
      ru: "Чили",
      pl: "Chile",
      ua: "Чилі",
      cz: "Chile",
      by: "",
      pt: "Chile",
      es: "Chile",
    },
    dial_code: "+56",
    code: "CL",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇱</Twemoji>,
  },
  {
    name: {
      en: "China",
      ru: "Китай",
      pl: "Chiny",
      ua: "Китай",
      cz: "Čína",
      by: "",
      pt: "China",
      es: "China",
    },
    dial_code: "+86",
    code: "CN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇳</Twemoji>,
  },
  {
    name: {
      en: "Christmas Island",
      ru: "Остров Рождества",
      pl: "Wyspa Bożego Narodzenia",
      ua: "Острів Різдва",
      cz: "Vánoční ostrov",
      by: "",
      pt: "Ilha do Natal",
      es: "Isla de Navidad",
    },
    dial_code: "+61",
    code: "CX",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇽</Twemoji>,
  },
  {
    name: {
      en: "Cocos (Keeling) Islands",
      ru: "Кокосовые (Килинг) острова",
      pl: "Wyspy Kokosowe (Keelinga)",
      ua: "Кокосові (Кілінг) острови",
      cz: "Kokosové (Keelingovy) ostrovy",
      by: "",
      pt: "Ilhas dos Cocos",
      es: "Islas Cocos (Keeling)",
    },
    dial_code: "+61",
    code: "CC",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇨</Twemoji>,
  },
  {
    name: {
      en: "Colombia",
      ru: "Колумбия",
      pl: "Kolumbia",
      ua: "Колумбія",
      cz: "Kolumbie",
      by: "",
      pt: "Colômbia",
      es: "Colombia",
    },
    dial_code: "+57",
    code: "CO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇴</Twemoji>,
  },
  {
    name: {
      en: "Comoros",
      ru: "Коморские острова",
      pl: "Komory",
      ua: "Коморські острови",
      cz: "Komory",
      by: "",
      pt: "Comores",
      es: "Comoras",
    },
    dial_code: "+269",
    code: "KM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇰🇲</Twemoji>,
  },
  {
    name: {
      en: "Congo",
      ru: "Конго",
      pl: "Kongo",
      ua: "Конго",
      cz: "Kongo",
      by: "",
      pt: "Congo-Brazzaville",
      es: "Congo",
    },
    dial_code: "+242",
    code: "CG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇬</Twemoji>,
  },
  {
    name: {
      en: "Congo, The Democratic Republic of the Congo",
      ru: "Конго, Демократическая Республика Конго",
      pl: "Kongo, Demokratyczna Republika Konga",
      ua: "Конго, Демократична Республіка Конго",
      cz: "Kongo, Demokratická republika Kongo",
      by: "",
      pt: "Congo-Kinshasa",
      es: "Congo, La República Democrática del Congo",
    },
    dial_code: "+243",
    code: "CD",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇩</Twemoji>,
  },
  {
    name: {
      en: "Cook Islands",
      ru: "Острова Кука",
      pl: "Wyspy Cooka",
      ua: "Острови Кука",
      cz: "Cookovy ostrovy",
      by: "",
      pt: "Ilhas Cook",
      es: "Islas Cook",
    },
    dial_code: "+682",
    code: "CK",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇰</Twemoji>,
  },
  {
    name: {
      en: "Costa Rica",
      ru: "Коста-Рика",
      pl: "Kostaryka",
      ua: "Коста-Ріка",
      cz: "Kostarika",
      by: "",
      pt: "Costa Rica",
      es: "Costa Rica",
    },
    dial_code: "+506",
    code: "CR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇷</Twemoji>,
  },
  {
    name: {
      en: "Cote d'Ivoire",
      ru: "Кот-д'Ивуар",
      pl: "Wybrzeże Kości Słoniowej",
      ua: "Кот-д'Івуар",
      cz: "Pobřeží slonoviny",
      by: "",
      pt: "Costa do Marfim",
      es: "Costa de Marfil",
    },
    dial_code: "+225",
    code: "CI",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇮</Twemoji>,
  },
  {
    name: {
      en: "Croatia",
      ru: "Хорватия",
      pl: "Chorwacja",
      ua: "Хорватія",
      cz: "Chorvatsko",
      by: "",
      pt: "Croácia",
      es: "Croacia",
    },
    dial_code: "+385",
    code: "HR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇭🇷</Twemoji>,
  },
  {
    name: {
      en: "Cuba",
      ru: "Куба",
      pl: "Kuba",
      ua: "Куба",
      cz: "Kuba",
      by: "",
      pt: "Cuba",
      es: "Cuba",
    },
    dial_code: "+53",
    code: "CU",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇺</Twemoji>,
  },
  {
    name: {
      en: "Cyprus",
      ru: "Кипр",
      pl: "Cypr",
      ua: "Кіпр",
      cz: "Kypr",
      by: "",
      pt: "Chipre",
      es: "Chipre",
    },
    dial_code: "+537",
    code: "CY",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇾</Twemoji>,
  },
  {
    name: {
      en: "Czech Republic",
      ru: "Чехия",
      pl: "Republika Czeska",
      ua: "Чеська Республіка",
      cz: "Česká republika",
      by: "",
      pt: "República Checa",
      es: "República Checa",
    },
    dial_code: "+420",
    code: "CZ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇿</Twemoji>,
  },
  {
    name: {
      en: "Denmark",
      ru: "Дания",
      pl: "Dania",
      ua: "Данія",
      cz: "Dánsko",
      by: "",
      pt: "Dinamarca",
      es: "Dinamarca",
    },
    dial_code: "+45",
    code: "DK",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇩🇰</Twemoji>,
  },
  {
    name: {
      en: "Djibouti",
      ru: "Джибути",
      pl: "Dżibuti",
      ua: "Джибуті",
      cz: "Džibuti",
      by: "",
      pt: "Jibuti",
      es: "Yibuti",
    },
    dial_code: "+253",
    code: "DJ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇩🇯</Twemoji>,
  },
  {
    name: {
      en: "Dominica",
      ru: "Доминика",
      pl: "Dominika",
      ua: "Домініка",
      cz: "Dominika",
      by: "",
      pt: "Domínica",
      es: "Dominica",
    },
    dial_code: "+1767",
    code: "DM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇩🇲</Twemoji>,
  },
  {
    name: {
      en: "Dominican Republic",
      ru: "Доминиканская Респблика",
      pl: "Republika Dominikany",
      ua: "Домініканська республіка",
      cz: "Dominikánská republika",
      by: "",
      pt: "República Dominicana",
      es: "República Dominicana",
    },
    dial_code: "+1849",
    code: "DO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇩🇴</Twemoji>,
  },
  {
    name: {
      en: "Ecuador",
      ru: "Эквадор",
      pl: "Ekwador",
      ua: "Еквадор",
      cz: "Ekvádor",
      by: "",
      pt: "Equador",
      es: "Ecuador",
    },
    dial_code: "+593",
    code: "EC",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇪🇨</Twemoji>,
  },
  {
    name: {
      en: "Egypt",
      ru: "Египет",
      pl: "Egipt",
      ua: "Єгипет",
      cz: "Egypt",
      by: "",
      pt: "Egipto",
      es: "Egipto",
    },
    dial_code: "+20",
    code: "EG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇪🇬</Twemoji>,
  },
  {
    name: {
      en: "El Salvador",
      ru: "Сальвадор",
      pl: "Salwador",
      ua: "Сальвадор",
      cz: "El Salvador",
      by: "",
      pt: "Salvador",
      es: "El Salvador",
    },
    dial_code: "+503",
    code: "SV",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇻</Twemoji>,
  },
  {
    name: {
      en: "Equatorial Guinea",
      ru: "Экваториальная Гвинея",
      pl: "Gwinea Równikowa",
      ua: "Екваторіальна Гвінея",
      cz: "Rovníková Guinea",
      by: "",
      pt: "Guiné Equatorial",
      es: "Guinea Ecuatorial",
    },
    dial_code: "+240",
    code: "GQ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇶</Twemoji>,
  },
  {
    name: {
      en: "Eritrea",
      ru: "Эритрея",
      pl: "Erytrea",
      ua: "Еритрея",
      cz: "Eritrea",
      by: "",
      pt: "Eritreia",
      es: "Eritrea",
    },
    dial_code: "+291",
    code: "ER",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇪🇷</Twemoji>,
  },
  {
    name: {
      en: "Estonia",
      ru: "Эстония",
      pl: "Estonia",
      ua: "Естонія",
      cz: "Estonsko",
      by: "",
      pt: "Estónia",
      es: "Estonia",
    },
    dial_code: "+372",
    code: "EE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇪🇪</Twemoji>,
  },
  {
    name: {
      en: "Ethiopia",
      ru: "Эфиопия",
      pl: "Etiopia",
      ua: "Ефіопія",
      cz: "Etiopie",
      by: "",
      pt: "Etiópia",
      es: "Etiopía",
    },
    dial_code: "+251",
    code: "ET",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇪🇹</Twemoji>,
  },
  {
    name: {
      en: "Falkland Islands (Malvinas)",
      ru: "Фолклендские острова (Malvinas)",
      pl: "Falklandy (Malwiny)",
      ua: "Фолклендські острови (Мальвінські острови)",
      cz: "Falklandské ostrovy (Malvíny)",
      by: "",
      pt: "Ilhas Falkland",
      es: "Islas Malvinas (Falkland Islands)",
    },
    dial_code: "+500",
    code: "FK",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇫🇰</Twemoji>,
  },
  {
    name: {
      en: "Faroe Islands",
      ru: "Фарерские острова",
      pl: "Wyspy Owcze",
      ua: "Фарерські острови",
      cz: "Faerské ostrovy",
      by: "",
      pt: "Faroé",
      es: "Islas Feroe",
    },
    dial_code: "+298",
    code: "FO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇫🇴</Twemoji>,
  },
  {
    name: {
      en: "Fiji",
      ru: "Фиджи",
      pl: "Fidżi",
      ua: "Фіджі",
      cz: "Fidži",
      by: "",
      pt: "Fiji",
      es: "Fiyi",
    },
    dial_code: "+679",
    code: "FJ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇫🇯</Twemoji>,
  },
  {
    name: {
      en: "Finland",
      ru: "Финляндия",
      pl: "Finlandia",
      ua: "Фінляндія",
      cz: "Finsko",
      by: "",
      pt: "Finlândia",
      es: "Finlandia",
    },
    dial_code: "+358",
    code: "FI",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇫🇮</Twemoji>,
  },
  {
    name: {
      en: "France",
      ru: "Франция",
      pl: "Francja",
      ua: "Франція",
      cz: "Francie",
      by: "",
      pt: "França",
      es: "Francia",
    },
    dial_code: "+33",
    code: "FR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇫🇷</Twemoji>,
  },
  {
    name: {
      en: "French Guiana",
      ru: "Французская Гвиана",
      pl: "Gujana Francuska",
      ua: "Французька Гвіана",
      cz: "Francouzská Guyana",
      by: "",
      pt: "Guiana Francesa",
      es: "Guayana Francesa",
    },
    dial_code: "+594",
    code: "GF",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇫</Twemoji>,
  },
  {
    name: {
      en: "French Polynesia",
      ru: "Французская Полинезия",
      pl: "Polinezja Francuska",
      ua: "Французька Полінезія",
      cz: "Francouzská Polynésie",
      by: "",
      pt: "Polinésia Francesa",
      es: "Polinesia Francesa",
    },
    dial_code: "+689",
    code: "PF",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇫</Twemoji>,
  },
  {
    name: {
      en: "Gabon",
      ru: "Габон",
      pl: "Gabon",
      ua: "Габон",
      cz: "Gabon",
      by: "",
      pt: "Gabão",
      es: "Gabón",
    },
    dial_code: "+241",
    code: "GA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇦</Twemoji>,
  },
  {
    name: {
      en: "Gambia",
      ru: "Гамбия",
      pl: "Gambia",
      ua: "Гамбія",
      cz: "Gambie",
      by: "",
      pt: "Gâmbia",
      es: "Gambia",
    },
    dial_code: "+220",
    code: "GM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇲</Twemoji>,
  },
  {
    name: {
      en: "Georgia",
      ru: "Грузия",
      pl: "Gruzja",
      ua: "Грузія",
      cz: "Gruzie",
      by: "",
      pt: "Geórgia",
      es: "Georgia",
    },
    dial_code: "+995",
    code: "GE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇪</Twemoji>,
  },
  {
    name: {
      en: "Germany",
      ru: "Германия",
      pl: "Niemcy",
      ua: "Німеччина",
      cz: "Německo",
      by: "",
      pt: "Alemanha",
      es: "Alemania",
    },
    dial_code: "+49",
    code: "DE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇩🇪</Twemoji>,
  },
  {
    name: {
      en: "Ghana",
      ru: "Гана",
      pl: "Ghana",
      ua: "Гана",
      cz: "Ghana",
      by: "",
      pt: "Gana",
      es: "Ghana",
    },
    dial_code: "+233",
    code: "GH",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇭</Twemoji>,
  },
  {
    name: {
      en: "Gibraltar",
      ru: "Гибралтар",
      pl: "Gibraltar",
      ua: "Гібралтар",
      cz: "Gibraltar",
      by: "",
      pt: "Gibraltar",
      es: "Gibraltar",
    },
    dial_code: "+350",
    code: "GI",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇮</Twemoji>,
  },
  {
    name: {
      en: "Greece",
      ru: "Греция",
      pl: "Grecja",
      ua: "Греція",
      cz: "Řecko",
      by: "",
      pt: "Grécia",
      es: "Grecia",
    },
    dial_code: "+30",
    code: "GR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇷</Twemoji>,
  },
  {
    name: {
      en: "Greenland",
      ru: "Гренландия",
      pl: "Grenlandia",
      ua: "Гренландія",
      cz: "Grenlandia",
      by: "",
      pt: "Gronelândia",
      es: "Groenlandia",
    },
    dial_code: "+299",
    code: "GL",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇱</Twemoji>,
  },
  {
    name: {
      en: "Grenada",
      ru: "Гренада",
      pl: "Grenada",
      ua: "Гренада",
      cz: "Grenada",
      by: "",
      pt: "Granada",
      es: "Granada",
    },
    dial_code: "+1473",
    code: "GD",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇩</Twemoji>,
  },
  {
    name: {
      en: "Guadeloupe",
      ru: "Гваделупа",
      pl: "Gwadelupa",
      ua: "Гваделупа",
      cz: "Guadeloupe",
      by: "",
      pt: "Guadalupe",
      es: "Guadalupe",
    },
    dial_code: "+590",
    code: "GP",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇵</Twemoji>,
  },
  {
    name: {
      en: "Guam",
      ru: "Гуам",
      pl: "Guam",
      ua: "Гуам",
      cz: "Guam",
      by: "",
      pt: "Guame",
      es: "Guam",
    },
    dial_code: "+1671",
    code: "GU",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇺</Twemoji>,
  },
  {
    name: {
      en: "Guatemala",
      ru: "Гватемала",
      pl: "Gwatemala",
      ua: "Гватемала",
      cz: "Guatemala",
      by: "",
      pt: "Guatemala",
      es: "Guatemala",
    },
    dial_code: "+502",
    code: "GT",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇹</Twemoji>,
  },
  {
    name: {
      en: "Guernsey",
      ru: "Гернси",
      pl: "Guernsey",
      ua: "Гернсі",
      cz: "Guernsey",
      by: "",
      pt: "Guernsey",
      es: "Guernsey",
    },
    dial_code: "+44",
    code: "GG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇬</Twemoji>,
  },
  {
    name: {
      en: "Guinea",
      ru: "Гвинея",
      pl: "Gwinea",
      ua: "Гвінея",
      cz: "Guinea",
      by: "",
      pt: "Guiné",
      es: "Guinea",
    },
    dial_code: "+224",
    code: "GN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇳</Twemoji>,
  },
  {
    name: {
      en: "Guinea-Bissau",
      ru: "Гвинея-Бисау",
      pl: "Gwinea Bissau",
      ua: "Гвінея-Бісау",
      cz: "Guinea-Bissau",
      by: "",
      pt: "Guiné-Bissau",
      es: "Guinea-Bissau",
    },
    dial_code: "+245",
    code: "GW",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇼</Twemoji>,
  },
  {
    name: {
      en: "Guyana",
      ru: "Гайана",
      pl: "Gujana",
      ua: "Гайана",
      cz: "Guyana",
      by: "",
      pt: "Guiana",
      es: "Guyana",
    },
    dial_code: "+595",
    code: "GY",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇾</Twemoji>,
  },
  {
    name: {
      en: "Haiti",
      ru: "Гаити",
      pl: "Haiti",
      ua: "Гаїті",
      cz: "Haiti",
      by: "",
      pt: "Haiti",
      es: "Haití",
    },
    dial_code: "+509",
    code: "HT",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇭🇹</Twemoji>,
  },
  {
    name: {
      en: "Holy See (Vatican City State)",
      ru: "Святой Престол (Vatican City State)",
      pl: "Stolica Apostolska (Państwo Watykańskie)",
      ua: "Святий Престол (Ватикан)",
      cz: "Svatý stolec (Vatikánský městský stát)",
      by: "",
      pt: "Vaticano",
      es: "Santa Sede (Estado de la Ciudad del Vaticano)",
    },
    dial_code: "+379",
    code: "VA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇻🇦</Twemoji>,
  },
  {
    name: {
      en: "Honduras",
      ru: "Гондурас",
      pl: "Honduras",
      ua: "Гондурас",
      cz: "Honduras",
      by: "",
      pt: "Honduras",
      es: "Honduras",
    },
    dial_code: "+504",
    code: "HN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇭🇳</Twemoji>,
  },
  {
    name: {
      en: "Hong Kong",
      ru: "Гонконг",
      pl: "Hongkong",
      ua: "Гонконг",
      cz: "Hongkong",
      by: "",
      pt: "Hong Kong",
      es: "Hong Kong",
    },
    dial_code: "+852",
    code: "HK",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇭🇰</Twemoji>,
  },
  {
    name: {
      en: "Hungary",
      ru: "Венгрия",
      pl: "Węgry",
      ua: "Угорщина",
      cz: "Maďarsko",
      by: "",
      pt: "Hungria",
      es: "Hungría",
    },
    dial_code: "+36",
    code: "HU",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇭🇺</Twemoji>,
  },
  {
    name: {
      en: "Iceland",
      ru: "Исландия",
      pl: "Islandia",
      ua: "Ісландія",
      cz: "Island",
      by: "",
      pt: "Islândia",
      es: "Islandia",
    },
    dial_code: "+354",
    code: "IS",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇮🇸</Twemoji>,
  },
  {
    name: {
      en: "India",
      ru: "Индия",
      pl: "Indie",
      ua: "Індія",
      cz: "Indie",
      by: "",
      pt: "Índia",
      es: "India",
    },
    dial_code: "+91",
    code: "IN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇮🇳</Twemoji>,
  },
  {
    name: {
      en: "Indonesia",
      ru: "Индонезия",
      pl: "Indonezja",
      ua: "Індонезія",
      cz: "Indonésie",
      by: "",
      pt: "Indonésia",
      es: "Indonesia",
    },
    dial_code: "+62",
    code: "ID",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇮🇩</Twemoji>,
  },
  {
    name: {
      en: "Iran, Islamic Republic of Iran",
      ru: "Иран, Исламская Республика Иран",
      pl: "Iran, Islamska Republika Iranu",
      ua: "Іран, Ісламська Республіка Іран",
      cz: "Írán, Íránská islámská republika",
      by: "",
      pt: "Irão",
      es: "Irán, República Islámica de Irán",
    },
    dial_code: "+98",
    code: "IR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇮🇷</Twemoji>,
  },
  {
    name: {
      en: "Iraq",
      ru: "Ирак",
      pl: "Irak",
      ua: "Ірак",
      cz: "Irák",
      by: "",
      pt: "Iraque",
      es: "Irak",
    },
    dial_code: "+964",
    code: "IQ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇮🇶</Twemoji>,
  },
  {
    name: {
      en: "Ireland",
      ru: "Ирландия",
      pl: "Irlandia",
      ua: "Ірландія",
      cz: "Irsko",
      by: "",
      pt: "Irlanda",
      es: "Irlanda",
    },
    dial_code: "+353",
    code: "IE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇮🇪</Twemoji>,
  },
  {
    name: {
      en: "Isle of Man",
      ru: "Остров Мэн",
      pl: "Wyspa Man",
      ua: "Острів Мен",
      cz: "Isle of Man",
      by: "",
      pt: "Ilha de Man",
      es: "Isla de Man",
    },
    dial_code: "+44",
    code: "IM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇮🇲</Twemoji>,
  },
  {
    name: {
      en: "Israel",
      ru: "Израиль",
      pl: "Izrael",
      ua: "Ізраїль",
      cz: "Izrael",
      by: "",
      pt: "Israel",
      es: "Israel",
    },
    dial_code: "+972",
    code: "IL",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇮🇱</Twemoji>,
  },
  {
    name: {
      en: "Italy",
      ru: "Италия",
      pl: "Włochy",
      ua: "Італія",
      cz: "Itálie",
      by: "",
      pt: "Itália",
      es: "Italia",
    },
    dial_code: "+39",
    code: "IT",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇮🇹</Twemoji>,
  },
  {
    name: {
      en: "Jamaica",
      ru: "Ямайка",
      pl: "Jamajka",
      ua: "Ямайка",
      cz: "Jamaica",
      by: "",
      pt: "Jamaica",
      es: "Jamaica",
    },
    dial_code: "+1876",
    code: "JM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇯🇲</Twemoji>,
  },
  {
    name: {
      en: "Japan",
      ru: "Япония",
      pl: "Japonia",
      ua: "Японія",
      cz: "Japonsko",
      by: "",
      pt: "Japão",
      es: "Japón",
    },
    dial_code: "+81",
    code: "JP",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇯🇵</Twemoji>,
  },
  {
    name: {
      en: "Jersey",
      ru: "Джерси",
      pl: "Jersey",
      ua: "Джерсі",
      cz: "Trikot",
      by: "",
      pt: "Jersey",
      es: "Jersey",
    },
    dial_code: "+44",
    code: "JE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇯🇪</Twemoji>,
  },
  {
    name: {
      en: "Jordan",
      ru: "Иордания",
      pl: "Jordania",
      ua: "Йорданія",
      cz: "Jordán",
      by: "",
      pt: "Jordânia",
      es: "Jordania",
    },
    dial_code: "+962",
    code: "JO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇯🇴</Twemoji>,
  },
  {
    name: {
      en: "Kazakhstan",
      ru: "Казахстан",
      pl: "Kazachstan",
      ua: "Казахстан",
      cz: "Kazachstán",
      by: "",
      pt: "Cazaquistão",
      es: "Kazajstán",
    },
    dial_code: "+77",
    code: "KZ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇰🇿</Twemoji>,
  },
  {
    name: {
      en: "Kenya",
      ru: "Кения",
      pl: "Kenia",
      ua: "Кенія",
      cz: "Keňa",
      by: "",
      pt: "Quénia",
      es: "Kenia",
    },
    dial_code: "+254",
    code: "KE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇰🇪</Twemoji>,
  },
  {
    name: {
      en: "Kiribati",
      ru: "Кирибати",
      pl: "Kiribati",
      ua: "Kiribati",
      cz: "Kiribati",
      by: "",
      pt: "Quiribáti",
      es: "Kiribati",
    },
    dial_code: "+686",
    code: "KI",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇰🇮</Twemoji>,
  },
  {
    name: {
      en: "Korea, Democratic People's Republic of Korea",
      ru: "Корея, Корейская Народно-Демократическая Республика",
      pl: "Korea, Koreańska Republika Ludowo-Demokratyczna",
      ua: "Корея, Корейська Народно-Демократична Республіка",
      cz: "Korea, Korejská lidově demokratická republika",
      by: "",
      pt: "Coreia do Norte",
      es: "Corea, República Popular Democrática de Corea",
    },
    dial_code: "+850",
    code: "KP",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇰🇵</Twemoji>,
  },
  {
    name: {
      en: "Korea, Republic of Korea",
      ru: "Республика Корея",
      pl: "Republika Korei",
      ua: "Республіка Корея",
      cz: "Korea, Korejská republika",
      by: "",
      pt: "Coreia do Sul",
      es: "Corea, República de Corea",
    },
    dial_code: "+82",
    code: "KR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇰🇷</Twemoji>,
  },
  {
    name: {
      en: "Kuwait",
      ru: "Кувейт",
      pl: "Kuwejt",
      ua: "Кувейт",
      cz: "Kuvajt",
      by: "",
      pt: "Kuwait",
      es: "Kuwait",
    },
    dial_code: "+965",
    code: "KW",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇰🇼</Twemoji>,
  },
  {
    name: {
      en: "Kyrgyzstan",
      ru: "Kyrgyzstan",
      pl: "Kirgistan",
      ua: "Киргизстан",
      cz: "Kyrgyzstán",
      by: "",
      pt: "Quirguizistão",
      es: "Kirguistán",
    },
    dial_code: "+996",
    code: "KG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇰🇬</Twemoji>,
  },
  {
    name: {
      en: "Lao People's Democratic Republic",
      ru: "Лаосская Народно-Демократическая Республика",
      pl: "Laotańska Republika Ludowo-Demokratyczna",
      ua: "Лаоська Народно-Демократична Республіка",
      cz: "Laoská lidově demokratická republika",
      by: "",
      pt: "Laos",
      es: "República Democrática Popular de Laos",
    },
    dial_code: "+856",
    code: "LA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇱🇦</Twemoji>,
  },
  {
    name: {
      en: "Latvia",
      ru: "Латвия",
      pl: "Łotwa",
      ua: "Латвія",
      cz: "Lotyšsko",
      by: "",
      pt: "Letónia",
      es: "Letonia",
    },
    dial_code: "+371",
    code: "LV",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇱🇻</Twemoji>,
  },
  {
    name: {
      en: "Lebanon",
      ru: "Ливан",
      pl: "Liban",
      ua: "Ліван",
      cz: "Libanon",
      by: "",
      pt: "Líbano",
      es: "Líbano",
    },
    dial_code: "+961",
    code: "LB",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇱🇧</Twemoji>,
  },
  {
    name: {
      en: "Lesotho",
      ru: "Лесото",
      pl: "Lesoto",
      ua: "Лесото",
      cz: "Lesotho",
      by: "",
      pt: "Lesoto",
      es: "Lesotho",
    },
    dial_code: "+266",
    code: "LS",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇱🇸</Twemoji>,
  },
  {
    name: {
      en: "Liberia",
      ru: "Либерия",
      pl: "Liberia",
      ua: "Ліберія",
      cz: "Libérie",
      by: "",
      pt: "Libéria",
      es: "Liberia",
    },
    dial_code: "+231",
    code: "LR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇱🇷</Twemoji>,
  },
  {
    name: {
      en: "Libyan Arab Jamahiriya",
      ru: "Ливийская Арабская Джамахирия",
      pl: "Libijska Arabska Dżamahirija",
      ua: "Лівійська Арабська Джамахірія",
      cz: "Libyjský Arab Jamahiriya",
      by: "",
      pt: "Líbia",
      es: "Jamahiriya Árabe Libia",
    },
    dial_code: "+218",
    code: "LY",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇱🇾</Twemoji>,
  },
  {
    name: {
      en: "Liechtenstein",
      ru: "Лихтенштейн",
      pl: "Liechtenstein",
      ua: "Ліхтенштейн",
      cz: "Lichtenštejnsko",
      by: "",
      pt: "Listenstaine",
      es: "Liechtenstein",
    },
    dial_code: "+423",
    code: "LI",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇱🇮</Twemoji>,
  },
  {
    name: {
      en: "Lithuania",
      ru: "Литва",
      pl: "Litwa",
      ua: "Литва",
      cz: "Litva",
      by: "",
      pt: "Lituânia",
      es: "Lituania",
    },
    dial_code: "+370",
    code: "LT",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇱🇹</Twemoji>,
  },
  {
    name: {
      en: "Luxembourg",
      ru: "Люксембург",
      pl: "Luksemburg",
      ua: "Люксембург",
      cz: "Lucembursko",
      by: "",
      pt: "Luxemburgo",
      es: "Luxemburgo",
    },
    dial_code: "+352",
    code: "LU",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇱🇺</Twemoji>,
  },
  {
    name: {
      en: "Macao",
      ru: "Макао",
      pl: "Makao",
      ua: "Макао",
      cz: "Macao",
      by: "",
      pt: "Macau",
      es: "Macao",
    },
    dial_code: "+853",
    code: "MO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇴</Twemoji>,
  },
  {
    name: {
      en: "Macedonia, The Former Yugoslav Republic of Macedonia",
      ru: "Македония, Бывшая югославская Республика Македония",
      pl: "Macedonia, Była Jugosłowiańska Republika Macedonii",
      ua: "Македонія, Колишня Югославська Республіка Македонія",
      cz: "Makedonie, Bývalá jugoslávská republika Makedonie",
      by: "",
      pt: "Macedónia",
      es: "Macedonia, La ex República Yugoslava de Macedonia",
    },
    dial_code: "+389",
    code: "MK",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇰</Twemoji>,
  },
  {
    name: {
      en: "Madagascar",
      ru: "Мадагаскар",
      pl: "Madagaskar",
      ua: "Мадагаскар",
      cz: "Madagaskar",
      by: "",
      pt: "Madagáscar",
      es: "Madagascar",
    },
    dial_code: "+261",
    code: "MG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇬</Twemoji>,
  },
  {
    name: {
      en: "Malawi",
      ru: "Малави",
      pl: "Malawi",
      ua: "Малаві",
      cz: "Malawi",
      by: "",
      pt: "Malávi",
      es: "Malawi",
    },
    dial_code: "+265",
    code: "MW",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇼</Twemoji>,
  },
  {
    name: {
      en: "Malaysia",
      ru: "Малайзия",
      pl: "Malezja",
      ua: "Малайзія",
      cz: "Malajsie",
      by: "",
      pt: "Malásia",
      es: "Malasia",
    },
    dial_code: "+60",
    code: "MY",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇾</Twemoji>,
  },
  {
    name: {
      en: "Maldives",
      ru: "Мальдивы",
      pl: "Malediwy",
      ua: "Мальдіви",
      cz: "Maledivy",
      by: "",
      pt: "Maldivas",
      es: "Maldivas",
    },
    dial_code: "+960",
    code: "MV",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇻</Twemoji>,
  },
  {
    name: {
      en: "Mali",
      ru: "Мали",
      pl: "Mali",
      ua: "Малі",
      cz: "Mali",
      by: "",
      pt: "Mali",
      es: "Malí",
    },
    dial_code: "+223",
    code: "ML",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇱</Twemoji>,
  },
  {
    name: {
      en: "Malta",
      ru: "Мальта",
      pl: "Malta",
      ua: "Мальта",
      cz: "Malta",
      by: "",
      pt: "Malta",
      es: "Malta",
    },
    dial_code: "+356",
    code: "MT",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇹</Twemoji>,
  },
  {
    name: {
      en: "Marshall Islands",
      ru: "Маршалловы острова",
      pl: "Wyspy Marshalla",
      ua: "Маршаллові острови",
      cz: "Marshallovy ostrovy",
      by: "",
      pt: "Ilhas Marshall",
      es: "Islas Marshall",
    },
    dial_code: "+692",
    code: "MH",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇭</Twemoji>,
  },
  {
    name: {
      en: "Martinique",
      ru: "Мартиника",
      pl: "Martynika",
      ua: "Мартініка",
      cz: "Martinik",
      by: "",
      pt: "Martinica",
      es: "Martinica",
    },
    dial_code: "+596",
    code: "MQ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇶</Twemoji>,
  },
  {
    name: {
      en: "Mauritania",
      ru: "Мавритания",
      pl: "Mauretania",
      ua: "Мавританія",
      cz: "Mauretánie",
      by: "",
      pt: "Mauritânia",
      es: "Mauritania",
    },
    dial_code: "+222",
    code: "MR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇷</Twemoji>,
  },
  {
    name: {
      en: "Mauritius",
      ru: "Маврикий",
      pl: "Mauritius",
      ua: "Маврикій",
      cz: "Mauricius",
      by: "",
      pt: "Maurícia",
      es: "Mauricio",
    },
    dial_code: "+230",
    code: "MU",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇺</Twemoji>,
  },
  {
    name: {
      en: "Mayotte",
      ru: "Майотта",
      pl: "Majotta",
      ua: "Майотта",
      cz: "Mayotte",
      by: "",
      pt: "Mayotte",
      es: "Mayotte",
    },
    dial_code: "+262",
    code: "YT",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇾🇹</Twemoji>,
  },
  {
    name: {
      en: "Mexico",
      ru: "Мексика",
      pl: "Meksyk",
      ua: "Мексика",
      cz: "Mexiko",
      by: "",
      pt: "México",
      es: "México",
    },
    dial_code: "+52",
    code: "MX",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇽</Twemoji>,
  },
  {
    name: {
      en: "Micronesia, Federated States of Micronesia",
      ru: "Микронезия, Федеративные Штаты Микронезии",
      pl: "Mikronezja, Sfederowane Stany Mikronezji",
      ua: "Мікронезія, Федеративні Штати Мікронезії",
      cz: "Mikronésie, Federativní státy Mikronésie",
      by: "",
      pt: "Micronésia",
      es: "Micronesia, Estados Federados de Micronesia",
    },
    dial_code: "+691",
    code: "FM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇫🇲</Twemoji>,
  },
  {
    name: {
      en: "Moldova, Republic of Moldova",
      ru: "Молдова, Республика",
      pl: "Mołdawia, Republika Mołdawii",
      ua: "Молдова, Республіка Молдова",
      cz: "Moldavsko, Moldavská republika",
      by: "",
      pt: "Moldávia",
      es: "Moldavia, República de Moldova",
    },
    dial_code: "+373",
    code: "MD",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇩</Twemoji>,
  },
  {
    name: {
      en: "Monaco",
      ru: "Монако",
      pl: "Monako",
      ua: "Монако",
      cz: "Monako",
      by: "",
      pt: "Mónaco",
      es: "Mónaco",
    },
    dial_code: "+377",
    code: "MC",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇨</Twemoji>,
  },
  {
    name: {
      en: "Mongolia",
      ru: "Монголия",
      pl: "Mongolia",
      ua: "Монголія",
      cz: "Mongolsko",
      by: "",
      pt: "Mongólia",
      es: "Mongolia",
    },
    dial_code: "+976",
    code: "MN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇳</Twemoji>,
  },
  {
    name: {
      en: "Montenegro",
      ru: "Черногория",
      pl: "Czarnogóra",
      ua: "Чорногорія",
      cz: "Černá Hora",
      by: "",
      pt: "Montenegro",
      es: "Montenegro",
    },
    dial_code: "+382",
    code: "ME",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇪</Twemoji>,
  },
  {
    name: {
      en: "Montserrat",
      ru: "Монтсеррат",
      pl: "Montserrat",
      ua: "Монтсеррат",
      cz: "Montserrat",
      by: "",
      pt: "Monserrate",
      es: "Montserrat",
    },
    dial_code: "+1664",
    code: "MS",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇸</Twemoji>,
  },
  {
    name: {
      en: "Morocco",
      ru: "Марокко",
      pl: "Maroko",
      ua: "Марокко",
      cz: "Maroko",
      by: "",
      pt: "Marrocos",
      es: "Marruecos",
    },
    dial_code: "+212",
    code: "MA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇦</Twemoji>,
  },
  {
    name: {
      en: "Mozambique",
      ru: "Мозамбик",
      pl: "Mozambik",
      ua: "Мозамбік",
      cz: "Mosambik",
      by: "",
      pt: "Moçambique",
      es: "Mozambique",
    },
    dial_code: "+258",
    code: "MZ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇿</Twemoji>,
  },
  {
    name: {
      en: "Myanmar",
      ru: "Мьянма",
      pl: "Myanmar",
      ua: "М'янма",
      cz: "Myanmar",
      by: "",
      pt: "Birmânia",
      es: "Myanmar",
    },
    dial_code: "+95",
    code: "MM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇲</Twemoji>,
  },
  {
    name: {
      en: "Namibia",
      ru: "Намибия",
      pl: "Namibia",
      ua: "Намібія",
      cz: "Namibie",
      by: "",
      pt: "Namíbia",
      es: "Namibia",
    },
    dial_code: "+264",
    code: "NA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇦</Twemoji>,
  },
  {
    name: {
      en: "Nauru",
      ru: "Науру",
      pl: "Nauru",
      ua: "Науру",
      cz: "Nauru",
      by: "",
      pt: "Nauru",
      es: "Nauru",
    },
    dial_code: "+674",
    code: "NR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇷</Twemoji>,
  },
  {
    name: {
      en: "Nepal",
      ru: "Непал",
      pl: "Nepal",
      ua: "Непал",
      cz: "Nepál",
      by: "",
      pt: "Nepal",
      es: "Nepal",
    },
    dial_code: "+977",
    code: "NP",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇵</Twemoji>,
  },
  {
    name: {
      en: "Netherlands",
      ru: "Нидерланды",
      pl: "Holandia",
      ua: "Нідерланди",
      cz: "Holandsko",
      by: "",
      pt: "Países Baixos",
      es: "Países Bajos",
    },
    dial_code: "+31",
    code: "NL",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇱</Twemoji>,
  },
  {
    name: {
      en: "Netherlands Antilles",
      ru: "Нидерландские Антильские острова",
      pl: "Antyle Holenderskie",
      ua: "Нідерландські Антильські острови",
      cz: "Nizozemské Antily",
      by: "",
      pt: "Antilhas Neerlandesas",
      es: "Antillas Neerlandesas",
    },
    dial_code: "+599",
    code: "AN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇳</Twemoji>,
  },
  {
    name: {
      en: "New Caledonia",
      ru: "Новая Каледония",
      pl: "Nowa Kaledonia",
      ua: "Нова Каледонія",
      cz: "Nová Kaledonie",
      by: "",
      pt: "Nova Caledónia",
      es: "Nueva Caledonia",
    },
    dial_code: "+687",
    code: "NC",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇨</Twemoji>,
  },
  {
    name: {
      en: "New Zealand",
      ru: "Новая Зеландия",
      pl: "Nowa Zelandia",
      ua: "Нова Зеландія",
      cz: "Nový Zéland",
      by: "",
      pt: "Nova Zelândia",
      es: "Nueva Zelanda",
    },
    dial_code: "+64",
    code: "NZ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇿</Twemoji>,
  },
  {
    name: {
      en: "Nicaragua",
      ru: "Никарагуа",
      pl: "Nikaragua",
      ua: "Нікарагуа",
      cz: "Nikaragua",
      by: "",
      pt: "Nicarágua",
      es: "Nicaragua",
    },
    dial_code: "+505",
    code: "NI",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇮</Twemoji>,
  },
  {
    name: {
      en: "Niger",
      ru: "Нигер",
      pl: "Niger",
      ua: "Нігер",
      cz: "Niger",
      by: "",
      pt: "Níger",
      es: "Níger",
    },
    dial_code: "+227",
    code: "NE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇪</Twemoji>,
  },
  {
    name: {
      en: "Nigeria",
      ru: "Нигерия",
      pl: "Nigeria",
      ua: "Нігерія",
      cz: "Nigérie",
      by: "",
      pt: "Nigéria",
      es: "Nigeria",
    },
    dial_code: "+234",
    code: "NG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇬</Twemoji>,
  },
  {
    name: {
      en: "Niue",
      ru: "Ниуэ",
      pl: "Niue",
      ua: "Ніуе",
      cz: "Niue",
      by: "",
      pt: "Niue",
      es: "Niue",
    },
    dial_code: "+683",
    code: "NU",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇺</Twemoji>,
  },
  {
    name: {
      en: "Norfolk Island",
      ru: "Остров Норфолк",
      pl: "Wyspa Norfolk",
      ua: "Острів Норфолк",
      cz: "Ostrov Norfolk",
      by: "",
      pt: "Ilha Norfolk",
      es: "Isla de Norfolk",
    },
    dial_code: "+672",
    code: "NF",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇫</Twemoji>,
  },
  {
    name: {
      en: "Northern Mariana Islands",
      ru: "Северные Марианские острова",
      pl: "Mariany Północne",
      ua: "Північні Маріанські острови",
      cz: "Severní Mariany",
      by: "",
      pt: "Marianas do Norte",
      es: "Islas Marianas del Norte",
    },
    dial_code: "+1670",
    code: "MP",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇵</Twemoji>,
  },
  {
    name: {
      en: "Norway",
      ru: "Норвегия",
      pl: "Norwegia",
      ua: "Норвегія",
      cz: "Norsko",
      by: "",
      pt: "Noruega",
      es: "Noruega",
    },
    dial_code: "+47",
    code: "NO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇳🇴</Twemoji>,
  },
  {
    name: {
      en: "Oman",
      ru: "Оман",
      pl: "Oman",
      ua: "Оман",
      cz: "Omán",
      by: "",
      pt: "Omã",
      es: "Omán",
    },
    dial_code: "+968",
    code: "OM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇴🇲</Twemoji>,
  },
  {
    name: {
      en: "Pakistan",
      ru: "Пакистан",
      pl: "Pakistan",
      ua: "Пакистан",
      cz: "Pákistán",
      by: "",
      pt: "Paquistão",
      es: "Pakistán",
    },
    dial_code: "+92",
    code: "PK",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇰</Twemoji>,
  },
  {
    name: {
      en: "Palau",
      ru: "Палау",
      pl: "Palau",
      ua: "Палау",
      cz: "Palau",
      by: "",
      pt: "Palau",
      es: "Palau",
    },
    dial_code: "+680",
    code: "PW",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇼</Twemoji>,
  },
  {
    name: {
      en: "Palestinian Territory, Occupied",
      ru: "Палестинская Территория, Оккупированная",
      pl: "Teretorium Paleństynskie, Okupowane",
      ua: "Палестинська територія, окупована",
      cz: "Palestinské území, obsazené",
      by: "",
      pt: "Território Palestino, Ocupado",
      es: "Territorio Palestino Ocupado",
    },
    dial_code: "+970",
    code: "PS",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇸</Twemoji>,
  },
  {
    name: {
      en: "Panama",
      ru: "Панама",
      pl: "Panama",
      ua: "Панама",
      cz: "Panama",
      by: "",
      pt: "Panamá",
      es: "Panamá",
    },
    dial_code: "+507",
    code: "PA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇦</Twemoji>,
  },
  {
    name: {
      en: "Papua New Guinea",
      ru: "Папуа - Новая Гвинея",
      pl: "Papua Nowa Gwinea",
      ua: "Папуа-Нова Гвінея",
      cz: "Papua-Nová Guinea",
      by: "",
      pt: "Papua-Nova Guiné",
      es: "Papúa Nueva Guinea",
    },
    dial_code: "+675",
    code: "PG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇬</Twemoji>,
  },
  {
    name: {
      en: "Paraguay",
      ru: "Парагвай",
      pl: "Paragwaj",
      ua: "Парагвай",
      cz: "Paraguay",
      by: "",
      pt: "Paraguai",
      es: "Paraguay",
    },
    dial_code: "+595",
    code: "PY",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇾</Twemoji>,
  },
  {
    name: {
      en: "Peru",
      ru: "Перу",
      pl: "Peru",
      ua: "Перу",
      cz: "Peru",
      by: "",
      pt: "Peru",
      es: "Perú",
    },
    dial_code: "+51",
    code: "PE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇪</Twemoji>,
  },
  {
    name: {
      en: "Philippines",
      ru: "Филиппины",
      pl: "Filipiny",
      ua: "Філіппіни",
      cz: "Filipíny",
      by: "",
      pt: "Filipinas",
      es: "Filipinas",
    },
    dial_code: "+63",
    code: "PH",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇭</Twemoji>,
  },
  {
    name: {
      en: "Pitcairn",
      ru: "Питкэрн",
      pl: "Pitcairn",
      ua: "Піткерн",
      cz: "Pitcairn",
      by: "",
      pt: "Pitcairn",
      es: "Pitcairn",
    },
    dial_code: "+872",
    code: "PN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇳</Twemoji>,
  },
  {
    name: {
      en: "Poland",
      ru: "Польша",
      pl: "Polska",
      ua: "Польща",
      cz: "Polsko",
      by: "",
      pt: "Polónia",
      es: "Polonia",
    },
    dial_code: "+48",
    code: "PL",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇱</Twemoji>,
  },
  {
    name: {
      en: "Portugal",
      ru: "Португалия",
      pl: "Portugalia",
      ua: "Португалія",
      cz: "Portugalsko",
      by: "",
      pt: "Portugal",
      es: "Portugal",
    },
    dial_code: "+351",
    code: "PT",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇹</Twemoji>,
  },
  {
    name: {
      en: "Puerto Rico",
      ru: "Пуэрто-Рико",
      pl: "Portoryko",
      ua: "Пуерто Ріко",
      cz: "Portoriko",
      by: "",
      pt: "Porto Rico",
      es: "Puerto Rico",
    },
    dial_code: "+1939",
    code: "PR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇷</Twemoji>,
  },
  {
    name: {
      en: "Qatar",
      ru: "Катар",
      pl: "Katar",
      ua: "Катар",
      cz: "Katar",
      by: "",
      pt: "Catar",
      es: "Qatar",
    },
    dial_code: "+974",
    code: "QA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇶🇦</Twemoji>,
  },
  {
    name: {
      en: "Romania",
      ru: "Румыния",
      pl: "Rumunia",
      ua: "Румунія",
      cz: "Rumunsko",
      by: "",
      pt: "Roménia",
      es: "Rumanía",
    },
    dial_code: "+40",
    code: "RO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇷🇴</Twemoji>,
  },
  {
    name: {
      en: "Russia",
      ru: "Россия",
      pl: "Rosja",
      ua: "Росія",
      cz: "Rusko",
      by: "",
      pt: "Rússia",
      es: "Rusia",
    },
    dial_code: "+7",
    code: "RU",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇷🇺</Twemoji>,
  },
  {
    name: {
      en: "Rwanda",
      ru: "Руанда",
      pl: "Rwanda",
      ua: "Руанда",
      cz: "Rwanda",
      by: "",
      pt: "Ruanda",
      es: "Ruanda",
    },
    dial_code: "+250",
    code: "RW",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇷🇼</Twemoji>,
  },
  {
    name: {
      en: "Réunion",
      ru: "Реюньон",
      pl: "Réunion",
      ua: "Реюньйон",
      cz: "Réunion",
      by: "",
      pt: "Reunião",
      es: "Reunión",
    },
    dial_code: "+262",
    code: "RE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇷🇪</Twemoji>,
  },
  {
    name: {
      en: "Saint Barthélemy",
      ru: "Святой Варфоломей",
      pl: "Saint Barthelemy",
      ua: "Сен-Бартелемі",
      cz: "Svatý Bartoloměj",
      by: "",
      pt: "São Bartolomeu",
      es: "San Bartolomé",
    },
    dial_code: "+590",
    code: "BL",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇧🇱</Twemoji>,
  },
  {
    name: {
      en: "Saint Helena, Ascension and Tristan Da Cunha",
      ru: "Святой Елены, Вознесения и Тристан-да-Кунья",
      pl: "Święta Helena, Wniebowstąpienie i Tristan Da Cunha",
      ua: "Свята Олена, Вознесіння і Трістан Да Кунья",
      cz: "Svatá Helena, Nanebevstoupení a Tristan Da Cunha",
      by: "",
      pt: "Santa Helena",
      es: "Santa Elena, Ascensión y Tristán Da Cunha",
    },
    dial_code: "+290",
    code: "SH",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇭</Twemoji>,
  },
  {
    name: {
      en: "Saint Kitts and Nevis",
      ru: "Сент-Китс и Невис",
      pl: "Saint Kitts i Nevis",
      ua: "Сент-Кітс і Невіс",
      cz: "Svatý Kryštof a Nevis",
      by: "",
      pt: "São Cristóvão e Neves",
      es: "San Cristóbal y Nieves",
    },
    dial_code: "+1869",
    code: "KN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇰🇳</Twemoji>,
  },
  {
    name: {
      en: "Saint Lucia",
      ru: "Санкт-Люсия",
      pl: "święta Lucia",
      ua: "Сент-Люсія",
      cz: "Svatá Lucie",
      by: "",
      pt: "Santa Lúcia",
      es: "Santa Lucía",
    },
    dial_code: "+1758",
    code: "LC",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇱🇨</Twemoji>,
  },
  {
    name: {
      en: "Saint Martin",
      ru: "Сен-Мартен",
      pl: "święty Marcin",
      ua: "Святий Мартін",
      cz: "Svatý Martin",
      by: "",
      pt: "São Martinho",
      es: "San Martín",
    },
    dial_code: "+590",
    code: "MF",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇲🇫</Twemoji>,
  },
  {
    name: {
      en: "Saint Pierre and Miquelon",
      ru: "Сен-Пьер и Микелон",
      pl: "Saint-Pierre i Miquelon",
      ua: "Сен-П'єр і Мікелон",
      cz: "Svatý Pierre a Miquelon",
      by: "",
      pt: "São Pedro e Miquelon",
      es: "San Pedro y Miquelón",
    },
    dial_code: "+508",
    code: "PM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇵🇲</Twemoji>,
  },
  {
    name: {
      en: "Saint Vincent and the Grenadines",
      ru: "Святой Винсент и Гренадины",
      pl: "Saint Vincent i Grenadyny",
      ua: "Сент-Вінсент і Гренадини",
      cz: "Svatý Vincenc a Grenadiny",
      by: "",
      pt: "São Vicente e Granadinas",
      es: "San Vicente y las Granadinas",
    },
    dial_code: "+1784",
    code: "VC",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇻🇨</Twemoji>,
  },
  {
    name: {
      en: "Samoa",
      ru: "Самоа",
      pl: "Samoa",
      ua: "Самоа",
      cz: "Samoa",
      by: "",
      pt: "Samoa",
      es: "Samoa",
    },
    dial_code: "+685",
    code: "WS",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇼🇸</Twemoji>,
  },
  {
    name: {
      en: "San Marino",
      ru: "Сан-Марино",
      pl: "San Marino",
      ua: "Сан-Марино",
      cz: "San Marino",
      by: "",
      pt: "São Marinho",
      es: "San Marino",
    },
    dial_code: "+378",
    code: "SM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇲</Twemoji>,
  },
  {
    name: {
      en: "Sao Tome and Principe",
      ru: "Сан-Томе и Принсипи",
      pl: "Wyspy Świętego Tomasza i Książęca",
      ua: "Сан-Томе і Принсіпі",
      cz: "Svatý Tomáš a Princův ostrov",
      by: "",
      pt: "São Tomé e Príncipe",
      es: "Santo Tomé y Príncipe",
    },
    dial_code: "+239",
    code: "ST",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇹</Twemoji>,
  },
  {
    name: {
      en: "Saudi Arabia",
      ru: "Саудовская Аравия",
      pl: "Arabia Saudyjska",
      ua: "Саудівська Аравія",
      cz: "Saudská arábie",
      by: "",
      pt: "Arábia Saudita",
      es: "San Vicente y las Granadinas",
    },
    dial_code: "+966",
    code: "SA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇦</Twemoji>,
  },
  {
    name: {
      en: "Senegal",
      ru: "Сенегал",
      pl: "Senegal",
      ua: "Сенегал",
      cz: "Senegal",
      by: "",
      pt: "Senegal",
      es: "Senegal",
    },
    dial_code: "+221",
    code: "SN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇳</Twemoji>,
  },
  {
    name: {
      en: "Serbia",
      ru: "Сербия",
      pl: "Serbia",
      ua: "Сербія",
      cz: "Srbsko",
      by: "",
      pt: "Sérvia",
      es: "Serbia",
    },
    dial_code: "+381",
    code: "RS",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇷🇸</Twemoji>,
  },
  {
    name: {
      en: "Seychelles",
      ru: "Сейшельские острова",
      pl: "Seszele",
      ua: "Сейшельські острови",
      cz: "Seychely",
      by: "",
      pt: "Seicheles",
      es: "Seychelles",
    },
    dial_code: "+248",
    code: "SC",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇨</Twemoji>,
  },
  {
    name: {
      en: "Sierra Leone",
      ru: "Сьерра-Леоне",
      pl: "Sierra Leone",
      ua: "Сьєрра-Леоне",
      cz: "Sierra Leone",
      by: "",
      pt: "Serra Leoa",
      es: "Sierra Leona",
    },
    dial_code: "+232",
    code: "SL",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇱</Twemoji>,
  },
  {
    name: {
      en: "Singapore",
      ru: "Сингапур",
      pl: "Singapur",
      ua: "Сінгапур",
      cz: "Singapur",
      by: "",
      pt: "Singapura",
      es: "Singapur",
    },
    dial_code: "+65",
    code: "SG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇬</Twemoji>,
  },
  {
    name: {
      en: "Slovakia",
      ru: "Словакия",
      pl: "Słowacja",
      ua: "Словаччина",
      cz: "Slovensko",
      by: "",
      pt: "Eslováquia",
      es: "Eslovaquia",
    },
    dial_code: "+421",
    code: "SK",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇰</Twemoji>,
  },
  {
    name: {
      en: "Slovenia",
      ru: "Словения",
      pl: "Słowenia",
      ua: "Словенія",
      cz: "Slovinsko",
      by: "",
      pt: "Eslovénia",
      es: "Eslovenia",
    },
    dial_code: "+386",
    code: "SI",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇮</Twemoji>,
  },
  {
    name: {
      en: "Solomon Islands",
      ru: "Соломоновы острова",
      pl: "Wyspy Salomona",
      ua: "Соломонові острови",
      cz: "Solomonovy ostrovy",
      by: "",
      pt: "Ilhas Salomão",
      es: "Islas Salomón",
    },
    dial_code: "+677",
    code: "SB",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇧</Twemoji>,
  },
  {
    name: {
      en: "Somalia",
      ru: "Сомали",
      pl: "Somali",
      ua: "Сомалі",
      cz: "Somálsko",
      by: "",
      pt: "Somália",
      es: "Somalia",
    },
    dial_code: "+252",
    code: "SO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇴</Twemoji>,
  },
  {
    name: {
      en: "South Africa",
      ru: "Южная Африка",
      pl: "Afryka Południowa",
      ua: "Південна Африка",
      cz: "Jižní Afrika",
      by: "",
      pt: "África do Sul",
      es: "Sudáfrica",
    },
    dial_code: "+27",
    code: "ZA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇿🇦</Twemoji>,
  },
  {
    name: {
      en: "South Georgia and the South Sandwich Islands",
      ru: "Южная Георгия и Южные Сандвичевы острова",
      pl: "Georgia Południowa i Sandwich Południowy",
      ua: "Південна Джорджія та Південні Сандвічеві острови",
      cz: "Jižní Georgie a Jižní Sandwichovy ostrovy",
      by: "",
      pt: "Geórgia do Sul e Sandwich do Sul",
      es: "Georgia del Sur y las Islas Sandwich del Sur",
    },
    dial_code: "+500",
    code: "GS",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇸</Twemoji>,
  },
  {
    name: {
      en: "Spain",
      ru: "Испания",
      pl: "Hiszpania",
      ua: "Іспанія",
      cz: "Španělsko",
      by: "",
      pt: "Espanha",
      es: "España",
    },
    dial_code: "+34",
    code: "ES",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇪🇸</Twemoji>,
  },
  {
    name: {
      en: "Sri Lanka",
      ru: "Шри-Ланка",
      pl: "Sri Lanka",
      ua: "Шрі Ланка",
      cz: "Srí Lanka",
      by: "",
      pt: "Sri Lanca",
      es: "Sri Lanka",
    },
    dial_code: "+94",
    code: "LK",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇱🇰</Twemoji>,
  },
  {
    name: {
      en: "Sudan",
      ru: "Судан",
      pl: "Sudan",
      ua: "Судан",
      cz: "Súdán",
      by: "",
      pt: "Sudão",
      es: "Sudán",
    },
    dial_code: "+249",
    code: "SD",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇩</Twemoji>,
  },
  {
    name: {
      en: "Suriname",
      ru: "Суринам",
      pl: "Surinam",
      ua: "Суринам",
      cz: "Surinam",
      by: "",
      pt: "Suriname",
      es: "Surinam",
    },
    dial_code: "+597",
    code: "SR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇷</Twemoji>,
  },
  {
    name: {
      en: "Svalbard and Jan Mayen",
      ru: "Шпицберген и Ян Майен",
      pl: "Svalbard i Jan Mayen",
      ua: "Шпіцберген та Ян Маєн",
      cz: "Špicberky a Jan Mayen",
      by: "",
      pt: "Svalbard e Jan Mayen",
      es: "Svalbard y Jan Mayen",
    },
    dial_code: "+47",
    code: "SJ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇯</Twemoji>,
  },
  {
    name: {
      en: "Swaziland",
      ru: "Свазиленд",
      pl: "Suazi",
      ua: "Свазіленд",
      cz: "Svazijsko",
      by: "",
      pt: "Suazilândia",
      es: "Suazilandia",
    },
    dial_code: "+268",
    code: "SZ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇿</Twemoji>,
  },
  {
    name: {
      en: "Sweden",
      ru: "Швеция",
      pl: "Szwecja",
      ua: "Швеція",
      cz: "Švédsko",
      by: "",
      pt: "Suécia",
      es: "Suecia",
    },
    dial_code: "+46",
    code: "SE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇪</Twemoji>,
  },
  {
    name: {
      en: "Switzerland",
      ru: "Швейцария",
      pl: "Szwajcaria",
      ua: "Швейцарія",
      cz: "Švýcarsko",
      by: "",
      pt: "Suíça",
      es: "Suiza",
    },
    dial_code: "+41",
    code: "CH",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇨🇭</Twemoji>,
  },
  {
    name: {
      en: "Syrian Arab Republic",
      ru: "Сирийская Арабская Республика",
      pl: "Republika Syryjsko-Arabska",
      ua: "Сирійська Арабська Республіка",
      cz: "Syrská Arabská republika",
      by: "",
      pt: "Síria",
      es: "República Árabe Siria",
    },
    dial_code: "+963",
    code: "SY",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇸🇾</Twemoji>,
  },
  {
    name: {
      en: "Taiwan, Province of China",
      ru: "Тайвань, провинция Китая",
      pl: "Tajwan, prowincja Chin",
      ua: "Тайвань, провінція Китаю",
      cz: "Tchaj-wan, provincie Číny",
      by: "",
      pt: "Taiwan",
      es: "Taiwán, provincia de China",
    },
    dial_code: "+886",
    code: "TW",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇼</Twemoji>,
  },
  {
    name: {
      en: "Tajikistan",
      ru: "Таджикистан",
      pl: "Tadżykistan",
      ua: "Таджикистан",
      cz: "Tádžikistán",
      by: "",
      pt: "Tajiquistão",
      es: "Tayikistán",
    },
    dial_code: "+992",
    code: "TJ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇯</Twemoji>,
  },
  {
    name: {
      en: "Tanzania, United Republic of Tanzania",
      ru: "Танзания, Объединенная Республика Танзания",
      pl: "Tanzania, Republika Tanzanii",
      ua: "Танзанія, Об'єднана Республіка Танзанія",
      cz: "Tanzanie, Sjednocená republika Tanzanie",
      by: "",
      pt: "Tanzânia",
      es: "Tanzania, República Unida de Tanzania",
    },
    dial_code: "+255",
    code: "TZ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇿</Twemoji>,
  },
  {
    name: {
      en: "Thailand",
      ru: "Таиланд",
      pl: "Tajlandia",
      ua: "Таїланд",
      cz: "Thajsko",
      by: "",
      pt: "Tailândia",
      es: "Tailandia",
    },
    dial_code: "+66",
    code: "TH",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇭</Twemoji>,
  },
  {
    name: {
      en: "Timor-Leste",
      ru: "Восточный Тимор",
      pl: "Timor Wschodni",
      ua: "Восточний Тимор",
      cz: "Východní Timor",
      by: "",
      pt: "Timor Leste",
      es: "Timor-Leste",
    },
    dial_code: "+670",
    code: "TL",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇱</Twemoji>,
  },
  {
    name: {
      en: "Togo",
      ru: "Того",
      pl: "Togo",
      ua: "Того",
      cz: "Togo",
      by: "",
      pt: "Togo",
      es: "Togo",
    },
    dial_code: "+228",
    code: "TG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇬</Twemoji>,
  },
  {
    name: {
      en: "Tokelau",
      ru: "Токелау",
      pl: "Tokelau",
      ua: "Токелау",
      cz: "Tokelau",
      by: "",
      pt: "Tokelau",
      es: "Tokelau",
    },
    dial_code: "+690",
    code: "TK",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇰</Twemoji>,
  },
  {
    name: {
      en: "Tonga",
      ru: "Тонга",
      pl: "Tonga",
      ua: "Тонга",
      cz: "Tonga",
      by: "",
      pt: "Tonga",
      es: "Tonga",
    },
    dial_code: "+676",
    code: "TO",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇴</Twemoji>,
  },
  {
    name: {
      en: "Trinidad and Tobago",
      ru: "Тринидад и Тобаго",
      pl: "Trynidad i Tobago",
      ua: "Тринідад і Тобаго",
      cz: "Trinidad a Tobago",
      by: "",
      pt: "Trindade e Tobago",
      es: "Trinidad y Tobago",
    },
    dial_code: "+1868",
    code: "TT",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇹</Twemoji>,
  },
  {
    name: {
      en: "Tunisia",
      ru: "Тунис",
      pl: "Tunezja",
      ua: "Туніс",
      cz: "Tunisko",
      by: "",
      pt: "Tunísia",
      es: "Túnez",
    },
    dial_code: "+216",
    code: "TN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇳</Twemoji>,
  },
  {
    name: {
      en: "Turkey",
      ru: "Турция",
      pl: "Turcja",
      ua: "Туреччина",
      cz: "Turecko",
      by: "",
      pt: "Turquia",
      es: "Turquía",
    },
    dial_code: "+90",
    code: "TR",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇷</Twemoji>,
  },
  {
    name: {
      en: "Turkmenistan",
      ru: "Туркменистан",
      pl: "Turkmenia",
      ua: "Туркменістан",
      cz: "Turkmenistán",
      by: "",
      pt: "Turquemenistão",
      es: "Turkmenistán",
    },
    dial_code: "+993",
    code: "TM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇲</Twemoji>,
  },
  {
    name: {
      en: "Turks and Caicos Islands",
      ru: "Острова Теркс и Кайкос",
      pl: "Wyspy Turks i Caicos",
      ua: "Острови Теркс і Кайкос",
      cz: "ostrovy Turks a Cacois",
      by: "",
      pt: "Ilhas Turcas e Caicos",
      es: "Islas Turcas y Caicos",
    },
    dial_code: "+1649",
    code: "TC",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇨</Twemoji>,
  },
  {
    name: {
      en: "Tuvalu",
      ru: "Тувалу",
      pl: "Tuvalu",
      ua: "Тувалу",
      cz: "Tuvalu",
      by: "",
      pt: "Tuvalu",
      es: "Tuvalu",
    },
    dial_code: "+688",
    code: "TV",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇹🇻</Twemoji>,
  },
  {
    name: {
      en: "Uganda",
      ru: "Уганда",
      pl: "Uganda",
      ua: "Уганда",
      cz: "Uganda",
      by: "",
      pt: "Uganda",
      es: "Uganda",
    },
    dial_code: "+256",
    code: "UG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇺🇬</Twemoji>,
  },
  {
    name: {
      en: "Ukraine",
      ru: "Украина",
      pl: "Ukraina",
      ua: "Україна",
      cz: "Ukrajina",
      by: "",
      pt: "Ucrânia",
      es: "Ucrania",
    },
    dial_code: "+380",
    code: "UA",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇺🇦</Twemoji>,
  },
  {
    name: {
      en: "United Arab Emirates",
      ru: "Объединенные Арабские Эмираты",
      pl: "Zjednoczone Emiraty Arabskie",
      ua: "Об'єднані Арабські Емірати",
      cz: "Spojené arabské emiráty",
      by: "",
      pt: "Emiratos Árabes Unidos",
      es: "Emiratos Árabes Unidos",
    },
    dial_code: "+971",
    code: "AE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇪</Twemoji>,
  },
  {
    name: {
      en: "United Kingdom",
      ru: "Объединенное Королевство",
      pl: "Zjednoczone Królestwo",
      ua: "Об'єднане Королівство",
      cz: "Spojené království",
      by: "",
      pt: "Reino Unido",
      es: "Reino Unido",
    },
    dial_code: "+44",
    code: "GB",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇬🇧</Twemoji>,
  },
  {
    name: {
      en: "United States",
      ru: "Соединенные Штаты",
      pl: "Stany Zjednoczone",
      ua: "Сполучені Штати",
      cz: "Spojené státy",
      by: "",
      pt: "Estados Unidos",
      es: "Estados Unidos",
    },
    dial_code: "+1",
    code: "US",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇺🇸</Twemoji>,
  },
  {
    name: {
      en: "Uruguay",
      ru: "Уругвай",
      pl: "Urugwaj",
      ua: "Уругвай",
      cz: "Uruguay",
      by: "",
      pt: "Uruguai",
      es: "Uruguay",
    },
    dial_code: "+598",
    code: "UY",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇺🇾</Twemoji>,
  },
  {
    name: {
      en: "Uzbekistan",
      ru: "Узбекистан",
      pl: "Uzbekistan",
      ua: "Узбекистан",
      cz: "Uzbekistán",
      by: "",
      pt: "Usbequistão",
      es: "Uzbekistán",
    },
    dial_code: "+998",
    code: "UZ",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇺🇿</Twemoji>,
  },
  {
    name: {
      en: "Vanuatu",
      ru: "Вануату",
      pl: "Vanuatu",
      ua: "Вануату",
      cz: "Vanuatu",
      by: "",
      pt: "Vanuatu",
      es: "Vanuatu",
    },
    dial_code: "+678",
    code: "VU",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇻🇺</Twemoji>,
  },
  {
    name: {
      en: "Venezuela, Bolivarian Republic of Venezuela",
      ru: "Венесуэла, Боливарианская Республика Венесуэла",
      pl: "Wenezuela, Boliwariańska Republika Wenezueli",
      ua: "Венесуела, Республіка Венесуела",
      cz: "Venezuela, Bolívarská republika Venezuela",
      by: "",
      pt: "Venezuela",
      es: "Venezuela, República Bolivariana de Venezuela",
    },
    dial_code: "+58",
    code: "VE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇻🇪</Twemoji>,
  },
  {
    name: {
      en: "Viet Nam",
      ru: "Вьетнам",
      pl: "Wietnam",
      ua: "В'єтнам",
      cz: "Vietnam",
      by: "",
      pt: "Vietname",
      es: "Vietnam",
    },
    dial_code: "+84",
    code: "VN",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇻🇳</Twemoji>,
  },
  {
    name: {
      en: "Virgin Islands, British",
      ru: "Виргинские острова, Британские",
      pl: "Wyspy Dziewicze, Brytyjskie",
      ua: "Віргінські острови, Британські",
      cz: "Panenské ostrovy, Britové",
      by: "",
      pt: "Ilhas Virgens Britânicas",
      es: "Islas Vírgenes Británicas",
    },
    dial_code: "+1284",
    code: "VG",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇻🇬</Twemoji>,
  },
  {
    name: {
      en: "Virgin Islands, U.S.",
      ru: "Американские Виргинские острова",
      pl: "Wyspy Dziewicze Stanów Zjednoczonych",
      ua: "Віргінські острови, США",
      cz: "Panenské ostrovy, USA",
      by: "",
      pt: "Ilhas Virgens Americanas",
      es: "Islas Vírgenes, EE.UU.",
    },
    dial_code: "+1340",
    code: "VI",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇻🇮</Twemoji>,
  },
  {
    name: {
      en: "Wallis and Futuna",
      ru: "Уоллис и Футуна",
      pl: "Wallis i Futuna",
      ua: "Уолліс і Футуна",
      cz: "Wallis a Futuna",
      by: "",
      pt: "Wallis e Futuna",
      es: "Wallis y Futuna",
    },
    dial_code: "+681",
    code: "WF",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇼🇫</Twemoji>,
  },
  {
    name: {
      en: "Yemen",
      ru: "Йемен",
      pl: "Jemen",
      ua: "Ємен",
      cz: "Jemen",
      by: "",
      pt: "Iémen",
      es: "Yemen",
    },
    dial_code: "+967",
    code: "YE",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇾🇪</Twemoji>,
  },
  {
    name: {
      en: "Zambia",
      ru: "Замбия",
      pl: "Zambia",
      ua: "Замбія",
      cz: "Zambie",
      by: "",
      pt: "Zâmbia",
      es: "Zambia",
    },
    dial_code: "+260",
    code: "ZM",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇿🇲</Twemoji>,
  },
  {
    name: {
      en: "Zimbabwe",
      ru: "Зимбабве",
      pl: "Zimbabwe",
      ua: "Зімбабве",
      cz: "Zimbabwe",
      by: "",
      pt: "Zimbabué",
      es: "Zimbabue",
    },
    dial_code: "+263",
    code: "ZW",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇿🇼</Twemoji>,
  },
  {
    name: {
      en: "Åland Islands",
      ru: "Аландские острова",
      pl: "Wyspy Alandzkie",
      ua: "Аландські острови",
      cz: "Alandy",
      by: "",
      pt: "Ilhas Aland",
      es: "Islas Åland",
    },
    dial_code: "+358",
    code: "AX",
    flag: <Twemoji options={{ className: 'twemoji' }}>🇦🇽</Twemoji>,
  },
];
