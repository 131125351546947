import React from 'react'
import { makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../page-header/PageHeader';
import IMAGES from '../../Images';

const PageNotFound = () => {
    const class_name = useStyles({ min: 10, max: 30, unit: "px" });
    const auth = useSelector((state) => state?.auth);
    const navigate = useNavigate()

    
  const handle_navigation = (name) => {
    toast.dismiss();
    if (name === "Home") {
      navigate("/boatOwnerDashBoard");
    } else if(name === 'My Listings'){
      navigate('/myListings')
    } else {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  return (
    <>
    <PageHeader
      showLoginSignUp={auth?.AuthToken ? false : true}
      handle_navigation={handle_navigation}
      // presentPage={"Home"}
      link1={"Boat Offers"}
      link2={"Scuba Courses/Programs"}
      link3={"Scuba Diving Trips"}
      link4={auth?.tokenDecodeData?.user_type === "BOAT_OWNER"? "My Listings":null}
    />
    <div className={class_name.pageNotFoundTxtDiv}>
      <span className={class_name.logo}>
        <img src={IMAGES.APP_ICON} alt="" className='img-fluid w-50'/>
      </span>
      <span className={class_name.pageNotFoundTxt}>
      404 Page Not Found!
      </span>
    </div>
    </>
  )
}

export default PageNotFound

const useStyles = makeStyles((theme) => ({
    pageNotFoundTxtDiv: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
        backgroundColor: "#f6f6f6",
    },
    logo: {

    },
    pageNotFoundTxt: {
        fontSize: "clamp(20px,4vw,100px)",
        fontWeight: 'bold',
        color: "rgba(66, 70, 81, 0.87)",
    }
}))