import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "../Setting.css";
import { withStyles } from "@mui/styles";
import { Typography, makeStyles } from "@material-ui/core";
import {
  get_notification_API,
  updateNotificationsSettings_API,
} from "../../../../Service/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import { IoMdCheckmark } from "react-icons/io";

const CustomCheckbox = withStyles({
  root: {
    color: "#ffffff",
    "&$checked": {
      color: "#ffffff",
    },
  },
  checked: {},
})(Checkbox);

export const NotificationTab = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const auth = useSelector((state) => state?.auth);
  const [notifications_points, setNotifications_points] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [boat_notifications_point, setBoat_notifications_point] = useState([
    {
      id: 1,
      name: 'Your Boat Documents Verified',
      status: false
    },
    {
      id: 2,
      name: 'Your Boat Documents Not Verified',
      status: false
    },
    {
      id: 3,
      name: 'Your Boat Booked',
      status: false
    },
    {
      id: 4,
      name: 'Your Boat Blocked',
      status: false
    },
    {
      id: 5,
      name: 'Your Boat Verified',
      status: false
    },
    {
      id: 6,
      name: 'Your Boat Rejected',
      status: false
    },
  ])

  const [other_notifications_point, setOther_Notifications_point] = useState([
    {
      id: 1,
      name: 'User Blocked',
      status: false
    },
  ])

  const [trip_notifications_point, setTrip_notifications_point] = useState([
    {
      id: 1,
      name: 'Your Trip Started Successfully',
      status: false
    },
    {
      id: 2,
      name: 'Your Trip Ended Successfully',
      status: false
    },
    {
      id: 3,
      name: 'Your Trip Cancelled',
      status: false
    },
  ])

  // const [booking_notifications_points, setBooking_notifications_points] =
  //   useState([
  //     {
  //       id: 1,
  //       name: "A booking request is submitted on your offer",
  //       status: false,
  //       request_submitted: "request_submitted",
  //     },
  //     {
  //       id: 2,
  //       name: "Your boat booked successfully",
  //       status: false,
  //       request_cancelled: "request_submitted",
  //     },
  //     {
  //       id: 3,
  //       name: "Your booking request is accepted successfully",
  //       status: false,
  //       request_done: "request_submitted",
  //     },
  //     {
  //       id: 4,
  //       name: "Your booking request is rejected by the owner",
  //       status: false,
  //       request_rejected: "request_submitted",
  //     },
  //   ]);

  const [offer_notifications_points, setOffer_notifications_points] = useState([
    {
      id: 1,
      name: "Your offer is declined",
      status: false,
      offer_declined: "offer_declined",
    },
    {
      id: 2,
      name: "Your offer is accepted",
      status: false,
      offer_accepted: "offer_accepted",
    },
    {
      id: 3,
      name: "Your offer is under review",
      status: false,
      offer_under_review: "offer_under_review",
    },
  ]);

  const [
    transaction_notifications_points,
    setTransaction_notifications_points,
  ] = useState([
    // {
    //   id: 1,
    //   name: "a refund is requested",
    //   status: false,
    //   amount_refund_request: "amount_refund_request",
    // },
    {
      id: 1,
      name: "Payment amount is requested",
      status: false,
      amount_received: "amount_received",
    },
    {
      id: 2,
      name: "Payment amount is sent",
      status: false,
      amount_sent: "amount_sent",
    },
  ]);

  const [
    payment_notifications,
    setPayment_notifications
  ] = useState([
    {
      id: 1,
      name: "Payment amount is received",
      status: false,
      payment_received: "payment_received",
    },
  ]);

  const [
    boat_owner_trip_notifications,
    setBoat_owner_trip_notifications
  ] = useState([
    {
      id: 1,
      name: "Trip cancelled by customer",
      status: false,
      payment_received: "trip_cancelby_customer",
    },
  ]);

  useEffect(() => {
    setIsLoading(true);
    toast.dismiss();
    get_notification_API(auth?.AuthToken)
      .then((res) => {
        console.log("get notification res", res?.data);
        if (res?.data?.message === "Notifications settings") {
          setNotifications_points(res?.data?.data);

          //other notification
          const updated_other_notifications = [...other_notifications_point]
          updated_other_notifications[0].status = res?.data?.data?.Block_User
          setOther_Notifications_point(updated_other_notifications)

          //boat notifications
          const updated_boat_notifications = [...boat_notifications_point]
          updated_boat_notifications[0].status = res?.data?.data?.Boat_verify
          updated_boat_notifications[1].status = res?.data?.data?.boat_notverified
          updated_boat_notifications[2].status = res?.data?.data?.Boat_booking
          updated_boat_notifications[3].status = res?.data?.data?.Block_Boat
          updated_boat_notifications[4].status = res?.data?.data?.user_verify
          updated_boat_notifications[5].status = res?.data?.data?.user_not_verify
          setBoat_notifications_point(updated_boat_notifications)
          
          //boat notifications
          const updated_trip_notifications = [...trip_notifications_point]
          updated_trip_notifications[0].status = res?.data?.data?.Trip_Start
          updated_trip_notifications[1].status = res?.data?.data?.Trip_End          
          updated_trip_notifications[2].status = res?.data?.data?.Trip_cancel
          setTrip_notifications_point(updated_trip_notifications)


          // // updated_booking_notifications
          // const updated_booking_notifications = [
          //   ...booking_notifications_points,
          // ];
          // updated_booking_notifications[0].status =
          //   res?.data?.data?.Offer_Underreview? true : false;
          // updated_booking_notifications[1].status =
          //   res?.data?.data?.Boat_booking? true : false;
          // updated_booking_notifications[2].status =
          //   res?.data?.data?.Offer_accepted? true : false;
          // updated_booking_notifications[3].status =
          //   res?.data?.data?.Offer_declined? true : false;
          // setBooking_notifications_points(updated_booking_notifications);

          // updated_offer_notifications
          const updated_offer_notifications = [...offer_notifications_points];
          updated_offer_notifications[0].status =
            res?.data?.data?.Offer_declined? true : false;
          updated_offer_notifications[1].status =
            res?.data?.data?.Offer_accepted ? true : false;
          updated_offer_notifications[2].status =
            res?.data?.data?.Offer_Underreview? true : false;
          setOffer_notifications_points(updated_offer_notifications);

          // transaction_notifications_points
          const updated_transaction_notifications = [
            ...transaction_notifications_points,
          ];
          updated_transaction_notifications[0].status =
            res?.data?.data?.payment_request? true : false;
          updated_transaction_notifications[1].status =
            res?.data?.data?.payment_sent? true : false;
          // updated_transaction_notifications[2].status =
          //   res?.data?.data?.amount_sent === 1 ? true : false;
          setTransaction_notifications_points(
            updated_transaction_notifications
          );
          
          //payment notifications
          const updated_payment_notifications = [
            ...payment_notifications
          ]
          updated_payment_notifications[0].status =
          res?.data?.data?.payment_received? true : false;
          setPayment_notifications(updated_payment_notifications)

          //boat owner trip notifications
          const updated_boat_owner_trip_notifications = [
            ...boat_owner_trip_notifications
          ]
          updated_boat_owner_trip_notifications[0].status =
          res?.data?.data?.trip_cancelby_customer? true : false;
          setBoat_owner_trip_notifications(updated_boat_owner_trip_notifications)
        } else {
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err?.response?.data?.message??"get notification err", err);
        toast.error(err?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth?.AuthToken,
    auth.userDetails.email,
    // booking_notifications_points,
    // notifications_points?.request_cancelled,
    // notifications_points?.request_done,
    // notifications_points?.request_rejected,
    // notifications_points?.request_submitted,
  ]);

  const handleCheckboxChange = (id, notifications, setNotifications) => {
    const updatedNotifications = [...notifications];
    const index = updatedNotifications.findIndex((item) => item.id === id);
    if (index !== -1) {
      updatedNotifications[index].status = !updatedNotifications[index].status;
      setNotifications(updatedNotifications);
    }
  };

  const renderList = (item, notifications, setNotifications) => {
    return (
      <div className="p-0">
        <FormControlLabel
          style={{ cursor: "pointer", margin: "0.5% 0" }}
          onClick={() => {}}
          control={
            <div 
            className={`
            border 
            rounded-2 
            d-flex align-items-center justify-content-center
            `}
            style={{
              padding: item?.status? '1px 3px':'9px 9px'
            }}
            onClick={()=>handleCheckboxChange(item.id, notifications, setNotifications)}
            >
              {item?.status?
              <span 
              style={{
                fontSize: '12px'
              }}
              >
                <IoMdCheckmark/>
              </span>:null
              }
            </div>
            // <input type="checkbox"
            // // sx={{paddingRight: '5%'}}
            //   name="RememberMe"
            //   checked={item?.status}
            //   onChange={() =>
            //     handleCheckboxChange(item.id, notifications, setNotifications)
            //   }
            //   // icon={<Typography className={class_name.checked_box} />}
            //   // checkedIcon={
            //   //   <Typography className={class_name.checked_box}>✓</Typography>
            //   // }
            // />
          }
          label={
            <Typography
              style={{
                color: item?.status ? "#424651" : "rgba(66, 70, 81, 0.38)",
                // paddingLeft: '5%',
              }}
              className={class_name.notification_text}
            >
              {item?.name}
            </Typography>
          }
        />
      </div>
    );
  };

  const handleSubmit = () => {
    setIsLoading(true);
    toast.dismiss();

    let payload = {
      "Boat_booking": boat_notifications_point[2]?.status,
      "Offer_Underreview": offer_notifications_points[2]?.status,
      "Offer_accepted": offer_notifications_points[1]?.status,
      "Offer_declined": offer_notifications_points[0]?.status,
      "payment_sent": transaction_notifications_points[1]?.status,
      "refund_request": false,
      "payment_request": transaction_notifications_points[0]?.status,
      "Trip_Start": trip_notifications_point[0]?.status,
      "Trip_End": trip_notifications_point[1]?.status,
      "Trip_cancel": trip_notifications_point[2]?.status,
      "Block_User": other_notifications_point[0]?.status,
      "Block_Boat": boat_notifications_point[3]?.status,
      "Boat_verify": boat_notifications_point[0]?.status,
      "payment_failed": false,
      "boat_notverified": boat_notifications_point[1]?.status,
      "payment_received": payment_notifications[0]?.status,
      "user_verify": boat_notifications_point[4]?.status,
      "user_not_verify": boat_notifications_point[5]?.status,
      "trip_cancelby_customer": boat_owner_trip_notifications[0]?.status
    }

    console.log("payload", payload);
    updateNotificationsSettings_API(auth?.AuthToken, payload)
      .then((res) => {
        // console.log("res", res?.data);
        if (
          res?.data?.message === "Notifications settings updated successfully"
        ) {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else {
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  return (
    <>
      <div className={class_name.page_content}>
        <Container className={class_name.inside_bodyContent}>
        {auth?.userDetails?.user_type === 'BOAT_OWNER'&&
        <>
          <Typography className={class_name.title_txt}>
            Boat Notifications
          </Typography>
          {isLoading ? <Loader loading={isLoading} /> : null}
          <Row style={{ margin: "20px 0px 40px 0px" }}>
            {boat_notifications_point?.map((item, index) => {
              return renderList(
                item,
                boat_notifications_point,
                setBoat_notifications_point
              );
            })}
          </Row>
          </>}
        {auth?.userDetails?.user_type === 'BOAT_OWNER'&&
        <>
          <Typography className={class_name.title_txt}>
            Payment Notifications
          </Typography>
          {isLoading ? <Loader loading={isLoading} /> : null}
          <Row style={{ margin: "20px 0px 40px 0px" }}>
            {payment_notifications?.map((item, index) => {
              return renderList(
                item,
                payment_notifications,
                setPayment_notifications
              );
            })}
          </Row>
          </>}
          {auth?.userDetails?.user_type === 'BOAT_OWNER'&&
        <>
          <Typography className={class_name.title_txt}>
            Trip Notifications
          </Typography>
          {isLoading ? <Loader loading={isLoading} /> : null}
          <Row style={{ margin: "20px 0px 40px 0px" }}>
            {boat_owner_trip_notifications?.map((item, index) => {
              return renderList(
                item,
                boat_owner_trip_notifications,
                setBoat_owner_trip_notifications
              );
            })}
          </Row>
          </>}
        {auth?.userDetails?.user_type !== 'BOAT_OWNER'&&
        <>
          <Typography className={class_name.title_txt}>
            Trip Notifications
          </Typography>
          {isLoading ? <Loader loading={isLoading} /> : null}
          <Row style={{ margin: "20px 0px 40px 0px" }}>
            {trip_notifications_point?.map((item, index) => {
              return renderList(
                item,
                trip_notifications_point,
                setTrip_notifications_point
              );
            })}
          </Row>
          </>}
          {/* {auth?.userDetails?.user_type !== 'BOAT_OWNER'&&
            <>
          <Typography className={class_name.title_txt}>
            Booking Notifications
          </Typography>
          {isLoading ? <Loader loading={isLoading} /> : null}
          <Row style={{ margin: "20px 0px 40px 0px" }}>
            {booking_notifications_points?.map((item, index) => {
              return renderList(
                item,
                booking_notifications_points,
                setBooking_notifications_points
              );
            })}
          </Row>
          </>
          } */}
          {auth?.userDetails?.user_type !== 'BOAT_OWNER'&&
          <>
          <Typography className={class_name.title_txt}>
            Offer Notifications
          </Typography>
          <Row style={{ margin: "20px 0px 40px 0px" }}>
            {offer_notifications_points?.map((item, index) => {
              return renderList(
                item,
                offer_notifications_points,
                setOffer_notifications_points
              );
            })}
          </Row>
          </>}
          {auth?.userDetails?.user_type !== 'BOAT_OWNER'&&
          <>
          <Typography className={class_name.title_txt}>
            Transaction Notifications
          </Typography>
          <Row style={{ margin: "20px 0px 40px 0px" }}>
            {transaction_notifications_points?.map((item, index) => {
              return renderList(
                item,
                transaction_notifications_points,
                setTransaction_notifications_points
              );
            })}
          </Row>
          </>}
          <Typography className={class_name.title_txt}>
            Other Notifications
          </Typography>
          <Row style={{ margin: "20px 0px 40px 0px" }}>
            {other_notifications_point?.map((item, index) => {
              return renderList(
                item,
                other_notifications_point,
                setOther_Notifications_point
              );
            })}
          </Row>

          {/* Save */}
          <div className={class_name.btnDiv}>
            <div
              className={class_name?.save_btn}
              onClick={() => handleSubmit()}
              style={{
                backgroundColor:
                  true !== "" ? "#026b93" : "rgba(151, 151, 151, 1)",
              }}
            >
              <Typography className={`${class_name.save_txt} hoverEffect`}>
                Save
              </Typography>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  btnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "60px 0px",
  },

  save_btn: {
    cursor: "pointer",
    borderRadius: "30px",
    border: "solid 1px #026b93",
    padding: "3% 9%",
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    transition: "background-color 0.3s",
    backgroundColor: "#026b93", // Add a smooth transition for the background color
    "&:hover": {
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
      // Change the background color on hover
      "& $save_txt": {},
    },
  },
  save_txt: {
    fontSize: "clamp(18px, 2vw, 24px)", // Adjust the range as needed
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.96,
    letterSpacing: "normal",
    textAlign: "center",
    color: "white",
  },

  page_content: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    // backgroundColor: "red",
  },
  inside_bodyContent: {
    padding: "5% 10%",
    [theme.breakpoints.down("764")]: {
      padding: "5% 5%",
      height: "100%",
    },
  },
  title_txt: {
    fontSize: "clamp(24px, 4vw, 30px)",
    // fontSize: "clamp(14px, 5vw, 40px)",
    fontWeight: "500",
    lineHeight: "1.53",
    textAlign: "left",
    color: "#424651",
  },

  point_txt: {
    fontSize: "clamp(24px, 4vw, 30px)",
    // fontSize: "clamp(14px, 5vw, 40px)",
    fontWeight: "500",
    lineHeight: "1.53",
    textAlign: "left",
    color: "#424651",
  },

  checked_box: {
    width: "18px",
    height: "18px",
    border: "1px solid rgba(66, 70, 81, 0.4)",
    fontSize: "clamp(10px, 1.5vw, 16px)",
    color: "#424651",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    // padding: "7px 3.9px 6px 4px",
    padding: "10%",
    borderRadius: "5px",
    // marginRight: "10%",

    [theme.breakpoints.up("sm")]: {
      width: "18px",
      height: "18px",
    },
    [theme.breakpoints.up("md")]: {
      width: "18px",
      height: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "22px",
      height: "22px",
    },
  },
  notification_text: {
    paddingLeft: "10px"
  },

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    // save_btn: {
    //   borderRadius: "0px",
    //   position: "fixed",
    //   bottom: 0,
    //   left: "50%",
    //   transform: "translateX(-50%)",
    //   backgroundColor: "rgba(151, 151, 151, 1)",
    //   border: "solid 1px rgba(151, 151, 151, 1)",
    //   padding: "0px",
    //   width: "100%",
    //   height: "56px",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignContent: "center",
    //   alignItems: "center",
    //   alignSelf: "center",
    // },
    // save_txt: {
    //   fontSize: "clamp(16px, 5vw, 20px)", // Adjust the range as needed
    //   fontWeight: "400",
    //   fontStretch: "normal",
    //   fontStyle: "normal",
    //   lineHeight: 0.96,
    //   letterSpacing: "normal",
    //   textAlign: "center",
    //   color: "white",
    // },
  },
}));
