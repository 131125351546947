export function* callLogin(action) {
  // try {
  //   const response = yield login(action.payload);
  //   yield put(loginSucess(response));
  // } catch (e) {
  //   yield put(loginFailure(e));
  // }
}

export function* authSaga() {
  // yield takeLatest(FetchingDataFromBureauRequest.type, callBureauApi);
}
