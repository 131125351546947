import React, { useEffect, useRef, useState } from 'react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { makeStyles } from "@material-ui/core";
// import './Map2.css'
// import IMAGES from '../../Images'
import { GoogleMap, Marker } from '@react-google-maps/api';

const Map = ({ markers, selectedMarker , onSelectMarker, notSelect = false }) => {
    // const refMap = useRef(null)
    const [refMap, setRefMap] = useState(null)
    const [place, setPlace] = useState({lat:20.146220361679458,lng:40.2568970541965})
    const class_name = useStyles({ min: 10, max: 30, unit: "px" });

    // console.log(selectedMarker,'marker',place)

    useEffect(()=>{
      let lat = selectedMarker?.lat
      let lng = selectedMarker?.lng
      setPlace({lat:lat,lng:lng})
    },[selectedMarker])
    
    const handleMapClick = async(event) => {
      console.log('working')
        const lat = event?.latLng?.lat(); //refMap?.getCenter().lat() //event.latLng.lat();
        const lng = event?.latLng?.lng(); //refMap?.getCenter().lng()
        setPlace({lat:lat,lng:lng})
        console.log(lat,lng,'latlng')
        axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDJC9c-nt6H0NMCq2eBYMxKT_SfIcZTWwo`
        )
        .then((res)=>{
          const address = res?.data?.results[0]?.formatted_address
          console.log(res,'place address',address)
          onSelectMarker({
            lat: lat,
            lng: lng,
            address: address
          })
        })
        .catch((err)=>{
          console.log(err)
        })
      };

  return (
    // <LoadScript
    //   googleMapsApiKey="AIzaSyDJC9c-nt6H0NMCq2eBYMxKT_SfIcZTWwo"
    // >
    <div style={{position:'relative'}}>
      <GoogleMap
      key=''
      mapContainerClassName={class_name.map_box}  
      // ref={refMap}
      onDragEnd={(e)=>handleMapClick(e)}
      onLoad={map => {
        setRefMap(map)
      }}
        // mapContainerStyle={containerStyle}
        onClick={(e)=>{
            if(!notSelect){
                handleMapClick(e)
            }
        }}
        center={{
          lat: place?.lat, //selectedMarkerLat,
          lng: place?.lng //selectedMarkerLng
        }}
        zoom={15}
      >
        {
        // selectedMarker &&
            <Marker
            style={{
              translate:"-100% -80%"
            }}
            // position={{ lat: place?.lat, lng: place?.lng }}
            position={place}
            // position={center}
            onDragEnd={(e)=>{
              if(!notSelect){
              handleMapClick(e)
            }
            }}
            draggable={!notSelect? true:false}
            // label={{
            //   className:"map_marker"
            // }}
            />
       }
      </GoogleMap>
      {/* <img src={IMAGES.MARKER_ICON} style={{width:'30px',position: 'absolute',top:"50%",left:'50%',right:'0',bottom:'0',zIndex:'999'}}/> */}
      </div>
    // </LoadScript>
  );
};

export default Map

const useStyles = makeStyles((theme) => ({
    map_box: {
      display: "flex",
      height: "500px",
      width: "100%",
      borderRadius: "15px",
      boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.16)`,
      overflow: "hidden",
      // position: 'relative'
    },
  
    //
    //
    //
    //
    //  ==============================    max-width: 767
    //
    //
    //
    //
    "@media (max-width: 767px)": {
      map_box: {
        height: "250px",
        width: "100%",
        borderRadius: "15px",
        boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.16)`,
        overflow: "hidden",
      },
    },
  }));