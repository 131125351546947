import React from "react";
import moment from "moment";
import { Typography, makeStyles } from "@material-ui/core";

const WeekDays = ({ item, index, setSelectedDate, selectedDate }) => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  return (
    <div style={{}} className={class_name.container}>
      {item?.days?.map((weekDay, weekIndex) => {
        return (
          <div
            className={class_name?.headerContent}
            style={{
              marginRight: weekIndex === 6 ? 0 : "2%",
              backgroundColor: weekDay?.isValid
                ? moment(selectedDate).format('DD-MM-YYYY') === moment(weekDay?.date).format("DD-MM-YYYY")
                  ? "#3973a5"
                  : "white"
                : "#ececec",
              boxShadow: weekDay?.isValid
                ? moment(selectedDate).format('DD-MM-YYYY') === moment(weekDay?.date).format("DD-MM-YYYY")
                  ? "0px 6px 12px rgba(0, 0, 0, 0.24)"
                  : null
                : null,
            }}
            onClick={() => {
              if (weekDay?.isValid) {
                setSelectedDate(weekDay?.date);
              }
            }}
          >
            <Typography
              className={class_name?.lableTxt}
              style={{
                color: weekDay?.isValid
                  ? moment(selectedDate).format('DD-MM-YYYY') === moment(weekDay?.date).format("DD-MM-YYYY")
                    ? "white"
                    : "#424651"
                  : "#c6c6c6",
                fontWeight: weekDay?.isValid
                  ? moment(selectedDate).format('DD-MM-YYYY') === moment(weekDay?.date).format("DD-MM-YYYY")
                    ? "bold"
                    : null
                  : null,
              }}
            >
              {weekDay?.day}
            </Typography>
            <Typography
              className={class_name?.lableTxt}
              style={{
                color: weekDay?.isValid
                  ? moment(selectedDate).format('DD-MM-YYYY') === moment(weekDay?.date).format("DD-MM-YYYY")
                    ? "white"
                    : "#424651"
                  : "#c6c6c6",
                fontWeight: weekDay?.isValid
                  ? moment(selectedDate).format('DD-MM-YYYY') === moment(weekDay?.date).format("DD-MM-YYYY")
                    ? "bold"
                    : null
                  : null,
              }}
            >
              {moment(weekDay?.date).format("D")}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default WeekDays;

const styles = {};

const weekdate = [
  { id: 1, day: "SAT" },
  { id: 2, day: "SUN" },
  { id: 3, day: "MON" },
  { id: 4, day: "TUE" },
  { id: 5, day: "WED" },
  { id: 6, day: "THU" },
  { id: 7, day: "FRI" },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",

    width: "100%",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("767")]: {
      justifyContent: "center",
      flexWrap: "wrap",
    },
  },
  headerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px rgba(66, 70, 81, 0.36)",

    padding: "2% 3.5%",
    borderRadius: "20%",
    // marginRight: "2%",
    marginTop: "3%",
    "&:hover": {
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
      border: "solid .5px rgba(66, 70, 81, 0.36)",
      // Change the background color on hover
      "& $lableTxt": {
        fontWeight: "600",
      },
    },
    [theme.breakpoints.down('480')]: {
      padding: "2% 3%",
      borderRadius: "12%",
    }
  },
  lableTxt: {
    // fontFamily: "Poppins",
    // fontSize: "20px",
    fontSize: "clamp(10px, 1.5vw, 20px)",
    // fontWeight: "normal",
    // fontStretch: "normal",
    // fontStyle: "normal",
    // lineHeight: 1.15,
    // letterSpacing: "normal",
    textAlign: "center",
    color: "#424651;",
    // width: "50%",
    // backgroundColor: "red",
    width: "100%",
    "&:hover": {
      // Change the color on hover
    },
  },
}));
