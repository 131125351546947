import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resend_otp, verifyOtp } from "../../../Service/api";
import {
  EmailId,
  UserType,
  confirmTickMsg,
  signUp_payload,
  verifyOTP,
} from "../../../redux/slices";
import IMAGES from "../../Images";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import "./VerifyOTP.css";
import { Input, TextField, Typography, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useOtpInputRefs = (length) => {
  const inputRefs = React.useMemo(() => {
    return Array(length)
      .fill()
      .map(() => React.createRef());
  }, [length]);

  return inputRefs;
};

const CustomTextField = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&. Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'green', // color when focused
      },
    },
    "& .MuiInputBase-root": {
      border: "none",
    },

    "& .MuiFilledInput-underline": {
      border: "none",
      // color: 'red'
    },

    "& input::placeholder": {
      fontSize: "clamp(10px, 5vw, 16px)",
      color: "rgba(66, 70, 81, 0.4)",
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-root": {},

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      borderBottom: "1px solid transparent",
      borderRadius: "0",
      border: "none",
    },

    [theme.breakpoints.down("sm")]: {
      "& input::placeholder": {
        fontSize: "13px",
      },
    },
    [theme.breakpoints.between("sm", "md")]: {
      "& input::placeholder": {
        fontSize: "14px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& input::placeholder": {
        fontSize: "18px",
      },
    },
  },
}))(TextField);

const VerifyOTP = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const inputRefs = useOtpInputRefs(6);
  const user = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState("");
  const [showResend, setShowResend] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const [isLoading, setIsLoading] = useState(false);
  const [OTPcount, setOTPcount] = useState(0);
  const [flag, setFlag] = useState(0);
  const [focusedIndex, setFocusedIndex] = useState(null);

  useEffect(() => {
    const otp = inputRefs.map((ref) => ref.current.value).join("");
    setOTPcount(otp?.length);
  }, [inputRefs, flag]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    setErrorMsg("");
    toast.dismiss();
    const otp = inputRefs.map((ref) => ref.current.value).join("");
    if (otp?.length >= 6) {
      let payload = {
        name: user?.name,
        email: user?.emailId,
        otp: otp,
      };
      // console.log("payload", payload);
      verifyOtp(payload)
        .then((res) => {
          // console.log("res", res);
          if (res?.data?.success) {
            dispatch(UserType(null));
            if (!user?.password) {
              dispatch(EmailId(null));
            }
            dispatch(verifyOTP("VERIFY_OTP"));
            // navigate("/LogIn");
            dispatch(
              confirmTickMsg({
                title:
                  "Your account has been created successfully, Please login!",
                buttonName: "Go to login",
              })
            );
            navigate("/confirmation");

            dispatch(signUp_payload(null));
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          } else {
            setErrorMsg(res?.data?.message);

            toast.error(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error : ", err);

          toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        });
    } else {
      setIsLoading(false);

      toast.error("Please enter your OTP", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  // const handleInputChange = (event, index) => {
  //   const value = event.target.value;
  //   if (value.length === 1 && index < inputRefs.length - 1) {
  //     inputRefs[index + 1].current.focus();
  //     setErrorMsg("");
  //   }
  // };

  const handleInputChange = (event, index) => {
    const value = event.target.value;
    if (value.length >= 1 && index < inputRefs.length - 1) {
      // Split the value into individual characters
      const chars = value.split('');
      // Iterate over each character
      for (let i = 0; i < chars.length; i++) {
        // If the current inputRef exists, set its value and focus the next one
        if (inputRefs[index + i]) {
          inputRefs[index + i].current.value = chars[i];
          if (inputRefs[index + i + 1]) {
            inputRefs[index + i + 1].current.focus();
          }
        }
      }
      setErrorMsg("");
    }
  };

  const handleInputBackspace = (event, index) => {
    if (event.key === "Backspace" && index > 0) {
      if (inputRefs[index].current.value === "") {
        inputRefs[index - 1].current.focus();
      } else {
        inputRefs[index].current.value = "";
      }
    }
  };

  const startCountdown = () => {
    ResentOTP();
    setErrorMsg("");
    setShowResend(false);
    let timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      setShowResend(true);
      setCountdown(60);
    }, 60000);
  };

  const ResentOTP = () => {
    let payload = {
      name: user?.name,
      email: user?.emailId,
    };
    // console.log("payload", payload);
    resend_otp(payload)
      .then((res) => {
        // console.log("res", res);
        inputRefs.forEach((ref) => {
          ref.current.value = "";
        });
      })
      .catch((err) => {
        console.log("Resent API Error", err);
      });
  };

  return (
    <div className={class_name.otp_full_container}>
      {isLoading ? <Loader loading={isLoading} /> : null}
      <div className="icon-div">
        <Grid
          item
          className="top-back-button"
          onClick={() => {
            dispatch(EmailId(null));
            navigate(-1);
          }}
        >
          <img src={IMAGES.LEFT_BACK_BUTTON} className="back-icon" alt="Back" />
        </Grid>
        <img src={IMAGES.APP_ICON} alt="ICON" className="app-icon-style" />
      </div>

      <div className={class_name.verify_otp_body_container}>
        <Grid
          item
          className="back-button"
          onClick={() => {
            dispatch(EmailId(null));
            navigate(-1);
          }}
        >
          <img src={IMAGES.LEFT_BACK_BUTTON} className="back-icon" alt="Back" />
          <Typography variant="subtitle1" className="back-txt">
            Back
          </Typography>
        </Grid>

        <div className={class_name.inside_content_box}>
          <img
            src={IMAGES.VERIFY_ACC}
            alt="Verify Acc"
            className={class_name.verified_img}
          />

          <Typography className={class_name.pageTitle}>
            Please Verify Account
          </Typography>

          <Typography className={class_name.page_info_txt}>
            Enter the six-digit code we sent to your email to verify your new
            account
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {inputRefs.map((ref, index) => (
              <>
                <div className={class_name.show_text_box}>
                  <CustomTextField
                    key={index}
                    variant={"outlined"}
                    // label='underline'
                    type="text"
                    inputRef={ref}
                    onChange={(event) => {
                      setFlag(Math.random(14.12 * 1));
                      const inputValue = event.target.value;
                      const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
                      if (inputValue !== numericValue) {
                        event.target.value = numericValue; // Update the input field value with only numeric characters
                      }
                      handleInputChange(event, index);
                    }}
                    onKeyDown={(event) => {
                      setFlag(Math.random(14.12 * 1));
                      handleInputBackspace(event, index);
                    }}
                    InputProps={{
                      disableUnderline: true,
                      maxLength: 1,
                      style: {},
                      className: class_name.insideTextBox,
                      // classes: {underline: {borderBottom: 'yellow'}}
                    }}
                    inputProps={{
                      inputMode: 'numeric'
                    }}
                    // inputProps={{
                    //   // disableUnderline: true,
                    
                    // }}
                    style={{
                      border: errorMsg
                        ? "solid 1px red"
                        : focusedIndex === index
                        ? "solid 1px rgba(66, 70, 81, 0.87)"
                        : "solid 0.3px #424651",
                    }}
                    className={class_name.textFiled}
                    onFocus={() => setFocusedIndex(index)}
                    onBlur={() => setFocusedIndex(null)}
                  />
                </div>
              </>
            ))}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {errorMsg ? (
              <Typography className="error-msg-txt">{errorMsg}</Typography>
            ) : null}

            {showResend ? (
              <Typography
                variant="contained"
                onClick={startCountdown}
                className={class_name.resend_txt}
              >
                Resend OTP
              </Typography>
            ) : (
              <Typography
                variant="body2"
                style={{
                  color: "#3973a5",
                  marginTop: errorMsg ? "18px" : "48px",
                  textAlign: "center",
                  borderRadius: "5px",
                  fontSize: 14,
                  marginBottom: "25px",
                }}
              >
                Resend OTP in {countdown} seconds
              </Typography>
            )}

            <div
              className={class_name?.Verify_Continue_btn}
              onClick={handleSubmit}
              style={{
                backgroundColor:
                  OTPcount === 6 ? "#026b93" : "rgba(151, 151, 151, 1)",
              }}
            >
              <Typography
                className={`${class_name.Verify_Continue_txt} hoverEffect`}
              >
                Verify & Continue
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;

const useStyles = makeStyles((theme) => ({
  verify_otp_body_container: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "2% 5%",
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    border: "solid 0.5px rgba(66, 70, 81, 0.1)",
    margin: "0 10%",
  },
  otp_full_container: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#f6f6f6",
    paddingBottom: "100px",
    display: "flex",
    flexDirection: "column",
  },

  pageTitle: {
    margin: ".5%",
    fontSize: "clamp(16px, 3vw, 40px)",
    fontWeight: "600",
    textAlign: "center",
    color: "var(--charcoal-grey-87)",
  },

  page_info_txt: {
    margin: "8px 0 0",
    fontSize: "clamp(12px, 1vw, 22px)",
    textAlign: "center",
    color: "var(--charcoal-grey-87)",
    padding: '0% 5%'
  },

  verified_img: {
    width: "clamp(165px, 30vw, 260px)", // Adjust the range as needed
    height: "clamp(145px, 30vh, 220px)", // Adjust the range as needed
    [theme.breakpoints.down("sm")]: {
      width: "clamp(165px, 30vw, 260px)", // Adjust the range as needed
      height: "clamp(145px, 20vh, 220px)", // Adjust the range as needed
    },
  },

  show_text_box: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    margin: "1%",
    [theme.breakpoints.down(300)]: {
      margin: ".2%",
    },
  },

  insideTextBox: {
    // color: 'red',
    // border: 'red',
    top: 10,
    backgroundColor: "white",
    // border: 'red'
  },

  textFiled: {
    borderRadius: "5%",
    width: "clamp(48px, 3vw, 50px)", // Adjust the range as needed
    marginLeft: "10px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0 10%",
      margin: "0 0 60%",
      height: "80%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 10%",
      margin: "0 0 60%",
      height: "90%",
    },
    [theme.breakpoints.up("lg")]: {},
  },

  resend_txt: {
    cursor: "pointer",
    color: "#3973a5",
    margin: "0% 0% 3%",
    textAlign: "center",
    borderRadius: "5px",
    fontSize: "clamp(14px, 1vw, 22px)",
  },

  Verify_Continue_btn: {
    margin: "0% 0% 3%",
    cursor: "pointer",
    borderRadius: "3px",
    border: "solid 1px #026b93",
    padding: "2% 7%",
    display: "flex",
    transition: "background-color 0.3s",
    backgroundColor: "#026b93", // Add a smooth transition for the background color
    "&:hover": {
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
      // Change the background color on hover
      "& $Verify_Continue_txt": {},
    },
  },

  Verify_Continue_txt: {
    fontSize: "clamp(8px, 2vw, 24px)", // Adjust the range as needed
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.96,
    letterSpacing: "normal",
    textAlign: "center",
    color: "white",
  },

  inside_content_box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    verify_otp_body_container: {
      backgroundColor: "#f6f6f6",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      padding: "0",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      border: "solid 0.5px rgba(66, 70, 81, 0.1)",
      margin: "0",
    },

    otp_full_container: {
      paddingBottom: "0",
      backgroundColor: "white",
    },
    insideTextBox: {
      top: 0,
      height: "90%",
      marginBottom: 0,
      paddingBottom: 0,
      width: "100%",
      // borderBottom: "red",
      // backgroundColor: "lightgray",
      // margin: "0 5%",
    },
    textFiled: {
      borderBottom: "none",
      backgroundColor: "white",
      [theme.breakpoints.up(300)]: {
        width: "40px",
        height: "70%",
        padding: "0",
      },
      [theme.breakpoints.down(300)]: {
        width: "38px",
        height: "60%",
        padding: "0%",
        marginLeft: "1px",
      },
    },

    Verify_Continue_txt: {
      fontSize: "clamp(16px, 5vw, 20px)", // Adjust the range as needed
      fontWeight: "400",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.96,
      letterSpacing: "normal",
      textAlign: "center",
      color: "white",
    },
  },
  '@media (max-height: 575.98px) and (orientation: landscape)': {
    otp_full_container: {
      height: '150vh',
      // backgroundColor: 'red'
      width: "100%",
    }
  }
}));
