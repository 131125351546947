import { Checkbox, IconButton } from "@mui/material";
import React, { useState } from "react";
import "../Setting.css";
import { withStyles } from "@mui/styles";
import { Edit } from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import { VerifyOTPmodal } from "./modal/VerifyOTPmodal";
import { ChangePwdModal } from "./modal/ChangePwdModal";
import { Typography } from "@material-ui/core";
import {
  forgot_password_request,
  user_phone_verify_initiate_API,
} from "../../../../Service/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import VerifyOTP from "../../../Auth/verify-OTP/VerifyOTP";

const CustomCheckbox = withStyles({
  root: {
    color: "#ffffff",
    "&$checked": {
      color: "#ffffff",
    },
  },
  checked: {},
})(Checkbox);

const useStyles_nxt = makeStyles((theme) => ({
  customSwitch: {
    "&$checked": {
      color: "#3973a5", // Change this to the desired thumb color
      margin: "0px",
      //   backgroundColor: "red",
      //   padding: "0px",
      //   alignSelf: "center",
    },
    "&$checked + $track": {
      backgroundColor: "#3973a5", // Change this to the desired track color
      margin: "0px",
      //   backgroundColor: "red",
    },
  },
  checked: {},
  track: {},
}));

export const SecurityAndPwdTab = ({}) => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const classes = useStyles_nxt();
  const [toggled, setToggled] = React.useState(true);
  const [showPwdModal, setShowPwdModal] = useState(false);
  const [showChangePwdModal, setShowChangePwdModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const auth = useSelector((state) => state?.auth);
  const [isLoader, setIsLoader] = useState(false);
  const [phoneModal, setPhoneModal] = useState(false)  

  //
  //
  //
  //
  //

  //
  //
  //
  //
  //
  //

  const handleModalClose = (name) => {
    setShowModal(false);
    setPhoneModal(false)
    setShowPwdModal(false);
    if (name === "open change pwd") {
      setShowChangePwdModal(true);
    } else if (name === "close change pwd") {
      setShowChangePwdModal(false);
    }
  };

  const send_otp_req_to_phnoneNo = () => {
    toast.dismiss();
    setIsLoader(true);
    user_phone_verify_initiate_API(auth?.AuthToken)
      .then((res) => {
        // console.log("res", res?.data);
        if (res?.data?.message === "otp send to phone number") {
          // setShowModal(true);
          setPhoneModal(true)
          setShowPwdModal(false);
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 20000,
          });
        } else {
          toast.error("Something went wrong. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        setIsLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoader(false);
        toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };
  // console.log(auth)

  const handlePhoneVerfication = () =>{
    if(auth?.userDetails?.phone_number){
      send_otp_req_to_phnoneNo();
    } else {
      toast.error('Please save your phone number',{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      })
    }
  }

  const send_otp_req_to_email = (values) => {
    toast.dismiss();
    setIsLoader(true);
    let payload = {
      email: auth?.userDetails?.email,
    };
    // console.log("forgot pwd request payload", payload);
    forgot_password_request(payload)
      .then((res) => {
        // console.log("forgot pwd request res", res);
        if (res?.data?.message.toLowerCase() === "otp sent to email") {
          setShowModal(true);
          setShowPwdModal(true);
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else {
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        setIsLoader(false);
      })
      .catch((err) => {
        console.log("forgot pwd request err", err);
        setIsLoader(false);
        toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  return (
    <>
      {showChangePwdModal ? (
        <ChangePwdModal
          showmodal={showChangePwdModal}
          handleModalClose={handleModalClose}
        />
      ) : null}
      {showModal ? (
        <VerifyOTPmodal
          showmodal={showModal}
          handleModalClose={handleModalClose}
          verify={true}
        />
      ) : null}
      {phoneModal ? (
        <VerifyOTPmodal
          showmodal={phoneModal}
          handleModalClose={handleModalClose}
        />
      ) : null}
      <div className="security-pwd-container">
        <div className="security-pwd">
          {isLoader ? <Loader loading={isLoader} /> : null}
          <div className={class_name.inside_bodyContent}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0px 0px 0px 0px",
                // padding: "0px 10px 0px 0px",
                justifyContent: "space-between",
                // backgroundColor: "lightpink",
              }}
            >
              <Typography className={class_name.security_pwd_title_txt}>
                Password
              </Typography>

              <IconButton
                style={{
                  // backgroundColor: "lightgreen",
                  display: auth?.userDetails?.signin_type === 'SOCIAL_LOGIN'
                  ? 'none':'flex',
                  border: "solid 0.5px #3973a5",
                  color: "#3973a5",
                  padding: "12px"
                }}
                className={class_name.change_pwd_icon}
                onClick={() => {
                  send_otp_req_to_email();
                }}
              >
                <Edit
                  backgroundColor="#3973a5"
                  className={class_name.change_pwd_icon_txt}
                />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "30px 0px 0px 0px",

                height: "100%",
              }}
            >
              <div className={class_name.tick_background_design}>
                <Typography className={class_name.tick_txt_style}>✔</Typography>
              </div>
              <Typography className={class_name.sub_title_txt}>
                Password has been set
              </Typography>
            </div>
            <Typography className={class_name.sub_title_info_txt}>
              You currently use{" "}
              {auth?.userDetails?.signin_type === "EMAIL" ? "Email" : "Google"}{" "}
              sign-in to login
            </Typography>
          </div>
        </div>
        <div className="security-pwd" style={{ marginTop: "10px" }}>
          <div className={class_name.inside_bodyContent}>
            <Typography className={class_name.security_pwd_title_txt}>
              Two-Factor Authentication Options
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "30px 0px 0px 0px",
                justifyContent: "space-between",
                // backgroundColor: "lightpink",
              }}
            >
              <Typography className={class_name.second_header_title_txt}>
                Email Verification
              </Typography>
              <Switch
                checked={toggled}
                disabled={toggled}
                // onChange={(e) => setToggled(e.target.checked)}
                classes={{
                  switchBase: classes.customSwitch,
                  checked: classes.checked,
                  track: classes.track,
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px 0px 0px 0px",
              }}
            >
                <div className={class_name.enabled_div}>
                  <div className={class_name.tick_background_design}>
                    <Typography className={class_name.tick_txt_style}>✔</Typography>
                  </div>
                  <Typography className={class_name.sub_title_txt_phone}>
                    Enabled
                  </Typography>
                </div>
            </div>
            <Typography className={class_name.sub_title_info_txt}>
              Receive passcode from your email to confirm it's you
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "30px 0px 0px 0px",
                justifyContent: "space-between",
                // backgroundColor: "lightpink",
              }}
            >
              <Typography className={class_name.second_header_title_txt}>
                Phone Verification
              </Typography>
              <Switch
                checked={auth?.userDetails?.phone_number_verified === 1}
                onChange={() => {
                  handlePhoneVerfication()
                }}
                disabled={auth?.userDetails?.phone_number_verified === 1}
                classes={{
                  switchBase: classes.customSwitch,
                  checked: classes.checked,
                  track: classes.track,
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px 0px 0px 0px",
              }}
            >
              <div className="w-100">
                {auth?.userDetails?.phone_number_verified === 1 ? (
                <div className={class_name.enabled_div}>
                  <div className={class_name.tick_background_design}>
                    <Typography className={class_name.tick_txt_style}>✔</Typography>
                  </div>
                  <Typography className={class_name.sub_title_txt_phone}>
                    Enabled
                  </Typography>
                </div>
                ):null}
              </div>
            </div>
            <Typography className={class_name.sub_title_info_txt}>
              Receive a six digit code by text message to confirm it’s you.
            </Typography>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "30px 0px 0px 0px",
                padding: "0px 10px 0px 0px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "10px 0px 0px 0px",
                  width: "100%",
                }}
              >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: '100%',
                // margin: "30px 0px 0px 0px",
                justifyContent: "space-between",
                // backgroundColor: "lightpink",
              }}
            >
              <Typography className={class_name.second_header_title_txt}>
                Phone Verification
              </Typography>
              <Switch
                checked={toggled}
                // onChange={(e) => setToggled(e.target.checked)}
                classes={{
                  switchBase: classes.customSwitch,
                  checked: classes.checked,
                  track: classes.track,
                }}
              />
            </div>
                {auth?.userDetails?.phone_number_verified === 1 ? (
                  <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "10px 0px 0px 0px",
                  }}>
                  <div className={class_name.tick_background_design}>
                    <Typography className={class_name.tick_txt_style}>
                      ✔
                    </Typography>
                  </div>
                  <Typography className={class_name.sub_title_txt}>
                    Enabled
                  </Typography>
                  </div>
                ) : null}
              </div>
            </div>
            <Typography className={class_name.sub_title_info_txt}>
              {" "}
              Receive a six digit code by text message to confirm it’s you.
            </Typography> */}
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  inside_bodyContent: {
    padding: "5% 10%",
    [theme.breakpoints.down("764")]: {
      padding: "5% 5%",
    },
  },
  security_pwd_title_txt: {
    fontSize: "clamp(24px, 4vw, 30px)",
    // fontSize: "clamp(14px, 5vw, 40px)",
    fontWeight: "500",
    lineHeight: "1.53",
    textAlign: "left",
    color: "#424651",
  },
  second_header_title_txt: {
    fontSize: "clamp(16px, 4vw, 24px)",
    fontWeight: "500",
    lineHeight: "1.53",
    textAlign: "left",
    color: "#424651",
  },
  sub_title_txt: {
    margin: "0px 0px 0px 3%",
    fontSize: "clamp(10px, 4vw, 20px)",
    // fontWeight: "lighter",
    lineHeight: "1.5",
    textAlign: "left",
    color: "#424651",
  },

  sub_title_txt_phone: {
    // margin: "0px 0px 0px 3%",
    fontSize: "clamp(10px, 4vw, 20px)",
    // fontWeight: "lighter",
    lineHeight: "1.5",
    textAlign: "left",
    color: "#424651",
  },
  change_pwd_icon: {
    padding: "5%",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("764")]: {},
  },
  change_pwd_icon_txt: {
    fontSize: "clamp(10px, 3vw, 30px)",
  },

  sub_title_info_txt: {
    margin: "11px 0 0 0",

    fontSize: "clamp(12px, 2vw, 14px)",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
  },

  enabled_div: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '5%'
  },

  tick_background_design: {
    backgroundColor: "#3973a5",
    width: "25px",
    height: "25px",
    borderRadius: "30px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "20px",
      height: "20px",
      borderRadius: "30px",
    },
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("764")]: {},
  },
  tick_txt_style: {
    fontSize: "clamp(8px, 2vw, 14px)",
    color: "white",
  },
}));
