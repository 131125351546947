import {
  applyMiddleware,
  combineReducers,
  configureStore,
  createStore,
} from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { persistReducer, persistStore } from "redux-persist";
import storageSession from "reduxjs-toolkit-persist/lib/storage/session";
// import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import sessionStorage from "redux-persist/es/storage/session";
// import localforage from 'https://unpkg.com/localforage@1.9.0/src/localforage.js'
import localforage from "localforage";

const persistConfig = {
  key: "root",
  storage: localforage,
  //   blacklist: ["auth"],
};

let sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware, thunk],
});

let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
