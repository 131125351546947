import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { HeaderContent } from "../Common/map/HeaderContent";
import { SelectableStarRating } from "../../UI kit/5Star/SelectableStarRating";
import IMAGES from "../Images";
import Footer from "../../Component/Footer/Footer";
import { PageHeader } from "../Dash/page-header/PageHeader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { boat_rating_API } from "../../Service/api";
import Loader from "../Loader";

const start_space_Validation = new RegExp(/^(?!\s).*/);

export const ReviewPage = () => {
  const location = useLocation()
  const auth = useSelector((state) => state?.auth);
  const class_name = useStyles({ min: 10, max: 60, unit: "px" });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [rating, setRating ] = useState(null)
  // const navigate = useHistory();
  const [tellUsAboutYourExperience, setTellUsAboutYourExperience] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectImgFile, setSelectImgFile] = useState(null)

  const handle_navigation = (name) => {
    if (name === "chat") {
      navigate("/allMessage");
    } else if(name === 'My Listings'){
      navigate('/myListings')
    } else{
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectImgFile(file)
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    // Trigger click on the file input element
    document.getElementById('fileInput').click();
  };

  const handleRatingChange = (selectedRating) => {
    // Do something with the selectedRating
    setRating(selectedRating)
  };

  const handleSubmit = () =>{
    setIsLoading(true)
    let payload = {
      boat_id: location?.state?.boat_id,
      rating: Number(rating),
      review: tellUsAboutYourExperience,
      joyfullImage: selectImgFile
    }
    console.log(payload,'paylod')
    boat_rating_API(auth?.AuthToken,payload)
    .then((res)=>{
      console.log(res?.data,'boat rating res')
      if(res?.data?.success){
        setIsLoading(false)
        setRating(null)
        setTellUsAboutYourExperience(null)
        setSelectedImage(null)
        setSelectImgFile(null)
        toast.success('Review submitted successfully',{
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        })
        navigate(-1)
      } else {
        setIsLoading(false)
        toast.error(res?.data?.message,{
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        })
        // navigate('/notification')
      }
    })
    .catch((err)=>{
      setIsLoading(false)
      console.log('boat rating api err=> ',err)
      toast.error(err?.message,{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      })
    })
  }

  return (
    <>
    <Loader loading={isLoading}/>
      {/* <HeaderContent
        contentname1={"Home"}
        contentname2={"Boat Offers"}
        contentname3={"Scuba Courses/Programs"}
        contentname4={"Scuba Diving Trips"}
        handleBack={handleCallBack}
      /> */}
      <PageHeader
        showLoginSignUp={auth?.AuthToken ? false : true}
        handle_navigation={handle_navigation}
        // presentPage={"Home"}
        link1={"Boat Offers"}
        link2={"Scuba Courses/Programs"}
        link3={"Scuba Diving Trips"}
        link4={auth?.tokenDecodeData?.user_type === "BOAT_OWNER"? "My Listings":null}
      />
      <div className={class_name.container}>
        <Typography className={class_name.titleTxt}>
          We hope you enjoyed your experience with us!
        </Typography>
        <Typography className={class_name.infoTxt}>
          Your feedback helps us improve our services and ensures that we
          provide the best possible experience for our customers. Please take a
          moment to share your thoughts and rate your experience with us. Thank
          you for your time!
        </Typography>
        <Typography className={class_name.starRateTxt}>
          How likely are you to rate your experience with us?
        </Typography>
        <div className={class_name.starRating_div} style={{marginBottom: '3%'}}>
          <SelectableStarRating onSelect={handleRatingChange} />
        </div>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            rows={8}
            id="TellUsAboutYourExperience"
            name="TellUsAboutYourExperience"
            placeholder="Tell us about your experience and if there's anything to recommend"
            value={tellUsAboutYourExperience}
            onChange={(event) => {
              const inputValue = event.target.value;
              if (start_space_Validation.test(inputValue)) {
                setTellUsAboutYourExperience(inputValue);
              }
            }}
            InputProps={{
              borderRadius: "10px",
              borderWidth: ".01px",
            }}
            style={{
              backgroundColor: "white",
              verticalAlign: "top",
              display: "flex",
              alignSelf: "flex-start",
              alignContent: "flex-start",
              alignItems: "flex-start",
              justifyContent: "start",
            }}
            multiline
            // maxRows={5}
          />
        </Grid>

        <Typography className={class_name.pictureTxt}>
          Would you like to leave a picture for your joyful experience?
        </Typography>
        <div
        className={class_name.pic_upload_input}
        onClick={handleClick}
        style={{
          backgroundImage: `url(${selectedImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
        >
          <img
          className={class_name.camera_img}
            alt="camera"
            src={IMAGES.CAMERA}
            style={{ 
              display: selectedImage? 'none':'block'
             }}
          />
          <Typography 
          className={class_name.uploadTxt}
          style={{ 
            display: selectedImage? 'none':'block'
           }}
          >
            Click here to Upload
          </Typography>
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileInputChange}
          />
        </div>
        <div className={class_name.btn_div}>
          <Button 
          className={class_name.btnStyle}
          onClick={()=>handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </div>
      <div className="d-none d-md-block">
      <Footer />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    // height: "100vh",
    backgroundColor: "#f6f6f6",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "18%",
    paddingRight: "18%",
    paddingBottom: "7%",
    [theme.breakpoints.down('992')]: {
      padding: '0px 10%',
      paddingBottom: "8%",
    }
  },
  titleTxt: {
    marginTop: "110px",
    // fontFamily: "Poppins",
    fontSize: "30px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    [theme.breakpoints.down('768')]: {
      fontSize: '23px'
    },
    [theme.breakpoints.down('480')]: {
      fontSize: '20px',
      marginTop: "40px",
    },
  },
  infoTxt: {
    marginTop: "32px",
    // fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.46,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    [theme.breakpoints.down('768')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.down('480')]: {
      fontSize: '15px',
      marginTop: "20px",
    },
  },
  starRateTxt: {
    marginTop: "64px",
    marginBottom: '1%',
    // fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.54,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    [theme.breakpoints.down('768')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.down('768')]: {
      fontSize: '15px',
      marginTop: "30px",
    },
  },
  // textFieldStyles: {
  //   border: "solid 0.01px rgba(66, 70, 81, 0.36)",
  //   borderRadius: "10px",
  //   borderWidth: ".01px",
  //   height: "265px",
  // },
  pictureTxt: {
    marginTop: "64px",
    // fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.54,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    [theme.breakpoints.down('768')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.down('480')]: {
      fontSize: '15px',
      marginTop: "35px",
    },
  },
  pic_upload_input: {
    marginTop: "33px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: '0',
    // alignSelf: "center",
    // alignContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: "265px",
    border: "solid 0.5px rgba(66, 70, 81, 0.36)",
    borderRadius: "10px",
    [theme.breakpoints.down('480')]: {
      height: "200px",
      marginTop: "23px",
    },
  },
  camera_img: {
    width: "60px", 
    height: "50px",
    [theme.breakpoints.down("480")]: {
      width: '40px',
      height: '35px'
    }
  },
  uploadTxt: {
    marginTop: "27px",
    // fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.15,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.6)",
    [theme.breakpoints.down('480')]: {
      fontSize: '17px'
    }
  },
  btn_div: {
    display: "flex",
    justifyContent: 'flex-end',
    [theme.breakpoints.down('480')]: {
      display: 'flex',
      justifyContent: 'center'
    },
  },
  btnStyle: {
    backgroundColor: "#3973a5",
    color: "white",
    marginTop: "72px",
    // fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.15,
    letterSpacing: "normal",
    textAlign: "center",
    borderRadius: "0px",
    padding: "19px 138.5px",
    // width: "350px",
    // height: "65px",
    alignSelf: "flex-end",
    "&:hover":{
      backgroundColor: '#3973a5'
    },
    [theme.breakpoints.down('768')]: {
      padding: "19px 138.5px",
    },
    [theme.breakpoints.down('480')]: {
      marginTop: "23px",
      fontSize: "17px",
      padding: "15px 100px",
    },
  },
}));


          // style={{
          //   marginTop: "33px",
          //   display: "flex",
          //   flexDirection: "column",
          //   justifyContent: "center",
          //   // alignSelf: "center",
          //   // alignContent: "center",
          //   alignItems: "center",
          //   backgroundColor: "white",
          //   height: "265px",
          //   border: "solid 0.5px rgba(66, 70, 81, 0.36)",
          //   borderRadius: "10px",
          // }}