import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IMAGES from "../../../../Images";
import {
  boat_register_step1,
  single_boat_details_store,
} from "../../../../../redux/slices";
import Footer from "../../../../../Component/Footer/Footer";
import { toast } from "react-toastify";
import { PageHeader } from "../../../page-header/PageHeader";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import useWindowDimensions from "../../../../../UI kit/useWindowDimensions";
import Loader from "../../../../Loader";
import { useLocation } from "react-router-dom";
import { single_boat_data_API } from "../../../../../Service/api";
import { API } from "../../../../../Service/constants";
import ImageSlider from "../../../../Common/ViewImages/ImageSlider/ImageSlider";
import { CheckCircle, Cancel } from "@material-ui/icons";

export const BoatOfferStep1 = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const location = useLocation();
  const auth = useSelector((state) => state?.auth);
  const dashboard = useSelector((state) => state?.dashboard);
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState("");
  const [ministryOfTransDoc, setMinistryOfTransDoc] = useState("");
  const [generalDireOfBorderGuardDoc, setGeneralDireOfBorderGuardDoc] =
    useState("");
  const [
    boatDocumentationsAndLicensesDoc,
    setBoatDocumentationsAndLicensesDoc,
  ] = useState("");
  const [ministryOfTransDoc_API, setMinistryOfTransDoc_API] = useState("");
  const [generalDireOfBorderGuardDoc_API, setGeneralDireOfBorderGuardDoc_API] =
    useState("");
  const [
    boatDocumentationsAndLicensesDoc_API,
    setBoatDocumentationsAndLicensesDoc_API,
  ] = useState("");
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(false);
  const [selectedImageUrl_index, setSelectedImageUrl_index] = useState(false);
  const [selectedImageUrlFullData, setSelectedImageUrlFullData] =
    useState(false);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  //single boat details
  useEffect(() => {
    if (
      dashboard?.single_boat_details &&
      !dashboard?.boat_register_step1_data
    ) {
      console.log("single boat got");
      setMinistryOfTransDoc_API(
        dashboard?.single_boat_details?.ministry_transport_document
      );

      setGeneralDireOfBorderGuardDoc_API(
        dashboard?.single_boat_details?.border_guard_document
      );

      setBoatDocumentationsAndLicensesDoc_API(
        dashboard?.single_boat_details?.boat_license_document
      );
    }
    if (
      !ministryOfTransDoc &&
      !generalDireOfBorderGuardDoc &&
      !boatDocumentationsAndLicensesDoc
    ) {
      dispatch(
        boat_register_step1({
          ministry_of_transport_doc_1:
            dashboard?.single_boat_details?.ministry_transport_document,
          general_directorate_of_border_guard_doc_2:
            dashboard?.single_boat_details?.border_guard_document,
          boat_documentations_and_licenses_3:
            dashboard?.single_boat_details?.boat_license_document,
        })
      );
    }
  }, [dashboard?.single_boat_details]);

  //boat register step1 data
  useEffect(() => {
    if (dashboard?.single_boat_details && dashboard?.boat_register_step1_data) {
      console.log("update");
      if (dashboard?.boat_register_step1_data?.ministry_of_transport_doc) {
        dashboard?.boat_register_step1_data?.ministry_of_transport_doc_name &&
        dashboard?.boat_register_step1_data?.ministry_of_transport_doc_type
          ? setMinistryOfTransDoc(
              convertBase64ToFile(
                dashboard?.boat_register_step1_data?.ministry_of_transport_doc,
                dashboard?.boat_register_step1_data
                  ?.ministry_of_transport_doc_name,
                dashboard?.boat_register_step1_data
                  ?.ministry_of_transport_doc_type
              )
            )
          : setMinistryOfTransDoc_API(
              dashboard?.boat_register_step1_data?.ministry_of_transport_doc
            );
      }
      if (
        dashboard?.boat_register_step1_data
          ?.general_directorate_of_border_guard_doc
      ) {
        dashboard?.boat_register_step1_data
          ?.general_directorate_of_border_guard_doc_name &&
        dashboard?.boat_register_step1_data
          ?.general_directorate_of_border_guard_doc_type
          ? setGeneralDireOfBorderGuardDoc(
              convertBase64ToFile(
                dashboard?.boat_register_step1_data
                  ?.general_directorate_of_border_guard_doc,
                dashboard?.boat_register_step1_data
                  ?.general_directorate_of_border_guard_doc_name,
                dashboard?.boat_register_step1_data
                  ?.general_directorate_of_border_guard_doc_type
              )
            )
          : setGeneralDireOfBorderGuardDoc_API(
              dashboard?.boat_register_step1_data
                ?.general_directorate_of_border_guard_doc
            );
      }
      if (
        dashboard?.boat_register_step1_data?.boat_documentations_and_licenses
      ) {
        dashboard?.boat_register_step1_data
          ?.boat_documentations_and_licenses_name &&
        dashboard?.boat_register_step1_data
          ?.boat_documentations_and_licenses_type
          ? setBoatDocumentationsAndLicensesDoc(
              convertBase64ToFile(
                dashboard?.boat_register_step1_data
                  ?.boat_documentations_and_licenses,
                dashboard?.boat_register_step1_data
                  ?.boat_documentations_and_licenses_name,
                dashboard?.boat_register_step1_data
                  ?.boat_documentations_and_licenses_type
              )
            )
          : setBoatDocumentationsAndLicensesDoc_API(
              dashboard?.boat_register_step1_data
                ?.boat_documentations_and_licenses
            );
      }
    } else {
      console.log("register");
      if (dashboard?.boat_register_step1_data?.ministry_of_transport_doc) {
        let doc1 = convertBase64ToFile(
          dashboard?.boat_register_step1_data?.ministry_of_transport_doc,
          dashboard?.boat_register_step1_data?.ministry_of_transport_doc_name,
          dashboard?.boat_register_step1_data?.ministry_of_transport_doc_type
        );
        setMinistryOfTransDoc(doc1);
      }
      if (
        dashboard?.boat_register_step1_data
          ?.general_directorate_of_border_guard_doc
      ) {
        let doc2 = convertBase64ToFile(
          dashboard?.boat_register_step1_data
            ?.general_directorate_of_border_guard_doc,
          dashboard?.boat_register_step1_data
            ?.general_directorate_of_border_guard_doc_name,
          dashboard?.boat_register_step1_data
            ?.general_directorate_of_border_guard_doc_type
        );
        setGeneralDireOfBorderGuardDoc(doc2);
      }
      if (
        dashboard?.boat_register_step1_data?.boat_documentations_and_licenses
      ) {
        let doc3 = convertBase64ToFile(
          dashboard?.boat_register_step1_data?.boat_documentations_and_licenses,
          dashboard?.boat_register_step1_data
            ?.boat_documentations_and_licenses_name,
          dashboard?.boat_register_step1_data
            ?.boat_documentations_and_licenses_type
        );
        setBoatDocumentationsAndLicensesDoc(doc3);
      }
    }
  }, [dashboard?.boat_register_step1_data, dashboard?.single_boat_details]);

  useEffect(() => {
    if (
      ministryOfTransDoc ||
      generalDireOfBorderGuardDoc ||
      boatDocumentationsAndLicensesDoc
    ) {
      handleHistory();
    }
  }, [
    ministryOfTransDoc,
    generalDireOfBorderGuardDoc,
    boatDocumentationsAndLicensesDoc,
  ]);

  useEffect(() => {
    if (dashboard?.boat_register_step1_data?.ministry_of_transport_doc_1) {
      if (
        !dashboard?.boat_register_step1_data?.ministry_of_transport_doc_1.includes(
          "https"
        )
      ) {
        // setMinistryOfTransDoc("")
        setMinistryOfTransDoc(
          convertBase64ToFile(
            dashboard?.boat_register_step1_data?.ministry_of_transport_doc_1,
            dashboard?.boat_register_step1_data
              ?.ministry_of_transport_doc_1_name,
            dashboard?.boat_register_step1_data
              ?.ministry_of_transport_doc_1_type
          )
        );
      } else {
        console.log("https");
        setMinistryOfTransDoc_API(
          dashboard?.boat_register_step1_data?.ministry_of_transport_doc_1
        );
      }
    }
    if (
      dashboard?.boat_register_step1_data
        ?.general_directorate_of_border_guard_doc_2
    ) {
      if (
        !dashboard?.boat_register_step1_data?.general_directorate_of_border_guard_doc_2.includes(
          "https"
        )
      ) {
        setGeneralDireOfBorderGuardDoc(
          convertBase64ToFile(
            dashboard?.boat_register_step1_data
              ?.general_directorate_of_border_guard_doc_2,
            dashboard?.boat_register_step1_data
              ?.general_directorate_of_border_guard_doc_2_name,
            dashboard?.boat_register_step1_data
              ?.general_directorate_of_border_guard_doc_2_type
          )
        );
      } else {
        setGeneralDireOfBorderGuardDoc_API(
          dashboard?.boat_register_step1_data
            ?.general_directorate_of_border_guard_doc_2
        );
      }
    }
    if (
      dashboard?.boat_register_step1_data?.boat_documentations_and_licenses_3
    ) {
      if (
        !dashboard?.boat_register_step1_data?.boat_documentations_and_licenses_3.includes(
          "https"
        )
      ) {
        setBoatDocumentationsAndLicensesDoc(
          convertBase64ToFile(
            dashboard?.boat_register_step1_data
              ?.boat_documentations_and_licenses_3,
            dashboard?.boat_register_step1_data
              ?.boat_documentations_and_licenses_3_name,
            dashboard?.boat_register_step1_data
              ?.boat_documentations_and_licenses_3_type
          )
        );
      } else {
        setBoatDocumentationsAndLicensesDoc_API(
          dashboard?.boat_register_step1_data
            ?.boat_documentations_and_licenses_3
        );
      }
    }
  }, []);
  //
  //
  //
  //
  //
  //

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleImageClick = (item, index) => {
    setShowModal(true);
    setSelectedImageUrl(
      typeof item === "object" ? URL.createObjectURL(item) : `${item}`
    ); //${API.baseUrls[API.currentEnv]}
    setSelectedImageUrlFullData(item);
    setSelectedImageUrl_index(index);
  };

  const handleFileSelect = async (files, fileType) => {
    console.log(files[0].size / 1024 / 1024, "file size");
    if (files && files[0]) {
      const allowedExtensions = ["jpg", "jpeg", "png", "pdf",'docx','doc'];
      const selectedFile = files[0];
      const fileName = files[0]?.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (
        (allowedExtensions.includes(fileExtension) &&
          files[0].size / 1024 / 1024) <= 2
      ) {
        console.log(selectedFile,'file')
        switch (fileType) {
          case "ministryOfTrans":
            setMinistryOfTransDoc(selectedFile);
            break;
          case "generalDireOfBorderGuard":
            setGeneralDireOfBorderGuardDoc(selectedFile);
            break;
          case "boatDocumentationsAndLicenses":
            setBoatDocumentationsAndLicensesDoc(selectedFile);
            break;
          default:
            break;
        }
      } else {
        toast.error(
          "Invalid file extension. Please select a file with extensions: jpg, jpeg, png, pdf and file size should be below 2MB",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 20000,
          }
        );
      }
    }
  };

  function handleDrop(event, handlebyName) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    switch (handlebyName) {
      case "ministryOfTrans":
        handleFileSelect(files, "ministryOfTrans");
        break;
      case "generalDireOfBorderGuard":
        handleFileSelect(files, "generalDireOfBorderGuard");
        break;
      case "boatDocumentationsAndLicenses":
        handleFileSelect(files, "boatDocumentationsAndLicenses");
        break;
      default:
        break;
    }
  }

  function handleDragOver(event) {
    event.preventDefault();
  }

  //remove img
  const removeFile = (fileName) => {
    if (fileName === "ministry") {
      setMinistryOfTransDoc("");
      setMinistryOfTransDoc_API("");
    }
    if (fileName === "general") {
      setGeneralDireOfBorderGuardDoc("");
      setGeneralDireOfBorderGuardDoc_API("");
    }
    if (fileName === "boat") {
      setBoatDocumentationsAndLicensesDoc("");
      setBoatDocumentationsAndLicensesDoc_API("");
    }
  };

  //double click to select img
  const handleDoubleClick = (fileInputId) => {
    const fileInput = document.getElementById(fileInputId);
    if (fileInput) {
      fileInput.click();
    }
  };

  //header
  const handleHeaderCallBack = (name) => {
    toast.dismiss();
    if (name === "Home") {
      if (auth?.tokenDecodeData?.user_type === "BOAT_OWNER") {
        navigate("/boatOwnerDashBoard");
      } else {
        navigate("/rental");
      }
    } else if (name === "Log In") {
      navigate("/logIn");
    } else if (name === "Sign Up") {
      navigate("/signUP");
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else if (
      // name === "My Listings" ||
      name === "Boat Offers" ||
      name === "List a Boat Offer"
    ) {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (name === "/searchBoat") {
      navigate("/searchBoat");
    }
  };

  const convertFileToBase64 = async (file) => {
    if (file instanceof Blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const convertBase64ToFile = (base64String, fileName, fileType) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const file = new File([blob], fileName);

    return file;
  };

  // console.log(
  //   boatDocumentationsAndLicensesDoc,
  //   boatDocumentationsAndLicensesDoc_API,
  //   "mini",
  //   errorMsg
  // );
  //nxt page button
  const handleButtonClick = async () => {
    setErrorMsg("");
    setLoader(true);
    if (
      dashboard?.single_boat_details &&
      (ministryOfTransDoc !== "" || ministryOfTransDoc_API !== "") &&
      (generalDireOfBorderGuardDoc !== "" ||
        generalDireOfBorderGuardDoc_API !== "") &&
      (boatDocumentationsAndLicensesDoc !== "" ||
        boatDocumentationsAndLicensesDoc_API !== "")
    ) {
      console.log("working");
      if (ministryOfTransDoc) {
        var docc1 = await convertFileToBase64(ministryOfTransDoc);
      } else {
        docc1 = ministryOfTransDoc_API;
      }
      if (generalDireOfBorderGuardDoc) {
        var docc2 = await convertFileToBase64(generalDireOfBorderGuardDoc);
      } else {
        docc2 = generalDireOfBorderGuardDoc_API;
      }
      if (boatDocumentationsAndLicensesDoc) {
        var docc3 = await convertFileToBase64(boatDocumentationsAndLicensesDoc);
      } else {
        docc3 = boatDocumentationsAndLicensesDoc_API;
      }
      dispatch(
        boat_register_step1({
          ministry_of_transport_doc: docc1,
          ministry_of_transport_doc_name: ministryOfTransDoc?.name,
          ministry_of_transport_doc_type:
            ministryOfTransDoc?.type ||
            dashboard?.boat_register_step1_data?.ministry_of_transport_doc_type,
          general_directorate_of_border_guard_doc: docc2,
          general_directorate_of_border_guard_doc_name:
            generalDireOfBorderGuardDoc?.name,
          general_directorate_of_border_guard_doc_type:
            generalDireOfBorderGuardDoc?.type ||
            dashboard?.boat_register_step1_data
              ?.general_directorate_of_border_guard_doc_type,
          boat_documentations_and_licenses: docc3,
          boat_documentations_and_licenses_name:
            boatDocumentationsAndLicensesDoc?.name,
          boat_documentations_and_licenses_type:
            boatDocumentationsAndLicensesDoc?.type ||
            dashboard?.boat_register_step1_data
              ?.boat_documentations_and_licenses_type,
        })
      );
      navigate("/BoatOfferStep2");
      setMinistryOfTransDoc_API("");
      setGeneralDireOfBorderGuardDoc_API("");
      setBoatDocumentationsAndLicensesDoc_API("");
      setMinistryOfTransDoc("");
      setGeneralDireOfBorderGuardDoc("");
      setBoatDocumentationsAndLicensesDoc("");
      setLoader(false);
    } else if (
      ministryOfTransDoc !== "" &&
      generalDireOfBorderGuardDoc !== "" &&
      boatDocumentationsAndLicensesDoc !== ""
    ) {
      console.log("not working");
      let doc1 = await convertFileToBase64(ministryOfTransDoc);
      let doc2 = await convertFileToBase64(generalDireOfBorderGuardDoc);
      let doc3 = await convertFileToBase64(boatDocumentationsAndLicensesDoc);

      dispatch(
        boat_register_step1({
          ministry_of_transport_doc: doc1,
          ministry_of_transport_doc_name: ministryOfTransDoc?.name,
          ministry_of_transport_doc_type:
            ministryOfTransDoc?.type ||
            dashboard?.boat_register_step1_data?.ministry_of_transport_doc_type,
          general_directorate_of_border_guard_doc: doc2,
          general_directorate_of_border_guard_doc_name:
            generalDireOfBorderGuardDoc?.name,
          general_directorate_of_border_guard_doc_type:
            generalDireOfBorderGuardDoc?.type ||
            dashboard?.boat_register_step1_data
              ?.general_directorate_of_border_guard_doc_type,
          boat_documentations_and_licenses: doc3,
          boat_documentations_and_licenses_name:
            boatDocumentationsAndLicensesDoc?.name,
          boat_documentations_and_licenses_type:
            boatDocumentationsAndLicensesDoc?.type ||
            dashboard?.boat_register_step1_data
              ?.boat_documentations_and_licenses_type,
        })
      );
      navigate("/BoatOfferStep2");
      setMinistryOfTransDoc("");
      setGeneralDireOfBorderGuardDoc("");
      setBoatDocumentationsAndLicensesDoc("");
      setLoader(false);
    } else {
      console.log("error");
      setErrorMsg("Please select a file");
      setLoader(false);
    }
  };

  const handleHistory = async () => {
    let doc1 = await convertFileToBase64(ministryOfTransDoc);
    let doc2 = await convertFileToBase64(generalDireOfBorderGuardDoc);
    let doc3 = await convertFileToBase64(boatDocumentationsAndLicensesDoc);
    // console.log(doc1,'doc1')
    dispatch(
      boat_register_step1({
        ministry_of_transport_doc_1: doc1
          ? doc1
          : ministryOfTransDoc || ministryOfTransDoc_API,
        ministry_of_transport_doc_1_name:
          ministryOfTransDoc?.name ||
          dashboard?.boat_register_step1_data?.ministry_of_transport_doc_1_name,
        ministry_of_transport_doc_1_type:
          ministryOfTransDoc?.type ||
          dashboard?.boat_register_step1_data?.ministry_of_transport_doc_1_type,
        general_directorate_of_border_guard_doc_2: doc2
          ? doc2
          : generalDireOfBorderGuardDoc || generalDireOfBorderGuardDoc_API,
        general_directorate_of_border_guard_doc_2_name:
          generalDireOfBorderGuardDoc?.name ||
          dashboard?.boat_register_step1_data
            ?.general_directorate_of_border_guard_doc_2_name,
        general_directorate_of_border_guard_doc_2_type:
          generalDireOfBorderGuardDoc?.type ||
          dashboard?.boat_register_step1_data
            ?.general_directorate_of_border_guard_doc_2_type,
        boat_documentations_and_licenses_3: doc3
          ? doc3
          : boatDocumentationsAndLicensesDoc ||
            boatDocumentationsAndLicensesDoc_API,
        boat_documentations_and_licenses_3_name:
          boatDocumentationsAndLicensesDoc?.name ||
          dashboard?.boat_register_step1_data
            ?.boat_documentations_and_licenses_3_name,
        boat_documentations_and_licenses_3_type:
          boatDocumentationsAndLicensesDoc?.type ||
          dashboard?.boat_register_step1_data
            ?.boat_documentations_and_licenses_3_type,
      })
    );
  };

  return (
    <>
      <div className="show-header-outSide-banner">
        <PageHeader
          showLoginSignUp={auth?.AuthToken ? false : true}
          handle_navigation={handleHeaderCallBack}
          presentPage={""}
          link1={"Boat Offers"}
          // link2={"My Listings"}
          link2={"List a Boat Offer"}
          link3={
            auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
              ? "My Listings"
              : null
          }
        />
      </div>
      {loader ? <Loader loading={loader} /> : null}
      <div
        className="show-header-inside-banner"
        style={{ backgroundColor: "#f6f6f6" }}
      >
        <PageHeader
          showLoginSignUp={auth?.AuthToken ? false : true}
          handle_navigation={handleHeaderCallBack}
          presentPage={""}
          link1={"Boat Offers"}
          // link2={"My Listings"}
          link2={"List a Boat Offer"}
          link3={
            auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
              ? "My Listings"
              : null
          }
        />
      </div>
      <div>
        {showModal ? (
          <>
            <ImageSlider
              slides={selectedImageUrl}
              onClose={handleModalClose}
              item={selectedImageUrlFullData}
              index={selectedImageUrl_index}
              goToPrevious={null}
              goToNext={null}
            />
          </>
        ) : null}
      </div>

      <div className={class_name.page_body_container}>
        <Typography className={class_name.Show_off_your_boat_in_few_clicks}>
          Show off your boat in few clicks!
        </Typography>

        <div className={class_name.form_container_box}>
          <Typography className={class_name.step_1_txt}>Step 1</Typography>
          <Divider
            variant="fullWidth"
            style={{
              backgroundColor: "rgba(66, 70, 81, 0.9)",
              height: "1px",
              border: "solid .5px rgba(66, 70, 81, 0.2)",
            }}
          />
          {/* Ministry of Transport Documentations */}
          <div className={class_name.doc_content}>
            <Typography className={class_name.Doc_title_txt}>
              Ministry of Transport Documentations
            </Typography>
            <Typography className={class_name.Doc_info_txt}>
              Please upload all official documentations issued/licensed by The
              Ministry of Transport.
            </Typography>
            <div
              style={{
                border: dashboard?.single_boat_details
                  ?.ministry_transport_document_verified
                  ? "2px dashed green"
                  : (ministryOfTransDoc === "" &&
                      ministryOfTransDoc_API === "" &&
                      errorMsg) ||
                    (!dashboard?.single_boat_details
                      ?.ministry_transport_document_verified &&
                      !ministryOfTransDoc &&
                      dashboard?.single_boat_details &&
                      dashboard?.single_boat_details
                        ?.ministry_transport_verification_reason)
                  ? width > 767
                    ? "2px dashed red"
                    : "1px dashed red"
                  : "1px dashed rgba(66, 70, 81, 0.3)",
              }}
              className={class_name.choose_file_box}
            >
              {ministryOfTransDoc || ministryOfTransDoc_API ? (
                <div className={class_name.uploadedFile_box}>
                  <div className={class_name.img_ImgName}>
                    {
                    ministryOfTransDoc?.type === "application/pdf" ||
                    ministryOfTransDoc?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    dashboard?.boat_register_step1_data
                      ?.ministry_of_transport_doc_type === "application/pdf" ||
                    dashboard?.boat_register_step1_data
                      ?.ministry_of_transport_doc_type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                      ((
                        ministryOfTransDoc_API?.endsWith('.pdf') ||
                        ministryOfTransDoc_API?.endsWith('.docx') ||
                        ministryOfTransDoc_API?.endsWith('.doc') 
                      ) && !ministryOfTransDoc)
                       ? (
                      <>
                        {console.log("iam IN")}
                        <img
                          alt="pdf"
                          src={IMAGES.PDF}
                          className={class_name.selectedImg}
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            ministryOfTransDoc_API !== ""
                              ? `${ministryOfTransDoc_API}` //`http://localhost:3000/${ministryOfTransDoc_API}` ${API.baseUrls[API.currentEnv]}
                              : URL.createObjectURL(ministryOfTransDoc)
                          }
                          alt="img"
                          className={class_name.selectedImg}
                          onClick={() => {
                            handleImageClick(
                              ministryOfTransDoc
                                ? ministryOfTransDoc
                                : ministryOfTransDoc_API,
                              null
                            );
                          }}
                        />
                      </>
                    )}
                    <Typography className={class_name?.imgName_txt}>
                      {ministryOfTransDoc?.name ??
                        "ministry transport document"}
                    </Typography>
                  </div>

                  <img
                    onClick={() => removeFile("ministry")}
                    src={IMAGES.DELETE_ICON}
                    style={{
                      display: dashboard?.single_boat_details
                        ?.ministry_transport_document_verified
                        ? "none"
                        : "flex",
                    }}
                    alt="up-arrow"
                    className={class_name.up_arrow_style}
                  />
                  {dashboard?.single_boat_details &&
                  !ministryOfTransDoc &&
                  dashboard?.single_boat_details
                    ?.ministry_transport_verification_reason ||
                    dashboard?.single_boat_details
                    ?.ministry_transport_document_verified? (
                    <div className="d-flex justify-content-end">
                      {dashboard?.single_boat_details
                        ?.ministry_transport_document_verified ? (
                        <CheckCircle className="text-success" />
                      ) : (
                        <Cancel className="text-danger" />
                      )}
                    </div>
                  ) : null}
                </div>
              ) : (
                <>
                  <label
                    htmlFor="ministryOfTransInput"
                    onDoubleClick={() =>
                      handleDoubleClick("ministryOfTransInput")
                    }
                    className={class_name.lable_content}
                  >
                    <div
                      id="dropArea"
                      className={class_name.inside_label_content}
                      onDrop={(e) => handleDrop(e, "ministryOfTrans")}
                      onDragOver={handleDragOver}
                    >
                      <img
                        src={IMAGES.UP_ARROW_IMG}
                        alt="up-arrow"
                        className={class_name.up_arrow_style}
                      />
                      <div className={class_name.show_dragFile_txt_box}>
                        <Typography className={class_name?.Drag_file_to_upload}>
                          Drag file to upload, or <br/> 
                          <span 
                          className="text-secondary"
                          style={{
                            fontSize: 'clamp(5px,5vw,12px)'
                          }}
                          >
                            *Support only Jpg,Png,pdf
                          </span>
                        </Typography>
                        <Typography className={class_name?.chooseFileBtn}>
                          Choose File
                        </Typography>
                        <input
                          type="file"
                          id="ministryOfTransInput"
                          style={{ display: "none" }}
                          // disabled={false}
                          onChange={(e) => {
                            handleFileSelect(e.target.files, "ministryOfTrans");
                            // dispatch(
                            //   boat_register_step1({
                            //   ministry_of_transport_doc: convertFileToBase64(ministryOfTransDoc),
                            //   ministry_of_transport_doc_name: ministryOfTransDoc?.name,
                            //   ministry_of_transport_doc_type: ministryOfTransDoc?.type
                            // }))
                          }}
                          accept='.pdf,.jpg,.jpeg,.png'
                        />
                      </div>
                    </div>
                  </label>
                </>
              )}
            </div>
            {dashboard?.single_boat_details
              ?.ministry_transport_verification_reason &&
            !dashboard?.single_boat_details
              ?.ministry_transport_document_verified &&
            !ministryOfTransDoc ? (
              <Typography className={class_name.err_msg_style}>
                reason:{" "}
                {
                  dashboard?.single_boat_details
                    ?.ministry_transport_verification_reason
                }
              </Typography>
            ) : ministryOfTransDoc === "" && ministryOfTransDoc_API === "" ? (
              <Typography className={class_name.err_msg_style}>
                {errorMsg}
              </Typography>
            ) : null}
          </div>

          {/* General Directorate of Border Guard */}
          <div className={class_name.doc_content}>
            <Typography className={class_name.Doc_title_txt}>
              General Directorate of Border Guard
            </Typography>
            <Typography className={class_name.Doc_info_txt}>
              Please upload all official documentations issued/licensed by The
              General Directorate of Border Guard
            </Typography>
            <div
              style={{
                border: dashboard?.single_boat_details
                  ?.border_guard_document_verified
                  ? "2px dashed green"
                  : (generalDireOfBorderGuardDoc === "" &&
                      generalDireOfBorderGuardDoc_API === "" &&
                      errorMsg) ||
                    (!dashboard?.single_boat_details
                      ?.border_guard_document_verified &&
                      !generalDireOfBorderGuardDoc &&
                      dashboard?.single_boat_details &&
                      dashboard?.single_boat_details
                        ?.border_guard_verification_reason)
                  ? width > 767
                    ? "2px dashed red"
                    : "1px dashed red"
                  : "1px dashed rgba(66, 70, 81, 0.3)",
              }}
              className={class_name.choose_file_box}
            >
              {generalDireOfBorderGuardDoc ||
              generalDireOfBorderGuardDoc_API ? (
                <div className={class_name.uploadedFile_box}>
                  <div className={class_name.img_ImgName}>
                    {
                    generalDireOfBorderGuardDoc?.type === "application/pdf" ||
                    generalDireOfBorderGuardDoc?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    dashboard?.boat_register_step1_data
                      ?.general_directorate_of_border_guard_doc_type ===
                      "application/pdf" ||
                    dashboard?.boat_register_step1_data
                      ?.general_directorate_of_border_guard_doc_type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                      ((
                        generalDireOfBorderGuardDoc_API?.endsWith('.pdf')||
                        generalDireOfBorderGuardDoc_API?.endsWith('.docx')||
                        generalDireOfBorderGuardDoc_API?.endsWith('.doc')
                    ) 
                      && !generalDireOfBorderGuardDoc)
                      ? (
                      <img
                        alt="pdf"
                        src={IMAGES.PDF}
                        className={class_name.selectedImg}
                      />
                    ) : (
                      <>
                        <img
                          alt="img"
                          src={
                            generalDireOfBorderGuardDoc_API !== ""
                              ? `${generalDireOfBorderGuardDoc_API}` //`http://localhost:3000/${generalDireOfBorderGuardDoc_API}`
                              : URL.createObjectURL(generalDireOfBorderGuardDoc) //${API.baseUrls[API.currentEnv]}
                          }
                          className={class_name.selectedImg}
                          onClick={() => {
                            handleImageClick(
                              generalDireOfBorderGuardDoc
                                ? generalDireOfBorderGuardDoc
                                : generalDireOfBorderGuardDoc_API,
                              null
                            );
                          }}
                        />
                      </>
                    )}
                    <Typography className={class_name?.imgName_txt}>
                      {generalDireOfBorderGuardDoc?.name ??
                        "border guard document"}
                    </Typography>
                  </div>

                  <img
                    onClick={() => removeFile("general")}
                    src={IMAGES.DELETE_ICON}
                    style={{
                      display: dashboard?.single_boat_details
                        ?.border_guard_document_verified
                        ? "none"
                        : "flex",
                    }}
                    alt="up-arrow"
                    className={class_name.up_arrow_style}
                  />
                  {dashboard?.single_boat_details &&
                  !generalDireOfBorderGuardDoc &&
                  dashboard?.single_boat_details
                    ?.border_guard_verification_reason ||
                    dashboard?.single_boat_details
                    ?.border_guard_document_verified? (
                    <div className="d-flex justify-content-end">
                      {dashboard?.single_boat_details
                        ?.border_guard_document_verified? (
                        <CheckCircle className="text-success" />
                      ) : (
                        <Cancel className="text-danger" />
                      )}
                    </div>
                  ) : null}
                </div>
              ) : (
                <>
                  <label
                    htmlFor="generalDireOfBorderGuardInput"
                    onDoubleClick={() =>
                      handleDoubleClick("generalDireOfBorderGuardInput")
                    }
                    className={class_name.lable_content}
                  >
                    <div
                      id="dropArea"
                      className={class_name.inside_label_content}
                      onDrop={(e) => handleDrop(e, "generalDireOfBorderGuard")}
                      onDragOver={handleDragOver}
                    >
                      <img
                        src={IMAGES.UP_ARROW_IMG}
                        alt="up-arrow"
                        className={class_name.up_arrow_style}
                      />
                      <div className={class_name.show_dragFile_txt_box}>
                        <Typography className={class_name?.Drag_file_to_upload}>
                        Drag file to upload, or <br/> 
                          <span 
                          className="text-secondary"
                          style={{
                            fontSize: 'clamp(5px,5vw,12px)'
                          }}
                          >
                            *Support only Jpg,Png,pdf
                          </span>
                        </Typography>
                        <Typography className={class_name?.chooseFileBtn}>
                          Choose File
                        </Typography>
                        <input
                          type="file"
                          id="generalDireOfBorderGuardInput"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleFileSelect(
                              e.target.files,
                              "generalDireOfBorderGuard"
                            )
                          }
                          accept='.pdf,.jpg,.jpeg,.png'
                        />
                      </div>
                    </div>
                  </label>
                </>
              )}
            </div>
            {dashboard?.single_boat_details?.border_guard_verification_reason &&
            !dashboard?.single_boat_details?.border_guard_document_verified &&
            !generalDireOfBorderGuardDoc ? (
              <Typography className={class_name.err_msg_style}>
                reason:{" "}
                {
                  dashboard?.single_boat_details
                    ?.border_guard_verification_reason
                }
              </Typography>
            ) : generalDireOfBorderGuardDoc === "" &&
              generalDireOfBorderGuardDoc_API === "" ? (
              <Typography className={class_name.err_msg_style}>
                {errorMsg}
              </Typography>
            ) : null}
          </div>

          {/* Boat Documentations and licenses */}
          <div className={class_name.doc_content}>
            <Typography className={class_name.Doc_title_txt}>
              Boat Documentations and licenses
            </Typography>
            <Typography className={class_name.Doc_info_txt}>
              Please upload the boat's documentations, licenses, and permission
              to sail
            </Typography>
            <div
              style={{
                border: dashboard?.single_boat_details
                  ?.boat_license_document_verified
                  ? "2px dashed green"
                  : (boatDocumentationsAndLicensesDoc === "" &&
                      boatDocumentationsAndLicensesDoc_API === "" &&
                      errorMsg) ||
                    (!dashboard?.single_boat_details
                      ?.boat_license_document_verified &&
                      !boatDocumentationsAndLicensesDoc &&
                      dashboard?.single_boat_details &&
                      dashboard?.single_boat_details
                        ?.boat_license_verification_reason)
                  ? width > 767
                    ? "2px dashed red"
                    : "1px dashed red"
                  : "1px dashed rgba(66, 70, 81, 0.3)",
              }}
              className={class_name.choose_file_box}
            >
              {boatDocumentationsAndLicensesDoc ||
              boatDocumentationsAndLicensesDoc_API ? (
                <div className={class_name.uploadedFile_box}>
                  <div className={class_name.img_ImgName}>
                    {boatDocumentationsAndLicensesDoc?.type ===
                      "application/pdf" ||
                    boatDocumentationsAndLicensesDoc?.type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    dashboard?.boat_register_step1_data
                      ?.boat_documentations_and_licenses_type ===
                      "application/pdf" ||
                    dashboard?.boat_register_step1_data
                      ?.boat_documentations_and_licenses_type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                      ((
                        boatDocumentationsAndLicensesDoc_API?.endsWith('.pdf') ||
                        boatDocumentationsAndLicensesDoc_API?.endsWith('.docx') ||
                        boatDocumentationsAndLicensesDoc_API?.endsWith('.doc')
                      ) 
                        && !boatDocumentationsAndLicensesDoc)
                      ? (
                      <img
                        alt="pdf"
                        src={IMAGES.PDF}
                        className={class_name.selectedImg}
                      />
                    ) : (
                      <>
                        <img
                          alt="img"
                          src={
                            boatDocumentationsAndLicensesDoc_API !== ""
                              ? `${boatDocumentationsAndLicensesDoc_API}` //`http://localhost:3000/${boatDocumentationsAndLicensesDoc_API}`
                              : URL.createObjectURL(
                                  boatDocumentationsAndLicensesDoc
                                ) //${API.baseUrls[API.currentEnv]}
                          }
                          className={class_name.selectedImg}
                          onClick={() => {
                            handleImageClick(
                              boatDocumentationsAndLicensesDoc
                                ? boatDocumentationsAndLicensesDoc
                                : boatDocumentationsAndLicensesDoc_API,
                              null
                            );
                          }}
                        />
                      </>
                    )}
                    <Typography className={class_name?.imgName_txt}>
                      {boatDocumentationsAndLicensesDoc?.name ??
                        "boat license document"}
                    </Typography>
                  </div>

                  <img
                    onClick={() => removeFile("boat")}
                    style={{
                      display: dashboard?.single_boat_details
                        ?.boat_license_document_verified
                        ? "none"
                        : "flex",
                    }}
                    src={IMAGES.DELETE_ICON}
                    alt="up-arrow"
                    className={class_name.up_arrow_style}
                  />
                  {dashboard?.single_boat_details &&
                  !boatDocumentationsAndLicensesDoc &&
                  dashboard?.single_boat_details
                    ?.boat_license_verification_reason ||
                    dashboard?.single_boat_details
                    ?.boat_license_document_verified ? (
                    <div className="d-flex justify-content-end">
                      {dashboard?.single_boat_details
                        ?.boat_license_document_verified ? (
                        <CheckCircle className="text-success" />
                      ) : (
                        <Cancel className="text-danger" />
                      )}
                    </div>
                  ) : null}
                </div>
              ) : (
                <>
                  <label
                    htmlFor="boatDocumentationsAndLicensesInput"
                    onDoubleClick={() =>
                      handleDoubleClick("boatDocumentationsAndLicensesInput")
                    }
                    className={class_name.lable_content}
                  >
                    <div
                      id="dropArea"
                      className={class_name.inside_label_content}
                      onDrop={(e) =>
                        handleDrop(e, "boatDocumentationsAndLicenses")
                      }
                      onDragOver={handleDragOver}
                    >
                      <img
                        src={IMAGES.UP_ARROW_IMG}
                        alt="up-arrow"
                        className={class_name.up_arrow_style}
                      />

                      <div className={class_name.show_dragFile_txt_box}>
                        <Typography className={class_name?.Drag_file_to_upload}>
                        Drag file to upload, or <br/> 
                          <span 
                          className="text-secondary"
                          style={{
                            fontSize: 'clamp(5px,5vw,12px)'
                          }}
                          >
                            *Support only Jpg,Png,pdf
                          </span>
                        </Typography>
                        <Typography className={class_name?.chooseFileBtn}>
                          Choose File
                        </Typography>
                        <input
                          type="file"
                          id="boatDocumentationsAndLicensesInput"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleFileSelect(
                              e.target.files,
                              "boatDocumentationsAndLicenses"
                            )
                          }
                          accept='.pdf,.jpg,.jpeg,.png'
                        />
                      </div>
                    </div>
                  </label>
                </>
              )}
            </div>
            {dashboard?.single_boat_details?.boat_license_verification_reason &&
            !dashboard?.single_boat_details?.boat_license_document_verified &&
            !boatDocumentationsAndLicensesDoc ? (
              <Typography className={class_name.err_msg_style}>
                reason:{" "}
                {
                  dashboard?.single_boat_details
                    ?.boat_license_verification_reason
                }
              </Typography>
            ) : boatDocumentationsAndLicensesDoc === "" &&
              boatDocumentationsAndLicensesDoc_API === "" ? (
              <Typography className={class_name.err_msg_style}>
                {errorMsg}
              </Typography>
            ) : null}
          </div>

          {/* Save & Continue */}
          <div className={class_name.btnDiv}>
            <div
              className={class_name?.save_and_continue_btn}
              onClick={() => handleButtonClick()}
              style={{
                backgroundColor:
                  (boatDocumentationsAndLicensesDoc !== "" ||
                    boatDocumentationsAndLicensesDoc_API !== "") &&
                  (generalDireOfBorderGuardDoc !== "" ||
                    generalDireOfBorderGuardDoc_API !== "") &&
                  (ministryOfTransDoc !== "" || ministryOfTransDoc_API !== "")
                    ? "#026b93"
                    : "rgba(151, 151, 151, 1)",
              }}
            >
              <Typography
                className={`${class_name.save_and_continue_txt} hoverEffect`}
              >
                Save & Continue
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-style-hide">
        <Footer />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form_container_box: {
    backgroundColor: "#fff",
    alignSelf: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    border: "solid 0.5px rgba(66, 70, 81, 0.1)",
    paddingLeft: "5%",
    paddingRight: "5%",
    borderRadius: "2px",
    width: "90%",
    marginTop: "15px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "30px",
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "40px",
      width: "70%",
    },
  },
  page_body_container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f6f6f6",
    paddingBottom: "100px",
    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
      WebkitOverflowScrolling: "touch",
    },
  },
  Show_off_your_boat_in_few_clicks: {
    marginTop: "40px",
    fontSize: "clamp(26px, 4vw, 45px)",

    fontWeight: "bolder",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.51,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#424651",
  },
  step_1_txt: {
    fontSize: "clamp(16px, 4vw, 35px)",

    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.51,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    padding: "20px 0px",
  },
  doc_content: {
    display: "flex",
    flexDirection: "column",
    // marginTop: "15px",
    marginTop: "24px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "107px",
    },
  },
  Doc_title_txt: {
    fontSize: "clamp(14px, 3.5vw, 30px)",

    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.53,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    // backgroundColor: "lightgreen",
  },
  Doc_info_txt: {
    fontSize: "clamp(10px, 2.5vw, 20px)",

    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    // backgroundColor: "orange",
    marginTop: "5px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "15px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "20px",
    },
  },
  up_arrow_style: {
    width: "clamp(20px, 5vw, 62.8px)",
    height: "clamp(20px, 5vw, 62.8px)",
  },

  choose_file_box: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    borderStyle: "dashed",
    borderColor: "rgba(66, 70, 81, 0.3)",
    borderRadius: "10px",
    borderWidth: 1,
    cursor: "pointer",
    padding: "20px 0px",
    marginTop: "10px",
    // marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      padding: "20px 10px",
      marginTop: "20px",
      // marginBottom: "15px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "30px 10px",
      marginTop: "30px",
      // marginBottom: "15px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "40px 20px",
      marginTop: "50px",
    },
  },

  lable_content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    width: "100%",
    // backgroundColor: "yellow",
    cursor: "pointer",
  },

  inside_label_content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    width: "100%",
  },

  Drag_file_to_upload: {
    fontSize: "clamp(10px, 2vw, 20px)",
    textAlign: "center",
    color: "#424651",
  },

  chooseFileBtn: {
    fontSize: "clamp(9px, 1vw, 18px)",
    color: "white",
    textAlign: "center",
    backgroundColor: "#3973A5",
    marginLeft: "5%",
    borderRadius: "15px",
    padding: "4px 12px",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 14px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "8px 16px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "12px 20px",
    },
  },

  btnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "60px 0px",
  },

  save_and_continue_btn: {
    cursor: "pointer",
    borderRadius: "15px",
    border: "solid 1px #026b93",
    padding: "2% 7%",
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    transition: "background-color 0.3s",
    backgroundColor: "#026b93", // Add a smooth transition for the background color
    "&:hover": {
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
      // Change the background color on hover
      "& $save_and_continue_txt": {},
    },
  },
  save_and_continue_txt: {
    fontSize: "clamp(8px, 2vw, 24px)", // Adjust the range as needed
    fontWeight: "600",

    textAlign: "center",
    color: "white",
  },

  uploadedFile_box: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  },
  img_ImgName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  selectedImg: {
    width: "clamp(24px, 5vw, 100px)",
    height: "clamp(24px, 5vw, 100px)",
  },

  imgName_txt: {
    fontSize: "clamp(10px, 2vw, 20px)",

    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#424651",
    marginLeft: "10px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "30px",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "70px",
    },
  },

  err_msg_style: {
    fontSize: "clamp(10px, 1vw, 20px)",
    color: "red",
    textAlign: "left",
  },
  show_dragFile_txt_box: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(300)]: {
      width: "80%",
    },
    [theme.breakpoints.up(300)]: {
      width: "70%",
    },
    [theme.breakpoints.up(400)]: {
      width: "50%",
    },
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    Show_off_your_boat_in_few_clicks: {
      marginTop: "20px",
    },
    form_container_box: {
      backgroundColor: "#fff",
      alignSelf: "center",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      paddingLeft: "5%",
      paddingRight: "5%",
      borderRadius: "15px",
      width: "90%",
      marginTop: "15px",
      border: "solid 1px rgba(66, 70, 81, 0.1)",
      [theme.breakpoints.up("sm")]: {
        marginTop: "30px",
        width: "90%",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "30px",
        width: "80%",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "40px",
        width: "70%",
      },
    },
    chooseFileBtn: {
      fontSize: "clamp(5px, 1vw, 9px)",
      color: "white",
      textAlign: "center",
      backgroundColor: "#3973A5",
      borderRadius: "15px",
      padding: "4px 12px",
      [theme.breakpoints.up("sm")]: {
        padding: "6px 14px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "8px 16px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "12px 20px",
      },
    },

    save_and_continue_btn: {
      borderRadius: "0px",
      position: "fixed",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: "rgba(151, 151, 151, 1)",
      border: "solid 1px rgba(151, 151, 151, 1)",
      padding: "0px",
      width: "100%",
      height: "56px",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    save_and_continue_txt: {
      fontSize: "clamp(16px, 5vw, 20px)", // Adjust the range as needed
      fontWeight: "400",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.96,
      letterSpacing: "normal",
      textAlign: "center",
      color: "white",
    },
  },
}));
