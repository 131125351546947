/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import IMAGES from "../../Images";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Drawer, ListItem, Typography } from "@material-ui/core";
import "./PageHeader.css";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@mui/material/Divider";
import useWindowDimensions from "../../../UI kit/useWindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthToken,
  GetUserDetails,
  TokenDecodeData,
  UserId,
  UserType,
  openChat,
  EmailId,
  GetUserCity,
  Password,
  boatServiceList,
  boatTypeList,
  boat_register_location_data,
  boat_register_step1,
  boat_register_step2,
  boat_register_step3,
  confirmTickMsg,
  search_boat_id,
  signUp_payload,
  single_boat_details_store,
  verifyOTP,
  popup_page,
  setting_tab,
  chat_count,
} from "../../../redux/slices";
import { API } from "../../../Service/constants";
import { notifications_count } from "../../../Service/api";
import socket from "../../../Service/SocketIo";
import useStorage from "../../../hooks/useStorage";
import PopupPage from "../../Common/PopupPage/PopupPage";

export const PageHeader = ({
  showLoginSignUp,
  handle_navigation,
  presentPage,
  link1,
  link2,
  link3,
  link4,
  //
  drawerIsOpen = () => {},
}) => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { height, width } = useWindowDimensions();
  const [openModal, setOpenModal] = useState(false);
  const auth = useSelector((state) => state?.auth);
  const dashboard = useSelector((state) => state?.dashboard);
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const [notifyCount, setNotifyCount] = useState(null);
  const [chatCount, setChatCount] = useState(null);
  const [openPopup, setOpenPopup] = useState(false)
  const {pathname} = useLocation()

  useEffect(()=>{
    console.log(pathname,'pathname')
    if(pathname !== '/setting'){
      dispatch(setting_tab(null))
    }
  },[pathname])

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  // console.log(auth,'auth')

  const handleOpenLogoutConfirm = () => {
    navigate("/LogOutModal");
  };

  const handleLogout = async () => {
    drawerIsOpen(false);

    const logoutCallback = async () => {
      // Create an array of promises for all the dispatch calls
      const dispatchPromises = [
        setTimeout(() => {
          localStorage?.removeItem('session')
          dispatch(GetUserDetails(null))
          dispatch(UserId(null))
          dispatch(TokenDecodeData(null))
          dispatch(openChat(null))
          dispatch(boat_register_location_data(null))
          dispatch(boat_register_step1(null))
          dispatch(boat_register_step2(null))
          dispatch(boat_register_step3(null))
          dispatch(single_boat_details_store(null))
          dispatch(AuthToken(null))
          socket.removeToken()
        }, 200),
        // dispatch(EmailId(null)),
        // dispatch(GetUserCity(null)),
        // dispatch(Password(null)),
        // dispatch(boatServiceList(null)),
        // dispatch(boatTypeList(null)),
        // dispatch(confirmTickMsg(null)),
        // dispatch(search_boat_id(null)),
        // dispatch(signUp_payload(null)),
        // dispatch(verifyOTP(null)),
      ];

      // Wait for all the dispatch calls to complete
      await Promise.all(dispatchPromises);

      // After all dispatch calls are completed, you can proceed with the next steps

      // window.location.replace("/logIn")
      await navigate("/logIn", { replace: true });

      setOpenModal(false);
    };

    return logoutCallback();
  };

  useEffect(() => {
    if (width < 910) {
      setOpenModal(false);
      drawerIsOpen(false);
    }
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal, so close it
        setOpenModal(false);
        drawerIsOpen(false);
      }
    };

    if (openModal) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };
  }, [drawerIsOpen, openModal, width]);

  useEffect(() => {
    if (width > 910) {
      drawerIsOpen(false);
      setOpenDrawer(false);
    }
  }, [drawerIsOpen, width]);

  // socket
  useEffect(() => {
    socket.connect(auth?.AuthToken);

    return () => {
      // socket.disconnect();
    };

    // socket.client.on("connect", () => {
    //   console.log("connected");
    // });

    // return () => {
    //   // User leaves room
    //   socket.client.disconnect();

    //   socket.client.off("disconnect", () => {
    //     console.log("disconnected");
    //   });
    // };
    // }
  }, [window.location.search]);
  // console.log(auth?.userDetails?.user_type,'user type')

  //socket.emit
  useEffect(() => {
    if(auth?.userDetails?.user_type === 'BOAT_OWNER'){
      socket.client.emit("getUnreadChatRoomsCount", auth?.userId, (err) => {
        console.log("getunread chat room count err", err);
      });
    }

    if(auth?.userDetails?.user_type === 'CUSTOMER'){
      socket.client.emit("getUnreadChatRoomsCountforcus", auth?.userId, (err) => {
        console.log("getunread chat room count err", err);
      });
    }

    socket.client.emit("getUnreadCountNotification", auth?.userId, (err) => {
      console.log("getunread count notification err", err);
    });
  }, []);

  //socket.on
  useEffect(() => {
    socket.client.on("unread_countof_notification", (unreadNotify) => {
      console.log(unreadNotify,'notification count',auth?.userId)
      if (unreadNotify?.userId === auth?.userId) {
        setNotifyCount(unreadNotify);
      }
    });

    socket.client.on("unreadcountChatroom", (unreadChatCount) => {
      console.log(unreadChatCount,'socket chat owner count',auth?.userId)
      if (unreadChatCount?.userId === auth?.userId && auth?.userDetails?.user_type === 'BOAT_OWNER') {
        setChatCount(unreadChatCount);
        dispatch(chat_count(unreadChatCount))
      }
    });

    socket.client.on("unreadcountChatroomCus", (unreadChatCount) => {
      console.log(unreadChatCount,'socket chat cus count',auth?.userId)
      if (unreadChatCount?.userId === auth?.userId && auth?.userDetails?.user_type === 'CUSTOMER') {
        setChatCount(unreadChatCount);
        dispatch(chat_count(unreadChatCount))
      }
    });
  }, []);
  console.log(chatCount,'chat count',dashboard?.chat_count)

  const handleClosePopup = () =>{
    setOpenPopup(false)
  }

  const handleStatusPopup = (status) => {
    if (status?.button === "accept") {
      handleLogout()
      setOpenPopup(false)
    } else {
      setOpenPopup(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div className={class_name.navi_container_box}>
        <div className="header-container-style">
          {/* ---------------->> open drawer btn <<---------------- */}
          <div
            className="drawer-menu-icon"
            onClick={() => {  
              drawerIsOpen(true);
              setOpenDrawer(true);
            }}
            style={{ backgroundColor: "white", cursor: "pointer" }}
          >
            <img src={IMAGES?.MENU_ICON} alt="menu" className="menu-icon" />
          </div>
          {/* ---------------->> list content <<---------------- || ---> appIcon <----> tab-list <---> search,mail,bell,-icons <--- */}
          <div className="all-content-box">
            <div className="icon-tabName-content">
              <img
                src={IMAGES.APP_ICON}
                className={class_name.picture_style}
                alt="logo"
                onClick={() => {
                  if (auth?.AuthToken) {
                    if (auth?.userDetails?.user_type === "BOAT_OWNER") {
                      navigate("/boatOwnerDashBoard");
                    } else {
                      navigate("/rental");
                    }
                  } else {
                    navigate("/");
                  }
                }}
                // onDoubleClick={() => {
                //   navigate("/");
                // }}
              />

              <div className="tab-name-list">
                <Typography
                  className={class_name.header_content_txt}
                  style={{
                    color: presentPage === "Home" ? "#026b93" : "#424651", //"#026b93",
                    fontWeight: presentPage === "Home" ? "bolder" : "400"//"bolder",
                  }}
                  onClick={() => {
                    if (auth?.AuthToken) {
                      if (auth?.userDetails?.user_type === "BOAT_OWNER") {
                        navigate("/boatOwnerDashBoard");
                      } else {
                        navigate("/rental");
                      }
                    } else {
                      navigate("/");
                    }
                  }}
                >
                  {"Home"}
                </Typography>

                {link1 ? (
                  <>
                    <Typography
                      className={class_name.header_content_txt}
                      style={{
                        color: presentPage === link1 ? "#026b93" : "#424651",
                        fontWeight: presentPage === link1 ? "bolder" : "400",
                      }}
                      onClick={() => {
                        //   clicktoScroll
                        //     ? handleLink1Click()
                        //     :
                        handle_navigation(link1);
                      }}
                    >
                      {link1}
                    </Typography>
                  </>
                ) : null}
                {link2 ? (
                  <Typography
                    className={class_name.header_content_txt}
                    style={{
                      color: presentPage === link2 ? "#026b93" : "#424651",
                      fontWeight: presentPage === link2 ? "bolder" : "400",
                    }}
                    onClick={() => {
                      handle_navigation(link2);
                    }}
                  >
                    {link2}
                  </Typography>
                ) : null}
                {link3 ? (
                  <Typography
                    className={class_name.header_content_txt}
                    style={{
                      color: presentPage === link3 ? "#026b93" : "#424651",
                      fontWeight: presentPage === link3 ? "bolder" : "400",
                    }}
                    onClick={() => {
                      handle_navigation(link3);
                    }}
                  >
                    {link3}
                  </Typography>
                ) : null}

                {link4 ? (
                  <Typography
                    className={class_name.header_content_txt}
                    style={{
                      color: presentPage === link4 ? "#026b93" : "#424651",
                      fontWeight: presentPage === link4 ? "bolder" : "400",
                    }}
                    onClick={() => {
                      handle_navigation(link4);
                    }}
                  >
                    {link4}
                  </Typography>
                ) : null}
              </div>
            </div>

            {showLoginSignUp ? (
              <div
                // className="icon-group"
                style={{
                  display: "flex",
                  alignItems: "center",
                  // backgroundColor: "lightgray",
                  // flexWrap: "nowrap",
                  // width: "100%",
                }}
              >
                <Typography
                  className={class_name.header_content_txt}
                  style={{
                    fontWeight: "bold",
                    width: "100%",
                  }}
                  onClick={() => {
                    navigate("/logIn");
                  }}
                >
                  LogIn
                </Typography>
                <Typography
                  className={class_name.header_content_txt}
                  style={{
                    fontWeight: "bold",
                    width: "100%",
                  }}
                  onClick={() => {
                    navigate("/userChoice");
                    dispatch(UserType(null));
                  }}
                >
                  SignUp
                </Typography>
              </div>
            ) : (
              <div className="icon-group">
                <img
                  alt="iocn"
                  src={IMAGES.SEARCH}
                  className="search-mail-icon"
                  onClick={() => {
                    navigate("/searchBoat");
                  }}
                />
                <div style={{ position: "relative" }}>
                  <img
                    alt="iocn"
                    src={IMAGES.EMAIL_ICON}
                    className="search-mail-icon"
                    onClick={() => {
                      dispatch(openChat(null));
                      navigate("/allMessage");
                    }}
                  />
                  {dashboard?.chat_count?.unreadCount
                  ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "-10px",
                        left: "12px",
                        backgroundColor: "#026b93",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "21px",
                        height: "21px",
                        color: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <span>{dashboard?.chat_count?.unreadCount}</span>
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    alt="icon"
                    src={IMAGES.BELL}
                    className="bell-icon"
                    onClick={() => {
                      navigate("/notification");
                      // handleNotificationCount()
                    }}
                  />
                  {notifyCount?.unreadCount ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "-10px",
                        left: "12px",
                        backgroundColor: "#026b93",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "21px",
                        height: "21px",
                        color: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <span>{notifyCount?.unreadCount}</span>
                    </div>
                  ) : null}
                </div>
                <div className={class_name.profile_icon_div}>
                  <img
                    alt="icon"
                    src={
                      auth?.userDetails?.profile_image
                        ? `
                        
                        ${
                          auth?.userDetails?.profile_image //${API.baseUrls[API.currentEnv]}
                        }`
                        : IMAGES.PROFILE_ICON
                    }
                    className={class_name.profile_icon_style}
                    onClick={() => {
                      handleOpenModal();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* ===========================================================            Drawer            ========================================= */}
        <Drawer
          anchor="left"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          PaperProps={{
            style: {
              width: "70%",
              backgroundColor: "rgba(246, 246, 246, 1)",
              padding: 0,
              margin: 0,
              height: "100%",
            },
          }}
          data-bs-toggle="collapse"
          style={{
            padding: 0,
            margin: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {/* ======================================================= App Icon ======================================================= */}
            <div
              style={{
                display: "flex",
                padding: "0 0 0 5%",
                width: "100%",
              }}
            >
              <img
                src={IMAGES.LEFT_BACK_BUTTON}
                alt="close"
                style={{
                  width: "16px",
                  height: "14px",
                  alignSelf: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  drawerIsOpen(false);
                  setOpenDrawer(false);
                }}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={IMAGES.APP_ICON}
                  className={class_name.drawer_app_icon_style}
                  alt="logo"
                />
              </div>
            </div>

            {/* =============================================== Profile ===&&===  Tab Options  ============================================== */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "space-between",
                padding: 0,
                margin: 0,
              }}
            >
              <ListItem button onClick={() => {}}>
                {!showLoginSignUp ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: "30px 0",
                      width: "100%",
                      cursor: "auto",
                    }}
                    onClick={() => {
                      navigate("/setting");
                    }}
                  >
                    <img
                      alt="profile"
                      src={
                        auth?.userDetails?.profile_image
                          ? // ${
                            auth?.userDetails?.profile_image //${API.baseUrls[API.currentEnv]}
                          : // }
                            IMAGES.PROFILE_ICON
                      }
                      className={class_name.profile_icon_style}
                      style={{
                        cursor: "auto",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "16px",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {auth?.userDetails?.first_name}
                      </Typography>
                      <Typography style={{}}>
                        {auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
                          ? "Boat Owner"
                          : "Customer"}
                      </Typography>
                    </div>
                  </div>
                ) : null}
              </ListItem>

              <>
                <ListItem
                  button
                  onClick={() => {
                    setOpenDrawer(false);
                    handle_navigation("Home");
                  }}
                >
                  <Typography
                    className="custom-text-style"
                    style={{
                      color: presentPage === "Home" ? "#026b93" : "#424651",
                    }}
                  >
                    {"Home"}
                  </Typography>
                </ListItem>

                {auth?.AuthToken ? (
                  <ListItem
                    button
                    onClick={() => {
                      setOpenPopup(true)
                      dispatch(
                        popup_page({
                          ride_mode: "log out",
                        })
                      );
                      setOpenModal(false)
                      setOpenDrawer(false)
                    }}
                  >
                    <Typography
                      className={class_name.drawer_content_txt}
                      style={{}}
                    >
                      {"Log Out"}
                    </Typography>
                  </ListItem>
                ) : null}

                {link1 ? (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        setOpenDrawer(false);
                        handle_navigation(link1);
                      }}
                    >
                      <Typography
                        className="custom-text-style"
                        style={{
                          color: presentPage === link1 ? "#026b93" : "#424651",
                        }}
                      >
                        {link1}
                      </Typography>
                    </ListItem>
                  </>
                ) : null}
                {link2 ? (
                  <ListItem
                    button
                    onClick={() => {
                      setOpenDrawer(false);
                      handle_navigation(link2);
                    }}
                  >
                    <Typography
                      className="custom-text-style"
                      style={{
                        color: presentPage === link2 ? "#026b93" : "#424651",
                      }}
                    >
                      {link2}
                    </Typography>
                  </ListItem>
                ) : null}
                {link3 ? (
                  <ListItem
                    button
                    onClick={() => {
                      //   clicktoScroll ? handleLink1Click2() :
                      handle_navigation(link3);
                    }}
                  >
                    <Typography
                      className="custom-text-style"
                      style={{
                        color: presentPage === link3 ? "#026b93" : "#424651",
                      }}
                    >
                      {link3}
                    </Typography>
                  </ListItem>
                ) : null}

                {link4 ? (
                  <ListItem
                    button
                    onClick={() => {
                      //   clicktoScroll ? handleLink1Click2() :
                      handle_navigation(link4);
                    }}
                  >
                    <Typography
                      className="custom-text-style"
                      style={{
                        color: presentPage === link4 ? "#026b93" : "#424651",
                      }}
                    >
                      {link4}
                    </Typography>
                  </ListItem>
                ) : null}

                {/* {auth?.userDetails?.user_type === 'BOAT_OWNER' ? ( */}
                  <ListItem
                    button
                    onClick={() => {
                      //   clicktoScroll ? handleLink1Click2() :
                      // handle_navigation(link4);
                      navigate('/bookingHistory')
                    }}
                  >
                    <Typography
                      className="custom-text-style"
                      style={{
                        color: presentPage === link4 ? "#026b93" : "#424651",
                      }}
                    >
                      {'Booking History'}
                    </Typography>
                  </ListItem>
                {/* ) : null}  */}

                {showLoginSignUp ? (
                  <>
                    <Divider
                      variant="middle"
                      style={{
                        backgroundColor: "rgba(172, 172, 172, 0.4)",
                        height: "1px",
                      }}
                    />

                    <ListItem
                      button
                      onClick={() => {
                        setOpenDrawer(false);
                        navigate("/logIn");
                      }}
                    >
                      <Typography
                        className="custom-text-style"
                        style={{
                          color:
                            presentPage === "LogIn" ? "#026b93" : "#424651",
                        }}
                      >
                        Log In
                      </Typography>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        setOpenDrawer(false);
                        navigate("/userChoice");
                        dispatch(UserType(null));
                      }}
                    >
                      <Typography
                        className="custom-text-style"
                        style={{
                          color:
                            presentPage === "SignUp" ? "#026b93" : "#424651",
                        }}
                      >
                        Sign Up
                      </Typography>
                    </ListItem>
                  </>
                ) : null}

                <Divider
                  variant="middle"
                  style={{
                    backgroundColor: "rgba(172, 172, 172, 0.4)",
                    height: "1px",
                  }}
                />
                {!showLoginSignUp ? (
                  <ListItem
                    button
                    onClick={() => {
                      setOpenDrawer(false);
                      navigate("/setting");
                    }}
                  >
                    <Typography
                      className="custom-text-style"
                      style={{
                        color:
                          presentPage === "About Us" ? "#026b93" : "#424651",
                      }}
                    >
                      {"Setting"}
                    </Typography>
                  </ListItem>
                ) : null}

                <ListItem
                  button
                  onClick={() => {
                    handle_navigation("");
                  }}
                >
                  <Typography
                    className="custom-text-style"
                    style={{
                      color: presentPage === "About Us" ? "#026b93" : "#424651",
                    }}
                  >
                    {"About Us"}
                  </Typography>
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    handle_navigation("");
                  }}
                >
                  <Typography
                    className="custom-text-style"
                    style={{
                      color:
                        presentPage === "Help & Support"
                          ? "#026b93"
                          : "#424651",
                    }}
                  >
                    {"Help & Support"}
                  </Typography>
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    handle_navigation("");
                  }}
                >
                  <Typography
                    className="custom-text-style"
                    style={{
                      color:
                        presentPage === "Contact Us" ? "#026b93" : "#424651",
                    }}
                  >
                    {"Contact Us"}
                  </Typography>
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    handle_navigation("");
                  }}
                >
                  <Typography
                    className="custom-text-style"
                    style={{
                      color:
                        presentPage === "Terms of Service"
                          ? "#026b93"
                          : "#424651",
                    }}
                  >
                    {"Terms of Service"}
                  </Typography>
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    handle_navigation("");
                  }}
                >
                  <Typography
                    className="custom-text-style"
                    style={{
                      color:
                        presentPage === "Privacy Policy"
                          ? "#026b93"
                          : "#424651",
                    }}
                  >
                    {"Privacy Policy"}
                  </Typography>
                </ListItem>
              </>

              <Divider
                variant="middle"
                style={{
                  backgroundColor: "rgba(172, 172, 172, 0.4)",
                  height: "1px",
                }}
              />
            </div>
          </div>

          {/* ======================================================= End-Content ======================================================= */}
          <div className={class_name.end_content_style_box}>
            {/* ======================================================= share-Icon ======================================================= */}
            <div className={class_name.end_content_media_Icons_box}>
              <div className={class_name.social_media_icon_box}>
                <img
                  className={class_name.social_media_icon_style}
                  src={IMAGES.TWITTER_ICON}
                  alt="Twitter"
                />
              </div>

              <div className={class_name.social_media_icon_box}>
                <img
                  className={class_name.social_media_icon_style}
                  src={IMAGES.INSTA_ICON}
                  alt="instagram"
                />
              </div>
              <div className={class_name.social_media_icon_box}>
                <img
                  className={class_name.social_media_icon_style}
                  src={IMAGES.SNAPCHAT_ICON}
                  alt="snapchat"
                />
              </div>
              <div className={class_name.social_media_icon_box}>
                <img
                  className={class_name.social_media_icon_style}
                  src={IMAGES.YOUTUBE_ICON}
                  alt="youtube"
                />
              </div>
            </div>

            {/* ======================================================= Copy-Writes ======================================================= */}

            <div className={class_name.copy_rty_style_box}>
              <Typography style={{}} className={class_name.copyRty_txt}>
                Copyright 2022 ...
              </Typography>
            </div>
          </div>
        </Drawer>
      </div>

      {/* ======================================================= LOG OUT Modal-Content ======================================================= */}

      {openModal ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            backgroundColor: "lightgoldenrodyellow",
            justifyContent: "flex-end",
            paddingRight: "10%",
          }}
        >
          <div className={class_name.profile_modal} ref={modalRef}>
            <div className={class_name.arrowWrapper}>
              <div className={class_name.arrowContainer}>
                <div className={class_name.arrowInside}></div>
                <div className={class_name.arrowInsideBefore}></div>
              </div>
            </div>

            <div className={class_name.modal_content_align}>
              <img
                alt="icon"
                src={
                  auth?.userDetails?.profile_image
                    ? `
                    
                    ${
                      auth?.userDetails?.profile_image //${API.baseUrls[API.currentEnv]}
                    }`
                    : IMAGES.PROFILE_ICON
                }
                className={class_name.profile_icon_style}
              />

              <div className="d-flex flex-column text-nowrap">
                <Typography style={{ marginLeft: "5%", fontWeight: "bold" }}>
                  {auth?.userDetails?.first_name}
                </Typography>
                <Typography style={{ marginLeft: "5%", fontSize: 'clamp(10px,2vw,13px)' }}>
                  {auth?.userDetails?.user_type}
                </Typography>
              </div>

            </div>
            <Divider
              variant="fullWidth"
              style={{
                backgroundColor: "rgba(172, 172, 172, 0.4)",
                height: "1px",
              }}
            />
            <div
              className={class_name.modal_content_align}
              onClick={() => {
                navigate("/bookingHistory");
              }}
            >
              <img
                alt="iocn"
                src={IMAGES.HISTORY_ICON}
                className={class_name.history_setting_logout_icons}
              />

              <Typography style={{ marginLeft: "5%", cursor: "pointer" }}>
                {"Booking History"}
              </Typography>
            </div>
            <div
              className={class_name.modal_content_align}
              onClick={() => {
                navigate("/setting");
              }}
            >
              <img
                alt="iocn"
                src={IMAGES.SETTING_ICON}
                className={class_name.history_setting_logout_icons}
              />

              <Typography style={{ marginLeft: "5%", cursor: "pointer" }}>
                {"Setting"}
              </Typography>
            </div>
            <div
              style={{ cursor: "pointer" }}
              className={class_name.modal_content_align}
              onClick={() => {
                setOpenPopup(true)
                dispatch(
                  popup_page({
                    ride_mode: "log out",
                  })
                );
                setOpenModal(false)
                setOpenDrawer(false)
              }}
            >
              <img
                alt="iocn"
                src={IMAGES.LOGOUT_ICON}
                className={class_name.history_setting_logout_icons}
              />

              <Typography style={{ marginLeft: "5%" }}>{"Log Out"}</Typography>
            </div>
          </div>
        </div>
      ) : null}
      {openPopup?
        <PopupPage
        open={openPopup}
        handleClosePopup={handleClosePopup}
        handleStatus={handleStatusPopup}
        title={`Are you sure you want to ${dashboard?.popup_page?.ride_mode}`}
        />:null
      }
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  navi_container_box: {
    display: "flex",
    flex: 1,
    // paddingLeft: "140px",
    // paddingRight: "140px",
    // padding: "0 10%",
    width: "100%",
    padding: "1% 2%",
    [theme.breakpoints.up(999)]: {
      padding: "1% 3%",
      // backgroundColor: "lightgreen",
    },
    [theme.breakpoints.up(1126)]: {
      padding: "1% 4%",
      // backgroundColor: "red",
    },
    [theme.breakpoints.up(1260)]: {
      padding: "1% 8%",
      // backgroundColor: "lightblue",
    },
    [theme.breakpoints.up(1324)]: {
      padding: "1% 10%",
    },
  },
  arrowWrapper: {
    position: "absolute",
    top: "-20px",
    right: "45px",
    width: "0",
    height: "0",
  },
  arrowContainer: {
    position: "relative",
  },
  arrowInside: {
    width: "0",
    height: "0",
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: "20px solid transparent",
    // borderBottomColor: "rgba(66, 70, 81, 0.1)",
    zIndex: 1, // Layered on top of the pseudo-element
    position: "absolute",
  },
  arrowInsideBefore: {
    content: '""',
    width: "0",
    height: "0",
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: "20px solid rgba(66, 70, 81, 0.1)", // Inside color
    position: "absolute",
    zIndex: -1, // Behind the main arrow
  },

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  profile_modal: {
    display: "flex",
    flexDirection: "column",

    border: "solid 0.5px rgba(66, 70, 81, 0.5)",
    borderRadius: "5px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "white",
    zIndex: "9999",
    position: "absolute",
    padding: "1% 2%",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.up("md")]: {
      width: "28%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "25%",
    },
  },

  modal_content_align: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "4% 0%",
  },

  history_setting_logout_icons: {
    width: "clamp(15px, 20vw, 20px)", // Adjust the range as needed
    height: "clamp(15px, 20vw, 20px)",
  },

  header_content_txt: {
    marginLeft: "25px",
    // /* font-family: Poppins; */
    fontSize: "clamp(10px, 1.6vw, 18px)",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.87)",
    letterSpacing: 0.5,
    cursor: "pointer",
  },
  picture_style: {
    // width: "clamp(136px, 23vw, 198px)", // Adjust the range as needed
    // height: "clamp(52px, 13vw, 98px)", // Adjust the range as needed
    width: '148px',
    height: '75px'
  },

  modal: {
    position: "fixed",
    zIndex: 1,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  inside_modal: {
    backgroundColor: "#fefefe",
    margin: "15% auto",
    padding: "20px",
    border: "1px solid #888",
    width: "300px",
  },

  profile_icon_div: {
    display: "flex",
  },

  end_content_media_Icons_box: {
    width: "100%",
    display: "flex",
    // justifyContent: "space-around",
    justifyContent: "center",
    margin: "5% 0",
  },

  social_media_icon_box: {
    backgroundColor: "white",
    // padding: "2%",
    height: "clamp(40px, 4vw, 40px)",
    width: "clamp(40px, 4vw, 40px)",
    // alignSelf: "center",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 3%",
  },

  social_media_icon_style: {
    width: "clamp(20px, 4vw, 40px)",
    height: "clamp(20px, 4vw, 40px)",
    // alignSelf: "center",
    // backgroundColor: "red",
  },

  end_content_box: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    // height: "100%",
    // backgroundColor: "lightcoral",
  },

  copy_rty_style_box: {
    display: "flex",
    backgroundColor: "rgba(2, 107, 147, 1)",
    width: "100%",
    // height: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
  },

  copyRty_txt: {
    fontSize: "clamp(10px, 3vw, 20px)",
    fontWeight: "400",
    color: "white",
    textAlign: "center",
    padding: "4% 0",
  },

  drawer_content_txt: {
    // fontSize: "clamp(10px, 2vw, 14px)",
    // fontWeight: "500",
    // textAlign: "left",
  },

  profile_icon_style: {
    width: "clamp(45px, 4.9vw, 45px)",
    height: "clamp(45px, 4.9vw, 45px)",
    borderRadius: "50%",
    cursor: "pointer",
  },

  end_content_style_box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    alignContent: "flex-end",
    alignSelf: "flex-end",
    // backgroundColor: "red",
    width: "100%",
    // height: "100%",
  },

  "@media (max-width: 1080px)": {
    navi_container_box: {
      display: "flex",
      flex: 1,
      // paddingLeft: "140px",
      // paddingRight: "140px",
      padding: "0",
      width: "100%",
      backgroundColor: "white",
      justifyContent: "space-between",
    },
  },

  //
  //
  //
  //
  //
  //
  // 910px
  //
  //
  //
  //
  //
  //
  //

  "@media (max-width: 910px)": {

    // picture_style: {
    //   width: "clamp(96px, 25vw, 165px)", // Adjust the range as needed
    //   height: "clamp(48px, 15vw, 98px)", // Adjust the range as needed
    //   // width: "clamp(136px, 23vw, 198px)", // Adjust the range as needed
    //   // height: "clamp(52px, 13vw, 98px)", // Adjust the range as needed
    //   // border: "solid 0.5px rgba(112, 112, 112, 0.3)",
    //   //   backgroundColor: "red",
    // },
    drawer_app_icon_style: {
      width: "clamp(102px, 25vw, 152px)", // Adjust the range as needed
      height: "clamp(51px, 15vw, 81px)", // Adjust the range as needed
    },
    profile_icon_div: {
      display: "none",
    },
  },
}));

const modalContentStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  alignSelf: "center",
  alignTracks: "center",
  position: "fixed" /* Ensure it's fixed in the viewport */,
  top: "30%" /* Move the top of the modal to the vertical center */,
  left: "50%" /* Move the left of the modal to the horizontal center */,
  transform: "translate(-50%, -50%)" /* Center it precisely */,
  backgroundColor: "#f6f6f6",
  // backgroundColor: "white",
  padding: "2%",
  border: "1px solid #888",
};

const buttonStyle = {
  marginBottom: "10px",
  marginRight: "10px",
  padding: "10px 20px",
  borderRadius: "4px",
  border: "none",
  cursor: "pointer",
  fontSize: "16px",
};

const logoutButtonStyle = {
  ...buttonStyle,
  backgroundColor: "#f44336",
  color: "white",
};

const cancelButtonStyle = {
  ...buttonStyle,
  backgroundColor: "#ccc",
  color: "black",
};
