import { user_details } from "../Service/api";

export function UserDetailsAPI(userId, AuthToken, callback) {
  if (AuthToken) {
    let payload = {
      user_id: userId,
    };
    // console.log("User Details API Payload : ", payload);
    user_details(AuthToken, payload)
      .then((res) => {
        if (res?.data?.success) {
          callback(undefined, res?.data?.data);
          // console.log("User Details API Success : ",res?.data?.data)
        }
      })
      .catch((e) => {
        console.log("User Details API Error : ", e);
      });
  }
}
