import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import Loader from "../Screens/Loader";
import {
  AuthToken,
  dispatchcalculateTime,
  GetUserDetails,
  TokenDecodeData,
} from "../redux/slices";
import { useDispatch, useSelector } from "react-redux";
//
import Home from "../Screens/Home/Home";
import { LogIn } from "../Screens/Auth/Log-in/Login";
import { ReviewPage } from "../Screens/new/ReviewPage";
import { SignUp } from "../Screens/Auth/Sign-up/SignUp";
import { UserDetailsAPI } from "../utils/UserDetailsAPI";
import { Rental } from "../Screens/Dash/RentalBoat/Rental";
import { Notification } from "../Screens/new/Notification";
import VerifyOTP from "../Screens/Auth/verify-OTP/VerifyOTP";
import { AllMessage } from "../Screens/Dash/Chat/AllMessage";
import { SearchBoat } from "../Screens/Dash/Search/SearchBoat";
import { Setting } from "../Screens/Dash/Settings-pages/Setting";
import { UserChoice } from "../Screens/Auth/user-type/UserChoice";
import { BoatDetailCard } from "../Screens/Dash/Card/BoatDetailCard";
import { Confirmation } from "../Screens/Common/Confirm/Confirmation";
import { MyListings } from "../Screens/Dash/BoatOwner/list/MyListings";
import { RequestList } from "../Screens/Dash/BoatOwner/list/RequestList";
import { ForgotPassword } from "../Screens/Auth/password/ForgotPassword";
import { ChangePassword } from "../Screens/Auth/password/ChangePassword";
import BoatViewDetails from "../Screens/Dash/Boat-View-Details/BoatViewDetails";
import { BookingHistory } from "../Screens/Dash/Booking_History/BookingHistory";
import { CustomerProfile } from "../Screens/Dash/BoatOwner/list/CustomerProfile";
import { VerifyForgotPwdOTP } from "../Screens/Auth/password/VerifyForgotPwdOTP";
import { BoatOwnerDashBoard } from "../Screens/Dash/Boat-owner-dashboard/BoatOwnerDashBoard";
import { BoatBookingRequest } from "../Screens/Dash/Boat-booking-request/BoatBookingRequest";
import { BoatOfferStep1 } from "../Screens/Dash/BoatOwner/ListABoatOffer/step1/BoatOfferStep1";
import { BoatOfferStep2 } from "../Screens/Dash/BoatOwner/ListABoatOffer/step2/BoatOfferStep2";
import { BoatOfferStep3 } from "../Screens/Dash/BoatOwner/ListABoatOffer/step3/BoatOfferStep3";
import { Chat } from "../Screens/Dash/Chat/Chat";
import { BookedReqDetails } from "../Screens/Dash/BoatOwner/list/BookedReqDetails";
import useStorage from "../hooks/useStorage";
import PageNotFound from "../Screens/Dash/PageNotFound/PageNotFound";
import { UpdateBoatDoc } from "../Screens/Dash/UpdateBoatDoc/UpdateBoatDoc";
import MyListing from "../Screens/Dash/BoatOwner/list/MyListing";

// const PrivateRoute = ({
//   children,
//   session,
//   type,
//   screenName,
//   pageName,
//   ForceAllow = false,
// }) => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [calculateTime, setCalculateTime] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [tokenData, setTokenData] = useState(true);
//   const auth = useSelector((state) => state?.auth);

//   // console.log("auth", auth?.userDetails);

//   useEffect(() => {
//     const getCurrentSession = async () => {
//       const token = localStorage.getItem("session");
//       const user_id = localStorage.getItem("user_id");
//       // console.log("tokenDecode", token);
//       if (token) {
//         let tokenDecode = jwt_decode(token);
//         // console.log("tokenDecode", tokenDecode);
//         setTokenData(tokenDecode);
//         const currentTimestamp = Math.floor(Date.now() / 1000);

//         if (currentTimestamp < tokenDecode.exp) {
//           setCalculateTime(true);
//           setLoading(false);
//           UserDetailsAPI(user_id, token, (err, ress) => {
//             dispatch(GetUserDetails(ress));
//           });
//         } else {
//           setLoading(false);
//           setCalculateTime(false);
//         }
//       } else {
//         setLoading(false);
//         setCalculateTime(false);
//       }
//     };

//     getCurrentSession();
//   }, [navigate, type, auth?.AuthToken]);
//   // auth?.userDetails dispatch

//   return loading ? (
//     <Loader />
//   ) : screenName ? (
//     tokenData?.user_type && type ? (
//       tokenData?.user_type === type ? (
//         <>
//           {children}
//           {console.log(
//             "screenName => TRUE // tokenData?.user_type && type => TRUE //tokenData?.user_type === type  EQUAL "
//           )}
//         </>
//       ) : (
//         <>
//           {/* {navigate(-1)}
//           {console.log(
//             "screenName => TRUE // tokenData?.user_type && type => TRUE //tokenData?.user_type === type NOT EQUAL "
//           )} */}
//           {type === "HOME" && tokenData?.user_type === "CUSTOMER" ? (
//             <Navigate to="/rental" />
//           ) : type === "HOME" && tokenData?.user_type === "BOAT_OWNER" ? (
//             <Navigate to="/boatOwnerDashBoard" />
//           ) : (
//             navigate(-1)
//           )}
//         </>
//       )
//     ) : (
//       children
//     )
//   ) : pageName === "OUT" ? (
//     calculateTime ? (
//       <>
//         {navigate(-1)}
//         {console.log("pageName === OUT => true and  calculateTime => true")}
//       </>
//     ) : (
//       <>
//         {children}
//         {console.log("pageName === OUT => true and  calculateTime => false")}
//       </>
//     )
//   ) : calculateTime ? (
//     tokenData?.user_type === type ? (
//       <>
//         {children}
//         {/* {console.log("user_type === PAGE type allow true")} */}
//       </>
//     ) : ForceAllow ? (
//       <>
//         {children}
//         {console.log("Force allow true")}
//       </>
//     ) : auth?.userDetails?.user_type === "BOAT_OWNER" ? (
//       <Navigate to="/boatOwnerDashBoard" />
//     ) : auth?.userDetails?.user_type === "CUSTOMER" ? (
//       <Navigate to="/rental" />
//     ) : (
//       <>
//         {navigate(-1)}
//         {console.log("Force allow fail")}
//       </>
//     )
//   ) : (
//     <Navigate to="/logIn" />
//   );
// };

export const CustomerPrivateRoute = ({ children }) => {
  const tokenData = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [token, setTokenData] = useState(null);
  const [calculateTime, setCalculateTime] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getCurrentSession = async () => {
      const token = localStorage.getItem("session");
      const user_id = localStorage.getItem("user_id");

      if (token) {
        let tokenDecode = await jwt_decode(token);
        setTokenData(tokenDecode);
        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (
          currentTimestamp < tokenDecode.exp &&
          tokenDecode?.user_type === "CUSTOMER"
        ) {
          setCalculateTime(true);
          setLoading(false);
        } else {
          setCalculateTime(false);
          setLoading(false);
          dispatch(TokenDecodeData(null));
          dispatch(AuthToken(null));
          localStorage.removeItem("session");
        }
      } else {
        setCalculateTime(false);
        setLoading(false);
        dispatch(TokenDecodeData(null));
        dispatch(AuthToken(null));
        localStorage.removeItem("session");
      }
    };

    getCurrentSession();
  }, []);

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>{token && calculateTime ? children : <Navigate to="/logIn" />}</>
      )}
    </>
  );
};

export const BoatPrivateRoute = ({ children }) => {
  const tokenData = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [token, setTokenData] = useState(null);
  const [calculateTime, setCalculateTime] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getCurrentSession = async () => {
      const token = localStorage.getItem("session");
      const user_id = localStorage.getItem("user_id");

      if (token) {
        let tokenDecode = await jwt_decode(token);
        console.log(tokenDecode, "token decode");
        setTokenData(tokenDecode);
        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (
          currentTimestamp < tokenDecode.exp &&
          tokenDecode?.user_type === "BOAT_OWNER"
        ) {
          setCalculateTime(true);
          setLoading(false);
        } else {
          setCalculateTime(false);
          setLoading(false);
          dispatch(TokenDecodeData(null));
          dispatch(AuthToken(null));
          localStorage.removeItem("session");
        }
      } else {
        setCalculateTime(false);
        setLoading(false);
        dispatch(TokenDecodeData(null));
        dispatch(AuthToken(null));
        localStorage.removeItem("session");
      }
    };

    getCurrentSession();
  }, []);
  // console.log(token,'children')
  console.log(loading, token, children?.type?.name, calculateTime);
  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {
          // !token && children?.type?.name === "BoatOwnerDashBoard" ? (
          //   children
          // ) : 
          token && calculateTime ? (
            children
          ) : (
            <Navigate to="/logIn" />
          )}
        </>
      )}
    </>
  );
};

export const CommonRoute = ({ children }) => {
  const tokenData = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [token, setTokenData] = useState(null);
  const [calculateTime, setCalculateTime] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getCurrentSession = async () => {
      const authToken = localStorage.getItem("session");
      const user_id = localStorage.getItem("user_id");

      if (authToken) {
        console.log('inside token')
        let tokenDecode = await jwt_decode(authToken);
        console.log(tokenDecode, "token decode");
        setTokenData(tokenDecode);
        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (
          currentTimestamp < tokenDecode.exp
        ) {
          setCalculateTime(true);
          setLoading(false);
        } else {
          setCalculateTime(false);
          setLoading(false);
          dispatch(TokenDecodeData(null));
          dispatch(AuthToken(null));
          localStorage.removeItem("session");
        }
      } else {
        setCalculateTime(false);
        setLoading(false);
        dispatch(TokenDecodeData(null));
        dispatch(AuthToken(null));
        localStorage.removeItem("session");
      }
    };

    getCurrentSession();
  }, []);
  // console.log(children, token, children?.type?.name, calculateTime, Boolean(token && calculateTime));
  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {
          // !token && children?.type?.name === "BoatOwnerDashBoard" ? (
          //   children
          // ) : 
          token && calculateTime ? (
            children
          ) : (
            <Navigate to="/logIn" />
          )}
        </>
      )}
    </>
  );
};

export const RootNavigator = React.forwardRef(function RootNavigator(
  props,
  ref
) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { token, calculateTime, userId } = useStorage();

  useEffect(() => {
    dispatch(
      dispatchcalculateTime({ calculateTime: calculateTime, token: token })
    );
    UserDetailsAPI(userId, token, (err, ress) => {
      dispatch(GetUserDetails(ress));
    });
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <BrowserRouter>
          <Routes>
            <>
              <Route
                path="/signUp"
                element={
                  //   <PrivateRoute pageName={"OUT"}>
                  <SignUp />
                  //   </PrivateRoute>
                }
              />
              <Route
                path="/logIn"
                element={
                  //   <PrivateRoute pageName={"OUT"}>
                  <LogIn />
                  //   </PrivateRoute>
                }
              />

              <Route
                path="/verifyOTP"
                element={
                  //   <PrivateRoute pageName={"OUT"}>
                  <VerifyOTP />
                  //   </PrivateRoute>
                }
              />
              <Route
                path="/forgotPassword"
                element={
                  //   <PrivateRoute pageName={"OUT"}>
                  <ForgotPassword />
                  //   </PrivateRoute>
                }
              />
              <Route
                path="/changePassword"
                element={
                  //   <PrivateRoute pageName={"OUT"}>
                  <ChangePassword />
                  //   </PrivateRoute>
                }
              />
              <Route
                path="/verifyForgotPwdOTP"
                element={
                  //   <PrivateRoute pageName={"OUT"}>
                  <VerifyForgotPwdOTP />
                  //   </PrivateRoute>
                }
              />

              <Route
                path="/"
                element={
                  //   <PrivateRoute type="HOME" screenName={true}>
                  <>
                    {!token?.user_type ? (
                      <Home />
                    ) : token?.user_type === "BOAT_OWNER" ? (
                      <Navigate to="/boatOwnerDashBoard" />
                    ) : token?.user_type === "CUSTOMER" ? (
                      <Navigate to="/rental" />
                    ) : (
                      <Navigate to="/logIn" />
                    )}
                  </>
                  //   </PrivateRoute>
                }
              />

              <Route
                path="/boatOwnerDashBoard"
                // index
                element={
                  <BoatPrivateRoute>
                    {/* token?.user_type === 'BOAT_OWNER'?( */}
                    <BoatOwnerDashBoard />
                    {/* ):(
                    <Navigate to='/logIn'/>
                   ) */}
                  </BoatPrivateRoute>
                }
              />
              <Route
                path="boatOfferStep1"
                element={
                  <BoatPrivateRoute token={token} calculateTime={calculateTime}>
                    <BoatOfferStep1 />
                  </BoatPrivateRoute>
                }
              />
              <Route
                path="boatOfferStep2"
                element={
                  <BoatPrivateRoute token={token} calculateTime={calculateTime}>
                    <BoatOfferStep2 />
                  </BoatPrivateRoute>
                }
              />
              <Route
                path="boatOfferStep3"
                element={
                  <BoatPrivateRoute token={token} calculateTime={calculateTime}>
                    <BoatOfferStep3 />
                  </BoatPrivateRoute>
                }
              />

              <Route
                path="myListings"
                element={
                  <BoatPrivateRoute token={token} calculateTime={calculateTime}>
                    <MyListing />
                  </BoatPrivateRoute>
                }
              />

              <Route
                path="updateBoatDoc"
                element={
                  <BoatPrivateRoute token={token} calculateTime={calculateTime}>
                    <UpdateBoatDoc />
                  </BoatPrivateRoute>
                }
              />

              <Route
                path="/rental"
                // index
                element={
                  //   <PrivateRoute type="CUSTOMER" screenName={true}>
                  // token?.user_type === 'CUSTOMER'?(
                  <Rental />
                  // ):(
                  // <Navigate to='/logIn'/>
                  // )
                  //   </PrivateRoute>
                }
              />
              <Route
                path="/searchBoat"
                element={
                  //   <PrivateRoute screenName={true} pageName={"searchBoat"}>
                  // <CommonRoute token={token} calculateTime={calculateTime}>
                  <SearchBoat />
                  // </CommonRoute>
                  //   </PrivateRoute>
                }
              />
              <Route
                path="/boatData/:id"
                element={
                  //   <PrivateRoute screenName={true} pageName={"id"}>
                  <BoatViewDetails />
                  //   </PrivateRoute>
                }
              />

              <Route
                path="/*"
                element={
                  //   <PrivateRoute screenName={true} pageName={"id"}>
                  <PageNotFound />
                  //   </PrivateRoute>
                }
              />

              <Route
                path="/userChoice"
                element={
                  //   <PrivateRoute pageName={"OUT"}>
                  //   <CustomerPrivateRoute
                  //     token={props?.token}
                  //     calculateTime={props?.calculateTime}
                  //   >
                  <UserChoice />
                  //   </CustomerPrivateRoute>
                }
              />

              <Route
                path="/requestList"
                element={
                  // <CustomerPrivateRoute
                  //   token={token}
                  //   calculateTime={calculateTime}
                  // >
                  <BoatPrivateRoute token={token} calculateTime={calculateTime}>
                  <RequestList />
                  </BoatPrivateRoute>
                  // </CustomerPrivateRoute>
                }
              />
              <Route
                path="/customerProfile"
                element={
                  <CustomerPrivateRoute
                    token={token}
                    calculateTime={calculateTime}
                  >
                    <CustomerProfile />
                  </CustomerPrivateRoute>
                }
              />
              <Route
                path="/bookedReqDetails"
                element={
                  // <CustomerPrivateRoute
                  //   token={token}
                  //   calculateTime={calculateTime}
                  // >
                  <CommonRoute token={token} calculateTime={calculateTime}>
                  <BookedReqDetails />
                  </CommonRoute>
                  // </CustomerPrivateRoute>
                }
              />
              <Route path="/confirmation" element={<Confirmation />} />
              <Route
                path="/reviewPage"
                element={
                  <CustomerPrivateRoute
                    token={token}
                    calculateTime={calculateTime}
                  >
                    <ReviewPage />
                  </CustomerPrivateRoute>
                }
              />
              <Route
                path="/notification"
                element={
                  // <CustomerPrivateRoute
                  //   token={token}
                  //   calculateTime={calculateTime}
                  // >
                  <CommonRoute token={token} calculateTime={calculateTime}>
                  <Notification />
                  </CommonRoute>
                  // </CustomerPrivateRoute>
                }
              />
              <Route
                path="/bookingHistory"
                element={
                  // <CustomerPrivateRoute
                  //   token={token}
                  //   calculateTime={calculateTime}
                  // >
                  <CommonRoute token={token} calculateTime={calculateTime}>
                  <BookingHistory />
                  </CommonRoute>
                  // </CustomerPrivateRoute>
                }
              />
              <Route
                path="/boatDetailCard"
                element={
                  <CustomerPrivateRoute
                    token={token}
                    calculateTime={calculateTime}
                  >
                    <BoatDetailCard />
                  </CustomerPrivateRoute>
                }
              />
              <Route
                path="/chat"
                element={
                  // <CustomerPrivateRoute
                  //   token={token}
                  //   calculateTime={calculateTime}
                  // >
                  <Chat />
                  // </CustomerPrivateRoute>
                }
              />
              <Route
                path="/setting"
                element={
                  //   <PrivateRoute type="BOAT_OWNER" pageName={"IN"} ForceAllow={true}>
                  //     <Setting />
                  //   </PrivateRoute>
                  // <CustomerPrivateRoute
                  //   token={token}
                  //   calculateTime={calculateTime}
                  // >
                  <CommonRoute token={token} calculateTime={calculateTime}>
                  <Setting />
                  </CommonRoute>
                  // </CustomerPrivateRoute>
                }
              />

              <Route
                path="/boatBookingRequest"
                element={
                  //   <PrivateRoute pageName={"IN"} ForceAllow={true}>
                  //     <BoatBookingRequest />
                  //   </PrivateRoute>
                  // <CustomerPrivateRoute
                  //   token={token}
                  //   calculateTime={calculateTime}
                  // >
                  <CommonRoute token={token} calculateTime={calculateTime}>
                  <BoatBookingRequest />
                  </CommonRoute>
                  // </CustomerPrivateRoute>
                }
              />

              <Route
                path="/allMessage"
                element={
                  //   <PrivateRoute type="BOAT_OWNER" pageName={"IN"} ForceAllow={true}>
                  //     <AllMessage />
                  //   </PrivateRoute>
                  // <CustomerPrivateRoute
                  //   token={token}
                  //   calculateTime={calculateTime}
                  // >
                  <CommonRoute token={token} calculateTime={calculateTime}>
                  <AllMessage />
                  </CommonRoute>
                  // </CustomerPrivateRoute>
                }
              />
            </>

            {/* <>
          <Route
            path="/"
            element={
              <PrivateRoute type="HOME" screenName={true}>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/rental"
            element={
              <PrivateRoute type="CUSTOMER" screenName={true}>
                <Rental />
              </PrivateRoute>
            }
          />
          <Route
            path="/boatOwnerDashBoard"
            element={
              <PrivateRoute type="BOAT_OWNER" screenName={true}>
                <BoatOwnerDashBoard />
              </PrivateRoute>
            }
          />
          <Route
            path="/searchBoat"
            element={
              <PrivateRoute screenName={true} pageName={"searchBoat"}>
                <SearchBoat />
              </PrivateRoute>
            }
          />
          <Route
            path="/:id"
            element={
              <PrivateRoute screenName={true} pageName={"id"}>
                <BoatViewDetails />
              </PrivateRoute>
            }
          />

          <Route
            path="/userChoice"
            element={
              <PrivateRoute pageName={"OUT"}>
                <UserChoice />
              </PrivateRoute>
            }
          />
          <Route
            path="/signUp"
            element={
              <PrivateRoute pageName={"OUT"}>
                <SignUp />
              </PrivateRoute>
            }
          />
          <Route
            path="/logIn"
            element={
              <PrivateRoute pageName={"OUT"}>
                <LogIn />
              </PrivateRoute>
            }
          />

          <Route
            path="/verifyOTP"
            element={
              <PrivateRoute pageName={"OUT"}>
                <VerifyOTP />
              </PrivateRoute>
            }
          />
          <Route
            path="/forgotPassword"
            element={
              <PrivateRoute pageName={"OUT"}>
                <ForgotPassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/changePassword"
            element={
              <PrivateRoute pageName={"OUT"}>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/verifyForgotPwdOTP"
            element={
              <PrivateRoute pageName={"OUT"}>
                <VerifyForgotPwdOTP />{" "}
              </PrivateRoute>
            }
          />

          <Route
            path="/boatOfferStep1"
            element={
              <PrivateRoute type="BOAT_OWNER">
                <BoatOfferStep1 />
              </PrivateRoute>
            }
          />
          <Route
            path="/boatOfferStep2"
            element={
              <PrivateRoute type="BOAT_OWNER">
                <BoatOfferStep2 />
              </PrivateRoute>
            }
          />
          <Route
            path="/boatOfferStep3"
            element={
              <PrivateRoute type="BOAT_OWNER">
                <BoatOfferStep3 />
              </PrivateRoute>
            }
          />

          <Route path="/requestList" element={<RequestList />} />
          <Route path="/customerProfile" element={<CustomerProfile />} />
          <Route path="/bookedReqDetails" element={<BookedReqDetails />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/reviewPage" element={<ReviewPage />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/bookingHistory" element={<BookingHistory />} />
          <Route path="/boatDetailCard" element={<BoatDetailCard />} />
          <Route path="/chat" element={<Chat />} />
          <Route
            path="/setting"
            element={
              <PrivateRoute type="BOAT_OWNER" pageName={"IN"} ForceAllow={true}>
                <Setting />
              </PrivateRoute>
            }
          />
          <Route
            path="/myListings"
            element={
              <PrivateRoute type="BOAT_OWNER" pageName={"IN"} ForceAllow={true}>
                <MyListings />
              </PrivateRoute>
            }
          />
          <Route
            path="/boatBookingRequest"
            element={
              <PrivateRoute pageName={"IN"} ForceAllow={true}>
                <BoatBookingRequest />
              </PrivateRoute>
            }
          />

          <Route
            path="/allMessage"
            element={
              <PrivateRoute type="BOAT_OWNER" pageName={"IN"} ForceAllow={true}>
                <AllMessage />
              </PrivateRoute>
            }
          />
        </> */}
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
});
