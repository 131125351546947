// import { takeLatest } from "redux-saga/effects";

export function* getDash_dataAPI(action) {
  // try {
  // const response = yield dashboardcreditReportAPi(action.payload);
  // yield put(dashboardcreditReportDataSuccess(response));
  // } catch (e) {
  // yield put(dashboardcreditReportDataFailure(e));
  // }
}

export function* dashboardSaga() {
  // yield takeLatest(dashboardcreditReportDataRequest.type, getDash_dataAPI);
}
