import React, { useEffect, useRef, useState } from "react";
import "./Client_review.css";
import { animated, easings, useSpring } from "react-spring";
import { styled } from "@mui/material/styles";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useWindowDimensions from "../../../../UI kit/useWindowDimensions";
import { padding } from "@mui/system";
import { StarRating } from "../../../../UI kit/5Star/StarRating";

const TypeQuest = animated(
  styled("div")(({ theme }) => ({
    // backgroundColor: `rgba(250, 250, 250, 1)`,
    // boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.16)`,
    borderRadius: `15px`,
    display: `flex`,
    position: `relative`,
    isolation: `isolate`,
    flexDirection: `column`,
    // width: "100%",
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    overflow: `hidden`,
    height: "auto",
    // width: "414px", // Set the width to 300px
    // height: "400px", // Set the height to 400px
    flexShrink: 0,
    width: "100%",
    marginRight: "20px",
    transition: "transform 0.3s ease",
  }))
);

const reviewCard_color = "rgba(102, 155, 195, 0.1)";
const reviewCard_height = 299;
const reviewCard_width = 425;
const reviewCard_center = "center";

const Client_review = ({ id, image, name, place, review, borderRadius = null, rating, seeMoreOption=false, customWidth }) => {
  const { width } = useWindowDimensions();
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const [seeMore, setSeeMore] = useState(false)

  const [TitleSpring, TitleApi] = useSpring(() => ({
    config: {
      duration: 500,
      easing: easings["easeInOutExpo"],
    },
    delay: 1250,
    from: { transform: "translateX(25px)", opacity: 0 },
  }));
  const [TypeQuestSpring, TypeQuestApi] = useSpring(() => ({
    config: {
      duration: 100,
      easing: easings["easeOutBack"],
    },
    delay: 0,
    from: { transform: "scale(1)" },
  }));


  React.useEffect(() => {
    TitleApi.start({
      ...{ transform: "translateX(0px)", opacity: 1 },
      delay: 1250,
      config: { duration: 500 },
    });
  }, []);

  return (
    <TypeQuest
    // onMouseLeave={() => {
    //   TypeQuestApi.start({ transform: "scale(1)" });
    // }}
    // onMouseEnter={() => {
    //   TypeQuestApi.start({ ...{ transform: "scale(1.05)" }, delay: 0 });
    // }}
    style={
      width >= 992
        ? {
            ...TypeQuestSpring,
            width: 300,
            borderRadius: borderRadius ?? null,
            paddingTop: '10px'
          }
        : width >= 768
        ? {
            ...TypeQuestSpring,
            width: 280,
            borderRadius: borderRadius ?? null,
            paddingTop: '10px'
          }
        // : width <= 1380
        // ? {
        //     ...TypeQuestSpring,
        //     width: 300,
        //     borderRadius: borderRadius ?? null,
        //     paddingTop: '10px'
        //   }
        : {
            ...TypeQuestSpring,
            width: customWidth? customWidth:300,
            borderRadius: borderRadius ?? null,
            paddingTop: '10px'
          }
    }
  >
    <div
      style={{
        position: "relative",
        top: '0',
        left: '0',
        overflow: "visible",
        // padding: "15%  5%",
        // backgroundColor: "red",
        width: "100%",
      }}
    >
      <div
        // style={{
        //   position: "relative",
        // }}
      >
        <img
          src={image}
          alt="client_img"
          className={class_name.profile_image}
        />
      </div>
      <div
      className={class_name.review_detail_div}
        style={{
          display: "flex",
          // backgroundColor: "royalblue",
          position: "relative",
          width: "100%",
          paddingTop: "10%",
        }}
      >
        <TypeQuest
          // onMouseLeave={() => {
          //   TypeQuestApi.start({ transform: "scale(1)" });
          // }}
          // onMouseEnter={() => {
          //   TypeQuestApi.start({
          //     ...{ transform: "scale(1.05)" },
          //     delay: 0,
          //   });
          // }}
          className={class_name.review_card}
          style={{
            ...TypeQuestSpring,
            backgroundColor: reviewCard_color,
            // width: reviewCard_width,
            // height: reviewCard_height,

            // padding: "50px 20px 20px 20px",
            // padding: "15% 3% 10%", 
            // width: '30%'
            // backgroundColor: "gold",
          }}
          key={id}
        >
          <Typography className={class_name.name_txt}>{name}</Typography>
          <Typography className={class_name.place_txt}>{place}</Typography>
          <Typography className={class_name.place_txt}>
            <StarRating rating={rating}/>
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              width: "100%",
              height: "100%",
              textAlign: 'center'
              // backgroundColor: "royalblue",
              // padding: "15% 5%",
            }}
          >
            <Typography
              // style={{ textAlign: reviewCard_center }}
              className={class_name.msg_txt}
            >
              {
              review?.length > 40 && !seeMore && seeMoreOption?
              <span>
                {review.slice(1,34)}
                <span className="ps-1 fw-semibold" style={{color: '#026b93'}} onClick={()=>setSeeMore(true)}>see more...</span>
              </span>:
              review?.length > 40 && seeMore && seeMoreOption?
              <span>
                {review}
                <span className="ps-1 fw-semibold" style={{color: '#026b93'}} onClick={()=>setSeeMore(false)}>see less</span>
              </span>:
              <span>{review}</span>
              }
            </Typography>
          </div>
        </TypeQuest>
      </div>
    </div>
    </TypeQuest>
  );
};

export default Client_review;

const useStyles = makeStyles((theme) => ({
  name_txt: {
    fontSize: "clamp(16px, 1.5vw, 21px)", // Adjust the range as needed
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    // textAlign: "left",
    color: "#424651",
  },

  profile_image: {
    width: "70px",
    height: "70px",
    left: '10px',
    top: '-5px',
    position: "absolute",
    borderRadius: "50px",
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.16)`,
    zIndex: 1,
    border: "1px solid rgba(102, 155, 195, 0.1)",
    // [theme.breakpoints.down('768')]:{
    //   width: '13%',
    //   height: '35%',
    //   top: '15%',
    // },
    [theme.breakpoints.down('481')]:{
      width: '60px',
      height: '60px',
      // top: '1%',
    },
  },

  review_card: {
    width: '100%',
    padding: '3% 4%',
    paddingTop: '16%',
    // [theme.breakpoints.down('768')]:{
    //   width: '50%',
    //   paddingTop: '10%'
    // },
    [theme.breakpoints.down('481')]:{
      padding: '5% 5%',
      paddingTop: '15%',
      width: '100%'
    },
  },
  place_txt: {
    fontSize: "clamp(14px, 1.5vw, 15px)", // Adjust the range as needed
    fontWeight: "300",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    // textAlign: "left",
    color: "#424651",
  },
  msg_txt: {
    fontSize: "clamp(14px, 1.5vw, 20px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    margin: '5% 0%',
    width: '100%',
    // textAlign:'center',

    color: "#424651",
  },
}));