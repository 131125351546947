const IMAGES = {
  APP_ICON: require("../assets/Logo/logo.png"),
  DOWN: require("../assets/Images/down.png"),
  DOWN_ARROW_2 :require('../assets/Images/down_arrow_2.png'),
  EMAIL_ICON: require("../assets/Images/email.png"),
  LOCK_ICON: require("../assets/Images/padlock.png"),
  SEA_VIEW_IMG: require("../assets/Images/sea_view_login.webp"),
  LEFT_BACK_BUTTON: require("../assets/Images/leftArrow.png"),
  VERIFY_ACC: require("../assets/Images/accountVerify.png"),
  ADD_FILES: require("../assets/Images/undrawAddFiles.webp"),
  UP_ARROW: require("../assets/Images/upArrow.png"),
  CLOUD_UPLOAD_SIGNAL: require("../assets/Images/cloudUploadSignal.webp"),
  DATE: require("../assets/Images/date.webp"),
  LINK: require("../assets/Images/link.webp"),
  TICK: require("../assets/Images/check_circle.webp"),
  MONEY_CARD: require("../assets/Images/moneyIcon.webp"),
  PROFILE_ICON: require("../assets/Images/profileIcon.png"),
  EDIT_ICON: require("../assets/Images/EditPencil.png"),
  FILTER_LIST: require("../assets/Images/filterList.webp"),
  USER_PROFILE: require("../assets/Images/userProfile.webp"),
  SEARCH: require("../assets/Images/search.png"),
  SEARCH_BLUE: require("../assets/Images/search_blue.png"),
  BELL: require("../assets/Images/bell.png"),
  BELL_COLOR: require("../assets/Images/bell_color.png"),
  GOOGLE: require("../assets/Images/google.png"),
  ADD_BOATE_DETAILS: require("../assets/Images/addBoatDetails.webp"),
  GROUP: require("../assets/Images/malesGroup.png"),
  NO_RESULT: require("../assets/Images/noResults.jpg"),
  CONFIRM_TICK: require("../assets/Images/confirmTick.webp"),
  STAR: require("../assets/Images/star.webp"),
  CAMERA: require("../assets/Images/camera.png"),
  UP_ARROW_IMG: require("../assets/Images/UpArrowImg.png"),
  PDF: require("../assets/Images/pdf.png"),
  CANCEL: require("../assets/Images/cancel.webp"),
  BACK_GROUNG: require("../assets/Images/bg_img.png"),
  ELLIPSE: require("../assets/Images/Ellipse.svg"),
  LOCATION: require("../assets/Images/location.webp"),
  RIGHT_ARROW: require("../assets/Images/right.png"),
  TOGGELE_BUTTON: require("../assets/Images/toggle-button-icon.png"),
  MENU_ICON: require("../assets/Images/menu.png"),
  WEB_WORLD: require("../assets/Images/webWorldGlobel.png"),
  DELETE_ICON: require("../assets/Images/Trash Bin 2.png"),
  MARKER_ICON: require("../assets/Images/marker.png"),
  GALLERY_IMG: require('../assets/Images/gallery_png.jpg'),

  SCUBA_DIVING: require("../assets/Images/ScubaDiving.jpeg"),
  BACKGROUNG_IMG_1_HOVER: require("../assets/Images/background_img_1.png"),
  BACKGROUNG_IMG_HOVER: require("../assets/Images/background_img_2.jpeg"),

  TWITTER_ICON: require("../assets/Images/twitterIcon.png"),
  INSTA_ICON: require("../assets/Images/instagramIcon.png"),
  SNAPCHAT_ICON: require("../assets/Images/snapchatIcon.png"),
  YOUTUBE_ICON: require("../assets/Images/youtubeIcon.png"),
  YOUTUBE_COLOR_ICON: require("../assets/Images/YouTube_full_color.png"),
  //
  HISTORY_ICON: require("../assets/Images/historyIcon.png"),
  LOGOUT_ICON: require("../assets/Images/logoutIcon.png"),
  SETTING_ICON: require("../assets/Images/settingsIcon.png"),
  FILTER_ICON: require("../assets/Images/Msgfilter.png"),
  ATTACH_PIN_ICON: require("../assets/Images/AttachPin.webp"),
  SENT_ICON: require("../assets/Images/Send.webp"),
  VIDEO_IMG: require("../assets/Images/videoImg.jpeg"),

  //
  //
  //
  //
  // /
  // //detele example
  boat1: require("../assets/Images/removeable/boat1.jpeg"),
  boat2: require("../assets/Images/removeable/boat2.jpeg"),
  boat3: require("../assets/Images/removeable/boat3.jpeg"),
  boat4: require("../assets/Images/removeable/boat4.jpeg"),
  subaDiving: require("../assets/Images/removeable/subaDiving.jpeg"),
  //weekend circle img
  weekendCircle_1: require("../assets/Images/weekend/weekend_charters_1.png"),
  weekendCircle_2: require("../assets/Images/weekend/weekend_charters_2.png"),
  weekendCircle_3: require("../assets/Images/weekend/weekend_charters_3.png"),
  weekendCircle_4: require("../assets/Images/weekend/weekend_charters_4.png"),
  weekendCircle_5: require("../assets/Images/weekend/weekend_charters_5.png"),
  weekendCircle_6: require("../assets/Images/weekend/weekend_charters_6.png"),
  weekendCircle_7: require("../assets/Images/weekend/weekend_charters_7.png"),
  weekendCircle_8: require("../assets/Images/weekend/weekend_charters_8.png"),
  weekendCircle_9: require("../assets/Images/weekend/weekend_charters_9.png"),
};
export default IMAGES;
