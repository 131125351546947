import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,

  //
  //
  //
  boat_register_step1_data: null,
  boat_register_step2_data: null,
  boat_id: null,
  single_boat_details: null,
  Opened_chat_data: null,
  boat_reg_location_data: null,
  boat_register_step3_data: null,
  boat_booking_request_details: null,
  boat_owner_id: null,
  popup_page: null,
  transaction_detail: null,
  tap_pay_page: false,
  payload_detail: null,
  update_boat_documents: null,
  setting_tab: null,
  chat_count: null,
};

const dashSlice = createSlice({
  initialState,
  name: "dashboard",
  reducers: {
    boat_register_step1(state, action) {
      state.boat_register_step1_data = action.payload;
    },

    boat_register_location_data(state, action) {
      state.boat_reg_location_data = action.payload;
    },

    boat_register_step2(state, action) {
      state.boat_register_step2_data = action.payload;
    },

    boat_register_step3(state, action) {
      state.boat_register_step3_data = action.payload;
    },

    search_boat_id(state, action) {
      state.boat_id = action.payload;
    },
    single_boat_details_store(state, action) {
      state.single_boat_details = action.payload;
    },
    openChat(state, action) {
      state.Opened_chat_data = action.payload;
    },
    boat_booking_request_details(state, action) {
      state.boat_booking_request_details = action.payload;
    },
    boat_owner_id(state, action) {
      state.boat_owner_id = action.payload;
    },
    popup_page(state, action) {
      state.popup_page = action.payload;
    },
    transaction_detail(state, action) {
      state.transaction_detail = action.payload;
    },
    tap_pay_page(state, action) {
      state.tap_pay_page = action.payload;
    },
    payload_detail(state, action) {
      state.payload_detail = action.payload;
    },
    update_boat_documents(state, action) {
      state.update_boat_documents = action.payload;
    },
    setting_tab(state,action){
      state.setting_tab = action.payload
    },
    chat_count(state,action){
      state.chat_count = action.payload
    },
  },
});
export const {
  boat_register_step1,
  boat_register_step2,
  search_boat_id,
  single_boat_details_store,
  openChat,
  boat_register_location_data,
  boat_register_step3,
  boat_booking_request_details,
  boat_owner_id,
  popup_page,
  transaction_detail,
  tap_pay_page,
  payload_detail,
  update_boat_documents,
  setting_tab,
  chat_count
} = dashSlice.actions;

export default dashSlice.reducer;
