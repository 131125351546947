import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import IMAGES from "../../Images";
import GoogleSignInButton from "../Google/GoogleSignInButton";
import { login, user_details } from "../../../Service/api";
import {
  AuthToken,
  dispatchcalculateTime,
  EmailId,
  GetUserDetails,
  Password,
  TokenDecodeData,
  UserId,
  UserType,
  verifyOTP,
} from "../../../redux/slices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import Loader from "../../Loader";
import "./Login.css";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import socket from "../../../Service/SocketIo";

const emailIdValidation = new RegExp(
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i
);

const CustomTextField = withStyles({
  root: {
    "& input::placeholder": {
      fontSize: "16px",
      color: "rgba(66, 70, 81, 0.4)",
    },
  },
})(TextField);

const CustomCheckbox = withStyles({
  root: {
    color: "#ffffff",
    "&$checked": {
      color: "#ffffff",
    },
  },
  checked: {},
})(Checkbox);

export const LogIn = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [isRemembermeChecked, setIsRemembermeChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const errors = {};

  const initialValues = {
    email: "",
    password: "",
    sign_in_type: "SOCIAL_LOGIN",
  };

  const formik = useFormik({
    initialValues,

    onSubmit: (value) => {
      handleSubmit(value);
    },

    validate: (values) => {
      if (values.email === "") {
        errors.email = "Please enter your email";
      } else if (!values.email.match(emailIdValidation)) {
        errors.email = "Invalid email address";
      }
      if (!values.password) {
        errors.password = "Please enter your password";
      }
      return errors;
    },
  });

  // Check local storage for a remember-me flag
  // useEffect(() => {
  //   const rememberMe = localStorage.getItem("rememberMe");
  //   if (rememberMe === "true") {
  //     setIsRemembermeChecked(true);
  //     const localStorageUserEmail = localStorage.getItem("LogInEmailId");
  //     const localStorageUserPwd = localStorage.getItem("LogInPassword");
  //     initialValues.email = localStorageUserEmail || user?.emailId || "";
  //     initialValues.password = localStorageUserPwd || user?.password || "";
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user?.emailId, user?.password]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "RememberMe") {
      setIsRemembermeChecked(checked);
    }
  };

  useEffect(() => {
    if (user?.verifyOTPpage === "VERIFY_OTP") {
      const blockBackButton = (e) => {
        e.preventDefault();
        // navigate(location.pathname);
        navigate("/userChoice");
      };
      window.history.pushState(null, null, window.location.pathname);
      // window.addEventListener("popstate", blockBackButton);
      return () => {
        // window.removeEventListener("popstate", blockBackButton);
      };
    }
  }, [location.pathname, navigate, user?.verifyOTPpage]);

  const handleSubmit = async (value, type) => {
    socket.removeToken();
    toast.dismiss();
    localStorage.removeItem("session");
    setIsLoading(true);
    dispatch(GetUserDetails(null));
    dispatch(TokenDecodeData(null));
    dispatch(UserId(null));
    dispatch(AuthToken(null));
    formik.setErrors({});
    let payload = {
      email: value?.email,
    };
    if (type === "GOOGLE") {
      payload = {
        ...payload,
        sign_in_type: "SOCIAL_LOGIN",
      };
    } else {
      payload = {
        ...payload,
        password: value?.password,
        sign_in_type: "EMAIL",
      };
    }
    // console.log("handleSubmit payload", payload);
    login(payload)
      .then(async (res) => {
        localStorage.setItem("LogInEmailId", value?.email);
        localStorage.setItem("LogInPassword", value?.password);
        console.log("login res", res?.data);
        if (res?.data?.message === "user not verified") {
          dispatch(EmailId(value?.email));
          dispatch(Password(value?.password));
          navigate("/VerifyOTP");
        } else if (res?.data?.message === "user Login successfully") {
          let tokenDecode = await jwt_decode(res?.data?.token);
          socket.addToken(res?.data?.token);
          // tokenDecode?.user_type === "BOAT_OWNER"
          //   ? navigate("/boatOwnerDashBoard")
          //   : navigate("/rental");
          dispatch(TokenDecodeData(tokenDecode));

          dispatch(
            dispatchcalculateTime({
              calculateTime: true,
              token: tokenDecode,
            })
          );
          dispatch(UserId(res?.data?.user_id));
          dispatch(AuthToken(res?.data?.token));
          localStorage.setItem("session", res?.data?.token);
          localStorage.setItem("user_id", res?.data?.user_id);
          dispatch(verifyOTP(null));
          let user_details_payload = {
            user_id: res?.data?.user_id,
          };

          user_details(res?.data?.token, user_details_payload)
            .then((res) => {
              console.log("user_details res", res?.data?.data);
              dispatch(GetUserDetails(res?.data?.data));
              if (res?.data?.data?.user_type === "BOAT_OWNER") {
                navigate("/boatOwnerDashBoard");
              } else {
                navigate("/rental");
              }
              console.log(localStorage?.getItem('session'),'login')
            })
            .catch((err) => {
              console.log("user_details Error : ", err);
            });

          // handle Remember_Me Checkbox
          if (isRemembermeChecked) {
            localStorage.setItem("rememberMe", "true");
          } else {
            localStorage.removeItem("rememberMe");
          }
          toast.success("Login successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else {
          if (res?.data?.message === "User not register") {
            formik.setFieldError("email", "User not registered");
          } else if (
            res?.data?.message === "Incorrect password, please try again"
          ) {
            formik.setFieldError(
              "password",
              "Incorrect password, please try again"
            );
          }
          if (res?.data?.message && Object.keys(res.data.message).length > 0) {
            toast.error(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 20000,
            });
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("LogIn Error : ", err);
        toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  return (
    <>
      <div className="full-container-body" style={{ height: "100vh" }}>
        {isLoading ? <Loader loading={isLoading} /> : null}
        <div className="background-image-show" />

        <form onSubmit={formik.handleSubmit} className="form-style">
          <div className="display-app-icon overflow-hidden">
            <img src={IMAGES.APP_ICON} alt="Icon" className="w-100" />
          </div>

          <div className="field-content">
            {/* Email */}
            <div className="text-field-box">
              <CustomTextField
                margin="normal"
                fullWidth
                id="email"
                name="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    backgroundColor: "white",
                    borderRadius: "5px",
                    border:
                      formik.touched.email && Boolean(formik.errors.email)
                        ? "1px solid red"
                        : null,
                  },
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ marginLeft: "20px" }}
                    >
                      <IconButton>
                        <img
                          src={IMAGES.EMAIL_ICON}
                          alt="lock"
                          className="email-icon"
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  className: "text-style",
                }}
              />
            </div>
            {formik.touched.email && Boolean(formik.errors.email) ? (
              <div className={class_name?.inside_end_content}>
                <div className="err-margin">
                  <Typography className="error-msg-txt">
                    {formik.touched.email && formik.errors.email}
                  </Typography>
                </div>
              </div>
            ) : null}
            {/* Password */}
            <div
              className="text-field-box"
              style={{
                padding: "0px",
                margin: "0px",
              }}
            >
              <CustomTextField
                type={showPassword ? "text" : "password"}
                margin="normal"
                fullWidth
                id="password"
                name="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    backgroundColor: "white",
                    borderWidth: 2,
                    borderColor: "red",
                    border:
                      formik.touched.password && Boolean(formik.errors.password)
                        ? "1px solid red"
                        : null,

                    borderRadius: "5px",
                  },
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ marginLeft: "20px" }}
                    >
                      <IconButton>
                        <img
                          src={IMAGES.LOCK_ICON}
                          alt="lock"
                          style={{
                            width: "20px",
                            height: "20px",
                            opacity: 0.4,
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ marginRight: "10px" }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  className: "text-style",
                }}
              />
            </div>
            {formik.touched.password && Boolean(formik.errors.password) ? (
              <div className={class_name?.inside_end_content}>
                <div className="err-margin">
                  <Typography className="error-msg-txt">
                    {formik.touched.password && formik.errors.password}
                  </Typography>
                </div>
              </div>
            ) : null}
            <div className={class_name.end_content}>
              <div className={class_name?.inside_end_content}>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      name="RememberMe"
                      checked={isRemembermeChecked}
                      onChange={handleCheckboxChange}
                      icon={<span className="unChecked" />}
                      checkedIcon={<span className="checked">✓</span>}
                    />
                  }
                  label={
                    <span
                      style={{
                        fontSize: "14px",
                        color: "rgba(66, 70, 81, 0.87)",
                        marginLeft: "6%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Remember me
                    </span>
                  }
                />

                <Grid item xs={12}>
                  <Button
                    sx={{
                      "&.MuiButton-root:hover": { bgcolor: "#026b93" },
                      backgroundColor: "#026b93",
                    }}
                    variant="contained"
                    // color="primary"
                    type="submit"
                    className={class_name.login_btn_style}
                    disabled={isLoading}
                  >
                    Log in
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "15px",
                      width: "100%", // Adjust the width as needed
                      backgroundColor: "#f6f6f6",
                      justifyContent: "center",
                      borderStyle: "dotted",
                      borderWidth: 0.1,
                      borderColor: "gray",
                      borderRadius: "5px",
                      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <GoogleOAuthProvider clientId="496577884812-quv2n4j54nvk6gtrmo4vuv98cmrlf5q4.apps.googleusercontent.com">
                      <GoogleSignInButton
                        title={"Sign in with google"}
                        handle={handleSubmit}
                        isTermsOfServiceChecked={true}
                        style={true}
                      />
                    </GoogleOAuthProvider>
                  </div>
                </Grid>

                <div className="forgot-pwd-div mb-3">
                  <Typography
                    className="forgot-pwd-txt"
                    onClick={() => {
                      dispatch(EmailId(formik.values.email));
                      navigate("/forgotPassword");
                    }}
                  >
                    Forgot Password?
                  </Typography>
                  <Typography
                    className="no-acc-txt"
                    onClick={() => {
                      navigate("/userChoice");
                      dispatch(UserType(null));
                    }}
                  >
                    Don't have an account?
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  end_content: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    width: "100%",
  },
  inside_end_content: {
    width: "67%",
    [theme.breakpoints.down(767)]: {
      width: "90%",
    },
  },
  login_btn_style: {
    marginTop: "30px",
    width: "100%",
    backgroundColor: "#026b93",
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.09)",
    padding: "10px",
  },
}));
