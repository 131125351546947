/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preserve that ability, always export from Quest to regenerate this file.
 *   To setup element attributes and CSS properties, use the Quest web app
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from "@mui/material";
import { useSpring, easings } from "react-spring";
import IMAGES from "../../../Images";
import { Card, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { countryCodeJson } from "../../../Auth/countryCodeJson";
import { useDispatch, useSelector } from "react-redux";
import {
  city_list_API,
  user_details,
  user_updateUser,
} from "../../../../Service/api";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import { GetUserDetails } from "../../../../redux/slices";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Cancel, Edit } from "@material-ui/icons";
import { UserDetailsAPI } from "../../../../utils/UserDetailsAPI";
import { API } from "../../../../Service/constants";
import useWindowDimensions from "../../../../UI kit/useWindowDimensions";
import {parsePhoneNumberFromString,validatePhoneNumberLength} from 'libphonenumber-js'

const start_space_Validation = new RegExp(/^(?!\s).*/);
const emailIdValidation = new RegExp(
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i
);
const NotNumber = new RegExp(/^[^\d]*$/)

export const EditProfileTab = ({ drawerIsOpenOrNot }) => {
  const auth = useSelector((state) => state?.auth);
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const [selectedCountry, setSelectedCountry] = useState(countryCodeJson[189]); // Set the initial selected country
  const [showModal, setShowModal] = useState(false);
  const [showModalForCityNames, setShowModalForCityNames] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [profile_img, setProfile_img] = useState(null);
  const [hideBtn, setHideBtn] = useState(false);
  const dispatch = useDispatch();
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState(auth?.userDetails?.city);
  const [phoneNumberLength,setPhoneNumberLength] = useState(10)
  const { width } = useWindowDimensions();
  useEffect(() => {
    setHideBtn(drawerIsOpenOrNot);
  }, [drawerIsOpenOrNot]);

  useEffect(() => {
    let user_details_payload = {
      user_id: auth?.userDetails?.user_id,
    };
    user_details(auth?.AuthToken, user_details_payload)
      .then((res) => {
        // console.log("user_details res", res?.data);
        dispatch(GetUserDetails(res?.data?.data));
      })
      .catch((err) => {
        console.log("user_details err", err);
      });
      if(auth?.userDetails?.country_code === '+91'){
        setPhoneNumberLength(10)
      }
      else if(auth?.userDetails?.country_code === '+966'){
        setPhoneNumberLength(10)
      }
      else{
        setPhoneNumberLength(10)
      }
  }, [dispatch,auth?.AuthToken,auth?.userDetails?.user_id]);

  useEffect(() => {
    setCityList([]);
    city_list_API()
      .then((res) => {
        // console.log("res", res?.data);
        if (res?.data?.success) {
          setCityList(res?.data?.parameters);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  let errors = {};
  const formik = useFormik({
    initialValues: {
      // firstName: "",
      // lastName: "",
      // city: "",
      // cellNo: "",
      // email: "",
      firstName: auth?.userDetails?.first_name ?? "",
      lastName: auth?.userDetails?.last_name ?? "",
      city: auth?.userDetails?.city ?? "",
      cellNo: auth?.userDetails?.phone_number ?? "",
      email: auth?.userDetails?.email ?? "",
      country: countryCodeJson?.find((item)=>(item?.dial_code === auth?.userDetails?.country_code))
    },

    onSubmit: (values) => {
      handleSubmit(values);
    },

    validate: (values) => {
      if (!values.firstName) {
        errors.firstName = "Please enter first name";
      }
      if (!values.lastName) {
        errors.lastName = "Please enter last name";
      }

      if (!values.city) {
        errors.city = "Please Select your city";
      }

      if (!values.cellNo) {
        errors.cellNo = "Please enter your phone number";
      }

      if (values.cellNo?.toString()?.length !== 10) {
        errors.cellNo = "Please enter 10 digit phone number";
      }

      if (values.email === "") {
        errors.email = "Please enter your email";
      } else if (!values.email.match(emailIdValidation)) {
        errors.email = "Invalid email address";
      }

      return errors;
    },
  });

  // useEffect(()=>{
  //   const pNoLength = validatePhoneNumberLength(formik.values?.country?.dial_code)
  //   console.log(pNoLength,'pNoLength')
  // },[formik])

  // useEffect(()=>{
  //   if(formik.values.cellNo.length > phoneNumberLength){
  //     formik.values.cellNo = ''
  //   }
  // },[selectedCountry])

  const handleSubmit = async (value) => {
    setIsLoader(true);
    setHideBtn(true);
    // formik.setErrors({});

    let payload = new FormData();

    payload.append("first_name", value?.firstName);
    payload.append("last_name", value?.lastName);
    payload.append("email", value?.email);
    payload.append("phone_number", value?.cellNo);
    payload.append("country_code", value?.country?.dial_code);
    if (profile_img) {
      payload.append("profile_img", profile_img);
    }
    payload.append("city", value?.city);

    //print console
    for (const [key, value] of payload.entries()) {
      console.log(key, ":", `'${value}'`);
    }

    user_updateUser(auth?.AuthToken, payload)
      .then((res) => {
        console.log("res", res?.data);
        if (res?.data?.success) {
          UserDetailsAPI(
            auth?.userDetails?.user_id,
            auth?.AuthToken,
            (err, ress) => {
              dispatch(GetUserDetails(ress));
            }
          );
          toast.success("user details updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else {
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        setIsLoader(false);
        setHideBtn(false);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message??'Something went wrong. Please try again later', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setIsLoader(false);
        setHideBtn(false);
      });
  };

  const handleCountryChange = (values) => {
    formik.setFieldValue('country',values)
    const countryCode = values;
    const selectedCountry = countryCodeJson.find(
      (country) => country.code === countryCode
    );
    setSelectedCountry(selectedCountry);
    setShowModal(false);
    setHideBtn(false);

    // if(selectedCountry.dial_code === '+91'){
    //   setPhoneNumberLength(10)
    // }
    // else if(selectedCountry.dial_code === '+966'){
    //   setPhoneNumberLength(10)
    // }
    // else{
    //   setPhoneNumberLength(10)
    // }
  };

  const handleCityChange = (values) => {
    const city_Code = values;
    const selectedCity = cityList.find((city) => city.city_id === city_Code);
    setSelectedCity(selectedCity);
    setShowModalForCityNames(false);
    setHideBtn(false);
    formik.values.city = selectedCity.city
  };

  const handleCountryCodeClick = () => {
    setShowModal(true);
    setHideBtn(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setHideBtn(false);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredCountries = countryCodeJson.filter((country) =>
    country.name.en.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileSelect(files);
  };

  const handleFileSelect = (files) => {
    if (files && files[0]) {
      const allowedExtensions = ["jpg", "jpeg", "png","webp"];
      const selectedFile = files[0];
      const fileName = selectedFile.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setProfile_img(selectedFile);
        // setProfile_img(URL.createObjectURL(selectedFile));
      } else {
        console.log(
          "Invalid file extension. Please select a file with extensions: jpg, jpeg, png"
        );
      }
    }
  };

  // const handleFileSelect = (files, fileType) => {
  //   if (files && files[0]) {
  //     const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
  //     const selectedFile = files[0];
  //     const fileName = files[0]?.name;
  //     const fileExtension = fileName.split(".").pop().toLowerCase();

  //     if (allowedExtensions.includes(fileExtension)) {
  //       switch (fileType) {
  //         case "ministryOfTrans":
  //           setMinistryOfTransDoc(selectedFile);
  //           break;
  //         case "generalDireOfBorderGuard":
  //           setGeneralDireOfBorderGuardDoc(selectedFile);
  //           break;
  //         case "boatDocumentationsAndLicenses":
  //           setBoatDocumentationsAndLicensesDoc(selectedFile);
  //           break;
  //         default:
  //           break;
  //       }
  //     } else {
  //       toast.error(
  //         "Invalid file extension. Please select a file with extensions: jpg, jpeg, png, pdf",
  //         {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 20000,
  //         }
  //       );
  //     }
  //   }
  // };

  const handleDoubleClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = (e) => handleFileSelect(e.target.files);
    fileInput.click();
  };

  return (
    <>
      {isLoader ? <Loader loading={isLoader} /> : null}
      <form onSubmit={formik.handleSubmit}>
        <div style={{}} className={class_name.body_content}>
          <div
            // style={{ padding: "50px" }}
            className={class_name.profile_img_content_box}
          >
            <div
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => e.preventDefault()}
              onClick={() => handleDoubleClick()}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className="p-3"
            >
              <div className={class_name.show_profile_img}>
                <img
                  src={
                    profile_img
                      ? URL.createObjectURL(profile_img)
                      : auth?.userDetails?.profile_image
                      ? `
                      
                      ${
                          auth?.userDetails?.profile_image //${API.baseUrls[API.currentEnv]}
                        }`
                      : IMAGES.PROFILE_ICON
                  }
                  alt="Avatar"
                  className={class_name.profile_images_style}
                />
                <div
                  style={{
                    display: hideBtn ? "none" : "flex",
                  }}
                >
                  <IconButton
                    onClick={(e) => {
                      handleFileSelect(e.target.files);
                    }}
                    className={class_name.edit_icon_btn}
                    style={{ backgroundColor: "white", padding: "5%" }}
                  >
                    <Edit
                      backgroundColor="#3973a5"
                      className={class_name.edit_icon_font}
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <div className={class_name.user_deatils_content_box}>
            <Card.Body
              className="p-3 align-items-center justify-content-center"
              style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                alignSelf: "center",
                margin: "0px",
                padding: "0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "auto",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CustomTextField
                  label={"First Name"}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  value={formik.values.firstName}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (start_space_Validation.test(inputValue) && NotNumber.test(inputValue)) {
                      formik.setFieldValue("firstName", inputValue);
                    }
                  }}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      marginTop: "30px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    // style: lable_text,
                    className: class_name.labelStyle,
                  }}
                  inputProps={{
                    style: {
                      // padding: "5%",
                      borderRadius: width >= 480? "15px":'8px',
                      border:
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                          ? "solid 1px red"
                          : "solid 1px rgba(66, 70, 81, 0.3)",
                    },
                    className: class_name.input_txt_style,
                  }}
                  style={{
                    margin: "0px",
                    borderRadius: "5px",
                    width: "45%",
                  }}
                />

                <CustomTextField
                  label={"Last Name"}
                  variant="standard"
                  margin="normal"
                  fullWidth
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  value={formik.values.lastName}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    if (start_space_Validation.test(inputValue) && NotNumber.test(inputValue)) {
                      formik.setFieldValue("lastName", inputValue);
                    }
                  }}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      marginTop: "30px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    // style: lable_text,
                    className: class_name.labelStyle,
                  }}
                  inputProps={{
                    style: {
                      // padding: "5%",
                      borderRadius: width >= 480? "15px":'8px',
                      border:
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                          ? "solid 1px red"
                          : "solid 1px rgba(66, 70, 81, 0.3)",
                    },
                    className: class_name.input_txt_style,
                  }}
                  style={{
                    margin: "0px",
                    borderRadius: "5px",
                    width: "45%",
                  }}
                />
              </div>

              <Row pt={2}>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  className={class_name.city_txtfield_row}
                >
                  <Typography className={class_name.city_label_txt} 
                  >
                    City
                  </Typography>
                  <CustomTextField
                    // label={
                    //   formik.values.city === "" ? <span style={{color: 'rgba(223,224,225,1)'}}>City</span> : null
                    // }
                    select
                    className={class_name.select}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="city"
                    name="city"
                    value={formik.values.city}
                    style={{border: 'none',marginTop:'10px'}}
                    SelectProps={{
                      displayEmpty: true,
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                        // anchorPosition:"bottom",
                        getContentAnchorEl: null,
                        
                      },
                    }}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    error={
                      formik.touched.city && Boolean(formik.errors.city)
                    }
                    helperText={formik.touched.city && formik.errors.city}
                    InputProps={{
                      style: {
                        // ...textFieldStyles,
                        backgroundColor: 'white',
                        padding: '0px',
                        borderRadius: width >= 480? "15px":'8px',
                        color: formik.values.city? '#212121':'#bcbcbc',
                        border:
                        formik.touched.city &&
                        Boolean(formik.errors.city)
                          ? "solid 1px red"
                          : "solid 1px rgba(66, 70, 81, 0.3)",
                      },
                    }}
                    InputLabelProps={{
                      shrink: false,
                      style: {
                        // top: width < 767 ? "-25%" : "-5%",
                        fontSize: "clamp(8px, 2vw, 16px)",
                        color: "#757575",
                        // height: width > 992? '10px': width> 768? "6px": width > 480? "0px": "-20px",
                        // translate: width>992? '0px -20%': width>768? '0px -30%': width>480? '0px -60%':'0px -80%',
                        display: 'flex',
                        alignItems: 'center',
                        // className: class_name.input_label_boatType
                      },
                    }}
                  >
                    <MenuItem
                    style={{
                      padding: "5px 10px",
                    }}
                    disabled
                    value=""
                    >
                      City
                    </MenuItem>
                    {cityList?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.city}
                        selected={formik.values.city === item.city}
                        onClick={()=>handleCityChange(item.city_id)}
                        style={{
                          width: "100%",
                          height: "auto",
                          textAlign: "left",
                          alignItems: "flex-start",
                          padding: "5px 10px",
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "clamp(16px, 3vw, 15px)",
                          color: "#424651",
                          overflowX: "hidden",
                          overflowY: "auto",
                        }}
                      >
                        {item?.city}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                  {/* <CustomTextField
                    label={"City"}
                    margin="normal"
                    fullWidth
                    // readonly={true}
                    id="City"
                    name="City"
                    placeholder="City"
                    value={formik.values.city}
                    onClick={() => setShowModalForCityNames(true)}
                    onChange={(event) => {
                      // const inputValue = event.target.value;
                      // if (start_space_Validation.test(inputValue)) {
                      // formik.setFieldValue("city", "");
                      // setSelectedCity("");
                      // }
                    }}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                      className: class_name.labelStyle,
                    }}
                    inputProps={{
                      readOnly: true,
                      style: {
                        border:
                          formik.touched.city && Boolean(formik.errors.city)
                            ? "solid 1px red"
                            : "solid 1px rgba(66, 70, 81, 0.2)",
                      },
                      className: class_name.input_txt_style,
                    }}
                    style={{
                      padding: "0px",
                      margin: "0px",
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        marginTop: "25px",
                      },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 0,
                          }}
                          onClick={() => {
                            setHideBtn(true);
                            setShowModalForCityNames(true);
                          }}
                        >
                          <IconButton
                          onClick={() => {
                            setHideBtn(true);
                            setShowModalForCityNames(true);
                          }}
                          >
                            <img
                              alt="down"
                              src={IMAGES.DOWN}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> */}

                  {/* Render the City options */}
                  {/* <Dialog
                    open={showModalForCityNames}
                    onClose={() => {
                      setHideBtn(false);
                      setShowModalForCityNames(false);
                    }}
                    scroll="paper"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <DialogTitle>Select City</DialogTitle>
                      <IconButton
                        onClick={() => {
                          setHideBtn(false);
                          setShowModalForCityNames(false);
                        }}
                        style={{
                          // alignContent: "center",
                          // alignItems: "center",
                          // alignSelf: "center",
                        }}
                      >
                        <Cancel />
                      </IconButton>
                    </div>
                    <DialogContent
                      dividers
                      style={{ minHeight: "auto", minWidth: "100%" }}
                    >
                      {cityList.map((city, index) => (
                        <MenuItem
                          key={city?.city_id}
                          value={city?.city}
                          style={{display:'flex',flexDirection: 'column',padding: '2px 0px',width: '100%'}}
                          onClick={() => {
                            handleCityChange(city.city_id);
                          }}
                        >
                          <Typography>{city.city} </Typography>
                        </MenuItem>
                      ))}
                    </DialogContent>
                  </Dialog> */}
                </Grid>
              </Row>

              <Row pt={1}>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  style={{
                    alignSelf: "center",
                    marginTop: "1%",
                  }}
                >
                  <CustomTextField
                    label="Phone Number"
                    margin="normal"
                    // className={class_name.phone_number_textfield}
                    fullWidth
                    id="cellNo"
                    name="cellNo"
                    placeholder="Phone Number"
                    value={`${formik.values.cellNo}`}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      if (start_space_Validation.test(inputValue) && inputValue.length <= phoneNumberLength && /^\d*$/.test(inputValue)) {
                        formik.setFieldValue("cellNo", inputValue);
                      }
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        // borderRadius: "5px",
                        marginTop: "25px",
                        borderRadius: width >= 480? "15px":'8px',
                        padding: '4px 5px',
                        border:
                          formik.touched.cellNo && Boolean(formik.errors.cellNo)
                            ? "solid 1px red"
                            : "solid 1px rgba(66, 70, 81, 0.3)",
                      },
                      // className: class_name.input_txt_style,
                      startAdornment: (
                        <div
                          onClick={handleCountryCodeClick}
                          style={{
                            cursor: "pointer",
                            justifyContent: "center",
                            display: "flex",
                            paddingLeft: "5%",
                            paddingRight: "5%",
                            display: 'flex',
                            gap: '8%',
                            alignItems: 'center',
                            // alignContent: 'center'
                          }}
                        >
                          {/* {selectedCountry && ( */}
                            <>
                              <Typography
                                className={class_name.flag_size_style}
                              >
                                {/* {selectedCountry.flag} */}
                                {formik?.values?.country?.flag}
                              </Typography>
                              <img
                                src={IMAGES.DOWN}
                                alt="up"
                                className={class_name.down_arror}
                              />
                              <Typography className={class_name.dial_code_txt}>
                                {/* {selectedCountry.dial_code} */}
                                {formik?.values?.country?.dial_code}
                              </Typography>
                            </>
                          {/* )} */}
                        </div>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        // ...lable_text,
                        // marginBottom: '10px'
                      },
                      className: class_name.labelStyle,
                    }}
                    inputProps={{
                      style: {
                        border: "none",
                        borderTopStyle: "solid 1px rgba(66, 70, 81, 0.2)",
                      },
                    }}
                    style={{
                      padding: "0px",

                      backgroundColor: "white",
                    }}
                  />
                  {formik.touched.cellNo && Boolean(formik.errors.cellNo) ? (
                    <span
                      style={{
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.touched.cellNo && formik.errors.cellNo}
                    </span>
                  ) : null}
                  {/* Render the country options */}
          <Dialog open={showModal} onClose={handleCloseModal} scroll="paper">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <DialogTitle>Select Country</DialogTitle>
              <IconButton
                onClick={handleCloseModal}
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <Cancel />
              </IconButton>
            </div>
            <DialogContent
              dividers
              style={{ 
              minHeight: "100%",
               minWidth: "100%",
               paddingTop: '0',
               overflowX: "hidden",
               overflowY: 'auto',
               }}
            >
              <div className="position-relative">
              <div className="position-sticky top-0 bg-white z-2">
                <TextField
                  label="Search Country"
                  variant="standard"
                  value={searchValue}
                  onChange={handleSearchChange}
                  className="po"
                  fullWidth
                  style={{ width: "84%" }}
                />
              </div>
              {filteredCountries.map((country, index) => (
                <MenuItem
                  key={country.code}
                  value={country}
                  style={{ width: "100%",whiteSpace: 'normal',display: 'flex', justifyContent: 'flex-start',padding: '2px 0px'}}
                  onClick={() => {
                    handleCountryChange(country);
                  }}
                >
                  <Typography className="modal-county-txt">
                    {country.flag}
                  </Typography>
                  {" "+country.name.en}
                </MenuItem>
              ))}
              </div>
            </DialogContent>
            {/* <DialogActions>
              <Button onClick={handleCloseModal}>Close</Button>
            </DialogActions> */}
          </Dialog>
                </Grid>
              </Row>

              <Row pt={1}>
                <Grid
                  item
                  xs={12}
                  sm={5.8}
                  style={{
                    alignSelf: "center",
                    marginTop: "15px",
                  }}
                >
                  <CustomTextField
                    label={"E-mail"}
                    margin="normal"
                    fullWidth
                    id="email"
                    name="email"
                    placeholder="Email"
                    disabled={true}
                    value={formik.values.email}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      if (start_space_Validation.test(inputValue)) {
                        formik.setFieldValue("email", inputValue);
                      }
                    }}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        marginTop: "30px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      // style: lable_text,
                      className: class_name.labelStyle,
                    }}
                    inputProps={{
                      style: {
                        color: 'gray',
                        borderRadius: width >= 480? "15px":'8px',
                        border:
                          formik.touched.email && Boolean(formik.errors.email)
                            ? "solid 1px red"
                            : "solid 1px rgba(66, 70, 81, 0.3)",
                      },
                      className: class_name.input_txt_style,
                    }}
                    style={{
                      padding: "0px",
                      margin: "0px",
                    }}
                  />
                </Grid>
              </Row>

              <Row
              // pt={1}
               className="gx-0"
                style={{
                  display: `flex`,
                  justifyContent: `flex-end`,
                  alignContent: `flex-end`,
                  alignItems: `flex-end`,
                  alignSelf: `flex-end`,
                  marginTop: "45px",
                  // gap: '0px'
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="default"
                  type="submit"
                  // style={{ ...ButtonContainedSpring }}
                  className={class_name.save_btn}
                >
                  Save
                </Button>
              </Row>
            </Card.Body>
          </div>
        </div>
      </form>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  return ({
    save_btn: {
      width: "40%",
      fontSize: "clamp(10px, 5vw, 18px)",
      textTransform: "none",
      backgroundColor: "#3973a5",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#3973a5",
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
      },
    },

    input_txt_style: {
      fontSize: "clamp(10px, 1vw, 18px)",
      // fontWeight: "600",
      // fontWeight: "bold",
      textAlign: "left",
      color: "#424651",
      padding: "13px 10px",
      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.09)",
      border: "solid 1px rgba(66, 70, 81, 0.2)",
      borderRadius: "5px",
      backgroundColor: "#fff",
    },

    labelStyle: {
      paddingLeft: "0px",
      fontSize: "clamp(16px, 1.8vw, 22px)",
      // marginBottom: '20px',
      // fontWeight: "bold",
      // fontStretch: "normal",
      // fontStyle: "normal",
      // lineHeight: 1.5,
      // letterSpacing: "normal",
      textAlign: "left",
      color: "#424651",
      // color: "dimgray",
      // [theme.breakpoints.down("sm")]: {
      //   paddingLeft: "0px",
      // },
      // [theme.breakpoints.up("md")]: {
      //   paddingLeft: "10px",
      // },
      // [theme.breakpoints.up("lg")]: {
      //   paddingLeft: "16px",
      // },
    },

    body_content: {
      width: "100%",
      height: "100%",
      borderRadius: "0",
      margin: "0px",
      padding: "3% 10%",
      border: "solid 0.5px rgba(66, 70, 81, 0.5)",
      backgroundColor: "white",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      //
      [theme.breakpoints.down("md")]: {
        padding: "3% 0%",
      },
      [theme.breakpoints.down("764")]: {
        flexDirection: "column",
        borderRadius: "2%",
      },
    },

    profile_images_style: {
      // width: "180px",
      // height: "180px",
      width: "clamp(80px, 20vw, 150px)", // Adjust the range as needed
      height: "clamp(80px, 20vw, 150px)", // Adjust the range as needed
      borderRadius: "90%",
      border: "solid 0.5px #3973a5",
    },

    show_profile_img: {
      display: "flex",
      position: "relative",
      // padding: "40px"
    },

    edit_icon_btn: {
      position: "absolute",
      border: "solid 0.5px #3973a5",
      color: "#3973a5",
      cursor: "pointer",
      zIndex: "1",
      right: 0,
      bottom: 0,
    },

    profile_img_content_box: {
      width: "40%",
      position: "relative",
      justifyContent: "center",
      // alignItems: "flex-start",
      // alignContent: "flex-start",
      // paddingLeft: "4%",
      [theme.breakpoints.down("764")]: {
        alignSelf: "center",
      },
    },

    user_deatils_content_box: {
      width: "100%",
      // margin: "0px",
      // padding: "0px",
      // backgroundColor: "green",
      // paddingRight: "10%",
      [theme.breakpoints.down("764")]: {
        padding: "0%",
      },
    },

    down_arror: {
      width: "8px",
      height: "8px",
      alignSelf: "center",
      [theme.breakpoints.up("sm")]: {
        width: "6px",
        height: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "8px",
        height: "8px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "10px",
        height: "10px",
      },
    },

    edit_icon_font: {
      fontSize: "clamp(16px, 2vw, 25px)",
      // fontSize: "5px",
    },

    flag_size_style: {
      fontSize: "clamp(14px, 5vw, 25px)"
    },

    dial_code_txt: {
      fontSize: "clamp(12px, 1vw, 16px)",
      textAlign: "center",
      // marginLeft: "5%",
      alignSelf: "center",
      color: "#424651",
    },
    dialog_box: {
      overflow: 'hidden'
    },
    city_txtfield_row: {
      alignSelf: "center",
      marginTop: "8%",
      position: 'relative',
      [theme.breakpoints.down('480')]:{
        marginTop: '11%'
      }
    },

    city_label_txt: {
      fontSize: "90%",
      color: '#424c5c',
      position: 'absolute',
      top: '-20px',
      left: '11px',
    [theme.breakpoints.down('767')]:{
      fontSize: '78%'
    },
    [theme.breakpoints.down('480')]:{
      top: '-15px',
    }
    },
    phone_number_textfield: {
      "& .MuiInputLabel-formControl" :{
        top: '1px',
        left: '0',
        position: 'absolute',
        // transform: translate(0, 24px) scale(1);
    },
    }
  });
});

// const lable_text = {
//   fontSize: "20px",
//   fontWeight: "600",
//   letterSpacing: "normal",
//   textAlign: "left",
//   color: "dimgray",
// };

const styles = (theme) => ({
  root: {
    //outer

    // Styles for the input text
    "& .MuiInputBase-input": {
      // padding: "3% 3%",

      fontSize: "14px",//clamp(14px, 1vw, 20px)
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      textAlign: "left",
      // paddingLeft: "20px",
      color: "rgba(66, 70, 81, 0.87)",
      // color: "red",
      borderBottom: "none",
      // backgroundColor: "red",
      // padding: "2.5%",
    },

    "& .MuiSelect-select":{
      padding: '13px 10px',
      paddingLeft: '9px',
    },

    // Styles for the placeholder text
    "& .MuiFormLabel-root": {
      borderBottom: "none",
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      borderBottom: "none",
      fontSize: "clamp(12px, 0.9vw, 20px)",
    },

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
      fontSize: "clamp(12px, 0.9vw, 20px)",
      border: "none",
    },

    "& input::placeholder": {
      fontSize: "10px",
      color: "rgba(66, 70, 81, 0.4)",
    },

    "& .MuiInputLabel-formControl" :{
      // top: '8px',
      // left: '0',
      // position: 'absolute',
      // transform: translate(0, 24px) scale(1);
  },

    // Apply styles for small screens
    [theme.breakpoints.down("sm")]: {
      "& .MuiInputBase-input": {},
      "& input::placeholder": {
        fontSize: "10px",
        color: "rgba(66, 70, 81, 0.4)",
      },
      borderRadius: "8px",
    },

    // Adjust for medium screens (md)
    [theme.breakpoints.up("md")]: {
      "& .MuiInputBase-input": {},
      "& input::placeholder": {
        fontSize: "14px",
        color: "rgba(66, 70, 81, 0.4)",
      },
      borderRadius: "15px",
    },

    // Adjust for large screens (lg)
    [theme.breakpoints.up("lg")]: {
      "& .MuiInputBase-input": {},
      "& input::placeholder": {
        fontSize: "16px",
        color: "rgba(66, 70, 81, 0.4)",
      },
      borderRadius: "15px",
    },
  },
});

const CustomTextField = withStyles(styles)(TextField);
