import { Dialog } from "@mui/material";
import { GoSell, config } from "@tap-payments/gosell";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import {
  open_payment_component,
  transaction_detail,
} from "../../../redux/slices";
// import bgImage from '../../../assets/Images/boat-background-img.jpeg'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Payment = ({
  open,
  redirectUrl,
  handleClosePayment,
  amount,
  callbackFunc,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div>
      {/* <Dialog
        open={open}
        onClose={handleClosePayment}
        scroll="paper"
        > */}
      <GoSell
        gateway={{
          publicKey: "pk_test_WQDd0Bin7pzvl9rK2R5A1HkE",//pk_test_Vlk842B1EA7tDN5QbrfGjYzh
          language: "en",
          contactInfo: true,
          supportedCurrencies: "all",
          supportedPaymentMethods: "all",
          saveCardOption: true,
          customerCards: true,
          notifications: "standard",
          backgroundImg: {
            // Image: bgImage,
            URL: require("../../../assets/Images/boat-background-img.jpeg"),
            opacity: "0.2",
          },
          callback: callbackFunc,
          onclose: handleClosePayment,
          labels: {
            cardNumber: "Card Number",
            expirationDate: "MM/YY",
            cvv: "CVV",
            cardHolder: "Name on Card",
            actionButton: "Pay",
          },
          style: {
            base: {
              color: "#535353",
              lineHeight: "18px",
              //  fontFamily: 'sans-serif',
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "rgba(0, 0, 0, 0.26)",
                fontSize: "15px",
              },
            },
            invalid: {
              color: "red",
              iconColor: "#fa755a ",
            },
          },
        }}
        customer={{
          first_name: "First Name",
          middle_name: "Middle Name",
          last_name: "Last Name",
          email: "demo@email.com",
          phone: {
            country_code: "965",
            number: "99999999",
          },
        }}
        order={{
          amount: 100,
          currency: "KWD",
          items: [
            {
              id: 1,
              name: "item1",
              description: "item1 desc",
              quantity: "x1",
              amount_per_unit: "KD00.000",
              discount: {
                type: "P",
                value: "10%",
              },
              total_amount: "KD000.000",
            },
            {
              id: 2,
              name: "item2",
              description: "item2 desc",
              quantity: "x2",
              amount_per_unit: "KD00.000",
              discount: {
                type: "P",
                value: "10%",
              },
              total_amount: "KD000.000",
            },
            {
              id: 3,
              name: "item3",
              description: "item3 desc",
              quantity: "x1",
              amount_per_unit: "KD00.000",
              discount: {
                type: "P",
                value: "10%",
              },
              total_amount: "KD000.000",
            },
          ],
          shipping: null,
          taxes: null,
        }}
        transaction={{
          mode: "charge",
          //  callbackFunc: callbackFunc(),
          //  onclose: handleClosePayment,
          charge: {
            saveCard: false,
            threeDSecure: true,
            description: "Test Description",
            statement_descriptor: "Sample",
            reference: {
              transaction: "txn_0001",
              order: "ord_0001",
            },
            metadata: {},
            receipt: {
              email: false,
              sms: true,
            },
            // redirect: 'http://localhost:3000/allMessage',
            post: null,
          },
        }}
      />
      {/* </Dialog> */}
    </div>
  );
};

export default Payment;
