import jwtDecode from "jwt-decode";
import io from "socket.io-client";
import { API } from "./constants";

class Socket {
  url = API.baseUrls[API.currentEnv];
  client = null;
  authToken = null;

  constructor() {
    this.client = io.connect(this.url);
  }
  addToken(token) {
    this.client = io.connect(this.url, { query: { token } });
  }
  removeToken() {
    this.client = io.connect(this.url);
  }

  connect(token) {
    if (token) {
      const decoded = jwtDecode(token); // Decode the token to get its payload
      if (decoded) {
        this.authToken = token;
        this.client = io.connect(this.url, { query: { token } });
        console.log("Connected with token:", decoded);
      } else {
        console.error("Invalid token");
      }
    } else {
      this.client = io.connect(this.url);
      console.log("Connected without token");
    }

    // Add event listeners or handle other socket logic here
    this.client.on("connect", () => {
      console.log("Connected");
    }); 

    // this.client.onevent = (packet) => {
    //   const args = packet.data || [];
    //   const eventName = args[0];
    //   const data = args.slice(1);
    //   if (this.authToken) {
    //     data.push({ token: this.authToken });
    //   }
    //   this.client.emit(eventName, ...data);
    // };

    // Add more event listeners and logic as needed
  }

  off(event, listener) {
    if (this.client) {
      this.client.off(event, listener);
      console.log(`Removed event listener for "${event}"`);
    }
  }

  disconnect() {
    if (this.client) {
      this.client.disconnect();
      this.client = null;
      console.log("Disconnected");
    }
  }
}

const instance = new Socket();

export default instance;

// import { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import io from "socket.io-client";
// import { API } from "./constants";

// const useSocket = () => {
//   const [socket, setSocket] = useState(null);
//   const url = API.baseUrls[API.currentEnv];
//   const token = useSelector((state) => state?.auth?.AuthToken);

//   useEffect(() => {
//     const newSocket = io.connect(url, { query: { token } });

//     setSocket(newSocket);

//     return () => {
//       newSocket.disconnect();
//     };
//   }, [url]);

//   const addToken = (token) => {
//     if (socket) {
//       socket.io.opts.query = { token };
//       socket.connect();
//     }
//   };

//   const removeToken = () => {
//     if (socket) {
//       delete socket.io.opts.query.token;
//       socket.connect();
//     }
//   };

//   return { socket, addToken, removeToken };
// };

// export default useSocket;
