import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PersonPinSharp, Star, StarOutline } from "@material-ui/icons";
import IMAGES from "../../../Images";
import { HeaderContent } from "../../../Common/map/HeaderContent";
import { my_listing, single_boat_data_API } from "../../../../Service/api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../../../Component/Footer/Footer";
import { StarRating } from "../../../../UI kit/5Star/StarRating";
import { PageHeader } from "../../../Dash/page-header/PageHeader";
import moment from "moment";
import Loader from "../../../Loader";
import { boatServiceList, boatTypeList, boat_register_location_data, boat_register_step1, boat_register_step2, boat_register_step3, single_boat_details_store } from "../../../../redux/slices";
import { API } from "../../../../Service/constants";

export const MyListings = () => {
  const class_name = useStyles({ min: 10, max: 60, unit: "px" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const [myList, setMyList] = useState([]);
  const [isLoading,setIsLoading] = useState(false)

  // console.log("myList", auth?.AuthToken);

  useEffect(() => {
    setIsLoading(true)
    my_listing(auth?.AuthToken)
      .then((res) => {
        console.log("my_listing res=>", res?.data);
        if (res?.data?.message === "success") {
          setMyList(res?.data?.boats);
        } else {
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log("my_listing err", err);
        setIsLoading(false)
      });
  }, [auth?.AuthToken]);



  const handle_navigation = (name) => {
    if (name === "chat") {
      navigate("/allMessage");
    } else if(name === 'My Listings'){
      navigate('/myListings')
    } else if(name === "Home"){
      navigate('/boatOwnerDashBoard')
    } else {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };
  // console.log(auth?.AuthToken)
  // console.log('mylist',myList.map((item)=>item))

  const handleEdit = (boat_id,user_id) =>{
    // navigate('/boatOfferStep1',{state:{boat_id: item?.dataValues?.boat_id,user_id:item?.dataValues?.user_id}})
    var payload = {
      boat_uuid: boat_id,
      user_id: user_id,
    };
    single_boat_data_API(auth?.AuthToken, payload)
    .then((res) => {
      console.log("res?.data", res?.data);
      if (res?.data?.success) {
        console.log('single boat detail updated')
        dispatch(single_boat_details_store(res?.data?.parameters));
        dispatch(boat_register_step1(null));
        dispatch(boat_register_step2(null));
        dispatch(boat_register_step3(null));
        navigate('/boatOfferStep1')
      } else {
        toast.error(res?.data?.error ?? "error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    })
    .catch((err) => {
      console.error("single boat data -=-=-=-=-=->>>>>  Error : ", err);
      toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    });
  }
  // const handleHeaderCallBack = (name) => {
  //   if (name === "Home") {
  //     if (auth?.tokenDecodeData?.user_type === "BOAT_OWNER") {
  //       navigate("/boatOwnerDashBoard");
  //     } else {
  //       // navigate("/rental");
  //       navigate("/boatOwnerDashBoard");
  //     }
  //   } else if (name === "Log In") {
  //     navigate("/logIn");
  //   } else if (name === "Sign Up") {
  //     navigate("/signUP");
  //   } else if (name === "My Listings") {
  //     navigate("/myListings");
  //   } else if (name === "For Boat Rentals" || name === "For Boat Owners") {
  //     toast.info("Under Development", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 2000,
  //     });
  //   } else if (name === "/searchBoat") {
  //     navigate("/searchBoat");
  //   }
  // };

  return (
    <>
      {/* <HeaderContent
        contentname1={"Home"}
        contentname2={"For Boat Owners"}
        contentname3={"For Boat Rentals"}
        contentname4={"My Listings"}
        handleBack={handleHeaderCallBack}
        search={"/searchBoat"}
        showLoginSignUp={auth?.AuthToken ? false : true}
        presentPage={"My Listings"}
      /> */}
          <Loader loading={isLoading} /> 
          <PageHeader
            showLoginSignUp={auth?.AuthToken ? false : true}
            handle_navigation={handle_navigation}
            presentPage={"My Listings"}
            link1={"Boat Offers"}
            link2={"Scuba Courses/Programs"}
            link3={"Scuba Diving Trips"}
            link4={"My Listings"}
          />
      <div style={{height: '100%'}}>
        <div className={class_name.myListingsContainer}>
          <div className={class_name.body}>
            <div className={class_name.headerContent} style={{marginBottom: !myList?.length? myList?.length: '0px'}}>
              {/* <div>
                <Typography className={class_name.titleName}>My Listings</Typography>
                <Typography className={class_name.subTitle}>
                  You have made {myList?.length} Boat Offers
                </Typography>
              </div>
              <div>
                <div className={class_name.AddNewOfferBtn}>
                <Button
                sx={{fontSize: 'clamp(12px,2vw,20px)',color: 'white'}}
                  onClick={() => {
                    dispatch(boat_register_step1(null));
                    dispatch(boat_register_step2(null));
                    dispatch(boat_register_step3(null));
                    dispatch(boatTypeList(null));
                    dispatch(boatServiceList(null));
                    dispatch(single_boat_details_store(null));
                    dispatch(boat_register_location_data(null));
                    navigate("/boatOfferStep1");
                  }}
                >
                  Add New Offer
                </Button>
                </div>
              </div> */}
            </div>
            {myList?.length?
            (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "156px",
              }}
            >
              {myList?.map((item,i) => (
                <>
                  <div
                    className={class_name.listings}
                    key={i}//item.id
                  >
                    <div
                     className={class_name.img_div}
                     >
                      <img
                        alt="boat image"
                        src={`${item?.dataValues?.front_image}`} //${API.baseUrls[API.currentEnv]}
                        className={class_name.boat_img}
                        onError={(err) => {
                          // console.log("err", err);
                        }}
                        onLoad={(load) => {
                          // console.log("on load", load);
                        }}
                      />
                    </div>
                    <div 
                    className={class_name.listings_body} 
                    style={{position: 'relative',top: 0,left: 0}}
                    >
                      <div
                      className=""
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        fontSize: '14px',
                        padding: '3.3px 0.6px'
                      }}
                      >
                        <span
                        style={{
                          backgroundColor: 
                          item?.dataValues?.ministry_transport_document_verified &&
                          item?.dataValues?.border_guard_document_verified &&
                          item?.dataValues?.boat_license_document_verified
                          ?'darkgreen':'darkred',
                          color: 'white',
                          padding: '4px 7px',
                          borderRadius: '2px'
                        }}
                        >
                          {
                            item?.dataValues?.ministry_transport_document_verified &&
                            item?.dataValues?.border_guard_document_verified &&
                            item?.dataValues?.boat_license_document_verified
                            ? 'Verified':'Unverified'
                          }
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <span
                        className={class_name.boat_name}
                        >
                          {item?.dataValues?.boat_name}
                        </span>

                        <StarRating rating={item?.dataValues?.averageRating} />
                        <div
                        className={class_name.price_div}
                        >
                          <img
                          className={class_name.money_img}
                            src={IMAGES.MONEY_CARD}
                            alt="money"
                          />

                          <span
                          className={class_name.money_text}
                          >
                            {item?.dataValues?.price_per_hour} {item?.dataValues?.price_currency}
                            <span
                            className={class_name.money_hour_text}
                            >
                              {"per hour"}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div
                      className={class_name.date_editicon_div}
                      >
                        <div 
                        className={class_name.date_txt}
                        >
                          {item?.dataValues?.createdAt? moment(item?.dataValues?.createdAt).format("DD.MM.YYYY"): "Date"}
                        </div>
                        <div
                          className={class_name.icon_div}
                        >
                          <div 
                          className={class_name.icon}
                          >
                          <span
                          onClick={() => {handleEdit(item?.dataValues?.boat_uuid,item?.dataValues?.user_id)}}
                          >
                            <img
                              alt="edit"
                              src={IMAGES.EDIT_ICON}
                              className={class_name.editIcon}
                            />
                          </span>
                          </div>
                          <div
                           className={class_name.icon}
                          >
                          <span
                            onClick={() => {navigate('/requestList',{
                              state:
                              {
                                boat_id: item?.dataValues?.boat_id,
                                // boat_booking_id: item?.dataValues?.boat_booking_id
                              }
                            })}}
                          >
                            <img
                              alt="edit"
                              src={IMAGES.USER_PROFILE}
                              className={class_name.editIcon}
                            />
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>)
            :
            (<div className={class_name.noDataContent}>
              <img
                alt="no result"
                src={IMAGES.NO_RESULT}
                className={class_name.noResultImg}
              />
              <span className={class_name.sryTxt}>
                Sorry, No Records found.
              </span>
            </div>)
            }
          </div>
        </div>
        <div className="d-none d-md-block">
        <Footer />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  myListingsContainer: {
    display: "flex",
    backgroundColor: "#f6f6f6",
    width: "100%",
    minHeight: '100%'
  },
  body: {
    display: "flex",
    width: "100%",
    paddingRight: "13%",
    paddingLeft: "13%",
    flexDirection: "column",
  },
  headerContent: {
    marginTop: "97px",
    // marginTop: "27px",
    flexDirection: "row",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "90px",
  },

  listings: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    backgroundColor: "white",
    boxShadow: "0px 0px 1px rgba(66, 70, 81, 0.3)",
  },

  titleName: {
    fontSize: "40px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#424651",
    textAlign: "left",
  },
  subTitle: {
    fontSize: "25px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.52,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
  },
  AddNewOfferBtn: {
    padding: "10px 48px",
    objectFit: "contain",
    backgroundColor: "#3973a5",
    borderRadius: 30,
    fontSize: "21px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.48,
    letterSpacing: "normal",
    textAlign: "left",
    // color: "#f6f6f6",
    color: 'white',
    "&:hover":{
      backgroundColor: '#3973a5'
    }
  },

  img_div: {
    width: "276px", 
    height: "210px"
  },

  boat_img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },

  listings_body: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '25px 36px'
  },

  boat_name: {
    fontSize: "22px",
    fontWeight: "800",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "#424651",
    marginBottom: "10px",
  },

  price_div: {
    display: "flex",
    paddingTop: '33px'
  },

  money_img: {
    width: "26px",
    height: "27px",
    marginRight: "20px",
  },

  money_text: {
    fontSize: "20px",
    color: "#424651",
    whiteSpace: 'nowrap'
  },

  money_hour_text: {
    marginLeft: "10px", 
    fontWeight: "bold",
    fontSize: '16px'
  },

  date_editicon_div: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-end",
  },

  date_txt: {
    fontSize: '18px'
  },

  icon_div: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: '10px',
    marginTop: "25px",
  },

  icon: {
    width: "44px",
    height: "44px",
    borderRadius: '50%',
    border: "1px solid #3973a5",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  boatListBody: {
    display: "flex",
    flexDirection: "row",
  },

  filledStar: {
    fill: "#f9c909",
  },
  emptyStar: {
    // border: "1px solid black",
  },
  editIcon: {
    width: "20px",
    height: "20px",
  },

  noDataContent: {
    display: "flex",
    flexDirection: "column",
    marginTop: "100px",
    alignItems: "center",
  },
  noResultImg: {
    width: "clamp(80px, 35vw, 139px)", // Adjust the range as needed
    height: "clamp(80px, 35vh, 139px)", // Adjust the range as needed
  },
  sryTxt: {
    fontSize: "clamp(14px, 3vw, 48px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.87)",
  },
  noResultTxt: {
    marginTop: "24px",
    fontSize: "clamp(12px, 3vw, 36px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.45)",
    width: "70%",
    textAlign: "center",
    marginBottom: "100px",
  },

  "@media(max-width: 1024px)": {
    body: {
      padding: '0px 5%'
    }
  },
  "@media(max-width: 768px)": {
    headerContent: {
      margin: '60px 0px'
    },

    listings_body: {
      padding: '20px 26px'
    },

    titleName: {
      fontSize: '30px'
    },
    subTitle: {
      fontSize: '18px'
    },
    AddNewOfferBtn: {
      fontSize: '15px',
      padding: "5px 20px",
    },
    boat_name: {
      fontSize: '16px'
    },
    money_img: {
      width: "20px",
      height: "21px",
      marginRight: "10px",
    },
    money_text: {
      fontSize: '16px'
    },
    money_hour_text: {
      marginLeft: "8px", 
      fontSize: '14px'
    },
    date_txt: {
      fontSize: '14px'
    },
    editIcon: {
      width: "16px",
      height: "16px",
    },
    icon_div: {
      marginTop: '18px'
    },
    icon: {
      width: "34px",
      height: "34px",
    },
    img_div: {
      width: "276px", 
      height: "180px"
    },
  },
  "@media(max-width: 480px)": {
    headerContent: {
      margin: '25px 0px'
    },
    listings_body: {
      padding: '23px 16px 0px 16px'
    },
    titleName: {
      fontSize: '22px'
    },
    subTitle: {
      fontSize: '12px'
    },
    AddNewOfferBtn: {
      fontSize: '12px',
      padding: "1px 6px",
    },
    boat_name: {
      fontSize: '14px',
      marginBottom: '0px'
    },
    price_div: {
      paddingTop: '10px'
    },
    money_img: {
      width: "18px",
      height: "19px",
      marginRight: "8px",
    },
    money_text: {
      fontSize: '11px'
    },
    money_hour_text: {
      marginLeft: "6px", 
      fontSize: '11px'
    },
    date_txt: {
      fontSize: '12px'
    },
    editIcon: {
      width: "13px",
      height: "13px",
    },
    icon_div: {
      marginTop: '14px'
    },
    icon: {
      width: "28px",
      height: "28px",
    },
    img_div: {
      width: "170px", 
      height: "130px"
    },
  },
}));


//boat list
const boatList = [
  {
    id: 1,
    boat_name: "Night Light, Jeddah",
    star: 4,
    price_currency: "300 SAR",
    date: "18.07.2022",
    image: IMAGES.boat1,
  },
  {
    id: 1,
    boat_name: "Black Purl",
    star: 2,
    price_currency: "30 SAR",
    date: "31.07.2022",
    image: IMAGES.boat2,
  },
  {
    id: 1,
    boat_name: "Tanker",
    star: 3.5,
    price_currency: "500 SAR",
    date: "11.07.2022",
    image: IMAGES.boat3,
  },
  {
    id: 1,
    boat_name: "Titen",
    star: 4.5,
    price_currency: "700 SAR",
    date: "15.07.2022",
    image: IMAGES.boat4,
  },
];
