import moment from "moment";

export const formatTimeAgo = (date) => {
  const chatDate = moment(date)
  const now = moment()

  // console.log(moment(date)?.format('DD-MM-YYYY h:mm A'),moment()?.format('DD-MM-YYYY h:mm A'),'updated time',now.diff(chatDate, 'hours'))


  if(now.diff(chatDate, 'seconds') < 60){
    return 'Just Now'
  } 
  else if(now.diff(chatDate, 'minutes') < 60){
    return `${now.diff(chatDate, 'minutes')} ${now.diff(chatDate, 'minutes') < 2? 'min':'mins'} ago`
  } 
  else if(now.diff(chatDate, 'hours') < 24){
    return `${now.diff(chatDate, 'hours')} ${now.diff(chatDate, 'hours') < 2? 'hour':'hours'} ago`
  } 
  else if(now.diff(chatDate, 'days') < 2){
    return 'Yesterday'
  } 
  else {
    return moment(date).format('DD/MM/YYYY')
  }
  // const now = moment();
  // const inputDate = moment(date);

  // const diffInSeconds = now.diff(inputDate, 'seconds');
  // const diffInMinutes = now.diff(inputDate, 'minutes');
  // const diffInHours = now.diff(inputDate, 'hours');
  // const diffInDays = now.diff(inputDate, 'days');

  // if (diffInSeconds < 60) {
  //   return 'just now';
  // } else if (diffInMinutes < 60) {
  //   return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
  // } else if (diffInHours < 24) {
  //   return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
  // } else if (diffInDays === 1) {
  //   return 'yesterday';
  // } else {
  //   return inputDate.format('MMMM Do YYYY, h:mm a');
  // }
};
