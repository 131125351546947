import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import IMAGES from "../../Images";
import { resend_otp, verify_forgotpass_otp } from "../../../Service/api";
import Loader from "../../Loader";
import "./VerifyForgotPwdOTP.css";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useOtpInputRefs = (length) => {
  const inputRefs = React.useMemo(() => {
    return Array(length)
      .fill()
      .map(() => React.createRef());
  }, [length]);

  return inputRefs;
};

const CustomTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      border: "none",
    },

    "& .MuiFilledInput-underline": {
      border: "none",
    },

    "& input::placeholder": {
      fontSize: "clamp(10px, 5vw, 16px)",
      color: "rgba(66, 70, 81, 0.4)",
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-input": {
      padding: '18.5px 13px'
    },

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      borderBottom: "1px solid transparent",
      borderRadius: "0",
      border: "none",
    },

    [theme.breakpoints.down("sm")]: {
      "& input::placeholder": {
        fontSize: "13px",
      },
    },
    [theme.breakpoints.between("sm", "md")]: {
      "& input::placeholder": {
        fontSize: "14px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& input::placeholder": {
        fontSize: "18px",
      },
    },
  },
}))(TextField);

export const VerifyForgotPwdOTP = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const inputRefs = useOtpInputRefs(6);
  const user = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [OTPcount, setOTPcount] = useState(0);
  const [flag, setFlag] = useState(0);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [showResend, setShowResend] = useState(true);
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    const otp = inputRefs.map((ref) => ref.current.value).join("");
    setOTPcount(otp?.length);
  }, [inputRefs, flag]);

  const startCountdown = () => {
    ResentOTP();
    setErrorMsg("");
    setShowResend(false);
    let timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      setShowResend(true);
      setCountdown(60);
    }, 60000);
  };

  const ResentOTP = () => {
    let payload = {
      name: user?.name,
      email: user?.emailId,
    };
    // console.log("payload", payload);
    resend_otp(payload)
      .then((res) => {
        // console.log("res", res);
        inputRefs.forEach((ref) => {
          ref.current.value = "";
        });
      })
      .catch((err) => {
        console.log("Resent API Error", err);
      });
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    setErrorMsg("");
    toast.dismiss();
    const otp = inputRefs.map((ref) => ref.current.value).join("");
    if (otp?.length >= 6) {
      let payload = {
        email: user?.emailId,
        otp: otp,
      };
      // console.log("verify_forgotpass_otp payload", payload);

      verify_forgotpass_otp(payload)
        .then((res) => {
          // console.log("verify_forgotpass_otp res", res);
          if (res?.data?.message === "User Verified successfully") {
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            navigate("/changePassword");
            setIsLoading(false);
          } else {
            setErrorMsg(res?.data?.message);
            toast.error(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("verify forgotpass OTP Error : ", err);
          setIsLoading(false);
          toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        });
    } else {
      setIsLoading(false);
      toast.error("Please enter your OTP", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  // const handleInputChange = (event, index) => {
  //   const value = event.target.value;
  //   if (value.length === 1 && index < inputRefs.length - 1) {
  //     inputRefs[index + 1].current.focus();
  //     setErrorMsg("");
  //   }
  // };

  const handleInputChange = (event, index) => {
    const value = event.target.value;
    if (value.length >= 1 && index < inputRefs.length - 1) {
      // Split the value into individual characters
      const chars = value.split('');
      // Iterate over each character
      for (let i = 0; i < chars.length; i++) {
        // If the current inputRef exists, set its value and focus the next one
        if (inputRefs[index + i]) {
          inputRefs[index + i].current.value = chars[i];
          if (inputRefs[index + i + 1]) {
            inputRefs[index + i + 1].current.focus();
          }
        }
      }
      setErrorMsg("");
    }
  };

  const handleInputBackspace = (event, index) => {
    if (event.key === "Backspace" && index > 0) {
      if (inputRefs[index].current.value === "") {
        inputRefs[index - 1].current.focus();
      } else {
        inputRefs[index].current.value = "";
      }
    }
  };

  return (
    <div className={class_name.pwd_full_box}>
      {isLoading ? <Loader loading={isLoading} /> : null}
      <div className={class_name.icon_and_back}>
        <div
          className={class_name.top_back_button}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            src={IMAGES.LEFT_BACK_BUTTON}
            className={class_name.back_iocn}
            alt="Back"
          />
        </div>
        <img src={IMAGES.APP_ICON} alt="ICON" className={class_name.appIcon} />
      </div>
      <div className={class_name.page_body_container}>
        <div className={class_name.form_container_box}>
          <div
            onClick={() => {
              navigate(-1);
            }}
            className={class_name.with_txt_back_icon}
          >
            <img
              src={IMAGES.LEFT_BACK_BUTTON}
              className={class_name.back_iocn}
              alt="Back"
            />
            <Typography className={class_name.back_txt}>Back</Typography>
          </div>

          <div className={class_name.inside_body}>
            <Typography className={class_name.pageTitle_pwd}>
              Enter the six-digit code we sent to your email to verify your
              authentication
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "2%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                {inputRefs.map((ref, index) => (
                  <>
                    <div style={{}} className={class_name.show_text_box}>
                    <CustomTextField
                    key={index}
                    variant={"outlined"}
                    // label='underline'
                    type="text"
                    inputRef={ref}
                    onChange={(event) => {
                      setFlag(Math.random(14.12 * 1));
                      const inputValue = event.target.value;
                      const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
                      if (inputValue !== numericValue) {
                        event.target.value = numericValue; // Update the input field value with only numeric characters
                      }
                      handleInputChange(event, index);
                    }}
                    onKeyDown={(event) => {
                      setFlag(Math.random(14.12 * 1));
                      handleInputBackspace(event, index);
                    }}
                    InputProps={{
                      disableUnderline: true,
                      maxLength: 1,
                      style: {},
                      className: class_name.insideTextBox,
                      // classes: {underline: {borderBottom: 'yellow'}}
                    }}
                    inputProps={{
                      inputMode: 'numeric'
                    }}
                    // inputProps={{
                    //   // disableUnderline: true,
                    
                    // }}
                    style={{
                      border: errorMsg
                        ? "solid 1px red"
                        : focusedIndex === index
                        ? "solid 1px rgba(66, 70, 81, 0.87)"
                        : "solid 0.3px #424651",
                    }}
                    className={class_name.textFiled}
                    onFocus={() => setFocusedIndex(index)}
                    onBlur={() => setFocusedIndex(null)}
                  />
                    </div>
                  </>
                ))}
              </div>
              {errorMsg ? (
                <Typography
                  style={{ marginTop: "0%", color: "#DC143C", fontSize: 12 }}
                >
                  {"Invalid Otp, Please try again"}
                </Typography>
              ) : null}
            </div>

            <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {errorMsg ? (
              <Typography className="error-msg-txt">{errorMsg}</Typography>
            ) : null}

            {showResend ? (
              <Typography
                variant="contained"
                onClick={startCountdown}
                className={class_name.resend_txt}
              >
                Resend OTP
              </Typography>
            ) : (
              <Typography
                variant="body2"
                style={{
                  color: "#3973a5",
                  marginTop: errorMsg ? "18px" : "48px",
                  textAlign: "center",
                  borderRadius: "5px",
                  fontSize: 14,
                  marginBottom: "0px",
                }}
              >
                Resend OTP in {countdown} seconds
              </Typography>
            )}

            {/* <div
              className={class_name?.Verify_Continue_btn}
              onClick={handleSubmit}
              style={{
                backgroundColor:
                  OTPcount === 6 ? "#026b93" : "rgba(151, 151, 151, 1)",
              }}
            >
              <Typography
                className={`${class_name.Verify_Continue_txt} hoverEffect`}
              >
                Verify & Continue
              </Typography>
            </div> */}
          </div>

            <div
              className={class_name?.Verify_Continue_btn}
              style={{
                backgroundColor:
                  OTPcount === 6 ? "#026b93" : "rgba(151, 151, 151, 1)",
              }}
            >
              <Typography
                className={`${class_name.Verify_Continue_txt} hoverEffect`}
                onClick={handleSubmit}
              >
                Verify & Continue
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  pwd_full_box: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#f6f6f6",
    paddingBottom: "100px",
  },
  page_body_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    height: "100%",
    backgroundColor: "#f6f6f6",
    paddingBottom: "100px",
  },

  form_container_box: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
    height: "100%",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    border: "solid 0.5px rgba(66, 70, 81, 0.1)",
    padding: "1% 1%",
    borderRadius: "2px",
    width: "90%",
    marginTop: "15px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "30px",
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "30px",
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "40px",
      width: "70%",
    },
  },

  icon_and_back: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  top_back_button: {
    display: "none",
  },

  back_iocn: {
    width: "14px",
    height: "18px",
  },

  appIcon: {
    marginLeft: "140px",
    width: "clamp(136px, 23vw, 198px)",
    height: "clamp(52px, 13vw, 98px)",
  },

  back_txt: {
    marginLeft: "20px",
    fontSize: "clamp(10px, 5vw, 20px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#3973a5",
  },

  body_content: { display: "flex", height: "90%" },
  container_body: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },

  pageTitle_pwd: {
    fontSize: "clamp(12px, 5vw, 32px)", // Adjust the range as needed
    textAlign: "center",
    color: "rgba(66, 70, 81, 1)",
    width: "70%",
  },

  inside_body: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },

  with_txt_back_icon: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    alignSelf: "flex-start",
    margin: "20px 10px",
  },

  show_text_box: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    margin: "1%",
    [theme.breakpoints.down(300)]: {
      margin: ".2%",
    },
  },

  insideTextBox: {
    top: 10,
    backgroundColor: "white",
  },

  textFiled: {
    borderRadius: "5%",
    width: "clamp(48px, 3vw, 50px)", // Adjust the range as needed
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 10%",
      margin: "0 0 60%",
      height: "80%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 10%",
      margin: "0 0 60%",
      height: "90%",
    },
    [theme.breakpoints.up("lg")]: {},
  },

  Verify_Continue_btn: {
    marginTop: "5%",
    cursor: "pointer",
    borderRadius: "3px",
    border: "solid 1px #026b93",
    padding: "2% 7%",
    display: "flex",
    transition: "background-color 0.3s",
    backgroundColor: "#026b93", // Add a smooth transition for the background color
    "&:hover": {
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
      // Change the background color on hover
      "& $Verify_Continue_txt": {},
    },
  },

  Verify_Continue_txt: {
    fontSize: "clamp(8px, 2vw, 24px)", // Adjust the range as needed
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.96,
    letterSpacing: "normal",
    textAlign: "center",
    color: "white",
  },

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    pwd_full_box: {
      width: "100%",
      height: "100vh",
      backgroundColor: "white",
      paddingBottom: "100px",
    },

    icon_and_back: {
      padding: "16px 16px 0px 16px",
    },

    appIcon: {
      marginLeft: "24px",
      width: "clamp(96px, 25vw, 165px)",
      height: "clamp(48px, 15vw, 98px)",
    },

    top_back_button: {
      display: "flex",
      alignSelf: "center",
    },

    with_txt_back_icon: {
      display: "none",
    },

    insideTextBox: {
      top: 0,
      height: "90%",
      marginBottom: 0,
      paddingBottom: 0,
      width: "100%",
      borderBottom: "none",
    },

    textFiled: {
      backgroundColor: "white",
      [theme.breakpoints.up(300)]: {
        width: "40px",
        height: "70%",
        padding: "5%",
      },
      [theme.breakpoints.down(300)]: {
        width: "38px",
        height: "60%",
        padding: "0%",
        marginLeft: "1px",
      },
    },

    Verify_Continue_txt: {
      fontSize: "clamp(16px, 5vw, 20px)", // Adjust the range as needed
      fontWeight: "400",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.96,
      letterSpacing: "normal",
      textAlign: "center",
      color: "white",
    },

    form_container_box: {
      display: "flex",
      justifyContent: "center",
      alignSelf: "center",
      alignContent: "center",
      height: "100%",
      backgroundColor: "#f6f6f6",
      boxShadow: "none",
      border: "none",
      padding: "1% 1%",
      borderRadius: "2px",
      width: "100%",
      marginTop: "15px",
    },
  },
}));
