import "./ImageSlider.css";

const ImageSlider = ({ slides, show, onClose, goToPrevious, goToNext }) => {
  const handleContentClick = (event) => {
    // Prevent propagation of click events to the parent elements
    event.stopPropagation();
  };
  console.log(slides,'img')

  return (
    <div style={{}}>
      <div
        onClick={onClose}
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100vh",
          backgroundColor: "rgba(\n    0,\n    0,\n    0,\n    0.5\n  )",
          // overflow:'auto',
          zIndex: "9999",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
        }}
      >
        <div
          onClick={handleContentClick}
          style={{
            maxWidth: "80%",
            maxHeight: "80%",
            overflow: "auto",
          }}
        >
          <div style={{ display: "flex" }}>
            {goToPrevious && goToNext?
            <div style={{}}>
              <img
                alt="left"
                src={require("../../../../assets/Images/leftbutnIcon.png")}
                onClick={() => goToPrevious()}
                className="left-arrow"
              />
              <img
                alt="right"
                src={require("../../../../assets/Images/rightbutnIcon.png")}
                className="right-arrow"
                onClick={() => goToNext()}
              />
            </div>:null
            }
            {slides ? (
              <img alt="boat img" src={slides} className="slides-img-style img-fluid" />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
