import {
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  user_verified_number_API,
  verify_forgotpass_otp,
} from "../../../../../Service/api";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../Loader";
import { UserDetailsAPI } from "../../../../../utils/UserDetailsAPI";
import { GetUserDetails } from "../../../../../redux/slices";
import { Message } from "@material-ui/icons";

const useOtpInputRefs = (length) => {
  const inputRefs = React.useMemo(() => {
    return Array(length)
      .fill()
      .map(() => React.createRef());
  }, [length]);

  return inputRefs;
};

export const VerifyOTPmodal = ({ handleModalClose, verify = null }) => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const dispatch = useDispatch();
  const inputRefs = useOtpInputRefs(6);
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const auth = useSelector((state) => state?.auth);
  const [OTPcount, setOTPcount] = useState(0);
  const [flag, setFlag] = useState(0);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [showInvalidOtp, setShowInvalidOtp] = useState(false)

  useEffect(() => {
    const otp = inputRefs.map((ref) => ref.current.value).join("");
    setOTPcount(otp?.length);
  }, [inputRefs, flag]);

  const handleContentClick = (event) => {
    // Prevent propagation of click events to the parent elements
    event.stopPropagation();
  };

  // const handleInputChange = (event, index) => {
  //   const value = event.target.value;
  //   if (value.length === 1 && index < inputRefs.length - 1) {
  //     inputRefs[index + 1].current.focus();
  //     setErrorMsg("");
  //   }
  // };

  const handleInputChange = (event, index) => {
    const value = event.target.value;
    if (value.length >= 1 && index < inputRefs.length - 1) {
      // Split the value into individual characters
      const chars = value.split('');
      // Iterate over each character
      for (let i = 0; i < chars.length; i++) {
        // If the current inputRef exists, set its value and focus the next one
        if (inputRefs[index + i]) {
          inputRefs[index + i].current.value = chars[i];
          if (inputRefs[index + i + 1]) {
            inputRefs[index + i + 1].current.focus();
          }
        }
      }
      setErrorMsg("");
    }
  };

  const handleInputBackspace = (event, index) => {
    if (event.key === "Backspace" && index > 0) {
      if (inputRefs[index].current.value === "") {
        inputRefs[index - 1].current.focus();
      } else {
        inputRefs[index].current.value = "";
      }
    }
  };

  const verify_OTP_api = () => {
    if (inputRefs) {
      console.log('working or not')
      setIsLoader(true);
      toast.dismiss();

      const otp = inputRefs?.map((ref) => ref.current.value).join("");

      let payload = {
        otp: otp,
      };

      if (verify) {
        let payload = {
          email: auth?.userDetails?.email,
          otp: otp,
        };
        console.log("verify_forgotpass_otp payload", payload);

        verify_forgotpass_otp(payload)
          .then((res) => {
            console.log("verify_forgotpass_otp res", res);
            if (res?.data?.success) {
              handleModalClose("open change pwd");
              toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            } else {
              setErrorMsg(res?.data?.message);
              setShowInvalidOtp(true)
              toast.error(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
            setIsLoader(false);
          })
          .catch((err) => {
            console.log("verify_forgotpass_otp err", err);
            setIsLoader(false);
            toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          });
      } else {
        user_verified_number_API(auth?.AuthToken, payload)
          .then((res) => {
            // console.log("res", res?.data);
            const msg = Boolean(res?.data?.message === "Invalid Otp" && otp?.length === 6)
            // console.log(msg)
            setShowInvalidOtp(msg)
            if (res?.data?.success) {
              handleModalClose();
              UserDetailsAPI(
                auth?.userDetails?.user_id,
                auth?.AuthToken,
                (err, ress) => {
                  dispatch(GetUserDetails(ress));
                }
              );
              toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 20000,
              });
            } else {
              setShowInvalidOtp(true)
              setErrorMsg(res?.data?.message);
              toast.error(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
            setIsLoader(false);
          })
          .catch((err) => {
            setIsLoader(false);
            console.log("err", err);
            toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          });
      }
    } else {
      setIsLoader(false);
      toast.error("Please enter your OTP", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const maskEmail = (email) => {
    if (email.length <= 6) {
      // If the email has 6 characters or less, return it as is
      return email;
    } else {
      // Mask characters before the last 6 characters with asterisks
      const maskedCharacters = "*".repeat(email.length - 5);
      return maskedCharacters + email.slice(-6);
    }
  };

  const maskPhoneNumber = (phoneNumber) => {
    // Convert the phoneNumber to a string to ensure it's a string
    phoneNumber = phoneNumber.toString();

    if (phoneNumber.length <= 6) {
      // If the phoneNumber has 6 digits or fewer, return it as is
      return phoneNumber;
    } else {
      // Mask characters before the last 6 digits with asterisks
      const maskedCharacters = "*".repeat(phoneNumber.length - 6);
      return maskedCharacters + phoneNumber.slice(-6);
    }
  };

  return (
    <>
      <div
        onClick={handleModalClose}
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "rgb(114, 117, 124,0.9)",
          zIndex: "9999",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          onClick={handleContentClick}
          className={class_name.inside_modal_body}
        >
          <ToastContainer
            position="top-right"
            // theme="red"
            autoClose={2000} // Adjust as needed
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
          />
          {isLoader ? <Loader loading={isLoader} /> : null}
          <Typography style={{}} className={class_name.pageTitle_txt}>
            {"Verify "}
            {verify ? "Email Id" : "Phone Number"}
          </Typography>

          <div className={class_name.verify_otp_body}>
            <Typography style={{}} className={class_name.page_subTitle_txt}>
              Keep your account more secure
            </Typography>

            <Typography style={{}} className={class_name.page_info_txt}>
              We have sent you a six digit code to{" "}
              {verify ? "Email Id " : "mobile number "}
              {verify
                ? maskEmail(auth?.userDetails?.email)
                : maskPhoneNumber(auth?.userDetails?.phone_number)}
              {/* ********** */}
            </Typography>

            <div className={class_name.body_content}>
              <div className="w-100 d-flex flex-column align-items-center">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  // flexWrap: "wrap",
                  width: "100%",
                }}
              >
                {inputRefs.map((ref, index) => (
                  <>
                    <div style={{}} className={class_name.show_text_box}>
                    <CustomTextField
                      key={index}
                      variant={"outlined"}
                      // label='underline'
                      type="text"
                      inputRef={ref}
                      // onFocusCapture={{}}
                      onChange={(event) => {
                        setShowInvalidOtp(false)
                        setFlag(Math.random(14.12 * 1));
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
                        if (inputValue !== numericValue) {
                          event.target.value = numericValue; // Update the input field value with only numeric characters
                        }
                        handleInputChange(event, index);
                      }}
                      onKeyDown={(event) => {
                        setFlag(Math.random(14.12 * 1));
                        handleInputBackspace(event, index);
                      }}
                      InputProps={{
                        disableUnderline: true,
                        maxLength: 1,
                        style: {},
                        className: class_name.insideTextBox,
                        // classes: {underline: {borderBottom: 'yellow'}}
                      }}
                      inputProps={{
                        inputMode: 'numeric'
                      }}
                      // inputProps={{
                      //   // disableUnderline: true,
                      
                      // }}
                      style={{
                        border: errorMsg
                          ? "solid 1px red"
                          : focusedIndex === index
                          ? "solid 2px rgba(66, 70, 81, 0.87)"
                          : "solid 0.3px #424651",
                      }}
                      className={class_name.textFiled}
                      onFocus={() => setFocusedIndex(index)}
                      onBlur={() => setFocusedIndex(null)}
                    />
                    </div>
                  </>
                ))}
                </div>
                <div>
                  <span className="text-danger"
                  style={{display: showInvalidOtp? 'block': 'none'}} 
                  >
                    Invalid OTP
                  </span>
                </div>
              </div>

              <div
                style={{
                  paddingTop: "10%",
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "flex-end",
                  width: "60%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    onClick={handleModalClose}
                    className={class_name.cancel_txt}
                  >
                    Cancel
                  </Typography>
                  <div
                    style={{
                      backgroundColor:
                        OTPcount === 6 ? "#3973a5" : "rgba(151, 151, 151, 1)",
                      marginLeft: "10%",

                      padding: "3% 15%",
                      borderRadius: "15px",
                      color: "white",
                      cursor: "pointer",
                    }}
                    // className={class_name.cancel_txt}
                    onClick={() => {
                      toast.dismiss();
                      const otp = inputRefs
                        ?.map((ref) => ref.current.value)
                        .join("");

                      otp?.length === 6
                        ? verify_OTP_api()
                        : toast.error("Please enter your OTP", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 2000,
                          });
                    }}
                  >
                    <Typography className={class_name.cancel_txt}>
                      Verify
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  inside_modal_body: {
    borderRadius: "0px",
    // padding: "1%",
    backgroundColor: "white",
    margin: "0",
    display: "flex",
    flexDirection: "column",
    width: "70%",
    // height: 'auto',
    alignSelf: "center",
    [theme.breakpoints.down("764")]: {
      width: "90%",
      // height: "auto",
    },
  },

  pageTitle_txt: {
    fontSize: "clamp(18px, 3vw, 26px)",
    fontWeight: "bolder",
    textAlign: "center",
    // paddingBottom: "50px",
    borderBottom:'solid 1px lightgray',
    padding: '4% 0px'
  },

  verify_otp_body: {
    padding: '0% 10%',
    [theme.breakpoints.down('480')]:{
      padding: '0% 1%'
    }
  },

  page_subTitle_txt: {
    fontSize: "clamp(16px, 3vw, 24px)",
    paddingTop: '5%',
    fontWeight: "bolder",
    textAlign: "center",
  },
  page_info_txt: {
    fontSize: "clamp(12px, 3vw, 22px)",
    fontWeight: "normal",
    textAlign: "center",
  },
  body_content: {
    width: "100%",
    height: "100%",
    margin: "5% 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  render_box_style: {
    width: "10%",
    height: "100%",
    display: "flex",
  },
  cancel_txt: {
    fontSize: "clamp(8px, 3vw, 18px)",
    fontWeight: "normal",
    textAlign: "center",
    cursor: "pointer",
  },

  textFiled: {
    borderRadius: "5%",
    width: "clamp(48px, 3vw, 50px)", // Adjust the range as needed
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 10%",
      margin: "0 0 60%",
      height: "80%",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 10%",
      margin: "0 0 60%",
      height: "90%",
    },
    [theme.breakpoints.down("lg")]: {},
  },

  show_text_box: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    margin: "1%",
    [theme.breakpoints.down(300)]: {
      margin: ".2%",
    },
  },
  insideTextBox: {
    top: 10,
    backgroundColor: "white",
  },
  insideTextBox: {
    top: 10,
    backgroundColor: "white",
  },

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //

  "@media (max-width: 767px)": {
    textFiled: {
      backgroundColor: "white",
      [theme.breakpoints.up(300)]: {
        width: "40px",
        height: "70%",
        padding: "3%",
      },
      [theme.breakpoints.down(300)]: {
        width: "38px",
        height: "60%",
        padding: "0%",
        marginLeft: "1px",
      },
    },

    insideTextBox: {
      top: 0,
      height: "90%",
      marginBottom: 0,
      paddingBottom: 0,
      width: "100%",
      borderBottom: "none",
    },
  },
}));

const CustomTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      border: "none",
    },

    "& .MuiFilledInput-underline": {
      border: "none",
    },

    "& input::placeholder": {
      fontSize: "clamp(10px, 5vw, 16px)",
      color: "rgba(66, 70, 81, 0.4)",
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "none", // Change this to your preferred color
      },
    },

    "& .MuiOutlinedInput-input":{
      padding: '18.5px 13px'
    },

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      // borderBottom: "1px solid transparent",
      borderRadius: "0",
      border: "none",
    },

    [theme.breakpoints.down("sm")]: {
      "& input::placeholder": {
        fontSize: "13px",
      },
    },
    [theme.breakpoints.between("sm", "md")]: {
      "& input::placeholder": {
        fontSize: "14px",
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& input::placeholder": {
        fontSize: "18px",
      },
    },
  },
  '@media (max-height: 575.98px) and (orientation: landscape)': {
    inside_modal_body: {
      height: '100vh',
      overflowY: 'auto'
    }
  }
}))(TextField);
