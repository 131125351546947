import React, { useEffect, useState } from "react";
import { isString, useFormik } from "formik";
import {
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useNavigate } from "react-router-dom";
import { Cancel, Visibility, VisibilityOff } from "@material-ui/icons";
import { countryCodeJson } from "../countryCodeJson";
import { useDispatch, useSelector } from "react-redux";
import {
  EmailId,
  UserType,
  confirmTickMsg,
  signUp_payload,
} from "../../../redux/slices";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { register } from "../../../Service/api";
import IMAGES from "../../Images";
import Loader from "../../Loader";
import { toast } from "react-toastify";
import GoogleSignInButton from "../Google/GoogleSignInButton";
import "./SignUp.css";
import { withStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment } from "@material-ui/core";
import { city_list_API } from "../../../Service/api";
import useWindowDimensions from "../../../UI kit/useWindowDimensions";

const start_space_Validation = new RegExp(/^(?!\s).*/);
const NotNumber = new RegExp(/^[^\d]*$/)
const emailIdValidation = new RegExp(
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i
);

const CustomCheckbox = withStyles({
  root: {
    color: "#ffffff",
    // width: "100px",
    "&$checked": {
      color: "#ffffff",
    },
  },
  checked: {},
})(Checkbox);

const styles = (theme) => ({
  root: {
    // Styles for the input text
    "& .MuiInputBase-input": {
      fontSize: "clamp(14px, 0.9vw, 20px)",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      textAlign: "left",
      paddingLeft: "10px",
      // color: "rgba(66, 70, 81, 0.87)",
      borderBottom: "none",
      // padding: "2.5%",
    },

    "& .MuiFormHelperText-contained": {
      marginLeft: '0px',
      fontSize: 'clamp(8px, 1vw, 12px)',
      color: '#DC143C'
    },

    // Styles for the placeholder text
    "& .MuiFormLabel-root": {
      borderBottom: "none",
    },

    "& .MuiSelect-selectMenu":{
      minHeight: 'auto'
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      borderBottom: "none",
      fontSize: "clamp(12px, 0.9vw, 20px)",
    },

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
      fontSize: "clamp(12px, 0.9vw, 20px)",
    },

    // "& input::placeholder": {
    //   fontSize: "clamp(12px, 0.9vw, 20px)",
    //   color: "rgba(66, 70, 81, 0.4)",
    // },

    // Apply styles for small screens
    [theme.breakpoints.down("sm")]: {
      "& .MuiInputBase-input": {},
      "& input::placeholder": {
        // fontSize: "8px",
        fontSize: "clamp(12px, 0.9vw, 20px)",
        // color: "rgba(66, 70, 81, 0.4)",
      },
      borderRadius: "8px",
    },

    // Adjust for medium screens (md)
    [theme.breakpoints.up("md")]: {
      "& .MuiInputBase-input": {},
      "& input::placeholder": {
        // fontSize: "12px",
        fontSize: "clamp(12px, 0.9vw, 20px)",
        // color: "rgba(66, 70, 81, 0.4)",
      },
      borderRadius: "15px",
    },

    // Adjust for large screens (lg)
    [theme.breakpoints.up("lg")]: {
      "& .MuiInputBase-input": {},
      "& input::placeholder": {
        fontSize: "16px",
        // color: "rgba(66, 70, 81, 0.4)",
      },
      borderRadius: "15px",
    },
  },
});

const CustomTextField = withStyles(styles)(TextField);

// Define the regex patterns for each condition
const regexPatterns = [
  /[a-z]/, // At least 1 lowercase letter
  /[A-Z]/, // At least 1 uppercase letter
  /[0-9]/, // At least 1 number
  /[!@#$%^&*]/, // At least 1 special character
];

export const SignUp = ({ drawerIsOpenOrNot }) => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const { width } = useWindowDimensions()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(false);
  const [strengthIndicator, setStrengthIndicator] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedCountry, setSelectedCountry] = useState(countryCodeJson[189]); // Set the initial selected country
  const [showModal, setShowModal] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(
    user?.SignUp_Payload_Data?.Email_Check_Box ?? false
  );
  const [isTermsOfServiceChecked, setIsTermsOfServiceChecked] = useState(false);
  const [termsOfServiceError, setTermsOfServiceError] = useState(false);
  const [googleResult, setGoogleResult] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  let errors = {};
  const [weakPwdErr, setWeakPwdErr] = useState(false);

  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [hideBtn, setHideBtn] = useState(false);
  const [showModalForCityNames, setShowModalForCityNames] = useState(false);
  const [phoneNumberLength,setPhoneNumberLength] = useState(10)

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const formik = useFormik({
    initialValues: {
      firstName: user?.SignUp_Payload_Data?.First_Name ?? "",
      lastName: user?.SignUp_Payload_Data?.Last_Name ?? "",
      email: user?.SignUp_Payload_Data?.Email_Id ?? "",
      cellNo: user?.SignUp_Payload_Data?.Cell_Phn_No ?? "",
      password: user?.SignUp_Payload_Data?.Password_SignUp ?? "",
      confirmPassword: user?.SignUp_Payload_Data?.Confirm_Password_SignUp ?? "",
      city: user?.SignUp_Payload_Data?.city ?? "",
    },

    onSubmit: (values) => {
      handleSubmit(values);
    },

    validate: (values) => {
      if (!values.firstName) {
        errors.firstName = "Please enter first name";
      }
      if (!values.lastName) {
        errors.lastName = "Please enter last name";
      }
      if (values.email === "") {
        errors.email = "Please enter your email";
      } else if (!values.email.match(emailIdValidation)) {
        errors.email = "Invalid email address";
      }
      if (!values.cellNo) {
        errors.cellNo = "Please enter your phone number";
      }

      if (values.cellNo?.length !== 10) {
        errors.cellNo = "Please enter 10 digit phone number";
      }
      // if(/^[0-9]*$/.test(values.cellNo) === false){
      //   errors.cellNo = "Please enter valid number"
      // }
      if (!values.password) {
        errors.password = "Please enter your password";
      }
      if (!values.city) {
        errors.city = "Please select your city";
      }
      // else if (values.password?.length < 8) {
      //   errors.password = "The minimum password length is 8 characters";
      // }
      if (!values.confirmPassword) {
        errors.confirmPassword = "Please enter your confirm password";
      } else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Password must be same";
      }
      return errors;
    },
  });

  useEffect(() => {
    setHideBtn(drawerIsOpenOrNot);
  }, [drawerIsOpenOrNot]);

  useEffect(() => {
    setCityList([]);
    city_list_API()
      .then((res) => {
        // console.log("res", res?.data);
        if (res?.data?.success) {
          setCityList(res?.data?.parameters);
        }
      })
      
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  //window Size Calculation
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // countryCodeJson.filter((country) => console.log(country))
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(()=>{
    if(formik.values.cellNo.length > phoneNumberLength){
      formik.values.cellNo = ''
    }
  },[selectedCountry])

  //Password strength check conditions
  useEffect(() => {
    let conditionsMet = 0;
    regexPatterns.forEach((pattern) => {
      if (pattern.test(formik.values.password)) {
        conditionsMet++;
      }
    });
    // Determine the password strength indicator based on the number of conditions met
    if (formik.values.password.length >= 8 && conditionsMet === 4) {
      setStrengthIndicator("green"); // All conditions met
    } else if (formik.values.password.length >= 8 && conditionsMet >= 3) {
      setStrengthIndicator("yellow"); // At least 4 conditions met
    } else {
      setStrengthIndicator("red"); // Less than 3 conditions met
    }
  }, [password]);

  // handleBackButton to store values
  useEffect(() => {
    const handleBackButton = (event) => {
      // Access Formik values from formik.values
      const {
        firstName,
        lastName,
        email,
        cellNo,
        password,
        confirmPassword,
        isEmailChecked,
        city,
      } = formik.values;

      // Dispatch your action with Formik values
      dispatch(
        signUp_payload({
          First_Name: firstName || "",
          Last_Name: lastName,
          Email_Id: email,
          Cell_Phn_No: cellNo,
          Password_SignUp: password,
          Confirm_Password_SignUp: confirmPassword,
          Email_Check_Box: isEmailChecked,
          city: city,
        })
      );
      // alert(city)
    };

    window.addEventListener("popstate", handleBackButton);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [dispatch, formik.values]);

  const handleCityChange = (values) => {
    const city_name = values;
    const selectedCity = cityList.find((city) => city.city === city_name);
    setSelectedCity(selectedCity);
    setShowModalForCityNames(false);
    setHideBtn(false);
    formik.values.city = selectedCity.city;
    // console.log(selectedCity)
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "email") {
      setIsEmailChecked(checked);
    } else if (name === "termsOfService") {
      setIsTermsOfServiceChecked(checked);
      setTermsOfServiceError(false);
    }
  };

  const handleCountryChange = (values) => {
    const countryCode = values;
    const selectedCountry = countryCodeJson.find(
      (country) => country.code === countryCode
    );
    setSelectedCountry(selectedCountry);
    setShowModal(false);

    if(selectedCountry.dial_code === '+91'){
      setPhoneNumberLength(10)
    }
    else if(selectedCountry.dial_code === '+966'){
      setPhoneNumberLength(10)
    }
    else{
      setPhoneNumberLength(10)
    }
  };

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    // Remove any non-alphabet characters using a regular expression
    const alphabetsOnly = inputValue.replace(/[^a-zA-Z ]/g, "");
    // Ensure that there is only one space in between text
    const cleanedValue = alphabetsOnly.replace(/ {2,}/g, " ");
    setSearchValue(cleanedValue);
  };

  const handleCountryCodeClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const filteredCountries = countryCodeJson.filter((country) =>
    country.name.en.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSubmit = async (value, type, allowGoogle) => {
    console.log(formik.values.password.length,strengthIndicator)
    toast.dismiss();
    setWeakPwdErr(false);
    if (strengthIndicator === "green" || allowGoogle === "GOOGLE") {
      setErrorMessage("");
      formik.setErrors({});
      if (isTermsOfServiceChecked) {
        setLoading(true);
        let payload = {
          first_name: value?.firstName || value?.name,
          email: value?.email,
          received_email: isEmailChecked,
          sign_in_type: type === "GOOGLE" ? "SOCIAL_LOGIN" : "EMAIL",
          user_type: user?.userType,
        };

        if (type !== "GOOGLE") {
          payload = {
            ...payload,
            last_name: value?.lastName,
            phone_number: value?.cellNo,
            country_code: selectedCountry?.dial_code,
            password: value?.password,
            city: value?.city,
          };
        }
        // console.log("handle Submit payload", payload);
        register(payload)
          .then((res) => {
            // console.log("register res", res);
            if (res?.data?.message?.toLowerCase() === "user registered successfully") {
              if (type !== "GOOGLE") {
                dispatch(EmailId(value?.email));
                dispatch(
                  signUp_payload({
                    First_Name: value?.firstName || value?.name,
                    Last_Name: value?.lastName,
                    Email_Id: value?.email,
                    Cell_Phn_No: value?.cellNo,
                    Password_SignUp: value?.password,
                    Confirm_Password_SignUp: value?.confirmPassword,
                    Email_Check_Box: isEmailChecked,
                    city: value?.city,
                  })
                );
                navigate("/verifyOTP");
              } else {
                dispatch(UserType(null));
                dispatch(signUp_payload(null));
                dispatch(
                  confirmTickMsg({
                    title:
                      "Your account has been created successfully, Please login!",
                    buttonName: "Go to login",
                  })
                );
                console.log(res?.data,'google')
                toast.success(res?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 20000,
                });
                navigate("/confirmation");
              }
            } else {
              if (
                res?.data?.message === "user already registered" &&
                type !== "GOOGLE"
              ) {
                formik.setFieldError("email", res?.data?.message);
              } else if (
                res?.data?.message === "Phone Number already registered"
              ) {
                formik.setFieldError("cellNo", res?.data?.message);
              }

              toast.error(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 20000,
              });
              console.log(res)
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log("Register API Error : ", err);

            toast.error(err?.message??"Something went wrong. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          });
      } else {
        setTermsOfServiceError(true);
      }
    } else {
      setWeakPwdErr(true);
      toast.error(
        `${
          strengthIndicator === "red"
            ? "Poor"
            : strengthIndicator === "yellow"
            ? "Weak"
            : null
        } Password`,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 20000,
        }
      );
    }
  };

  return (
    <div className={class_name.signUp_full_box}>
      {loading ? <Loader loading={loading} /> : null}

      <div className={class_name.show_outer_headder}>
        <img
          src={IMAGES.APP_ICON}
          alt="ICON"
          className={class_name.app_icon_style}
        />
      </div>

      <div className={class_name.show_inner_headder}>
        <img src={IMAGES.APP_ICON} alt="ICON" className="appIconStyle" />
      </div>

      <form
        onSubmit={formik.handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
          width: "100%",
          backgroundColor: "#f6f6f6",
          padding: '3% 0%'
        }}
      >
        <Typography className={class_name.page_title}>
          Create Account
        </Typography>
        <Typography className={class_name.page_info}>
          Open your gate to endless happiness!
        </Typography>
        <div className={class_name.text_filed_container_box}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: '10px',
              width: '100%',
              // height: "100%",
            }}
          >
            {/* First Name */}
            <div className="d-flex flex-column w-100">
            <Typography className={class_name.labelStyle} style={{display: width < 480? "flex":"none"}}>
              First Name
            </Typography>
            <CustomTextField
              style={{
                // backgroundColor: "white",
                height: "auto",
                width: "100%",
                borderRadius: "10px",
                marginTop: '0'
              }}
              className="text-fileds"
              margin="normal"
              fullWidth
              id="firstName"
              name="firstName"
              placeholder="First Name"
              value={formik.values.firstName}
              onChange={(event) => {
                const inputValue = event.target.value;
                if (start_space_Validation.test(inputValue) && NotNumber.test(inputValue)) {
                  formik.setFieldValue("firstName", inputValue);
                }
              }}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={
                formik.touched.firstName &&
                formik.errors.firstName && (
                  <Typography className={class_name.err_style_txt}>
                    {formik.errors.firstName}
                  </Typography>
                )
              }
              // variant="standard"
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: "white",
                  borderRadius: '5px',
                  border:
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                      ? "1px solid red"
                      : "solid .5px rgba(66, 70, 81, 0.2)",
                  padding: "2.5%",
                  // padding: width <= 479? '2%':'2.5%'
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                // className: "text-field-inputs",
                style: {
                  // backgroundColor: "greenyellow",
                  height: "100%",
                  // alignSelf: "center",
                  // alignItems: "center",
                  // textAlign: "center",
                  alignContent: "center",
                  display: "flex",
                  justifyContent: "center",
                },
              }}
              // label={ isSmallScreen?
              //   <span className="fw-bold d-block d-sm-none">First Name</span>:null
              // }
            />
            </div>

            {/* Last Name */}
            <div className="d-flex flex-column w-100">
            <Typography className={class_name.labelStyle} style={{display: width < 480? "flex":"none"}}>
              last Name
            </Typography>
            <CustomTextField
              style={{
                // backgroundColor: "white",
                height: "auto",
                width: "100%",
                borderRadius: "15px",
                marginTop: '0'
              }}
              className="text-fileds"
              margin="normal"
              fullWidth
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              value={formik.values.lastName}
              onChange={(event) => {
                const inputValue = event.target.value;
                if (start_space_Validation.test(inputValue) && NotNumber.test(inputValue)) {
                  formik.setFieldValue("lastName", inputValue);
                }
              }}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={
                formik.touched.lastName &&
                formik.errors.lastName && (
                  <Typography className={class_name.err_style_txt}>
                    {formik.errors.lastName}
                  </Typography>
                )
              }
              // variant="standard"
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: "white",
                  borderRadius: '4px',
                  border:
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                      ? "1px solid red"
                      : "solid .5px rgba(66, 70, 81, 0.2)",
                  padding: "2.5%",
                  // padding: width <= 479? '2%':'2.5%'
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                // className: "text-field-inputs",
                style: {
                  // backgroundColor: "greenyellow",
                  height: "100%",
                  // alignSelf: "center",
                  // alignItems: "center",
                  // textAlign: "center",
                  alignContent: "center",
                  display: "flex",
                  justifyContent: "center",
                },
              }}
              // label={ isSmallScreen?
              //   <span className="fw-bold d-block d-sm-none">Last Name</span>:null
              // }
            />
            </div>
          </div>

          {/* Email */}
          <Typography className={class_name.labelStyle} style={{display: width < 480? "flex":"none"}}>
              Email
            </Typography>
          <CustomTextField
            margin="normal"
            fullWidth
            id="email"
            name="email"
            placeholder="Email Address"
            value={formik.values.email}
            onChange={(event) => {
              const inputValue = event.target.value;
              if (start_space_Validation.test(inputValue)) {
                formik.setFieldValue("email", inputValue);
              }
            }}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              formik.touched.email &&
              formik.errors.email && (
                <Typography className={class_name.err_style_txt}>
                  {formik.errors.email}
                </Typography>
              )
            }
            variant="standard"
            InputProps={{
              disableUnderline: true,
              style: {
                backgroundColor: "white",
                borderRadius: "5px",
                border:
                  formik.touched.email && Boolean(formik.errors.email)
                    ? "1px solid red"
                    : "solid .5px rgba(66, 70, 81, 0.2)",
              },
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                // fontSize: "18px",
              },
            }}
            inputProps={
              {
                style:{
                  // ...single_text_field,
                  // padding: '2.5%'
                  padding: width <= 479? '3.5% 2.5%':'2.5%',
                  paddingLeft: '17px'
                },
                // className: {class_name.single_text_box}
                // className: "text-inputs",
              }
            }
          //   label={ isSmallScreen?
          //   <span className="fw-bold d-block d-sm-none">Email</span>:null
          // }
            className={class_name.text_field_class}
          />

          {/* Phone Number */}
          <Typography className={class_name.labelStyle} style={{display: width < 480? "flex":"none"}}>
              Phone Number
            </Typography>
          <CustomTextField
            margin="normal"
            // type="number"
            fullWidth
            id="cellNo"
            name="cellNo"
            placeholder="Cell Phone Number"
            // valuemin={10}
            value={formik.values.cellNo}
            // error={formik.touched.cellNo && Boolean(formik.errors.cellNo)}
            error={formik.touched.cellNo && Boolean(formik.errors.cellNo)}
            helperText={
              formik.touched.cellNo &&
              formik.errors.cellNo && (
                <Typography className={class_name.err_style_txt}>
                  {formik.errors.cellNo}
                </Typography>
              )
            }
            onChange={(event) => {
              const inputValue = event.target.value;
              if (start_space_Validation.test(inputValue) && inputValue.length <= phoneNumberLength && /^\d*$/.test(inputValue)) {
                formik.setFieldValue("cellNo", inputValue);
              }
            }}
            // style={{
            //   "-webkit-appearance": "none",
            //    margin: "0",
            
            // }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              style: {
                backgroundColor: "white",
                borderRadius: "5px",
                border:
                  formik.touched.cellNo && Boolean(formik.errors.cellNo)
                    ? "1px solid red"
                    : "solid .5px rgba(66, 70, 81, 0.2)",
              },
              startAdornment: (
                <div onClick={handleCountryCodeClick} className="country-div">
                  {selectedCountry && (
                    <>
                      <Typography className={class_name.flag_size_style}>
                        {selectedCountry.flag}
                      </Typography>
                      <img
                        src={IMAGES.DOWN}
                        alt="up"
                        style={{}}
                        className={class_name.down_arror}
                      />
                      <Typography className={class_name.dial_code_txt}>
                        {selectedCountry.dial_code}
                      </Typography>
                    </>
                  )}
                </div>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={
              {
                style:{
                  // ...single_text_field
                  padding: width <= 479? '3.5% 2.5%':'2.5%',
                  paddingLeft: '17px'
                }
                // className: "text-inputs",
              }
            }
            // style={{
            //   padding: "0px",
            //   borderRadius: "5px",
            //   backgroundColor: "white",
            // }}
            // label={ isSmallScreen?
            //   <span className="fw-bold d-block d-sm-none">Phone Number</span>:null
            // }
            className={class_name.text_field_class}
          />

          {/* Render the country options */}
          <Dialog open={showModal} onClose={handleCloseModal} scroll="paper">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <DialogTitle>Select Country</DialogTitle>
              <IconButton
                onClick={handleCloseModal}
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <Cancel />
              </IconButton>
            </div>
            <DialogContent
              dividers
              style={{
                minHeight: "auto",
                minWidth: "100%",
                paddingTop: "0",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              <div className="position-relative">
              <div className="position-sticky top-0 bg-white z-2">
                <TextField
                  label="Search Country"
                  variant="standard"
                  value={searchValue}
                  onChange={handleSearchChange}
                  className="po"
                  fullWidth
                  style={{ width: "84%" }}
                />
              </div>
              {filteredCountries.map((country, index) => (
                <MenuItem
                  key={country.code}
                  value={country}
                  style={{ width: "100%",whiteSpace: 'normal',display: 'flex', justifyContent: 'flex-start',padding: '2px 0px'}}
                  onClick={() => {
                    handleCountryChange(country.code);
                  }}
                >
                  <Typography className="modal-county-txt">
                    {country.flag}
                  </Typography>
                  {" "+country.name.en}
                </MenuItem>
              ))}
              </div>
            </DialogContent>
            {/* <DialogActions>
              <Button onClick={handleCloseModal}>Close</Button>
            </DialogActions> */}
          </Dialog>

          {/* mob no. err MSG */}
          {/* <div style={{ display: "flex", width: "100%" }}>
            {formik.touched.cellNo && Boolean(formik.errors.cellNo) ? (
              <Typography className={class_name.err_style_txt}>
                {formik.touched.cellNo && formik.errors.cellNo}
              </Typography>
            ) : null}
          </div> */}

          {/* city */}
          {/* <CustomTextField
            margin="normal"
            fullWidth
            select
            variant="standard"
            id="City"
            name="City"
            placeholder="City"
            value={formik.values.city}
            // onClick={() => setShowModalForCityNames(true)}
            onChange={(event) => {
              const inputValue = event.target.value;
              if (start_space_Validation.test(inputValue)) {
                formik.setFieldValue("city", inputValue);
              }
            }}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={
              formik.touched.city &&
              formik.errors.city && (
                <Typography className={class_name.err_style_txt}>
                  {formik.errors.city}
                </Typography>
              )
            }
            InputLabelProps={{
              shrink: false,
            }}
            // inputprops={{readonly: true,}}
            inputProps={{
              disableUnderline: true,
              readOnly: true,
              style: {
                borderWidth: ".1px",
                borderColor: "rgba(66, 70, 81, 0.2)",
                backgroundColor: "white",
                borderRadius: "5px",
                border:
                  formik.touched.city && Boolean(formik.errors.city)
                    ? "1px solid red"
                    : "solid .5px rgba(66, 70, 81, 0.2)",
              },
              className: class_name.input_txt_style,
            }}
            label={ isSmallScreen?
            <span className="fw-bold d-block d-sm-none">City</span>:null
            }
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                // anchorPosition:"bottom",
                getContentAnchorEl: null,
                
              },
            }}
            InputProps={{
              disableUnderline: true,
              // endAdornment: (
              //   <InputAdornment
              //     position="end"
              //     style={{
              //       position: "absolute",
              //       top: "50%",
              //       transform: "translateY(-50%)",
              //       right: 0,
              //     }}
              //     onClick={() => {
              //       setHideBtn(true);
              //       setShowModalForCityNames(true);
              //     }}
              //   >
              //     <IconButton
              //     onClick={() => {
              //       setHideBtn(true);
              //       setShowModalForCityNames(true);
              //     }}
              //     >
              //       <img
              //         alt="down"
              //         src={IMAGES.DOWN}
              //         style={{ width: "15px", height: "15px" }}
              //       />
              //     </IconButton>
              //   </InputAdornment>
              // ),
            }}
            className={class_name.text_field_class}
          >
            {cityList.map((item, index) => (
              <MenuItem
                key={index}
                value={item.city}
                selected={formik.values.city === item.city}
                onClick={()=>handleCityChange(item.city_id)}
                style={{
                  width: "auto",
                  height: "auto",
                  textAlign: "left",
                  alignItems: "flex-start",
                  padding: "5px 10px",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "clamp(16px, 3vw, 15px)",
                  color: "#424651",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                {item?.city}
              </MenuItem>
            ))}
          </CustomTextField> */}
          <Typography className={class_name.labelStyle} style={{display: width < 480? "flex":"none"}}>
            City
          </Typography>
          <CustomTextField
            // label={
            //   formik.values.city === "" ? <span style={{color: 'rgba(223,224,225,1)'}}>City</span> : null
            // }
            select
            className={class_name.select}
            variant="outlined"
            margin="normal"
            fullWidth
            id="city"
            name="city"
            value={formik.values.city}
            style={{marginTop: '0',border: 'none'}}
            SelectProps={{
              displayEmpty: true,
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                // anchorPosition:"bottom",
                getContentAnchorEl: null,
                
              },
            }}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            error={
              formik.touched.city && Boolean(formik.errors.city)
            }
            helperText={formik.touched.city && formik.errors.city}
            InputProps={{
              style: {
                ...textFieldStyles,
                backgroundColor: 'white',
                color: formik.values.city? '#212121':"#bcbcbc",
                borderRadius: "5px",
                border:
                  formik.touched.city &&
                  Boolean(formik.errors.city)
                    ? "solid 1px red"
                    : "solid 1px rgba(66, 70, 81, 0.3)",
              },
            }}
            inputProps={{
              style:{
                ...single_text_field
              }
            }}
            InputLabelProps={{
              shrink: false,
              style: {
                // top: width < 767 ? "-25%" : "-5%",
                fontSize: "clamp(8px, 2vw, 16px)",
                // color: "#757575",
                // height: width > 992? '10px': width> 768? "6px": width > 480? "0px": "-20px",
                // translate: width>992? '0px -20%': width>768? '0px -30%': width>480? '0px -60%':'0px -80%',
                display: 'flex',
                alignItems: 'center',
                // className: class_name.input_label_boatType
              },
            }}
          >
            <MenuItem
            disabled
            value=""
            style={{
              padding: "5px 10px",
            }}
            >
              City
            </MenuItem>
            {cityList?.map((item, index) => (
              <MenuItem
                key={index}
                value={item.city}
                selected={formik.values.city === item.city}
                onClick={()=>handleCityChange(item.city)}
                style={{
                  width: "100%",
                  height: "auto",
                  textAlign: "left",
                  alignItems: "flex-start",
                  padding: "5px 10px",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "clamp(16px, 3vw, 15px)",
                  color: "#424651",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                {item?.city}
              </MenuItem>
            ))}
          </CustomTextField>

          {/* Render the City options */}
          {/* <Dialog
            open={showModalForCityNames}
            onClose={() => {
              setHideBtn(false);
              setShowModalForCityNames(false);
            }}
            scroll="paper"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <DialogTitle>Select City</DialogTitle>
              <IconButton
                onClick={() => {
                  setHideBtn(false);
                  setShowModalForCityNames(false);
                }}
                style={{
                  alignContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <Cancel />
              </IconButton>
            </div>
            <DialogContent
              dividers
              style={{ minHeight: "auto", minWidth: "100%" }}
            >
              <TextField
                label="Search City"
                variant="standard"
                // value={searchValue}
                // onChange={handleSearchChange}
                fullWidth
                style={{ width: "84%" }}
              />
              {cityList.map((city) => (
                <MenuItem
                  key={city?.city_id}
                  value={city?.city}
                  style={{ width: "100%",display:'flex',flexDirection: 'column',padding: '2px 0px' }}
                  onClick={() => {
                    handleCityChange(city.city);
                  }}
                >
                  <Typography>{city.city} </Typography>
                </MenuItem>
              ))}
            </DialogContent>
          </Dialog> */}

          {/* Password */}
          <Typography className={class_name.labelStyle} style={{display: width < 480? "flex":"none"}}>
              Password
            </Typography>
          <CustomTextField
            type={showPassword ? "text" : "password"}
            margin="normal"
            fullWidth
            id="password"
            name="password"
            placeholder="Password"
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              formik.touched.password &&
              formik.errors.password && (
                <Typography className={class_name.err_style_txt}>
                  {formik.errors.password}
                </Typography>
              )
            }
            onChange={(event) => {
              const inputValue = event.target.value;
              if (start_space_Validation.test(inputValue)) {
                formik.setFieldValue("password", inputValue);
                setPassword(inputValue);
              }
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              style: {
                backgroundColor: "white",
                borderRadius: "5px",
                border:
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword) &&
                  strengthIndicator !== "green"
                    ? "1px solid red"
                    : "solid .5px rgba(66, 70, 81, 0.2)",
              },
              endAdornment: (
                <>
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <VisibilityOff className={class_name.eye_icon_font} />
                    ) : (
                      <Visibility className={class_name.eye_icon_font} />
                    )}
                  </IconButton>
                </>
              ),
            }}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            // style={{
            //   padding: "0px",
            //   borderRadius: "5px",
            //   backgroundColor: "white",
            // }}
            // InputProps={{
            //   disableUnderline: true,
            //   style: {
            //     backgroundColor: "white",
            //     borderRadius: "5px",
            //     border:
            //       formik.touched.confirmPassword &&
            //       Boolean(formik.errors.confirmPassword)
            //         ? "1px solid red"
            //         : "solid .5px rgba(66, 70, 81, 0.2)",
            //   },
            // }}
            inputProps={{
              style:{
                // ...single_text_field
                padding: width <= 479? '3.5% 2.5%':'2.5%',
                paddingLeft: '17px'
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
          //   label={ isSmallScreen?
          //   <span className="fw-bold d-block d-sm-none">Password</span>:null
          // }
            className={class_name.text_field_class}
          />

          {/* <div style={{ display: "flex", width: "100%" }}>
            {formik.touched.password && Boolean(formik.errors.password) ? (
              <Typography className={class_name.err_style_txt}>
                {formik.touched.password && formik.errors.password}
              </Typography>
            ) : null}
          </div> */}

          {password ? (
            <>
              {strengthIndicator !== "green" ? (
                <div className="small-pwd-strength-div">
                  <Typography className="small-pwd-strength-err-info">
                    The minimum password length is 8 characters and must contain
                    at least 1 lowercase letter, 1 uppercase letter, 1 number and
                    1 special character.
                  </Typography>
                </div>
              ) : (
                <div className="small-pwd-strength-div">
                  <Typography className="small-pwd-strength-success-info">
                    Your password is strong and secure. Good job!
                  </Typography>
                </div>
              )}
            </>
          ) : null}

          {/* Confirm Password */}
          <Typography className={class_name.labelStyle} style={{display: width < 480? "flex":"none"}}>
              Confirm Password
            </Typography>
          <CustomTextField
            type="password"
            margin="normal"
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formik.values.confirmPassword}
            onChange={(event) => {
              const inputValue = event.target.value;
              if (start_space_Validation.test(inputValue)) {
                formik.setFieldValue("confirmPassword", inputValue);
              }
            }}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <Typography className={class_name.err_style_txt}>
                  {formik.errors.confirmPassword}
                </Typography>
              )
            }
            // label={ isSmallScreen?
            //   <span className="fw-bold d-block d-sm-none">
            //     Confirm Password
            //   </span> : null
            // }
            variant="standard"
            InputProps={{
              disableUnderline: true,
              style: {
                backgroundColor: "white",
                borderRadius: "5px",
                border:
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword) &&
                  formik.values.password !== formik.values.confirmPassword
                    ? "1px solid red"
                    : "solid .5px rgba(66, 70, 81, 0.2)",
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {
                // ...single_text_field
                padding: width <= 479? '3.5% 2.5%':'2.5%',
                paddingLeft: '17px'
              }
            }}
            className={class_name.text_field_class}
          />

          {password ? (
            <div
              className={class_name.pwd_strength_div}
              style={{
                border: weakPwdErr
                  ? strengthIndicator === "red"
                    ? "1px solid  red"
                    : strengthIndicator === "yellow"
                    ? "1px solid  #FFAA33"
                    : strengthIndicator === "green"
                    ? "1px solid  green"
                    : "white"
                  : null,
              }}
            >
              <Typography className="pwd-strength-title">
                Password Strength{" "}
                <Typography
                  style={{
                    fontSize: 10,
                    color:
                      strengthIndicator === "red"
                        ? "red"
                        : strengthIndicator === "yellow"
                        ? "#FFAA33"
                        : strengthIndicator === "green"
                        ? "green"
                        : "white",
                  }}
                >
                  {strengthIndicator === "red"
                    ? "Poor"
                    : strengthIndicator === "yellow"
                    ? "Weak"
                    : strengthIndicator === "green"
                    ? "Good"
                    : null}
                </Typography>
              </Typography>
              <div className="pwd-strength-info-marker">
                <div
                  style={{
                    height: "5px",
                    width:
                      strengthIndicator === "red"
                        ? "30%"
                        : strengthIndicator === "yellow"
                        ? "65%"
                        : strengthIndicator === "green"
                        ? "100%"
                        : "0%",
                    backgroundColor:
                      strengthIndicator === "yellow"
                        ? "#FFAA33"
                        : strengthIndicator,
                  }}
                />
              </div>
              <Typography className="pwd-strength-info-txt">
                The minimum password length is 8 characters and must contain at
                least 1 lowercase letter, 1 uppercase letter, 1 number, and 1
                special character.
              </Typography>
            </div>
          ) : null}

          {/* check boat */}
          <div className="check-box-div">
            <FormControlLabel
              control={
                <>
                  <CustomCheckbox
                    name="email"
                    checked={isEmailChecked}
                    onChange={handleCheckboxChange}
                    icon={<Typography className={class_name.Checked_box} />}
                    checkedIcon={
                      <Typography className={class_name.Checked_box}>
                        ✓
                      </Typography>
                    }
                  />
                </>
              }
              label={
                <Typography className={class_name.check_box_txt}>
                  Receiving emails about latest news, offers and more
                </Typography>
              }
            />

            <FormControlLabel
              control={
                <CustomCheckbox
                  name="termsOfService"
                  checked={isTermsOfServiceChecked}
                  onChange={handleCheckboxChange}
                  icon={<Typography className={class_name.Checked_box} />}
                  checkedIcon={
                    <Typography className={class_name.Checked_box}>
                      ✓
                    </Typography>
                  }
                />
              }
              label={
                <Typography className={class_name.check_box_txt}>
                  Creating an account means you agree to our{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      toast.dismiss();
                      toast.info("Under Development", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                      });
                    }}
                  >
                    Terms of Service
                  </span>{" "}
                  and our{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      toast.dismiss();
                      toast.info("Under Development", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                      });
                    }}
                  >
                    Privacy Policy
                  </span>
                </Typography>
              }
            />
            {termsOfServiceError && (
              <Typography className={class_name.err_style_txt}>
                Please accept the Terms of Service & our Privacy Policy
              </Typography>
            )}
          </div>

          {/* end buttons */}
          <div style={{ marginTop: "1%" }}>
            <Button
              variant="contained"
              type="submit"
              className={class_name?.Create_Account_btn}
              style={{ backgroundColor: "#026b93" }}
            >
              <Typography
                className={`${class_name.Create_Account_txt} hoverEffect`}
              >
                Create Account
              </Typography>
            </Button>
            <div style={{ marginTop: "20px" }} />

            {/* GOOGLE LOGIN */}
            <GoogleOAuthProvider clientId="496577884812-quv2n4j54nvk6gtrmo4vuv98cmrlf5q4.apps.googleusercontent.com">
              <GoogleSignInButton
                googleResponce={setGoogleResult}
                handle={handleSubmit}
                isTermsOfServiceChecked={isTermsOfServiceChecked}
              />
            </GoogleOAuthProvider>

            {errorMessage && (
              <Typography className="error-msg-txt">{errorMessage}</Typography>
            )}
            <div
              className="login-div"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography className={class_name.acc_txt}>
                Already have an account?
              </Typography>
              <Typography
                onClick={() => {
                  navigate("/LogIn");
                }}
                className={class_name.acc_txt}
                style={{
                  textDecoration: "underline",
                  marginLeft: "15px",
                  cursor: "pointer",
                }}
              >
                Log in
              </Typography>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  signUp_full_box: {
    display: "flex",
    flexDirection: "column",
    height: "120vh",
    backgroundColor: "#f6f6f6",
  },
  show_outer_headder: {
    display: "none",
  },
  app_icon_style: {
    width: "200px",
    height: "100px",
  },
  body_content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    backgroundColor: "#f6f6f6",
  },
  err_style_txt: {
    color: "#DC143C",
    fontSize: "clamp(8px, 1vw, 12px)",
    // marginTop: "5px",
  },
  page_title: {
    fontSize: "clamp(26px, 4vw, 45px)",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.89,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.87)",
    marginBottom: "0.8%",
  },
  page_info: {
    fontSize: "clamp(14px, 3.5vw, 30px)",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.87)",
    marginBottom: "1.6%",
  },
  check_box_txt: {
    fontSize: "clamp(12px, 1.5vw, 16px)",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.87)",
    marginLeft: '4px',
    // whiteSpace: 'nowrap'
  },
  Create_Account_btn: {
    width: "100%",
    borderRadius: "5px",
    border: "solid 1px #026b93",
    // border: "solid 1px white",
    padding: "2% 7%",
    display: "flex",
    justifyContent: "center",
    transition: "background-color 0.3s",
    backgroundColor: "#026b93",
    whiteSpace: 'nowrap',
    "&:hover": {
      boxShadow: "4px 6px 12px rgba(0, 0, 0, 0.24)",
      "& $Create_Account_txt": {
        fontWeight: "600",
      },
    },
  },
  Create_Account_txt: {
    fontSize: "clamp(14px, 2vw, 18px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.96,
    letterSpacing: "normal",
    textAlign: "center",
    color: "white",
    padding: "1% 7%",
    textTransform: "none",
  },
  acc_txt: {
    fontSize: "clamp(12px, 2vw, 18px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.96,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.87)",
  },

  pwd_strength_div: {
    borderColor: "white",
    borderRadius: "5px",
    borderWidth: 1,
    borderStyle: "solid",
    alignSelf: "center",
    flex: 0.5,
    backgroundColor: "white",
    position: "absolute",
    right: "4%",
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    boxSizing: "border-box",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.09)",
    height: "auto",
    marginTop: "10px",
    flexDirection: "column",
    width: "20%",
  },
  eye_icon_font: {
    fontSize: "clamp(16px, 1vw, 20px)",
  },

  flag_size_style: {
    fontSize: "clamp(16px, 1.5vw, 20px)",
    marginRight: "7px",
  },
  dial_code_txt: {
    fontSize: "clamp(10px, 1vw, 12px)",
    textAlign: "center",
    marginLeft: "20%",
    alignSelf: "center",
    color: "#424651",
  },
  Checked_box: {
    color: "#424651",
    width: "16px",
    height: "16px",
    borderRadius: "1px",
    border: "1px solid rgba(66, 70, 81, 0.4)",
    //
    fontSize: "clamp(8px, 1.5vw, 16px)",
    textAlign: "center",
    // top: "10",
    // dispatch: "flex",
    // alignSelf: "center",
    // alignContent: "center",
    // alignItems: "center",
    //
    [theme.breakpoints.up("sm")]: {
      width: "16px",
      height: "16px",
    },
    [theme.breakpoints.up("md")]: {
      width: "18px",
      height: "18px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "22px",
      height: "22px",
    },
  },
  down_arror: {
    width: "6px",
    height: "6px",
    alignSelf: "center",
    [theme.breakpoints.up("sm")]: {
      width: "6px",
      height: "6px",
    },
    [theme.breakpoints.up("md")]: {
      width: "8px",
      height: "8px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "10px",
      height: "10px",
    },
  },

  text_filed_container_box: {
    display: "flex",
    flexDirection: "column",
    padding: "0 10%",
  },
  text_field_class: {
    margin: "0 0 2% 0",
    // mozAppearence: 'textfield'
  },
  labelStyle: {
    paddingLeft: "0px",
    fontWeight: 'bold',
    fontSize: "clamp(12px, 1.1vw, 22px)",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "gray",
  },
  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    "& .MuiOutlinedInput-input":{
      padding: '2.5%',
      paddingLeft: '17px',
      [theme.breakpoints.down('480')]:{
        padding: '3.2% 2.5%',
        paddingLeft: '10px',
      }
    },
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    app_icon_style: {
      width: "96px",
      height: "48px",
      margin: "16px",
    },
    show_outer_headder: {
      height: "auto",
      display: "flex",
      backgroundColor: "white",
    },
    show_inner_headder: {
      display: "none",
    },
    check_box_txt: {
      textAlign: "left",
    },
    pwd_strength_div: {
      display: "none",
    },
    text_filed_container_box: {
      display: "flex",
      flexDirection: "column",
      padding: "0 5%",
    },
    text_field_class: {
      margin: "0 0 2% 0",
    },
  },
  '@media (max-height: 575.98px) and (orientation: landscape)': {
    signUp_full_box: {
      height: '200vh',
      // backgroundColor: 'red'
    }
  },
}));
const textFieldStyles = {
  // borderRadius: "15px",
  borderWidth: ".1px",
  borderColor: "rgba(66, 70, 81, 0.2)",
};

const single_text_field = {
  padding: '2.5%',
  paddingLeft: '17px',
  fontSize: 'clamp(14px, 0.9vw, 20px)'
}