import React, { useEffect, useRef, useState } from "react";

import { Search } from "@material-ui/icons";
import { toast } from "react-toastify";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Divider, IconButton, Typography } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import Boat_Experience from "../../../assets/Images/removeable/Boating_Experience.png";
import Footer from "../../../Component/Footer/Footer";
import { BoatDetailCard } from "../Card/BoatDetailCard";
import { boat_list_filter } from "../../../Service/api";
import { search_boat_id } from "../../../redux/slices";
import { PageHeader } from "../page-header/PageHeader";
import IMAGES from "../../Images";
import useWindowDimensions from "../../../UI kit/useWindowDimensions";
import CardLoader from "../../Common/CardLoader";
import { API } from "../../../Service/constants";
import { SearchBoat } from "../Search/SearchBoat";

const styles = (theme) => ({
  root: {
    "& input::placeholder": {
      fontSize: "clamp(10px, 3vw, 24px)",
      color: "rgba(66, 70, 81, 0.4)",
    },
    [theme.breakpoints.down("767")]: {
      "& input::placeholder": {
        fontSize: "clamp(10px, 3vw, 24px)",
        // color: "red",
      },
    },
    [theme.breakpoints.down("424")]: {
      "& input::placeholder": {
        fontSize: "clamp(10px, 3vw, 24px)",
      },
    },
  },
});

const CustomTextField = withStyles(styles)(TextField);

export const Rental = () => {
  // const class_name = useStyles();
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state) => state?.auth);
  const link1 = "Boat Offers";
  const link2 = "Scuba Courses/Programs";
  const link3 = "Scuba Diving Trips";
  const link4 = "Settings";
  const [boatListDataDetails, setBoatListDataDetails] = useState("");
  const [boatListData, setBoatListData] = useState([]);
  const [extraInputValue, setExtraInputValue] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const containerRef = useRef(null);
  const cardRef = useRef(null);
  const [emptyItems, setEmptyItems] = useState(0);
  const [searchTxt, setSearchTxt] = useState("");

  const handleExtraInputChange = (event) => {
    setExtraInputValue(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // API call boat list
  useEffect(() => {
    setIsLoading(true);
    let payload = {
      pageNo: 1,
      maxNoOfData: 6,
    };
    boat_list_filter(auth?.AuthToken, payload)
      .then((res) => {
        // console.log("res", res?.data);
        if (res?.data?.message === "success") {
          setBoatListDataDetails(res?.data);
          setBoatListData(res?.data?.parameters);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  }, []);

  //  block back btn
  useEffect(() => {
    if (auth?.AuthToken) {
      const blockBackButton = (e) => {
        e.preventDefault();
        navigate(location.pathname);
      };
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", blockBackButton);
      return () => {
        window.removeEventListener("popstate", blockBackButton);
      };
    }
  }, [auth?.AuthToken, location.pathname, navigate]);

  // all click naviagtion
  const handle_navigation = (selected_page) => {
    toast.dismiss();
    if (selected_page === "chat") {
      navigate("/allMessage");
    } else {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleSearch = (value) => {
    setSearchTxt(value);
  };

  const showBoatCards = (item, index) => {
    return (
      <div
        className={
          boatListData?.length <= 2
            ? class_name.boat_card_space_change_space
            : class_name.boat_card_space
        }
        // style={
        //   width > 767
        //     ? {}
        //     : index === 0
        //     ? {
        //         marginLeft: "0px",
        //         backgroundColor: "green",
        //       }
        //     : {}
        // }

        onClick={() => {
          navigate("/boatViewDetails");
          dispatch(search_boat_id(item?.dataValues?.boat_id));
        }}
      >
        <BoatDetailCard
          boatName={item?.dataValues?.boat_name}
          marine_city={item?.dataValues?.marine_city}
          starRating={item?.dataValues?.averageRating}
          pricePerHour={item?.dataValues?.price_per_hour}
          priceCurrency={item?.dataValues?.price_currency}
          boatMaxCapacity={item?.dataValues?.boat_max_capacity}
          // profile_image={item?.profile_image}
          profile_image={`${item?.dataValues?.front_image}`} //${API.baseUrls[API.currentEnv]}
        />
      </div>
    );
  };
  return (
    <div className={class_name.rental_page}>
      {/* outSide header */}
      <div className={class_name.show_rental_header_outSide_banner}>
        <PageHeader
          showLoginSignUp={!auth?.AuthToken}
          handle_navigation={handle_navigation}
          presentPage={"Home"}
          link1={link1}
          link2={link2}
          link3={link3}
        />
      </div>

      {/* ================================================== banner ================================================== */}

      {/* header & content */}

      <div
        className={class_name?.rental_banner}
        style={{
          // height: windowWidth >= 768 ? windowHeight : "auto",
          // height: window.innerWidth > 1000 ? window.innerHeight : "auto",
          height: width > 1000 ? height : "50%",
          // padding: "0px",
          backgroundColor: "gray",
        }}
      >
        {/* inside header */}
        <div className={class_name.show_rental_header_inside_banner}>
          <PageHeader
            showLoginSignUp={!auth?.AuthToken}
            handle_navigation={handle_navigation}
            presentPage={"Home"}
            link1={link1}
            link2={link2}
            link3={link3}
            // link4={"chat"}
          />
        </div>
        {/* banner txt content */}
        <div className={class_name.banner_inside_content}>
          <Container className={class_name.banner_txt_search_content}>
            <Container
              className="container-sm"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography className={class_name.welcome_txt}>
                {"Best Boating Experience in Saudi Arabia"}
              </Typography>
              <div className={class_name.search}>
                <CustomTextField
                  className={class_name.text_fileds}
                  variant="standard"
                  margin="normal"
                  onChange={(e) => handleSearch(e.target.value)}
                  value={searchTxt}
                  fullWidth
                  name="email"
                  placeholder="Search for a city"
                  onClick={() => {
                    navigate("/searchBoat",{state:searchTxt});
                  }}
                  // value={searchText}
                  // onChange={}

                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      margin: "0",
                      padding: "0",
                      width: "100%",
                    },
                    endAdornment: (
                      <>
                        <IconButton>
                          <Search
                            onClick={() => {
                              navigate("/searchBoat", { state: searchTxt });
                            }}
                            style={{}}
                            className={class_name.search_img_font}
                          />
                        </IconButton>
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    className: class_name.text_field_inputs,
                    style: {},
                  }}
                />
              </div>
            </Container>
          </Container>
        </div>
      </div>

      {/* ================================================== boat card ================================================== */}
      <div className={class_name.boat_card_AND_Circle}>
        <div
          // fluid
          className={class_name.boat_list_container}
        >
          <div className={class_name.boat_Offers_title}>
            <Typography className={class_name.boat_Offers_txt}>
              Discover Some Boat Offers
            </Typography>
            <Typography
              className={class_name.show_more_txt}
              onClick={() => {
                navigate("/searchBoat");
              }}
            >
              show more
            </Typography>
          </div>

          {/* 
            <div
              className={
                boatListData?.length <= 2
                  ? class_name.show_boar_cards_cahnge_align
                  : class_name.show_boar_cards
              }
              style={{
                justifyContent: width > 930 ? "space-between" : "space-evenly",
              }}
            >
              {isLoading ? (
                <>
                  <CardLoader />
                  <CardLoader />
                </>
              ) : (
                <>
                  {boatListData?.map((item, index) => {
                    if (boatListData?.length === 6) {
                      return showBoatCards(item, index);
                    } else if (
                      boatListData?.length >= 3 &&
                      boatListData?.length < 6
                    ) {
                      if (index < 3) {
                        return showBoatCards(item, index);
                      }
                    } else {
                      return showBoatCards(item, index);
                    }
                  })}
                </>
              )}
            </div>
          */}
          <div className={class_name.show_boat_cards_container}>
            <div
              className={class_name.boat_offers}
              style={{
                justifyContent:
                  width > 767
                    ? width > 930
                      ? "space-between"
                      : "space-evenly"
                    : null,
              }}
              ref={containerRef}
            >
              {isLoading ? (
                <div className={class_name.show_loader_card}>
                  <CardLoader />
                  <CardLoader />
                </div>
              ) : (
                <>
                  {boatListData?.length ? (
                    <>
                      {boatListData?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={class_name.boat_card_spaces}
                            onClick={() => {
                              navigate(`/boatData/${item?.dataValues?.boat_uuid}`);
                              dispatch(search_boat_id(item?.dataValues?.boat_id));
                            }}
                            ref={cardRef}
                          >
                            <BoatDetailCard
                              // profile_image={`http://localhost:3000/${item?.front_image}`}
                              boatName={item?.dataValues?.boat_name}
                              marine_city={item?.dataValues?.marine_city}
                              starRating={item?.averageRating}
                              priceCurrency={item?.dataValues?.price_currency}
                              pricePerHour={item?.dataValues?.price_per_hour}
                              boatMaxCapacity={item?.dataValues?.boat_max_capacity}
                              profile_image={`${
                                item?.dataValues?.front_image
                              }`} //${API.baseUrls[API.currentEnv]}
                              //
                              cardRef={cardRef}
                              containerRef={containerRef}
                              boatListData={boatListData}
                              setEmptyItems={setEmptyItems}
                            />
                          </div>
                        );
                      })}
                      {width > 767 ? (
                        <>
                          {[...Array(emptyItems)].map((_, index) => {
                            return (
                              <div
                                key={index}
                                // className={class_name.showEmptyCard}
                                style={
                                  width <= 790
                                    ? {
                                        width: width / 2.4,
                                        // width: 200,
                                        // padding: "0",
                                        // backgroundColor: "lightgray",
                                      }
                                    : width <= 1024
                                    ? { width: 280 }
                                    : width <= 1380
                                    ? {
                                        width: 300,
                                        // backgroundColor: "lightcoral",
                                      }
                                    : {
                                        width: 350,
                                        // backgroundColor: "#026b93",
                                      }
                                }
                              ></div>
                            );
                          })}
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <div className={class_name.noDataContent}>
                        <img
                          alt="no result"
                          src={IMAGES.NO_RESULT}
                          className={class_name.noResultImg}
                        />
                        <Typography className={class_name.sryTxt}>
                          Sorry, no results found.
                        </Typography>

                        <Typography className={class_name.noResultTxt}>
                          It looks like we couldn't find any boats that match
                          your search Please try again.
                        </Typography>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {/* <Divider
          variant="middle"
          style={{
            height: "2px", // Set the desired height
            backgroundColor: "#424651",
            margin: "16px 0", // Adjust margin as needed
          }}
        /> */}
        {/* ================================================== circle design ================================================== */}

        <Container
          // fluid
          className={class_name.circle_design_container}
          // style={{ backgroundColor: "#fff9e3" }}
        >
          <div className={class_name.seperate_container_box1} style={{}}>
            <div style={{}} className={class_name.circle_box}>
              <div className={class_name.bigCircle}>
                <Typography className={class_name.bigCircleInsideTxt}>
                  Over 900 boat trips done through us
                </Typography>
              </div>
            </div>
            <div className={class_name.reviews_info_box} style={{}}>
              <Typography className={class_name.reviews_info_title}>
                Real reviews from happy customers!
              </Typography>
              <Typography className={class_name.marketing_label}>
                Join Our Trusted Community. Our satisfied customers have
                embarked on more than 900 incredible boat trips with us.
                Experience the joy of smooth sailing, breathtaking views, and
                unforgettable moments on the water. Book your dream boat trip
                today and become a part of our growing community of happy
                adventurers.
              </Typography>
            </div>
          </div>

          <div style={{}} className={class_name.seperate_container_box2}>
            <div style={{}} className={class_name.circle_content_box}>
              <div style={{}} className={class_name.circle_row}>
                <Col className="d-flex justify-content-end">
                  <img
                    alt="ellipse"
                    src={IMAGES?.weekendCircle_1}
                    className={class_name.picture_style}
                  />
                </Col>
                <Col className="d-flex justify-content-end">
                  <img
                    alt="ellipse"
                    src={IMAGES?.weekendCircle_2}
                    className={class_name.picture_style}
                  />
                </Col>
                <Col className="d-flex justify-content-end">
                  <img
                    alt="ellipse"
                    src={IMAGES?.weekendCircle_3}
                    className={class_name.picture_style}
                  />
                </Col>
              </div>
              <div className={class_name.middle_circle_row}>
                <Col xs={4} className="p-0">
                  <img
                    alt="ellipse"
                    src={IMAGES?.weekendCircle_4}
                    className={class_name.picture_style}
                  />
                </Col>
                <Col xs={4} className="p-0">
                  <img
                    alt="ellipse"
                    src={IMAGES?.weekendCircle_5}
                    className={class_name.picture_style}
                  />
                </Col>
                <Col xs={4} className="p-0">
                  <img
                    alt="ellipse"
                    src={IMAGES?.weekendCircle_6}
                    className={class_name.picture_style}
                  />
                </Col>
              </div>
              <div style={{}} className={class_name.circle_row}>
                <Col className="d-flex justify-content-end">
                  <img
                    alt="ellipse"
                    src={IMAGES?.weekendCircle_7}
                    className={class_name.picture_style}
                  />
                </Col>
                <Col className="d-flex justify-content-end">
                  <img
                    alt="ellipse"
                    src={IMAGES?.weekendCircle_8}
                    className={class_name.picture_style}
                  />
                </Col>
                <Col className="d-flex justify-content-end">
                  <img
                    alt="ellipse"
                    src={IMAGES?.subaDiving}
                    className={class_name.picture_style}
                  />
                </Col>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="footer-style-hide">
        <Footer />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  rental_page: {
    backgroundColor: "#f6f6f6",
    height: "100%",
    flexGrow: 1,
  },

  show_rental_header_outSide_banner: {
    display: "none",
  },

  show_loader_card: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
  },

  rental_banner: {
    width: "100%",
    height: "auto",
    // minHeight: "300px",
    position: "sticky",
    backgroundImage: `url(${Boat_Experience})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    /* Flex properties to center content */
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  banner_txt_search_content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // width: "50%",
    // backgroundColor: "green",
  },

  welcome_txt: {
    // fontFamily: "Poppins",
    fontSize: "clamp(16px, 3.5vw, 48px)",
    fontWeight: "bolder",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    width: "100%",
    height: "auto",
    color: "rgba(66, 70, 81, 1)",
    // backgroundColor: "red",
    // color: "black",
  },
  search: {
    padding: "52px 0px 0px 0px",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
    alignItems: "center",
    width: "100%",
    // backgroundColor: "rebeccapurple",
  },

  text_fileds: {
    margin: "0px",
    borderRadius: "5px",
    // width: "45%",
    backgroundColor: "white",
    border: "none",
    padding: "0px 60px 0px 0px",
    paddingRight: ({ min, max, unit }) =>
      `clamp(${min}${unit}, calc(${min}${unit} + (${max} - ${min}) * ((100vw - 300px) / (1600 - 300))), ${max}${unit})`,
    // backgroundColor: "red",
  },

  search_img_font: {
    fontSize: "clamp(26px, 4vw, 42px)",
  },

  text_field_inputs: {
    fontSize: "clamp(12px, 3vw, 24px)",
    // fontFamily: "Poppins",
    color: "#424651",
    // margin: "30px 0px 30px 60px",
    padding: ({ min, max, unit }) =>
      `clamp(${min}${unit}, calc(${min}${unit} + (${max} - ${min}) * ((100vw - 300px) / (1600 - 300))), ${max}${unit})`,
    border: "none",
    borderRadius: "5px",
    height: "25px",
    // backgroundColor: "red",
  },
  show_rental_header_inside_banner: {
    width: "100%",
    display: "flex",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  banner_inside_content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    // position: "static",
    // backgroundColor: "red",
  },

  boat_card_AND_Circle: {
    backgroundColor: "#f6f6f6",
    padding: ({ min, max, unit }) =>
      `clamp(${min}${unit}, calc(${min}${unit} + (${max} - ${min}) * ((100vw - 300px) / (1600 - 300))), ${max}${unit})`,
  },

  boat_offers: {
    display: "flex",
    flexDirection: "row",

    width: "100%",
    height: "100%",
    flexWrap: "wrap",
  },

  boat_list_container: {
    //
    padding: "1% 0%",
    // backgroundColor: "blue",
    [theme.breakpoints.down(767)]: {
      padding: "1% 0%",
      // backgroundColor: "gold",
    },
    [theme.breakpoints.up("md")]: {
      padding: "1% 4%",
      // backgroundColor: "gold",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "1% 10%",
      // backgroundColor: "green",
    },
  },

  boat_Offers_title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: "0px 10px",
    // backgroundColor: "lightyellow",
  },
  boat_Offers_txt: {
    // fonFamily: "Poppins",

    fontSize: "clamp(16px, 4vw, 38px)",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.74,
    letterSpacing: "normal",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.87)",

    [theme.breakpoints.down("300")]: {
      fontSize: "clamp(10px, 4vw, 30px)",
    },
  },

  show_more_txt: {
    fontSize: "clamp(12px, 3vw, 24px)",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.74,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.6)",
    cursor: "pointer",
    "&:hover": {
      // textDecoration: "underline",
      color: "rgba(66, 70, 81, 0.9)",
    },
  },

  show_boat_cards_container: {
    width: "100%",
    overflowX: "auto",
    padding: "0px",
    // backgroundColor: "pink",
    margin: "0px",
  },

  show_boar_cards: {
    display: "flex",
    width: "100%",
    // alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    margin: "0px",
    padding: "0px",
  },

  show_boar_cards_cahnge_align: {
    display: "flex",
    width: "100%",
    // alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    margin: "0px",
    padding: "0px",
    // backgroundColor: "red",
  },

  boat_card_spaces: {
    // backgroundColor: "red",
    // padding: "1%",
    margin: "27.5px 0",

    [theme.breakpoints.down("sm")]: {
      margin: "10.5px 0",
    },
    [theme.breakpoints.up("md")]: {
      margin: "20.5px 0",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "27.5px 0",
    },
  },

  boat_card_space_change_space: {
    margin: "27.5px",
    [theme.breakpoints.up("sm")]: {
      margin: "10.5px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "20.5px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "27.5px",
    },
  },

  circle_design_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    padding: "150px 0px",
    height: "100%",
    // backgroundColor: "pink",
    // margin: "10% 0",
  },
  seperate_container_box1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "70%",
    // margin: "1%",
    height: "100%",
    // backgroundColor: "black",
    // backgroundColor: "pink",
  },

  circle_box: {
    width: "100%",
    height: "50%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    justifyContent: "flex-end",
    alignItems: "center",
    // backgroundColor: "lightgreen",
  },

  bigCircle: {
    display: "flex",
    width: "fit-content",

    objectFit: "contain",
    backgroundImage: `linear-gradient(
      132deg,
      rgba(57, 115, 165, 0.1) 29%,
      rgba(57, 115, 165, 0.2) 53%,
      rgba(57, 115, 165, 0.3) 66%,
      rgba(57, 115, 165, 0.4) 77%
    )`,
    borderRadius: "50%",
    justifyContent: "center",
    padding: "30% 15%",
  },

  bigCircleInsideTxt: {
    // fontFamily: "Poppins",
    fontSize: "clamp(14px, 2.5vw, 38px)", //  600px,750px,1000px Adjust the range as needed
    fontWeight: "bold",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.87)",
    width: "80%",
    // backgroundColor: "red",
    [theme.breakpoints.down("400")]: {
      fontSize: "clamp(10px, 1vw, 28px)",
    },
    [theme.breakpoints.down("300")]: {
      fontSize: "clamp(8px, 1vw, 10px)",
    },
  },

  reviews_info_box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "50%",
    marginTop: "20%",
  },

  reviews_info_title: {
    // fontFamily: "Poppins",
    fontSize: "clamp(12px, 3vw, 28px)", // Adjust the range as needed
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.53,
    letterSpacing: "normal",
    textAlign: "center",
    color: "var(--charcoal-grey-87)",
    width: "100%",
    // backgroundColor: "red",
  },

  marketing_label: {
    // fontFamily: "Poppins",
    fontSize: "clamp(10px, 1.2vw, 20px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.6)",
  },

  seperate_container_box2: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    justifyContent: "center",
    width: "100%",
    // backgroundColor: "lightsalmon",
    padding: "10px",
  },
  circle_content_box: {
    // backgroundColor: "gainsboro",
    display: "flex",
    flexDirection: "column",
  },
  circle_row: {
    // backgroundColor: "red",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    // borderRadius: "30%",
    padding: "28px 0px",
  },
  middle_circle_row: {
    display: "flex",
    flexDirection: "row",
    // backgroundImage: "green",
  },
  picture_style: {
    width: "clamp(80px, 13vw, 180px)", // Adjust the range as needed
    height: "clamp(80px, 13vw, 180px)", // Adjust the range as needed
    border: "solid 0.5px rgba(112, 112, 112, 0.3)",
    borderRadius: "150px",
  },

  noDataContent: {
    display: "flex",
    flexDirection: "column",
    marginTop: "100px",
    alignItems: "center",
  },
  noResultImg: {
    width: "clamp(80px, 35vw, 139px)", // Adjust the range as needed
    height: "clamp(80px, 35vh, 139px)", // Adjust the range as needed
  },
  sryTxt: {
    // marginTop: "50px",
    fontSize: "clamp(14px, 3vw, 48px)",

    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.87)",
  },
  noResultTxt: {
    marginTop: "24px",

    fontSize: "clamp(12px, 3vw, 36px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.45)",
    width: "70%",
    textAlign: "center",
    marginBottom: "100px",
  },

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    boat_offers: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "100%",
      // display: "flex",
      // alignItems: "center",
      // justifyContent: "flex-start",
      flexWrap: "nowrap",
      // margin: "0px",
      // padding: "0px",
      // backgroundColor: "pink",
    },

    rental_page: {
      // backgroundColor: "#f6f6f6",
      // backgroundColor: "red",
      height: "100%",
      // flexGrow: 1,
    },
    show_rental_header_outSide_banner: {
      display: "flex",
    },
    rental_banner: {
      width: "100%",
      height: "100%",
      // minHeight: "300px",
      position: "sticky",
      backgroundImage: `url(${require("../../../assets/Images/Boating_Experience.png")})`,
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      /* Flex properties to center content */
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      // justifyContent: "space-between",
      alignItems: "center",
      // backgroundColor: "red",
    },

    show_rental_header_inside_banner: {
      width: "100%",
      display: "none",
    },

    banner_inside_content: {
      // display: "flex",
      // justifyContent: "center",
      // flexDirection: "column",
      // position: "static",
      // width: "100%",
      // height: "100%",
      // maxHeight: "10%",
      // padding: "10% 0",
      // backgroundColor: "red",
      // maxHeight: "10%",
      // padding: "10% 0",
    },

    show_boar_cards: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "nowrap",
      margin: "0px",
      padding: "0px",
    },

    boat_card_spaces: {
      margin: "0 1.5% 3%",
    },

    boat_card_space_change_space: {
      margin: "10px",
      [theme.breakpoints.up("sm")]: {
        margin: "20px",
      },
      [theme.breakpoints.up("md")]: {
        margin: "17.5px",
      },
      [theme.breakpoints.up("lg")]: {
        margin: "17.5px",
      },
    },

    circle_design_container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "0px",
      height: "auto",
      // backgroundColor: "lightblue",
    },
    seperate_container_box1: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      width: "100%",
      margin: "0px",
      // height: "100%",
      height: "auto",
      // height: "fit-content",
      // backgroundColor: "lightblue",
      // margin: "1%",
    },

    seperate_container_box2: {
      display: "flex",
      flexDirection: "column",
      // alignItems: "center",
      justifyContent: "center",
      width: "100%",
      // backgroundColor: "lightsalmon",
      padding: "10px",
    },

    marketing_label: {
      display: "none",
    },
    circle_content_box: {
      // backgroundColor: "gainsboro",
      display: "flex",
      flexDirection: "column",
    },
    circle_row: {
      // backgroundColor: "red",
      display: "flex",
      flexDirection: "row",
      padding: "5%",
    },
    picture_style: {
      width: "clamp(80px, 20vw, 140px)", // Adjust the range as needed
      height: "clamp(80px, 20vw, 140px)", // Adjust the range as needed
      border: "solid 0.5px rgba(112, 112, 112, 0.3)",
      borderRadius: "150px",
      // backgroundColor: "red",

      [theme.breakpoints.down("320")]: {
        width: "clamp(50px, 20vw, 100px)", // Adjust the range as needed
        height: "clamp(50px, 20vw, 100px)", // Adjust the range as needed
      },
    },

    bigCircle: {
      display: "flex",
      width: "fit-content",
      objectFit: "contain",
      backgroundImage: `linear-gradient(
        132deg,
        rgba(57, 115, 165, 0.1) 29%,
        rgba(57, 115, 165, 0.2) 53%,
        rgba(57, 115, 165, 0.3) 66%,
        rgba(57, 115, 165, 0.4) 77%
      )`,
      borderRadius: "50%",
      justifyContent: "center",
      padding: "30% 20%",

      [theme.breakpoints.down("sm")]: {
        padding: "30% 20%",
      },
      [theme.breakpoints.up("md")]: {
        padding: "30% 15%",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "30% 15%",
      },
    },

    reviews_info_box: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "50%",
      marginTop: "0%",
    },
  },
}));
