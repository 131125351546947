import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Typography } from "@mui/material";
import { HeaderContent } from "../Common/map/HeaderContent";
import Footer from "../../Component/Footer/Footer";
import IMAGES from "../Images";
import { Cancel, TramRounded } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PageHeader } from "../Dash/page-header/PageHeader";
import { makeStyles } from "@material-ui/core/styles";
// import "react-swipe-to-delete-component/dist/swipe-to-delete.css";
// import SwipeToDelete from "react-swipe-to-delete-component";
import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  delete_all_notifications,
  delete_notifications,
  my_notifications,
  notification_mark_as_read,
  notifications_count,
  single_boat_data_API,
} from "../../Service/api";
import moment from "moment";
import Loader from "../Loader";
import socket from "../../Service/SocketIo";
import { API } from "../../Service/constants";
import { AuthToken, boat_register_step1, boat_register_step2, boat_register_step3, single_boat_details_store } from "../../redux/slices";

export const Notification = () => {
  const class_name = useStyles({ min: 10, max: 60, unit: "px" });
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const theme = useTheme();
  const dispatch = useDispatch()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("481"));
  const [notifiList, setNotifyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [notifyCount, setNotifyCount] = useState(0);
  const [readArr, setReadArr] = useState([]);

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  useEffect(() => {
    setIsLoading(true);
    setNotifyList([]);
    my_notifications(auth?.AuthToken)
      .then((res) => {
        console.log(res?.data, "notify");
        if (res?.data?.success) {
          setNotifyList(res?.data?.notifications);
        } else {
          console.log("something went wrong");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("notification err", err);
        toast.error(err?.response?.data?.message??"something went wrong", {
          position: toast?.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setIsLoading(false);
      });
    setUserId(notifiList[0]?.userId);
  }, []);

  // socket
  useEffect(() => {
    // socket.client.on("connect", () => {
    //   console.log("connected");
    // });
    socket.connect(auth?.AuthToken);

    return () => {
      // socket.disconnect();
    };
    //   socket.client.off("disconnect", () => {
    //     console.log("disconnected");
    //   });
    // }
  }, [window.location.search]);

  //socket.on
  // useEffect(()=>{
  //   socket.on('notificationDetails',(notifications)=>{
  //     setNotifyList(notifications)
  //   })
  // },[])

  const handle_navigation = (name) => {
    if (name === "chat") {
      navigate("/allMessage");
    } else if(name === 'My Listings'){
      navigate('/myListings')
    } else {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleDelete = (id) => {
    delete_notifications(auth?.AuthToken, id)
      .then((res) => {
        console.log(res?.data, "delete");
        let updateNotify = notifiList.filter((item) => item.id !== id);
        setNotifyList(updateNotify);
      })
      .catch((err) => {
        console.error(err, "delete error");
      });
  };

  const handleDeleteAll = () => {
    setNotifyList([]);
    delete_all_notifications(auth?.AuthToken, userId)
      .then((res) => {
        if (res?.data?.message) {
          console.log(res?.data, "delete");
          socket.client.emit(
            "getUnreadCountNotification",
            auth?.userId,
            (err) => {
              console.log("getunread count notification err", err);
            }
          );
        }
      })
      .catch((err) => {
        console.log(err, "delete error");
      });
  };
  // console.log(auth?.AuthToken)

  const handleMarkAsRead = (id, text, boat_id, boat_uuid, user_id, reviewed,booking_id) => {
    setIsLoading(true)
    let payload = {
      id: id,
    };
    notification_mark_as_read(auth?.AuthToken, payload)
      .then((res) => {
        console.log(res?.data, "res notify");
        if (
          res?.data?.message === "Notification marked as read"
        ) {
          setIsLoading(false)
          console.log(text,'notification text')

          if(text?.includes('your boat booked')){
            navigate('/allMessage')
          }

          if(text?.includes('Trip Cancelled')){
            navigate('/bookedReqDetails',{state:{boat_booking_id:booking_id}})
          }

          if(text.includes('end') && !reviewed){
            console.log('review page navigate')
            navigate('/reviewPage',{state:{boat_id:boat_id}})
          }

          if(text.includes('verified by admin') || text.includes('Unverified Documents')){
            console.log('update boat doc page navigate')
            var payload = {
              boat_uuid: boat_uuid,
              user_id: user_id,
            };
            single_boat_data_API(auth?.AuthToken, payload)
            .then((res) => {
              console.log("res?.data", res?.data);
              if (res?.data?.success) {
                console.log('single boat detail updated')
                dispatch(single_boat_details_store(res?.data?.parameters));
                dispatch(boat_register_step1(null));
                dispatch(boat_register_step2(null));
                dispatch(boat_register_step3(null));
                navigate('/updateBoatDoc')
              } else {
                toast.error(res?.data?.error ?? "error", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
              }
            })
            .catch((err) => {
              console.error("single boat data -=-=-=-=-=->>>>>  Error : ", err);
              toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            });
          }
          console.log("successfully changed");
          let markAsReadArr = notifiList?.map((notify) => {
            if (notify?.id === id) {
              return { ...notify, read: true };
            }
            return notify;
          });
          setNotifyList(markAsReadArr);
          socket.client.emit(
            "getUnreadCountNotification",
            auth?.userId,
            (err) => {
              console.log("getunread count notification err", err);
            }
          );
        }
      })
      .catch((err) => {
        console.log("notification markasread", err);
        setIsLoading(false)
      });
  };

  return (
    <>
      {isLoading ? <Loader loading={isLoading} /> : null}
      <PageHeader
        showLoginSignUp={auth?.AuthToken ? false : true}
        handle_navigation={handle_navigation}
        // presentPage={"Home"}
        link1={"Boat Offers"}
        link2={"Scuba Courses/Programs"}
        link3={"Scuba Diving Trips"}
        link4={auth?.tokenDecodeData?.user_type === "BOAT_OWNER"? "My Listings":null}
        notificationCount={notifyCount}
      />
      <div style={{ height: "100%" }}>
        <div
          className={class_name.container}
          style={{
            minHeight: "100%",
            // maxHeight: 'auto',
            // height: '100%'
          }}
        >
          {notifiList.length ? (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <Typography className={class_name.titleTxt}>
                  Notifications
                </Typography>
                <Typography
                  className={class_name.clearAll}
                  onClick={() => {
                    handleDeleteAll();
                    // handleNotificationCount();
                  }}
                >
                  Clear All
                </Typography>
              </div>
              <div style={{ marginTop: "5%" }}>
                <div style={{ marginBottom: "16px" }}>
                  {notifiList?.find(
                    (item) =>
                      moment(item?.createdAt).format("DD.MM.YYYY") ===
                      moment().format("DD.MM.YYYY")
                  ) ? (
                    <>
                      <Typography className={class_name.dayTxt}>
                        Today
                      </Typography>
                      {
                        // isSmallScreen
                        //   ? notifiList?.map((msgItem, msgIndex) => {
                        //       // setUserId(msgItem.user_id);
                        //       if (
                        //         moment(msgItem?.createdAt).format(
                        //           "DD.MM.YYYY"
                        //         ) === moment().format("DD.MM.YYYY")
                        //       ) {
                        //         return (
                        //           <>
                        //             {/* <SwipeToDelete
                        //               key={msgIndex}
                        //               className=""
                        //               background={false}
                        //               onDelete={() => {
                        //                 handleDelete(msgItem?.id);
                        //                 handleNotificationCount();
                        //               }}
                        //             > */}
                        //               <div
                        //                 className={class_name.msg_index}
                        //                 style={{
                        //                   backgroundColor: 'white',
                        //                     // msgItem?.read
                        //                     //   ? "rgba(255,255,255)"
                        //                     //   : "rgba(255,255,255,0.2)",
                        //                   color:
                        //                     msgItem?.read
                        //                       ? "black"
                        //                       : "rgba(0,0,0,0.2)",
                        //                 }}
                        //                 key={msgIndex}
                        //               >
                        //                 <div
                        //                   style={{
                        //                     display: "flex",
                        //                     flexDirection: "row",
                        //                     alignItems: "center",
                        //                     // alignContent: "center",
                        //                     // alignSelf: "center",
                        //                   }}
                        //                 >
                        //                   <img
                        //                     alt="img"
                        //                     src={IMAGES.CAMERA}
                        //                     className={class_name.imgStyle}
                        //                   />

                        //                   <div
                        //                     className={class_name.txt_content}
                        //                     onClick={() =>
                        //                       handleMarkAsRead(msgItem?.id)
                        //                     }
                        //                     // style={{
                        //                     //   display: "flex",
                        //                     //   flexDirection: "column",
                        //                     //   paddingLeft: "43px",
                        //                     // }}
                        //                   >
                        //                     <Typography
                        //                       className={class_name.msgTxt}
                        //                     >
                        //                       {msgItem?.description}
                        //                     </Typography>
                        //                     <Typography
                        //                       className={class_name.dateTxt}
                        //                     >
                        //                       {moment(msgItem?.createdAt).format(
                        //                         "MMMM DD"
                        //                       )}
                        //                     </Typography>
                        //                   </div>
                        //                 </div>
                        //                 <div className={class_name.cancel_icon}>
                        //                   <IconButton
                        //                     style={{ padding: "0", margin: "0" }}
                        //                   >
                        //                     <Cancel />
                        //                   </IconButton>
                        //                 </div>
                        //               </div>
                        //             {/* </SwipeToDelete> */}
                        //           </>
                        //         );
                        //       }
                        //     })
                        //   :
                        notifiList?.map((msgItem, msgIndex) => {
                          if (
                            moment(msgItem?.createdAt).format("DD.MM.YYYY") ===
                            moment().format("DD.MM.YYYY")
                          ) {
                            return (
                              <>
                                <div
                                  className={class_name.msg_index}
                                  key={msgIndex}
                                  style={{
                                    backgroundColor: "white",
                                    // readArr.includes(msgItem?.id)
                                    //   ? "rgba(255,255,255)"
                                    //   : "rgba(255,255,255,0.2)",
                                    color:
                                      msgItem?.read
                                        ? "black"
                                        : "rgba(0,0,0,0.2)",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      // alignContent: "center",
                                      // alignSelf: "center",
                                    }}
                                  >
                                    <img
                                      alt="img"
                                      src={IMAGES.CAMERA}
                                      className={class_name.imgStyle}
                                    />

                                    <div
                                      className={class_name.txt_content}
                                      onClick={() =>
                                        handleMarkAsRead(
                                          msgItem?.id,
                                          msgItem?.description,
                                          msgItem?.boatId,
                                          msgItem?.boat_uuid,
                                          msgItem?.userId,
                                          msgItem?.reviewed,
                                          msgItem?.bookingId
                                        )
                                      }
                                      // style={{
                                      //   display: "flex",
                                      //   flexDirection: "column",
                                      //   paddingLeft: "43px",
                                      // }}
                                    >
                                      <Typography className={class_name.msgTxt}>
                                        {msgItem?.description}
                                      </Typography>
                                      <Typography
                                        className={class_name.dateTxt}
                                      >
                                        {moment(msgItem?.createdAt).format(
                                          "MMMM DD h:mm A"
                                        )}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div className={class_name.cancel_icon}>
                                    <IconButton
                                      style={{ padding: "0", margin: "0" }}
                                      onClick={() => {
                                        handleDelete(msgItem?.id);
                                        // handleNotificationCount();
                                      }}
                                    >
                                      <Cancel />
                                    </IconButton>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })
                      }
                    </>
                  ) : null}
                </div>
                <div style={{ marginBottom: "16px" }}>
                  {notifiList?.find(
                    (item) =>
                      moment(item?.createdAt).format("DD.MM.YYYY") !==
                      moment().format("DD.MM.YYYY")
                  ) ? (
                    <>
                      <Typography className={class_name.dayTxt}>
                        Earlier
                      </Typography>
                      {
                        // isSmallScreen
                        //   ? notifiList?.map((msgItem, msgIndex) => {
                        //       if (
                        //         moment(msgItem?.createdAt).format(
                        //           "DD.MM.YYYY"
                        //         ) !== moment().format("DD.MM.YYYY")
                        //       ) {
                        //         return (
                        //           <>
                        //             {/* <SwipeToDelete
                        //               key={msgIndex}
                        //               className=""
                        //               background={false}
                        //               onDelete={() => {
                        //                 handleDelete(msgItem?.id);
                        //                 handleNotificationCount();
                        //               }}
                        //             > */}
                        //               <div
                        //                 className={class_name.msg_index}
                        //                 key={msgIndex}
                        //                 style={{
                        //                   backgroundColor: 'white',
                        //                     // msgItem?.read
                        //                     //   ? "rgba(255,255,255)"
                        //                     //   : "rgba(255,255,255,0.2)",
                        //                   color:
                        //                     msgItem?.read
                        //                       ? "black"
                        //                       : "rgba(0,0,0,0.2)",
                        //                 }}
                        //               >
                        //                 <div
                        //                   style={{
                        //                     display: "flex",
                        //                     flexDirection: "row",
                        //                     alignItems: "center",
                        //                     // alignContent: "center",
                        //                     // alignSelf: "center",
                        //                   }}
                        //                 >
                        //                   <img
                        //                     alt="img"
                        //                     src={IMAGES.CAMERA}
                        //                     className={class_name.imgStyle}
                        //                   />

                        //                   <div
                        //                     className={class_name.txt_content}
                        //                     onClick={() =>
                        //                       handleMarkAsRead(msgItem?.id)
                        //                     }
                        //                     // style={{
                        //                     //   display: "flex",
                        //                     //   flexDirection: "column",
                        //                     //   paddingLeft: "43px",
                        //                     // }}
                        //                   >
                        //                     <Typography
                        //                       className={class_name.msgTxt}
                        //                     >
                        //                       {msgItem?.description}
                        //                     </Typography>
                        //                     <Typography
                        //                       className={class_name.dateTxt}
                        //                     >
                        //                       {moment(msgItem?.createdAt).format(
                        //                         "MMMM DD"
                        //                       )}
                        //                     </Typography>
                        //                   </div>
                        //                 </div>
                        //                 <div className={class_name.cancel_icon}>
                        //                   <IconButton
                        //                     style={{ padding: "0", margin: "0" }}
                        //                   >
                        //                     <Cancel />
                        //                   </IconButton>
                        //                 </div>
                        //               </div>
                        //             {/* </SwipeToDelete> */}
                        //           </>
                        //         );
                        //       }
                        //     })
                        //   :
                        notifiList?.map((msgItem, msgIndex) => {
                          if (
                            moment(msgItem?.createdAt).format("DD.MM.YYYY") !==
                            moment().format("DD.MM.YYYY")
                          ) {
                            return (
                              <>
                                {/* <SwipeToDelete key={msgIndex} className=""> */}
                                <div
                                  className={class_name.msg_index}
                                  key={msgIndex}
                                  style={{
                                    backgroundColor: "white",
                                    // msgItem?.read
                                    //   ? "rgba(255,255,255)"
                                    //   : "rgba(255,255,255,0.2)",
                                    color:
                                      msgItem?.read
                                        ? "black"
                                        : "rgba(0,0,0,0.2)",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      // alignContent: "center",
                                      // alignSelf: "center",
                                    }}
                                  >
                                    <img
                                      alt="img"
                                      src={IMAGES.CAMERA}
                                      className={class_name.imgStyle}
                                    />

                                    <div
                                      className={class_name.txt_content}
                                      onClick={() =>
                                        handleMarkAsRead(
                                          msgItem?.id,
                                          msgItem?.description,
                                          msgItem?.boatId,
                                          msgItem?.boat_uuid,
                                          msgItem?.userId,
                                          msgItem?.reviewed,
                                          msgItem?.bookingId
                                        )
                                      }
                                      // style={{
                                      //   display: "flex",
                                      //   flexDirection: "column",
                                      //   paddingLeft: "43px",
                                      // }}
                                    >
                                      <Typography className={class_name.msgTxt}>
                                        {msgItem?.description}
                                      </Typography>
                                      <Typography
                                        className={class_name.dateTxt}
                                      >
                                        {moment(msgItem?.createdAt).format(
                                          "MMMM DD h:mm A"
                                        )}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div className={class_name.cancel_icon}>
                                    <IconButton
                                      style={{ padding: "0", margin: "0" }}
                                      onClick={() => {
                                        handleDelete(msgItem?.id);
                                        // handleNotificationCount();
                                      }}
                                    >
                                      <Cancel />
                                    </IconButton>
                                  </div>
                                </div>
                                {/* </SwipeToDelete> */}
                              </>
                            );
                          }
                        })
                      }
                    </>
                  ) : null}
                </div>
              </div>
            </>
          ) : (
            <div
              className={class_name.noDataContent}
              style={{ display: isLoading ? "none" : "flex" }}
            >
              <img
                alt="no result"
                src={IMAGES.NO_RESULT}
                className={class_name.noResultImg}
              />
              <Typography className={class_name.sryTxt}>
                No Notifications Yet.
              </Typography>

              {/* <Typography className={class_name.noResultTxt}>
          It looks like we couldn't find any boats that match
          your search Please try again.
        </Typography> */}
            </div>
          )}
        </div>
        <div className="d-none d-md-block">
          <Footer />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    // minHeight: '100%',
    // maxHeight: 'auto',
    // height: '100%',
    // height: "auto",
    // overflowY: 'auto',
    // height: "100vh",
    backgroundColor: "#f6f6f6",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "18%",
    paddingRight: "calc(18% - 0vw)",
    // padding: "0 calc(50px-4%)",
    paddingBottom: "15%",
    // [theme.breakpoints.up('md')]:{
    //   paddingLeft: "30%",
    //   paddingRight: "30%",
    //   paddingBottom: "15%",
    // }
  },
  titleTxt: {
    marginTop: "100px",
    fontSize: "clamp(10px,8vw,40px)",
    // fontFamily: "Poppins",
    // fontSize: '1rem',
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    // textAlign: "left",
    color: "#424651",
  },
  clearAll: {
    fontSize: "clamp(13px,8vw,25px)",
    marginTop: "100px",
    textDecoration: "underline",
    color: "#026b93",
  },
  msg_index: {
    // backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    padding: "32px 60px",
    border: "solid 0.5px rgba(66, 70, 81, 0.3)",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dayTxt: {
    // fontFamily: "Poppins",
    fontSize: "30px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.53,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#f6f6f6",
    backgroundColor: "#3973a5",
    padding: "20px 60px",
  },
  imgStyle: {
    width: "32px",
    height: "30px",
  },
  txt_content: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "43px",
  },
  msgTxt: {
    // marginTop: "64px",
    // fontFamily: "Poppins",
    fontSize: "clamp(8px,8vw,18px)",
    paddingBottom: "5px",
    // fontWeight: "normal",
    // fontStretch: "normal",
    // fontStyle: "normal",
    lineHeight: 1.54,
    letterSpacing: "normal",
    textAlign: "left",
    // color: "#424651",
  },
  dateTxt: {
    // marginTop: "64px",
    // fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    // color: "#424651",
  },
  //
  //
  //
  //
  //
  //
  //

  textFieldStyles: {
    border: "solid 0.01px rgba(66, 70, 81, 0.36)",
    borderRadius: "10px",
    borderWidth: ".01px",
    height: "265px",
  },
  pictureTxt: {
    marginTop: "64px",
    // fontFamily: "Poppins",
    fontSize: "24px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.54,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
  },
  uploadTxt: {
    marginTop: "27px",
    // fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.15,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.6)",
  },
  btnStyle: {
    backgroundColor: "#3973a5",
    color: "white",
    marginTop: "72px",
    // fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.15,
    letterSpacing: "normal",
    textAlign: "center",
    borderRadius: "0px",
    padding: "19px 137.5px 18px 138.5px",
    width: "350px",
    height: "65px",
    alignSelf: "flex-end",
  },

  noDataContent: {
    // display: "flex",
    flexDirection: "column",
    marginTop: "100px",
    alignItems: "center",
  },
  noResultImg: {
    width: "clamp(80px, 35vw, 139px)", // Adjust the range as needed
    height: "clamp(80px, 35vh, 139px)", // Adjust the range as needed
  },
  sryTxt: {
    fontSize: "clamp(14px, 3vw, 48px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.87)",
  },
  noResultTxt: {
    marginTop: "24px",
    fontSize: "clamp(12px, 3vw, 36px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.45)",
    width: "70%",
    textAlign: "center",
    marginBottom: "100px",
  },

  // cancel_icon: {
  //   display: 'none',
  //   backgroundColor: 'red'
  // },

  "@media (max-width: 910px)": {
    footer: {
      display: "none",
    },
  },

  "@media (max-width: 1024px)": {
    container: {
      paddingLeft: "8%",
      paddingRight: "8%",
    },
    dayTxt: {
      padding: "15px 30px",
    },
    msg_index: {
      padding: "10px 30px",
    },
    titleTxt: {
      marginTop: "20px",
    },
    clearAll: {
      marginTop: "20px",
    },
  },

  "@media (max-width: 768px)": {
    container: {
      paddingLeft: "5%",
      paddingRight: "5%",
    },
    dayTxt: {
      padding: "10px 30px",
      fontSize: "25px",
    },
    msg_index: {
      padding: "8px 15px",
    },
    titleTxt: {
      marginTop: "20px",
      fontSize: "clamp(10px,8vw,30px)",
    },
    clearAll: {
      marginTop: "20px",
      fontSize: "clamp(10px,8vw,18px)",
    },
    msgTxt: {
      fontSize: "clamp(8px,10vw,17px)",
      // paddingBottom: '8px'
    },
    txt_content: {
      paddingLeft: "20px",
    },
    imgStyle: {
      width: "25px",
      height: "23px",
    },
  },
  "@media (max-width: 480px)": {
    // cancel_icon: {
    //   display: "none",
    // },
    container: {
      paddingLeft: "5%",
      paddingRight: "5%",
    },
    dayTxt: {
      padding: "8px 15px",
      fontSize: "20px",
    },
    msg_index: {
      padding: "8px 15px",
    },
    imgStyle: {
      width: "23px",
      height: "21px",
    },
    msgTxt: {
      fontSize: "clamp(8px,10vw,15px)",
      paddingBottom: "8px",
    },
    titleTxt: {
      fontSize: "clamp(10px,8vw,20px)",
    },
    clearAll: {
      fontSize: "clamp(10px,8vw,15px)",
    },
    dateTxt: {
      fontSize: "12px",
    },
  },
}));

// const notifiList = [
//   {
//     id: 1,
//     day: "Today",
//     message: [
//       {
//         msg: "We're sorry. Your submitted request, Majd Boat, has been rejected by the owner",
//         day: "22 September",
//       },
//       {
//         msg: "Your experience matters to us! Leave a review and tell us how we can make your next experience even better.",
//         day: "22 September",
//       },
//     ],
//   },
//   {
//     id: 2,
//     day: "Earlier",
//     message: [
//       {
//         msg: "You have successfully booked `Madinah Ship` on Saturday 21.10.2022",
//         day: "19 September",
//       },
//       {
//         msg: "Congratulations! Your diving trip, Experience the Thrill of Diving , has been successfully approved.",
//         day: "15 September",
//       },

//       {
//         msg: "Your boat offer, Night Lights, was not approved yet. Please review your form and submit again.",
//         day: "15 September",
//       },
//     ],
//   },
// ];
