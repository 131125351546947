import { Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../Loader";
import { useSelector } from "react-redux";
import { set_new__password } from "../../../../../Service/api";

const start_space_Validation = new RegExp(/^(?!\s).*/);

const styles = (theme) => ({
  root: {
    "& input::placeholder": {
      fontSize: "16px",
      color: "rgba(66, 70, 81, 0.4)",
      // fontFamily: "Poppins",
    },
    [theme.breakpoints.down("sm")]: {
      // Apply styles for small screens
      "& input::placeholder": {
        fontSize: "10px",
        color: "rgba(66, 70, 81, 0.4)", // Change the color for small screens
      },
    },
  },
});

const CustomTextField = withStyles(styles)(TextField);

const regexPatterns = [
  /[a-z]/, // At least 1 lowercase letter
  /[A-Z]/, // At least 1 uppercase letter
  /[0-9]/, // At least 1 number
  /[!@#$%^&*]/, // At least 1 special character
];

export const ChangePwdModal = ({ handleModalClose }) => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const auth = useSelector((state) => state?.auth);
  const [strengthIndicator, setStrengthIndicator] = useState(false);
  const [errorPwdNotSame, setErrorPwdNotSame] = useState(false);

  const handleContentClick = (event) => {
    // Prevent propagation of click events to the parent elements
    event.stopPropagation();
  };

  useEffect(() => {
    let conditionsMet = 0;
    regexPatterns.forEach((pattern) => {
      if (pattern.test(newPwd)) {
        conditionsMet++;
      }
    });
    // Determine the password strength indicator based on the number of conditions met
    if (newPwd.length >= 8 && conditionsMet === 4) {
      setStrengthIndicator("green"); // All conditions met
    } else if (newPwd.length >= 8 && conditionsMet >= 3) {
      setStrengthIndicator("yellow"); // At least 4 conditions met
    } else {
      setStrengthIndicator("red"); // Less than 3 conditions met
    }

    if (newPwd === confirmPwd) {
      setErrorPwdNotSame(false);
    } else {
      setErrorPwdNotSame(true);
    }
  }, [confirmPwd, newPwd]);

  const handleSubmit = (values) => {
    toast.dismiss();
    setErrorMsg("");
    if (newPwd !== "") {
      if (newPwd?.length >= 8) {
        if (confirmPwd === newPwd) {
          let payload = {
            email: auth?.userDetails?.email,
            password: newPwd,
          };
          // console.log("payload", payload);
          setIsLoader(true);
          set_new__password(payload)
            .then((res) => {
              // console.log("res", res);
              if (res?.data?.message === "Password updated successfully") {
                toast.success(res?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                handleModalClose("close change pwd");
                setIsLoader(false);
              } else {
                setErrorMsg(res?.data?.message);
                toast.error(res?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
                setIsLoader(false);
              }
            })
            .catch((err) => {
              console.log("err", err);
              setIsLoader(false);
            });
        } else {
          toast.error(
            "Please enter your confirm password, Password must be same",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
        }
      } else {
        toast.error("The minimum password length is 8 characters ", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } else {
      toast.error("Please enter your password", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <div
        className={class_name.modal_overlay}
        onClick={handleModalClose}
        style={{}}
      >
        <ToastContainer
          position="top-right"
          autoClose={2000} // Adjust as needed
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
        />
        <div
          className='modal-content'
          onClick={handleContentClick}
          style={{
            //   maxWidth: "60%",
            // padding: "5% 0",
            backgroundColor: "white",
          }}
        >
          {isLoader ? <Loader loading={isLoader} /> : null}
          <Typography className={class_name.pageTitle_txt}>
            Change Password
          </Typography>
          <div style={{}} className={class_name.content_box}>
            <CustomTextField
              label={"New Password"}
              className="text-fileds"
              variant="standard"
              margin="normal"
              fullWidth
              name="New Password"
              placeholder="New Password"
              value={newPwd}
              onChange={(event) => {
                const inputValue = event.target.value;
                if (start_space_Validation.test(inputValue)) {
                  setNewPwd(inputValue);
                }
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: "white",
                  borderRadius: "5px",
                },
              }}
              InputLabelProps={{
                shrink: true,
                className: "text-field-label",
              }}
              inputProps={{
                className: "text-field-inputs",
                onFocus: (event) => {
                  event.target.style.border = "solid 1px blue";
                },
                onBlur: (event) => {
                  event.target.style.border = "solid 1px rgba(66, 70, 81, 0.2)";
                },
              }}
            />
            <CustomTextField
              label={"Confirm New Password"}
              className="text-fileds"
              variant="standard"
              margin="normal"
              fullWidth
              name="Confirm New Password"
              placeholder="Confirm New Password"
              value={confirmPwd}
              onChange={(event) => {
                const inputValue = event.target.value;
                if (start_space_Validation.test(inputValue)) {
                  setConfirmPwd(inputValue);
                }
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: "white",
                  borderRadius: "5px",
                },
              }}
              InputLabelProps={{
                shrink: true,
                className: "text-field-label",
              }}
              inputProps={{
                className: "text-field-inputs",
                onFocus: (event) => {
                  event.target.style.border = "solid 1px blue";
                },
                onBlur: (event) => {
                  event.target.style.border = "solid 1px rgba(66, 70, 81, 0.2)";
                },
              }}
            />
            {errorPwdNotSame && confirmPwd !== "" ? (
              <Typography className={class_name.err_style_txt}>
                {"Password must be same"}
              </Typography>
            ) : null}
            {newPwd ? (
              <>
                {strengthIndicator !== "green" ? (
                  <div className={class_name.small_pwd_strength_div}>
                    <Typography className={class_name.small_pwd_strength_info}>
                      The minimum password length is 8 characters and must
                      contain at least 1 lowercase letter, 1 capital letter, 1
                      number and 1 special character.
                    </Typography>
                  </div>
                ) : (
                  <div className={class_name.small_pwd_strength_div}>
                    <Typography
                      className={class_name.small_pwd_strength_info}
                      style={{ color: "green" }}
                    >
                      Your password is strong and secure. Good job!
                    </Typography>
                  </div>
                )}
              </>
            ) : null}

            <div
              style={{
                display: "flex",
                alignSelf: "center",
                justifyContent: "flex-end",
                width: "100%",
                // backgroundColor: "lightseagreen",
                marginTop: "10%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Typography
                  onClick={() => handleModalClose("close change pwd")}
                  className={class_name.cancel_txt}
                >
                  Cancel
                </Typography>
                <Button
                  style={{
                    // width: "25%",
                    margin: "0%",
                    marginLeft: "10%",
                  }}
                  className={class_name.cancel_txt}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  modal_overlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(114, 117, 124,0.9)",
    zIndex: "9999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
  },
  change_pwd_modal: {
    width: '60%',/* Adjust the maximum width of the modal content */
    height: '80%', /* Adjust the maximum height of the modal content */
    overflow: 'hidden',/* Hide any content that exceeds the modal's dimensions */
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  
    padding: 20,
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
  },
  pageTitle_txt: {
    fontSize: "clamp(14px, 3vw, 36px)",
    fontWeight: "bolder",
    textAlign: "center",
    borderBottom:'solid 1px lightgray',
    padding: '4% 0px'
  },
  cancel_txt: {
    fontSize: "clamp(8px, 3vw, 18px)",
    fontWeight: "normal",
    textAlign: "center",
    // marginRight: "35px",
    cursor: "pointer",
  },

  small_pwd_strength_div: {
    display: "flex",
  },
  small_pwd_strength_info: {
    fontSize: "clamp(8px, 1.5vw, 14px)",
    fontWeight: "inherit",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.96,
    letterSpacing: "normal",
    textAlign: "start",
    color: "#DC143C",
  },
  err_style_txt: {
    fontSize: "clamp(8px, 1vw, 12px)",
    fontWeight: "bold",
    color: "#DC143C",
  },

  content_box: {
    display: "flex",
    alignSelf: "center",
    flexDirection: "column",
    padding: '5% 0%',
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "65%",
    },
    [theme.breakpoints.down("767")]: {
      width: "90%",
    },
  },
}));
