import React, { useEffect, useState } from 'react'
import Loader from '../../../Loader'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { PageHeader } from '../../page-header/PageHeader';
import { makeStyles } from '@material-ui/core';
import { my_listing, single_boat_data_API } from '../../../../Service/api';
import IMAGES from '../../../Images';
import { StarRating } from '../../../../UI kit/5Star/StarRating';
import moment from 'moment';
import { boat_register_step1, boat_register_step2, boat_register_step3, single_boat_details_store } from '../../../../redux/slices';
import Footer from '../../../../Component/Footer/Footer';

const MyListing = () => {

    const auth = useSelector((state) => state?.auth);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const class_name = useStyles({ min: 10, max: 60, unit: "px" });
    const [myList, setMyList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        my_listing(auth?.AuthToken)
          .then((res) => {
            console.log("my_listing res=>", res?.data);
            if (res?.data?.message === "success") {
              setMyList(res?.data?.boats);
            } else {
            }
            setIsLoading(false)
          })
          .catch((err) => {
            console.log(err?.response?.data?.message??"my_listing err", err);
            toast.error(err?.response?.data?.message ?? "Something went wrong. Please try again later", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setIsLoading(false)
          });
      }, [auth?.AuthToken]);

    const handle_navigation = (name) => {
        if (name === "chat") {
          navigate("/allMessage");
        } else if(name === 'My Listings'){
          navigate('/myListings')
        } else if(name === "Home"){
          navigate('/boatOwnerDashBoard')
        } else {
          toast.info("Under Development", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      };

      const handleEdit = (boat_id,user_id) =>{
        setIsLoading(true)
        // navigate('/boatOfferStep1',{state:{boat_id: item?.dataValues?.boat_id,user_id:item?.dataValues?.user_id}})
        var payload = {
          boat_uuid: boat_id,
          user_id: user_id,
        };
        single_boat_data_API(auth?.AuthToken, payload)
        .then((res) => {
          console.log("res?.data", res?.data);
          if (res?.data?.success) {
            console.log('single boat detail updated')
            dispatch(single_boat_details_store(res?.data?.parameters));
            dispatch(boat_register_step1(null));
            dispatch(boat_register_step2(null));
            dispatch(boat_register_step3(null));
            navigate('/boatOfferStep1')
          } else {
            toast.error(res?.data?.error ?? "error", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          setIsLoading(false)
        })
        .catch((err) => {
          console.error("single boat data -=-=-=-=-=->>>>>  Error : ", err);
          toast.error(err?.message??"Something went wrong. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setIsLoading(false)
        });
      }

  return (
    <div>
        <div>
            <Loader loading={isLoading} /> 
            <PageHeader
                showLoginSignUp={auth?.AuthToken ? false : true}
                handle_navigation={handle_navigation}
                presentPage={"My Listings"}
                link1={"Boat Offers"}
                link2={"Scuba Courses/Programs"}
                link3={"Scuba Diving Trips"}
                link4={"My Listings"}
            />
            <div style={{height: '100%'}}>
                <div className={class_name.myListingsContainer}>
                    <div className={class_name.body}>
                      {myList?.length?
                        <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "156px",
                        }}
                        >
                            {myList?.map((item,i) => (
                              <div className='d-flex flex-column'>
                                <div
                                className={class_name.listings}
                                style={{
                                  border: item?.dataValues?.OverAllBoatVerificationStatus === 'REJECTED'
                                  ?'solid 1px red':'none'
                                }}
                                key={i}//item.id
                                >
                                    <div className={class_name.img_div}>
                                      <img
                                          alt="boat image"
                                          src={`${item?.dataValues?.front_image}`} //${API.baseUrls[API.currentEnv]}
                                          className={class_name.boat_img}
                                      />
                                    </div>
                                    <div 
                                    className={class_name.listings_body} 
                                    style={{position: 'relative',top: 0,left: 0}}
                                    >
                                      <div
                                      className=""
                                      style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: -1,
                                        fontSize: '14px',
                                        padding: '3.3px 0.6px'
                                      }}
                                      >
                                        <span
                                        style={{
                                          backgroundColor: 
                                          item?.dataValues?.OverAllBoatVerificationStatus === 'VERIFIED'
                                          ?'darkgreen':'darkred',
                                          color: 'white',
                                          padding: '4px 7px',
                                          // borderRadius: '2px'
                                        }}
                                        >
                                          {
                                            
                                            item?.dataValues?.OverAllBoatVerificationStatus?.slice(0,1)+
                                            item?.dataValues?.OverAllBoatVerificationStatus?.slice(1)?.toLowerCase()
                                            // ? 'Verified':'Unverified'
                                          }
                                        </span>
                                      </div>

                                      {/* boat name star rating money */}
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span className={class_name.boat_name}>
                                          {item?.dataValues?.boat_name}
                                        </span>

                                        <StarRating rating={item?.dataValues?.averageRating} />

                                        <div className={class_name.price_div}>
                                          <img
                                          className={class_name.money_img}
                                            src={IMAGES.MONEY_CARD}
                                            alt="money"
                                          />
                                          <span className={class_name.money_text}>
                                            {item?.dataValues?.price_per_hour} {item?.dataValues?.price_currency}
                                            <span className={class_name.money_hour_text}>
                                              {"per hour"}
                                            </span>
                                          </span>
                                        </div>
                                      </div>

                                      {/* edit and view icons */}
                                      <div className={class_name.date_editicon_div}>
                                        <div className={class_name.date_txt}>
                                          {item?.dataValues?.createdAt? moment(item?.dataValues?.createdAt).format("DD.MM.YYYY"): "Date"}
                                        </div>
                                        <div className={class_name.icon_div}>
                                          <div className={class_name.icon}>
                                            <span
                                            onClick={() => {handleEdit(item?.dataValues?.boat_uuid,item?.dataValues?.user_id)}}
                                            >
                                              <img
                                                alt="edit"
                                                src={IMAGES.EDIT_ICON}
                                                className={class_name.editIcon}
                                              />
                                            </span>
                                          </div>
                                          <div className={class_name.icon}>
                                            <span
                                            onClick={() => {navigate('/requestList',{
                                            state:
                                            {
                                              boat_id: item?.dataValues?.boat_id,
                                              // boat_booking_id: item?.dataValues?.boat_booking_id
                                            }
                                            })}}
                                            >
                                              <img
                                                alt="edit"
                                                src={IMAGES.USER_PROFILE}
                                                className={class_name.editIcon}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div>
                                  {item?.dataValues?.OverAllBoatVerificationStatus === 'REJECTED'?
                                  <span
                                  style={{
                                    color: 'red',
                                    fontSize: '12px'
                                  }}
                                  >
                                    {item?.dataValues?.OverAllBoatVerificationRejectReason}
                                  </span>:null
                                  }
                                </div>
                              </div>
                            ))}
                        </div>
                        :
                        <div className={class_name.noDataContent}>
                          <img
                            alt="no result"
                            src={IMAGES.NO_RESULT}
                            className={class_name.noResultImg}
                          />
                          <span className={class_name.sryTxt}>
                            Sorry, No Records found.
                          </span>
                        </div>
                      }
                    </div>
                </div>
                <div className="d-none d-md-block">
                  <Footer />
                </div>
            </div>
        </div>
    </div>
  )
}

export default MyListing

const useStyles = makeStyles((theme) => ({
    myListingsContainer: {
        display: "flex",
        backgroundColor: "#f6f6f6",
        width: "100%",
        minHeight: '100%'
      },

      body: {
        display: "flex",
        width: "100%",
        paddingRight: "13%",
        paddingLeft: "13%",
        flexDirection: "column",
      },

      listings: {
        display: "flex",
        flexDirection: "row",
        marginTop: "20px",
        backgroundColor: "white",
        boxShadow: "0px 0px 1px rgba(66, 70, 81, 0.3)",
      },

      img_div: {
        width: "276px", 
        height: "210px",
        [theme.breakpoints.down('768')]:{
            width: "276px", 
            height: "180px"
        },
        [theme.breakpoints.down('480')]:{
            width: "170px", 
            height: "130px"
        }
      },

      boat_img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      },

      listings_body: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '25px 36px',
        [theme.breakpoints.down('768')]: {
          padding: '20px 26px'
        },
        [theme.breakpoints.down('480')]: {
          padding: '23px 16px 0px 16px'
        }
      },

      boat_name: {
        fontSize: "22px",
        fontWeight: "800",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: "#424651",
        marginBottom: "10px",
        [theme.breakpoints.down('768')]:{
          fontSize: '16px'
        },
        [theme.breakpoints.down('480')]:{
          fontSize: '14px',
          marginBottom: '0px'
        }
      },

      price_div: {
        display: "flex",
        paddingTop: '33px',
        [theme.breakpoints.down('480')]:{
          paddingTop: '10px'
        }
      },

      money_img: {
        width: "26px",
        height: "27px",
        marginRight: "20px",
        [theme.breakpoints.down('768')]:{
          width: "20px",
          height: "21px",
          marginRight: "10px",
        },
        [theme.breakpoints.down('480')]:{
          width: "18px",
          height: "19px",
          marginRight: "8px",
        }
      },

      money_text: {
        fontSize: "20px",
        color: "#424651",
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('768')]:{
          fontSize: '16px'
        },
        [theme.breakpoints.down('480')]:{
          fontSize: '11px'
        }
      },

      money_hour_text: {
        marginLeft: "10px", 
        fontWeight: "bold",
        fontSize: '16px',
        [theme.breakpoints.down('768')]:{
          marginLeft: "8px", 
          fontSize: '14px'
        },
        [theme.breakpoints.down('480')]:{
          marginLeft: "6px", 
          fontSize: '11px'
        }
      },

      date_editicon_div: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-end",
      },

      date_txt: {
        fontSize: '18px',
        [theme.breakpoints.down('768')]:{
          fontSize: '14px'
        },
        [theme.breakpoints.down('480')]:{
          fontSize: '12px'
        }
      },

      icon_div: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: '10px',
        marginTop: "25px",
        [theme.breakpoints.down('768')]:{
          marginTop: '18px'
        },
        [theme.breakpoints.down('480')]:{
          marginTop: '14px'
        }
      },

      icon: {
        width: "44px",
        height: "44px",
        borderRadius: '50%',
        border: "1px solid #3973a5",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('768')]:{
          width: "34px",
          height: "34px",
        },
        [theme.breakpoints.down('480')]:{
          width: "28px",
          height: "28px",
        }
      },

      editIcon: {
        width: "20px",
        height: "20px",
        [theme.breakpoints.down('768')]:{
          width: "16px",
          height: "16px",
        },
        [theme.breakpoints.down('480')]:{
          width: "13px",
          height: "13px",
        }
      },
      
      noDataContent: {
        height: '100vh',
        display: "flex",
        flexDirection: "column",
        marginTop: "100px",
        marginBottom: '100px',
        alignItems: "center",
      },
      
      noResultImg: {
        width: "clamp(80px, 35vw, 139px)", // Adjust the range as needed
        height: "clamp(80px, 35vh, 139px)", // Adjust the range as needed
      },

      sryTxt: {
        fontSize: "clamp(14px, 3vw, 48px)",
        fontWeight: "600",
        color: "rgba(66, 70, 81, 0.87)",
      },
}))