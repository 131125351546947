import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InputLabel, Typography, TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//
import Loader from "../../Loader";
import IMAGES from "../../Images";
import { EmailId } from "../../../redux/slices";
import { forgot_password_request } from "../../../Service/api";

const start_space_Validation = new RegExp(/^(?!\s).*/);

const styles = (theme) => ({
  root: {

    "& .MuiInputBase-root":{
      "& fieldset":{
        border: 'none'
      },
    },

    "& MuiInputBase-input":{
      fontSize: "clamp(14px, 3vw, 20px)",
    },

    "& input::placeholder": {
      fontSize: "clamp(10px, 5vw, 16px)",
      color: "rgba(66, 70, 81, 0.4)",
    },
    [theme.breakpoints.down("sm")]: {
      "& input::placeholder": {
        fontSize: "13px", // Font size for small screens
      },
    },
    [theme.breakpoints.between("sm", "md")]: {
      "& input::placeholder": {
        fontSize: "14px", // Font size for medium-sized screens
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& input::placeholder": {
        fontSize: "18px", // Font size for large screens
        top: "10px",
      },
      padding: "0px",
    },
  },
});
const CustomTextField = withStyles(styles)(TextField);

export const ForgotPassword = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth);
  const [emailAddress, setEmailAddress] = useState(user?.emailId ?? "");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const error_toast = () =>{
    toast.error("Please Enter Your Email Id", {
      position: 'top-right',
      autoClose: 5000,
    });
  }

  const handleSubmit = (values) => {
    setErrorMsg("");
    toast.dismiss();
    setIsLoading(true);
    let payload = {
      email: emailAddress,
    };
    console.log("forgot pwd request payload", payload);
    forgot_password_request(payload)
      .then((res) => {
        console.log("forgot pwd request res", res);
        if (res?.data?.message?.toLowerCase() === "otp sent to email") {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          navigate("/verifyForgotPwdOTP");
          dispatch(EmailId(emailAddress));
          setIsLoading(false);
        } else {
          setErrorMsg(res?.data?.message);
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("Forgot Pwd Request Error : ", err);
        setIsLoading(false);
        toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  return (
    <div className={class_name.pwd_full_box}>
      {isLoading ? <Loader loading={isLoading} /> : null}
      <div className={class_name.icon_and_back}>
        <div
          className={class_name.top_back_button}
          onClick={() => {
            dispatch(EmailId(null));
            navigate(-1);
          }}
        >
          <img
            src={IMAGES.LEFT_BACK_BUTTON}
            className={class_name.back_iocn}
            alt="Back"
          />
        </div>
        <div className={class_name.appIcon_div}>
          <img src={IMAGES.APP_ICON} alt="ICON" className={class_name.appIcon} />
        </div>
      </div>
      <div className={class_name.page_body_container}>
        <div className={class_name.form_container_box}>
          <div
            onClick={() => {
              navigate(-1);
            }}
            className={class_name.with_txt_back_icon}
          >
            <img
              src={IMAGES.LEFT_BACK_BUTTON}
              className={class_name.back_iocn}
              alt="Back"
            />
            <Typography className={class_name.back_txt}>Back</Typography>
          </div>

          <div className={class_name.inside_body}>
            <Typography className={class_name.pageTitle_pwd}>
              Forgot Password
            </Typography>

            <Typography className={class_name.pwd_info_txt}>
              Enter the email address associated with your account and we`ll
              send you a verification code to reset your password
            </Typography>

            <div className={class_name.show_txt_filed}>
              <InputLabel className={class_name.email_label}>Email</InputLabel>

              <CustomTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="emailAddress"
                name="emailAddress"
                placeholder="Please enter your email id"
                value={emailAddress}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  if (start_space_Validation.test(inputValue)) {
                    setEmailAddress(inputValue);
                  }
                }}
                InputProps={{
                  style: { 
                    padding: "0px",
                    backgroundColor: 'white',
                    border:
                    !emailAddress
                    ?? "1px solid red"
                  },
                  className: class_name.email_txt,
                }}
                style={{
                  padding: "0px",
                }}
                className={class_name.email_txt_filed_box}
              />

              {errorMsg ? (
                <Typography
                  style={{
                    color: "#DC143C",
                    fontSize: 12,
                  }}
                >
                  {errorMsg}
                </Typography>
              ) : null}
            </div>

            <div
              className={class_name?.get_action_btn}
              onClick={() => (emailAddress !== "" ? handleSubmit() : error_toast())}
              style={{
                backgroundColor:
                  emailAddress !== "" ? "#026b93" : "rgba(151, 151, 151, 1)",
                border:
                  emailAddress !== ""
                    ? "solid 2.5px #026b93"
                    : "solid .5px #026b93",
              }}
            >
              <Typography
                className={`${class_name.get_action_txt} hoverEffect`}
              >
                Continue
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  page_body_container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    height: "100%",
    backgroundColor: "#f6f6f6",
    paddingBottom: "100px",
  },
  form_container_box: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
    height: "100%",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    border: "solid 0.5px rgba(66, 70, 81, 0.1)",
    padding: "1% 1%",
    borderRadius: "2px",
    width: "90%",
    marginTop: "15px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "30px",
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "30px",
      width: "80%",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "40px",
      width: "70%",
    },
    [theme.breakpoints.down('767')]:{
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: 'none'
    }
  },

  pwd_full_box: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#f6f6f6",
    paddingBottom: "100px",
  },

  body_content: {
    display: "flex",
    height: "90%",
  },

  icon_and_back: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  appIcon_div: {
    width: '100%',
    display: 'flex',
    paddingLeft: '8%',
    [theme.breakpoints.down('767')]:{
      justifyContent: 'center',
      paddingLeft: '0%',
      paddingRight: '7%'
    }
  },

  appIcon: {
    // marginLeft: "140px",
    width: "clamp(136px, 23vw, 198px)",
    height: "clamp(52px, 13vw, 98px)",
  },

  top_back_button: {
    display: "none",
  },

  inside_body: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: "1% 5%",
    [theme.breakpoints.down('767')]:{
      backgroundColor: 'transparent'
    }
  },

  back_iocn: {
    width: "14px",
    height: "18px",
  },

  with_txt_back_icon: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
    alignSelf: "flex-start",
    margin: "20px 10px",
  },

  back_txt: {
    marginLeft: "20px",
    fontSize: "clamp(10px, 5vw, 20px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#3973a5",
  },

  pageTitle_pwd: {
    fontSize: "clamp(16px, 7vw, 48px)",
    fontWeight: "bolder",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 1)",
  },

  pwd_info_txt: {
    fontSize: "clamp(12px, 3vw, 20px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "rgba(66, 70, 81, 0.6)",
    width: "70%",
  },

  show_txt_filed: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
  },
  email_txt_filed_box: {
    marginTop: "7px",
  },
  email_label: {
    fontSize: "clamp(14px, 3vw, 20px)", // Adjust the range as needed
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    color: "rgba(66, 70, 81, 0.8)",
    textAlign: "left",
    marginLeft: "10px",
  },
  email_txt: {
    fontSize: "clamp(12px, 3.5vw, 20px)",
    // height: '100%',
    borderRadius: "5px",
    border: 'solid 1px lightgray',
    color: "rgba(66, 70, 81, 0.9)",
    [theme.breakpoints.down('767')]:{
      border: 'none'
    }
  },

  get_action_btn: {
    cursor: "pointer",
    width: '50%',
    borderRadius: "5px",
    border: "solid 2.5px #026b93",
    padding: "2% 7%",
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    transition: "background-color 0.3s", // Add a smooth transition for the background color
    backgroundColor: "#026b93",
    "&:hover": {
      backgroundColor: "#026b93", // Change the background color on hover
      boxShadow: "1px 1px 2px #026b93",
      "& $get_action_txt": {
        color: "white", // Change text color on hover
      },
      "& $left_arr": {
        filter: "brightness(0) invert(1)", // Change image color on hover
      },
    },
    [theme.breakpoints.down('767')]:{
      width: '100%',
      marginTop: '10%',
      padding: '3% 7%'
    }
  },
  get_action_txt: {
    width: '100%',
    fontSize: "clamp(16px, 2vw, 24px)", // Adjust the range as needed
    fontWeight: "inherit",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.96,
    letterSpacing: "normal",
    textAlign: "center",
    color: "white",
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    form_container_box: {
      height: "auto",
      borderRadius: "15px",
    },
    icon_and_back: {
      padding: "16px 16px 0px 16px",
    },
    appIcon: {
      // marginLeft: "24px",
      width: '150px',
      height: '70px',
    },

    top_back_button: {
      display: "flex",
      alignSelf: "center",
      // paddingRight: '30%'
    },
    with_txt_back_icon: {
      display: "none",
    },
    inside_body: {
      justifyContent: "flex-start",
      // padding: "20% 5%",
    },
    email_txt_filed_box: {},
    email_txt: {
      // height: "100%",
    },
    pwd_info_txt: {
      margin: "15% 0",
      width: "100%",
    },
    show_txt_filed: {
      width: "100%",
    },
    email_label: {},
    // get_action_btn: {
    //   borderRadius: "0px",
    //   position: "fixed",
    //   bottom: 0,
    //   left: "50%",
    //   transform: "translateX(-50%)",
    //   backgroundColor: "rgba(151, 151, 151, 1)",
    //   border: "solid 1px rgba(151, 151, 151, 1)",
    //   padding: "0px",
    //   width: "100%",
    //   height: "56px",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignContent: "center",
    //   alignItems: "center",
    //   alignSelf: "center",
    // },
    // get_action_txt: {
    //   fontSize: "clamp(16px, 5vw, 20px)", // Adjust the range as needed
    //   fontWeight: "600",
    //   fontStretch: "normal",
    //   fontStyle: "normal",
    //   lineHeight: 0.96,
    //   letterSpacing: "normal",
    //   textAlign: "center",
    //   color: "white",
    // },
  },
  '@media (max-height: 575.98px) and (orientation: landscape)': {
    pwd_full_box: {
      height: '250vh'
    }
  }
}));
